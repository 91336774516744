import firebase from 'firebase/app';
import { useContext } from 'react';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { useSignupWithoutBooking } from '../fetch/endpoints';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { useLoginSuccessCallback } from './useLoginSuccessCallback';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import * as Sentry from "@sentry/react";
import { authFunctions, FIREBASE_AUTH_METHODS } from './useLogin';

export const useGoogleSignup = ({ onSuccess = () => { }, onFailure = () => { } }) => {
  const { inviteCode, guestPass } = useContext(InviteInfoContext);
  const { preSignupUserData, setLoginInProgress, setLogoutInProgress } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: signupWithoutBooking } = useSignupWithoutBooking()
  const setLoggedInSuccessResult = useLoginSuccessCallback(onSuccess, "signup")
  const createFirestoreUser = async (authUser) => {
    const { displayName, email, uid: userId } = authUser
    const { success, error } = await signupWithoutBooking({ displayName, email, userId, inviteCode, guestPassId: guestPass ? guestPass.id : null, preSignupUserData })
    if (success) {
      sendSegmentEvent("Signed up for trial", { authMethod: 'Google Login' })
      setLoggedInSuccessResult(authUser)
    } else {
      console.error(`Error creating firestore user after successful account-creation auth – deleting newly created firebase auth user (id ${userId}) in order to allow clean retry`)
      console.error(error)
      Sentry.captureException(error, { extra: { displayName, email, userId } });
      await firebase.auth().currentUser.delete()
      onFailure(`There was an error creating your account: ${error}`)
    }
  }

  const signInSuccessful = (signInResult) => {
    sendSegmentEvent("User Signed Up", { accountExisted: !signInResult.additionalUserInfo.isNewUser, firebaseAuthMethod: FIREBASE_AUTH_METHODS.GOOGLE })
    const authUser = signInResult.user
    if (signInResult.additionalUserInfo.isNewUser) {
      setLoginInProgress(true)
      createFirestoreUser(authUser)
    } else {
      setLogoutInProgress(true)
      firebase.auth().signOut()
      onFailure(`You already have a Flow Club account using this email (${signInResult.additionalUserInfo.profile.email})! Did you mean to log in instead?`)
    }
  }

  const authFunction = authFunctions[FIREBASE_AUTH_METHODS.GOOGLE]
  const attemptAuthentication = async (...args) => {
    try {
      setLoginInProgress(true)
      signInSuccessful(await authFunction(...args))
    } catch (error) {
      onFailure(translateAuthErrorCodeToMessage(error.code))
    }
  }

  return attemptAuthentication
}