/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useHistory } from 'react-router';
import { WelcomePane } from './WelcomePane';
import { ADHDWelcomePane } from './ADHDWelcomePane';
import { TestimonialPane } from './TestimonialPane';
import { BUTTON_STYLES, FlowButton } from '../components/flowComponents';
import { useMediaQuery } from 'react-responsive';
import Icon, { TYPE_CLOSE } from '../daily/daily-components/Icon/Icon';
import { FlowCarousel } from './FlowCarousel';
import { FC_DARK_BLUE } from '../emotionVariables';
import { GoalPane } from './GoalPane';
import { ADHDTestimonialPane } from './ADHDTestimonialPane'
import { InviteInfoContext } from '../InviteInfoWrapper';

import { animated, config, useSpring } from '@react-spring/web'
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { ConnectCalendarPane } from './ConnectCalendarPane';
import { SelectCalendarSpanPane } from './SelectCalendarSpanPane';
import { SignupPane } from './SignupPane';

export const InterstitialContext = createContext(null)

const WelcomeInterstitialDataWrapper = ({ paneSetOverride = null, onExit = null }) => {
  const paneSets = {
    connectCalendar: {
      panes: [
        { name: "WelcomePane", MainContent: <WelcomePane key='welcome' /> },
        { name: "TestimonialPane", MainContent: <TestimonialPane key='testimonial' /> },
        { name: "SignupPane", MainContent: <SignupPane key='signup' />, navType: 'skip-exit' },
        { name: "GoalPane", MainContent: <GoalPane key='goal' />, navType: 'skip' },
        { name: "ConnectCalendarPane", MainContent: <ConnectCalendarPane key='connect-calendar' />, navType: 'skip' },
        { name: "SelectCalendarSpanPane", MainContent: <SelectCalendarSpanPane key='select-calendar-span' />, navType: 'skip-exit' },
      ]
    }
  }

  const paneSetName = useMemo(() => {
    if (paneSetOverride !== null) { return paneSetOverride.name }

    const options = Object.keys(paneSets)
    return options[Math.floor(Math.random() * options.length)]
  }, [])
  const carouselPanes = paneSetOverride === null ? paneSets[paneSetName].panes : paneSetOverride.panes

  const [activePaneIndex, setActivePaneIndex] = useState(0)
  
  const paneNames = carouselPanes.map(pane => pane.name)
  const segmentBaseData = {
    panes: paneNames,
    activePaneIndex: activePaneIndex,
    currentPaneName: paneNames[activePaneIndex],
    paneSetName
  }

  return (
    <SegmentProvider baseData={segmentBaseData} eventLabel={'Interstitial-FE'}>
      <WelcomeInterstitialContent
        activePaneIndex={activePaneIndex}
        setActivePaneIndex={setActivePaneIndex}
        carouselPanes={carouselPanes}
        onExit={onExit}
      />
    </SegmentProvider>
  )
}

const WelcomeInterstitialContent = ({
  activePaneIndex,
  setActivePaneIndex,
  carouselPanes,
  onExit
}) => {
  useEffect(() => {
    localStorage.setItem('fc-sawWelcome', new Date().toISOString())
  }, [])

  const largeScreenView = useMediaQuery({query: '(min-width: 769px)'})

  const [interstitialState, setInterstitialState] = useState({})

  // this is hacky and I don't like it but here we are
  const { navTypeOverride } = interstitialState
  const navType = navTypeOverride !== undefined ? navTypeOverride : carouselPanes[activePaneIndex].navType

  const history = useHistory()
  const goToSchedule = () => {
    const timeFilter = interstitialState.sessionTimeFilter !== undefined && interstitialState.sessionTimeFilter !== null ? `?timeFilter=${interstitialState.sessionTimeFilter.name}` : ''
    history.push(`/upcoming${timeFilter}`)
  }
  const exitInterstitial = onExit ?? goToSchedule

  const sendSegmentEvent = useSendSegmentEvent()

  useEffect(() => {
    sendSegmentEvent("Interstitial Shown")
  }, [])


  useEffect(() => {
    if (activePaneIndex !== 0) {
      sendSegmentEvent("Navigated to Pane", { targetPaneIndex: activePaneIndex, targetPane: carouselPanes[activePaneIndex].name })
    }
  }, [activePaneIndex])

  const goToNextPane = (exitImmediately = false) => {
    if (activePaneIndex < carouselPanes.length - 1 && !exitImmediately) {
      setActivePaneIndex(activePaneIndex + 1)
    } else {
      // an only mildly insane hack around the fact that if this isn't delayed, the click event will somehow propagate through to elements which aren't even mounted yet at the time of the click
      // in this case, useClickOutside is catching clicks after the time filters are supposed to be automatically opened and closing them again
      setTimeout(exitInterstitial, 0)
    }
  }

  const goToPreviousPane = () => {
    if (activePaneIndex > 0) {
      setActivePaneIndex(activePaneIndex - 1);
    } 
  }

  const nextPressed = () => {
    const exitImmediately = navType === 'skip-exit' || (navType === 'skip-unless-time-filter' && interstitialState.sessionTimeFilter !== null)
    sendSegmentEvent("Next Button Pressed")
    goToNextPane(exitImmediately)
  }

  const closeButtonClicked = () => {
    exitInterstitial()
    sendSegmentEvent("Close Button Pressed")
  }

  return (
    <InterstitialContext.Provider value={{
      sendSegmentEvent,
      largeScreenView,
      goToPreviousPane,
      goToNextPane,
      interstitialState,
      setInterstitialState: (newState) => setInterstitialState(interstitialState => ({ ...interstitialState, ...newState }))
    }}>
      <div css={css`
        position: relative;
        overflow-x: hidden;
      `}>
        <div css={css`display: flex; justify-content: center; align-items: center;`}>
          <div css={css`
            display: flex;
            flex-direction: column;
            width: ${largeScreenView ? 650 : 311}px;
            height: 100%;
            ${largeScreenView && css`max-height: 700px;`};
            align-items: center;
            position: relative;
            padding-top: 68px;
          `}>
            <div css={css`width: 100%; height: ${largeScreenView ? '100vh' : '650px'}; `}>
              <FlowCarousel currentItemIndex={activePaneIndex}>
                {carouselPanes.map(({ MainContent }) => MainContent)}
              </FlowCarousel>
            </div>
            <div css={css`
              width: 303px;
              display: flex;
              justify-content: center;
            `}>
              <FooterContent navType={navType} nextPressed={nextPressed} carouselPanes={carouselPanes} currentPaneIndex={activePaneIndex} />
            </div>
          </div>
        </div>
        <div onClick={closeButtonClicked} css={css`
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        `}>
          <Icon type={TYPE_CLOSE} />
        </div>
      </div>
    </InterstitialContext.Provider>
  )
}

const FooterContent = ({ navType = null, nextPressed, carouselPanes, currentPaneIndex }) => {
  let buttonProps = {
    buttonStyle: undefined,
    text: "Next"
  }
  if (['skip', 'skip-exit', 'skip-unless-time-filter'].includes(navType)) {
    buttonProps = {
      buttonStyle: BUTTON_STYLES.NO_BACKGROUND,
      text: "Skip"
    }
  }

  return (
    <div css={css`
      width: 100%;
      display: flex;
      justify-content: space-between;
    `}>
      <div css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;
      `}>
        {carouselPanes.map((_, dotIndex) => 
          <NavDot dotIndex={dotIndex} currentPaneIndex={currentPaneIndex}/>
        )}
      </div>
      {navType !== 'none' && <FlowButton disabled={navType === 'disabled'} key={currentPaneIndex} buttonStyle={buttonProps.buttonStyle} onClick={nextPressed}>{buttonProps.text}</FlowButton>}
    </div>
  )
}

const NavDot = ({ dotIndex, currentPaneIndex }) => {
  const style = useSpring({
    clipPath: `inset(0 ${currentPaneIndex < dotIndex ? '100%' : '0%'} 0 0`,
    opacity: `${currentPaneIndex > dotIndex ? '0.3' : '1'}`,
    config: config.slow,
  })

  return (
    <div key={dotIndex} css={css`
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background-color: #E1E1E1;
      ${dotIndex !== 0 && css`margin-left: 8px;`}

      position: relative;
    `}>
      <animated.div style={style} css={css`
        position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; border-radius: inherit;
        background-color: ${FC_DARK_BLUE};
      `}/>
    </div>
  )

}

export const WelcomeInterstitial = WelcomeInterstitialDataWrapper