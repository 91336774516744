/** @jsxImportSource @emotion/react */
import { keyframes, css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import Icon, { TYPE_CLOSE_UNSET_FILL } from '../daily/daily-components/Icon/Icon';
import { FC_GREEN, FC_IRIS_BLUE } from '../emotionVariables';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { LoungeFullscreenModal } from './LoungeFullscreenModal';
import { TooltipWrapper } from '../components/TooltipWrapper';
import { ExclusiveMouseoverWrapper } from './ExclusiveMouseoverWrapper';
import { FlowButton } from '../components/flowComponents';
import { LoungeContext } from './Lounge';
import { UserContext } from '../UserProvider';
import { useOptimisticInitiateHighFiveWithUser } from './loungeStateManagement/useOptimisticInitiateHighFiveWithUser';
import { useMediaQuery } from 'react-responsive';
import { HighFiveIcon } from './HighFiveIcon';
import { LoungeTooltip } from './LoungeTooltip';
import { SegmentContext, useSendSegmentEvent } from '../wrappers/SegmentProvider';

const MAX_ICONS_TO_DISPLAY = 6

// this is way too finicky with totally unvalidated user entered data so here we are
export const parseInitialsFromDisplayName = (name) => {
  try {
    const words = name.trim().split(' ')
    if (words.length > 0) {
      return words.map(word => word[0].toUpperCase()).join('')
    }
  } catch (error) {
    console.error(`Error parsing initials from display name: '${name}'`)
  }

  return "🙂"
}

export const OtherPeepsList = ({ users }) => {
  const moreUsersExistThanDisplayable = users.length > MAX_ICONS_TO_DISPLAY
  const extraUsers = moreUsersExistThanDisplayable ? users.length - (MAX_ICONS_TO_DISPLAY - 1) : 0
  const usersToDisplay = users.slice(0, moreUsersExistThanDisplayable ? MAX_ICONS_TO_DISPLAY - 1 : MAX_ICONS_TO_DISPLAY)

  return (
    <div css={css`display: flex; justify-content: flex-end;`}>
      <ExclusiveMouseoverWrapper exclusivityKey={0}>
        {usersToDisplay.map(user => <UserIconWithTooltip key={user.id} user={user}/>)}
      </ExclusiveMouseoverWrapper>
      {moreUsersExistThanDisplayable > 0 && <ExtraUsersIcon extraUsers={extraUsers} allUsers={users} />}
    </div>
  )
}

const UserIconWithTooltip = ({ user, sharedMouseoverState }) => {
  const { user: signedInUser } = useContext(UserContext)
  return (
    <>
      <div css={css`width: 8px;`}/>
      {signedInUser !== null ? 
        <TooltipWrapper TooltipComponent={LoungeTooltip} TooltipContents={<UserIconTooltip user={user} />} sharedMouseoverState={sharedMouseoverState} retainMousedOverInsideTooltip>
          <UserIcon user={user} />
        </TooltipWrapper> :
        <UserIcon user={user} mousedOverActive={false} />
      }
    </>
  )
}


const UserIcon = ({ user, mousedOverActive, insideFullUserList = false }) => {
  const { name, timedSession } = user

  const currentlyInTimedSession = timedSession.start !== undefined
  const timerLengthSeconds = Math.floor(timedSession.timerLengthMs / 1000)
  const progressCirclePortionCompleted = ((Date.now() - timedSession.start) / 1000) / timerLengthSeconds
  const progressCircleRemainingFillTime = timerLengthSeconds * (1 - progressCirclePortionCompleted)

  const initials = parseInitialsFromDisplayName(name)

  const hoveredStyle = css`
    background-color: ${FC_IRIS_BLUE};
  `

  const sendSegmentEvent = useSendSegmentEvent()
  useEffect(() => {
    if (mousedOverActive) {
      sendSegmentEvent("User Icon Moused Over", { targetName: name, targetCurrentlyInTimedSession: currentlyInTimedSession, targetTimedSessionPortionCompleted: progressCirclePortionCompleted, insideFullUserList })
    }
  }, [mousedOverActive])

  return (
    <div css={css`
      border-radius: 100%;
      width: 42px;
      height: 42px;
      border: 1px solid white;

      background-color: rgba(0, 0, 0, 0.5);
      cursor: default;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: background-color 0.2s, border 0.2s;

      position: relative;

      ${mousedOverActive && hoveredStyle};
    `}>
      <div css={css`
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
      `}>{initials}</div>
      {currentlyInTimedSession && <ProgressCircle startPercent={progressCirclePortionCompleted} fillTime={progressCircleRemainingFillTime}/>}
    </div>
  )
}
  
export const UserIconTooltip = ({ user }) => {
  const { spaceId } = useContext(LoungeContext)
  const { performFetch: initiateHighFiveWithUser } = useOptimisticInitiateHighFiveWithUser()
  const sendHighFive = (recipientId) => initiateHighFiveWithUser({ spaceId, recipientId })

  return (
    <div css={css`
      width: 198px;
      background-color: black;
      border-radius: 4px;
    `}>
      <OtherUserMouseoverInfo user={user} sendHighFive={sendHighFive} />
    </div>
  )
}

const OtherUserMouseoverInfo = ({ user, sendHighFive }) => {
  const { user: signedInUser } = useContext(UserContext)

  if (!user) {
    // dont render the tooltip if it's still loading user data
    return <></>
  }
  const { name, goals, userId, locationSubtitle } = user;

  const goalsEntered = goals.some(goal => goal.text.length > 0)

  const showHighFiveButton = signedInUser.uid !== userId

  return (
    <>
      <div css={css`
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: white;
      `}>
        {name}
      </div>
      <div css={css`
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: white;
      `}>
        {locationSubtitle}
      </div>
      {showHighFiveButton && <HighFiveButton sendHighFive={sendHighFive} userId={userId}/>}
      {goalsEntered &&
        <>
          <div css={css`
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 1.5px;
            margin-top: 12px;
            margin-bottom: 8px;
            color: white;
          `}>
            TASKS
          </div>
          {goals.map((goal, index) => <UserIconTooltipTask key={index}>{goal}</UserIconTooltipTask>)}
        </>
      }
    </>
  )
}

const HighFiveButton = ({ userId, sendHighFive }) => {
  const [highFiveSent, setHighFiveSent] = useState(false)
  const highFiveClicked = () => {
    if (!highFiveSent) {
      sendHighFive(userId)
      setHighFiveSent(true)
    }
  }
  // Not enormously elegant code, but we want to reset the green state of the button when mousing over a different user in the expanded user list
  useEffect(() => setHighFiveSent(false), [userId])

  const [highFiveHovered, setHighFiveHovered] = useState(false)
  const onHighFiveHover = () => setHighFiveHovered(true)
  const onhighFiveHoverEnded = () => setHighFiveHovered(false)

  return (
    <FlowButton onMouseEnter={onHighFiveHover} onMouseLeave={onhighFiveHoverEnded} onClick={highFiveClicked} customCss={css`
      background-color: transparent;
      border: 1px solid white;
      margin-top: 16px;
      padding: 14px 24px;
      height: 44px;
      transition: transform 0.2s;
      &:highFiveHovered {
        transform: scale(1.05);
      }
      ${highFiveSent && css`
        background-color: ${FC_GREEN};
        &:hover { background-color: ${FC_GREEN}; }
        &:active { background-color: ${FC_GREEN} }
      `}
    `}>
      <HighFiveIcon hovered={highFiveHovered} highFiveSent={highFiveSent} customCss={css`margin-left: 8px;`} />
      <span css={css`margin-left: 10px;`}>
        High Five
      </span>
    </FlowButton>
  )
}

const UserIconTooltipTask = ({ children: goal }) => {
  const { text, completedAt } = goal
  return (
    <div css={css`
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: white;
      text-overflow: ellipsis;
      overflow: hidden;
    `}>
      {completedAt !== null ? '☑' : '☐'} {text}
    </div>
  )
}

const ProgressCircle = ({ startPercent = 0, fillTime = 20 }) => {
  const animateProgress = keyframes({
    'from': css`
      stroke-dashoffset: ${142 - (142 * startPercent)};
    `,
    'to': css`
      stroke-dashoffset: 0;
    `
  })

  return (
    <svg css={css`
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      transform: rotate(-90deg);
      position: absolute;
      clip-path: circle(51% at 50% 50%);
      overflow: visible;
    `}>
      <circle cx="50%" cy="50%" r="50%" css={css`
        width: 100%;
        height: 100%;
        fill: none;
        stroke-width: 3;
        stroke-dasharray: 142;
        stroke: ${FC_IRIS_BLUE};
        stroke-linecap: butt;

        animation: ${animateProgress} ${fillTime}s linear forwards;
      `}/>
    </svg>
  )
}

const ExtraUsersIcon = ({ extraUsers, allUsers }) => {
  const { spaceId, optimisticUpdatesData } = useContext(LoungeContext)
  const { user } = useContext(UserContext)
  const segmentContextValue = useContext(SegmentContext)
  const sendSegmentEvent = useSendSegmentEvent()

  const { setActiveModal } = useModal()
  const openFullUsersList = () => {
    setActiveModal(LoungeFullscreenModal, { Contents: ({ onHide }) =>
      (
        <SegmentContext.Provider value={segmentContextValue}>
          <LoungeContext.Provider value={{ spaceId, optimisticUpdatesData }}>
            <FullUsersList onHide={onHide} users={allUsers} />
          </LoungeContext.Provider>
        </SegmentContext.Provider>
      ),
      overlayColor: 'rgba(0, 0, 0, .5)'
    })
  }

  const mobileView = useMediaQuery({query: '(max-width: 767px)'})
  const mouseoversEnabled = !mobileView

  const buttonClicked = () => {
    if (mouseoversEnabled) {
      sendSegmentEvent("+X Users Icon Clicked", { clickAllowed: user !== null })
      if (user !== null) {
        openFullUsersList()
      }
    }
  }

  const buttonMousedOver = () => {
    sendSegmentEvent("+X Users Icon Moused Over")
  }

  const hoveredStyle = css`
    background-color: ${FC_IRIS_BLUE};
  `

  return (
    <>
      <div css={css`width: 8px;`}/>
      <div onMouseEnter={buttonMousedOver} onClick={buttonClicked} css={css`
        border-radius: 100%;
        width: 42px;
        height: 42px;
        border: 1px solid white;

        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: background-color 0.2s;

        &:hover {
          ${mouseoversEnabled && hoveredStyle}
        }
      `}>
        <div css={css`
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #FFFFFF;
        `}>+{extraUsers}</div>
      </div>
    </>
  )
}

const FullUsersList = ({ onHide, users }) => {
  const [hoveredUserIndex, setHoveredUserIndex] = useState(0)

  return (
    <div css={css`
      width: 446px;
      height: 320px;
      background-color: black;
      border-radius: 16px;
      padding-left: 36px;
      padding-bottom: 24px;
      display: flex;
      flex-flow: column;
    `}>
      <div css={css`
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
      `}>
        <div onClick={onHide}>
          <Icon type={TYPE_CLOSE_UNSET_FILL} css={css`
            cursor: pointer;
            width: 44px;
            height: 44px;
            fill: white;
          `} />
        </div>
      </div>
      <div css={css`display: flex; margin-bottom: 24px; overflow-x: auto; padding: 3px;`}>
        {users.map((user, index) => <FullUsersListUserIconWrapper key={user.id} user={user} isCurrentlyHovered={hoveredUserIndex === index} setHoveredUser={() => setHoveredUserIndex(index)} />)}
      </div>
      {hoveredUserIndex !== null && <FullUsersListUserDetails user={users[hoveredUserIndex]} />}
    </div>
  )
}

const FullUsersListUserIconWrapper = ({ user, setHoveredUser, isCurrentlyHovered }) => {

  const onMouseEnter = () => {
    setHoveredUser()
  }
  
  return (
    <div onMouseEnter={onMouseEnter} css={css`margin-right: 8px;`}>
      <UserIcon user={user} mousedOverActive={isCurrentlyHovered} insideFullUserList />
    </div>
  )
}

const FullUsersListUserDetails = ({ user }) => {
  const { spaceId } = useContext(LoungeContext)
  const { performFetch: initiateHighFiveWithUser } = useOptimisticInitiateHighFiveWithUser()
  const sendHighFive = (recipientId) => initiateHighFiveWithUser({ spaceId, recipientId })
  
  return (
    <div css={css`overflow-y: auto; flex-basis: 0%; flex-grow: 1; padding-right: 36px;`}>
      <OtherUserMouseoverInfo user={user} sendHighFive={sendHighFive} />
    </div>
  )
}