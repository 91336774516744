/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive';
import { NEUTRAL_100 } from '../emotionVariables';
import { Nav } from 'react-bootstrap';
import { DAYS_TO_SHOW_ON_SCHEDULE } from '../../functions/shared/constants';

export const DateShortcuts = ({ days, sessionDayVisibilities, loading }) => {
  const lastSessionDay = days.length > 0 ? days[days.length - 1] : dayjs().startOf('day')
  const daysToGenerate = DAYS_TO_SHOW_ON_SCHEDULE - days.length
  const daysLoading = Array.from({ length: daysToGenerate }, (_, i) => 
    lastSessionDay.add(i + 1, 'day')
  ).map(day => day.unix())
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })

  return (
     <div css={css`display: flex; align-items: center; ${!mobileView && css`justify-content: center;`}`}>
      <div css={css`
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      `}>
        {days.map((day, index) => (
          <DatePill
            key={day.unix()}
            day={day}
            active={sessionDayVisibilities[index]}
            isFirst={!sessionDayVisibilities[index - 1]}
            isLast={!sessionDayVisibilities[index + 1]}
          />
        ))}
        {loading && daysLoading.map((day, index) => (
          <DatePill
            key={day}
            day={dayjs.unix(day)}
            isLoading={true}
            isFirst={!sessionDayVisibilities[index - 1]}
            isLast={!sessionDayVisibilities[index + 1]}
          />
        ))}
      </div>
    </div>
  )
}

const DatePill = ({ day, active, isLast, isFirst, isLoading = false }) => {
  const activePillStyle = css`
    background: #091454;
    border-radius: ${isFirst ? '16' : '0'}px ${isLast ? '16px 16px' : '0px 0px'} ${isFirst ? '16' : '0'}px;
    color: #fff;
    &:hover {
      color: #fff;
    }
  `
  const fadeAnimation = keyframes({
    '0%, 100%': css`opacity: 0.9;`,
    '50%': css`opacity: 0.5;`
  })

  const loadingStyle = css`
    color: ${NEUTRAL_100};
    pointer-events: none;

    animation: ${fadeAnimation} 1.5s ease-in-out infinite;
  `;

  const pillStyle = css`
    padding: 6px 16px;
    border-radius: 16px;
    transition: background-color .4s, border-radius .4s;
    ${active && activePillStyle}
    ${isLoading && loadingStyle}
  `

  return (
    <Nav.Item key={"nav-date-" + day.format('YYYY-MM-DD')} >
      <Nav.Link href={isLoading ? '#' : `#schedule-${day.format('YYYY-MM-DD')}`} css={pillStyle}>
        {day.format('ddd D')}
      </Nav.Link>
    </Nav.Item>
  )
}