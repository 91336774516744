import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

// Initial duration is in minutes, interval is in MS
export const useTimer = (initialDuration, intervalPeriod, onFinish = () => {}) => {
  const [endTime, setEndTime] = useState(null)
  const currentEndTime = useRef(endTime)
  currentEndTime.current = endTime

  const initialTime = dayjs.duration(initialDuration, 'minutes')
  const activeTimerRef = useRef(null)
  // timeRemaining is a dayjs duration object, because I enjoy causing myself confusion apparently
  // why didn't I make this in typescript, again?
  const [timeRemaining, setTimeRemaining] = useState(initialTime)

  const start = (timeRemainingOverride = dayjs.duration(initialDuration, 'minutes')) => {
    const newEndTime = dayjs().add(timeRemainingOverride)
    setEndTime(newEndTime)

    const secondsRemaining = Math.ceil(newEndTime.diff(dayjs()) / 1000)
    setTimeRemaining(dayjs.duration(secondsRemaining, 'seconds'))

    if (activeTimerRef.current === null) {
      const interval = setInterval(() => {
        const secondsRemaining = Math.ceil(currentEndTime.current.diff(dayjs()) / 1000)
        setTimeRemaining(dayjs.duration(secondsRemaining, 'seconds'))
        if (secondsRemaining <= 0) {
          disableTimer()
          onFinish()
        }
      }, intervalPeriod)
      activeTimerRef.current = interval
    }
  }
  useEffect(() => {
    return () => clearInterval(activeTimerRef.current)
  }, [])

  const disableTimer = () => {
    clearInterval(activeTimerRef.current)
    if (activeTimerRef.current !== null) {
      activeTimerRef.current = null
    }
    setEndTime(null)
  }

  const reset = () => {
    disableTimer()
    setTimeRemaining(initialTime)
  }

  const active = endTime !== null

  return { timeRemaining, active, start, reset }
}