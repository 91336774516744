/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect } from 'react';
import { useRequestHostTBDSessionAndBook } from '../../fetch/endpoints';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { BookSessionModalContent } from './BookSessionModalContent';

export function RequestSessionModal({ onHide, requestTime, onSuccess = () => {} }) {
  const { fetching: requestSessionLoading, error, result } = useAutomaticallyFetch(useRequestHostTBDSessionAndBook, { startTimestamp: requestTime })
  useEffect(() => {
    if (result !== null) { onSuccess() }
  }, [result])

  const event = result !== null ? { id: result.eventId, start: requestTime } : null

  return (
    <BookSessionModalContent event={event} onHide={onHide} loading={requestSessionLoading} error={error} />
  );
}