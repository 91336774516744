/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { useSendSegmentEvent } from "../wrappers/SegmentProvider";
import {  Text, TEXT_STYLES } from './flowComponents';
import { FC_BLUE, FC_LIGHTER_BLUE, NEUTRAL_50 } from '../emotionVariables';

export const BadgesSelector = ({ badges, selectedBadgeIds, setSelectedBadgeIds, showHeader = true, bigButtons = false }) => {

  const [searchTerm, setSearchTerm] = useState('');

  const sendSegmentEvent = useSendSegmentEvent()
  const badgeToggled = (badgeId) => {
    const togglingTo = !selectedBadgeIds.has(badgeId)
    sendSegmentEvent("Badge Membership Toggled", togglingTo)
    const newSelectedBadgeIds = new Set(selectedBadgeIds)
    if (togglingTo) {
      newSelectedBadgeIds.add(badgeId)
    } else {
      newSelectedBadgeIds.delete(badgeId)
    }
    setSelectedBadgeIds(newSelectedBadgeIds)
  }

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredBadges = badges.filter((badge) =>
    badge.name.toLowerCase().includes(searchTerm)
  )

  const pillOrButtonStyle = bigButtons ? css`
    border-radius: 16px;
    width: 194px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  ` : css`
    padding: 8px 16px 8px 12px;
    border-radius: 32px;
    border: 1px solid ${NEUTRAL_50};
  `
  const emojiStyle = bigButtons ? css`
    font-size: 32px;
  ` : css`
    font-size: 24px;
  `
  const selectedStyle = bigButtons ? css`
    font-weight: bold;
    color: ${FC_BLUE};
    border: 1px solid ${FC_BLUE};
  ` : css`
    background-color: ${FC_LIGHTER_BLUE};
    border-color: ${FC_BLUE};
    color: ${FC_BLUE};
    font-weight: bold;
  `

  return (
    <div css={css`margin-bottom: 36px;`}>
      {showHeader ?
      <>
        <Text customCss={css`margin-bottom: 12px;`} style={TEXT_STYLES.APP_H4}>Clubs</Text>
        <Text customCss={css`margin-bottom: 12px;`}>Pick as many as you identify or want to identify with. Don't see one that represents you? Send a request to <a href="mailto:help@flow.club" target="_blank" rel="noreferer noopener">help@flow.club</a></Text>
      </> : null }
      <input
        type="text"
        placeholder="Search clubs..."
        onChange={onSearchChange}
        value={searchTerm}
        css={css`
          padding: 8px;
          margin: 8px 0;
          border: 1px solid #D9D9D9;
          border-radius: 4px;
          margin: 16px 0;
        `}
      />
      <div css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px 12px;
        user-select: none;
        ${bigButtons ? css`justify-content: center;` : null}
      `}>
        {!filteredBadges.length && <Text style={TEXT_STYLES.BODY}>No clubs found<a href="mailto:help@flow.club" target="_blank" rel="noreferer noopener">Suggest a club</a></Text>}
        {filteredBadges.map(badge => {
          const selected = selectedBadgeIds.has(badge.id)
          return (
            <div key={badge.id} onClick={() => badgeToggled(badge.id)} css={css`
              background-color: white;
              color: black;
              transition: background-color 0.2s, color 0.2s, border-color 0.2s;
              ${pillOrButtonStyle};
              cursor: pointer;
              &:hover {
                background-color: ${FC_LIGHTER_BLUE};
                transition: color 0.2s, border-color 0.2s;
              }
              ${selected && selectedStyle}
              display: flex;
              gap: 8px;
            `}>
              <Text customCss={emojiStyle}>{badge.emoji}</Text>
              {bigButtons ?
                <Text
                  style={TEXT_STYLES.BODY_1}
                  customCss={css`margin-top: 8px;`}
                >
                  {badge.name}
                </Text>
              :
                <Text>{badge.name}</Text>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}