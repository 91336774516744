/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Checkmark } from '../../shared/components/icons';
import { toastError } from '../../components/utils/toaster';
import { FlowScoreButtons } from '../../components/FlowScoreButtons';
import { useDebounced } from '../../utils';
import { useAddSpaceTimerFlowScore } from '../../fetch/endpoints';

export const FlowScoreInput = ({ finishFlowScoreSubmittal, visitId }) => {
  const [flowScoreSubmitted, setFlowScoreSubmitted] = useState(false)

  const { performFetch: submitFlowScore } = useAddSpaceTimerFlowScore()

  const [completeSubmittalAfterDelay, cancelCompletion] = useDebounced(finishFlowScoreSubmittal, 5000)

  const onSubmitFlowScore = async ({ flowScore }) => {
    setFlowScoreSubmitted(true)
    completeSubmittalAfterDelay()
    const { success, error } = await submitFlowScore({ visitId, flowScore })
    if (!success) {
      setFlowScoreSubmitted(false)
      cancelCompletion()
      console.error(error)
      toastError({ message: "There was an error submitting your flow score. Please try again or contact members@flow.club and we'll get this resolved." })
    }
  };

  return (
    <>
      <div css={css`
        font-family: 'Sato';
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: black;
        margin-top: 20px;
        margin-bottom: 14px;
      `}>
        How close to flow were you?
      </div>
      <div css={css`width: 408px; max-width: 90%; display: flex; justify-content: center;`}>
        {!flowScoreSubmitted ?
          <FlowScoreButtons onSubmitFlowScore={onSubmitFlowScore} /> :
          <div css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            width: 44px;
            height: 44px;
            border-radius: 100%;
          `}>
            <Checkmark width={15} height={10}/>
          </div>
        }
      </div>
    </>
  )
}