/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text, TEXT_STYLES } from '../components/flowComponents';
import { NEUTRAL_80 } from '../emotionVariables';
import { getLimitedSeatsText } from "./LimitedSeats";
import { getEffectiveMaxAttendees, EMPTY_HOST_ID } from "./SessionUtils";

const LAST_MINUTE_CUTOFF = 2 * 60 * 60 * 1000

export const SessionInfo = ({ event, user, inviteTag, isHighlighted, guestPass }) => {
  const guests = (event.guests ?? []).filter(id => id !== EMPTY_HOST_ID)

  let userEventTags = [];
  if (user && user.tags && event.userTags) {
    userEventTags = [...user.tags.filter(x => new Set(event.userTags).has(x))]
  } else if (inviteTag && event.userTags && new Set(event.userTags).has(inviteTag)) {
    userEventTags = [inviteTag]
  }

  const effectiveMaxAttendees = getEffectiveMaxAttendees(event, user)

  const limitedSeats = Boolean(guests.length >= effectiveMaxAttendees - 2)
  const lastMinute = event.created && event.start.getTime() - event.created.toDate().getTime() < LAST_MINUTE_CUTOFF
  const guestSet = new Set(guests)
  const musicDescription = event.musicDescription !== undefined && event.musicDescription !== "" ? event.musicDescription : null

  const { chatOnly, hostGoalDescription, hostGoalEmoji, pomodoro, breakCheckIns } = event

  let emojiDescriptions = ''

  if (chatOnly) {
    emojiDescriptions += ' ⌨️ Chat-only (no verbal sharing)'
  }

  if (breakCheckIns) {
    emojiDescriptions += ' ✅ Check-in during breaks'
  }

  if (pomodoro) {
    emojiDescriptions += ' 🍅 Pomodoro'
  }

  if (isHighlighted || (guestPass && guestSet.has(guestPass.invitedByUser.id))) {
    emojiDescriptions += ` ${guestPass.invitedByName} is joining`
  }

  if (musicDescription !== null) {
    emojiDescriptions += ` 🎵 ${musicDescription}`
  }

  if (limitedSeats) {
    emojiDescriptions += getLimitedSeatsText({ guests: event.guests, maxAttendees: effectiveMaxAttendees, canceledSeatOpen: event.canceledSeatOpen })
  } else if (lastMinute) {
    emojiDescriptions += " ⚡️ Just added!"
  }

  if (hostGoalDescription !== undefined && hostGoalDescription !== "" && hostGoalEmoji !== undefined && hostGoalEmoji !== "") {
    emojiDescriptions += ` ${hostGoalEmoji} ${hostGoalDescription}`
  }

  if (emojiDescriptions === '') return ""

  return (
    <Text style={TEXT_STYLES.BODY_2} customCss={css`color: ${NEUTRAL_80}; margin-top: 24px; margin-bottom: 12px;`}>
      {emojiDescriptions}
    </Text>
  );
}