// TODO: move this into src/wrappers/

import { createContext, useContext, useEffect, useState } from "react"
import { db } from "./firebase"
import { UserContext } from "./UserProvider"
import { useQuery } from "./utils"
import { useFirestoreQuery } from "./firebase/helpers"
import { DEFAULT_INVITE_CODE } from "../functions/shared/constants"

export const InviteInfoContext = createContext({})

const getLocalStorageVariableName = (property) => `invite-info-${property}`
const getSingleLocalStorageValue = (property) => localStorage.getItem(getLocalStorageVariableName(property))
const setLocalStorageValues = (valuesObject) => Object.keys(valuesObject).forEach(property => {
  if (valuesObject[property] !== null) {
    localStorage.setItem(getLocalStorageVariableName(property), valuesObject[property])
  } else {
    localStorage.removeItem(getLocalStorageVariableName(property))
  }
})
const clearLocalStorageValues = (valuesObject) => Object.keys(valuesObject).forEach(property => localStorage.removeItem(getLocalStorageVariableName(property)))
const readLocalStorageValues = (properties) =>
  properties.reduce((values, property) => {
    values[property] = getSingleLocalStorageValue(property)
    return values
  }, {})

const isNewInfoRelativeToLocalStorage = (inviteInfo) => 
  Object.keys(inviteInfo).some(property =>
    inviteInfo[property] !== null &&
    inviteInfo[property] !== getSingleLocalStorageValue(property)
  )

const inviteInfoProperties = ["firstName", "lastName", "email", "inviteCode", "guestPassId", "greeting"]

export const InviteInfoWrapper = ({children}) => {
  const query = useQuery()
  const inviteInfo = inviteInfoProperties.reduce((values, property) => {
    values[property] = query.get(property);
    return values;
  }, {})

  // great named variables in history -- the query param data if there's anything new there, localstorage data otherwise
  const upToDateInviteInfo = isNewInfoRelativeToLocalStorage(inviteInfo) ? inviteInfo : readLocalStorageValues(inviteInfoProperties)

  const { user } = useContext(UserContext);

  const [loading, invitedBy, inviteError] = useValidateInviteCode(upToDateInviteInfo.inviteCode)
  const [ guestPass, guestPassLoading, error ] = useValidateGuestPass(upToDateInviteInfo.guestPassId)
  const { firstName, lastName, email, inviteCode, invitedByName } = guestPass
  const inviteInfoFromGuestPass = { firstName, lastName, email, inviteCode }
  // This is not great, but didn't want to mess with the InviteInfo stuff and didn't want to separate wrappers/contexts
  let inviteInfoContext
  if (upToDateInviteInfo.guestPassId) {
    inviteInfoContext = {
      ...upToDateInviteInfo,
      ...inviteInfoFromGuestPass,
      guestPass: guestPass,
      inviteValidationLoading: guestPassLoading,
      inviteValidationInvitedBy: invitedByName,
      inviteValidationError: error,
      userCanCreateUnauthedUser: email !== null && firstName !== null // just a little helper property
    }
  }
  else {
    inviteInfoContext = {
      ...upToDateInviteInfo,
      inviteValidationLoading: loading,
      inviteValidationInvitedBy: invitedBy,
      inviteValidationError: inviteError,
      userCanCreateUnauthedUser: upToDateInviteInfo.email !== null && upToDateInviteInfo.firstName !== null // just a little helper property
    }
  }
  useEffect(() => {
    if (user === null) {
      if (isNewInfoRelativeToLocalStorage(inviteInfo)) {
        setLocalStorageValues(inviteInfo)
      }
    } else if (user.inviteCodeUsed !== undefined) {
      clearLocalStorageValues(inviteInfo)
    }
  }, [...Object.values(inviteInfo), user])

  useEffect(() => {
    if (user === null) {
      if (upToDateInviteInfo.guestPassId && isNewInfoRelativeToLocalStorage(inviteInfoFromGuestPass)) {
        setLocalStorageValues(inviteInfoFromGuestPass)
      }
    } else if (user.inviteCodeUsed !== undefined) {
      clearLocalStorageValues(inviteInfo)
    }
  }, [...Object.values(inviteInfoFromGuestPass), user])

  inviteInfoContext.guestPassId = upToDateInviteInfo.guestPassId

  // hack: disable invite code reqs for now
  inviteInfoContext.inviteCode = inviteInfoContext.inviteCode ?? DEFAULT_INVITE_CODE
  inviteInfoContext.inviteValidationError = null

  inviteInfoContext.introContent = ((user === null || user.sessions < 1) && invitedBy && invitedBy.introContent !== undefined) ? invitedBy.introContent : null

  return (
    <InviteInfoContext.Provider value={inviteInfoContext}>
      {children}
    </InviteInfoContext.Provider>
  )
}

const useValidateInviteCode = (code) => {
  const [loading, setLoading] = useState(true);
  const [invitedBy, setInvitedBy] = useState(null);
  const [inviteError, setInviteError] = useState(null);
  // TODO: replace this with useValidateInviteCode()
  useEffect(() => {
    if (code) {
      db.collection('invites').doc(code)
        .get()
        .then((inviteDoc) => {
          const invite = inviteDoc.data();
          if (inviteDoc.exists) {
            setLoading(false);
            setInvitedBy(invite);
          } else {
            setLoading(false);
            setInvitedBy(null);
            setInviteError("Sorry, that doesn't appear to be a valid invite code.")
          }
        }).catch((error) => {
          setLoading(false);
          setInvitedBy(null);
          setInviteError("Sorry, that doesn't appear to be a valid invite code.")
        })
    } else {
      setLoading(false);
      setInvitedBy(null);
    }
  }, [code])

  return [loading, invitedBy, inviteError]
}

const useValidateGuestPass = (guestPassId) => {
  const [ guestPass, loading ] = useFirestoreQuery({
    functionalQuery: db => db.collection('invitedUsers').doc(guestPassId),
    fields: ["id", "firstName", "lastName", "email", "invitedByName", "inviteUsed", "invitedBy", "invitedByUser", "redeemed"],
    transform: (guestPass) => ({...guestPass, inviteCode: guestPass && guestPass.inviteUsed ? guestPass.inviteUsed.id : null})
  })
  const error = guestPass && (guestPass.redeemed || !guestPass.inviteCode) ? "Sorry, that guest pass is invalid or has already been redeemed." : null
  return [ guestPass, loading, error]
}