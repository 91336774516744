/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import '../Schedule.scss'
import dayjs from 'dayjs'

import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { UserContext } from '../../UserProvider'
import { useSessionDayVisibilities } from '../SessionUtils';
import { useQuery } from '../../utils';
import { FinishSignupBanner } from '../../shared/components/FinishSignupBanner';
import { HostBanner } from '../../components/hosts/host-banner'
import { InviteInfoContext } from '../../InviteInfoWrapper';
import { ONE_MINUTE } from '../../../functions/shared/constants';
import { useTimeFilters } from './useTimeFilters';
import { useDurationFilters } from './useDurationFilters';
import { useThemeFilters } from './useThemeFilters';
import { DatesAndHosts } from './DatesAndHosts';
import { EventsList } from './EventsList';
import { SegmentProvider } from '../../wrappers/SegmentProvider';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { useGetSchedulePage } from '../../fetch/endpoints';
import { getUserIsUnauthed, shouldShowSessionOnSchedule, sort, toTitleCase, getUserCanHostSessions } from '../../../functions/shared/helpers';
import { DefaultBanner } from '../../components/banners';
import { CalendarViewSchedule } from './CalendarViewSchedule';
import { FloatingCreateSessionButton } from './CreateSessionButton';


const getHostsOfUpcomingSessions = (events) => {
  if (events === null) {
    return []
  } else {
    const hostMap = new Map();
    events.forEach(event => {
      const { displayName } = event.hostUser;
      hostMap.set(displayName, event.isFavoriteHost);
    });

    const hosts = Array.from(hostMap.entries());
    hosts.sort((a, b) => a[0].localeCompare(b[0]));
    const favorites = hosts.filter(([_, isFavorite]) => isFavorite).map(([displayName]) => displayName);
    const remaining = hosts.filter(([_, isFavorite]) => !isFavorite).map(([displayName]) => displayName);
    return { favorites, remaining, all: hosts.map(([displayName]) => displayName) };
  }
}

const getPrivateGroups = (events) => {
  if (events === null) {
    return []
  } else {
    let privateGroups = new Set([])
    events.forEach(event => {
      if (event.visibility !== 'public') {
        privateGroups.add(event.visibility);
      }
    })
    return [...privateGroups]
  }
}

export const ScheduleContext = createContext(null);

const transformSchedulePageData = (result, showHiddenSessions) => ({
  ...result,
  sessions: result.sessions.filter(
    showHiddenSessions ?
      () => true :
      event => shouldShowSessionOnSchedule(event.userSessionStatus)
  )
})

export const SCHEDULE_VIEW_MODES = {
  LIST: 'LIST',
  CALENDAR: 'CALENDAR',
  RECOMMENDED: 'RECOMMENDED',
  COLLAPSED_DEFAULT: 'collapseTimeSlots',
  TINY_CARDS: 'tinySessionCards'
}

function Schedule() {
  const { user, userHasAdminPermissions } = useContext(UserContext);
  const { inviteCode, guestPass, inviteValidationInvitedBy: invitedBy, guestPassId, introContent: inviteIntroContent } = useContext(InviteInfoContext);
  // Use Derived State!
  const { host } = useParams()
  const query = useQuery()
  const invitedGroupFilter = invitedBy && invitedBy.filter || null
  const groupFilter = query.get('group') || invitedGroupFilter
  const [showHiddenSessions, setShowHiddenSessions] = useState(false)
  const upcomingSessionsContainerRef = useRef(null)
  const [collapseFilters, setCollapseFilters] = useState(false)

  const { result: initialSchedulePageData, fetching: initialLoading } = useAutomaticallyFetch(useGetSchedulePage, { inviteCode, guestPassId, limitEvents: 40 }, {
    transform: result => transformSchedulePageData(result, showHiddenSessions)
  })
  const { result: fullSchedulePageData, fetching: loading, performFetch } = useAutomaticallyFetch(useGetSchedulePage, { inviteCode, guestPassId }, {
    transform: result => transformSchedulePageData(result, showHiddenSessions),
    refetchEvery: ONE_MINUTE
  })
  const schedulePageData = fullSchedulePageData ?? initialSchedulePageData
  const { sessions: events, canCreateSessions } = schedulePageData ?? { sessions: [], canCreateSessions: false }
  const refetchSchedulePageData = () => performFetch({ inviteCode, guestPassId })


  const daysWithSessions = sort(Array.from(events.reduce((days, event) => {
    days.add(dayjs(event.start).startOf('day').unix())
    return days
  }, new Set())))
    .map(day => dayjs.unix(day))

  const hostsOfUpcomingSessions = getHostsOfUpcomingSessions(events)
  const privateGroups = getPrivateGroups(events)

  // legacy redirect from ?createSession=true -> /create
  const history = useHistory();
  const createSessionQueryParamSet = query.get('createSession') === 'true'
  useEffect(() => {
    if (createSessionQueryParamSet) {
      history.replace(`/upcoming/create/`)
    }
  }, [createSessionQueryParamSet])

  // legacy redirect
  const [sessionDayVisibilities, setDayVisibility] = useSessionDayVisibilities()

  const { eventsWithinActiveTimeFilters, timeFilterOptions, activeTimeFilters, setActiveTimeFilters } = useTimeFilters(events)

  const { eventsWithinActiveDurationFilters: eventsWithinActiveTimeAndDurationFilters, activeDurationFilters, setActiveDurationFilters } = useDurationFilters(eventsWithinActiveTimeFilters)

  const { eventsForActiveThemeFilters: eventsWithinActiveTimeDurationAndThemeFilters, themeFilterOptions, activeThemeFilters, setActiveThemeFilters } = useThemeFilters(eventsWithinActiveTimeAndDurationFilters)

  const hiddenEventsCount = events.length - eventsWithinActiveTimeDurationAndThemeFilters.length


  const mainLink = "/upcoming/"

  const [scheduleViewMode, setScheduleViewMode] = useState('LIST')
  // useEffect(() => {
  //   if (tinySessionCards) {
  //     setScheduleViewMode('tinySessionCards')
  //     return
  //   }
  // }, [welcomeType])
  const scheduleViewOptions = [
    {
      value: SCHEDULE_VIEW_MODES.LIST,
      name: "List",
      active: scheduleViewMode === SCHEDULE_VIEW_MODES.LIST
    },
    {
      value: SCHEDULE_VIEW_MODES.COLLAPSED,
      name: "Collapsed List",
      active: scheduleViewMode === SCHEDULE_VIEW_MODES.COLLAPSED
    },
    {
      value: SCHEDULE_VIEW_MODES.TINY_CARDS,
      name: "Tiny Cards",
      active: scheduleViewMode === SCHEDULE_VIEW_MODES.TINY_CARDS
    },
    {
      value: SCHEDULE_VIEW_MODES.CALENDAR,
      name: "Calendar",
      active: scheduleViewMode === SCHEDULE_VIEW_MODES.CALENDAR
    }
  ]
  const onScheduleViewModeSelected = (modeSelected) => {
    setScheduleViewMode(modeSelected)
  }
  const userCanHostSessions =
  (user !== null && getUserCanHostSessions(user))

  useEffect(() => {
    const upcomingSessionsContainer = upcomingSessionsContainerRef.current;
    let debounceTimeout;
  
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCollapseFilters(false);
        } else {
          debounceTimeout = setTimeout(() => {
            setCollapseFilters(true);
          }, 200);
        }
      });
    };
  
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '-62px 0px 0px 0px', // to account for the fixed navbar
      threshold: 0.1,
    });
  
    if (upcomingSessionsContainer) {
      observer.observe(upcomingSessionsContainer);
    }
  
    return () => {
      if (upcomingSessionsContainer) {
        observer.unobserve(upcomingSessionsContainer);
      }
      clearTimeout(debounceTimeout);
    };
  }, []);
  

  return (
    <SegmentProvider baseData={{
      activeTimeFilters,
      activeHostFilter: host ?? null,
      activeThemeFilters,
    }}>
      <div>
        <Helmet>
          <title>Upcoming Flow Club Sessions{(groupFilter && groupFilter !== 'all') ? ` for ${toTitleCase(groupFilter)}` : ""}{host ? (' with ' + host) : ''}</title>
          <meta name='robots' content='noindex, nofollow' />
        </Helmet>
        <div>
          {user !== null && getUserIsUnauthed(user) &&
            <FinishSignupBanner />
          }
          <HostBanner />
          {inviteIntroContent !== null && <InviteIntroContentBanner inviteIntroContent={inviteIntroContent} />}
          <Container ref={upcomingSessionsContainerRef}>
            <h1 className="schedule-h1 my-4 text-center">Upcoming Sessions{(groupFilter && groupFilter !== 'all') ? ` for ${toTitleCase(groupFilter)}` : ""}{host ? (' with ' + host) : ''}</h1>
          </Container>
          <div css={css`
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 62px;
            background: #fff;
            padding: 10px 0px;
            z-index: 1;
            border-bottom: 1px #000;
            box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
          `}>
            <DatesAndHosts
              daysWithSessions={daysWithSessions}
              groupFilter={groupFilter}
              invitedGroupFilter={invitedGroupFilter}
              privateGroups={privateGroups}
              mainLink={mainLink}
              selectedHost={host}
              hostsOfUpcomingSessions={hostsOfUpcomingSessions}
              sessionDayVisibilities={sessionDayVisibilities}
              timeFilterOptions={timeFilterOptions}
              activeTimeFilters={activeTimeFilters}
              setActiveTimeFilters={setActiveTimeFilters}
              themeFilterOptions={themeFilterOptions}
              activeThemeFilters={activeThemeFilters}
              setActiveThemeFilters={setActiveThemeFilters}
              activeDurationFilters={activeDurationFilters}
              setActiveDurationFilters={setActiveDurationFilters}
              hiddenEventsCount={hiddenEventsCount}
              showHiddenSessions={showHiddenSessions}
              setShowHiddenSessions={setShowHiddenSessions}
              loading={initialLoading || loading}
              collapseFilters={collapseFilters}
              setCollapseFilters={setCollapseFilters}
            />
          </div>
          <div className="schedule-background">
            <ScheduleContext.Provider value={{ refetchSchedulePageData, scheduleViewMode }}>
              {scheduleViewMode === SCHEDULE_VIEW_MODES.CALENDAR ?
                <CalendarViewSchedule
                  events={eventsWithinActiveTimeDurationAndThemeFilters}
                /> :
                <EventsList
                  user={user}
                  events={eventsWithinActiveTimeDurationAndThemeFilters}
                  initialLoading={initialLoading}
                  additionalLoading={loading}
                  selectedHost={host}
                  groupFilter={groupFilter}
                  code={inviteCode}
                  invitedBy={invitedBy}
                  guestPass={guestPass}
                  setDayVisibility={setDayVisibility}
                  numCurrentlyWorking={schedulePageData !== null ? schedulePageData.numCurrentlyWorking : null}
                  numCurrentSessions={schedulePageData !== null ? schedulePageData.numCurrentSessions : null}
                  canCreateSessions={canCreateSessions}
                  activeThemeFilters={activeThemeFilters}
                  activeDurationFilters={activeDurationFilters}
                />
              }
            </ScheduleContext.Provider>
          </div>
        </div>
        <FloatingCreateSessionButton userCanHostSessions={userCanHostSessions} />
      </div>
    </SegmentProvider>
  );
}

export default Schedule


const InviteIntroContentBanner = ({ inviteIntroContent }) => {
  return (
    <DefaultBanner>
      <b>{inviteIntroContent.header}</b>
    </DefaultBanner>
  )
}