/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import './plans.scss'

import {
  useContext,
} from 'react'
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { UserContext } from '../UserProvider'
import { PlansPricing } from './PlansPricing'
import { PlansSessionCard } from './PlansSessionCard'
import { Logos } from './Logos'
import { useEventsWithParticipantData, eventWithParsedDates } from '../event-utils';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { LinkStyledText } from '../components/flowComponents';
import { useCompoundFirestoreSubscribe } from '../firebase/helpers';
import { arrObjectsToObject } from '../../functions/shared/helpers';
import { QuotesSection, QUOTE_TYPES } from '../Onboarding/QuotesSidebar'
import { FC_BLUE, FC_DARK_BLUE } from "../emotionVariables";


export const Plans = () => {
  const { user } = useContext(UserContext)

  let [completedSessions, completedSessionsLoading] = useEventsWithParticipantData({
    functionalQuery: db =>
      db.collection('participants')
      .where('userId', '==', user.uid)
      .where('status', '==', 'completed')
      .orderBy("start", "desc"),
    fields: ["eventId"]
    },
    ["start", "host", "title", "music", "musicLink", "hostId"]
  )

  const hostUserIds = completedSessions.map(event => event.hostId)
  const [hostUsers, hostUsersLoading] = useCompoundFirestoreSubscribe('users', hostUserIds, ["id", "displayName", "image64", "image100"])
  const hostUsersById = arrObjectsToObject(hostUsers)

  if (completedSessions !== null) {
    completedSessions= completedSessions
      .map(eventWithParsedDates)
      .map(event => ({ ...event, hostUser: hostUsersById[event.hostId] }))
      .filter(event => event.hostUser !== undefined)
  }

  return (
    <SegmentProvider eventLabel="Plans Page">
      <div>
        <PlansPricing location="Top" />
        <Logos />
        {user !== null &&
        <>
        <section className="plans-section section-unlimited">
          <div className="section-header">
            <h2>Go unlimited with membership</h2>
            <p>Unlimited sessions & curated playlists to drop into flow anytime, anywhere.</p>
          </div>
          {!hostUsersLoading && !completedSessionsLoading && completedSessions !== null ?
            <div className="session-cards">
              {completedSessions.map((session, i) => (
                <PlansSessionCard
                    key={i}
                    user={user}
                    event={session}
                />
              ))}
            </div>
          : ''}
        </section>
        <UserAchievements />
        </>
        }
        <PlansFAQ />

        <PlansPricing showDetail={false} location="After FAQ" />

        <CommunityAchievements />

        <QuotesSection quoteType={QUOTE_TYPES.MEDIA} />
        
        <PlansPricing showDetail={false} location="After Media Quotes" />

        <IntentionalAction />
        <QuotesSection quoteType={QUOTE_TYPES.USER} />

        <PlansPricing showDetail={false} location="After User Quotes" />
      </div>
    </SegmentProvider>
  )
}

const IntentionalAction = () => {
  return (
    <Container css={css`
      text-align: center;
      max-width: 800px;
      margin: 2rem auto;
    `}>
      <h1 css={css`
        color: ${FC_DARK_BLUE};
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      `}>
        The Power of Intentional Action
      </h1>
      <p css={css`
        font-size: 1.2rem;
        margin-bottom: 2rem;
      `}>
        Flow Club helps align intention with action,
        <br />
        and it <em>really</em> works for our thousands of members.
      </p>
      <Row css={css`
        justify-content: space-between;
      `}>
        {[
          { percentage: '98%', text: 'members report professional or academic improvement' },
          { percentage: '91%', text: 'members report increased ability to meet personal obligations' },
          { percentage: '97%', text: 'members find it easier to start difficult tasks or projects' }
        ].map((item, index) => (
          <Col key={index} xs={12} md={4} css={css`
            margin-bottom: 1.5rem;
          `}>
            <h2 css={css`
              color: ${FC_BLUE};
              font-size: 3rem;
              font-weight: bold;
              margin-bottom: 0.5rem;
            `}>
              {item.percentage}
            </h2>
            <p css={css`
              font-size: 1rem;
              line-height: 1.4;
            `}>
              {item.text.split(' ').map((word, i) => 
                i === 0 || i === 1 ? <span key={i}>{word} </span> :
                <span key={i} css={css`font-style: italic;`}>{word} </span>
              )}
            </p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const UserAchievements = () => {
  const { user } = useContext(UserContext)
  const sessions = user ? user.sessions : 7
  const computedFlowScore = user ? Math.round(user.totalFlowScore / user.scoredSessions) : NaN
  const avgFlowScore = isNaN(computedFlowScore) ? 9 : computedFlowScore
  const hours = user ? Math.round(user.minutes / 60) : 8
  return (
    user && user.sessions > 0 ?
    <section className="plans-section section-achievements">
      <div className="section-header">
          <h2>What you've accomplished with Flow Club</h2>
          <p>Way to flow with the community</p>
      </div>
      <div className="data-pills">
          <div className="data-pill">
              <div className="data-pill-num">
                  {sessions}
              </div>
              <div className="data-pill-label">sessions</div>
          </div>
          <div className="data-pill">
              <div className="data-pill-num">
                  {avgFlowScore}
              </div>
              <div className="data-pill-label">avg flow</div>
          </div>
          <div className="data-pill">
              <div className="data-pill-num">
                  {hours}
              </div>
              <div className="data-pill-label">hours</div>
          </div>
      </div>
    </section>
    :
    <section className="plans-section">
      <div className="section-header">
        {user && user.trialEnd.toMillis() < Date.now() ?
        <>
        <h2>Didn't get a chance to try out Flow Club?</h2>
        <p>It doesn't look like you completed any Flow Club sessions.
        <br />
        <Link to="/trial-extension">See if you're eligible for a trial extension</Link>
        </p>
        </>
        :
        <>

        <h2>You haven't been to a session yet!</h2>
        <Link to="/upcoming">See the schedule, book a session, and see for yourself</Link>
        </>
        }
      </div>
    </section>
  )
}

const CommunityAchievements = () => {
  return (
    <section className="plans-section section-community">
    <h2 className="section-header section-header-whiteout">Flow Club <br />
    <span className="dark-blue">Community</span></h2>
    <div className="section-community-content">
      <div className="data-pill data-pill-hosts">
        <div className="data-pill-num">900+</div>
        <div className="data-pill-label">hosts</div>
      </div>

      <img className="data-avatar data-avatar-top-left" alt="" />

      <div className="data-pill data-pill-countries">
        <div className="data-pill-num">40</div>
        <div className="data-pill-label">countries</div>
      </div>

      <img className="data-avatar data-avatar-mid" alt="" />

      <div className="data-pill data-pill-sessions">
        <div className="data-pill-num">2,000+</div>
        <div className="data-pill-label">sessions every week</div>
      </div>

      <img className="data-avatar data-avatar-bottom-right" alt="" />
      <img className="data-avatar data-avatar-bottom-left" alt="" />
    </div>
  </section>
  )
}

const PlansFAQ = () => {
  const sendSegmentEvent = useSendSegmentEvent()
  return (
    <Container className="plans-section section-faq">
      <Row>
        <Col><h2 className="section-header">Frequently Asked Questions</h2></Col>
      </Row>
      <Row>
        <Col xl={4} md={6} sm={6} xs={12}>
          <h4>How can I get my employer to pay for this?</h4>
          <p>
          Employers love Flow Club. We have experience working with employers to pay for or approve Flow Club as a perk or benefit. If that's something you're interested in,&nbsp;
          <LinkStyledText onClick={() => {
            sendSegmentEvent("Visited How to expense Flow Club Notion")
            window.open("https://www.notion.so/flowclub/How-to-get-Flow-Club-expensed-by-your-employer-674a236c21c446eda00e813736f4d685")
          }}>
          check out our guide to getting Flow Club expensed
          </LinkStyledText>&nbsp;
          or&nbsp;
          <LinkStyledText onClick={() => {
            sendSegmentEvent("Clicked email for expense help")
            window.open("mailto:members@flow.club?subject=Help%20my%20company%20pay%20for%20Flow%20Club")
          }}>
          email us at members@flow.club
          </LinkStyledText>&nbsp;
          to tell us your company's specific requirements and how we can help.
          </p>
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <h4>Am I locked into an agreement?</h4>
          <p>
            No, the membership runs on standard monthly and yearly subscription plans.
            You can cancel your membership anytime with just a few clicks,
            and retain access until the end of the billing cycle.
          </p>
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <h4>What payment methods do you accept?</h4>
          <p>
            Payments are powered by Stripe, which means we accept
            all major credit cards. If your company's finance department
            would rather pay through an invoice, simply&nbsp;
            <LinkStyledText onClick={() => {
              sendSegmentEvent("Clicked email to pay by invoice")
              window.open("mailto:members@flow.club?subject=Pay%20by%20invoice")
             }}>contact us at members@flow.club
            </LinkStyledText>&nbsp;
            and we'll help you out!
          </p>
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <h4>Do hosts get discounts?</h4>
          <p>
          Yes, you will become eligible to be a host after attending 10 sessions, and hosts can discount their membership up to 50% for hosting a certain number of sessions.&nbsp;
          <LinkStyledText onClick={() => {
            sendSegmentEvent("Visited host discount Notion")
            window.open("https://www.notion.so/flowclub/Host-FAQ-3ada1ab2a7f84d7f9826dc4d6e1a570b#c8c866031ad8413292610aa64acea12f")
          }}>
            More details on the program here
          </LinkStyledText>
          </p>
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <h4>Do you offer any student discounts?</h4>
          <p>
            Yes! If you are currently a full-time student,&nbsp;
            <LinkStyledText onClick={() => {
              sendSegmentEvent("Clicked on link to Notion for Student discount")
              window.open("https://flowclub.notion.site/Flow-Club-Student-Discount-0441f57ce9054511a2e94fc8a0ce6960")
             }}>please visit this page for more details and fill out the form to apply for 50% off your monthly or yearly subscription.
            </LinkStyledText>
          </p>
        </Col>
        <Col xl={4} md={6} sm={6} xs={12}>
          <h4>What about non-profit discounts?</h4>
          <p>
            Yes! If you currently work at a non-profit organization,&nbsp;
            <LinkStyledText onClick={() => {
              sendSegmentEvent("Clicked to email for non-profit discount")
              window.open("mailto:members@flow.club?subject=I%20work%20at%20a%20non-profit%20and%20want%20to%20sign%20up%20for%20memership!")
             }}>please email members@flow.club
            </LinkStyledText>&nbsp;
            with the name of the non-profit you work at along with your LinkedIn profile or another link so we can verify, and we'll send you a discount code for 50% off your monthly or yearly membership.
          </p>
        </Col>
      </Row>
    </Container>
  )
}