/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { BUTTON_STYLES, FlowButton, LightGrayText, LinkStyledText } from '../components/flowComponents';
import { FC_BLUE, FC_DARK_BLUE, FC_GREEN, FC_RED } from '../emotionVariables';
import { UserContext } from '../UserProvider';
import { getSessionDisplayTimeAndDate, getSessionIsInProgress } from '../Sessions/SessionUtils';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { Link } from 'react-router-dom';
import { useBookSessionWrapper } from '../Sessions/modals/BookSessionModal';
import { toastError, toastSuccess } from './utils/toaster';
import { getImageForHost } from '../../functions/shared/helpers';

// copypasted from SessionPane.js and very slightly changed because I love bad code
// Also this has no relation to the session-card/session-card.jsx SessionCard. There's absolutely nothing confusing going on here at all.
export const SquareIshSessionCard = ({ session, sessionIsBooked = true, responsiveWidth = false, completed = false, participant = null, flowScoreFn }) => {

  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const { timeToDisplay, dateToDisplay } = getSessionDisplayTimeAndDate(session, user)
  const sessionHappeningNow = getSessionIsInProgress(session)

  // EW
  const hostName = session.hostUser.displayName !== undefined ? session.hostUser.displayName : session.host

  const { bookSession, bookingSession } = useBookSessionWrapper()
  const [sessionBookedThroughCard, setSessionBookedThroughCard] = useState(false)

  // variable names continue to be my greatest strength
  const sessionIsBookedLocalState = sessionIsBooked || sessionBookedThroughCard
  const sessionCardClicked = async () => {
    sendSegmentEvent(`${completed ? 'Completed' : 'Upcoming'} Session Card Clicked`, { sessionId: session.id, clickTriggeredBooking: !sessionIsBookedLocalState })

    if (!sessionIsBookedLocalState) {
      const { success, error } = await bookSession({ id: session.id })
      if (success) {
        toastSuccess({ message: "Successfully booked! 🎉" })
        setSessionBookedThroughCard(true)
      } else {
        toastError({ message: error })
      }
    }
  }

  const linkDestination = completed ? '/profile/completed-sessions/' : 
    (sessionHappeningNow ? '/s/' : `/session/${session.id}`)
  const WrappingComponent = completed && participant && !participant.flowScore && flowScoreFn !== undefined ?
    ({ children }) => <LinkStyledText onClick={() => flowScoreFn()}>{children}</LinkStyledText> :
      (sessionIsBookedLocalState ?
      ({ children }) => <Link to={linkDestination} style={{ textDecoration: 'none' }}>{children}</Link> :
      ({ children }) => children)

  return (
    <WrappingComponent>
      <div onClick={sessionCardClicked} css={css`
        ${!responsiveWidth && css`width: 340px;`}
        padding: ${responsiveWidth ? 24 : 32}px;
        border: 1px solid #E4E4E4;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;

        transition: transform .5s cubic-bezier(.39,1.99,.69,1.01);
        &:hover, &:active {
          transform: scale(1.05);
        }
      `}>
        <div css={css`
          font-family: 'Sato';
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: ${ completed ? FC_DARK_BLUE : sessionHappeningNow ? FC_GREEN : FC_BLUE};

          margin-bottom: 20px;
        `}>
          {completed ? 'Completed' : sessionIsBookedLocalState ? 'Booked' : sessionHappeningNow ? 'Happening Now' : 'Upcoming'}
        </div>
        <LightGrayText customCss={css`
          font-family: 'Sato';
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;

          margin-bottom: 12px;
        `}>
          {timeToDisplay}
        </LightGrayText>
        <LightGrayText customCss={css`
          font-family: 'Sato';
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          margin-bottom: 32px;
        `}>
          {dateToDisplay}
        </LightGrayText>
        <div css={css`display: flex; align-items: center; margin-bottom: 32px;`}>
          <img
            css={css`
              width: ${48}px;
              height: ${48}px;
              border-radius: 50%;
              
              margin-right: 12px;
            `}
            src={getImageForHost(session.hostUser, 'image64')}
            alt={hostName}
          />
          <div>
            <LightGrayText customCss={css`
              font-weight: 500;
              font-size: 10px;
              line-height: 16px;
              letter-spacing: 1.5px;
              text-transform: uppercase;
              text-align: start;
            `}>Host</LightGrayText>
            <LightGrayText customCss={css`
              line-height: 24px;
              letter-spacing: 0.5px;
              text-align: start;
            `}>{hostName}</LightGrayText>
          </div>
        </div>
        <FlowButton loading={bookingSession} buttonStyle={BUTTON_STYLES.OUTLINE_DARK} customCss={css`
          pointer-events: none;
          ${completed && participant && !participant.flowScore && css`color: ${FC_RED}; border-color: ${FC_RED};`}
        `}>
          {completed && participant ? (participant.flowScore ? `Flow Score: ${participant.flowScore}` : 'Missing Flow Score') : sessionIsBookedLocalState ? (!sessionHappeningNow ? 'View Details' : 'Join') : 'Book'}
        </FlowButton>
      </div>
    </WrappingComponent>
  )
}