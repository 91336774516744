/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_LIGHTER_BLUE } from '../emotionVariables';
import LoadingDots from '../images/loading-dots.gif';

export const MainLoading = () => {
  return (
    <div css={css`
      display: flex;
      width: 100vw;
      height: 100vh;
      background-color: ${FC_LIGHTER_BLUE};
      justify-content: center;
      align-items: center;
    `}>
      <img width={180} height={180} src={LoadingDots} alt="App Loading Animated Dots" />
    </div>
  );
}