import dayjs from 'dayjs';
import { ONE_MINUTE } from '../../functions/shared/constants';

export const parseSessionRecommendations = (recommendations) => {
  const userTimezone = dayjs.tz.guess()
  const parsedRecs = recommendations.map(recommendation => {
    const recommendationStartDate = dayjs(recommendation.object.start).tz(userTimezone)
    const startMinutes = recommendationStartDate.hour() * 60 + recommendationStartDate.minute()
    const parsedData = {
      type: recommendation.type,
      startMinutes,
      endMinutes: startMinutes + (recommendation.object.end - recommendation.object.start) / ONE_MINUTE,
      startTimestamp: recommendation.object.start,
      endTimestamp: recommendation.object.end,
      id: recommendation.object.id
    }

    if (recommendation.type === 'session') {
      const { title, hostName } = recommendation.object
      Object.assign(parsedData, { title, description: hostName })
    } else {
      Object.assign(parsedData, { title: "60 min Flow Club", description: "Host TBD" })
    }

    return parsedData
  })

  return parsedRecs
}