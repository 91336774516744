import {
  Button,
  Modal,
} from 'react-bootstrap'


export const SignupNoBookingWelcomeModal = ({ onHide }) => {
  return (
    <Modal
      show
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Welcome to Flow Club!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <p>You are now part of a community of thousands of founders, builders, creatives, and operators who value
            improving mental fitness, feeling fulfilled at work and finding more time for life.</p>
          <p>Your free trial starts today. Join a session to experience Flow Club for yourself!</p>
        </>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>

    </Modal>
  );
}