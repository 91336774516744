/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Modal, TabbedInterface } from '../../components/flowComponents';
import { NEUTRAL_40 } from '../../emotionVariables';
import { useSetUserPreferences } from '../../fetch/endpoints';
import { usePreferences } from '../../UserProvider';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { FlowClubRadioHostControls } from './FlowClubRadioHostControls';
import { ScreensSharedMusicHelp } from './ScreensSharedMusicHelp';

const SHARING_MODAL_TABS = [
  { title: "My Music", Component: ScreensSharedMusicHelp, id: 'mymusic' },
  { title: "Flow Club Radio", Component: FlowClubRadioHostControls, id: 'flowclubradio' },
]

export const MusicSharingModal = ({ onHide }) => {
  const tabs = SHARING_MODAL_TABS.map(({ title, Component, id }) => ({ title, Content: <Component onHide={onHide} key={id} /> }))

  const { musicSharingDefaultTab } = usePreferences()
  const initialTabIndex = musicSharingDefaultTab !== undefined ? (SHARING_MODAL_TABS.findIndex(tab => tab.id === musicSharingDefaultTab) ?? 0) : 0
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex)


  const { performFetch: setUserPreferences } = useSetUserPreferences()

  const sendSegmentEvent = useSendSegmentEvent()
  const tabClicked = tabIndex => {
    sendSegmentEvent("Music Sharing Tab Selected", { tabTitle: tabs[tabIndex].title })
    setActiveTabIndex(tabIndex)
    setUserPreferences({ newPreferenceData: { musicSharingDefaultTab: SHARING_MODAL_TABS[tabIndex].id } })
  }

  return (
    <Modal width={500} onClose={onHide}>
      <div css={css`
        padding: 32px 24px 0px 24px;
      `}>
        <TabbedInterface tabs={tabs} activeTabIndex={activeTabIndex} setActiveTabIndex={tabClicked} customHeaderCss={css`text-align: center; justify-content: center;`} />
      </div>
    </Modal>
  );
}