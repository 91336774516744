/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { LoadingIndicator } from '../../components/flowComponents';

import { EventCard } from '../EventCard'
import { useQuery } from '../../utils';

import { useGetUpcomingEvents } from '../../fetch/endpoints';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const EmbedSessions = () => {
  const query = useQuery()
  const theme = query.get('theme', 'Classic')
  const source = query.get('source')
  const sendSegmentEvent = useSendSegmentEvent()
  const { result: eventData, fetching: loading } = useAutomaticallyFetch(
    useGetUpcomingEvents,
    { limitEvents: 5, theme },
  )

  return (
    <div css={css`
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 10px;
    `}>
      {loading || !eventData ? 
      <LoadingIndicator /> :
      eventData.sessions.length > 0 ? 
      <div>
        <h3 css={css`display: flex; align-items: center; justify-content: center;`}>Upcoming Sessions</h3>
        {eventData.sessions.map((event, index) => <EventCard
          key={event.id}
          showDate={true}
          forceMobileView={true}
          isFirst={index === 0}
          isLast={index === eventData.sessions.length - 1}
          event={event}
          challengeGroupMembers={[]}
          hideButton={true}
          embed={true}
          onSessionClick={() => sendSegmentEvent('Clicked on session from embed', { theme, source, eventId: event.id })}
        />)}
      </div> : "No upcoming sessions"}
    </div>
  )
}

export default EmbedSessions