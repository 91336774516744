/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  useContext,
} from "react";
import {
  Card,
  CardDeck,
  Container,
} from 'react-bootstrap';
import { getUserAverageFlowScore, getUserHoursOfFlow } from '../../../functions/shared/helpers';
import { UserContext } from '../../UserProvider';

export const Stats = () => {
  const { user } = useContext(UserContext)
  
  return (
    <Container>
      <CardDeck css={css`margin-bottom: 12px;`}>
        <Card className="text-center p-3 border-0">
          <Card.Text>
            Sessions Attended
          </Card.Text>
          <Card.Title>
            <h5>{user.sessions && user.sessions > 0 ? user.sessions : '-'}</h5>
          </Card.Title>
        </Card>
        <Card className="text-center p-3 border-0">
          <Card.Text>
            Hours of Flow
          </Card.Text>
          <Card.Title>
            <h5>{getUserHoursOfFlow(user)}</h5>
          </Card.Title>
        </Card>
        <Card className="text-center p-3 border-0">
          <Card.Text>
            Average Flow Score
          </Card.Text>
          <Card.Title>
            <h5>{getUserAverageFlowScore(user)}</h5>
          </Card.Title>
        </Card>
      </CardDeck>
      {user.hostedSessions && user.hostedSessions > 1 ?
        <CardDeck className="mt-1">
          <Card className="text-center p-3 border-0">
            <Card.Text>
              Sessions Hosted
            </Card.Text>
            <Card.Title>
              <h5>{user.hostedSessions}</h5>
            </Card.Title>
          </Card>
          <Card className="text-center p-3 border-0">
            <Card.Text>
              Hours of Flow Enabled
            </Card.Text>
            <Card.Title>
              <h5>{Math.round((user.hostedMinutes ?? 0) / 60)}</h5>
            </Card.Title>
          </Card>
          <Card className="text-center p-3 border-0">
            <Card.Text>
              Thank you for holding space and building community!
            </Card.Text>
          </Card>
        </CardDeck>
        :
        ""}
    </Container>
  )
}