/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { BUTTON_STYLES, FlowButton, LoadingIndicator, Text } from '../../components/flowComponents';
import { toastError } from '../../components/utils/toaster';
import EventContext from '../../EventContext';
import { useGetActiveHostedMusicPlaylists, useToggleSessionHostedMusicPlayback } from '../../fetch/endpoints';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { useAutomaticallyFetch } from '../../fetch/helpers';

export const FlowClubRadioHostControls = ({ onHide }) => {
  const { performFetch: togglePlayback, fetching } = useToggleSessionHostedMusicPlayback()

  const { result: activePlaylists } = useAutomaticallyFetch(useGetActiveHostedMusicPlaylists)

  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null)
  const { event, refetchEventData } = useContext(EventContext)
  const sendSegmentEvent = useSendSegmentEvent()

  const musicButtonClicked = async () => {
    sendSegmentEvent("Start Radio Clicked")
    const { success, error } = await togglePlayback({ eventId: event.id, togglingTo: true, playlistId: selectedPlaylistId })
    if (success) {
      refetchEventData()
      onHide()
    } else {
      toastError({ message: error })
    }
  }
  return (
    <div css={css`display: flex; flex-direction: column; align-items: center; padding-bottom: 48px;`}>
      <div css={css`
          display: flex;
          flex-direction: column;
          width: 220px;
          padding: 11px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          margin-bottom: 48px;
      `}>
      <FlowButton key="random" onClick={() => setSelectedPlaylistId(null)} buttonStyle={selectedPlaylistId === null ? BUTTON_STYLES.SECONDARY : BUTTON_STYLES.OUTLINE_SECONDARY} fillAvailableWidth>
        Pick for me
      </FlowButton>
      {activePlaylists && activePlaylists.length > 0 ? (
        activePlaylists.map(({name, id}) => (
          <FlowButton key={id} onClick={() => setSelectedPlaylistId(id)} buttonStyle={selectedPlaylistId === id ? BUTTON_STYLES.SECONDARY : BUTTON_STYLES.OUTLINE_SECONDARY} fillAvailableWidth>
            {name}
          </FlowButton>
        ))
      ) : <LoadingIndicator />}
      </div>
      <FlowButton loading={fetching} onClick={musicButtonClicked}>Start Radio</FlowButton>
    </div>
  )
}