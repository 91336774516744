import { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import { useQuery } from '../utils';
import { useHomeScreenVisibility } from '../HomeOnboarding/HomeHome';

const welcomeTypes = [
  // 'home',
  'intentionOnboarding',
  //'score3Sessions', // Figure out what to do to blend this with home and intentions
  //'timeOnboarding' // Stop showing this to new users in favor of testing intention onboarding, but we still have some users who have this welcome type
  //'tinySessionCards' // TODO David, 2023-06-05 FIX ON MOBILE BEFORE WE ENABLE THIS
]
const pickWelcomeType = () => welcomeTypes[Math.floor(Math.random() * welcomeTypes.length)]

export const useWelcomeRedirect = () => {
  const location = useLocation()
  const history = useHistory()
  const { user, preSignupUserData, setPreSignupUserData } = useContext(UserContext)

  let { welcomeType } = user ?? preSignupUserData

  // Paths that we don't want to redirect to welcome for even if they aren't logged in
  const publicPaths = new Set(['/plans/'])
  const homeScreenVisible = useHomeScreenVisibility()

  const query = useQuery();
  const skipWelcome = query.get('skip-welcome') === "true"

  useEffect(() => {
    if (!publicPaths.has(location.pathname)) {
      if (welcomeType === undefined) {
        welcomeType = skipWelcome ? 'none' : pickWelcomeType()
        setPreSignupUserData({ welcomeType })
      }

      if (welcomeType !== 'none' && location.pathname === '/' && homeScreenVisible) {
        if (welcomeType === 'home') {
          history.push('/home-onboarding/')
        } else {
          history.push('/intention-onboarding/')
          return
        }
      }
    }
  }, [user, location.pathname])
}