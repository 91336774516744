/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { useContext, useEffect, useMemo} from 'react';
import EventContext from '../../EventContext';
import Icon, { TYPE_NO_PHONE } from '../../daily/daily-components/Icon/Icon';
import { FC_DARK_BLUE, FC_LIGHTER_BLUE } from '../../emotionVariables';
import { LoadingIndicator, Modal } from '../../components/flowComponents';
import { LinkStyledText, Text, TEXT_STYLES } from '../../components/flowComponents';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { useModal } from '../../wrappers/MagnificentlyMunificentModalManager';
import { baseURL } from '../../firebase';
import { Checkmark } from '../../shared/components/icons';
import { ONE_SECOND } from '../../../functions/shared/constants';
import { QRCodeSVG } from 'qrcode.react';

const isPhoneFreeActive = (phoneFreeLastActive) => {
  return phoneFreeLastActive && phoneFreeLastActive.toMillis()>= Date.now() - (ONE_SECOND * 35)
}

const getPhoneFreeStatus = ({ participant }) => {
  const { phoneFreeOpened, phoneFreeLastActive, phoneFreeStarted } = participant
  return phoneFreeStarted ? (
    isPhoneFreeActive(phoneFreeLastActive) ?
    "Currently Phone Free!" :
    "Re-activate Phone Free on your phone") :
    phoneFreeOpened ?
    "Synced with your Phone!" :
    "Activate Phone Free"
}

const DoNotDisturbModal = ({ onHide, participant }) => {
  const { id, phoneFreeOpened } = participant
  const text = getPhoneFreeStatus({ participant })
  return (
    <Modal width={500} onClose={onHide}>
      <div css={css`
        padding: 32px 24px 12px 24px;
      `}>
        <Text style={TEXT_STYLES.APP_H3} customCss={css`margin-bottom: 24px;`}>
          Turn on Phone Free
        </Text>
        <div css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}>
          <Text>Scan QR code or visit<br/>
          <a href={`${baseURL}/dnd/${id}`} target="_blank" rel="noreferrer noopener">{baseURL}/dnd/{id}</a><br/>
          on your phone to activate Phone Free</Text>
          <QRCodeSVG
            value={`${baseURL}/dnd/${id}`}
            size={200}
            bgColor={"#ffffff"}
            fgColor={FC_DARK_BLUE}
            level={"L"}
            includeMargin={false}
            imageSettings={{
              src: "https://in.flow.club/favicon-32x32.png",
              x: undefined,
              y: undefined,
              height: 32,
              width: 32,
              excavate: true,
            }}
          />
          { phoneFreeOpened ?
            <div>
              <Text>{text}</Text>
              <Checkmark width={30} height={20} />
            </div>
            :
            <div>
              <Text>Waiting for you to open page on your phone...</Text><br/>
              <LoadingIndicator />
            </div>
          }
      </div>
    </div>
    </Modal>
  );
}

export const DoNotDisturb = ({ deviceMenuStyle = false, participant }) => {
  const { event } = useContext(EventContext)
  const { eventTypeId } = event
  const { setActiveModal } = useModal()
  const sendSegmentEvent = useSendSegmentEvent()
  const { phoneFreeOpened, phoneFreeStarted } = participant
  const onDoNotDisturbClicked = () => {
    sendSegmentEvent("Do Not Disturb Button Clicked")
    setActiveModal(({ onHide }) => (<DoNotDisturbModal onHide={onHide} participant={participant} />))
  }

  const text = getPhoneFreeStatus({ participant })
  const phoneFreeCurrentlyActive = isPhoneFreeActive(participant.phoneFreeLastActive)

  useEffect(() => {
    if (phoneFreeOpened && !phoneFreeStarted) {
      setActiveModal(({ onHide }) => (<DoNotDisturbModal onHide={onHide} participant={participant} />))
    }
  }, [phoneFreeOpened, phoneFreeStarted])
  if (eventTypeId !== 'Phone Free') {
    return null;
  }

  const twinkling = keyframes`
    0%, 100% { box-shadow: 0 0 10px rgba(255, 255, 128, 0.7), 0 -10px 10px rgba(255, 255, 128, 0.7); }
    25% { box-shadow: 0 0 20px rgba(255, 255, 128, 0.5), 0 -20px 20px rgba(255, 255, 128, 0.5); }
    50% { box-shadow: 0 0 30px rgba(255, 255, 128, 0.3), 0 -30px 30px rgba(255, 255, 128, 0.3); }
    75% { box-shadow: 0 0 15px rgba(255, 255, 128, 0.5), 0 -15px 15px rgba(255, 255, 128, 0.5); }
  `;
  

  return (
    <div css={css`
      display: flex;
      justify-content: space-between;
      ${phoneFreeCurrentlyActive && css`
        animation: ${twinkling} 5s infinite;
      `}`}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0px 6px;
          cursor: pointer;
          padding: 12px;
          border-radius: 8px;
          width: 100%;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
        `}
        onClick={onDoNotDisturbClicked}
      >
        <Icon type={TYPE_NO_PHONE} css={css`fill: ${FC_DARK_BLUE}; width: 30px; height: 30px; margin-right: 6px;`} />
        {deviceMenuStyle ?
          <Text style={TEXT_STYLES.BUTTON_2}>
            {text}
          </Text>
          :
          <LinkStyledText>
            <Text style={TEXT_STYLES.SUBTITLE_1}>{text}</Text>
          </LinkStyledText>
        }
      </div>
    </div>
  )

}