/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useContext } from 'react';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

import { FC_DARK_BLUE, FC_LIGHTER_BLUE } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';
import { BUTTON_STYLES, FlowButton, DropdownToggleButton, LoadingIndicator  } from '../components/flowComponents';
import { useGetSessionRecommendations } from '../fetch/endpoints';
import { TimeFilterOptions } from '../../functions/shared/constants';
import { useUserMakingTimeFor } from './useUserMakingTimeFor';
import { toastError } from '../components/utils/toaster';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { RecommendationModal } from '../CalendarBooking/RecommendationModal';
import { parseSessionRecommendations } from '../CalendarBooking/parseSessionRecommendations';
import { useClickOutside } from '../Sessions/Schedule/useClickOutside';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

const sectionStyle = css`
  text-align: left;
  margin-bottom: 16px;
`;

const headingStyle = css`
  font-size: 16px;
  color: ${FC_DARK_BLUE};
  margin: 0 0 8px 0;
`;


const TimeButton = ({ label, onSelect }) => {
  return (
    <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_SECONDARY} onClick={onSelect}>
      {label}
    </FlowButton>
  );
};


const DayTimeOption = ({ label, selected, setSelected }) => {
  return (
    <div css={css`
    background: #fff;
    padding: 5px 20px;
    text-align: left;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background-color: ${FC_LIGHTER_BLUE};
      border-color: ${FC_DARK_BLUE};
    }
    ${selected && css`
      background: #091454;
      color: #fff;
    `};`}
    onClick={setSelected}>
      {label}
    </div>
  )
}

const TimeDropdownContent = ({ openButtonRef, closeDropdown, day, setSelectedTime }) => {
  const clickOutsideRef = useClickOutside({ onTriggered: closeDropdown, additionalIgnoredRefs: [openButtonRef] })

  const hoursOfDay = Array.from({ length: 24 }, (_, hour) => {
    const label = `${hour === 0 || hour === 12 ? 12 : hour % 12}${hour < 12 ? 'AM' : 'PM'}`;
    const dayjsObject = day.startOf('day').add(hour, 'hour');
  
    return {
      label,
      dayjsObject,
    };
  });
  const amHours = hoursOfDay.slice(0, 12)
  const pmHours = hoursOfDay.slice(12, 24)

  return (
    <div ref={clickOutsideRef} css={css`
      width: 100%;
      height: 100%;
    
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 20px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

    `}>
      <div css={css`
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 16px 16px 14px 16px;
        background: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        `}>
          {[amHours, pmHours].map((hours) => (
            <div>
              {hours.map(({label, dayjsObject}) => (
                <DayTimeOption label={label} day={day} setSelected={() => setSelectedTime({ time: dayjsObject })} />
              ))}
            </div>
          ))}
        </div>
      
    </div>
  )
}


const DaySection = ({ dayLabel, onSelectTime, day }) => {
  const mobileView = useMediaQuery({query: '(max-width: 575px)'})
  const otherDayLabels = Object.keys(TimeFilterOptions)
  const currentHour = dayjs().startOf("hour")
  
  return (
    <div css={sectionStyle}>
      <h3 css={headingStyle}>{dayLabel}</h3>
      <div css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        ${mobileView ? css`
          > div {
            flex: 0 0 33%; // Default to 3 elements per row on mobile
            width: 33%; // Default to 3 elements per row on mobile
            box-sizing: border-box;
            padding: 5px;
            text-align: center; // Center the button text
          }
        ` : css`
            > div {
              flex: 0 0 12.5%; // Up to 6 elements per row on desktop
              max-width: 12.5%; // Up to 6 elements per row on desktop
              text-align: center; // Center the button text
            }
          }
        `};
      `}>
        {day.isSame(dayjs(), 'day') ? (
          // For 'Today', show remaining times
          Array.from({ length: 23 - currentHour.hour() }, (_, i) => currentHour.add(i+1, 'hour')).map(time => (
            <TimeButton key={time.format('H')} label={time.format('h A')} onSelect={() => onSelectTime({ time })} />
          ))
        ) : (
          // For other days, show 'Morning', 'Afternoon', 'Evening'
          <>
            {otherDayLabels.map(label => (
              <TimeButton key={label} label={label} onSelect={() => onSelectTime({ dateAndTimeOfDay: { date: day, label }})} />
            ))}
            <DropdownToggleButton
              label={'Choose a time'}
              width={350}
              height={400}
              buttonStyle={BUTTON_STYLES.OUTLINE_SECONDARY}

            >
              <TimeDropdownContent day={day} setSelectedTime={onSelectTime} />
            </DropdownToggleButton>
          </>
        )}
        
      </div>
    </div>
  );
};


const INTERVAL_TO_SUGGEST_SESSIONS_HOURS = 1

const DayAndTimePicker = ({ onBookingSuccess = () => {} }) => {
  const [selectedTime, setSelectedTime] = useState(null)
  const { setActiveModal } = useModal()
  const { performFetch: getSessionRecommendations, fetching: gettingSessionRecommendations } = useGetSessionRecommendations()
  const sendSegmentEvent = useSendSegmentEvent()
  
  // Function to handle time selection
  const handleTimeSelect = async ({ time = null, dateAndTimeOfDay = null }) => {
    const start = time ? time.valueOf() : dateAndTimeOfDay.date.add(TimeFilterOptions[dateAndTimeOfDay.label].start, 'milliseconds').valueOf()
    const end = time ? time.add(INTERVAL_TO_SUGGEST_SESSIONS_HOURS, 'hours').valueOf() : dateAndTimeOfDay.date.add(TimeFilterOptions[dateAndTimeOfDay.label].end, 'milliseconds').valueOf()
    const hoursUntilSession = dayjs(end).diff(dayjs(), 'hours')
    setSelectedTime({ start, end })
    const { result, success, error } = await getSessionRecommendations({ minTimestamp: start, maxTimestamp: end })
    if (success) {
      const sessionRecommendations = parseSessionRecommendations(result.recommendations)
      if (sessionRecommendations.length > 0) {
        setActiveModal(RecommendationModal, {
          recommendations: sessionRecommendations,
          onBookingSuccess,
        })
        sendSegmentEvent('Selected later time', {
          hoursUntilSession,
        })
      }
    } else {
      toastError(({ message: `Error getting sessions for the time you selected: ${error} . Please email help@flow.club.`}))
    }
  };

  const today = dayjs().startOf('day');
  const days = [today];
  for (let i = 1; i < 7; i++) {
    days.push(today.add(i, 'day'));
  }

  return (
    <div css={css`padding: 20px;`}>
      {selectedTime && gettingSessionRecommendations &&
        <LoadingIndicator />
      }
      {days.map((day, index) => (
        <DaySection
          key={day.format('YYYY-MM-DD')}
          dayLabel={index === 0 ? 'LATER TODAY' : day.format('dddd, MMM D').toUpperCase()}
          onSelectTime={handleTimeSelect}
          day={day}
        />
      ))}
    </div>
  );
};

export const SelectTimePane = () => {
  const { largeScreenView, goToNextPane } = useContext(InterstitialContext)
  const makingTimeFor = useUserMakingTimeFor()
  const setRecommendationBooked = () => {
    goToNextPane()
  }

  return (
    <>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? '54' : '40'}px;
        text-align: center;
      `}>Let's block out time for {makingTimeFor}</h2>
      <div css={css`letter-spacing: 0.15px; text-align: center; color: ${FC_DARK_BLUE}; margin-bottom: 20px;`}>
        <DayAndTimePicker onBookingSuccess={setRecommendationBooked}/>
      </div>
    </>
  )
}