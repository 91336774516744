import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDaily } from '@daily-co/daily-react';

import { UserContext } from '../UserProvider';
import { useAutomaticallyFetch } from '../fetch/helpers';
import { useGetDailyMeetingToken, useSetupDemoSession } from '../fetch/endpoints';
import { getRoomUrl } from '../daily/api';
import { pageUrlFromRoomUrl, roomUrlFromPageUrl } from '../daily/urlUtils';
import { logDailyEvent } from '../daily/logUtils';
import { useHotkeys } from 'react-hotkeys-hook';
import { IS_PROD } from '../utils';
import { usePopConfetti } from '../wrappers/ConfettiProvider';
import { useBackgroundBlurOrImage } from './useBackgroundBlurOrImage';

const STATE_IDLE = 'STATE_IDLE';
const STATE_CREATING = 'STATE_CREATING';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_ERROR = 'STATE_ERROR';
const ERROR_TYPE_NBF_ROOM = 'nbf-room';
const ERROR_TYPE_EXP_ROOM = 'exp-room';
const ERROR_TYPE_EJECTED = 'ejected';

/**
 * Gets [isCameraMuted, isMicMuted, isSharingScreen].
 * This function is declared outside Tray() so it's not recreated every render
 * (which would require us to declare it as a useEffect dependency).
 */
function getStreamStates(callObject) {
  let isCameraMuted,
    isMicMuted,
    isSharingScreen = false;
  if (
    callObject &&
    callObject.participants() &&
    callObject.participants().local
  ) {
    const localParticipant = callObject.participants().local;
    isCameraMuted = !localParticipant.video;
    isMicMuted = !localParticipant.audio;
    isSharingScreen = localParticipant.screen;
  }
  return [isCameraMuted, isMicMuted, isSharingScreen];
}

export const useDailyCall = ({ event, sessionWorkingTimeFinished, leaveSession, demoSession }) => {
  const { user } = useContext(UserContext);
  const [appState, setAppState] = useState(STATE_IDLE);
  const [callInitializationError, setCallInitializationError] = useState(null)
  const callObject = useDaily()
  const { chatOnly } = event

  /**
 * Show the call UI if we're either joining, already joined, or are showing
 * an error.
 */
  const showCall = [STATE_JOINING, STATE_JOINED].includes(
    appState
  );

  /**
   * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
   * or if we've errored out.
   *
   * !!!
   * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
   * can result in unexpected behavior. Disabling the leave call button
   * until then avoids this scenario.
   * !!!
   */

  const enableCallButtons = [STATE_JOINED, STATE_ERROR].includes(appState);

  /**
   * Only enable the start button if we're in an idle state (i.e. not creating,
   * joining, etc.).
   *
   * !!!
   * IMPORTANT: only one call object is meant to be used at a time. Creating a
   * new call object with DailyIframe.createCallObject() *before* your previous
   * callObject.destroy() completely finishes can result in unexpected behavior.
   * Disabling the start button until then avoids that scenario.
   * !!!
   */
  const enableJoinScreen = appState === STATE_IDLE;

  /**
   * Creates a new call room.
   */
  const createCall = useCallback(() => {
    setAppState(STATE_CREATING);
  }, []);

  const { result: realSessionMeetingToken } = useAutomaticallyFetch(useGetDailyMeetingToken, { eventId: event.id }, { transform: result => result.meetingToken, condition: !demoSession })
  const { result: setupDemoSessionResult } = useAutomaticallyFetch(useSetupDemoSession, {}, { condition: demoSession })
  const { meetingToken: demoSessionMeetingToken, dailyRoomName } = setupDemoSessionResult ?? { demoSessionMeetingToken: null, dailyRoomName: null }
  const roomUrl = !demoSession ? getRoomUrl(event) : `https://${IS_PROD ? 'flowclub' : 'flowclub-dev'}.daily.co/${dailyRoomName}`
  const token = realSessionMeetingToken ?? demoSessionMeetingToken

  const emitPopConfetti = usePopConfetti()

  const updatePageUrlWithRoomUrl = () => {
    const pageUrl = pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, null, pageUrl);
  }

  /**
   * Starts joining an existing call.
   *
   */
  const startJoiningCall = useCallback(async (url, token) => {
    muteMic()
    if (token) {
      updatePageUrlWithRoomUrl()
      setAppState(STATE_JOINING);
      await callObject.join({
        url,
        token,
        receiveSettings: {
          base: { video: { layer: 1 } }
        }
      })
    }
  }, [callObject]);

  /**
   * Starts leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        leaveSession()
      });
    } else {
      if (sessionWorkingTimeFinished || window.confirm("Are you sure you want to break the flow?")) {
        callObject.leave();
        leaveSession()
      }

    }
  }, [callObject, appState, sessionWorkingTimeFinished]);

  /**
   * If a room's already specified in the page's URL when the component mounts,
   * join the room.
   */
  useEffect(() => {
    if (user && user.uid && token) {
      const url = roomUrlFromPageUrl();
      if (url && !showCall) {
        startJoiningCall(url, token)
      }
    }
  }, [startJoiningCall, showCall, user, token]);

  /**
   * Uncomment to attach call object to window for debugging purposes.
   */
  // useEffect(() => {
  //   window.callObject = callObject;
  // }, [callObject]);

  /**
   * Update app state based on reported meeting state changes.
   *
   */
  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error'];

    function handleNewMeetingState(event) {
      if (event) { logDailyEvent(event); }

      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {

          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          const { errorMsg, error } = event
          const { type: errorType } = error
          console.error("Error", errorMsg)
          if (errorType === ERROR_TYPE_NBF_ROOM) {
            setCallInitializationError("This session is not available yet. Please try rejoining closer to the scheduled start time.")
            callObject.destroy();
          } else if ( errorType === ERROR_TYPE_EXP_ROOM || errorType === ERROR_TYPE_EJECTED ) {
            setCallInitializationError("This session has ended. Please visit the schedule to book another session.")
            console.log("This session has ended. Please visit the schedule to book another session.")
            callObject.destroy();
            leaveSession("This session has ended and the room is no longer available.");
          } else {
            setCallInitializationError("There was an error connecting to the session room. Please refresh the page and try again, or contact members@flow.club and we'll get this resolved.")
          }
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  const videosAreaRef = useRef(null)

  /**
   * Listen for app messages from other call participants.
   */
  useEffect(() => {
    if (!callObject) {
      return;
    }

    function handleAppMessage(event) {
      if (event) {
        if (event.data.type === 'confetti' && videosAreaRef.current !== null) {
          const { normalizedX, normalizedY, color } = event.data.values

          const { x: areaOffsetX, y: areaOffsetY, width: clientWidth, height: clientHeight } = videosAreaRef.current.getBoundingClientRect()
          emitPopConfetti({ x: clientWidth * normalizedX + areaOffsetX, y: clientHeight * normalizedY + areaOffsetY, color })
        }
        logDailyEvent(event);
      }
    }

    callObject.on('app-message', handleAppMessage);

    return function cleanup() {
      callObject.off('app-message', handleAppMessage);
    };
  }, [callObject]);

  const startEnteringCall = () => {
    if (event && token) {
      createCall();
      startJoiningCall(roomUrl, token);
    }
  }


  /* Extracted from old Tray.js */

  const [isCameraMuted, setCameraMuted] = useState(false);
  const [isMicMuted, setMicMuted] = useState(true);
  const [isSharingScreen, setSharingScreen] = useState(false);
  const [isMusicMuted, setMusicMuted] = useState(false);

  function toggleMusic() {
    setMusicMuted(!isMusicMuted);
  }

  function toggleCamera() {
    callObject.setLocalVideo(isCameraMuted);
  }

  function toggleMic() {
    if (!chatOnly) {
      // Local audio is the opposite of isMicMuted
      callObject.setLocalAudio(isMicMuted);
      setMicMuted(!isMicMuted)
    }
  }

  function muteMic() {
    if (callObject === null) { return }
    setMicMuted(true)
    callObject.setLocalAudio(false);
  }

  useHotkeys('ctrl+e, command+e', (e, handler) => {
    toggleCamera()
    return false
  }, [isCameraMuted])
  
  /**
   * Start listening for participant changes when callObject is set (i.e. when the component mounts).
   * This event will capture any changes to your audio/video mute state.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(
        callObject
      );
      setCameraMuted(isCameraMuted);
      setMicMuted(isMicMuted);
      setSharingScreen(isSharingScreen);
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    callObject.on('participant-updated', handleNewParticipantsState);

    // Stop listening for changes in state
    return function cleanup() {
      callObject.off('participant-updated', handleNewParticipantsState);
    };
  }, [callObject]);
  
  /* /Extracted from old Tray.js */

  const { backgroundBlurOn, onToggleBackgroundBlur, backgroundImageOn, onToggleBackgroundImage } = useBackgroundBlurOrImage(callObject)

  return {
    callObject,
    showCall,
    callInitializationError,
    enableCallButtons,
    enableJoinScreen,
    startLeavingCall,
    startEnteringCall,
    dailyMeetingToken: token,
    roomUrl,

    toggleMusic,
    toggleCamera,
    toggleMic,
    muteMic,
    isCameraMuted,
    isMicMuted,
    isMusicMuted,
    isSharingScreen,

    backgroundBlurOn,
    onToggleBackgroundBlur,
    backgroundImageOn,
    onToggleBackgroundImage,
    
    videosAreaRef
  }
}