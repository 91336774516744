import { useState } from "react"
import { DURATION_OPTIONS } from "../../../functions/shared/constants"
import { useSetUserPreferences } from "../../fetch/endpoints"
import { usePreferences } from "../../UserProvider"
import { useDebounced, useQuery } from "../../utils"

export const useUrlParamDurationFilters = () => {
  const query = useQuery()
  const queryDurationFilter = query.get('durationFilter')
  const queryDurationFilterActive = queryDurationFilter !== null && DURATION_OPTIONS.includes(parseInt(queryDurationFilter))
  return queryDurationFilterActive ? queryDurationFilter : null
}

export const getShow60Minutes = (activeDurationFilters) => {
  return activeDurationFilters.length === 0 || activeDurationFilters[60]
}

export const useDurationFilters = (events) => {
  const { sessionDurationFilters: savedFilters } = usePreferences()
  const urlParamDurationFilter = useUrlParamDurationFilters()
  const [activeDurationFilters, setActiveDurationFilters] = useState(
    urlParamDurationFilter !== null ?
      { [urlParamDurationFilter]: true }
      : savedFilters ?? {}
  )
  const { performFetch: setUserPreferences } = useSetUserPreferences()
  const activeDurations = DURATION_OPTIONS.filter((duration) => activeDurationFilters[duration])
  const eventsWithinActiveDurationFilters = events.filter(event => {
    if (activeDurations.length === 0) {
      return true
    }
    return activeDurations.some((duration) => event.duration === duration)
  })

  const [saveSelectedFilters] = useDebounced((sessionDurationFilters) => {
    setUserPreferences({ newPreferenceData: { sessionDurationFilters } })
  }, 1000, true)

  const setNewFilters = newSelectedFilters => {
    setActiveDurationFilters(newSelectedFilters)
    saveSelectedFilters(newSelectedFilters)
  }
  return {eventsWithinActiveDurationFilters, activeDurationFilters, setActiveDurationFilters: setNewFilters}
}