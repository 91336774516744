/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FC_DARK_BLUE, FC_LIGHTER_BLUE } from '../emotionVariables';
import { Button, Nav, Navbar, NavItem, NavDropdown } from 'react-bootstrap';
import { useLogout } from '../auth/logout';
import { UserContext, useGetDisplayName } from '../UserProvider';
import { useQuery } from '../utils';
import { InviteModal } from '../InviteUtils';
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { SignupNoBookingWelcomeModal } from '../auth/SignupNoBookingWelcomeModal';
import { LoginSignupModal } from '../Sessions/modals/LoginSignupModal';
import { BUTTON_STYLES, CheckboxButton, FlowButton, LinkStyledText, Text, TEXT_STYLES } from '../components/flowComponents';
import Icon, { TYPE_CLOSE, TYPE_HAMBURGER_MENU } from '../daily/daily-components/Icon/Icon';
import { WeeklyGoalProgressIndicator } from '../components/WeeklyGoalProgressIndicator';
import { useHomeScreenVisibility } from '../HomeOnboarding/HomeHome';
import { getUserIsUnauthed } from '../../functions/shared/helpers';
import { useNumUpcomingEvents } from '../Sessions/SessionUtils';
import { TooltipWrapper } from '../components/TooltipWrapper';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';


const inviteMessages = [
  "Invite a friend",
  "Share Flow Club",
  "Get a free month",
  "Flow with friends",
  "Share a guest pass",
]

const UserNav = ({ showInviteLink, inviteLinkClicked, inviteMessage }) => {
  const { user, authUser } = useContext(UserContext);
  const displayName = useGetDisplayName(user)
  if (authUser.isAnonymous || getUserIsUnauthed(user)) {
    return (
      <Nav>
        <Nav.Item>
          👋 {displayName}
        </Nav.Item>
      </Nav>
    )
  }

  return (
    <Nav>
      <MediaQuery minWidth={900}>
        <WeeklyGoalProgressIndicator />
        {showInviteLink &&
          <Nav.Item>
            <Button variant="info" className="btn-header" onClick={inviteLinkClicked}>
              {user.subscriptionStatus === 'active - complimentary' ?
                <span>Invite a Friend</span>
                :
                <span>{inviteMessage}</span>
              }
            </Button>
          </Nav.Item>
        }
        <div css={css`display: flex; align-items: center;`}>
          👋 {displayName}
        </div>
      </MediaQuery>
    </Nav>
  );
}

const NavBarContent = ({ openLoginSignupModal, showInviteLink, inviteLinkClicked, inviteMessage, numUpcomingBookedEvents, variant = "light", rightMobileMenuOpened, setRightMobileMenuOpened, setSidebarOpen }) => {
  const { user, adminViewToggledOff, setAdminViewToggledOff } = useContext(UserContext);
  const homeScreenVisible = useHomeScreenVisibility()

  const menuButtonClicked = () => {
    if (!rightMobileMenuOpened) {
      setSidebarOpen(false)
    }
    setRightMobileMenuOpened(!rightMobileMenuOpened)
  }

  const mobileView = useMediaQuery({ query: '(max-width: 899px)' })

  const showSignedInNavBar = user !== null && !getUserIsUnauthed(user)

  return (
    <>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <MediaQuery minWidth={900}>
            {homeScreenVisible &&
              <Nav.Item>
                <Link to="/" css={css`font-weight: bold; padding: 8px;`} >
                  Home
                </Link>
              </Nav.Item>
            }
            <Nav.Item>
              <Link to="/upcoming/" css={css`padding: 8px;`}>
                Schedule
              </Link>
            </Nav.Item>
            {showSignedInNavBar &&
              <>
              <NavItem>
                <Link to="/profile/" css={css`padding: 8px;`}>
                  My Sessions{numUpcomingBookedEvents > 0 ? ` (${numUpcomingBookedEvents})` : ''}
                </Link>
              </NavItem>
              {/* <NavItem>
                <Link to="/goals/" css={css`padding: 8px;`}>
                  Goals
                </Link>
              </NavItem> */}
              </>
            }
            <NavItem>
              <Link to="/hosts/" css={css`padding: 8px;`}>
                Host Directory
              </Link>
            </NavItem>
            {showSignedInNavBar &&
            <>
            <NavItem>
              <a href="https://flowclub.notion.site/Community-Resource-Bank-c2cac1f5454c47dca92eba16f2881cd4?pvs=74" css={css`padding: 8px;`} target="_blank" rel="noreferrer noopener">
                Resources
              </a>
            </NavItem>
            {user.isAdmin && !adminViewToggledOff &&
              <TooltipWrapper TooltipContents={
                <div css={css`display: flex; flex-direction: column; gap: 8px;`}>
                  <CheckboxButton selected={adminViewToggledOff} setSelected={setAdminViewToggledOff}>
                    Disable admin view
                  </CheckboxButton>
                  <Text style={TEXT_STYLES.CAPTION}>This will effectively show you what a non-admin user would see.</Text>
                  <Text style={TEXT_STYLES.CAPTION}>On the create session screen it has special behavior — you'll still be able to see the ✨Admin Mode✨ toggle, but you'll see the list of available event types that your target host (or yourself) would see, based on their/your tags, rather than the full list.</Text>
                </div>
              }>
                <NavItem>
                  <Link to={`/admin-dashboard/`} css={css`padding: 8px; ${adminViewToggledOff && css`color: red;`}`}>
                    Admin Dashboard
                  </Link>
                </NavItem>
              </TooltipWrapper>
            }
          </>
          }
          </MediaQuery>
        </Nav>
        {showSignedInNavBar && <div css={css`margin-right: 8px;`}>
          <UserNav showInviteLink={showInviteLink} inviteLinkClicked={inviteLinkClicked} inviteMessage={inviteMessage} />
        </div>}
        {!showSignedInNavBar && (
          mobileView ? (
            <FlowButton onClick={() => openLoginSignupModal(true)} customCss={css`
              margin-left: 12px;
              margin-right: 12px;
              transition: opacity 0.6s;
              ${rightMobileMenuOpened && css`
                opacity: 0;
                pointer-events: none;
              `}
            `}>
              Login
            </FlowButton>
          ) : (
            <>
              <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={() => openLoginSignupModal(true)}>Log In</FlowButton>
            </>
          )
        )}
        {(showSignedInNavBar || mobileView) &&
          <div onClick={menuButtonClicked} css={css`
            &:hover, &:active { background-color: ${FC_LIGHTER_BLUE}; }
            border-radius: 100%;
            cursor: pointer;
          `}>
            <Icon type={rightMobileMenuOpened ? TYPE_CLOSE : TYPE_HAMBURGER_MENU} fill={variant === 'dark' ? '#ffffff' : FC_DARK_BLUE}/>
          </div>
        }
      </Navbar.Collapse>
    </>
  )
}

const DropdownMenu = ({ opened, closeMenu = () => {}, openLoginSignupModal, showInviteLink, inviteLinkClicked, numUpcomingBookedEvents }) => {
  const { user } = useContext(UserContext)
  const logout = useLogout()

  const homeScreenVisible = useHomeScreenVisibility()

  const showSignedInNavBar = user !== null && !getUserIsUnauthed(user)

  return (
    <div css={css`
      position: fixed;
      top: 62px;
      right: 0px;
      height: calc(100vh - 62px);
      width: 100vw;
      background-color: white;
      z-index: 101;

      display: flex;
      flex-direction: column;
      padding: 4px 24px 24px 24px;

      @media(min-width: 900px) {
        height: fit-content;
        width: 250px;
        padding: 4px 0px 8px 0px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      }

      transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
      transform: translateY(${opened ? '0%' : '-100%'});
      opacity: ${opened ? '1' : '0'};

      ${!opened && css`pointer-events: none;`}
    `}>
      {showSignedInNavBar ?
        <>
          {user.slug !== undefined &&
            <DropdownMenuItem linkTo={`/host/${user.slug}`}>My Host Profile</DropdownMenuItem>
          }
          {user.currentClubSlug !== undefined &&
            <DropdownMenuItem linkTo={`/guided-club/${user.currentClubSlug}`}>My Club</DropdownMenuItem>
          }
          <MediaQuery maxWidth={899}>
            {homeScreenVisible && <DropdownMenuItem linkTo="/home-onboarding/">Home</DropdownMenuItem>}
            <DropdownMenuItem linkTo="/upcoming/">Schedule</DropdownMenuItem>
            <DropdownMenuItem linkTo="/profile/">My Sessions{numUpcomingBookedEvents > 0 ? ` (${numUpcomingBookedEvents})` : ''}</DropdownMenuItem>
            {/* <DropdownMenuItem linkTo="/goals/">Goals</DropdownMenuItem> */}
            <DropdownMenuItem linkTo="/hosts/">Host Directory</DropdownMenuItem>
            <DropdownMenuItem linkTo="https://flowclub.notion.site/Community-Resource-Bank-c2cac1f5454c47dca92eba16f2881cd4?pvs=74" target="_blank" rel="noreferrer noopener">
              Resources
            </DropdownMenuItem>
            {user.isAdmin &&
              <DropdownMenuItem linkTo={`/admin-dashboard/`}>Admin Dashboard</DropdownMenuItem>
            }
            <NavDropdown.Divider />
            {showInviteLink &&
              <>
                <DropdownMenuItem onClick={inviteLinkClicked}>Invite Friends</DropdownMenuItem>
                <NavDropdown.Divider />
              </>
            }
          </MediaQuery>

          <DropdownMenuItem linkTo="/settings/">Settings</DropdownMenuItem>
          <DropdownMenuItem linkTo="mailto:help@flow.club" target="_blank" rel="noreferrer noopener">Email us!</DropdownMenuItem>
          <NavDropdown.Divider />
          <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
        </> :
        <>
          {homeScreenVisible &&
            <DropdownMenuItem linkTo="/home-onboarding/">
              Home
            </DropdownMenuItem>
          }
          <DropdownMenuItem linkTo="/upcoming/">
            Schedule
          </DropdownMenuItem>
          <DropdownMenuItem linkTo="/hosts/">
            Host Directory
          </DropdownMenuItem>
          <DropdownMenuItem>
            <LinkStyledText onClick={() => {
              openLoginSignupModal(true)
              closeMenu()
            }}>
              Log in
            </LinkStyledText>
          </DropdownMenuItem>
          <FlowButton customCss={css`margin-top: 12px;`} onClick={() => {
            openLoginSignupModal(false)
            closeMenu()
          }}>Sign Up</FlowButton>
        </>
      }
    </div>
  )
}

const DropdownMenuItem = ({ children, linkTo, onClick, ...linkProps }) => {
  const linkStyle = css`
    &:hover { text-decoration: none; }
  `

  const Content = (
    <div onClick={onClick} css={css`
      height: 40px;
      display: flex;
      align-items: center;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: ${FC_DARK_BLUE};

      margin-top: 4px;

      @media(min-width: 900px) {
        margin-top: 0px;
        padding: 4px 24px;
        cursor: pointer;
        user-select: none;
        &:hover { background-color: #EBEDF0; }
        &:active { background-color: ${FC_LIGHTER_BLUE}; }
      } 
    `}>
      {children}
    </div>
  )

  return (
    linkTo !== undefined ?
      <a css={linkStyle} href={linkTo} {...linkProps}>{Content}</a> :
      Content
  )
}

export const NavBar = ({ sidebarAlwaysOpen = false, onlyHamburgerMenu = false, dark = false, sidebarOpen = false, setSidebarOpen = () => {}, toggleSidebar = () => {}, rightMobileMenuOpened = false, setRightMobileMenuOpened = () => {} }) => {
  const { user } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent()
  
  const { setActiveModal, clearModal } = useModal()
  const openLoginSignupModal = (startInLoginMode) => {
    setActiveModal(LoginSignupModal, {
      onSuccess: ({ authType }) => { if (authType === "signup") { setActiveModal(SignupNoBookingWelcomeModal) } else { clearModal() } },
      startInLoginMode,
      showLoginModeLink: true,
      showBookingRelatedText: false
    })
  }

  const showInviteLink = user !== null && user.inviteCode !== undefined

  const inviteMessage = useMemo(
    () => inviteMessages[Math.floor(Math.random() * inviteMessages.length)],
    [])
  const query = useQuery();
  const showInviteOnPageOpen = showInviteLink && query.get('showInvite', false);
  useEffect(() => {
    if (showInviteOnPageOpen) {
      setActiveModal(InviteModal)
    }
  }, [showInviteOnPageOpen])
  const inviteLinkClicked = () => {
    setActiveModal(InviteModal)
    sendSegmentEvent('Opened Invite Modal', {
      buttonText: inviteMessage,
      location: "Navbar"
    })
  }

  const numUpcomingBookedEvents = useNumUpcomingEvents()

  return (
    <>
      <DropdownMenu
        opened={rightMobileMenuOpened}
        closeMenu={() => setRightMobileMenuOpened(false)}
        openLoginSignupModal={openLoginSignupModal}
        showInviteLink={showInviteLink}
        inviteLinkClicked={inviteLinkClicked}
        numUpcomingBookedEvents={numUpcomingBookedEvents}
      />
      {onlyHamburgerMenu ?
      <Navbar bg={dark ? "transparent" : "navbar-white"} expand css={css`
        position: fixed;
        top: 0px;
        z-index: 101;
        height: 62px;
      `}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <NavBarContent
            rightMobileMenuOpened={rightMobileMenuOpened}
            setRightMobileMenuOpened={setRightMobileMenuOpened}
            openLoginSignupModal={openLoginSignupModal}
            showInviteLink={showInviteLink}
            inviteLinkClicked={inviteLinkClicked}
            inviteMessage={inviteMessage}
            numUpcomingBookedEvents={numUpcomingBookedEvents}
            variant={dark ? 'dark' : 'light'}
            setSidebarOpen={setSidebarOpen}
          />
        </Navbar>
      :
      <Navbar bg="navbar-white" expand css={css`
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 101;
        height: 62px;
      `}>
         {!sidebarAlwaysOpen && user && (
           <div onClick={toggleSidebar} css={css`
            &:hover, &:active { background-color: ${FC_LIGHTER_BLUE}; }
            margin-right: 10px;
            border-radius: 100%;
            cursor: pointer;
          `}>
            <Icon type={sidebarOpen ? TYPE_CLOSE : TYPE_HAMBURGER_MENU} fill={FC_DARK_BLUE}/>
          </div>
        )}
        {user ?
          <Navbar.Brand as={Link} to="/" className="navbar-branding-flowclub">
            <span className="FlowClub-Title">Flow Club</span>
          </Navbar.Brand>
          :
          <Navbar.Brand as={Link} to="#" onClick={(e) => {
            e.preventDefault();
            window.open('https://www.flow.club');
            return null;
          }}>
            <span className="FlowClub-Title pt-5">Flow Club</span>
          </Navbar.Brand>
        }
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <NavBarContent
          rightMobileMenuOpened={rightMobileMenuOpened}
          setRightMobileMenuOpened={setRightMobileMenuOpened}
          openLoginSignupModal={openLoginSignupModal}
          showInviteLink={showInviteLink}
          inviteLinkClicked={inviteLinkClicked}
          inviteMessage={inviteMessage}
          numUpcomingBookedEvents={numUpcomingBookedEvents}
          variant={dark ? 'dark' : 'light'}
          setSidebarOpen={setSidebarOpen}
        />
      </Navbar>
      }
    </>
  )
}