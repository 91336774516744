/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const FlowClubLogo = ({ customCss }) => {
  return (
    <span css={css`
      font-family: "Permanent Marker", "Sato", Helvetica, Arial, sans-serif;
      ${customCss}
    `}>
      Flow Club
    </span>
  )
}