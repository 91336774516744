import { useContext } from 'react';
import { useHighFiveFromEvent } from '../../fetch/endpoints';
import { UserContext } from '../../UserProvider';
import { useLoungeOptimisticFetchFunction } from './helpers';

const createFakeResponseHighFiveEvent = (spaceEventId, spaceState, sendingUser) => {
  const spaceEvent = spaceState.space.events.find(event => event.id === spaceEventId)

  return {
    ...spaceEvent,
    metadata: {
      canHighFive: false,
      otherUsers: [{
        displayName: `You (${sendingUser.displayName})`,
        id: sendingUser.uid
      }],
      respondingHighFiveCompleted: true
    }
  }
}

export const useOptimisticHighFiveFromEvent = () => {
  const { user } = useContext(UserContext)

  const argumentParser = (currentState, { spaceId, spaceEventId }) => {
    const fakeHighFiveEvent = createFakeResponseHighFiveEvent(spaceEventId, currentState, user)
    return {
      temporary: {
        ...currentState,
        space: {
          ...currentState.space,
          events: currentState.space.events.map(spaceEvent => spaceEvent.id === spaceEventId ? fakeHighFiveEvent : spaceEvent)
        }
      },
      authoritative: { spaceId, spaceEventId }
    }
  }
  
  return useLoungeOptimisticFetchFunction(useHighFiveFromEvent, argumentParser)
}