/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { getUserIsHost, groupObjectsByProperty } from '../functions/shared/helpers';
import { BUTTON_STYLES, FlowButton, LoadingIndicator, Text, TEXT_STYLES, UnstyledLink } from './components/flowComponents';
import { toastSuccess } from './components/utils/toaster';
import Icon, { TYPE_HIGH_FIVE_HAND, TYPE_OUTLINK } from './daily/daily-components/Icon/Icon';
import { CHAT_MESSAGE_COLORS, FC_BACKGROUND_GRAY, FC_DARK_BLUE, FC_IRIS_BLUE, FC_LIGHT_BLUE, NEUTRAL_20, NEUTRAL_30, NEUTRAL_60, NEUTRAL_70, NEUTRAL_90 } from './emotionVariables';
import { useApplyBadge, useGetClubPage, useSendClubPageSharedWinHighFive, useUpdateUser } from './fetch/endpoints';
import { useAutomaticallyFetch } from './fetch/helpers';
import { baseURL } from './firebase';
import { EventCard } from './Sessions/EventCard';
import { LoginSignupModal } from './Sessions/modals/LoginSignupModal';
import { UserContext } from './UserProvider';
import { copyToClipboard, useABTestVariation } from './utils';
import { useModal } from './wrappers/MagnificentlyMunificentModalManager';
import { SegmentProvider, useSendSegmentEvent } from './wrappers/SegmentProvider';
import dayjs from "dayjs"
import { HighFiveIcon } from './Lounge/HighFiveIcon';
import { DarkTooltip } from './components/DarkTooltip';
import { TooltipWrapper } from './components/TooltipWrapper';
import { TimezoneIndicator } from './Sessions/Schedule/EventsList';
import { ShareWinToClubPrompt } from './PostSession/PostSessionFeedbackScreen';

const BadgeClubContext = createContext(null);

export const BadgeClub = () => {
  const { user } = useContext(UserContext)
  const { badgeSlug } = useParams()

  const pageVariation = useABTestVariation("badgeClubPageVersion", ['original', 'withOnboardingContent'])
  const showOnboardingContent = user === null && pageVariation === 'withOnboardingContent'

  const { result: basicData, error: basicFetchError, fetching } = useAutomaticallyFetch(useGetClubPage, { badgeSlug, requestType: "basic" }, { dependencies: [badgeSlug] })
  const { result: fullData, error: fullFetchError, performFetch  } = useAutomaticallyFetch(useGetClubPage, { badgeSlug, requestType: "full" }, { dependencies: [badgeSlug] })
  const error = basicFetchError ?? fullFetchError
  const result = fullData ?? basicData
  const refetchClubPageData = () => performFetch({ badgeSlug })

  const history = useHistory()
  useEffect(() => {
    if (error !== null) {
      history.push("/upcoming")
    }
  }, [error])

  const mobileView = useMediaQuery({ query: '(max-width: 1060px)' })

  if (result === null || fetching ) {
    return (
      <div css={css`
        display: flex;
        justify-content: center;
        padding: 64px;
        min-height: 100vh;
      `}>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <BadgeClubContext.Provider value={{ ...result, refetchClubPageData, mobileView }}>
      <div css={css`
        display: flex;
        flex-direction: column;
        background-color: ${FC_BACKGROUND_GRAY};
        ${!mobileView ? css`
          padding: 64px 163px;
        ` : css`
          padding: 24px 10px;
        `}
      `}>
        <div css={css`margin: 10px 0px;`}>
          <Link to="/clubs/community">← Back to all Clubs</Link>
        </div>
        <Header />
        <div css={css`
          padding-left: ${mobileView ? '0' : '132'}px;
        `}>
          <Stats />
          {showOnboardingContent && <OnboardingContent />}

        </div>
        <Divider />
        {result.containsFullData ?
          <CommunityActivity /> :
          <div css={css`width: 100%; padding: 24px; display: flex; justify-content: center;`}>
            <LoadingIndicator />
          </div>
        }
      </div>
    </BadgeClubContext.Provider>
  )
}

const Divider = () => {
  return (
    <div css={css`width: 100%; height: 4px; background-color: ${NEUTRAL_30};`} />
  )
}

const Header = () => {
  const { badgeSlug } = useParams()
  const { emoji, title } = useContext(BadgeClubContext)
  return (
    <div css={css`display: flex; align-items: center; gap: 32px; padding-bottom: 16px;`}>
      <div css={css`
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
        height: 100px;
        width: 100px;
        border-radius: 100%;

        background-color: white;
        font-size: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        flex-shrink: 0;
      `}>
        {emoji}
      </div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={`Co-work with others in the ${title}!`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={`Co-work with others in the ${title}!`} />
        <link rel="canonical" href={`${baseURL}/club/${badgeSlug}`} />
      </Helmet>
      <Text style={TEXT_STYLES.APP_H1}>{title}</Text>
    </div>
  )
}

const Stats = () => {
  const { subtitle, numMembers, sharedCounter, sharedCounterLabel, description, viewingUserJoinedClub, mobileView, badgeId, theme, sessionVisibilityTag, refetchClubPageData } = useContext(BadgeClubContext)
  const { user } = useContext(UserContext)
  const { badgeSlug } = useParams()
  const { setActiveModal } = useModal()
  const userIsHost = getUserIsHost(user)
  const showCreateButton = userIsHost && viewingUserJoinedClub
  
  const sendSegmentEvent = useSendSegmentEvent()
  const createSessionClicked = () => {
    sendSegmentEvent("Link to Create Screen Clicked")
  }

  const [joiningClubLoading, setJoiningClubLoading] = useState(false)
  const { performFetch: applyBadge } = useApplyBadge()
  const { performFetch: updateUser } = useUpdateUser()
  const joinClub = async () => {
    setJoiningClubLoading(true)
    await applyBadge({ badgeId })
    await refetchClubPageData()
    toastSuccess({ message: "Successfully joined!" })
    setJoiningClubLoading(false)
  }

  const joinThisClubClicked = async () => {
    sendSegmentEvent("Join This Club Button Clicked", { signedIn: user !== null })

    if (user === null) {
      setActiveModal(LoginSignupModal, {
        showLoginModeLink: true,
        onSuccess: async ({ user, authType }) => {
          if (authType === "signup") {
            await updateUser({ updateObject: { signupOrigin: 'clubPage' } })
            sendSegmentEvent("Signed Up Through Join Club Button")
          }
          await joinClub()
          setActiveModal(null)
        }
      })
    } else {
      joinClub()
    }
  }

  const inviteAFriendClicked = () => {
    sendSegmentEvent("Invite a Friend Clicked")
    copyToClipboard(`${baseURL}/c/${badgeSlug}${user.inviteCode ? `?inviteCode=${user.inviteCode}` : ''}`, { onSuccessMessage: "Copied link to club page!" })
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: ${mobileView ? 'column' : 'row'};
      padding-bottom: 28px;
    `}>
      <div css={css`flex: 1; min-width: 350px;`}>
        <Text style={TEXT_STYLES.APP_H4} customCss={css`margin-bottom: 16px;`}>{subtitle}</Text>
        <HowItWorksButton />
        <div css={css` display: flex; flex-direction: row; gap: 24px;`}>
        {numMembers !== null && <div css={css`display: flex; flex-direction: column; gap: 8px; margin-top: 56px;`}>
          <Text style={TEXT_STYLES.APP_H2} customCss={css`color: ${NEUTRAL_90};`}>{numMembers}</Text>
          <Text customCss={css`color: ${NEUTRAL_70};`}>members</Text>
        </div>}
        {sharedCounter && sharedCounterLabel !== null && <div css={css`display: flex; flex-direction: column; gap: 8px; margin-top: 56px;`}>
          <Text style={TEXT_STYLES.APP_H2} customCss={css`color: ${NEUTRAL_90};`}>{sharedCounter}</Text>
          <Text customCss={css`color: ${NEUTRAL_70};`}>{sharedCounterLabel} this week</Text>
        </div>}
        </div>
      </div>
      <div css={css`flex: 1.4; min-width: min(405px, calc(100vw - 20px)); display: flex; flex-direction: column; gap: 16px; align-items: flex-start; justify-content: space-between; padding-bottom: 18px;`}>
        <Text customCss={css`white-space: pre-line; word-wrap: break-word;`}>{description}</Text>
        <div css={css`display: flex; gap: 12px;`}>
          {showCreateButton &&
            <Link to={`/upcoming/create?${sessionVisibilityTag ? `sessionVisibility=${sessionVisibilityTag}` : theme ? `sessionTheme=${theme}` : ''}`}>
              <FlowButton onClick={createSessionClicked} buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>Create Session</FlowButton>
            </Link>
          }
          {!viewingUserJoinedClub ?
          <FlowButton loading={joiningClubLoading} onClick={joinThisClubClicked}>Join this Club</FlowButton> :
          <FlowButton onClick={inviteAFriendClicked}>Invite a Friend</FlowButton>
          }
        </div>
      </div>
    </div>
  )
}

const HowItWorksButton = () => {
  const { howItWorksLink } = useContext(BadgeClubContext)
  const sendSegmentEvent = useSendSegmentEvent()

  const buttonClicked = () => {
    sendSegmentEvent("How it Works Link Clicked")
  }

  return (
    <a
      onClick={buttonClicked}
      href={howItWorksLink || "https://www.flow.club/how-it-works"}
      target="_blank"
      rel="noreferrer noopener"
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 16px;
        gap: 2px;

        height: 32px;
        width: fit-content;

        background-color: white;
        border-radius: 16px;

        user-select: none;

        &:hover, &:active { background-color: ${FC_LIGHT_BLUE}; }
      `}
    >
      <Text style={TEXT_STYLES.BODY_2}>How it works</Text>
      <Icon type={TYPE_OUTLINK} css={css`fill: ${FC_DARK_BLUE}; width: 20px; height: 20px;`} />
    </a>
  )
}

const CommunityActivity = () => {
  const { mobileView } = useContext(BadgeClubContext)

  return (
    <div css={css`display: flex; padding-top: 64px; gap: 40px; flex-direction: ${mobileView ? 'column-reverse' : 'row'};`}>
      <div css={css`flex: 2;`}>
        <Sessions />
      </div>
      <div css={css`flex: 1;`}>
        <Wins />
        <Hosts />
      </div>
    </div>
  )
}

const Sessions = () => {
  let { sessionsListTitle, sessionsListDescription, upcomingSessions, exclusiveSessions, exclusiveSessionsListTitle, title, showExclusiveSessions } = useContext(BadgeClubContext)

  return (
    <div css={css`display: flex; flex-direction: column; gap: 24px;`}>
      {showExclusiveSessions &&
        <SegmentProvider baseData={{ badgeClubSessionSection: 'exclusive' }}>
          <SessionsSection title={exclusiveSessionsListTitle} description={`Sessions exclusive to members of the ${title}.`} sessions={exclusiveSessions} />
        </SegmentProvider>
      }
      <SegmentProvider baseData={{ badgeClubSessionSection: 'upcoming' }}>
        <SessionsSection title={sessionsListTitle} description={sessionsListDescription} sessions={upcomingSessions} showButtonInEmptyState />
      </SegmentProvider>
    </div>
  )
}

const SessionsSection = ({ title, description, sessions, showButtonInEmptyState = false }) => {
  const { user } = useContext(UserContext)
  let { refetchClubPageData } = useContext(BadgeClubContext)
  const userIsHost = getUserIsHost(user)
 
  const noSessions = sessions.length === 0

  const sendSegmentEvent = useSendSegmentEvent()
  const goToScheduleClicked = () => {
    sendSegmentEvent("Go to Schedule Button Clicked")
  }

  return (
    <div>
      <Text style={TEXT_STYLES.APP_H3} customCss={css`margin-bottom: 32px;`}>{title}</Text>
      <TimezoneIndicator />
      <div css={css`display: flex; justify-content: space-between;`}>
        <div css={css`margin-bottom: 48px;`}>
          <Text>{description}</Text>
          {noSessions && <Text>
            {userIsHost ?
              "There aren't any right now. Create a new session to work with others!" :
              "There aren't any right now. Browse the schedule to join a session with others."}
          </Text>}
          {noSessions && !userIsHost && showButtonInEmptyState && <Link to={"/upcoming/"}>
            <FlowButton onClick={goToScheduleClicked} customCss={css`margin-top: 20px;`} buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>Go to Schedule</FlowButton>
          </Link>}
        </div>
      </div>
      {sessions.map((event, index) => <EventCard
        key={event.id}
        isFirst={index === 0}
        isLast={index === sessions.length - 1}
        event={event}
        onActionSuccess={refetchClubPageData}
        showDate
      />)}
    </div>
  )
}

const Wins = () => {
  const [localHighFives, setLocalHighFives] = useState(0)
  const { performFetch: recordHighFive } = useSendClubPageSharedWinHighFive()
  const sendSegmentEvent = useSendSegmentEvent()

  const { sharedWins, badgeId, title, winsPlaceholders, viewingUserJoinedClub, refetchClubPageData } = useContext(BadgeClubContext)
  const { badgeSlug } = useParams()
  const badges = [{ id: badgeId, clubPageSlug: badgeSlug, clubTitle: title, winsPlaceholders }]
  useEffect(() => {
    setLocalHighFives(0)
  }, [badgeSlug])
  if (sharedWins.length === 0 && !viewingUserJoinedClub) {
    return null
  }

  const users = groupObjectsByProperty(sharedWins, 'userId')
  const colorsForUsers = {}
  Object.keys(users).forEach((userId, index) => {
    colorsForUsers[userId] = CHAT_MESSAGE_COLORS[index % 9]
  })

  const totalHighFives = sharedWins.reduce((total, { highFives }) => total + highFives, 0) + localHighFives
  const sendHighFive = (winId) => {
    setLocalHighFives(count => count + 1)
    recordHighFive({ winId })
    sendSegmentEvent("Shared Win High Five Sent", { from: "clubPage" })
  }

  return (
    <div css={css`margin-bottom: 64px;`}>
      <div css={css`display: flex; margin-bottom: 32px; justify-content: space-between;`}>
        <Text style={TEXT_STYLES.APP_H3}>Small Wins 🎉</Text>
        <div css={css`display: flex; gap: 8px; align-items: center;`}>
          <div css={css`background-color: ${NEUTRAL_60}; border-radius: 100%; width: 32px; height: 32px; display: flex; justify-content: center; align-items: center;`}>
            <Icon type={TYPE_HIGH_FIVE_HAND} css={css`
              width: 16px;
              height: 16px;
              stroke: ${'white'};
            `} />
          </div>
          <div css={css`display: flex;`}>
            <Text>{totalHighFives}</Text>
            <Text style={TEXT_STYLES.CAPTION} customCss={css`margin-top: 5px; margin-left: 1px;`}> this week</Text>
          </div>
        </div>
      </div>
      <ShareWinToClubPrompt badges={badges} onSuccess={refetchClubPageData} />
      <Text customCss={css`margin-bottom: 24px;`}>What we accomplished together</Text>
      <div css={css`display: flex; flex-direction: column; gap: 16px; background-color: white; border-radius: 16px; padding: 32px; max-height: 515px; overflow: auto;`}>
        {sharedWins.map((win) =>
          <Win key={win.id} win={win} userColor={colorsForUsers[win.userId]} sendHighFive={() => sendHighFive(win.id)} />
        )}
      </div>
    </div>
  )
}

const Win = ({ win, userColor, sendHighFive }) => {
  const { userHasAdminPermissions, user } = useContext(UserContext)
  const { text, userDisplayName, session, highFives, userId, created } = win
  const sessionStartDate = session === null ? null : dayjs(session.start)
  const formatTimeString = (winTime) => winTime.day() === dayjs().day() ? `Today @ ${winTime.format('h:mm A')}`: `${winTime.format('dddd')} at ${winTime.format('hA')}`
  const timeString = session === null ? formatTimeString(dayjs(created)) : formatTimeString(sessionStartDate)
  const showHighFiveOption = user !== null && userId !== user.uid
  
  const [highFived, setHighFived] = useState(false)
  const highFiveClicked = () => {
    setHighFived(true)
    setTimeout(() => {
      setHighFived(false)
    }, 0)
    sendHighFive()
  }

  return (
    <div css={css`
      position: relative;
      padding: 8px 16px;
      border-radius: 2px;
      transition: background-color 0.1s;
      .high-five-icon { opacity: 0; transition: opacity 0.1s; }
      &:hover {
        background-color: ${NEUTRAL_20};
        transition: none;
        .high-five-icon { opacity: 1; transition: none; } 
      }
    `}>
      <Text><span css={css`color: ${userColor}; font-weight: bold;`}>{userDisplayName}</span>: {text}</Text>
      {session !== null ?
        <Text style={TEXT_STYLES.CAPTION} customCss={css`color: ${NEUTRAL_70}; margin-top: 4px;`}>{timeString} with <UnstyledLink css={css`text-decoration: underline;`} to={`/host/${session.hostSlug}/`}>{session.hostName}</UnstyledLink></Text>
        :
        <Text style={TEXT_STYLES.CAPTION} customCss={css`color: ${NEUTRAL_70}; margin-top: 4px;`}>{timeString}</Text>
      }
      {showHighFiveOption &&
        <div className='high-five-icon'>
          <div onClick={highFiveClicked} css={css`cursor: pointer; width: fit-content; position: absolute; top: 0px; right: 8px; user-select: none;`}>
            <TooltipWrapper retainMousedOverInsideTooltip={false} TooltipComponent={DarkTooltip} useFloatingArgs={{ placement: 'top' }} TooltipContents={'Give a high five!'}>
              <HighFiveIcon highFiveSent={highFived} iconColor={FC_IRIS_BLUE} clickParticleColor={FC_IRIS_BLUE} />
            </TooltipWrapper>
            {userHasAdminPermissions && <Text customCss={css`
              background: repeating-linear-gradient(
                -45deg,
                rgba(160, 187, 217, 0.2),
                rgba(160, 187, 217, 0.2) 15px,
                transparent 15px,
                transparent 30px
              );
              width: fit-content;
              padding: 0px 8px;
              border-radius: 8px;
              user-select: none;
              border: 1px solid rgba(160, 187, 217, 0.2);
            `}>{highFives}</Text>}
          </div>
        </div>
      }
    </div>
  )
}

const MAX_HOSTS_DISPLAYED = 10
const Hosts = () => {
  const { badgedHostUsers } = useContext(BadgeClubContext)
  const { badgeSlug } = useParams()
  const hostsToShow = badgedHostUsers.slice(0, MAX_HOSTS_DISPLAYED)
  return (
    <div>
      <Text style={TEXT_STYLES.APP_H3} customCss={css`margin-bottom: 32px;`}>{'Hosts'}</Text>
      <Text customCss={css`margin-bottom: 48px;`}>{"Hosts are members who've dedicated time to creating sessions for the community."}</Text>
      <div css={css`display: flex; flex-direction: column; gap: 16px;`}>
        {hostsToShow.map((hostUser) =>
          <Host key={hostUser.id} hostUser={hostUser} />
        )}
      </div>
      <UnstyledLink to={`/club/${badgeSlug}/hosts/`}>
      {badgedHostUsers.length > MAX_HOSTS_DISPLAYED ?
        <FlowButton customCss={css`margin-top: 32px;`}>{`See ${badgedHostUsers.length - 10} More`}</FlowButton> :
        <FlowButton customCss={css`margin-top: 32px;`}>See All</FlowButton>
      }
      </UnstyledLink>
    </div>
  )
}

const Host = ({ hostUser }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const hostLinkClicked = () => {
    sendSegmentEvent("Host Profile Link Clicked", { from: "clubPage", slug: hostUser.slug })
  }

  return (
    <Link onClick={hostLinkClicked} css={css`display: flex; gap: 16px; align-items: flex-start;`} to={`/host/${hostUser.slug}`}>
      <img
        css={css`
          width: ${64}px;
          height: ${64}px;
          border-radius: 50%;
        `}
        src={hostUser.image100}
        alt={hostUser.displayName}
      />
      <div>
        <Text customCss={css`font-weight: bold;`}>{hostUser.displayName}</Text>
        {hostUser.numUpcomingSessions > 0 &&
          <Text customCss={css`color: ${NEUTRAL_70};`}>{hostUser.numUpcomingSessions} upcoming</Text>
        }
      </div>
    </Link>
  )
}

const OnboardingContent = () => {
  const sendSegmentEvent = useSendSegmentEvent()
  const testimonialHostLinkClicked = (slug) => {
    sendSegmentEvent("Testimonial Host Link Clicked", { from: "clubPage", slug })
  }
  const { testimonials } = useContext(BadgeClubContext)
  return (
    <div css={css`display: flex;  gap: 24px; margin-bottom: 24px;`}>
      <div css={css`display: flex; overflow: auto; justify-content: flex-start; gap: 24px;`}>
        <iframe
          src="https://www.loom.com/embed/672be34401d744b99e2db752bdd87775?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&autoplay=0"
          frameBorder="0"
          webkitAllowFullScreen={true}
          mozAllowFullScreen={true}
          allowFullScreen={true}
          css={css`
            height: 250px;
            width: 250px;
            border-radius: 8px;
          `}
        ></iframe>
        {testimonials && testimonials.map(({ image, name, title, text, slug }) =>
          <div css={css`
            width: 250px;
            height: 250px;
            overflow-y: auto;
            flex-shrink: 0;

            background-color: white;

            filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08));
            border-radius: 8px;
            padding: 16px;
          `}>
            <UnstyledLink to={`/host/${slug}/`} onClick={testimonialHostLinkClicked}>
            <img css={css`width: 64px; height: 64px; border-radius: 100%;`} src={image} alt={name} draggable={false} />
            </UnstyledLink>
            <div css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}>
              <Link to={`/host/${slug}/`} onClick={testimonialHostLinkClicked}><Text style={TEXT_STYLES.APP_H4}>{name}</Text></Link>
              <Text>{title}</Text>
              <Text style={TEXT_STYLES.CAPTION}>{text}</Text>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}