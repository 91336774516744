
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import {
  ButtonGroup,
  Button,
} from 'react-bootstrap';

import { useDevices } from '@daily-co/daily-react';
import './device-selector.scss'
import Icon, {
  TYPE_BACKGROUND_ON,
  TYPE_CAMERA_ON,
  TYPE_MIC_ON,
  TYPE_MUSIC_ON,
  TYPE_RIGHT_CARET,
} from '../Icon/Icon';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import EventContext from '../../../EventContext';
import { MicVolumeIndicator } from '../../../ParticipantApp/MicVolumeIndicator';
import { FC_GREEN } from '../../../emotionVariables';
import { BackgroundBlurToggle, BackgroundImageToggle, MirrorSelfViewToggle } from '../more-menu/more-menu'
import { useMediaQuery } from 'react-responsive';

export const BackIcon = ({ onClick, className }) => {
  return (
    <Button variant="button" className={className} onClick={onClick}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 12L2.46037 7.46038C2.06985 7.06985 2.06985 6.43669 2.46037 6.04616L7 1.50654" stroke="black" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </Button>
  );
}

export const V2DeviceSelector = ({ onShowDevices = () => {}, onSelectDevice, displayMicVolume = false }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })

  const { event, mirrorSelfView, setMirrorSelfView } = useContext(EventContext);
  const { chatOnly } = event
  const {
    microphones,
    cameras,
    speakers,
    currentCam,
    currentSpeaker,
    currentMic,
    setCamera,
    setSpeaker,
    setMicrophone,
  } = useDevices()

  const [activeSelector, setActiveSelector] = useState(null)
  const deviceTypeSelected = (type) => {
    sendSegmentEvent("Device Type Selected", { type })
    setActiveSelector(type)
  }

  async function setActiveVideoDevice(device) {
    sendSegmentEvent("Device Selected", { type: activeSelector, label: device.label, useNewDeviceSelection: true })
    await setCamera(device.deviceId)
    setActiveSelector(null)
    if (onSelectDevice) onSelectDevice()
  }

  async function setActiveAudioDevice(device) {
    sendSegmentEvent("Device Selected", { type: activeSelector, label: device.label, useNewDeviceSelection: true })
    await setMicrophone(device.deviceId)
    setActiveSelector(null)
    if (onSelectDevice) onSelectDevice()
  }

  async function setActiveAudioOutputDevice(device) {
    sendSegmentEvent("Device Selected", { type: activeSelector, label: device.label, useNewDeviceSelection: true })
    await setSpeaker(device.deviceId)
    setActiveSelector(null)
    if (onSelectDevice) onSelectDevice()
  }

  const micType = chatOnly ? [] : [{type: 'mic', icon: TYPE_MIC_ON, defaultSelectPrompt: 'Choose a mic', activeDevice: currentMic}]

  const deviceTypes = [
    ...micType,
    {
      type: "camera",
      icon: TYPE_CAMERA_ON,
      defaultSelectPrompt: "Choose a camera",
      activeDevice: currentCam,
    },
    {
      type: "speakers",
      icon: TYPE_MUSIC_ON,
      defaultSelectPrompt: "Choose audio output",
      activeDevice: currentSpeaker,
      showIf: speakers !== undefined && Object.keys(speakers).length > 0,
    },
    {
      type: "video background",
      icon: TYPE_BACKGROUND_ON,
      defaultSelectPrompt: "Choose background effects",
      showIf: !mobileView,
    },
  ];

  return (

    <div className="device-selector">

      <div className="join-screen-subtitle mb-2">
        {activeSelector ?
          <BackIcon onClick={() => {
            sendSegmentEvent("Device Selection Canceled")
            setActiveSelector(null)
            onShowDevices && onShowDevices(false)
          }} /> : ""}

        {activeSelector ?
          (activeSelector === "mic" ?
            "Choose Microphone"
            :
            activeSelector === "camera" ?
              "Choose Video Camera"
              :
              activeSelector === "speakers" ?
                "Choose Audio Output"
                :
              activeSelector === "video background" ?
                "Choose Background Effects"
                :
                "")
          :
          "Camera, Mic, & Speakers"
        }

      </div>
      <ButtonGroup vertical>
        {activeSelector ?
          (activeSelector === "mic" ?

            microphones.map(({device, selected, state}) => (
              <Button variant="menu" key={device.deviceId} onClick={(e) => {
                e.preventDefault();
                setActiveAudioDevice(device);
              }}>
                <div className="more-menu-device-icon">{selected ? <MicVolumeIndicator icon={TYPE_MIC_ON} size={40} forceMicOn={false} backgroundColor="#ffffff" /> : ""}</div>
                <div className="more-menu-button-text">{device.label}</div>
              </Button>
            ))
            :
            activeSelector === "camera" ?
              cameras.map(({device, selected, state}) => (
                <Button variant="menu" key={device.deviceId} onClick={(e) => {
                  e.preventDefault();
                  setActiveVideoDevice(device);
                }}>
                  <div className="more-menu-device-icon">{selected ? <Icon type={TYPE_CAMERA_ON} /> : ""}</div>
                  <div className="more-menu-button-text">{device.label}</div>
                </Button>
              ))
              :
              activeSelector === "speakers" ?
                speakers.map(({device, selected, state}) => (
                  <Button variant="menu" key={device.deviceId} onClick={(e) => {
                    e.preventDefault();
                    setActiveAudioOutputDevice(device);
                  }}>
                    <div className="more-menu-device-icon">{selected ? <Icon type={TYPE_MUSIC_ON} /> : ""}</div>
                    <div className="more-menu-button-text">{device.label}</div>
                  </Button>
                ))
                :
              activeSelector === 'video background' ?
                  <>
                    <BackgroundImageToggle />
                    <BackgroundBlurToggle />
                    <MirrorSelfViewToggle mirrorSelfView={mirrorSelfView} setMirrorSelfView={setMirrorSelfView}/>
                  </> :
                "")
          :
          deviceTypes.map(({ type, icon, defaultSelectPrompt, activeDevice, showIf }) =>
            <V2DevicePicker
              key={type}
              type={type}
              icon={icon}
              defaultSelectPrompt={defaultSelectPrompt}
              activeDevice={activeDevice}
              showIf={showIf}
              onClick={() => {
                deviceTypeSelected(type)
                onShowDevices(true)
              }}
              displayMicVolume={displayMicVolume}
            />
          )
        }
      </ButtonGroup>
    </div>
  )
}

const V2DevicePicker = ({ type, icon, defaultSelectPrompt, activeDevice, showIf = true, onClick, displayMicVolume = false }) => {
  if (!showIf) {
    return null
  }
  return (
    <Button variant="menu" onClick={onClick}>
      {type === "mic" && displayMicVolume ?
        <MicVolumeIndicator icon={icon} size={44} /> :
        <div className="more-menu-device-icon"><Icon type={icon} /></div>
      }
      <div className="more-menu-button-text">{activeDevice !== undefined ? activeDevice.device.label : defaultSelectPrompt}</div>
      <div className="more-menu-right-caret"><Icon type={TYPE_RIGHT_CARET} /></div>
    </Button>
  )
}

export const CameraPicker = ({ onSelectDevice = () => {}}) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const {
    cameras,
    setCamera,
  } = useDevices()

  async function setActiveVideoDevice(device) {
    sendSegmentEvent("Device Selected", { type: 'camera', label: device.label, useNewDeviceSelection: true })
    await setCamera(device.deviceId)
    if (onSelectDevice) onSelectDevice()
  }

  return (
    <ButtonGroup vertical>
      {cameras.map(({ device, selected }) => (
        <Button variant="menu" css={css`height: 30px;`} key={device.deviceId} onClick={(e) => {
          e.preventDefault();
          setActiveVideoDevice(device);
        }}>
          <div>{selected ? <Icon type={TYPE_CAMERA_ON} css={css`width: 30px; height: 30px;`} /> : ""}</div>
          <div className="more-menu-button-text"  css={selected && css`color: ${FC_GREEN}`}>{device.label}</div>
        </Button>
      ))
      }
    </ButtonGroup>
  )
}

export const MicAndSpeakerPicker = ({ onSelectDevice = () => {} }) => {

  const sendSegmentEvent = useSendSegmentEvent()
  const { event } = useContext(EventContext);
  const { chatOnly } = event
  const {
    microphones,
    speakers,
    setSpeaker,
    setMicrophone,
  } = useDevices()

  async function setActiveAudioDevice(device) {
    sendSegmentEvent("Device Selected", { type: 'mic', label: device.label, useNewDeviceSelection: true })
    await setMicrophone(device.deviceId)
    if (onSelectDevice) onSelectDevice()
  }

  async function setActiveAudioOutputDevice(device) {
    sendSegmentEvent("Device Selected", { type: 'speakers', label: device.label, useNewDeviceSelection: true })
    await setSpeaker(device.deviceId)
    if (onSelectDevice) onSelectDevice()
  }
  return (
    <div>
      {!chatOnly && (
      <>
      <span className="menu-subtitle">Select a mic</span>
      <ButtonGroup vertical>
        {microphones.map(({ device, selected }) => (
          <Button variant="menu" css={css`height: 30px;`} key={device.deviceId} onClick={(e) => {
            e.preventDefault();
            setActiveAudioDevice(device);
          }}>
            <div css={css`
              flex-basis: 30px;
              flex-grow: 0;
              flex-shrink: 0;
            `}>
              {selected ? <MicVolumeIndicator icon={TYPE_MIC_ON} size={30} forceMicOn={false} backgroundColor="#ffffff" /> : ""}
            </div>
            <div className="more-menu-button-text" css={selected && css`color: ${FC_GREEN}`}>{device.label}</div>

          </Button>
        ))}
      </ButtonGroup>
      </>)
      }
      <span className="menu-subtitle">Select speakers</span>
      <ButtonGroup vertical>
        {speakers.map(({ device, selected }) => (
          <Button variant="menu" css={css`height: 30px;`} key={device.deviceId} onClick={(e) => {
            e.preventDefault();
            setActiveAudioOutputDevice(device);
          }}>
            <div css={css`
              flex-basis: 30px;
              flex-grow: 0;
              flex-shrink: 0;
            `}>
              {selected ? <Icon type={TYPE_MUSIC_ON} css={css`width: 30px; height: 30px;`} /> : ""}
            </div>
            <div className="more-menu-button-text"  css={selected && css`color: ${FC_GREEN}`}>{device.label}</div>
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )

}
