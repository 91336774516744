/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_DARK_BLUE } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';
import { createContext, useContext } from 'react';
import { CalendarBooking } from '../CalendarBooking/CalendarBooking';
import { useUserMakingTimeFor } from './useUserMakingTimeFor';

export const SelectCalendarSpanPaneContext = createContext(null)

export const SelectCalendarSpanPane = () => {
  const { largeScreenView } = useContext(InterstitialContext)
  const makingTimeFor = useUserMakingTimeFor()

  return (
    <>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? '54' : '40'}px;
        text-align: center;
      `}>Let's block out time for {makingTimeFor}</h2>
      <div css={css`letter-spacing: 0.15px; text-align: center; color: ${FC_DARK_BLUE}; margin-bottom: 20px;`}>
        Select a span of time, and we'll help you find a Flow Club session that works for your schedule.
      </div>
      <CalendarBooking onRecommendationBooked={() => {}} />
    </>
  )
}