// I stole the basis for this code from the internet, cause hey, let's save time and effort by reusing others' code!
// unfortunately, whoever came up with said basis apparently had no need to ever actually reorder data, it could only visually swap things around
// so in the process of making this actually update the order of goals in the data, this got...very janky.
// tragically, at this point I can actually say I understand most of it. this does not in any way mean that it is good code.

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { cloneElement, useEffect, useRef, useState } from 'react'
import { useDrag } from '@use-gesture/react'
import { clamp } from 'lodash'
import { useSprings, animated, to } from '@react-spring/web'
import { reorder as reorderArray, usePrevious } from '../utils';

// WHEN dragging, this function will be fed with all arguments.
// OTHERWISE, only the list order is relevant.
const makePropGetterForSpring = (order, { verticalSpacing, onRest, forceImmediate = false }, down, originalIndex, curIndex, y) => index =>
  down && index === originalIndex
    ? /*
        No need to transition the following properties:
        - z-index, the elevation of the item related to the root of the view; it should pop straight up to 1, from 0.
        - y, the translated distance from the top; it's already being updated dinamically, smoothly, from react-gesture.
        Thus immediate returns `true` for both.
      */
    { y: curIndex * verticalSpacing + y, scale: 1.1, zIndex: '1', immediate: n => n === 'y' || n === 'zIndex' || forceImmediate, onRest}
    : { y: order.indexOf(index) * verticalSpacing, scale: 1, zIndex: '0', immediate: forceImmediate, onRest }


export function DraggableList({ children: items, reorder, options }) {
  const { verticalSpacing } = options

  const order = useRef(items.map((_, index) => index)) // Store indices as a local ref, this represents the item order
  const pendingReorder = useRef(null)
  const springsRef = useRef(null)
  const [reorderIsPending, setReorderIsPending] = useState(false)
  const onRest = () => {
    // if no grabbed-item animations are in progress -- i.e. once the last animation finishes -- update the goals state so it stays in sync with visuals
    if (!springsRef.current.some(spring => spring.scale.isAnimating) && pendingReorder.current !== null) {
      reorder(pendingReorder.current)
      pendingReorder.current = null
      setReorderIsPending(false)
    }
  }

  useEffect(() => {
    const newOrder = items.map((_, index) => index)
    order.current = newOrder
    setSprings(makePropGetterForSpring(newOrder, { verticalSpacing, onRest, forceImmediate: true }))
  }, [items])

  /*
    Curries the default order for the initial, "rested" list state.
    Only the order array is relevant when the items aren't being dragged, thus
    the other arguments from makePropGetterForSpring don't need to be supplied initially.
  */
  const [springs, setSprings] = useSprings(items.length, makePropGetterForSpring(order.current, { verticalSpacing, onRest }))
  springsRef.current = springs
  const bind = useDrag(({ args: [originalIndex], down, initial: [, initialY], values: [, currentY] }) => {
    const y = currentY - initialY
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(Math.round((curIndex * verticalSpacing + y) / verticalSpacing), 0, items.length - 1)
    const newOrder = reorderArray(order.current, curIndex, curRow)
    /*
      Curry all variables needed for the truthy clause of the ternary expression from makePropGetterForSpring,
      so that new objects are fed to the springs without triggering a re-render.
    */
    setSprings(makePropGetterForSpring(newOrder, { verticalSpacing, onRest }, down, originalIndex, curIndex, y))
    // Settles the new order on the end of the drag gesture (when down is false)
    if (!down) {
      if (originalIndex !== curRow) {
        order.current = reorderArray(order.current, curIndex, curRow)
        pendingReorder.current = { originalIndex: curIndex, destinationIndex: curRow }
        setReorderIsPending(true)
      }
    }
  })

  return (
    <div css={css`
      height: ${items.length * verticalSpacing}px;
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
    `}>
      {springs.map((animatedProps, index) => <AnimatedItemContainer animatedProps={animatedProps} index={index} item={items[index]} bind={bind} verticalSpacing={verticalSpacing} reorderIsPending={reorderIsPending}/>)}
    </div>
  )
}

const AnimatedItemContainer = ({ animatedProps, index, item, bind, verticalSpacing, reorderIsPending }) => {
  const { zIndex, y, scale } = animatedProps
  const initial = usePrevious(null) === undefined
  const yValue = index * verticalSpacing
  return (
    <animated.div
      css={css`position: absolute; width: 100%;`}
      key={index}
      style={{
        zIndex,
        transform: initial ?
          `translate3d(0, ${yValue}px, 0) scale(1)` :
          to([y, scale], (y, s) => `translate3d(0,${y}px,0) scale(${s})`)
      }}
      children={cloneElement(item, { dragBindings: bind(index), reorderIsPending })}
    />
  )
}