/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useSetupSignuplessUser } from '../auth/useSetupSignuplessUser';
import { BUTTON_STYLES, FlowButton, LinkStyledText, Modal, SIZE_PRESETS, Text, TextInput, TEXT_STYLES } from '../components/flowComponents';
import { useQuery } from '../utils';
import { toastError } from '../components/utils/toaster';
import { LoginSignup } from '../auth/LoginSignup';

const DataWrapper = ({ onHide, onSuccess = () => {}, prepopulatedFields = {}, contentOverrides = {}, showCloseAndCancel = false }) => {
  return (
    <SegmentProvider baseData={{ prepopulatedFields, contentOverrides, showCloseAndCancel }} eventLabel={'EnterNameModal'}>
      <EnterNameModalContent onSuccess={onSuccess} onHide={onHide} prepopulatedFields={prepopulatedFields} contentOverrides={contentOverrides} showCloseAndCancel={showCloseAndCancel}  />
    </SegmentProvider>
  )
}

const EnterNameModalContent = ({ onHide, onSuccess = () => {}, prepopulatedFields = {}, contentOverrides = {}, showCloseAndCancel = false }) => {
  const query = useQuery();
  const prepopulatedUsername = query.get('name')
  const [name, setName] = useState(prepopulatedUsername ?? prepopulatedFields.firstName ?? '')

  const { title = 'Welcome!', description = 'Enter your name to join the session.', submitButtonText = 'Submit' } = contentOverrides

  const onLoginSuccess = () => {
    onSuccess()
    onHide()
  }

  const { createAnonymousFirebaseUserAndFirestoreUser, loading: createExtremelyUnauthedUserLoading } = useSetupSignuplessUser()
  const sendSegmentEvent = useSendSegmentEvent()
  const nameSubmitted = async (event) => {
    event.preventDefault()
    sendSegmentEvent("Extremely Unauthed Username Submitted", { name })
    const { success, error } = await createAnonymousFirebaseUserAndFirestoreUser({ displayName: name })
    if (success) {
      onLoginSuccess()
    } else {
      toastError({ message: error })
    }
  }

  useEffect(() => {
    sendSegmentEvent("Modal Shown")
  }, [])

  const [loggingIn, setLoggingIn] = useState(false)

  const logInClicked = (event) => {
    event.stopPropagation()
    setLoggingIn(true)
    sendSegmentEvent("Extremely Unauthed Toggle To Login Mode Toggled", { togglingTo: true })
  }

  const joinWithoutLoggingInClicked = (event) => {
    event.stopPropagation()
    setLoggingIn(false)
    sendSegmentEvent("Extremely Unauthed Toggle To Login Mode Toggled", { togglingTo: false })

  }

  return (
    <Modal width={524} dismissable={showCloseAndCancel} onClose={onHide}>
      <div css={css`width: max(100%); padding: 48px 64px;`}>
        {!loggingIn ? (
          <form onSubmit={nameSubmitted}>
            <div css={css`display: flex; flex-direction: column; gap: 16px;`}>
              <Text style={TEXT_STYLES.APP_H2}>{title}</Text>
              <Text>{description}</Text>
              <div css={css`display: flex; align-items: center; flex-direction: column; gap: 32px;`}>
                <div css={css`width: 300px; display: flex; align-items: center; flex-direction: column; gap: 8px;`}>
                  <TextInput customCss={css`width: 300px;`} value={name} onChange={event => setName(event.target.value)} label={'Name'} />
                  <div css={css`display: flex; margin-top: 16px; width: 100%;`}>
                    {showCloseAndCancel && <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`flex: 1; margin-right: 4px;`} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={onHide}>Cancel</FlowButton>}
                    <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`flex: 1;`} type="submit" disabled={name.length === 0} loading={createExtremelyUnauthedUserLoading}>{submitButtonText}</FlowButton>
                  </div>
                </div>
                <Text>Have an account? <LinkStyledText customCss={css`font-weight: bold;`} onClick={logInClicked}>Log in</LinkStyledText></Text>
              </div>
            </div>
          </form>

        ) : (
          <div css={css`display: flex; flex-direction: column; align-items: center; gap: 24px;`}>
            <div>
              <LoginSignup onSuccess={onLoginSuccess} startInLoginMode showBookingRelatedText={false} />
            </div>
            <Text>Don't have an account? <LinkStyledText customCss={css`font-weight: bold;`} onClick={joinWithoutLoggingInClicked}>Join without logging in.</LinkStyledText></Text>
          </div>
        )}
      </div>
    </Modal>
  )
}

export const EnterNameModal = DataWrapper