/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { BUTTON_STYLES, FlowButton } from '../../components/flowComponents';
import { INITIAL_TIMER_DURATION } from './PomodoroTimer';

export const ResetConfirmPopup = ({ onHide, onConfirm }) => {
  return (
    <div css={css`
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 220px;
      padding: 46px 28px 21px 28px;
      align-items: center;
    `}>
      <div>
        <div css={css`
          font-family: 'Red Hat Display';
          font-size: 16px;
          line-height: 24px;

          text-align: center;
          letter-spacing: 0.5px;

          color: #FFFFFF;
        `}>
          The timer will reset to {dayjs.duration(INITIAL_TIMER_DURATION, 'minutes').format('m:ss')}.
        </div>
      </div>
      <div css={css`
        display: flex;
        width: 202px;
        justify-content: space-between;
      `}>
        <FlowButton buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={onHide} customCss={css`
          color: white;
          &:hover, &:active, &:focus { background-color: #EBEDF010; }
        `}>
          Cancel
        </FlowButton>
        <FlowButton onClick={() => { onConfirm(); onHide(); }}>Confirm</FlowButton>
      </div>
    </div>
  )
}