
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon, { TYPE_HELP } from '../daily/daily-components/Icon/Icon';

const EmojiExplanationTooltip = (props) => (
  <Tooltip {...props} css={css`z-index: 10;`}>
    Use emojis to add a little flair to your display name, whether to show your personality, identity, help others identify you, or just for fun.
  </Tooltip>
)

export const EmojiOverlay = (props) => (
  <OverlayTrigger
    placement={props.placement || 'top'}
    delay={{ show: 250, hide: 400 }}
    overlay={EmojiExplanationTooltip}
  >
    {props.children}
  </OverlayTrigger>
);

export const EmojiHelpIcon = (props) => (
  <EmojiOverlay {...props}>
    <div css={css`display: inline-block; margin-left: 8px`}>
      <Icon type={TYPE_HELP} css={css`width: 24px; height: 24px; margin: 0px;`} />
    </div>
  </EmojiOverlay>
)