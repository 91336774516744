/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useMemo, useState } from 'react';
import { FC_LIGHT_BLUE } from '../emotionVariables';
import { FetchContainer } from '../fetch/helpers';
import { UserContext, useGetDisplayName } from '../UserProvider';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { ActiveSession } from './ActiveSession';
import { DailyCallWrapper } from './ParticipantApp';
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import { shuffleArray } from '../../functions/shared/helpers';
import { ConfettiProvider } from '../wrappers/ConfettiProvider';

const makeDummyEventData = ({ sessionStart, user, playlistInfo, hostedMusicPlaybackData, displayName }) => ({
  "musicDescription": "",
  "visibility": "public",
  "attendees": 0,
  "start": sessionStart,
  "description": "-",
  "hostId": user.uid,
  "source": "-",
  "title": "Demo Flow Club",
  "agenda": [
    {
      "duration": 1,
      "name": "Enter the session and get settled",
      "activeWorkingTime": false
    },
    {
      "duration": 3,
      "name": "Test music and host controls",
      "activeWorkingTime": true
    },
    {
      "duration": 1,
      "name": "Celebrate! Thank you!",
      "activeWorkingTime": false
    }
  ],
  "tags": [
    "New Host"
  ],
  "duration": 5,
  "subtitle": "-",
  "guests": [
    user.uid
  ],
  "host": displayName,
  "end": dayjs(sessionStart).add(5, "minute").toDate(),
  "customAgendaDescription": null,
  "dailyRoomName": null,
  "maxAttendees": 9,
  "status": "upcoming",
  "created": null,
  "ogImageUrl": "-",
  "modifiedAt": null,
  "id": "DEMO_SESSION",
  "date": "-",
  "sessionName": `5 min Demo Flow Club w/ ${displayName}`,
  ...playlistInfo,
  hostedMusicPlaybackData
})

export const DemoSession = ({ }) => {
  const { user } = useContext(UserContext)
  const userDisplayName = useGetDisplayName(user)

  const sessionStart = useMemo(() => new Date(), [])
  const joined =  new firebase.firestore.Timestamp(sessionStart / 1000)

  const [goals, setGoals] = useState([
    {
      text: "Share music",
      placeholder: null,
      completedAt: null,
      id: uuidv4()
    },
    {
      text: "Test attendee mute controls",
      placeholder: null,
      completedAt: null,
      id: uuidv4()
    },
    {
      text: "Test out confetti during the celebration",
      placeholder: null,
      completedAt: null,
      id: uuidv4()
    },
  ])

  const participantsById = {
    [user.uid]: {
      goals,
      userId: user.uid,
      chatColorIndex: 0,
      joined,
      name: userDisplayName,
    }
  }

  const badgeMembershipsByUser = {}

  const [chatHistory, setChatHistory] = useState([])
  const [chatMessageAttachments, setChatMessageAttachments] = useState([])

  const [playlistInfo, setPlaylistInfo] = useState({ music: null, musicLink: null })

  // hardcoded arbitrary jankiness cause why not
  const hostedMusicPlaylistAudioFiles = [
    'lofi-keys-112871.mp3',
    'spirit-blossom-15285.mp3'
  ]
  const [hostedMusicPlaybackData, setHostedMusicPlaybackData] = useState({
    playlist: null,
    playbackPoint: 0,
    playbackStartedAt: null
  })

  const endpointOverrides = {
    joinSession: ({ eventId, goals, displayName: userDisplayName }) => {
      setGoals(goals)
    },
    updateSessionGoals: ({ eventId, goals, updateCalendarEvent }) => {
      setGoals(goals)
    },
    sendChatMessage: ({ eventId, message, messageId }) => {
      setChatHistory(chatHistory => [...chatHistory, {
        message,
        id: messageId,
        sender: user.uid,
        sentAt: Date.now()
      }])
    },
    updateSessionMusic: ({ eventId, musicLink }) => {
      setPlaylistInfo({ musicLink, music: musicLink !== null ? "Your Cool Playlist" : null })
    },
    toggleSessionHostedMusicPlayback: ({ eventId, togglingTo }) => {
      let playbackPoint = hostedMusicPlaybackData.playbackPoint
      if (hostedMusicPlaybackData.playbackStartedAt !== null && !togglingTo) {
        playbackPoint += Date.now() - (hostedMusicPlaybackData.playbackStartedAt ?? Date.now())
      }
      setHostedMusicPlaybackData({
        playlist: hostedMusicPlaybackData.playlist ?? {
          tracks: shuffleArray(hostedMusicPlaylistAudioFiles),
          name: "Lofi"
        },
        playbackPoint,
        playbackStartedAt: togglingTo ? Date.now() : null
      })
    },
    sendChatMessageAttachment: ({ eventId, attachment, messageId }) => {
      const { value, type } = attachment
      setChatMessageAttachments(chatMessageAttachments => [...chatMessageAttachments, {
        id: uuidv4(),
        eventId,
        value,
        type,
        sender: user.uid,
        baseMessageId: messageId,
        sentAt: Date.now(),
      }])
    },
    removeChatMessageAttachment: ({ attachmentId }) => {
      setChatMessageAttachments(chatMessageAttachments => chatMessageAttachments.filter(attachment => attachment.id !== attachmentId))
    }
  }

  const event = makeDummyEventData({ sessionStart, user, playlistInfo, hostedMusicPlaybackData, displayName: userDisplayName })

  return (
    <div css={css`
      background-color: ${FC_LIGHT_BLUE};
    `}>
      <SegmentProvider eventLabel='In-Session [Demo]'>
        <ConfettiProvider>
        <DailyCallWrapper>
          <FetchContainer endpointOverrides={endpointOverrides}>
            <ActiveSession
              event={event}
              participants={participantsById}
              badgeMembershipsByUser={badgeMembershipsByUser}
              demoSession
              chatMessageAttachments={chatMessageAttachments}
              chatHistory={chatHistory}
              refetchEventData={() => {}}
            />
          </FetchContainer>
        </DailyCallWrapper>
        </ConfettiProvider>
      </SegmentProvider>
    </div>
  )
}