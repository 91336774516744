/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useContext } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toastError } from '../../components/utils/toaster';
import EventContext from '../../EventContext';
import { useUpdateMusic } from '../../fetch/endpoints';

export const ScreensharedMusicLinkSharingForm = () => {
  const { event, refetchEventData } = useContext(EventContext)
  const { performFetch: performUpdateMusic, fetching: updating, result, error } = useUpdateMusic()
  const onSubmit = useCallback(async (data) => {
    if (event) {
      const { success, error } = await performUpdateMusic({ eventId: event.id, musicLink: data.musicLink })
      if (success) {
        refetchEventData()
      } else {
        toastError({ message: error })
      }
    }
  }, [event, performUpdateMusic])
  const { register, handleSubmit } = useForm(); // initialize the hook

  return (
    <div>
      {error && result !== null && <Alert variant="danger" css={css` color: white;`}>Couldn't fetch your playlist title.</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <div css={css`display: flex; gap: 12px; justify-content: space-between;`}>
          <div>
            <Form.Control
              type="text"
              name="musicLink"
              placeholder="Link to playlist"
              ref={register}
              required
            />
          </div>
          <div>
            <Button
              className="float-right"
              variant="primary"
              type="submit"
              disabled={updating}
            >
              {updating ? "Updating..." : "Share"}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}