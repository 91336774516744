/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FlowButton } from '../../components/flowComponents';

export const PomodoroExplanation = ({ onHide }) => {
  return (
    <div css={css`display: flex; align-items: center;`}>
      <div css={css`color: #FFFFFF; width: 490px; line-height: 24px; letter-spacing: 0.5px; display: flex; flex-flow: column; align-items: center;`}>
        <div css={css`font-size: 32px; line-height: 32px; font-family: 'Sato'; margin-bottom: 24px;`}>What is the pomodoro technique?</div>
        <div css={css`margin-left: 12px;`}>
          <div css={css`margin-bottom: 24px;`}>The Pomodoro Technique was developed in the 1980s by Francesco Cirillo, who was a student struggling to focus at the time. Pomodoro is Italian for tomato, and it works as follows:</div>
          <div>1. Pick a task</div>
          <div>2. Set a 25-minute timer</div>
          <div>3. Work on your task until timer is up</div>
          <div>4. Take a 5-minute break</div>
          <div css={css`margin-top: 24px; margin-bottom: 28px;`}>When we’re overwhelmed, a small commitment to focus for 25 minutes on a clear task helps us get out of our own heads. The timer reminds us that time is scarce and rewards us with a break at the end.</div>
        </div>
        <FlowButton onClick={onHide}>to-may-to, to-mah-to</FlowButton>
      </div>
    </div>
  )
}