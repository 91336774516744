/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react'
import dayjs from 'dayjs'
import { UserContext } from '../../UserProvider'
import { Text, TEXT_STYLES } from '../flowComponents';
import { DayColumn } from '../../CalendarView/DayColumn';
import { FC_DARK_BLUE, FC_IRIS_BLUE, FC_LIGHTER_BLUE, FC_LIGHT_BLUE, NEUTRAL_30 } from '../../emotionVariables';
import { FancySessionTimeSelectorContext } from './FancySessionTimeSelector';
import { NowLine } from '../../CalendarView/NowLine';
import { groupObjectsByProperty, sortBy } from '../../../functions/shared/helpers';
import { flip, shift } from '@floating-ui/react-dom-interactions';
import { Tooltip, TooltipWrapper } from '../TooltipWrapper';
import { EventCard } from '../../Sessions/EventCard';
import { ExclusiveMouseoverWrapper } from '../../Lounge/ExclusiveMouseoverWrapper';
import { SESSION_CREATION_MINIMUM_MINUTES_BEFORE_SESSION_START, DATE_FORMAT } from '../../../functions/shared/constants';

const TOTAL_SLOTS = 96 // every 15 minutes (:00, :15, :30, :45)
const HOURS_IN_DAY = 24
const MINUTES_IN_DAY = 60 * HOURS_IN_DAY
const MINUTES_PER_SLOT = MINUTES_IN_DAY / TOTAL_SLOTS

const calculateRelativeMinutesInDayForSession = (session, timezone) => {
  const sessionStart = dayjs(session.start).tz(timezone)
  const sessionEnd = dayjs(session.end).tz(timezone)
  return {
    ...session,
    hour: sessionStart.hour(),
    startMinutes: sessionStart.hour() * 60 + sessionStart.minute(),
    endMinutes: sessionEnd.hour() * 60 + sessionEnd.minute()
  }
}

export const DayColumnWrapper = ({ date }) => {
  const {
    alreadyBookedSessionsByDay,
    claimableSessionsByDay,
    selectedSessionTime,
    selectSessionTime,
    selectedHourBlockRef,
    selectedHost,
    onDemandCreationInfo
  } = useContext(FancySessionTimeSelectorContext)
  const { refetchUpcomingSessions } = useContext(FancySessionTimeSelectorContext)

  const onDemandSlot = onDemandCreationInfo !== null ? onDemandCreationInfo.creationAllowedAt : null

  const { user } = useContext(UserContext)
  const userId = user.uid
  const alreadyBookedSessions = (alreadyBookedSessionsByDay[date.format(DATE_FORMAT)] ?? [])
    .map(session => calculateRelativeMinutesInDayForSession(session, selectedHost.timezone))
    .map(session => {
      return ({
        ...session,
        hosting: session.hostId === userId
      })
    })

  const claimableSessionsWithRelativeMinutes = (claimableSessionsByDay[date.format(DATE_FORMAT)] ?? [])
    .map(session => calculateRelativeMinutesInDayForSession(session, selectedHost.timezone))

  const visuallyOccupiedTimeSlots = []
  for (let i = 0; i < TOTAL_SLOTS; i++) {
    const slotMinutesSinceMidnight = i * MINUTES_PER_SLOT
    const currentTime = dayjs().tz(selectedHost.timezone)
    const currentMinutesSinceMidnight = currentTime.hour() * 60 + currentTime.minute() + SESSION_CREATION_MINIMUM_MINUTES_BEFORE_SESSION_START

    const isToday = date.format('YYYY-MM-DD') === currentTime.format('YYYY-MM-DD')
    if (isToday && slotMinutesSinceMidnight < currentMinutesSinceMidnight) {
      visuallyOccupiedTimeSlots.push(i)
    }
  }

  const occupiedTimeSlots = [...visuallyOccupiedTimeSlots]
  alreadyBookedSessions.forEach(session => {
    for (let sessionOccupiedMinutes = session.startMinutes; sessionOccupiedMinutes < session.endMinutes; sessionOccupiedMinutes += MINUTES_PER_SLOT) {
      if (!occupiedTimeSlots.includes(sessionOccupiedMinutes / MINUTES_PER_SLOT)) {
        occupiedTimeSlots.push(sessionOccupiedMinutes / MINUTES_PER_SLOT)
      }
    }
  })

  const openTimeSpans = findOpenSpans(visuallyOccupiedTimeSlots.map(slot => ({ startMinutes: slot * MINUTES_PER_SLOT, endMinutes: (slot + 1) * MINUTES_PER_SLOT })))

  const [highlightedSpan, setHighlightedSpan] = useState(null)

  const onMouseMove = ({ x, y }) => {
    if (occupiedTimeSlots.length >= TOTAL_SLOTS) { return }
    calculateHighlightedHourSlot(x, y)
  }

  const onTouchMove = ({ x, y }) => {
    if (occupiedTimeSlots.length >= TOTAL_SLOTS) { return }
    calculateHighlightedHourSlot(x, y)
  }

  const onTouchEnd = () => {
    selectSession()
    clearHighlight()
  }

  const calculateHighlightedHourSlot = (x, y) => {
    const closestHourSlot = Math.max(findClosestHourSlot(y, occupiedTimeSlots), 0)
    const startMinutes = closestHourSlot * MINUTES_PER_SLOT
    if (highlightedSpan === null || startMinutes !== highlightedSpan.startMinutes) {
      setHighlightedSpan({ startMinutes, endMinutes: startMinutes + 60 })
    }
  }

  const clearHighlight = () => {
    setHighlightedSpan(null)
  }

  const onTouchCancel = () => clearHighlight()
  const onMouseLeave = () => clearHighlight()

  const title = date.format('dddd MM/DD')
  
  const visuallySelectedSpan = (selectedSessionTime !== null && selectedSessionTime.format(DATE_FORMAT) === date.format(DATE_FORMAT)) ? {
    startMinutes: selectedSessionTime.hour() * 60 + selectedSessionTime.minute(),
    endMinutes: selectedSessionTime.hour() * 60 + selectedSessionTime.minute() + 60
  } : highlightedSpan

  const selectSession = () => {
    if (highlightedSpan !== null) {
      selectSessionTime(date.minute(highlightedSpan.startMinutes))
    }
  }

  const onClick = () => {
    selectSession()
  }

  const contents = [
    ...(openTimeSpans.map(({ startMinutes, endMinutes }) => ({
      startMinutes,
      endMinutes,
      customCss: css`
        background-color: white;
        border: 1px solid ${FC_IRIS_BLUE};
      `,
      key: `open-${startMinutes}-${endMinutes}`
    }))),
    ...(claimableSessionsWithRelativeMinutes.map((event) => {
      const { startMinutes, endMinutes } = event
      return {
        startMinutes,
        endMinutes,
        customCss: css`
          border-left: 1px solid ${FC_IRIS_BLUE};
          border-radius: 0px 2px 2px 0px;
          width: 50%;

          padding-left: 4px;
          background-color: ${FC_LIGHTER_BLUE};
          pointer-events: auto;
        `,
        children: <SessionInDay event={event} refetchUpcomingSessions={refetchUpcomingSessions} date={date} timezone={selectedHost.timezone}>
          Claim Session
        </SessionInDay>,
        key: event.id
      }
    })),
    ...(occupiedTimeSlots.map((slot) => ({
      startMinutes: slot * MINUTES_PER_SLOT,
      endMinutes: (slot + 1) * MINUTES_PER_SLOT,
      customCss: css`background-color: ${NEUTRAL_30};`,
      key: `occupied${slot}`
    }))),

    ...(alreadyBookedSessions.map((event) => {
      const { startMinutes, endMinutes, hosting } = event
      const endOfDay = endMinutes === 0 || endMinutes === 24 * 60
      const startOfDay = startMinutes === 0 || startMinutes === 24 * 60
      return {
        startMinutes,
        endMinutes,
        customCss: css`
          background-color: ${NEUTRAL_30};
          border-left: 1px solid ${FC_IRIS_BLUE};
          border-right: 1px solid ${FC_IRIS_BLUE};
          ${!alreadyBookedSessions.some(({ startMinutes: otherSessionStartMinutes }) => endMinutes === otherSessionStartMinutes) && css`border-top: 1px ${!startOfDay ? 'dashed' : 'solid'} ${FC_IRIS_BLUE};`};
          ${!alreadyBookedSessions.some(({ endMinutes: otherSessionEndMinutes }) => startMinutes === otherSessionEndMinutes) && css`border-bottom: 1px ${!endOfDay ? 'dashed' : 'solid'} ${FC_IRIS_BLUE};`};
          pointer-events: auto;
        `,
        children: <SessionInDay event={event} refetchUpcomingSessions={refetchUpcomingSessions} date={date} timezone={selectedHost.timezone} />,
        key: `alreadyBooked${startMinutes}-${endMinutes}-${hosting}`
      }
    })),
    ...((selectedSessionTime !== null && (selectedSessionTime.format(DATE_FORMAT) === date.format(DATE_FORMAT))) ? [{
      startMinutes: selectedSessionTime.hour() * 60,
      endMinutes: (selectedSessionTime.hour() + 1) * 60,
      hourBlockRef: selectedHourBlockRef,
      key: `selected${selectedSessionTime.hour()}`
    }] : []),
  ]

  if (visuallySelectedSpan !== null) {
    contents.push({
      startMinutes: visuallySelectedSpan.startMinutes,
      endMinutes: visuallySelectedSpan.endMinutes,
      customCss: css`
        background-color: ${FC_LIGHTER_BLUE};
        border: solid ${FC_IRIS_BLUE};
        /* i love janky incomprehensible code */
        border-width:
          ${openTimeSpans.some(
            ({ startMinutes }) => startMinutes === visuallySelectedSpan.startMinutes
          ) || (onDemandSlot !== null && visuallySelectedSpan.startMinutes === onDemandSlot.hour() * 60 + onDemandSlot.minute()) ? '1' : '0'}px
          1px
          ${(openTimeSpans.some(
            ({ endMinutes }) => endMinutes === visuallySelectedSpan.endMinutes
          ) || visuallySelectedSpan.endMinutes > MINUTES_IN_DAY) ? '1' : '0'}px
          1px;
      `,
      children: <Text>
        {`${date.minute(visuallySelectedSpan.startMinutes).format("h:mm a")}`}
      </Text>,
      key: `selectedSpan-${visuallySelectedSpan.startMinutes}-${visuallySelectedSpan.endMinutes}`
    })
  }

  if (dayjs().format(DATE_FORMAT) === date.format(DATE_FORMAT)) {
    const now = dayjs()
    // Use a timezone-aware hour to avoid daylight savings issues
    const currentMinutes = now.hour() * 60 + now.minute()
    contents.push({
      startMinutes: currentMinutes,
      endMinutes: currentMinutes,
      key: `current-time`,
      children: <NowLine/>
    })
  }

  return (
    <DayColumn
      title={title}
      contents={contents}
      interactionEvents={{
        onTouchMove,
        onTouchEnd,
        onTouchCancel,

        onMouseMove,
        onMouseLeave,

        onClick
      }}
      customCss={highlightedSpan !== null && css`cursor: pointer;`}
    />
  )
}

const findClosestHourSlot = (y, occupiedTimeSlots) => {
  const lastSlotIndex = TOTAL_SLOTS - 1
  const slotInterval = 1 / TOTAL_SLOTS
  // adjust y upwards by slotInterval so we round towards the center of slots rather than the start (top edge)
  const closestHourSlot = Math.max(Math.min(Math.round((y - slotInterval) / slotInterval), lastSlotIndex), 0)


  if (!occupiedTimeSlots.includes(closestHourSlot)) {
    return closestHourSlot
  }

  if (occupiedTimeSlots.includes(closestHourSlot)) {
    for (let i = 1; i <= Math.max(lastSlotIndex - closestHourSlot, closestHourSlot); i++) {
      const previousSlot = closestHourSlot - i
      if (previousSlot >= 0 && !occupiedTimeSlots.includes(previousSlot)) {
        return previousSlot
      }
      const subsequentSlot = closestHourSlot + i
      if (subsequentSlot <= lastSlotIndex && !occupiedTimeSlots.includes(subsequentSlot)) {
        return subsequentSlot
      }
    }
  }

  return null
}

const findOpenSpans = (occupiedSpans) => {
  const sortedEvents = sortBy(occupiedSpans, 'startMinutes')

  const openSpans = []
  let lastEventEndMinutes = 0

  for (let i = 0; i < sortedEvents.length; i++) {
    const event = sortedEvents[i]
    const { startMinutes, endMinutes } = event
    if (startMinutes > lastEventEndMinutes) {
      openSpans.push({ startMinutes: lastEventEndMinutes, endMinutes: startMinutes })
    }
    lastEventEndMinutes = endMinutes
  }

  if (lastEventEndMinutes < 24 * 60) {
    openSpans.push({ startMinutes: lastEventEndMinutes, endMinutes: 24 * 60 })
  }
  
  return openSpans
}

const SessionInDay = ({ event, refetchUpcomingSessions, date, timezone, children = null }) => {
  const { startMinutes, hosting } = event
  if (children === null) {
    children = `${hosting ? 'Hosting' : 'Joining'} ${date.tz(timezone).startOf('day').minute(startMinutes).format("h:mm a")}`
  }

  return (
    <div css={css`width: 100%; height: 100%;`}>
      <ExclusiveMouseoverWrapper exclusivityKey={event.id}>
        <TooltipWrapper
          TooltipComponent={({ children }) =>
            <Tooltip customCss={css`width: fit-content; padding: 0px; border: none; border-radius: 12px;`}>
              {children}
            </Tooltip>
          }
          TooltipContents={<EventCard
            key={event.id}
            event={event}
            onActionSuccess={refetchUpcomingSessions}
            forceMobileView
          />}
          useFloatingArgs={{ placement: 'top', middleware: [shift({ padding: 32 }), flip()] }}
        >
          <div css={css`width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;`}>
            <Text style={TEXT_STYLES.CAPTION} customCss={css`font-size: 11px; color: ${FC_IRIS_BLUE};`}>
              {children}
            </Text>
          </div>
        </TooltipWrapper>
      </ExclusiveMouseoverWrapper>
    </div>
  )
}