import { useContext } from 'react';
import { LoungeContext } from '../Lounge';

//   just a list of endpoint hooks used in the lounge for convenient reference -- will probably rapidly become out of date but eh
//   useVisitSpace()
//   useStartSpaceTimer()
//   useStopSpaceTimer()
//   useSpaceCompleteGoal()
//   useAddSpaceTimerFlowScore()
//   useInitiateHighFiveWithUser()
//   useHighFiveFromEvent()

/**
 * @callback argumentParser
 * @param currentState
 * @param updateArguments
 * @returns {{ temporary?, authoritative? }}
 */
/**
 * This hook looks slightly insane, mostly because it is
 * 
 * At its core it simply stores an expected result from the `updateMethod` as temporary state, and keeps a list of active optimistic requests in `currentUpdateRequests` for use in determining when that temporary state is safe to clear.
 * @param updateMethod - When the `performOptimisticUpdate` function returned from this hook is called, this callback will be called with the `.authoritative` property returned by `argumentParser` as an argument.
 * @param temporaryStateManager - Basically expected to be the return value from a `setState()` hook -- an array in the shape `[state, setState]`.
 * @param currentUpdateRequests - An object used to track whether there are any currently in-flight updates.
 * @param {argumentParser} argumentParser - Optional. A callback expected to return an object with one or both of `{ temporary, authoritative }` properties, which will be passed to `setTemporaryState` and `updateMethod`, respectively. If not present, the `updateArguments` will be passed to `setTemporaryState` and `updateMethod` directly.
 * @param authoritativeState - The most recent copy of server-provided state. Is only used here to provide a `currentState` argument to `argumentParser` when necessary.
 * @returns {function} performOptimisticUpdate
*/
export const useOptimisticUpdate = ({ authoritativeState, updateMethod, temporaryStateManager, currentUpdateRequests, argumentParser = null }) => {
  const [temporaryState, setTemporaryState] = temporaryStateManager

  const currentState = temporaryState.active ? temporaryState.state : authoritativeState

  const performOptimisticUpdate = async (...updateArguments) => {
    
    // parse arguments as may be needed for differences in setting the temporary state vs calling the authoritative update method
    const argumentParseResults = argumentParser !== null ? argumentParser(currentState, ...updateArguments) : { temporary: null, authoritative: null }

    // set the temp state
    // if argumentParser is not given, or does not return a temporary value, will use the first updateArgument by itself as the temporary state
    const argumentsToSetTemporaryStateTo = argumentParseResults.temporary ?? updateArguments[0]
    setTemporaryState({ active: true, state: argumentsToSetTemporaryStateTo })

    // make the authoritative update request
    const argumentsToCallUpdateMethodWith = [argumentParseResults.authoritative] ?? updateArguments
    const updatePromise = updateMethod(...argumentsToCallUpdateMethodWith)

    // add this request to list of active requests
    // once all active request resolves, temp state will be allowed to be cleared
    // until all requests have resolved, the temp state will be retained, in order to avoid pingponging state on the client side
    currentUpdateRequests.current.push({ promise: updatePromise, active: true })

    const response = await updatePromise

    // mark this request as resolved in the list of active requests once it returns
    currentUpdateRequests.current.find(request => request.promise === updatePromise).active = false

    // currently unused but seems clean to return this so the return values can still be used as with any normal `performFetch()` call
    return response
  }

  return performOptimisticUpdate
}

export const getDefaultOptimisticTemporaryState = () => ({ active: false, state: null })

// Syntactic sugar for useOptimisticUpdate hooked up to use the correct lounge-relevant values and return the normal endpoint values
export const useLoungeOptimisticFetchFunction = (endpointHook, argumentParser = null) => {
  const { optimisticUpdatesData } = useContext(LoungeContext)
  const endpointValues = endpointHook()
  const { performFetch } = endpointValues
  const { authoritativeState, temporaryStateManager, currentUpdateRequests } = optimisticUpdatesData
  const performOptimisticUpdate = useOptimisticUpdate({ authoritativeState, updateMethod: performFetch, temporaryStateManager, currentUpdateRequests, argumentParser })
  return { ...endpointValues, performFetch: performOptimisticUpdate }
}