import { functionsHost } from "../firebase"
import { fetchButCoolerAndWithErrorHandling } from "./helpers"

const getRequestOptions = (authToken, bodyFormData) => ({
  method: 'POST',
  headers: authToken !== null ? { Authorization: 'Bearer ' + authToken } : {},
  body: bodyFormData
})

// doing this manually for now until I get around to upgrading usePostRequest to support file uploading
// in the meantime this isn't going to be nearly as robust or consistent as other requests, but oh well such is life
export const uploadPhoto = async (authToken, file) => {
  const requestPath = `${functionsHost}uploadHostPhoto`
  const bodyFormData = new FormData()
  bodyFormData.append('profilePhoto', file)
  const result = await fetchButCoolerAndWithErrorHandling(requestPath, getRequestOptions(authToken, bodyFormData))
  return result
}