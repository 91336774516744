/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { BUTTON_STYLES, FloatingBottomButton, UnstyledLink } from '../../components/flowComponents';

export const FloatingCreateSessionButton = ({ userCanHostSessions }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  if (!userCanHostSessions) return null
  return (
    <UnstyledLink
      to={"/upcoming/create/"}
      onClick={() =>
        sendSegmentEvent("Clicked Create Session Button", {
          location: "floatingBottom",
        })
      }
    >
      <FloatingBottomButton
        buttonStyle={BUTTON_STYLES.SECONDARY}
      >+</FloatingBottomButton>
    </UnstyledLink>
  );
}