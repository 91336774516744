/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { UserContext } from '../../UserProvider';
import { useSetUserPreferences } from "../../fetch/endpoints";
import { useAutomaticallyFetch } from '../../fetch/helpers';
import Icon, { TYPE_CLOSE } from '../../daily/daily-components/Icon/Icon';
import { OPEN_SESSION_SETTINGS_OPTIONS } from '../../../functions/shared/constants';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { FlowButton, SegmentedButton, Text, TEXT_STYLES, } from '../flowComponents';

import { toastError, toastSuccess } from '../utils/toaster';


export const SessionSettings = ({ }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()

  const [openSessionSetting, setOpenSessionSetting] = useState(user.preferences?.openSessionSetting ?? OPEN_SESSION_SETTINGS_OPTIONS.NEW_WINDOW)

  const openSessionOptions = Object.entries(OPEN_SESSION_SETTINGS_OPTIONS).map(([_key, text]) => ({
    value: text,
    name: text,
    active: openSessionSetting === text,
  }))

  const { performFetch: setUserPreferences } = useSetUserPreferences()

  const openSessionOptionChanged = async (newValue) => {
    setOpenSessionSetting(newValue)
    const { success, error } = await setUserPreferences({ newPreferenceData: { openSessionSetting: newValue } })
    if (success) {
      sendSegmentEvent("Changed Session Window Setting", { newValue })
      toastSuccess({ message: 'Successfully updated your session window preferences.' })
    } else {
      toastError({ message: error })
    }
  }

  return (
    <div>
      <Text
        style={TEXT_STYLES.APP_H1}
        customCss={css`
          margin-bottom: 16px;
        `}
      >
        Session settings
      </Text>
      <Text
        style={TEXT_STYLES.BODY_1}
        customCss={css`
          margin-bottom: 48px;
        `}
      >
        Customize your session preferences to flow how you want!
      </Text>
      <div css={css`display: flex; flex-direction: column; justify-content: space-between; align-items: left ; margin-bottom: 12px; gap: 12px;`}>
          <Text>Open sessions in:</Text>
          <SegmentedButton options={openSessionOptions} onOptionToggled={openSessionOptionChanged} customItemCss={css`height: 50px;`}/>
      </div>
    </div>
  );

}