/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useContext, useMemo } from 'react';
import TrayButton, {
  TYPE_MIC_OFF,
  TYPE_MIC_ON,
} from '../TrayButton/TrayButton';
import EventContext from '../../../EventContext';
import { BUTTON_STYLES, FlowButton } from '../../../components/flowComponents';
import { useHotkeys } from 'react-hotkeys-hook';
import { toastInfo } from '../../../components/utils/toaster';

export const MicTrayButton = ({ isMac, active, toggleMicAndSpeakerMenu }) => {
  const { isMicMuted, enableCallButtons, event, activeWorkingTime, toggleMic } = useContext(EventContext);
  const { chatOnly } = event

  const { micIconClicked } = useMicMuteLogic({ chatOnly, isMicMuted, activeWorkingTime, toggleMic })
  // the sequel to the great Sort Tower of ages past...the Tooltip Ternary Tower (TTT™️) (#tripletriads)
  const micTooltip = useMemo(() => <>
    {!chatOnly ?
      <>
        {(isMicMuted ?
          (!activeWorkingTime ?
            "Turn mic on" :
            <>This is <b>muted working time</b>. Are you sure you want to unmute?</>
          ) :
          "Mute mic"
        )}
        {
          (isMicMuted && activeWorkingTime) ? <FlowButton onClick={toggleMic} customCss={css`margin-top: 8px;`} buttonStyle={BUTTON_STYLES.OUTLINE_DARK} fillAvailableWidth>Unmute</FlowButton> :
            (isMac ? " (⌘+d)" : " (Ctrl+d)")
        }
      </> :
      <>This session is <b>chat only</b>. All attendees are muted.</>
    }
  </>, [chatOnly, isMicMuted, activeWorkingTime, isMac])
  
  return (
    <TrayButton
      type={isMicMuted ? TYPE_MIC_OFF : TYPE_MIC_ON}
      disabled={!enableCallButtons || chatOnly}
      active={active}
      onClick={micIconClicked}
      tooltip={micTooltip}
      clickEvent={{ name: 'Microphone', togglingTo: isMicMuted }}
      showTooltipOnMobile
      showSideButton={true}
      fill={active && '#FFFFFF'}
      onSideButtonClicked={toggleMicAndSpeakerMenu}
    />
  )
}

const useMicMuteLogic = ({ chatOnly, isMicMuted, activeWorkingTime, toggleMic }) => {
  const allowUnconfirmedUnmuting = chatOnly || !isMicMuted || !activeWorkingTime

  const micIconClicked = () => {
    if (allowUnconfirmedUnmuting) {
      toggleMic()
    }
  }

  useHotkeys('ctrl+d, command+d', (e, handler) => {
    if (allowUnconfirmedUnmuting) {
      toggleMic()
    } else {
      toastInfo({ message: "Unmuting by hotkey is disabled during working time in order to prevent accidentally unmuting."})
    }

    e.preventDefault()
    return false
  }, [isMicMuted])

  return { micIconClicked }
}