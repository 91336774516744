/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { MicAndSpeakerPicker } from '../device-selector/device-selector'
import '../more-menu/more-menu.scss'
import { SegmentProvider } from '../../../wrappers/SegmentProvider';

export const MicAndSpeakerMenu = ({ closeFn }) => {
  return (
    <div css={css`width: 300px;`}>
      <div className="more-menu options-menu">
        <SegmentProvider eventLabel={'Device Selector'}>
          <MicAndSpeakerPicker onSelectDevice={closeFn} />
        </SegmentProvider>
      </div>
    </div>
  );
}
