/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC_DARK_BLUE } from "../emotionVariables";
import { MAKING_TIME_FOR_GOALS } from "../../functions/shared/constants";
import { FullScreenSidebar, SIDEBAR_COLORS, SIDEBAR_MIN_WIDTH } from "./FullScreenSidebar";
import StruggleImage from './images/struggle.jpg'
import WorkImage from './images/work.jpg'
import SchoolImage from './images/school.jpg'
import MyselfImage from './images/myself.jpg'
import RelationshipsImage from './images/relationships.jpg'
import OtherImage from './images/other.jpg'

const GoalSidebarTextAndImage = {
  [MAKING_TIME_FOR_GOALS.WORK]: {
    goal: {
      text: "Can't focus on your work?",
      subtext: "We're here to help",
      image: StruggleImage,
    },
    "specific-goal": {
      text: "98%",
      subtext: "Of members feel more able to accomplish their professional obligations when using Flow Club",
      image: WorkImage,
    }
  },
  [MAKING_TIME_FOR_GOALS.SCHOOL]: {
    goal: {
      text: "Struggling to focus on school?",
      subtext: "We're here to help",
      image: StruggleImage,
    },
    "specific-goal": {
      text: "98%",
      subtext: "Of members feel more able to accomplish their academic obligations when using Flow Club",
      image: SchoolImage,
    }
  },

  [MAKING_TIME_FOR_GOALS.MYSELF]: {
    goal: {
      text: "Need time for yourself?",
      subtext: "We're here to help",
      image: StruggleImage,
    },
    "specific-goal": {
      text: "91%",
      subtext: "Of members are more able to keep up with personal obligations when using Flow Club",
      image: MyselfImage,
    }
  },
  [MAKING_TIME_FOR_GOALS.RELATIONSHIPS]: {
    goal: {
      text: "Want to focus on your relationships?",
      subtext: "We're here to help",
      image: StruggleImage,
    },
    "specific-goal": {
      text: "91%",
      subtext: "Of members feel more able to keep up with their relationships when using Flow Club",
      image: RelationshipsImage,
    }
  },
  [MAKING_TIME_FOR_GOALS.OTHER]: {
    goal: {
      text: "Can't focus on your tasks?",
      subtext: "We're here to help",
      image: StruggleImage,
    },
    "specific-goal": {
      text: "97%",
      subtext: "of members believe Flow Club helps them START working on tasks or projects that they are avoiding",
      image: OtherImage,
    }
  },
}

const textWrapperCss = css`
  color: ${FC_DARK_BLUE};
  align-self: stretch;
  
  font-family: "Red Hat Display";
`

const desktopHeaderCss = css`
  ${textWrapperCss}
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 125% */
  letter-spacing: -0.5px;
`

const mobileHeaderCss = css`
  ${textWrapperCss}
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px; /* 133.333% */
`

const desktopSubtextCss = css`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  text-align: center;
`

const mobileSubtextCss = css`font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: left;
`

const imageCss = css`
  flex-shrink: 0;
`

const imageDesktopCss = css`
  ${imageCss}
  width: 286px;
  height: 298px;
`

const mobileBottomContainerCss = css`
  display: flex;
  width: 358px;
  height: 128px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  position: fixed;
  bottom: 24px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06), 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
`

const imageMobileCss = css`
  ${imageCss}
  width: 101px;
  height: 92px;
`


export const GoalSidebar = ({ selectedGoal = MAKING_TIME_FOR_GOALS.OTHER, step, mobileView = false }) => {
  const goal = GoalSidebarTextAndImage[selectedGoal]


  if (!goal) return null
  const sidebar = goal[step];
  if (!sidebar) return null;
  const { text, subtext, image } = sidebar;
  return (
    mobileView ?
    <div css={mobileBottomContainerCss}>
      <div>
        <div css={mobileHeaderCss}>{text}</div>
        <div css={mobileSubtextCss}>{subtext}</div>
      </div>
      <img src={image} alt={selectedGoal} css={imageMobileCss} />
    </div>
    :
    <FullScreenSidebar color={SIDEBAR_COLORS.WHITE}>
      <img src={image} alt={selectedGoal} css={imageDesktopCss} />
      <div css={desktopHeaderCss}>{text}</div>
      <div css={desktopSubtextCss}>{subtext}</div>
    </FullScreenSidebar>
  );
};