/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spinner } from 'react-bootstrap';

import { DailyProvider } from "@daily-co/daily-react";
import LoungeCall from "./LoungeCall";
import { useLoungeCall } from "./useLoungeCall";
import { DailyCallWrapper } from "../../ParticipantApp/ParticipantApp";
import LoungeTray from "./LoungeTray";

export const LoungeVideoGrid = ({ users, space, user }) => {

  // If space or current user hasn't loaded yet, don't render
  if (!space || !user) return <></>;

  return (
      <DailyCallWrapper event={{chatOnly: true}}>
        {space && 
          <CallHandler space={space} user={user} users={users} />
        }
      </DailyCallWrapper>
  );
};

const CallHandler = ({space, user, users}) => {
  const event = {
    id: space.id,
    chatOnly: false,
    dailyRoomName: `space-${space.id}`,
  };
  const loungeCallData = useLoungeCall({
    event
  });


  const renderCall = () => {

    if (loungeCallData.showCall) {
      return (
        <DailyProvider callObject={loungeCallData.callObject}>
          <div css={css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          `}>
            <LoungeCall users={users} />
            <LoungeTray user={user} loungeCallData={loungeCallData} />
          </div>
        </DailyProvider>
      );
    }

    // The default view to show some loading text
    return (
      <div css={css`padding: 10px`}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          {` `}
          Loading...
      </div>
    );
  };
  
  return renderCall();
};
