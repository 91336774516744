// You may ask -- what is the different between a popover and a tooltip?
// The answer is: I have no idea.
// In this particular case the distinction is just that this popover's rendering is controlled by external state (so can be triggered on clicks, etc), rather than activating on mouseover of a particular child element
// (and has some fancy styling)
// But it's a very silly distinction.

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { useFloating } from '@floating-ui/react-dom-interactions';
import { animated, useSpring } from '@react-spring/web'
import { createContext, useEffect } from "react";
import { useClickOutside } from '../Sessions/Schedule/useClickOutside';
import { usePrevious, useRerenderOnScreenResize } from '../utils';

export const usePopoverData = (optionOverrides = {}) => {
  const options = {
    open: true,
    placement: 'left',
    strategy: 'fixed',
    middleware: [offset(12), flip(), shift({ padding: 32, crossAxis: true })],
    ...optionOverrides
  }

  const floatingUIData = useFloating(options);

  return floatingUIData
}

const PopoverContext = createContext(null)

export const Popover = ({ closePopup = () => { }, floatingUIData, children, overlay = false, animate = true, popupWindowCss = null }) => {
  const { x, y, floating, strategy, placement, middlewareData, update } = floatingUIData
  const floatingShiftOffset = middlewareData.shift

  const initial = usePrevious(null) === undefined
  const clickOutsideRef = useClickOutside({
    onTriggered: () => {
      if (!initial) {
        closePopup()
      }
    }
  })


  const refreshedAt = useRerenderOnScreenResize()

  useEffect(update, [refreshedAt])

  useEffect(() => {
    // Please do not ask me to explain anything about this little nugget of occult sorcery, there is context but I...don't want to talk about it
    // frontend is really a place that exists.
    setTimeout(update, 0)
    update()
  }, [])
  
  return (
    <>
      {overlay && <div css={css`position: fixed; top: 0px; left: 0px; width: 100vw; height: 100vh; background-color: rgba(255, 255, 255, 0.4);`} />}
      <div
        ref={floating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
          zIndex: 1
        }}
      >
        <div ref={clickOutsideRef}>
          <PopupWindow placement={placement} floatingShiftOffset={floatingShiftOffset} closePopup={closePopup} animate={animate} customCss={popupWindowCss}>
            {children}
          </PopupWindow>
        </div>
      </div>
    </>
  )
}

// Popover itself handles positioning, PopupWindow handles styling the window that's popped up
const PopupWindow = ({ placement, floatingShiftOffset = { x: 0, y: 0 }, closePopup, animate, children, customCss }) => {
  const animatedStyle = useSpring({
    from: { opacity: 0.5, transform: `scale(0)` },
    to: { opacity: 1, transform: `scale(1)` },
  })
  
  return (
    <animated.div style={animate ? animatedStyle : null} css={css`
      padding: 10px;
      background: white;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      max-height: calc(100vh - 48px);
      overflow: auto;

      ${css`transform-origin: ${placement === 'left' ? 'right' : 'left'} calc(50% - ${floatingShiftOffset.y}px);`}

      ${customCss}
    `}>
      <PopoverContext.Provider value={{ placement, floatingShiftOffset, closePopup }}>
        {children}
      </PopoverContext.Provider>
    </animated.div>
  )
}