/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Alert, Badge, Container, Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { baseURL } from './firebase';
import { Header } from './HostProfile'
import { Helmet } from 'react-helmet'
import { Widget } from '@typeform/embed-react'
import { FC_DARK_BLUE, FC_IRIS_BLUE, FC_BACKGROUND_GRAY, FC_LIGHT_BLUE, FC_LIGHTER_BLUE } from './emotionVariables'
import { FlowButton, LoadingIndicator, BUTTON_STYLES, Text, TEXT_STYLES, UnstyledLink, LinkStyledText, LightGrayText, SIZE_PRESETS } from './components/flowComponents';
import { useGetBadgesForUsers, useGetActiveClubs } from './fetch/endpoints';
import { LoginSignupModal } from './Sessions/modals/LoginSignupModal';
import { useSendSegmentEvent } from './wrappers/SegmentProvider';
import { UserContext } from './UserProvider';
import { useAutomaticallyFetch } from "./fetch/helpers";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TabbedInterface } from './components/flowComponents';
import { getClubIsOpenForSignups, getUserCanHostSessions, getUserIsUnauthed, truncateTextWithEllipses } from '../functions/shared/helpers';
import { CLUB_MAX_MEMBERS, CLUB_CLOSES_FOR_SIGNUPS_AFTER, GENERIC_HOST_IMAGE } from '../functions/shared/constants';
import { useIndicateInterestInClub } from './fetch/endpoints';
import { useModal } from './wrappers/MagnificentlyMunificentModalManager';
import { ClubPaymentBadge, MAX_DESCRIPTION_CHARACTERS_MOBILE_GALLERY, ClubDescription, Club } from './Club';

const MobileViewMediaQuery = {query: '(max-width: 767px)'}

const ClubListings = ({ badges, badgeMemberships }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredBadges = badges.filter((badge) =>
    badge.name.toLowerCase().includes(searchTerm) || (badge.clubPage && badge.clubPage.title && badge.clubPage.title.toLowerCase().includes(searchTerm))
  )
  return (
    <Container>
      <div css={css`
        text-align: left;
        padding: 0px 18px;
      `}>
      <input
        type="text"
        placeholder="Search clubs..."
        onChange={onSearchChange}
        value={searchTerm}
        css={css`
          padding: 8px;
          margin: 8px 0;
          border: 1px solid #D9D9D9;
          border-radius: 4px;
          margin: 16px 0;
          @media (min-width: 768px) {
            width: calc(33.333% - 40px);
          }
          @media (max-width: 767px) {
            width: 100%;
          }
        }`}
      />
      </div>
      <div css={css`
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        gap: 12px;
        min-height: 100vh;

        @media (min-width: 768px) {
          & > * {
            flex: 1 0 calc(33.333% - 40px);
            max-width: calc(33.333% - 40px);
          }
        }
      `}>
        {filteredBadges.map(badge => <BadgeClubListing key={badge.id} club={badge} isMember={badgeMemberships.has(badge.id)} />)}
        {!filteredBadges.length && <div css={css`padding: 24px;`}>No clubs found</div>}
      </div>
    </Container>
  )
}

const BadgeClubListing = ({ club, isMember = false }) => {  

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = (e) => { e.preventDefault(); e.stopPropagation(); setExpanded(!expanded); }
  const expandable = club.clubPage.description !== null && club.clubPage.description.length > MAX_DESCRIPTION_CHARACTERS
  const displayText = expandable && !expanded ? truncateTextWithEllipses(club.clubPage.description, MAX_DESCRIPTION_CHARACTERS) : club.clubPage.description;

  return (
    <UnstyledLink to={`/club/${club.clubPageSlug}/`}>
      <div
        css={css`
          background: white;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        `}
        >
        <div css={css`
          display: flex;
          padding: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}>
          <div css={css`
            display: flex;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
          `}>
            <div css={css`
              display: flex;
              width: 60px;
              height: 60px;
              padding: 10px;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              flex-shrink: 0;

              border-radius: 256px;
              background: #F1F1F1;
              font-family: "Red Hat Display";
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `}>
              {club.emoji}
            </div>
            <div css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              gap: 2px;
              flex: 1 0 0;
            `}>
            <Text style={TEXT_STYLES.BODY} customCss={css`font-weight: 700;`}>{club.clubPage.title}</Text>
            <Text style={TEXT_STYLES.CAPTION}>{club.clubPage.subtitle}</Text>
          </div>
        </div>
        </div>
        <div css={css`
          margin: 0 16px;
        `}>
          <LightGrayText customCss={css`max-width: 576px; text-align: left;`}>
          {displayText}
          {expandable && <LinkStyledText customCss={css`
            margin-left: 8px;
          `} onClick={toggleExpand}>
            {expanded ? 
            "See Less" :
            "See More"
            }
          </LinkStyledText>}
        </LightGrayText>
        <div css={css`margin: 16px 0px;`}>
        <Link to={`/club/${club.clubPageSlug}/`}>
          <FlowButton buttonStyle={BUTTON_STYLES.GREEN_OUTLINE} sizePreset={SIZE_PRESETS.CHUNKY} fillAvailableWidth>{isMember ? 'Visit' : 'Join'}</FlowButton>
        </Link>
        </div>
        </div>
      </div>
     </UnstyledLink>
  )
}

const StartClubModal = ({ onHide }) => {
  // const { user } = useContext(UserContext)
  // const { email, displayName } = user
  return (
    <Modal
      onHide={onHide}
      show
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h2">Start Your Own Club</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Widget
          id="TrAsyVVj"
          style={{ width: "100%", height: "80vh" }}
          //This broke the Typeform sheets/Slack integration so we removed it
          // hidden={{
          //   fc_email: email,
          //   fc_name: displayName,
          // }}
        />
      </Modal.Body>
    </Modal>
  );
}

const StartYourOwnClubCard = () => {
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()
  const sendSegmentEvent = useSendSegmentEvent()
  const onStartClubClicked = () => {
    sendSegmentEvent("Clicked Start Club")
    setActiveModal(StartClubModal)
  }
  return (
    <div
      css={css`
        background: white;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      `}
    >
      <div
        css={css`
          background-color: ${FC_LIGHT_BLUE};
          background-image: linear-gradient(
            to left,
            ${FC_LIGHTER_BLUE},
            ${FC_LIGHT_BLUE}
          );
          height: 87px;
        `}
      ></div>
      <div css={css`
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      `}>
        <div css={css`
          display: flex;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
        `}>
          <img
            src={user.image100 || GENERIC_HOST_IMAGE}
            alt={`Host ${user.displayName}`}
            css={css`
              width: 60px;
              height: 60px;
              border-radius: 50%;
            `}
          />
          <div css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            gap: 2px;
            flex: 1 0 0;
          `}>
            <Text style={TEXT_STYLES.CAPTION} customCss={css`color: ${FC_IRIS_BLUE};`}>{user.displayName}</Text>
            <Text style={TEXT_STYLES.BODY} customCss={css`font-weight: 700;`}> Start Your Own Club</Text>
          </div>
        </div>
        <LightGrayText customCss={css`max-width: 576px; text-align: left;`}>
          Guided Clubs have a shared purpose designed around specific goals.<br/>
          Any host can lead a Club. If you are a coach with expertise to offer, we will help highlight ways you can help.
        </LightGrayText>
      </div>
      <div css={css`
        margin: 16px;
      `}>
        <FlowButton
          buttonStyle={BUTTON_STYLES.GREEN}
          sizePreset={SIZE_PRESETS.CHUNKY}
          onClick={onStartClubClicked}
          fillAvailableWidth
        >
          Start Your Own Club
        </FlowButton>
      </div>
    </div>
  );
}

const ClubStatuses = {
  ALL: 'All',
  // OPEN_FOR_SIGNUPS: 'Accepting Signups',
  STARTING_SOON: 'Starting Soon',
  WAITLIST_OR_FINISHED: 'Join Waitlist or Next Cohort',
}

const ClubStatusFilterFunctions = {
  [ClubStatuses.ALL]: () => true,
  [ClubStatuses.OPEN_FOR_SIGNUPS]: club => getClubIsOpenForSignups(club),
  [ClubStatuses.STARTING_SOON]: club => club.startDate === undefined || new Date(club.startDate) > new Date(),
  [ClubStatuses.WAITLIST_OR_FINISHED]: club => club.members === CLUB_MAX_MEMBERS || new Date(new Date(club.startDate).getTime() + CLUB_CLOSES_FOR_SIGNUPS_AFTER) < new Date(),

}

const ClubStatusFilter = ({ filter, setFilter }) => {
  return (
    <div css={css`
      display: flex;
      align-items: flex-start;
      gap: 16px;
    `}>
      {Object.entries(ClubStatuses).map(([status, label]) => (
          <div key={label}
            onClick={() => setFilter(label)}
            css={css`
              display: flex;
              padding: 8px 24px;
              align-items: flex-start;
              gap: 10px;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: ${FC_DARK_BLUE};

              ${filter === label ? css`
                border-bottom: 4px solid #091454;
                font-weight: 700;
              ` : css`cursor:pointer;`}
            `}
          >
            {label === ClubStatuses.STARTING_SOON ? 'Indicate Interest' : label}
          </div>
      ))}
    </div>
  )
}

const ClubGallery = ({ clubs }) => {
  const { user } = useContext(UserContext)
  const [ filter, setFilter ] = useState(ClubStatuses.ALL)
  const filterFn = ClubStatusFilterFunctions[filter]
  const clubsToShow = clubs.filter(filterFn)
  const userCanHostSessions = getUserCanHostSessions(user)
  const mobileView = useMediaQuery(MobileViewMediaQuery)
  return (
    <>
    {!mobileView &&
    <Container css={css`padding: 0px 24px;`}>
      <ClubStatusFilter filter={filter} setFilter={setFilter} />
    </Container>
    }
    <div css={css`
      background: ${FC_BACKGROUND_GRAY};
      width: 100vw;
      margin-left: calc(-50vw + 480px);
      margin-right: calc(-50vw + 480px);
      overflow: hidden;
    `}>
    <Container css={css`padding: 24px;`}>
      <Alert variant="info">
        Guided Clubs will be back in the near future. You can still check out these past clubs, and in the meantime, check out our <Link to="/clubs/guided">Community Clubs!</Link>
      </Alert>

      <div css={css`
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 12px;

        @media (min-width: 768px) { // Adjust the breakpoint as necessary for desktop view
          & > * {
            flex: 1 0 calc(33.333% - 40px); // Subtract the total gap size to maintain three cards per row
            max-width: calc(33.333% - 40px); // Ensures that the flex-basis does not exceed the actual max width
          }
        }
      `}>
      {clubsToShow.map(club => <ClubCard club={club} key={club.name} />)}
      {userCanHostSessions && <StartYourOwnClubCard />}
    </div>
  </Container>
  </div>
  </>
  )
}

const MAX_DESCRIPTION_CHARACTERS = MAX_DESCRIPTION_CHARACTERS_MOBILE_GALLERY

const ClubCard = ({ club }) => {
  const {
    name,
    startDate,
    description,
    image,
    hostImage,
    members,
    fullName,
    duration,
    paymentType,
  } = club;

  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()
  const history = useHistory()
  const seatsLeft = CLUB_MAX_MEMBERS - members
  const seatsLeftText = seatsLeft <= 0 ? "🏠 Full house!" : seatsLeft === 1  ? "🔥 1 seat left" : `🔥 ${seatsLeft} seats left`
  const startDateFormatted = startDate === undefined ? null : dayjs(startDate).format('ddd MMM D')
  const start = new Date(startDate)
  const now = new Date()
  
  const { performFetch: indicateInterest, fetching: indicateInterestLoading, error: indicateInterestError } = useIndicateInterestInClub()
  const isOpenForSignups = getClubIsOpenForSignups(club)

  const registerOrIndicateInterest = async (e) => {
    e.stopPropagation()
    const nonUserOrUnauthedUser = user === null || getUserIsUnauthed(user)
    if (startDate && seatsLeft > 0) {
      history.push(`/guided-club/${club.slug}/`)
    } else if (nonUserOrUnauthedUser) {
      sendSegmentEvent("Indicated interest in club", { clubId: club.id, redirectedToSignup: true })
      setActiveModal(LoginSignupModal, {
        showLoginModeLink: true,
        onSuccess: () => { history.push(`/guided-club/${club.slug}/`) }
      })
    } else {
      const { success } = await indicateInterest({ clubId: club.id })
      if (success) {
        sendSegmentEvent("Indicated interest in club", { clubId: club.id })
      }
    }
  }
  const bannerHeight = 87;

  return (
    <UnstyledLink
      to={`/guided-club/${club.slug}/`}
      onClick={() => sendSegmentEvent("Visited Club from Directory", { clubname: club.name })}
    >
    <div
      css={css`
        background: white;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      `}
    >
      <div css={css`
        background-color: ${FC_LIGHT_BLUE};
        background-image: linear-gradient(
          to left, ${FC_LIGHTER_BLUE}, ${FC_LIGHT_BLUE}
        );
        height: ${bannerHeight}px;
        position: relative;
      `}>
          <img
          src={image}
          alt={name}
          css={css`
            width: 100%;
            height: ${bannerHeight}px;
            object-fit: cover;
          `}
          onError={(event) => {
            event.target.src = 'https://in.flow.club/flowclub_ogimage.png'
            event.onerror = null;
          }}
        />
        <div css={css`
          position: absolute;
          left: 16px;
          top: 16px;
    
          display: flex;
          align-items: center;
          gap: 4px;
        `}>
         <ClubPaymentBadge paymentType={paymentType} />
       </div>
      </div>
      <div css={css`
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      `}>
        <div css={css`
          display: flex;
          align-items: flex-start;
          gap: 12px;
          align-self: stretch;
        `}>
          <img
            src={hostImage}
            alt={`Host ${fullName}`}
            css={css`
              width: 60px;
              height: 60px;
              border-radius: 50%;
            `}
          />
          <div css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            gap: 2px;
            flex: 1 0 0;
          `}>
            <Text style={TEXT_STYLES.CAPTION} customCss={css`color: ${FC_IRIS_BLUE};`}>{fullName}</Text>
            <Text style={TEXT_STYLES.BODY} customCss={css`font-weight: 700;`}>{name}</Text>
          </div>
        </div>
        <LightGrayText customCss={css`max-width: 576px; text-align: left;`}>
          <ClubDescription description={description} maxChars={MAX_DESCRIPTION_CHARACTERS} />
        </LightGrayText>
      </div>
      <div css={css`
        margin: 0px 16px;
      `}>
        <div css={css`
          margin: 10px 0px;
          display: flex;
          
          align-items: center;
          gap: 16px;  
        `}>
          <Badge css={css`background-color: #D5E3E0;`} className="mr-2">
            {duration}
          </Badge>
          {startDate === undefined ?
            <Text style={TEXT_STYLES.CAPTION}>Indicate your interest</Text>
            :
            <Text style={TEXT_STYLES.CAPTION}>
               {start > now ?
               <>Starts <strong>{startDateFormatted}</strong></> :
               <>Started <strong>{startDateFormatted}</strong>{isOpenForSignups ? ' (still accepting members)' : ''}</>}
            </Text>
          }
          {seatsLeft <= 2 && startDate?
            <Text style={TEXT_STYLES.CAPTION}>{seatsLeftText}</Text>
            : null
          }
        </div>
        <div css={css`margin: 16px 0px;`}>
          <FlowButton buttonStyle={BUTTON_STYLES.GREEN} sizePreset={SIZE_PRESETS.CHUNKY} fillAvailableWidth onClick={registerOrIndicateInterest}>
            {startDate && seatsLeft > 0 ? 'See more info' : "I'm Interested"}
          </FlowButton>
        </div>
        {seatsLeft <= 0 ?
        <div css={css`margin: 10px 0px;`}>
          <Text style={TEXT_STYLES.CAPTION}>🏠 Full house! Indicate interest to join the waitlist and get notified for the next one.</Text>
        </div>
        : null
        }
      </div>
    </div>
    </UnstyledLink>
  )
}


const GuidedClubs = ({ activeClubs }) => {
  return (
    activeClubs !== null ?
      <ClubGallery clubs={activeClubs} />
      :
      <div css={css`display: flex; justify-content: center;`}><LoadingIndicator /></div>
    )
}

const CommunityClubs = ({badgeData, activeBadgeClubs, badgeMemberships}) => {
  return (
    badgeData !== null ?
    <>
    {activeBadgeClubs.length > 0 &&
      <ClubListings badges={activeBadgeClubs} badgeMemberships={badgeMemberships} />
    }
  </> :
  <div css={css`display: flex; justify-content: center;`}><LoadingIndicator /></div>
  )
}

const tabIndexPathNames = {
  0: '/clubs/community',
  1: '/clubs/guided',
}

const tabIndexToPathname = index => tabIndexPathNames[index]

export const ClubsDirectory = () => {
  const mobileView = useMediaQuery(MobileViewMediaQuery)
  
  const { user } = useContext(UserContext)
  const [selectedBadgeIds, setSelectedBadgeIds] = useState(new Set())

  const { result: badgeData } = useAutomaticallyFetch(useGetBadgesForUsers, { userIds: [] }, {})

  const { result: activeClubs } = useAutomaticallyFetch(useGetActiveClubs, {}, {})

  useEffect(() => {
    if (badgeData !== null && user !== null && badgeData.badgeMembershipsByUser[user.uid] !== undefined) {
      setSelectedBadgeIds(new Set(badgeData.badgeMembershipsByUser[user.uid].map(membership => membership.badgeId)))
    }
  }, [badgeData])

  const badges = badgeData !== null ? badgeData.badges : []
  const activeBadgeClubs = badges.filter(badge => badge.clubPage !== undefined)
  const badgeMemberships = badgeData !== null && user !== null && badgeData.badgeMembershipsByUser[user.uid] !== undefined ? new Set(badgeData.badgeMembershipsByUser[user.uid].map(membership => membership.badgeId)) : new Set([])
  
  const metaDescription = "Find people to co-work with from over 800+ hosts across clubs like the Neurodivergent Flow Club, Students Flow Club, Academics Flow Club, and Founders Flow Club."

  const { pathname } = useLocation()
  let initialTabIndex = parseInt(Object.keys(tabIndexPathNames).find(index => pathname.startsWith(tabIndexPathNames[index])) || 0)
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex)

  const history = useHistory()
  useEffect(() => {
    const resultingPathname = tabIndexToPathname(activeTabIndex)
    if (resultingPathname !== pathname) {
      history.replace(resultingPathname)
    }
  }, [activeTabIndex])

  const guidedClubDescription = "Guided Flow Clubs are coach and peer-led accountability groups that combines expert guidance with body doubling. They are limited to 10 people, run for a set period of time, and designed to support a specific goal. They can be free, paid, or tipping based."

  const communityClubDescription = "Community Clubs are open to all and have active hosts and members who identify with the club."


  const tabs = [
    { title: 'Community Clubs', Content: <CommunityClubs key="community" badgeData={badgeData} activeBadgeClubs={activeBadgeClubs} badgeMemberships={badgeMemberships} />, description: communityClubDescription },
    { title: 'Guided Clubs', Content: <GuidedClubs key="guided" activeClubs={activeClubs} />, description: guidedClubDescription },
  ]


  return (
    <div>
      <Helmet>
        <title>Find your Club in Flow Club</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content="Find your Club in Flow Club" />
        <meta property="og:description" content={metaDescription} />
        <link rel="canonical" href={`${baseURL}/clubs/`} />
      </Helmet>
      <div css={css`
        padding: ${mobileView ? '20px 0 10px 0' : '48px 0px 16px 0px'};
      `}>
        <Container css={css`padding: 0px 32px;`}>
        <Text style={TEXT_STYLES.MARKETING_H3} customCss={css`margin-bottom: ${mobileView ? 12 : 24}px;`}>Find your Clubs</Text>
      <Text style={TEXT_STYLES.BODY_1}>
      Join clubs to find others who share in your struggles and are striving towards common goals.</Text>
      </Container>
      </div>
      <div css={css`margin-bottom: 72px; text-align: center;`}>
        <TabbedInterface
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          showDescriptions
        />
      </div>
    </div>
  )
}