/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { FlowButton, Text, TextInput, TEXT_STYLES } from '../../../components/flowComponents';
import EventContext from '../../../EventContext';
import { UserContext } from '../../../UserProvider';
import { copyToClipboard } from '../../../utils';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import Icon, { TYPE_CLOSE } from '../Icon/Icon';

export const InvitePopup = ({ closeFn }) => {
  const { user } = useContext(UserContext)
  const { event } = useContext(EventContext)
  const sendSegmentEvent = useSendSegmentEvent()

  let inviteLink = `https://in.flow.club/j/${event.id}`
  if (user.inviteCode !== undefined) {
    inviteLink += `?inviteCode=${user.inviteCode}`
  }

  const copiedSuccessText = 'Invite link copied with your referral code!'
  const copyInviteLinkClicked = () => {
    sendSegmentEvent("Copied invite to session link", { inviteLink })
    copyToClipboard(inviteLink, { onSuccessMessage: copiedSuccessText })
  }

  return (
    <div css={css`display: flex; flex-direction: column; gap: 8px;`}>
      <div css={css`display: flex; justify-content: space-between; align-items: center;`}>
        <Text style={TEXT_STYLES.APP_H5}>Invite To Session</Text>
        <div css={css`cursor: pointer;`} onClick={closeFn}>
          <Icon type={TYPE_CLOSE} />
        </div>
      </div>
      <Text style={TEXT_STYLES.BODY_2}>No Flow Club account needed to join.</Text>
      <TextInput disabled value={inviteLink}/>
      <FlowButton onClick={copyInviteLinkClicked} fillAvailableWidth>Copy Invite Link</FlowButton>
    </div>
  )
}