import { useContext, useState } from 'react';
import { UserContext } from '../UserProvider';
import { useCreateExtremelyUnauthedUser } from '../fetch/endpoints';
import { auth } from '../firebase';
import { InviteInfoContext } from '../InviteInfoWrapper';

export const useSetupSignuplessUser = () => {
  const inviteInfo = useContext(InviteInfoContext)

  const { performFetch: createExtremelyUnauthedUser } = useCreateExtremelyUnauthedUser()
  const { preSignupUserData } = useContext(UserContext);

  const [loading, setLoading] = useState(false)
  const createAnonymousFirebaseUserAndFirestoreUser = async ({ displayName = null }) => {
    setLoading(true)

    await auth.signInAnonymously()
    const authUser = auth.currentUser
    const result = await createExtremelyUnauthedUser({
      userId: authUser.uid,
      inviteCode: inviteInfo.inviteCode,
      preSignupUserData,
      displayName
    })
    setLoading(false)
    return result
  }

  return {
    createAnonymousFirebaseUserAndFirestoreUser,
    loading
  }
}