import { useEffect } from "react"
import { usePrevious } from "../utils"

export const useMuteOnWorkingTimeStart = (activeWorkingTime, muteMic, automutingDisabled) => {
  const workingTimeJustStarted = !usePrevious(activeWorkingTime) && activeWorkingTime
  useEffect(() => {
    if (!automutingDisabled && workingTimeJustStarted) {
      muteMic()
    }
  }, [workingTimeJustStarted])
}