import { useEffect, useState } from 'react';
import { useGetSessionRecommendations } from '../fetch/endpoints';
import { toastError } from '../components/utils/toaster';
import { parseSessionRecommendations } from './parseSessionRecommendations';

export const useRecommendations = (date, effectiveSelectedSpan, spanSelectionFinished) => {
  const { performFetch: getSessionRecommendations, fetching: gettingSessionRecommendations } = useGetSessionRecommendations()
  
  // not using useGetSessionsToBookForNewUser().result because want the ability to manually clear the result later
  // might be nice at some point for usePerformFetch() to return a method to clear its .results so this isn't necessary, but w/e for now
  const [sessionRecommendations, setSessionRecommendations] = useState([])
  const requestRecommendations = async () => {
    const minTimestamp = date.minute(effectiveSelectedSpan.startMinutes).valueOf()
    const maxTimestamp = date.minute(effectiveSelectedSpan.endMinutes).valueOf()
    let { result, success, error } = await getSessionRecommendations({ minTimestamp, maxTimestamp })
    if (success) {
      setSessionRecommendations(parseSessionRecommendations(result.recommendations))
    } else {
      toastError({ message: `Error getting sessions for the timeblock you selected: ${error}. Please email help@flow.club.` })
    }
  }

  useEffect(() => {
    if (spanSelectionFinished) {
      requestRecommendations()
    }
  }, [spanSelectionFinished])

  const clearSessionRecommendations = () => setSessionRecommendations([])

  return {
    sessionRecommendations,
    gettingSessionRecommendations,
    clearSessionRecommendations
  }
}