/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { Text, TEXT_STYLES } from './components/flowComponents';
import { TooltipWrapper } from './components/TooltipWrapper';
import { useSendSegmentEvent } from './wrappers/SegmentProvider';

export const BadgeEmojiIcon = ({ badge }) => {
  const { emoji, name } = badge
  return (
    <span css={css`user-select: none;`}>
      <TooltipWrapper TooltipComponent={BadgeEmojiTooltip} TooltipContents={<BadgeEmojiTooltipContents name={name} />} useFloatingArgs={{ placement: 'top' }}>
        {emoji}&nbsp;
      </TooltipWrapper>
    </span>
  )
}

const BadgeEmojiTooltipContents = ({ name }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  useEffect(() => {
    sendSegmentEvent("Badge Emoji Moused Over", { badgeName: name })
  }, [])

  return (
    <Text style={TEXT_STYLES.CAPTION}>{name}</Text>
  )
}

const BadgeEmojiTooltip = ({ children, arrowX, arrowRef }) => {
  return (
    <>
      <div css={css`
        padding: 4px 8px;
        background-color: black;
        border-radius: 8px;
        color: white;
      `}>
        {children}
      </div>
      <div css={css`
        /* A slightly hacky extra element to add the little wedge at the top */
        left: ${arrowX !== null ? `${arrowX}px` : ''};
        bottom: -16px;
        position: absolute;
        border: 8px solid; 
        border-color: black transparent transparent transparent;
      `} ref={arrowRef} />
    </>
  )
}