/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Modal } from "react-bootstrap"
import { useContext, useEffect } from 'react';
import { useUpdateUser } from '../fetch/endpoints';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { UserContext } from '../UserProvider';

export const IntroVideoModal = ({ onHide }) => {

  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: updateUser } = useUpdateUser()
  const { user, setPreSignupUserData } = useContext(UserContext)
  const setUserWatchedIntroVideo = async () => {
    if (user !== null) {
      await updateUser({ updateObject: { watchedIntroVideo: true } })
    } else {
      setPreSignupUserData({ watchedIntroVideo: true })
    }
    sendSegmentEvent("Watched Intro Video")
  }

  useEffect(() => {
    setUserWatchedIntroVideo()
  }, [])

  return (
    <Modal
      show
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
      <div css={css`
        position: relative;
        padding-bottom: 177.77777777777777%;
        height: 0;
      `}>
        <iframe
          src="https://www.loom.com/embed/672be34401d744b99e2db752bdd87775?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&autoplay=1"
          frameBorder="0"
          webkitAllowFullScreen={true}
          mozAllowFullScreen={true}
          allowFullScreen={true}
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}></iframe>
        </div>
      </Modal.Body>
    </Modal>
  )
}