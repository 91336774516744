
import { createICSEvent } from '../../functions/shared/calHelpers'

const icsFileName = 'invite.ics'

export const getICSFileUrl = async (event) => {
  const { error, value } = await createICSEvent({event, calendarType: 'apple-calendar'})
  if (error) {
    throw error
  }
  return new File([value], icsFileName, { type: 'text/calendar' })
}

// This must be called within a click context
export const downloadICSFile = async (event) => {
  const icsFile = await getICSFileUrl(event)
  const url = URL.createObjectURL(icsFile);

  // trying to assign the file URL to a window could cause cross-site
  // issues so this is a workaround using HTML5
  const anchor = document.createElement('a');
  anchor.href = url
  anchor.download = icsFileName

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}
