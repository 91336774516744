/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FC_BLUE, FC_DARK_BLUE, FC_GREEN } from '../emotionVariables'
import { useGetUserHoursBookedThisWeek } from '../fetch/endpoints'
import { useAutomaticallyFetch } from '../fetch/helpers'
import { usePreferences } from '../UserProvider'
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { DarkText } from './flowComponents'
import { TooltipWrapper } from './TooltipWrapper'

const INCOMPLETE_COLOR = FC_BLUE
const COMPLETE_COLOR = FC_GREEN

export const WeeklyGoalProgressIndicator = () => {
  const { weeklyHoursGoal } = usePreferences()
  const { result: hoursBooked, fetching } = useAutomaticallyFetch(useGetUserHoursBookedThisWeek, {}, { transform: ({ hoursBooked }) => hoursBooked })

  if (weeklyHoursGoal === undefined || weeklyHoursGoal === 0 || fetching) {
    return null
  }

  const goalPortionMet = Math.min(1, hoursBooked / weeklyHoursGoal)
  const flooredHoursBooked = Math.floor(hoursBooked)

  return (
    <SegmentProvider baseData={{ hoursBooked, weeklyHoursGoal }} eventLabel={'Weekly Goal Progress Indicator'}>
      <div css={css`margin-right: 16px;`}>
        <TooltipWrapper TooltipComponent={WeeklyProgressTooltip} TooltipContents={<WeeklyProgressTooltipContents goalSessions={weeklyHoursGoal} hoursBooked={hoursBooked} goalPortionMet={goalPortionMet} />} useFloatingArgs={{ placement: 'bottom-end' }}>
          <ProgressCircle portionFilled={goalPortionMet}>
            {flooredHoursBooked}
          </ProgressCircle>
        </TooltipWrapper>
      </div>
    </SegmentProvider>
  )
}

export const WeeklyProgressTooltip = ({ children }) => {
  return (
    <div css={css`
      padding: 24px 36px;
      background: white;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    `}>
      {children}
    </div>
  )
}

const WeeklyProgressTooltipContents = ({ goalSessions, hoursBooked, goalPortionMet }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const editGoalLinkClicked = () => {
    sendSegmentEvent("Edit Goal Link Clicked")
  }
  
  const goalMet = goalPortionMet >= 1

  const flooredHoursBooked = Math.floor(hoursBooked)

  return (
    <div>
      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}>
        <DarkText customCss={css`
          font-family: 'Sato';
          font-weight: 500;
          font-size: 20px;
        `}>
          Weekly Progress
        </DarkText>
        <Link to="/settings/goals" onClick={editGoalLinkClicked}>
          <div css={css`
            font-family: 'Sato';
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: ${FC_DARK_BLUE};
          `}>
            Edit Goal
          </div>
        </Link>
      </div>
      <div css={css`
        width: 348px;
        height: 20px;
        background-color: #F5F5F5;
        border-radius: 4px;

        margin-top: 24px;
      `}>
        <div css={css`
          background-color: ${!goalMet ? INCOMPLETE_COLOR : COMPLETE_COLOR};
          border-radius: 4px;
          width: ${goalPortionMet * 100}%;
          height: 100%;
        `}/>
      </div>
      <div css={css`
        letter-spacing: 0.5px;
        color: #626262;

        margin-top: 24px;

        ${goalMet && css`color: ${COMPLETE_COLOR}; font-weight: bold; filter: brightness(0.9);`}
      `}>
        <span css={css`font-weight: 800;`}>{flooredHoursBooked}/{goalSessions}</span> hours of Flow Club booked{goalMet ? '!' : ''}
      </div>
    </div>
  )
}

export const ProgressCircle = ({ mousedOverActive, portionFilled = 0, children }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  useEffect(() => {
    if (mousedOverActive) {
      sendSegmentEvent("Progress Circle Moused Over")
    }
  }, [mousedOverActive])

  const diameter = 40;
  const ringWidth = 6;
  const circumference = Math.PI * diameter

  return (
    <div css={css`
      width: ${diameter + ringWidth}px;
      height: ${diameter + ringWidth}px;

      display: flex;
      align-items: center;
      justify-content: center;
    `}>
      <div css={css`
        width: ${diameter}px;
        height: ${diameter}px;

        position: relative;
      `}>
        <div css={css`
          width: 100%;
          height: 100%;
          
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
          <div css={css`
            font-size: 12px;
            letter-spacing: 0.4px;
            color: #404040;
          `}>
            {children}
          </div>
        </div>
        <svg css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
          overflow: visible;
        `}>
          <circle cx="50%" cy="50%" r="50%" css={css`
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: ${ringWidth};
            stroke-dasharray: ${circumference};
            stroke-dashoffset: ${circumference - (circumference * portionFilled)};
            stroke: ${portionFilled < 1 ? INCOMPLETE_COLOR : COMPLETE_COLOR};
            stroke-linecap: butt;
          `}/>
        </svg>
        <svg css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
          overflow: visible;
        `}>
          <circle cx="50%" cy="50%" r="50%" css={css`
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: ${ringWidth};
            stroke-dasharray: ${circumference} ${circumference * portionFilled};
            stroke-dashoffset: ${circumference};
            stroke: #F5F5F5;
            stroke-linecap: butt;
          `}/>
        </svg>
      </div>
    </div>
  )
}