/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Children, cloneElement, isValidElement, useEffect, useRef, useState } from 'react';
import { shift, offset, arrow, autoUpdate, autoPlacement } from '@floating-ui/react-dom';
import { useFloating, useInteractions, useHover, safePolygon, useDismiss } from '@floating-ui/react-dom-interactions';
import { useDebounced } from '../utils';
import { useMediaQuery } from 'react-responsive';
import { Text } from './flowComponents';

export const TooltipWrapper = ({ children: RenderContent, TooltipContents, TooltipComponent = Tooltip, sharedMouseoverState = null, retainMousedOverInsideTooltip = true, useFloatingArgs = {}, disabled = false, showOnMobile = false, shouldAutoAlign = false }) => {
  const mobileView = useMediaQuery({query: '(max-width: 767px)'})
  const mouseoversEnabled = (!mobileView || showOnMobile) && TooltipContents !== null

  // Some overcomplicated nonsense to animate entry/exit owing to floating-ui related timing complications
  const animationTime = 200 // ms
  const localMouseoverState = useState(false)
  const [mousedOver, setMousedOver] = sharedMouseoverState || localMouseoverState

  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [renderTooltip, setRenderTooltip] = useState(false)
  const [unmountTooltipAfterFadeOut, cancelUnmount] = useDebounced(() => setRenderTooltip(false), animationTime)
  useEffect(() => {
    if (mousedOver && mouseoversEnabled) {
      setRenderTooltip(true)
      cancelUnmount()
      setTimeout(() => setTooltipVisible(true), 0)
    } else {
      unmountTooltipAfterFadeOut()
      setTooltipVisible(false)
    }
  }, [mousedOver])

  const arrowRef = useRef(null)

  const tooltipMiddlewareOptions = shouldAutoAlign ?
    [
      shift({ padding: 32 }),
      offset(12),
      autoPlacement({
        allowedPlacements:['bottom', 'top']
      }),
      arrow({ element: arrowRef }),
    ] : [
      shift({ padding: 32 }),
      offset(12),
      arrow({ element: arrowRef }),
    ];
  const floatingUIData =  useFloating({
    open: mousedOver,
    onOpenChange: setMousedOver,
    placement: 'bottom',
    middleware: tooltipMiddlewareOptions,
    whileElementsMounted: (...args) => autoUpdate(...args, { ancestorScroll: true }),
    ...{
      ...useFloatingArgs,
      middleware: [...useFloatingArgs.middleware ?? [], arrow({ element: arrowRef })]
    }
  });
  const { x, y, reference, floating, strategy, middlewareData, context: floatingContext, placement: floatingPlacement, refs: floatingRefs } = floatingUIData
  const { x: arrowX, y: arrowY } = middlewareData.arrow || {};

  const { getReferenceProps, getFloatingProps } =
    useInteractions([
      useHover(floatingContext, retainMousedOverInsideTooltip ? {
        handleClose: safePolygon()
      } : {}),
      useDismiss(floatingContext, { enabled: mobileView, ancestorScroll: true })
    ]);

  const WrappedRenderContent =
    <span ref={reference} {...(retainMousedOverInsideTooltip ? getReferenceProps() : {})}>
      {
        isValidElement(RenderContent) ?
          Children.map(RenderContent, child =>
            cloneElement(child, {
              mousedOver,
              mousedOverActive: mousedOver && tooltipVisible,
            })
          ) :
          RenderContent
      }
    </span>

  return (
    <>
      {WrappedRenderContent}
      <div css={css`position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; pointer-events: none; z-index: 1;`}>
        {!disabled && renderTooltip && <div
          ref={floating}
          // this doesn't work with the css={} property, I would love to say I understand why but I absolutely don't
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
          css={css`
            transition: opacity ${animationTime / 1000}s;
            opacity: ${tooltipVisible ? 1 : 0};
            z-index: 1;
            max-width: 1000px;
            ${retainMousedOverInsideTooltip && css`pointer-events: initial;`};
          `}
          {...(retainMousedOverInsideTooltip ? getFloatingProps() : {})}
        >
          <TooltipComponent arrowX={arrowX} arrowRef={arrowRef} placement={floatingPlacement} refs={floatingRefs}>{TooltipContents}</TooltipComponent>
        </div>}
      </div>
    </>
  )
}

export const Tooltip = ({ children, customCss }) => {
  return (
    <div css={css`
      padding: 12px 12px 18px 12px;
      background: white;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      text-align: center;
      width: 224px;

      ${customCss}
    `}>
      <Text>
        {children}
      </Text>
    </div>
  )
}