/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FlowButton } from '../components/flowComponents';
import { FC_DARK_BLUE, FC_LIGHTER_BLUE } from '../emotionVariables';
import { GoogleLogo24, Mail } from '../shared/components/icons';

const LoginButton = ({ children, customCss, onClick, disabled, loading }) => {
  return (
    <FlowButton
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      fillAvailableWidth
      customCss={css`
        height: 55px;
        box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.04);
        ${customCss}
      `}
    >
      {children}
    </FlowButton>
  )
}

export const GoogleLoginButton = ({ customCss, onClick, disabled, loading, children: buttonText = "Log In With Google" }) => {
  return (
    <LoginButton
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      customCss={css`
        background-color: #FFFFFF;
        &:hover { background-color: #FFFFFF; }
        &:active { background-color: #FFFFFF; }
        border: 1px solid ${FC_DARK_BLUE};
        color: ${FC_DARK_BLUE};
        ${customCss}
      `}
    >
      <GoogleLogo24 width={22} height={22} />
      <span css={css`margin-left: 12px;`}>{buttonText}</span>
    </LoginButton>
  )
}

export const EmailLoginButton = ({ onClick, disabled, loading, children: buttonText = "Log In With Email" }) => {
  return <LoginButton onClick={onClick} disabled={disabled} loading={loading}>
    <Mail/>
    <span css={css`margin-left: 16px;`}>{buttonText}</span>
  </LoginButton>
}