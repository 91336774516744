import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDelay } from "../../utils";

/**
 * Stolen from the internet and modified to be a bit more flexible with additionalIgnoredRefs
 * Detects clicks (or escape key presses) made outside the designated element
 * onTriggered - callback to be called when the element is clicked out of
 * additionalIgnoredRefs - refs to any additional elements which should not trigger the onTriggered callback
 * @returns targetRef - to be attached to the element in question
 */
export const useClickOutside = ({ onTriggered, additionalIgnoredRefs = [], delay = 100 }) => {
  // without any delay, elements that are opened by a click event and use useClickOutside tend to have their useClickOutside callback (e.g. closing them)
  // immediately called by the same event that opened them. which is a bit dumb (and also baffling to me that it happens at all, but here we are)
  const delayElapsed = useDelay(delay)

  const triggerCallback = useCallback((...args) => {
    if (delayElapsed) {
      onTriggered(...args)
    }
  }, [onTriggered, delayElapsed])

  const targetRef = useRef(null);

  const keyListener = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        triggerCallback(event, 'keyup');
      }
    },
    [triggerCallback]
  );
  const clickOrTouchListener = useCallback(
    (event) => {
      const elementRefsToCheck = [targetRef, ...additionalIgnoredRefs]
      if (elementRefsToCheck.every(ref => ref && ref.current && !(ref.current).contains(event.target))) {
        triggerCallback(event, 'click');
      }
    },
    [triggerCallback, additionalIgnoredRefs]
  );

  const eventsConfig = useMemo(
    () => [
      ['click', clickOrTouchListener],
      ['touchstart', clickOrTouchListener],
      ['keyup', keyListener],
    ],
    [clickOrTouchListener, keyListener]
  );

  useEffect(() => {
    eventsConfig.forEach((eventConfigItem) => {
      const [eventName, listener] = eventConfigItem;
      document.addEventListener(eventName, listener);
    });

    return () => {
      eventsConfig.forEach((eventConfigItem) => {
        const [eventName, listener] = eventConfigItem;
        document.removeEventListener(eventName, listener);
      });
    };
  }, [eventsConfig]);

  return targetRef
}