/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { animated, config, useTransition } from '@react-spring/web'
import { usePreviousSticky } from '../utils';

export const FlowCarousel = ({ children: items, currentItemIndex }) => {
  const prevItemIndex = usePreviousSticky(currentItemIndex)

  const transitions = useTransition(currentItemIndex, {
    keys: null,
    from: index => {
      const targetX = prevItemIndex > index ? '-120' : prevItemIndex < index ? '120' : '0'
      return { opacity: 0, transform: `translate3d(${targetX}%,0,0)` }
    },
    enter: { opacity: 1, transform: `translate3d(${0}%,0,0)` },
    leave: index => {
      const targetX = index > currentItemIndex ? '100' : index < currentItemIndex ? '-100' : '0'
      return { opacity: 0, transform: `translate3d(${targetX}%,0,0)` }
    },
    config: config.slow,
  })

  return (
    <div css={css`
      position: relative;
      width: 100%;
    `}>
      {transitions((style, index) => {
        const CarouselItem = items[index]
        return <div css={css`position: absolute; top: 0px; left: 0px; width: 100%;`}>
          <animated.div style={style}>
            <div css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}>
              {CarouselItem}
            </div>
          </animated.div>
        </div>
      })}
    </div>
  )
}