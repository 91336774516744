import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom'
import { UserContext } from './UserProvider';
import { IS_PROD } from './utils';


function AuthedRoute({ children, requireSignedIn = false, requireAdmin = false, hideInProduction = false, requireAuth = null, ...props }) {
  const location = useLocation();
  const { user, userHasAdminPermissions } = useContext(UserContext);
  
  if (hideInProduction && IS_PROD) {
    return <Redirect to={"/"}/>
  }

  if (requireAdmin && (user === null || !userHasAdminPermissions)) {
    return <Redirect to={"/"} />
  }

  if (requireSignedIn && user === null) {
    return <Redirect to={"/login/?redirect=" + location.pathname} />
  }

  if (requireAuth !== null && !requireAuth(user)) {
    return <Redirect to={"/"} />
  }

  return (
    <Route {...props}>
      {children}
    </Route>
  )
}

export default AuthedRoute;