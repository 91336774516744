import { toast } from 'react-hot-toast';
import { FC_BLUE } from '../../emotionVariables';

/**
 * Returns an error toast with the appropriate styling
 * @param message - the message or JSX element of the error
 * @param duration - how long the Toast should stay on screen, use Infinity to make it persistent
 * @returns a toast
 */

const errorStyle = {
  borderRadius: '15px',
  background: '#B75C4A',
  color: '#fff',
}

const successStyle = {
  borderRadius: '15px',
  background: '#2fc6a5',
  color: '#fff',
}

const infoStyle = {
  borderRadius: '15px',
  background: FC_BLUE,
  color: '#fff',
}

export function toastError({ message, duration = 5000 }) {
  return toast(message,
    {
      duration,
      style: errorStyle,
    }
  );
}

export function toastErrorUntilDismissed({ message }) {
  return toast(
    message,
    {
      duration: Infinity,
      style: errorStyle,
    }
  )
}

/**
 * Returns a success toast with the appropriate styling
 * @param message - the message or JSX element of the success
 * @param duration - how long the Toast should stay on screen, use Infinity to make it persistent
 * @returns a toast
 */
export function toastSuccess({ message, duration = 4000 }) {
  return toast(message,
    {
      duration,
      style: successStyle,
    }
  );
}

export function toastInfo({ message, duration = 4000 }) {
  return toast(message,
    {
      duration,
      style: infoStyle,
    }
  );
}