/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Alert,
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import './banner.scss'

export const Banner = ({ children, onClose, ...props }) => {
  return (
    <Alert onClose={onClose} className="top-banner" variant="info" dismissible {...props}>
      <Container>
        <div css={css`display: flex; justify-content: center;`}>
          {children}
        </div>
      </Container>
    </Alert>
  )
}