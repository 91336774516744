/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import '../Schedule.scss';
import {
  Button,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import dayjs from 'dayjs'
import { UserContext } from '../../UserProvider';
import { groupBy } from '../../utils';
import { attendeeBookEndpoint, attendeeCancelEndpoint } from '../../shared/api';
import { JOIN_SESSION_BEFORE_START_PERIOD_MIN } from '../../../functions/shared/constants';

export function RescheduleModal({ eventToReschedule, events, closeFn = () => {}, onSuccess }) {
  const { authUser, user } = useContext(UserContext);
  const [rescheduling, setRescheduling] = useState(false);
  const [rescheduled, setRescheduled] = useState(false);
  const { register, handleSubmit } = useForm();
  const [newEvent, setNewEvent] = useState(null);

  const eventsWithDates = events.map(event => ({ ...event, date: dayjs(event.start).format('YYYY-MM-DD') }))
  const availableEvents = eventsWithDates.filter(event => eventToReschedule === null || event.id !== eventToReschedule.id)

  const rescheduleBooking = async (data) => {
    setRescheduling(true)
    if (authUser !== null) {
      const token = await authUser.getIdToken()
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          eventId: eventToReschedule.id,
          displayName: user.displayName,
          email: user.email,
          userId: user.uid,
          reason: "Rescheduled",
        })
      };
      fetch(attendeeCancelEndpoint, requestOptions)
        .then(response => {
          // Doesn't have auth yet
          const newRequestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
              eventId: data.eventId,
              displayName: user.displayName,
              userId: user.uid,
              tags: user.tags,
            })
          };
          fetch(attendeeBookEndpoint, newRequestOptions)
            .then(response => {
              setNewEvent(availableEvents.find(event => event.id === data.eventId))
              setRescheduling(false);
              setRescheduled(true);
              onSuccess()
            })
        }).catch((error) => {
          console.error(error);
          setRescheduling(false);
          setRescheduled(false);
        }).catch((error) => {
          console.error(error);
          setRescheduling(false);
          setRescheduled(false);
        })
    }
  }

  return (
    <Modal
      show
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeFn}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {rescheduling ?
            <span>
              <Spinner animation="grow" variant="primary" className="mr-2" />
              Working on your reschedule request...
            </span> :
            rescheduled ? "Rescheduled!" : "Reschedule"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rescheduling ?
          <div>
            We'll update your host, {eventToReschedule.hostUser.displayName},
            that you're no longer able to make it on {dayjs(eventToReschedule.start).format('ddd MMMM, D @ h:mma z')}.<br />
          </div>
          :
          rescheduled ?
            <div>
              We'll see you at {newEvent.title} on <span className="font-weight-bold">
                {dayjs(newEvent.start).format('ddd MMMM, D @ h:mma z')}.
              </span>
              <div className="font-weight-light my-2">
                Check your Google Calendar or email for the event invite.<br />
                Or, access the event on this page {JOIN_SESSION_BEFORE_START_PERIOD_MIN} minutes before the session starts.
              </div>
              <div className="float-right">
                <Button variant="primary" type="button" className="my-3 mr-3" onClick={closeFn}>
                  Close
                </Button>
              </div>
            </div>
            :
            <Form onSubmit={handleSubmit(rescheduleBooking)}>
              <Form.Group controlId="rescheduleTime">
                <Form.Label>Pick another session:</Form.Label>
                <Form.Control as="select" name="eventId" ref={register}>
                  {Object.entries(groupBy(availableEvents, 'date')).map(([date, dayEvents]) => (
                    <optgroup label={dayjs(date).format('ddd D')} key={date}>
                      {dayEvents.sort((a, b) => (
                        (a.start === b.start) ?
                          ((a.host > b.host) ? 1 : -1) : ((a.start > b.start) ? 1 : -1)
                      )).map((event, eventIndex) => (
                        <option value={event.id} key={eventIndex}>
                          {dayjs(event.start).format('ddd D @ h:mma z')} with {event.hostUser.displayName} ({event.duration} min)
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Form.Control>
              </Form.Group>
              <div className="float-right">
                <Button variant="link" type="button" className="my-3 mr-3" onClick={closeFn}>
                  Never mind
                </Button>
                <Button variant="primary" type="submit" className="my-3">
                  Confirm
                </Button>
              </div>
            </Form>
        }
      </Modal.Body>
    </Modal>
  );
}