/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { LightGrayText } from '../components/flowComponents'
import { EventCard } from '../Sessions/EventCard'
import { SegmentProvider } from '../wrappers/SegmentProvider'

export const RecommendedSession = ({ session, recommendationType }) => {
  return (
    <div css={css`display: flex; flex-flow: column; justify-content: center; align-items: center;`}>
      <h4 css={css`margin-top: 36px; margin-bottom: 16px;`}>Book another Flow Club</h4>
      <LightGrayText customCss={css`margin-bottom: 24px;`}>
        We found a session that you might enjoy. Book your next session now!
      </LightGrayText>
      <SegmentProvider baseData={{ postSessionRecommendedSessionType: recommendationType }}>
        <EventCard
          event={session}
          showDate={true}
        />
      </SegmentProvider>
    </div>
  )
}