/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import './plans.scss'
import {
  useContext,
} from 'react'
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import { useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { FlowButton } from '../components/flowComponents'
import { baseURL } from '../firebase'
import { UserContext } from '../UserProvider'

import { FC_GOLD } from '../emotionVariables'

export const PlansPricing = ({ showDetail = true, location = "Top" }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext)
  const cta = user === null ? 'Try it free' : 'Subscribe'
  const tryItFreeUrl = baseURL
  const onCtaClick = (plan) => {
    if (user === null) {
      sendSegmentEvent("Clicked on Try it free button", { location })
      window.location.href = tryItFreeUrl
    } else {
      sendSegmentEvent(`Initiated Stripe checkout for ${plan} membership`, { location })
      sendSegmentEvent("Initiated Stripe checkout for all plans", { location })
      window.location.href = `${baseURL}/membership/${plan}`
    }
  }

  return (
    <section className="plans-section section-pricing">
      <div>
        <div className="section-header">
          <h2>{user === null ? "Try Flow Club free today" : "Choose a plan"}</h2>
          {showDetail &&
          <Container>
            <Row>
              <Col>
                {user === null ?
                  <p>
                    7-day free trial. No credit card required.<br />
                    <br />
                    Join thousands of committed members in organized co-working sessions.
                  </p>
                  :
                  <p>
                    Join thousands of members in our community.
                    <span className="hide-sm"><br/></span>
                    You'll be able to access structured co-working sessions and accountability whenever you need it.
                  </p>
                }
              </Col>
            </Row>
          </Container>}
        </div>
        <div className="pricing-pills">
          <div className="pricing-pill">
            <div css={css`
              text-transform: uppercase;
              background: ${FC_GOLD};
              color: white;
              font-weight: 800;
              font-size: .75rem;

              position: absolute;
              padding: 4px 16px;
              top: -16px;
              right: -20px;
            `}>
              2 months free!
            </div>
            <h4>Yearly Plan</h4>
            <div className="pricing">
                <span className="price">$33.33</span>/month <br/>
                Billed Annually
            </div>
            <FlowButton fillAvailableWidth onClick={() => onCtaClick('yearly')}>
              {cta}
            </FlowButton>
          </div>
          <div className="pricing-pill">
              <h4>Monthly Plan</h4>
              <div className="pricing">
                  <span className="price">$40</span>/month
                  <br/><br/>
              </div>
              <FlowButton fillAvailableWidth onClick={() => onCtaClick('monthly')}>
                {cta}
              </FlowButton>
          </div>
        </div>
      </div>
    </section>
  )
}