/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSaveUserGoogleCalendar } from '../fetch/endpoints';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { toastError } from '../components/utils/toaster';
import { LoadingIndicator } from '../components/flowComponents';
import { translateAuthErrorToToast } from '../components/utils/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLoginButton } from '../auth/loginButtons';

export const ConnectCalendarButton = ({ onSuccess = () => {} }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: saveUserGoogleCalendar, fetching: savingUserGoogleCalendar } = useSaveUserGoogleCalendar()

  async function onCalendarAuthSuccess(response) {
    const code = response.code

    const getRefreshTokenResult = await saveUserGoogleCalendar({ code })
    if (!getRefreshTokenResult.success) {
      displayError(getRefreshTokenResult.error)
      return
    }
    if (!getRefreshTokenResult.result.success) {
      console.error("getting refresh token broke for unclear reasons. BE didn't tell us why. RIP.")
      displayError("Oops! Something went wrong here. Please email help@flow.club for assistance.")
      return
    }
  }

  async function onCalendarAuthFailure(response) {
    translateAuthErrorToToast({ errorCode: response.error })
    console.error(response.error)
  }

  const displayError = (error) => {
    toastError({ message: error })
    console.error(error)
  }

  const authButtonClicked = () => {
    sendSegmentEvent("Google Calendar Auth Button Clicked")
  }

  const authWithGoogle = useGoogleLogin({
    onSuccess: onCalendarAuthSuccess,
    onError: onCalendarAuthFailure,
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events',
  });

  return (
    <>
      <div onClick={authButtonClicked}>
        <GoogleLoginButton onClick={authWithGoogle} customCss={css`height: 40px;`}>Sign In With Google</GoogleLoginButton>
      </div>
      {savingUserGoogleCalendar &&
        <div css={css`margin-top: 20px;`}>
          <LoadingIndicator />
        </div>
      }
    </>
  )
}