/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_IRIS_BLUE, FC_LIGHTER_IRIS_BLUE, FC_LIGHT_GREEN, FC_LIGHT_BLUE, FC_BLUE, NEUTRAL_40, NEUTRAL_60, NEUTRAL_80 } from '../emotionVariables';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toTitleCase } from '../../functions/shared/helpers';
import { DURATION_OPTIONS } from '../../functions/shared/constants';
import { useMediaQuery } from 'react-responsive';

const stageColors = {
  'share goals': { filled: FC_IRIS_BLUE, unfilled: FC_LIGHTER_IRIS_BLUE },
  'muted deep work': { filled: FC_LIGHT_GREEN, unfilled: FC_LIGHT_BLUE},
  'break & check in': { filled: FC_IRIS_BLUE, unfilled: FC_LIGHTER_IRIS_BLUE },
  'break': { filled: NEUTRAL_60, unfilled: NEUTRAL_40 },
  'celebrate': { filled: FC_IRIS_BLUE, unfilled: FC_BLUE },
};

export const SessionStageProgressBars = ({ stages, minutesElapsedThisStage, currentStage }) => {
  return (
    <div css={css`display: flex;`}>
      {stages.map((stage, index) => (
        <div style={{ flex: stage.duration }} key={`${stage.name}${index}`}>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 750 }}
            overlay={AgendaTooltip(`${stage.duration}m ${stage.name}`)}
            key={index}
          >
            <div>
              <ProgressBar fillPortion={currentStage > index ? 1 : (currentStage < index ? 0 : (minutesElapsedThisStage / stages[currentStage].duration))} filledColor={stageColors[stage.name]?.filled || FC_LIGHT_GREEN} unfilledColor={stageColors[stage.name]?.unfilled || FC_LIGHT_BLUE} isFirst={index === 0} isLast={index === stages.length - 1} />
            </div>
          </OverlayTrigger>
        </div>
      ))
      }
    </div>
  )
}

const MAX_DURATION = Math.max(...DURATION_OPTIONS);

const scaleFunction = (duration) => {
  return 0.5 + 0.5 * (duration / MAX_DURATION);
};


export const OutOfSessionProgressBars = ({ stages }) => {
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })
  const uniqueStageNames = new Set(stages.map(stage => stage.name));
  const totalDuration = stages.reduce((sum, stage) => sum + stage.duration, 0);
  const scaledWidth = scaleFunction(totalDuration);

  return (
    <div css={css`width: 100%;`}>
      <div css={css`
        display: flex;
        height: 12px;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
        width: ${scaledWidth * 100}%;
      `}>
        {stages.map((stage, index) => (
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 750 }}
            overlay={AgendaTooltip(`${stage.duration}m ${stage.name}`)}
            key={index}
          >
          <div
            key={`${stage.name}${index}`}
            css={css`
              flex: ${stage.duration};
              height: 100%;
              background-color: ${stageColors[stage.name]?.unfilled || (stage.activeWorkingTime ? NEUTRAL_40 : FC_LIGHTER_IRIS_BLUE)};
              ${index > 0 && `margin-left: 2px;`}
              ${index === 0 ? 'border-top-left-radius: 6px; border-bottom-left-radius: 6px;' : ''}
              ${index === stages.length - 1 ? 'border-top-right-radius: 6px; border-bottom-right-radius: 6px;' : ''}
            `}
          />
          </OverlayTrigger>
        ))}
      </div>
      <div css={css`
        display: flex;
        width: ${mobileView ? 100 : scaledWidth * 100}%;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        font-size: 12px;
        color: ${NEUTRAL_80};
      `}>
        {Array.from(uniqueStageNames).map((stageName, index) => {
          const stageColor = stageColors[stageName] || {
            unfilled: stages.find(stage => stage.name === stageName).activeWorkingTime ? NEUTRAL_40 : FC_LIGHTER_IRIS_BLUE
          };
          return (
          <div key={index} css={css`display: flex; align-items: center;`}>
            <div css={css`
              width: 12px;
              height: 12px;
              background-color: ${stageColor.unfilled};
              margin-right: 4px;
              border-radius: 2px;
            `}/>
            <span>{toTitleCase(stageName)}</span>
          </div>
        );
        })}
      </div>
    </div>
  )
}

const AgendaTooltip = (description) => <Tooltip className="in-session-tooltip">{description}</Tooltip>

const ProgressBar = ({ fillPortion, unfilledColor, filledColor, isFirst, isLast }) => {
  return (
    <div css={css`
      height: 12px;
      background-color: ${unfilledColor};

      ${isFirst ? 'border-top-left-radius: 6px; border-bottom-left-radius: 6px;' : ''}
      ${isLast ? 'border-top-right-radius: 6px; border-bottom-right-radius: 6px;' : ''}
      margin: 1px;
    `}>
      <div css={css`
        background-color: ${filledColor};
        ${isFirst ? 'border-top-left-radius: 6px; border-bottom-left-radius: 6px;' : ''}
        ${isLast ? 'border-top-right-radius: 6px; border-bottom-right-radius: 6px;' : ''}
        width: ${Math.min(fillPortion, 1) * 100}%;
        height: 100%;
      `}/>
    </div>
  )
}