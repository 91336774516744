/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { DayColumn } from '../../CalendarView/DayColumn';
import { CalendarViewScheduleContext } from './CalendarViewSchedule';
import dayjs from 'dayjs'
import { NowLine } from '../../CalendarView/NowLine';
import { UserContext } from '../../UserProvider';
import { FC_IRIS_BLUE } from '../../emotionVariables';
import { ExclusiveMouseoverWrapper } from '../../Lounge/ExclusiveMouseoverWrapper';
import { Tooltip, TooltipWrapper } from '../../components/TooltipWrapper';
import { EventCard } from '../EventCard';
import { flip, shift } from '@floating-ui/react-dom-interactions';
import { groupObjectsByProperty, sortBy } from '../../../functions/shared/helpers';
import { DATE_FORMAT } from '../../../functions/shared/constants';
import { ScheduleContext } from './Schedule';

export const CalendarViewScheduleDayColumnWrapper = ({ date }) => {
  const { user } = useContext(UserContext)
  const { sessionsByDay } = useContext(CalendarViewScheduleContext)
  const { refetchSchedulePageData } = useContext(ScheduleContext)

  const sessionsInDay = sessionsByDay[date.format(DATE_FORMAT)] ?? []
  const sessions = sessionsInDay
    .map(session => {
      const sessionStart = dayjs(session.start)
      const sessionEnd = dayjs(session.end)
      return {
        ...session,
        hour: sessionStart.hour(),
        startMinutes: sessionStart.hour() * 60 + sessionStart.minute(),
        endMinutes: sessionEnd.hour() * 60 + sessionEnd.minute(),
        hosting: user !== null && session.hostId === user.uid
      }
    })
  const sessionsByTimeSlot = groupObjectsByProperty(sessions, session => Math.floor(session.startMinutes / 60) * 60)
  const timeSlotsWithSessions = Object.keys(sessionsByTimeSlot).map(timeSlot => {
    const sessions = sortBy(sessionsByTimeSlot[timeSlot], 'start')
    const hostImages = sessions.map(session => session.hostUser.image100)
    return { startMinutes: parseInt(timeSlot), sessions, hostImages }
  })
  
  console.log(timeSlotsWithSessions)

  const title = date.format('dddd MM/DD')

  const onTouchMove = () => { }
  const onTouchEnd = () => { }
  const onTouchCancel = () => { }

  const onMouseMove = () => { }
  const onMouseLeave = () => { }

  const onClick = () => { }

  const contents = [
    ...(timeSlotsWithSessions.map(({ startMinutes, sessions, hostImages }) => {
      const endMinutes = startMinutes + 60
      return {
        startMinutes,
        endMinutes,
        customCss: css`
          border: 1px solid ${FC_IRIS_BLUE};
          pointer-events: auto;
        `,
        children:
          <div css={css`width: 100%; height: 100%;`}>
            <ExclusiveMouseoverWrapper exclusivityKey={startMinutes}>
              <TooltipWrapper
                TooltipComponent={({ children }) =>
                  <Tooltip customCss={css`width: fit-content; padding: 8px; border: none; border-radius: 12px; max-width: 400px;`}>
                    <div css={css`overflow: auto;`}>
                      {children}
                    </div>
                  </Tooltip>
                }
                TooltipContents={
                  <div css={css`display: flex; gap: 8px;`}>
                    {sessions.map(session =>
                      <div css={css`flex-shrink: 0; max-width: 350px;`}>
                        <EventCard
                          key={session.id}
                          event={session}
                          onActionSuccess={refetchSchedulePageData}
                          forceMobileView
                        />
                      </div>
                    )}
                  </div>
                }
                useFloatingArgs={{ placement: 'top', middleware: [shift({ padding: 32 }), flip()] }}
              >
                <div css={css`width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; gap: 4px; overflow: hidden; padding: 0px 4px;`}>
                  {hostImages.map(hostImage => <img css={css`border-radius: 100%; min-width: 0; flex-basis: 24px;`} src={hostImage}/>)}
                </div>
              </TooltipWrapper>
            </ExclusiveMouseoverWrapper>
          </div>,
        key: `existingSession-${startMinutes}-${endMinutes}`
      }
    }))
  ]
  if (dayjs().format(DATE_FORMAT) === date.format(DATE_FORMAT)) {
    const now = dayjs()
    const currentMinutes = now.hour() * 60 + now.minute()
    contents.push({
      startMinutes: currentMinutes,
      endMinutes: currentMinutes,
      key: `current-time`,
      children: <NowLine/>
    })
  }


  return (
    <DayColumn
      title={title}
      contents={contents}
      interactionEvents={{
        onTouchMove,
        onTouchEnd,
        onTouchCancel,

        onMouseMove,
        onMouseLeave,

        onClick
      }}
    />
  )
}