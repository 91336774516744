/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState, useMemo} from 'react';
import { Form } from 'react-bootstrap'
import { CheckboxButton, LoadingIndicator, Text, TEXT_STYLES } from '../flowComponents';
import { sortBy } from '../../../functions/shared/helpers'

export const AdminCreationOptions = ({ selectedHostId, setSelectedHostId, allHosts, adminModeEnabled, setAdminModeEnabled, creatingForOtherHost }) => {

  const [searchTerm, setSearchTerm] = useState("");
  const filteredHosts = useMemo(() => {
    if (allHosts === null) return [];
    return sortBy(allHosts, 'displayName').filter(host =>
      !searchTerm ||
      host.displayName.toLowerCase().includes(searchTerm.toLowerCase()) || 
      (host.email && host.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm, allHosts]);

  useEffect(() => {
    if(filteredHosts.length === 1) {
      setSelectedHostId(filteredHosts[0].id);
    }
  }, [filteredHosts]);

  return (
    <div css={css`
      border-radius: 8px;
      border: 1px solid #8e8e8e;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      background: repeating-linear-gradient(
        -45deg,
        rgba(160, 187, 217, 0.2),
        rgba(160, 187, 217, 0.2) 30px,
        transparent 30px,
        transparent 60px
      );

      width: 250px;
    `}>
      <CheckboxButton selected={adminModeEnabled} setSelected={setAdminModeEnabled}><Text>Enable ✨admin mode✨</Text></CheckboxButton>
      {adminModeEnabled &&
        <div css={css`
          display: flex;
          width: 100%;
          padding-top: 8px;
          align-items: center;
          flex-direction: column;
          gap: 12px;
        `}>
          {allHosts === null ? <LoadingIndicator /> : (
            <div>
              <Form.Label>Create session for:</Form.Label>
              <input 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                placeholder="Search for a specific host..."
                css={css`
                  width: 100%;
                  padding: 8px;
                  margin: 8px 0;
                  border: 1px solid #D9D9D9;
                  border-radius: 4px;
                  margin: 16px 0;
                `}
              />
              <Form.Control
                as="select"
                css={creatingForOtherHost && css`background-color: orange; color: white;`}
                className="fc-select dynamic-size"
                value={selectedHostId}
                onChange={(event) => setSelectedHostId(event.target.value)}
              >
                {filteredHosts.map(({ id, displayName, email }) =>
                  <option value={id} key={id}>{displayName} ({email})</option>
                )}
              </Form.Control>
            </div>
          )}
          <Text style={TEXT_STYLES.CAPTION}>⏱ Custom times also enabled -- choose a time slot to customize</Text>
          <Text style={TEXT_STYLES.CAPTION}>⚠️ <b>NOTE</b> – if you attempt to cancel while acting as another user, you will actually attempt to cancel that session for yourself. This means that it should mostly do nothing, unless you happen to also be booked for that session.</Text>
        </div>
      }
    </div>
  )
}