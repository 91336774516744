/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { createContext, useContext, useEffect, useState } from "react";
import { FC_DARK_BLUE, FC_LIGHTER_BLUE, TEXT_60_PERCENT } from '../../emotionVariables';
import { DarkText, DropdownToggleButton, LightGrayText, LinkStyledText, Text, TEXT_STYLES } from '../../components/flowComponents';
import Icon, { TYPE_CHECKMARK } from '../../daily/daily-components/Icon/Icon';
import { useClickOutside } from './useClickOutside';
import { useUrlParamTimeFilters } from './useTimeFilters';
import { useUrlParamDurationFilters } from './useDurationFilters';
import { DURATION_OPTIONS } from '../../../functions/shared/constants';
import { SegmentProvider, useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const TimeFiltersContext = createContext(null)

export const TimeFilters = ({ timeFilterOptions, activeTimeFilters, setActiveTimeFilters, activeDurationFilters, setActiveDurationFilters }) =>
  <SegmentProvider eventLabel={'Time Filters'}>
    <TimeFiltersContent
      timeFilterOptions={timeFilterOptions}
      activeTimeFilters={activeTimeFilters}
      setActiveTimeFilters={setActiveTimeFilters}
      activeDurationFilters={activeDurationFilters}
      setActiveDurationFilters={setActiveDurationFilters}
    />
  </SegmentProvider>

const TimeFiltersContent = ({ timeFilterOptions, activeTimeFilters, setActiveTimeFilters, activeDurationFilters, setActiveDurationFilters }) => {
  const activeTimeFiltersList = timeFilterOptions.filter(({ name }) => activeTimeFilters[name])
  const activeDurationFiltersList = Object.keys(activeDurationFilters).filter(duration => activeDurationFilters[duration])
  const activeTimeFiltersCount = activeTimeFiltersList.length
  const activeDurationFiltersCount = activeDurationFiltersList.length
  const getTimeString = () => activeTimeFiltersCount === 1 ?
  activeTimeFiltersList[0].name :
  activeTimeFiltersCount > 1 ?
  `${activeTimeFiltersList[0].name}+${activeTimeFiltersCount - 1}` :
  ''
  const getDurationString = () => activeDurationFiltersCount === 1 ?
  `${activeDurationFiltersList[0]} minutes` :
  activeDurationFiltersCount > 1 ?
  `${activeDurationFiltersCount} durations` : ''
  const dropdownName = activeTimeFiltersCount === 0 && activeDurationFiltersCount === 0?
    'Times & Durations' :
    activeTimeFiltersCount === 0 ?
    `${getDurationString()}` :
    activeDurationFiltersCount === 0 ?
    `${getTimeString()}` :
    `${getTimeString()}, ${getDurationString()}`

  const urlParamTimeFilter = useUrlParamTimeFilters()
  const urlParamTimeFilterActive = urlParamTimeFilter !== null
  const urlParamDurationFilter = useUrlParamDurationFilters()
  const urlParamDurationFilterActive = urlParamDurationFilter !== null

  const sendSegmentEvent = useSendSegmentEvent()
  const dropdownOpened = () => {
    sendSegmentEvent('Opened')
  }
  const dropdownClosed = () => {
    sendSegmentEvent('Closed')
  }

  return (
    <TimeFiltersContext.Provider value={{ timeFilterOptions, activeTimeFilters, setActiveTimeFilters, activeDurationFilters, setActiveDurationFilters }}>
      <DropdownToggleButton
        label={dropdownName}
        onOpen={dropdownOpened}
        onClose={dropdownClosed}
        initiallyOpened={urlParamTimeFilterActive || urlParamDurationFilterActive}
        customCss={(activeTimeFiltersCount > 0 || activeDurationFiltersCount > 0) && css`
          background-color: ${FC_LIGHTER_BLUE};
          border-color: ${FC_DARK_BLUE};
        `}
        height={400}
      >
        <TimeFiltersDropdown/>
      </DropdownToggleButton>
    </TimeFiltersContext.Provider>
  )
}

const TimeFiltersDropdown = ({ openButtonRef, closeDropdown }) => {
  const { timeFilterOptions, activeTimeFilters, setActiveTimeFilters, activeDurationFilters, setActiveDurationFilters } = useContext(TimeFiltersContext)
  const anyTimeFiltersActive = Object.values(activeTimeFilters).filter(filterActive => filterActive).length > 0
  const anyDurationFiltersActive = Object.keys(activeDurationFilters).filter(filter => activeDurationFilters[filter]).length > 0

  const sendSegmentEvent = useSendSegmentEvent()
  const clickedOutside = (_, eventType) => {
    const dropdownWasClosed = closeDropdown()
    if (dropdownWasClosed) {
      if (eventType === 'click') {
        sendSegmentEvent('Dropdown Clicked Outside')
      } else {
        sendSegmentEvent('Escape Key Pressed')
      }
    }
  }

  const clearAllClicked = () => {
    setActiveTimeFilters({})
    setActiveDurationFilters({})
    sendSegmentEvent('Clear All Clicked')
  }

  const doneClicked = () => {
    closeDropdown()
    sendSegmentEvent('Done Clicked')
  }

  const clickOutsideRef = useClickOutside({ onTriggered: clickedOutside, additionalIgnoredRefs: [openButtonRef] })

  return <div ref={clickOutsideRef} css={css`
    width: 100%;
    height: 100%;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    display: flex;
    flex-direction: column;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  `}>
    <div css={css`
      padding: 16px 16px 14px 16px;
      border-bottom: 1px solid #D9D9D9;
    `}>
      <Text style={TEXT_STYLES.SUBTITLE_2}>🕒 Time of Day</Text>
      {timeFilterOptions.map(({ name, icon, description }, index) => 
        <div key={name} css={index !== 0 && css`margin-top: 8px;`}>
          <TimeFilterOption
            name={name}
            icon={icon}
            description={description}
            selected={activeTimeFilters[name]}
            setSelected={(selected) => setActiveTimeFilters({...activeTimeFilters, [timeFilterOptions[index].name]: selected})}
          />
        </div>
      )}
      <Text style={TEXT_STYLES.SUBTITLE_2} customCss={css`margin-top: 16px;`}>⏳ Duration</Text>
      {DURATION_OPTIONS.map((duration, index) =>
        <div key={duration} css={index !== 0 && css`margin-top: 8px;`}>
          <TimeFilterOption
            name={`${duration} minutes`}
            selected={activeDurationFilters[duration]}
            setSelected={(selected) => setActiveDurationFilters({...activeDurationFilters, [duration]: selected})}
          />
        </div>
      )}

    </div>
    <div css={css`display: flex; justify-content: flex-end; flex-grow: 1; align-items: center;`}>
      <LinkStyledText onClick={clearAllClicked} customCss={css`
        margin-right: 16px;
        ${!anyTimeFiltersActive && !anyDurationFiltersActive && css`
          cursor: initial;
          color: ${TEXT_60_PERCENT};
          user-select: none;
        `}
      `}>Clear All</LinkStyledText>
      <LinkStyledText onClick={doneClicked} customCss={css`margin-right: 16px;`}>Done</LinkStyledText>
    </div>
  </div>
}

const TimeFilterOption = ({ name, icon, description, selected = false, setSelected }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const filterToggled = () => {
    setSelected(!selected)
    sendSegmentEvent('Filter Option Toggled', { name, description })
  }

  const selectedAnimation = keyframes({
    'from': css`transform: scale(1.1);`,
    '50%': css`transform: scale(1.25);`,
    'to': css`transform: scale(1.1);`
  })

  const [hovered, setHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={filterToggled}
      css={css`display: flex; align-items: center; cursor: pointer;`}
    >
      <div css={css`
        width: 24px;
        height: 24px;
        border: 1px solid ${selected ? FC_DARK_BLUE : 'rgba(0, 0, 0, 0.38)'};
        border-radius: 4px;
        ${selected && css`background-color: ${FC_LIGHTER_BLUE};`}

        transition: transform 0.2s;
        ${hovered && css`
          border-color: ${FC_DARK_BLUE};
          transform: scale(1.1);
        `}

        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 12px;

        ${selected && css`animation: ${selectedAnimation} 0.3s;`}
      `}>
        <Icon type={TYPE_CHECKMARK} css={css`fill: ${FC_DARK_BLUE}; width: 16px; height: 12px; opacity: ${selected ? '1' : '0'}; ${selected && css`transition: opacity 0.3s;`}`}/>
      </div>
      <DarkText>
        {icon} {name} <LightGrayText customCss={css`display: inline;`}>
          {description}
        </LightGrayText>
      </DarkText>
    </div>
  )
}