/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

/* COLORS */
export const FC_IRIS_BLUE = '#5D5FEF'
export const FC_LIGHTER_IRIS_BLUE = '#737AC9'
export const FC_LIGHTER_BLUE = '#EAF3FF'
export const FC_LIGHT_BLUE = '#D2DDEC'
export const FC_BLUE = '#515EA8'
export const FC_DARK_BLUE = '#091454'
export const FUSCHIA_100 = '#EF5DA8'
export const FC_PINK = '#EBB4A9'
export const FC_GREEN = '#2FC6A5'
export const FC_ALT_GREEN = '#74C2B2'
export const FC_LIGHT_GREEN = '#A5F3E3'
export const FC_DARKER_GRAY = '#333232';
export const FC_BACKGROUND_GRAY = '#F9F9F9'
export const FC_GOLD = '#E09408'
export const FC_PEACH = '#FFEEE4'
export const FC_YELLOW = "#FEF3C7"
export const FC_RED = '#B75C4A'
export const BRONZE = '#997247'
export const NEUTRAL_100  = '#202020'
export const NEUTRAL_90 = '#404040'
export const NEUTRAL_80 = '#626262'
export const NEUTRAL_70 = '#757575'
export const NEUTRAL_60 = '#989898'
export const NEUTRAL_50 = '#C2C2C2'
export const NEUTRAL_40 = '#E0E0E0'
export const NEUTRAL_30 = '#EDEDED'
export const NEUTRAL_20 = '#F5F5F5'
export const SMOKE = "#EEF0F4;"
export const TEXT_60_PERCENT = 'rgba(0, 0, 0, 0.6)'
export const TEXT_87_PERCENT = 'rgba(0, 0, 0, 0.87)'
export const LINK_MAIN = '#4744C9'
export const LINK_ACTIVE = '#261E7D'

export const ALL_COLORS = {
  FC_IRIS_BLUE,
  FC_LIGHTER_BLUE,
  FC_LIGHT_BLUE,
  FC_BLUE,
  FC_DARK_BLUE,
  FUSCHIA_100,
  FC_PINK,
  FC_GREEN,
  FC_LIGHT_GREEN,
  FC_DARKER_GRAY,
  FC_BACKGROUND_GRAY,
  FC_GOLD,
  FC_PEACH,
  FC_RED,
  BRONZE,
  NEUTRAL_100,
  NEUTRAL_90,
  NEUTRAL_80,
  NEUTRAL_70,
  NEUTRAL_60,
  NEUTRAL_50,
  NEUTRAL_40,
  NEUTRAL_30,
  NEUTRAL_20,
  TEXT_60_PERCENT,
  TEXT_87_PERCENT,
  LINK_MAIN,
  LINK_ACTIVE
}

// index 0 = fixed color for hosts
// NOTE: assignNewColorIndex in functions/logic/chat.js will assign values from 0-8 inclusive here
// so if there are fewer than 9 values here, it will probably break things 
export const CHAT_MESSAGE_COLORS = [
  "#5D5FEF",
  "#A65DEF",
  "#00C2C0",
  "#EF5D5F",
  "#EF5DA8",
  "#EFA65D",
  "#EF815D",
  "#EFCA5D",
  "#2FC6A5"
]

// samea as above, just in a different format for easier manipulating
export const CHAT_MESSAGE_COLORS_HSL = [
  [239, 82, 65],
  [270, 82, 65],
  [179, 100, 38],
  [359, 82, 65],
  [329, 82, 65],
  [30, 82, 65],
  [15, 82, 65],
  [45, 82, 65],
  [167, 62, 48]
]

export const adminBackground = css`
  background: repeating-linear-gradient(
    -45deg,
    rgba(160, 187, 217, 0.2),
    rgba(160, 187, 217, 0.2) 30px,
    transparent 30px,
    transparent 60px
  );
`