import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { useSpaceCompleteGoal, useVisitSpace } from '../fetch/endpoints';
import { LoungeContent } from './LoungeContent';
import dayjs from 'dayjs';
import { getDefaultOptimisticTemporaryState } from './loungeStateManagement/helpers';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { makeNewGoalObject, useGoals } from '../components/GoalList/useGoals';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../UserProvider';
import { ConfettiProvider } from '../wrappers/ConfettiProvider';

const HEARTBEAT_INTERVAL = 5000 // ms

export const LoungeContext = createContext({ spaceId: null, setSpaceState: null, optimisticUpdatesData: null })

const useHeartbeat = (spaceId, setSpaceState, latestGoals, currentOptimisticUpdateRequests, optimisticUpdatesTemporaryStateManager) => {
  const { user, authUser } = useContext(UserContext)
  const { performFetch: visitSpace } = useVisitSpace()

  const [temporaryState, setTemporaryState] = optimisticUpdatesTemporaryStateManager

  const history = useHistory()

  const performHeartbeatUpdate = async (initialRequest) => {
    // clear out finished requests before checking if there are any current requests initially
    currentOptimisticUpdateRequests.current = currentOptimisticUpdateRequests.current.filter(request => request.active)

    const optimisticallyUpdatedRequestsInFlightPreHeartbeat = currentOptimisticUpdateRequests.current.length > 0
    const { result, success } = await visitSpace({ spaceId, goals: latestGoals.current.map(({ text, completedAt }) => ({ text, completedAt })) })
    const optimisticallyUpdatedRequestsInFlight = currentOptimisticUpdateRequests.current.length > 0

    // redirect back to schedule page if initial lounge visit failed
    if (initialRequest && !success) {
      history.push("/upcoming")
      return
    }

    setSpaceState({ initial: initialRequest, state: result })

    // if there have been no optimistically rendered requests since this heartbeat request started, we can take that as an indication that the server state will contain all optimistic changes we were retaining
    // so we can clear any existing temporary optimistic state
    if (!optimisticallyUpdatedRequestsInFlightPreHeartbeat && !optimisticallyUpdatedRequestsInFlight) {
      setTemporaryState(getDefaultOptimisticTemporaryState())
    }
  }

  const heartbeatActive = useRef(null)
  heartbeatActive.current = (authUser === null && user === null) || (authUser !== null && user !== null)

  useEffect(() => {
    performHeartbeatUpdate(true)
    const interval = setInterval(() => {
      if (heartbeatActive.current) {
        performHeartbeatUpdate(false)
      }
    }, HEARTBEAT_INTERVAL)
    return () => clearInterval(interval)
  }, [spaceId])
}

const LoungeSegmentWrapper = ({ spaceState, children }) => {
  const getBaseData = () => ({
    ...(spaceState != null ? {
      usersInLounge: spaceState.currSpaceVisits.length,
      loungeName: spaceState.space.name,
      interactableLoungeEventPresent: spaceState.space.events.some(event => event.metadata.canHighFive),
      ...(spaceState.visit !== undefined ? {
        msSinceJoining: Date.now() - spaceState.visit.joined,
        totalGoals: spaceState.visit.goals.filter(goal => goal.text.length > 0).length,
        completedGoals: spaceState.visit.goals.filter(goal => goal.completedAt !== null).length,
        currentlyInPomodoro: spaceState.visit.currTimedSession.start !== undefined
      } : {})
    } : {})
  })
  
  return (
    <SegmentProvider baseData={getBaseData} eventLabel={'Lounge-FE'}>
      {children}
    </SegmentProvider>
  )
}

const LoungeDataWrapper = () => {
  const { loungeId: spaceId } = useParams();

  const optimisticUpdatesTemporaryStateManager = useState(getDefaultOptimisticTemporaryState())
  const currentOptimisticUpdateRequests = useRef([])

  const [spaceState, setSpaceState] = useState({ initial: false, state: null })
  const [inferredSpaceId, setInferredSpaceId] = useState(null) // store space id from response after first request, to make the backend's life a little easier and speed up responses
  useEffect(() => {
    if (spaceId === undefined && inferredSpaceId === null && spaceState.state !== null) {
      setInferredSpaceId(spaceState.state.space.id)
    }
  }, [spaceState])
  const effectiveSpaceId = spaceId ?? inferredSpaceId

  const goalsState = useState([makeNewGoalObject([])])
  const [goals, setGoals] = goalsState
  const latestGoals = useRef(goals)
  latestGoals.current = goals

  // initial data loading funkiness //

  const [existingTimedSessionRemainingTime, setExistingTimedSessionRemainingTime] = useState(null)
  useEffect(() => {
    if (spaceState.initial && spaceState.state.visit !== undefined) {
      const { visit } = spaceState.state
      setGoals(visit.goals.map(goal => ({ ...goal, id: uuidv4() }))) // arbitrary id, just used for react keying -- not stored serverside
      if (visit.currTimedSession.start !== undefined) {
        setExistingTimedSessionRemainingTime(dayjs.duration(visit.currTimedSession.start + visit.currTimedSession.timerLengthMs - Date.now(), 'milliseconds'))
      }
    }
  }, [spaceState.initial])

  // end initial data loading //

  useHeartbeat(effectiveSpaceId, setSpaceState, latestGoals, currentOptimisticUpdateRequests, optimisticUpdatesTemporaryStateManager, setInferredSpaceId)

  const [optimisticSpaceState] = optimisticUpdatesTemporaryStateManager
  const effectiveSpaceState = optimisticSpaceState.active ? optimisticSpaceState.state : spaceState.state

  const optimisticUpdatesData = {
    authoritativeState: spaceState.state,
    temporaryStateManager: optimisticUpdatesTemporaryStateManager,
    currentUpdateRequests: currentOptimisticUpdateRequests
  }

  return (
    <SegmentProvider>
      <LoungeContext.Provider value={{ spaceId: effectiveSpaceId, optimisticUpdatesData }}>
      <ConfettiProvider>
        <LoungeSegmentWrapper spaceState={effectiveSpaceState}>
          <GoalsDataWrapper spaceState={effectiveSpaceState} goalsState={goalsState} existingTimedSessionRemainingTime={existingTimedSessionRemainingTime} />
        </LoungeSegmentWrapper>
      </ConfettiProvider>
      </LoungeContext.Provider>
    </SegmentProvider>
  )
}

const GoalsDataWrapper = ({ spaceState, goalsState, existingTimedSessionRemainingTime }) => {
  const visitId = spaceState !== null ? spaceState.visitId : null
  
  const { performFetch: completeGoal } = useSpaceCompleteGoal()
  const onGoalCompleted = () => completeGoal({ visitId })
  const goalsData = useGoals(goalsState, onGoalCompleted)
  
  return <LoungeContent spaceState={spaceState} goalsData={goalsData} existingTimedSessionRemainingTime={existingTimedSessionRemainingTime} />
}

export const Lounge = () => <LoungeDataWrapper/>