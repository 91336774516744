/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import '../Schedule.scss'
import { Helmet } from "react-helmet";
import { Stats } from './Stats';
import { Events } from './Events';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserProvider';
import { Header } from './Header';
import { FC_BACKGROUND_GRAY } from '../../emotionVariables';
import { useGetHostFeedback } from '../../fetch/endpoints';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { HostingFeedback } from './HostingFeedback';
import { SegmentProvider, useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { useHistory, useLocation } from 'react-router';
import { USER_SESSION_LIST_MODES } from '../../../functions/shared/constants';
import { getUserCanHostSessions } from '../../../functions/shared/helpers';
import { FloatingCreateSessionButton } from '../Schedule/CreateSessionButton';
import { useMediaQuery } from 'react-responsive';

const tabIndexPathNames = {
  0: '/profile/sessions/',
  1: '/profile/completed-sessions/',
  2: '/profile/stats/',
  3: '/profile/feedback/',
};

const tabIndexToPathname = index => tabIndexPathNames[index];

export const MyFlowClub = () => {
  const { user } = useContext(UserContext)
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })
  const userCanHostSessions = getUserCanHostSessions(user)
  
  const { result: feedbackData } = useAutomaticallyFetch(useGetHostFeedback)
  
  const tabs = [
    { title: mobileView ? "Upcoming" : "Upcoming Sessions", Content: <Events key='upcomingsessions' listMode={USER_SESSION_LIST_MODES.UPCOMING} /> },
    { title: mobileView ? "Completed" : "Completed Sessions", Content: <Events key='completedsessions' listMode={USER_SESSION_LIST_MODES.PAST} isPast /> },
    { title: "Stats", Content: <Stats key='stats' /> },
  ]
  if (userCanHostSessions) {
    tabs.push({ title: "Feedback", Content: <HostingFeedback key='hostingfeedback' feedbackData={feedbackData} /> })
  }

  const { pathname } = useLocation()
  let initialTabIndex = parseInt(Object.keys(tabIndexPathNames).find(index => pathname.startsWith(tabIndexPathNames[index])) || 0)
  if (!userCanHostSessions && initialTabIndex === 3) {
    initialTabIndex = 0
  }
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex)

  const feedbackCount = feedbackData === null ? null : feedbackData.eventsWithFeedback.reduce((count, event) => count + event.feedback.length, 0)
  const sendSegmentEvent = useSendSegmentEvent()
  useEffect(() => {
    sendSegmentEvent('[My Flow Club] Tab Viewed', { tab: tabs[activeTabIndex].title, feedbackCount })
  }, [activeTabIndex])

  const history = useHistory()
  useEffect(() => {
    const resultingPathname = tabIndexToPathname(activeTabIndex)
    if (resultingPathname !== pathname) {
      history.replace(resultingPathname)
    }
  }, [activeTabIndex])

  return (
    <SegmentProvider eventLabel={'My Flow Club'}>
      <div>
        <Helmet>
          <title>My Sessions</title>
          <meta name='robots' content='noindex, follow' />
        </Helmet>
        <Header tabs={tabs} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />
        <FloatingCreateSessionButton userCanHostSessions={userCanHostSessions} />
      </div>
    </SegmentProvider>
  );
}