
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { SessionRecommendation } from './SessionRecommendation';
import { Text, TEXT_STYLES } from '../components/flowComponents';
import { NEUTRAL_90 } from '../emotionVariables';

export const RecommendationPopoverContent = ({ recommendations, onBookingSuccess }) => {
  const firstRec = recommendations.length > 0 ? recommendations[0] : null
  const { startTimestamp } = firstRec || {}
  return (
    <div css={css`display: flex; flex-direction: column; gap: 8px; width: 325px;`}>
      {recommendations.length === 0 ? // technically should never happen since we'll always get session requests
        <Text style={TEXT_STYLES.BODY_1} customCss={css`margin-bottom: 8px; color: ${NEUTRAL_90}; font-weight: bold;`}>No sessions available</Text> :
        <>
          <Text style={TEXT_STYLES.BODY_1} customCss={css`margin-bottom: 8px; color: ${NEUTRAL_90}; font-weight: bold;`}>{dayjs(startTimestamp).format('ddd MMM D')}</Text>
          {recommendations.map((recommendation, index) => <SessionRecommendation recommendation={recommendation} onBookingSuccess={() => onBookingSuccess(index)} />)}
        </>
      }
    </div>
  )
}