/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC_LIGHTER_IRIS_BLUE } from "../emotionVariables";


export const SIDEBAR_COLORS = {
  WHITE: "#ffffff1a",
  BLUE: FC_LIGHTER_IRIS_BLUE,
}

export const SIDEBAR_MIN_WIDTH = 990;

const contentCss = css`
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
`

export const FullScreenSidebar = ({ color = SIDEBAR_COLORS.WHITE, soloContent = true, children }) => {
  const sideBarCss = css`
    align-items: center;
    background: ${color};
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-height: 100vh;
    padding: 64px 40px;
    position: relative;
    width: 552px;
    box-shadow: -2px 0px 13px 0px rgba(0, 0, 0, 0.05);
  `;

  return (
    <div css={sideBarCss}>
      {soloContent ?
        <div css={contentCss}>
          {children}
        </div>
      :
        children
      }
    </div>
  );
};