/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import { EmailLoginButton, GoogleLoginButton } from './loginButtons'
import { FIREBASE_AUTH_METHODS, useLogin } from './useLogin';
import { DarkText, TextInput, FlowButton, LinkStyledText, BUTTON_STYLES, SIZE_PRESETS, Text, TEXT_STYLES, TEXT_INPUT_TYPES } from '../components/flowComponents';
import { toastError } from '../components/utils/toaster';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { useEmailPasswordSignup } from './useEmailPasswordSignup';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useGoogleSignup } from './useGoogleSignup';
import { UserContext } from '../UserProvider';
import { getUserIsUnauthed } from '../../functions/shared/helpers';
import { useFinishSigningUp } from './useFinishSigningUp';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { isInAppBrowser } from '../utils';

export const LOGIN_SIGNUP_STATES = {
  SELECTING_PROVIDER: 'selecting_provider',
  ENTERING_EMAIL_PW: 'entering_email_pw',
  PENDING_EMAIL_VERIFICATION: 'pending_email_verification',
  ACCOUNT_FOR_EMAIL_ALREADY_EXISTS: 'account_for_email_already_exists'
}

const getTitle = (loginSignupState, isSigningUp, showLoginModeLink, showBookingRelatedText) => {
  if (isSigningUp) {
    switch (loginSignupState.state) {
      case LOGIN_SIGNUP_STATES.SELECTING_PROVIDER:
        if (showLoginModeLink && showBookingRelatedText) {
          return 'Sign up to finish booking'
        } else {
          return 'Create Flow Club account'
        }
      case LOGIN_SIGNUP_STATES.ENTERING_EMAIL_PW:
        return 'Create Flow Club account'
      case LOGIN_SIGNUP_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS:
        return 'Log in to Flow Club'
      case LOGIN_SIGNUP_STATES.PENDING_EMAIL_VERIFICATION:
        return 'Verify your email address'
    }
  } else {
    if (!showBookingRelatedText) { return 'Log in to Flow Club' }
    
    switch (loginSignupState.state) {
      case LOGIN_SIGNUP_STATES.SELECTING_PROVIDER:
        return 'Log in to finish booking'
      case LOGIN_SIGNUP_STATES.ENTERING_EMAIL_PW:
        return 'Log in to Flow Club'
      case LOGIN_SIGNUP_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS:
        return 'Log in to Flow Club'
    }
  }
}

export const LoginSignup = ({ onSuccess, showLoginModeLink = false, startInLoginMode = false, showBookingRelatedText = true }) => {
  const [isSigningUp, setIsSigningUp] = useState(!startInLoginMode)
  
  const [loginSignupState, setLoginSignupState] = useState({ state: LOGIN_SIGNUP_STATES.SELECTING_PROVIDER, props: {} })

  const title = getTitle(loginSignupState, isSigningUp, showLoginModeLink, showBookingRelatedText)

  let MainContent = LoginMethodChoices

  switch (loginSignupState.state) {
    case LOGIN_SIGNUP_STATES.SELECTING_PROVIDER:
      MainContent = LoginMethodChoices
      break
    case LOGIN_SIGNUP_STATES.ENTERING_EMAIL_PW:
      MainContent = EnterEmail
      break
    case LOGIN_SIGNUP_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS:
      MainContent = AccountForEmailAlreadyExists
      break
    case LOGIN_SIGNUP_STATES.PENDING_EMAIL_VERIFICATION:
      MainContent = EmailVerificationPending
      break
  }

  return (
    <SegmentProvider eventLabel={'Login/Signup'}>
      <Text style={TEXT_STYLES.APP_H4} customCss={css`margin-bottom: 12px;`}>{title}</Text>
      {isSigningUp && 
      <Text style={TEXT_STYLES.BODY_2} customCss={css`letter-spacing: 0.5px;`}>
         Sign up with the email linked to your main calendar for session invites.
      </Text>
      }
      <MainContent onSuccess={onSuccess} setLoginSignupState={setLoginSignupState} isSigningUp={isSigningUp} {...loginSignupState.props} />
      {showLoginModeLink && loginSignupState.state === LOGIN_SIGNUP_STATES.SELECTING_PROVIDER &&
        <ToggleModeText isSigningUp={isSigningUp} setIsSigningUp={setIsSigningUp} />
      }
    </SegmentProvider>
  )
}

const ToggleModeText = ({ isSigningUp, setIsSigningUp }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const toggleSigningUp = () => {
    setIsSigningUp(!isSigningUp)
    sendSegmentEvent("Signing Up/Logging In Toggled", { previouslySigningUp: isSigningUp })
  }

  // TODO: style these 'links'
  const text = isSigningUp ?
    <>Have an account? <LinkStyledText customCss={css`font-weight: bold;`} onClick={toggleSigningUp}>Log in</LinkStyledText></> :
    <LinkStyledText customCss={css`font-weight: bold;`} onClick={toggleSigningUp}>Sign up for an account</LinkStyledText>
  return (
    <DarkText customCss={css`margin-top: 24px; text-align: center;`}>
      {text}
    </DarkText>
  )
}
const LoginMethodChoices = ({ onSuccess, setLoginSignupState, isSigningUp }) => {
  const { user } = useContext(UserContext)
  const userIsUnauthed = user !== null && getUserIsUnauthed(user)
  const sendSegmentEvent = useSendSegmentEvent()
  const [isInAppView, setIsInAppView] = useState(false)
  const [ isIOS, setIsIOS] = useState(false)
  const [ isAndroid, setIsAndroid] = useState(false)

  useEffect(() => {
    const { isIOS, isAndroid, isInApp } = isInAppBrowser()
    setIsInAppView(isInApp)
    setIsIOS(isIOS)
    setIsAndroid(isAndroid)
  }, [])

  const { upgradeWithGoogle } = useFinishSigningUp({ onSuccess })

  const onFailure = (error) => {
    toastError({ message: error })
  }
  const signupWithGoogle = useGoogleSignup({ onSuccess, onFailure })
  const loginWithGoogle = useLogin({ firebaseAuthMethod: FIREBASE_AUTH_METHODS.GOOGLE, onSuccess, onFailure })

  const buttonText = {
    google: isSigningUp ? 'Sign Up With Google' : undefined,
    email: isSigningUp ? 'Sign Up With Email' : undefined,
  }

  const googleLoginButtonClicked = () => {
    if (isSigningUp) {
      if (userIsUnauthed) {
        upgradeWithGoogle()
      } else {
        signupWithGoogle()
      }
    } else {
      loginWithGoogle()
    }
    sendSegmentEvent("Google Login Button Clicked")
  }

  const emailLoginButtonClicked = () => {
    setLoginSignupState({ state: LOGIN_SIGNUP_STATES.ENTERING_EMAIL_PW, props: {} })
    sendSegmentEvent("Email Login Button Clicked")
  }

  const openInSystemBrowser = () => {
    const targetPath = isSigningUp ? '/signup' : '/login';
    if (isIOS) {
      // Try Safari first
      window.location.href = `x-safari-https://${window.location.hostname}${targetPath}`;
      // Fallback to Chrome after a short delay
      setTimeout(() => {
        window.location.href = `googlechrome://${window.location.hostname}${targetPath}`;
      }, 1000);
    } else if (isAndroid) {
      // Android Chrome intent
      window.location.href = `intent://${window.location.hostname}${targetPath}#Intent;scheme=https;package=com.android.chrome;end`;
    } else {
      // Fallback to regular URL
      window.location.href = `https://${window.location.hostname}${targetPath}`;
    }
  }

  return (
    <div css={css`margin-top: 40px;`}>
      {!isInAppView &&
      <GoogleLoginButton 
        customCss={css`margin-bottom: 12px;`} 
        onClick={googleLoginButtonClicked}
      >
        {buttonText.google}
      </GoogleLoginButton>
      }
      <EmailLoginButton onClick={emailLoginButtonClicked} onSuccess={onSuccess}>
        {buttonText.email}
      </EmailLoginButton>
      {isInAppView &&
      <>
       <GoogleLoginButton 
        customCss={css`margin-bottom: 8px; margin-top: 8px;`} 
        onClick={openInSystemBrowser}
      >
        {buttonText.google}
      </GoogleLoginButton>
        <Text style={TEXT_STYLES.CAPTION} customCss={css`margin-bottom: 12px;`}>
          Clicking {buttonText.google} will open your system browser. Alternatively, tap the three dots in the top-right corner and select Open in external browser.
        </Text>
      </>
      }
      {isSigningUp && <div css={css`margin-top: 24px;`}>
        <Text style={TEXT_STYLES.SUBTITLE_3}>
        By clicking Sign Up, you confirm that you are at least 18 years of age and agree to Flow Club's <a href="https://www.flow.club/terms-and-conditions" target="_blank" rel="noreferrer noopener">Terms and Conditions</a> and <a href="https://www.flow.club/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
        </Text>
      </div>}
    </div>
  )
}

const EnterEmail = ({ onSuccess, setLoginSignupState, isSigningUp }) => {
  const { user } = useContext(UserContext)
  const inviteInfo = useContext(InviteInfoContext)
  const userIsUnauthed = user !== null && getUserIsUnauthed(user)
  const sendSegmentEvent = useSendSegmentEvent()

  const { upgradeWithEmail } = useFinishSigningUp({ onSuccess })

  const onFailure = (error) => toastError({ message: error })
  const createAccountWithEmailPassword = useEmailPasswordSignup({ onSuccess, onFailure })
  const logInWithEmailPassword = useLogin({ firebaseAuthMethod: FIREBASE_AUTH_METHODS.EMAIL_PASSWORD, onSuccess, onFailure })

  const authFunction = isSigningUp ?
    userIsUnauthed ?
      (email, password) => upgradeWithEmail({ email, password }) :
      (email, password, firstName, lastName) => createAccountWithEmailPassword(email, password, firstName, lastName) :
    (email, password) => logInWithEmailPassword(email, password)

  const [email, setEmail] = useState(inviteInfo.email || '')
  const [firstName, setFirstName] = useState(inviteInfo.firstName || '')
  const [lastName, setLastName] = useState(inviteInfo.lastName || '')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  
  const onSubmit = async (event) => {
    sendSegmentEvent("Email Form Submitted", { isSigningUp })
    event.preventDefault()
    setLoading(true)
    try {
      const existingAccountsForEmail = await firebase.auth().fetchSignInMethodsForEmail(email)
      if (existingAccountsForEmail.includes('google.com')) {
        setLoginSignupState({ state: LOGIN_SIGNUP_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS, props: { email, authMethod: FIREBASE_AUTH_METHODS.GOOGLE } })
      } else if (isSigningUp && existingAccountsForEmail.includes('password')) {
        setLoginSignupState({ state: LOGIN_SIGNUP_STATES.ACCOUNT_FOR_EMAIL_ALREADY_EXISTS, props: { email, authMethod: FIREBASE_AUTH_METHODS.EMAIL_PASSWORD } })
      } else {
        await authFunction(email, password, firstName, lastName)
        if (isSigningUp && !userIsUnauthed) {
          const currentUser = firebase.auth().currentUser
          setLoginSignupState({
            state:  LOGIN_SIGNUP_STATES.PENDING_EMAIL_VERIFICATION, props: { user: currentUser, email }
          })
        }
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      onFailure(translateAuthErrorCodeToMessage(error.code))
      setLoading(false)
    }
  }

  const cancelButtonClicked = () => {
    sendSegmentEvent("Email Form Cancel Button Clicked", { isSigningUp })
    setLoginSignupState({ state: LOGIN_SIGNUP_STATES.SELECTING_PROVIDER, props: {} })
  }

  const resetPasswordLinkClicked = () => {
    sendSegmentEvent("Reset Password Link Clicked", { isSigningUp })
  }

  const disabled = !(email.length > 0 && password.length > 0) || (isSigningUp && !userIsUnauthed && !(firstName.length > 0 && lastName.length > 0))
  
  return (
    <div css={css`margin-top: 12px;`}>
      <form onSubmit={onSubmit}>
        <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 8px;`} value={email} onChange={(event) => setEmail(event.target.value)} placeholder={'mcsikszentmihalyi@gmail.com'} label={'Email'} type={TEXT_INPUT_TYPES.EMAIL}/>
        {isSigningUp && !userIsUnauthed && <>
          <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 8px;`} value={firstName} onChange={(event) => setFirstName(event.target.value)} placeholder={'Mihaly'} label={'First Name'}/>
          <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 8px;`} value={lastName} onChange={(event) => setLastName(event.target.value)} placeholder={'Csikszentmihalyi'} label={'Last Name'}/>
        </>}
        <TextInput customCss={css`margin-bottom: 8px;`} sizePreset={SIZE_PRESETS.CHUNKY} value={password} onChange={(event) => setPassword(event.target.value)} placeholder={'Enter a password'} label={'Password'} type={TEXT_INPUT_TYPES.PASSWORD} />
        {isSigningUp && <Text style={TEXT_STYLES.SUBTITLE_3}>
        By clicking Create Account, you confirm that you are at least 18 years of age and agree to Flow Club's <a href="https://www.flow.club/terms-and-conditions" target="_blank" rel="noreferrer noopener">Terms and Conditions</a> and <a href="https://www.flow.club/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
        </Text>
        }
        <div css={css`
          display: flex;
          margin-top: 16px;
        `}>
          <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} customCss={css`flex: 1; margin-right: 4px;`} onClick={cancelButtonClicked}>Cancel</FlowButton>
          <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} disabled={disabled} loading={loading} customCss={css`flex: 1;`} onClick={onSubmit} type={'submit'}>
            {isSigningUp ? 'Create Account' : 'Log In'}
          </FlowButton>
        </div>
        {!isSigningUp && <div css={css`margin-top: 24px; text-align: center;`}>
          <a onClick={resetPasswordLinkClicked} href="/reset-password" target="_blank" rel="noreferrer">Having trouble logging in?</a>
        </div>}
      </form>
    </div>
  )
}

const AccountForEmailAlreadyExists = ({ onSuccess, email, authMethod = FIREBASE_AUTH_METHODS.GOOGLE }) => {
  const onFailure = (error) => toastError({ message: error })
  const loginWithGoogle = useLogin({ firebaseAuthMethod: FIREBASE_AUTH_METHODS.GOOGLE, onSuccess, onFailure })
  return (
    <div css={css`margin-top: 16px;`}>
      <DarkText>You already have an account associated with:</DarkText>
      <DarkText customCss={css`font-weight: 700; margin-bottom: 20px;`}>{email}</DarkText>
      <DarkText customCss={css`margin-bottom: 28px;`}>Log in{authMethod === FIREBASE_AUTH_METHODS.GOOGLE ? 'with Google' : ''} to continue.</DarkText>
      <div css={css`
        display: flex;
      `}>
        {authMethod === FIREBASE_AUTH_METHODS.GOOGLE ? (
        <GoogleLoginButton customCss={css`flex: 1;`} onClick={loginWithGoogle} />
          ) : (
      <Link to={`/login/?email=${encodeURIComponent(email)}`}>
        <FlowButton customCss={css`flex: 1;`}>
          Log in
        </FlowButton>
      </Link>
      )}
      </div>
    </div>
  )
}

const EmailVerificationPending = ({ user, email }) => {
  const [canResend, setCanResend] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(60);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanResend(true);
      setResendCountdown(0);
    }, 60000); // 60 seconds timeout

    const countdownInterval = setInterval(() => {
      setResendCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000); // Update countdown every second

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    };
  }, []);

  const resendVerification = () => {
    user.sendEmailVerification();
    setCanResend(false);
    setResendCountdown(60);
  };

  return (
    <div css={css`margin-top: 16px;`}>
      <Text style={TEXT_STYLES.SUBTITLE_3}>
        We've sent a verification email to {email}. Please check your inbox and click the verification link to complete your signup.
      </Text>
      <Text style={TEXT_STYLES.SUBTITLE_3}>
        Once verified, you'll be automatically logged in.
      </Text>
      <div css={css`
        display: flex;
        margin-top: 16px;
      `}>
        <FlowButton 
          fillAvailableWidth 
          sizePreset={SIZE_PRESETS.CHUNKY} 
          disabled={!canResend} 
          onClick={resendVerification}
          customCss={css`text-transform: none;`}
        >
          {canResend ? 'Resend Verification Email' : `Resend available in ${resendCountdown}s`}
        </FlowButton>
      </div>
    </div>
  );
};
