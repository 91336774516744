/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import { normalizeSlug } from '../functions/shared/helpers';
import { BUTTON_STYLES, FlowButton, LoadingIndicator, Text, TextArea, TextInput, TEXT_STYLES, UnstyledLink, Modal } from './components/flowComponents';
import { toastError, toastSuccess } from './components/utils/toaster';
import { FC_BLUE, FC_DARK_BLUE, NEUTRAL_20, NEUTRAL_50, NEUTRAL_70, NEUTRAL_80, NEUTRAL_90 } from './emotionVariables';
import { useRegisterHostUpgradeFormCompletion, useStartHostUpgradeProcess, useUpdateHost } from './fetch/endpoints';
import { useAutomaticallyFetch } from './fetch/helpers';
import { baseURL } from './firebase';
import { useAuthToken, useQuery } from './utils';
import blankAvatarImage from './images/blank-avatar.png';
import { SegmentProvider, useSendSegmentEvent } from './wrappers/SegmentProvider';
import { UserContext } from './UserProvider';
import { ShareMilestoneLinkWithImagePreview } from './components/ShareMilestoneLinkWithImagePreview';
import { uploadPhoto } from './fetch/uploadPhoto';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useModal } from './wrappers/MagnificentlyMunificentModalManager';

const ChooseUsername = ({ goToNextStep }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const [username, setUsername] = useState(user.slug ?? '')

  const { performFetch: updateHost, fetching: loading } = useUpdateHost()
  const usernameSubmitted = async (event) => {
    if (loading) { return }

    event.preventDefault()
    
    const { success, error } = await updateHost({ updateObject: { slug: username } })
    sendSegmentEvent("Username Submitted", { succeeded: success, error, username })
    if (success) {
      goToNextStep()
    } else {
      toastError({ message: error })
    }
  }

  return (
    <div>
      <form onSubmit={usernameSubmitted}>
        <Text customCss={css`margin-bottom: 32px;`}>We'll use this username in the link to your Flow Club host profile.</Text>
        <TextInput wrapperCustomCss={css`text-align: initial; margin-bottom: 4px; flex: 1;`} label={'Username'} placeholder={'Choose a username'} value={username} onChange={event => setUsername(event.target.value)} />
        <Text style={TEXT_STYLES.BODY_2} customCss={css`color: ${NEUTRAL_70}; text-align: initial; margin-bottom: 24px;`}>Host profile: https://in.flow.club/host/{normalizeSlug(username)}</Text>
        <FlowButton fillAvailableWidth disabled={username.length === 0} loading={loading} type={'submit'}>Save & Continue</FlowButton>
      </form>
    </div>
  )
}

const getCroppedImg = ({ imgSrc, scaleX, scaleY, crop, fileName, mimeType }) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.src = imgSrc;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const pixelRatio = window.devicePixelRatio;
      const width = crop.width * pixelRatio * scaleX;
      const height = crop.height * pixelRatio * scaleY;
      const squareWidthOrHeight = Math.max(width, height)
      canvas.width = squareWidthOrHeight
      canvas.height = squareWidthOrHeight
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        img,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName
        resolve(blob);
      }, mimeType);
    };
  });
};


export const CircularImageCropModal = ({ imgSrc, onCropComplete, onCancel, mimeType }) => {
  const [crop, setCrop] = useState(null)
  const [scaleX, setScaleX] = useState(1)
  const [scaleY, setScaleY] = useState(1)

  const onImageLoad = (e) => {
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget
    setScaleX(naturalWidth / width)
    setScaleY(naturalHeight / height)
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width,
        },
        1,
        width,
        height
      ),
      width,
      height
    )
    setCrop(convertToPixelCrop(crop, width, height))
  }

  return (
    <Modal width={500} onClose={onCancel}>
      <div css={css`
        margin-top: 24px;
        padding: 32px 24px 12px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}>
        <ReactCrop
          crop={crop}
          aspect={1}
          circularCrop={true}
          onChange={(crop, _percentCrop) => setCrop(crop)}
        >
          <img
            src={imgSrc}
            onLoad={onImageLoad}
            alt="Image to crop"

            css={css`max-width: 400px; max-height: 400px;`}
          />
        </ReactCrop>
        <FlowButton fillAvailableWidth onClick={() => onCropComplete({ imgSrc, scaleX, scaleY, crop, mimeType })}>Save</FlowButton>
      </div>
    </Modal>
  )
}

export const showCropModalForFileSelected = async (event, showCropModal ) => {
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    const mimeType = file.type;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageSrc = reader.result?.toString() || ''
      showCropModal(imageSrc, mimeType)
    });
    reader.readAsDataURL(file);
  }
};

export const getCroppedImageFile = async ({ user, imgSrc, scaleX, scaleY, crop, mimeType }) => {
  const extension = mimeType === 'image/png' ? 'png' : 'jpg'
  const fileName = `${user.slug}-${user.uid}.${extension}`
  const croppedImageBlob = await getCroppedImg({ imgSrc, scaleX, scaleY, crop, fileName, mimeType });
  const newFile = new File([croppedImageBlob], fileName, {
    type: mimeType,
  })
  return newFile
}


const UploadPicture = ({ goToNextStep }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const [loading, setLoading] = useState(false)
  const [uploadedFilePath, setUploadedFilePath] = useState(user.image100 ?? null)
  const { setActiveModal } = useModal()

  const showCropModal = (imgSrc, mimeType) => {
    setLoading(false)
    setActiveModal(CircularImageCropModal, { imgSrc, onCropComplete, onCancel: hideCropModal, mimeType })
  }
  const hideCropModal = () => {
    setActiveModal(null)
  }

  const getAuthToken = useAuthToken()

  const onCropComplete = async ({ imgSrc, scaleX, scaleY, crop, mimeType,  }) => {
    hideCropModal();
    setLoading(true);
    const newFile = await getCroppedImageFile({ user, imgSrc, scaleX, scaleY, crop, mimeType })
    const authToken = await getAuthToken();
    const { result, error } = await uploadPhoto(authToken, newFile);
    setLoading(false);
    sendSegmentEvent("Picture Submitted", { succeeded: error === null, error });
    if (error === null) {
      setUploadedFilePath(result.imagePath);
      toastSuccess({ message: "Picture successfully uploaded!" });
    } else {
      toastError({ message: error });
    }
  };

  const fileInputRef = useRef(null)

  const uploadPictureClicked = () => {
    if (loading) { return }

    if (fileInputRef.current !== null) {
      fileInputRef.current.click()
    }
  }

  const continueClicked = () => {
    goToNextStep()
  }

  return (
    <div css={css`display: flex; flex-direction: column; align-items: center;`}>
      <div onClick={uploadPictureClicked} css={css`cursor: pointer; width: fit-content;`}>
        {loading ?
          <div css={css`height: 96px; display: flex; justify-content: center; align-items: center; borderradius`}><LoadingIndicator /></div> :
          <img css={css`width: 96px; height: 96px; margin-bottom: 20px; border-radius: 50%;`} src={uploadedFilePath ?? blankAvatarImage} />
        }
        <Text style={TEXT_STYLES.BUTTON} customCss={css`color: ${FC_DARK_BLUE}; margin-bottom: 24px;`}>UPLOAD PICTURE</Text>
      </div>
      <Text customCss={css`color: ${NEUTRAL_90}; margin-bottom: 32px;`}>Upload a profile photo that shows your face clearly. You'll be able to crop it and edit this any time from your host profile.</Text>
      <FlowButton fillAvailableWidth disabled={uploadedFilePath === null} onClick={continueClicked}>Continue</FlowButton>
      <input type="file" accept="image/png, image/jpeg" ref={fileInputRef} onChange={(event) => {
        setLoading(true);
        showCropModalForFileSelected(event, showCropModal);
      }} css={css`display: none;`} />
    </div>
  )
}

const WriteBio = ({ goToNextStep }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const [bio, setBio] = useState(user.bio ?? '')

  const { performFetch: updateHost, fetching: loading } = useUpdateHost()
  const bioSubmitted = async (event) => {
    if (loading) { return }

    event.preventDefault()
    
    const { success, error } = await updateHost({ updateObject: { bio } })
    sendSegmentEvent("Bio Submitted", { succeeded: success, error })
    if (success) {
      goToNextStep()
    } else {
      toastError({ message: error })
    }
  }

  return (
    <div>
      <div css={css`
        background-color: ${NEUTRAL_20};
        border-radius: 8px;
        padding: 16px 24px;
        margin-bottom: 12px;
        text-align: initial;
      `}>
        <Text customCss={css`color: ${NEUTRAL_80}; margin-bottom: 8px;`} style={TEXT_STYLES.OVERLINE}>EXAMPLE</Text>
        <Text customCss={css`color: ${NEUTRAL_70};`}>Join Amy, recovering actor and sales ops leader based in Ohio. Amy uses Flow Club as a flywheel to get started, especially for the work she has been avoiding. Ask her about reading a book a week for the last 3 years.</Text>
      </div>
      <form onSubmit={bioSubmitted}>
        <TextArea wrapperCustomCss={css`margin-bottom: 16px;`} customCss={css`height: 154px;`} value={bio} onChange={event => setBio(event.target.value)} placeholder={'Write something about yourself by following the example above.'} />
        <FlowButton fillAvailableWidth disabled={bio.length === 0} loading={loading} type={'submit'}>Save & Continue</FlowButton>
      </form>
    </div>
  )
}

const FavoriteMusic = ({ goToNextStep }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const [music, setMusic] = useState(user.musicTitles ?? '')

  const { performFetch: updateHost, fetching: loading } = useUpdateHost()
  const musicSubmitted = async (event) => {
    if (loading) { return }

    event.preventDefault()
    
    const { success, error } = await updateHost({ updateObject: { musicTitles: music } })
    sendSegmentEvent("Music Submitted", { succeeded: success, error })
    if (success) {
      goToNextStep()
    } else {
      toastError({ message: error })
    }
  }

  return (
    <div>
      <div css={css`
        background-color: ${NEUTRAL_20};
        border-radius: 8px;
        padding: 16px 24px;
        margin-bottom: 12px;
        text-align: initial;
      `}>
        <Text customCss={css`color: ${NEUTRAL_80}; margin-bottom: 8px;`} style={TEXT_STYLES.OVERLINE}>EXAMPLE</Text>
        <Text customCss={css`color: ${NEUTRAL_70};`}>Yoga Essentials, EDM Hits, Pure Focus (Electronic)</Text>
      </div>
      <form onSubmit={musicSubmitted}>
        <TextArea wrapperCustomCss={css`margin-bottom: 16px;`} customCss={css`height: 154px;`} value={music} onChange={event => setMusic(event.target.value)} placeholder={'What music do you like in sessions? Follow the example above by listing three artists, albums, or playlists that represent your Flow Club musical taste'} />
        <FlowButton fillAvailableWidth disabled={music.length === 0} loading={loading} type={'submit'}>Save & Continue</FlowButton>
      </form>
    </div>
  )
}

const HostingOrientation = ({ goToNextStep }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const continueButtonClicked = () => {
    sendSegmentEvent("Orientation Continue Button Clicked")
    goToNextStep()
  }

  const testSessionButtonClicked = () => {
    sendSegmentEvent("Test Session Button Clicked")
  }

  return (
    <div>

      <Text customCss={css`margin-bottom: 8px;`}>
        Watch this video to learn how to set up your music and navigate yourself around the platform as a host.&nbsp;
        Find the replay and answers to any questions in the <a href="https://www.notion.so/Host-FAQ-3ada1ab2a7f84d7f9826dc4d6e1a570b" target="_blank" rel="noreferrer">Host FAQ</a>.
      </Text>
      <Text customCss={css`margin-bottom: 16px;`}>
        <a href="mailto:hosts@flowclub">Email Hannah at hosts@flow.club</a> if you want to set up a time to test music or ask any lingering questions.
      </Text>
      <div css={css`
        position: relative;
        padding-bottom: 64.86486486486486%;
        height: 0;
        margin-bottom: 32px;
      `}>
        <iframe
          src="https://www.loom.com/embed/d697f3b1058746e599f6f8c14028f58e?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
          frameBorder="0"
          webkitAllowFullScreen={true}
          mozAllowFullScreen={true}
          allowFullScreen={true}
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} />
      </div>
      <div css={css`display: flex; margin-bottom: 32px;`}>
        <Text customCss={css`flex: 7;`}>To get used to the flow of hosting on your own before creating a session, you can explore this test session:</Text>
        <UnstyledLink css={css`flex: 3;`} to="/demo-session/" target="_blank" rel="noreferrer">
          <FlowButton customCss={css`height: 100%;`} onClick={testSessionButtonClicked} fillAvailableWidth>Test Session</FlowButton>
        </UnstyledLink>
      </div>
      <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={continueButtonClicked} fillAvailableWidth>I'm ready!</FlowButton>
    </div>
  )
}

const HostingSetupComplete = ({ }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext)
  const { slug, inviteCode } = user

  const viewProfileButtonClicked = () => {
    sendSegmentEvent("View Profile Button Clicked")
  }

  const { result: upgradeHostResult, error: upgradeHostError } = useAutomaticallyFetch(useRegisterHostUpgradeFormCompletion)
  useEffect(() => {
    if (upgradeHostError !== null) { toastError({ message: upgradeHostError }) }
  }, [upgradeHostError])

  if (upgradeHostResult === null || user.ogImageUrl === undefined || user.ogImageUrl === null ) {
    return <LoadingIndicator/>
  }

  return (
    <div>
      <Text customCss={css`margin-bottom: 32px;`}>Congrats! Your application to become a host was successful. You're ready to create your first session!</Text>
      <UnstyledLink to={`/host/${slug}`} onClick={viewProfileButtonClicked}>
        <FlowButton fillAvailableWidth buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>View my host profile</FlowButton>
      </UnstyledLink>
      <hr />
      <ShareMilestoneLinkWithImagePreview
        inviteLink={`${baseURL}/h/${slug}?inviteCode=${inviteCode}`}
        ogImage={user.ogImageUrl}
        />
    </div>
  )
}

const STEPS = [
  { title: "Choose a username", Content: ChooseUsername },
  { title: "Upload a picture", Content: UploadPicture },
  { title: "Write a bio", Content: WriteBio },
  { title: "What music do you like to flow to?", Content: FavoriteMusic },
  { title: "Hosting Orientation", Content: HostingOrientation },
  { title: "Hosting Setup Complete!", Content: HostingSetupComplete },
]

const HostOnboardingDataLoader = ({ }) => {
  const { fetching: initialLoading, result, error } = useAutomaticallyFetch(useStartHostUpgradeProcess, {}, { transform: result => result.hostUserData })

  const query = useQuery();
  const directlyNavigatedStep = query.get('step')

  if (error !== null) {
    if (error === "Sorry! You're not eligible to host just yet. You must complete 10 sessions first." ||
        error === "User is already an activated host") {
      if (directlyNavigatedStep === null) {
        return <Redirect to={'/'} />
      }
    } else {
      toastError({ message: error })
    }
  }

  // not the most beautiful code I've ever written, I will admit, yes
  let initialStep = directlyNavigatedStep !== null ? parseInt(directlyNavigatedStep, 10) - 1 : 0
  if (result !== null && directlyNavigatedStep === null) {
    if (result.musicTitles !== null) {
      initialStep = 4
    } else if (result.bio !== null) {
      initialStep = 3
    } else if (result.image !== null) {
      initialStep = 2
    } else if (result.slug !== null) {
      initialStep = 1
    }
  }

  return (
    <SegmentProvider eventLabel={'Host Onboarding'}>
      <div css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 64px 0px;
        min-height: 100vh;
      `}>
        <div css={css`
          max-width: 520px;
          padding: 0px 20px;

          text-align: center; 
          display: flex;
          flex-direction: column;
          align-items: center;
        `}>
          {initialLoading ?
            <LoadingIndicator /> :
            <HostOnboardingContent initialStep={initialStep} />
          }
        </div>
      </div>
    </SegmentProvider>
  )
}

export const HostOnboardingContent = ({ initialStep }) => {
  const [currentStep, setStep] = useState(initialStep)

  const onFinalStep = currentStep === STEPS.length - 1

  const { title, Content } = STEPS[currentStep]

  return (
    <>
      <StepIndicator currentStep={currentStep} />
      <Text customCss={css`color: ${NEUTRAL_80}; margin-bottom: 8px;`}>{onFinalStep ? '🎉 Application Complete! 🎉' : `Step ${currentStep + 1}`}</Text>
      <Text style={TEXT_STYLES.APP_H1} customCss={css`margin-bottom: 24px;`}>{title}</Text>
      <Content goToNextStep={() => setStep(step => step + 1)} />
    </>
  )
}

const StepIndicator = ({ currentStep }) => {
  return (
    <div css={css`
      display: flex;
      margin-bottom: 64px;
    `}>
      {STEPS.slice(0, STEPS.length - 1).map((_, index) => <StepIcon key={index} stepIndex={index} currentStep={currentStep} isLast={index === STEPS.length - 2} />)}
    </div>
  )
}

const StepIcon = ({ stepIndex, currentStep, isLast }) => {
  const completed = currentStep > stepIndex
  const active = currentStep === stepIndex
  
  return (
    <div css={css`display: flex; align-items: center;`}>
      <div css={css`
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid ${(active || completed) ? FC_BLUE : NEUTRAL_50};
        background-color: ${completed ? FC_BLUE : 'white'};
        user-select: none;

        display: flex;
        justify-content: center;
        align-items: center;
      `}>
        <Text customCss={css`
          color: ${completed ? 'white' : (active ? FC_BLUE : NEUTRAL_80)};
          font-weight: bold;
        `}>
          {stepIndex + 1}
        </Text>
      </div>
      {!isLast && <div css={css`width: 52px; height: 1px; background-color: ${completed ? FC_BLUE : NEUTRAL_50};`} />}
    </div>
  )
}

export const HostOnboarding = HostOnboardingDataLoader