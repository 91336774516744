/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text, TEXT_STYLES } from './flowComponents';

export const DarkTooltip = ({ children, arrowX, arrowRef, placement }) => {
  return (
    <>
      <div css={css`
        padding: 4px 8px;
        background-color: black;
        border-radius: 8px;
        color: white;
        max-width: 240px;
        text-align: center;
      `}>
        <Text style={TEXT_STYLES.CAPTION}>
          {children}
        </Text>
      </div>
      <div css={css`
        /* A slightly hacky extra element to add the little wedge at the top */
        left: ${arrowX !== null ? `${arrowX}px` : ''};
        ${placement === "top" ? css`bottom: -16px;` : css`top: -16px;`}
        position: absolute;
        border: 8px solid;
        border-color: ${placement === "top" ? 'black transparent transparent transparent' : 'transparent transparent black transparent'};
      `} ref={arrowRef} />
    </>
  )
}