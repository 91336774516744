
export const AudioOff = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="4.90909" y1="18.3837" x2="18.3838" y2="4.909" stroke="#B75C4A" strokeLinecap="round"/>
    <path d="M13.6364 18H10.3636" stroke="#B75C4A" strokeLinecap="round"/>
    <path d="M12 15.8184V18.0002" stroke="#B75C4A"/>
    <rect x="9.81818" y="5.4541" width="4.36364" height="8.72727" rx="2.18182" stroke="#B75C4A"/>
    <path d="M8.18182 9.81836V12.0002C8.18182 14.1089 9.89128 15.8184 12 15.8184V15.8184C14.1087 15.8184 15.8182 14.1089 15.8182 12.0002V9.81836" stroke="#B75C4A" strokeLinecap="round"/>
    </svg>
)

export const AudioOn = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.31812 7.63641C9.31812 6.15528 10.5188 4.95459 11.9999 4.95459C13.4811 4.95459 14.6818 6.15528 14.6818 7.63641V12C14.6818 13.4812 13.4811 14.6819 11.9999 14.6819C10.5188 14.6819 9.31812 13.4812 9.31812 12V7.63641ZM11.9999 5.95459C11.0711 5.95459 10.3181 6.70757 10.3181 7.63641V12C10.3181 12.9289 11.0711 13.6819 11.9999 13.6819C12.9288 13.6819 13.6818 12.9289 13.6818 12V7.63641C13.6818 6.70757 12.9288 5.95459 11.9999 5.95459ZM12.5 17.5001H13.6365C13.9126 17.5001 14.1365 17.7239 14.1365 18.0001C14.1365 18.2762 13.9126 18.5001 13.6365 18.5001H10.3637C10.0876 18.5001 9.86375 18.2762 9.86375 18.0001C9.86375 17.7239 10.0876 17.5001 10.3637 17.5001H11.5V16.2896C9.3507 16.0417 7.68188 14.2158 7.68188 12.0001V9.81824C7.68188 9.54209 7.90574 9.31824 8.18188 9.31824C8.45803 9.31824 8.68188 9.54209 8.68188 9.81824V12.0001C8.68188 13.8326 10.1675 15.3182 12.0001 15.3182C13.8326 15.3182 15.3182 13.8326 15.3182 12.0001V9.81824C15.3182 9.54209 15.5421 9.31824 15.8182 9.31824C16.0944 9.31824 16.3182 9.54209 16.3182 9.81824V12.0001C16.3182 14.2158 14.6494 16.0418 12.5 16.2896V17.5001Z" fill="black"/>
    </svg>
)

export const VideoOff = () => (
    <svg width="80%" height="80%" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.6844 13.0548V13.9056L27.4277 13.4916L36.493 8.44206C36.5937 8.38608 36.7207 8.38595 36.8215 8.44168C36.9221 8.49796 36.9741 8.59314 36.975 8.68894L36.975 25.0091L36.975 25.0132C36.9744 25.1109 36.9211 25.2064 36.8233 25.2604L36.8201 25.2622C36.7202 25.3184 36.5949 25.3184 36.495 25.2622L36.3809 25.1981H36.3784L27.4279 20.2085L26.6844 19.794V20.6452V23.6336C26.6844 24.5409 26.3122 25.4141 25.6425 26.0605C24.972 26.7078 24.0573 27.075 23.1013 27.075H6.08308C5.1273 27.075 4.21415 26.7079 3.54348 26.0605C2.8734 25.4137 2.5 24.5404 2.5 23.6336V10.0664C2.5 8.18218 4.08865 6.625 6.08308 6.625H23.1013C24.0573 6.625 24.972 6.99219 25.6425 7.63946C26.3122 8.28593 26.6844 9.15911 26.6844 10.0664V13.0548ZM26.0067 24.1338H26.0529L26.0528 23.6337L26.0512 10.0666C26.0512 10.0666 26.0512 10.0666 26.0512 10.0666C26.0512 8.46856 24.715 7.20177 23.1014 7.20177H6.08311C4.47124 7.20177 3.13327 8.46839 3.13327 10.0666V23.6338C3.13327 25.232 4.47124 26.4986 6.08311 26.4986H23.1014C24.5426 26.4986 25.7624 25.4882 26.0067 24.1338ZM35.5988 24.0708L36.3419 24.4839L36.3418 23.6337L36.3401 10.0559L36.34 9.20501L35.5967 9.61921L26.941 14.4427L26.6844 14.5857V14.8795V18.8209V19.115L26.9415 19.2579L35.5988 24.0708Z" fill="B75C4A" stroke="B75C4A"/>
        <line x1="2" y1="33.5858" x2="33.5858" y2="2" stroke="B75C4A" strokeWidth="2" strokeLinecap="round"/>
    </svg>
)