import { useEffect } from "react"
import useSound from "use-sound"
import { usePrevious } from "../utils"
import chimeSound from '../sounds/bell.wav'

export const useChimeOnWorkingTimeEnd = (activeWorkingTime) => {
  const workingTimeJustEnded = usePrevious(activeWorkingTime) && !activeWorkingTime
  const [playChime] = useSound(chimeSound)
  useEffect(() => {
    if (workingTimeJustEnded) {
      playChime()
    }
  }, [workingTimeJustEnded])
}