/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import './Chat.scss'

export const CaretIcon = ({ customCss }) => {
  return (
    <svg width="26" height="15" viewBox="0 0 26 15" xmlns="http://www.w3.org/2000/svg" css={customCss}>
    <path d="M11.9383 14.3112C12.2196 14.5925 12.6021 14.7514 13 14.7514C13.398 14.7514 13.7805 14.5925 14.0617 14.3112L24.9363 3.43669C25.5227 2.85028 25.5227 1.89967 24.9363 1.31326C24.3499 0.728262 23.3992 0.728262 22.8142 1.31326L12.9999 11.1276L3.1856 1.31326C2.6006 0.728262 1.64999 0.728262 1.06358 1.31326C0.477178 1.89967 0.477178 2.85028 1.06358 3.43669L11.9383 14.3112Z" />
    </svg>
  );
}


export const CaretUpIcon = ({ customCss }) => {
  return (
    <svg className="inverted-caret" width="26" height="15" viewBox="0 0 26 15" xmlns="http://www.w3.org/2000/svg" css={customCss}>
    <path d="M11.9383 14.3112C12.2196 14.5925 12.6021 14.7514 13 14.7514C13.398 14.7514 13.7805 14.5925 14.0617 14.3112L24.9363 3.43669C25.5227 2.85028 25.5227 1.89967 24.9363 1.31326C24.3499 0.728262 23.3992 0.728262 22.8142 1.31326L12.9999 11.1276L3.1856 1.31326C2.6006 0.728262 1.64999 0.728262 1.06358 1.31326C0.477178 1.89967 0.477178 2.85028 1.06358 3.43669L11.9383 14.3112Z" />
    </svg>
  );
}
