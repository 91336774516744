import React, { useState, useEffect, useContext } from 'react';
import { sortBy } from '../../../../functions/shared/helpers';
import EventContext from '../../../EventContext';
import { useSendChatMessage } from '../../../fetch/endpoints';
import { removeEmojis } from '../../../utils';

const getMessageScenarios = participants => [
  ({ user, sessionWorkingTimeStarted, event }) => {
    const newUser = user.sessions === 0
    const usernameText = user.firstName ? (', ' + user.firstName) : ''
    const { chatOnly } = event
    let message = ''
    if (newUser) {
      if (sessionWorkingTimeStarted) {
        message = `Welcome to your first Flow Club${usernameText}!${chatOnly ? " This session is chat only." : ""} Feel free to write in your goal and get started on your task.`
      } else {
        message = `Welcome to your first Flow Club${usernameText}!${chatOnly ? " This session is chat only. Share your goals by typing in chat." : " The session will begin with the host asking each person to share what they'd like to work on."}`
      }
    } else {
      message = `Welcome to the session${usernameText}!${chatOnly ? " This session is chat only. Share your goals by typing in chat." : ""}`
    }
    return { id: "welcome", sentAt: participants[user.uid] !== undefined && participants[user.uid].joined !== undefined ? participants[user.uid].joined.toMillis() : Date.now(), message }
  },
  ({ user, sessionWorkingTimeStarted, sessionWorkingTimeFinished, event }) => {
    const newUser = user.sessions === 0
    if (newUser && sessionWorkingTimeStarted && !sessionWorkingTimeFinished) {
      return { id: "workStarted", sentAt: event.start.getTime(), message: "It's time for deep work. Everyone's mic will be muted. Feel free to get started on your tasks!" }
    } else {
      return null
    }
  },
  ({ user, sessionWorkingTimeFinished, event }) => {
    const newUser = user.sessions === 0
    if (sessionWorkingTimeFinished) {
      let message = ''
      if (newUser) {
        message = "Session complete! Thanks for trying Flow Club. After you debrief and celebrate with the group, hit the red leave button and give yourself a flow score for this session."
      } else {
        const options = ["Session complete! Time to celebrate.", "Session complete! You showed up and that means a lot!"]
        message = options[Math.floor(Math.random() * options.length)]
      }
      return { id: "sessionComplete", sentAt: event.end.getTime(), message }
    } else {
      return null
    }
  },
  ...Object.keys(participants).map(participantId =>
    ({ userIsHost, user }) => {
      const { hideDisplayNameEmojis } = user.preferences || {}
      const participant = participants[participantId]
      if (userIsHost && participant.userId !== user.uid && participant.joined !== undefined) {
        const { name, joined } = participant
        const displayName = hideDisplayNameEmojis ? removeEmojis(name) : name

        // this case specifically returns an explicit id, in order to avoid weirdness if the participants list is reordered, added to/removed from, etc
        // is this really better than having every case return an id? unclear. I kind of suspect no. But I liked the simplicity of returning just a string normally
        // so 🤷‍♂️
        return { id: participantId, sentAt: joined.toMillis(), message: `${displayName} joined the session.` }
      } else {
        return null
      }
    }
  )
]

const getActiveBotMessages = (user, participants, event, userIsHost, sessionWorkingTimeStarted, sessionWorkingTimeFinished) => {
  return getMessageScenarios(participants)
    .map((scenario, index) => {
      let scenarioOutput = scenario({ user, participants, event, userIsHost, sessionWorkingTimeStarted, sessionWorkingTimeFinished })
      // janky code because of the above-mentioned irregular return shape from getMessageScenarios functions
      if (scenarioOutput === null || scenarioOutput.id === undefined) {
        return { id: index, message: scenarioOutput }
      } else {
        return scenarioOutput
      }
    })
    .filter(({ message }) => message !== null)
}

export const useChat = (user, eventId, participants, userId, userIsHost, sessionWorkingTimeStarted, sessionWorkingTimeFinished) => {
  const { performFetch: sendMessage, fetching, error } = useSendChatMessage()
  const { chatHistory, event } = useContext(EventContext)

  const [pendingMessages, setPendingMessages] = useState([])

  const submitMessage = (message) => {
    const sentAt = Date.now()
    const messageId = `S${userId}T${sentAt}`
    sendMessage({ eventId, message, messageId })
    setPendingMessages(messages => [...messages, { sender: userId, message: message, eventId, id: messageId, sentAt, pending: true }])
  }

  const maxRetries = 5
  const [retries, setRetries] = useState(0)
  useEffect(() => {
    setPendingMessages(pendingMessages => pendingMessages.filter(message => !chatHistory.some(receivedMessage => receivedMessage.id === message.id)))
    setRetries(0)
  }, [chatHistory.length])

  useEffect(() => {
    if (error) {
      // if we get an error back, remove the most recently sent message as it likely didn't go through, and retry the request
      // (after a fake delay, because react state management & promises are confusing, and `error` gets set to true before `fetching` is set to false)
      const lastMessage = pendingMessages.pop()
      setPendingMessages(pendingMessages)

      if (retries < maxRetries) {
        setTimeout(function () {
          submitMessage(lastMessage.message)
          setRetries(retries + 1)
        }, 0)
      } else {
        // TODO: should probably actually display some sort of error somewhere rather than silently give up
        setRetries(0)
      }
    }
  }, [error])
  
  const activeBotMessages = getActiveBotMessages(user, participants, event, userIsHost, sessionWorkingTimeStarted, sessionWorkingTimeFinished)
  const [displayedBotMessages, setDisplayedBotMessages] = useState([])
  useEffect(() => {
    // Bot messages diff based on an id that is unique to their display-condition in order to avoid displaying the same message more than once
    const newMessages = activeBotMessages.filter(({ id }) => !displayedBotMessages.some(displayedMessage => displayedMessage.id === id))
    setDisplayedBotMessages(displayedBotMessages.concat(newMessages))
  }, [JSON.stringify(activeBotMessages.map(({ id }) => id))])

  let messagesIncludingPending = chatHistory.concat(pendingMessages)
  if (participants !== null) {
    messagesIncludingPending = messagesIncludingPending.map(message => ({
      ...message,
      senderId: message.sender,
      sender: participants[message.sender] !== undefined ? participants[message.sender].name : "(unknown user)",
      senderColorIndex: participants[message.sender] !== undefined ? participants[message.sender].chatColorIndex : 0
    }))
  }

  const allMessages = sortBy(messagesIncludingPending.concat(displayedBotMessages), "sentAt")

  return { chatHistory: allMessages, sendMessage: submitMessage, messageSending: fetching }
}