/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router";
import { SubscriptionStatus, ONE_DAY, TRIAL_EXTENSION_LENGTH } from '../../functions/shared/constants';
import { userEligibleForResubscribePromo, userEligibleForTrialExtension } from "../../functions/shared/helpers";
import { BUTTON_STYLES, FlowButton, Text, TEXT_STYLES, UnstyledLink } from "../components/flowComponents";
import { HostAvatar, HostAvatarGroup } from "../components/hosts/avatar";
import { ActionModal, LockoutModal, UnlockIcon } from "../shared/components/modals";
import { UserContext } from "../UserProvider";
import { useQuery } from "../utils";
import dayjs from 'dayjs';
import { FC_IRIS_BLUE } from '../emotionVariables';
import { useGetBillingCustomerPortalUrl } from '../fetch/endpoints';
import { useAutomaticallyFetch } from '../fetch/helpers';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

export const SubscriptionStatusModals = () => {
  const { user } = useContext(UserContext);
  const query = useQuery();
  const location = useLocation()

  const showUnlockExtensionAction = query.get('trialExtended', false)
  const subscribeSessionId = query.get('session_id', false)
  const [subscribeWelcomeActionModalShow, setSubscribeWelcomeActionModalShow] = useState(subscribeSessionId);
  const [unlockExtensionActionModalShow, setUnlockExtensionActionModalShow] = useState(showUnlockExtensionAction)
  const billingPaths = new Set(['/plans/', '/trial-extension/', 'trial-ended/'])
  const trialExtensionInDays = TRIAL_EXTENSION_LENGTH / ONE_DAY

  if (user === null || billingPaths.has(location.pathname)) {
    return null
  }

  const hasCanceledSubscription = user.subscriptionStatus === SubscriptionStatus.CANCELED
  const canceledResubscriptionDiscountAvailable = userEligibleForResubscribePromo(user)
  const hasPausedSubscription = user.subscriptionStatus === SubscriptionStatus.PAUSED
  const hasExpiredTrial =
    (user.subscriptionStatus === SubscriptionStatus.TRIALING_NO_CARD || user.subscriptionStatus === SubscriptionStatus.ACTIVE_BUT_TRIALING) &&
    user.trialEnd !== undefined &&
    user.trialEnd !== null &&
    user.trialEnd.toDate() < new Date()
  // Allow users current in trial with fewer than 5 sessions
  // or users whose trial expired more than 2 weeks ago
  // to extend their trial
  const expiredTrialExtensionAvailable = !user.trialExtensionSubmitted && userEligibleForTrialExtension(user)

  if (hasExpiredTrial) {
    return <TrialEndedModal expiredTrialExtensionAvailable={expiredTrialExtensionAvailable} />
  }

  if (hasPausedSubscription) {
    return <SubscriptionPausedModal />
  }

  if (hasCanceledSubscription) {
    return <SubscriptionEndedModal canceledResubscriptionDiscountAvailable={canceledResubscriptionDiscountAvailable} />
  }

  return (
    <div>
      <ActionModal
        show={subscribeWelcomeActionModalShow}
        onHide={() => setSubscribeWelcomeActionModalShow(false)}
      >
        <HostAvatarGroup className="fcModal-img" />
        <h2>You're officially in Flow Club</h2>
        <p>We're so excited to have you here. Flow Club is part co-working space, part mental fitness, and part inspired community. </p>
        <Button variant="primary" onClick={() => setSubscribeWelcomeActionModalShow(false)}>continue</Button>
      </ActionModal>
      <ActionModal
        show={unlockExtensionActionModalShow}
        onHide={() => setUnlockExtensionActionModalShow(false)}
      >
        <UnlockIcon className="fcModal-img" />
        <h2>You've unlocked a trial extension!</h2>
        <p>Nice! Your free trial is extended another {trialExtensionInDays} days. Hop in some sessions to tackle your most important goal.</p>
        <Button variant="primary" onClick={() => setUnlockExtensionActionModalShow(false)}>book a session</Button>
      </ActionModal>
    </div>
  )
}

const TrialEndedModal = ({ expiredTrialExtensionAvailable }) => {
  const { user } = useContext(UserContext)

  const sendSegmentEvent = useSendSegmentEvent()
  const extendTrialClicked = () => {
    sendSegmentEvent("[Trial Ended Modal] Extend Trial Clicked")
  }
  const seePlansClicked = () => {
    sendSegmentEvent("[Trial Ended Modal] See Plans Clicked")
  }

  return (
    <LockoutModal>
      {user.trialExtensionMIT &&
        <div className="fcModal-mit">
          <div className="fcModal-mit-content">
            <div className="fcModal-mit-label">your most important task</div>
            <div className="fcModal-mit-data">{user.trialExtensionMIT}</div>
          </div>
        </div>}
      <HostAvatar className="fcModal-img" />
      <h2>Keep up your good work!</h2>
      <p>Thanks for trying out Flow Club. Your free trial has ended. Choose a membership plan to continue using Flow Club.</p>
      <div css={css`display: flex; justify-content: center;`}>
        {expiredTrialExtensionAvailable &&
          <UnstyledLink to="/trial-extension/" onClick={extendTrialClicked}>
            <FlowButton buttonStyle={BUTTON_STYLES.NO_BACKGROUND}>Extend my trial</FlowButton>
          </UnstyledLink>}
        <Button onClick={seePlansClicked} className="ml-3" variant="primary" href="/plans/">see plans</Button>
      </div>
    </LockoutModal>
  )
}

const SubscriptionPausedModal = () => {
  const { user } = useContext(UserContext)
  const subscriptionResumesDate = user.resumesAt ?? null

  const { result: portalURL } = useAutomaticallyFetch(useGetBillingCustomerPortalUrl, {}, { transform: result => result.url, condition: user.stripeCustomerId !== undefined })

  const sendSegmentEvent = useSendSegmentEvent()
  const manageMembershipClicked = () => {
    sendSegmentEvent("[Subscription Paused Modal] Manage Membership Clicked")
  }
  const reactivateClicked = () => {
    sendSegmentEvent("[Subscription Paused Modal] Reactivate Now Clicked")
  }

  return (
    <LockoutModal>
      <div css={css`display: flex; flex-direction: column; gap: 12px;`}>
        <Text style={TEXT_STYLES.APP_H4}>You've paused Flow Club</Text>
        {subscriptionResumesDate !== null && <Text>Your membership is scheduled to resume on {dayjs(subscriptionResumesDate.toDate()).format('MMMM D, YYYY')}.</Text>}
        <div css={css`display: flex; justify-content: center; gap: 8px;`}>
          <a onClick={manageMembershipClicked} href={portalURL} target="_blank" rel="noreferrer">
            <FlowButton loading={portalURL === null} buttonStyle={BUTTON_STYLES.NO_BACKGROUND}>Manage Membership</FlowButton>
          </a>
          <a onClick={reactivateClicked} href={portalURL} target="_blank" rel="noreferrer">
            <FlowButton loading={portalURL === null}>Reactivate Now</FlowButton>
          </a>
        </div>
      </div>
    </LockoutModal>
  )
}

const SubscriptionEndedModal = ({ canceledResubscriptionDiscountAvailable }) => {

  const sendSegmentEvent = useSendSegmentEvent()
  const resubscribeClicked = () => {
    sendSegmentEvent("[Subscription Ended Modal] Resubscribe Clicked", { canceledResubscriptionDiscountAvailable })
  }

  return (
    <LockoutModal>
      <div css={css`display: flex; flex-direction: column; gap: 12px; align-items: center;`}>
        {canceledResubscriptionDiscountAvailable ?
          <img src="https://storage.googleapis.com/flowclub-static/images/reflow2.png" alt="reflow" width="270px" /> :
          <HostAvatar/>
        }
        <Text style={TEXT_STYLES.APP_H4}>{canceledResubscriptionDiscountAvailable ? 'Welcome back! A limited time offer for you' : 'Sorry to see you go!'}</Text>
        <Text>{canceledResubscriptionDiscountAvailable ?
          <>Resubscribe and get 50% off your first month with code <span css={css`color: ${FC_IRIS_BLUE}; font-weight: bold;`}>REFLOW</span>.</> :
          'Thanks for trying out Flow Club. It looks like you need to resubscribe in order to continue using Flow Club.'
        }</Text>
        <div css={css`display: flex; justify-content: center;`}>
          <a onClick={resubscribeClicked} href={'/membership/'} target="_blank" rel="noreferrer">
            <FlowButton>Resubscribe</FlowButton>
          </a>
        </div>
      </div>
    </LockoutModal>
  )
}