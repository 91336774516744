/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_DARK_BLUE } from '../emotionVariables';

export const TimeDisplay = ({ time }) => {
  return (
    <div css={css`
      font-family: 'Sato';
      font-weight: 500;
      font-size: 48px;
      line-height: 72px;

      text-align: center;

      color: ${FC_DARK_BLUE};

    `}>
      {time}
    </div>
  )
}