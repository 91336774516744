/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LinkStyledText } from '../components/flowComponents';
import { UserContext, useGetDisplayName } from '../UserProvider';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { ClaimHostModal } from './modals/ClaimHostModal';
import { getHostClaimability } from './SessionUtils';


export const HostDisplayName = ({ event, onHostClaimSuccess = () => {} }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext)

  const { isEmptyHost, userCanClaimHosting, alreadyBooked } = getHostClaimability({ event, user })
  const { hostUser } = event
  const hostDisplayName = useGetDisplayName(hostUser)

  const { setActiveModal } = useModal()
  const claimHostLinkClicked = (clickEvent) => {
    clickEvent.stopPropagation()
    sendSegmentEvent("Claim Host Link Clicked")
    setActiveModal(ClaimHostModal, { event, alreadyBooked, onSuccess: onHostClaimSuccess })
  }

  const hostProfileLinkClicked = () => {
    sendSegmentEvent("Host Profile Link Clicked", { from: "sessionDetails", slug: hostUser.slug })
  }

  if (!isEmptyHost) {
    return <Link onClick={hostProfileLinkClicked} to={`/host/${hostUser.slug}`}>{hostDisplayName}</Link>
  }

  if (userCanClaimHosting) {
    return <LinkStyledText onClick={claimHostLinkClicked}>Would you like to host?</LinkStyledText>
  }

  return 'Looking for a host'
}