
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const welcomeCss = css`
    width: 311px;
    height: 188px;
    
    @media(min-width: 769px) {
      width: 360px;
      height: 242px;
    }
`

export const WelcomeImage1 = () => (
  <svg css={welcomeCss} width="520" height="340" viewBox="0 0 520 340" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="520" height="340" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M202.52 41.0579C149.597 12.6976 81.7865 14.5585 30.5194 45.708C30.0474 45.9947 29.4323 45.8446 29.1455 45.3726C28.8588 44.9006 29.0089 44.2855 29.4809 43.9987C81.3526 12.4818 149.917 10.6002 203.464 39.2951C216.937 46.5145 229.584 58.5855 241.866 72.8793C252.42 85.1629 262.755 99.1497 273.135 113.199C274.837 115.503 276.541 117.808 278.246 120.108C290.37 136.454 302.621 152.527 315.481 165.851C328.344 179.178 341.754 189.685 356.169 194.967C401.61 211.618 441.52 191.805 495.492 159.97C495.968 159.69 496.581 159.848 496.861 160.324C497.142 160.799 496.984 161.412 496.508 161.693C442.653 193.459 401.927 213.864 355.481 196.845C340.658 191.413 326.999 180.663 314.042 167.24C301.081 153.812 288.761 137.642 276.64 121.299C274.93 118.994 273.223 116.684 271.52 114.379C261.136 100.325 250.851 86.4057 240.349 74.1827C228.121 59.9509 215.665 48.1023 202.52 41.0579Z" fill="#D2DDEC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M181.337 100.223C147.656 76.4516 98.7792 69.8454 41.6827 101.285C32.9745 106.08 22.0279 102.908 17.2328 94.1997C12.4377 85.4915 15.6099 74.5449 24.3181 69.7498C92.1284 32.4107 155.95 38.2422 202.096 70.8108C223.297 85.7743 240.962 110.779 257.526 134.227C259.015 136.335 260.496 138.431 261.969 140.506C280.813 167.04 299.925 192.584 324.701 210.605C355.198 232.787 413.804 241.761 486.66 197.535C495.158 192.377 506.229 195.084 511.387 203.582C516.546 212.08 513.839 223.15 505.341 228.309C423.237 278.149 348.484 272.418 303.526 239.719C273.397 217.805 251.268 187.612 232.618 161.35C231.536 159.826 230.468 158.321 229.414 156.834C211.342 131.349 197.217 111.43 181.337 100.223Z" fill="#515EA8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M159.965 123.344C121.911 99.2171 71.8618 101.47 14.5806 142.314C14.131 142.635 13.5065 142.53 13.1859 142.08C12.8652 141.631 12.9698 141.006 13.4195 140.686C71.1384 99.5296 122.089 96.9614 161.036 121.655C170.804 127.849 180.275 137.843 190.074 149.886C196.392 157.652 202.886 166.321 209.704 175.423C213.458 180.434 217.31 185.576 221.284 190.77C243.715 220.083 270.197 251.251 305.83 271.283C352.396 297.461 419.146 296.593 485.583 266.065C486.084 265.834 486.678 266.054 486.909 266.556C487.139 267.058 486.919 267.651 486.418 267.882C419.588 298.591 352.134 299.608 304.85 273.027C268.837 252.781 242.149 221.327 219.696 191.985C215.691 186.751 211.826 181.591 208.067 176.573C201.268 167.497 194.818 158.886 188.523 151.149C178.749 139.137 169.446 129.356 159.965 123.344Z" fill="#D2DDEC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M144.779 148.1C103.135 124.98 54.5211 139.866 7.63371 178.273C7.20646 178.623 6.57641 178.56 6.22643 178.133C5.87646 177.706 5.93911 177.076 6.36635 176.726C53.479 138.134 103.018 122.627 145.75 146.351C156.482 152.31 166.507 162.946 176.662 175.929C183.164 184.243 189.769 193.587 196.67 203.349C200.542 208.827 204.508 214.437 208.601 220.07C231.432 251.496 258.378 283.877 296.177 300.073C351.647 323.841 414.665 317.769 480.638 292.146C481.153 291.946 481.732 292.201 481.932 292.716C482.132 293.231 481.877 293.81 481.362 294.01C415.117 319.739 351.518 325.962 295.389 301.911C257.048 285.483 229.829 252.692 206.983 221.246C202.853 215.562 198.871 209.929 194.993 204.442C188.115 194.712 181.563 185.442 175.086 177.161C164.96 164.215 155.141 153.852 144.779 148.1Z" fill="#D2DDEC" />
    <ellipse cx="42.6791" cy="81.7065" rx="10.2627" ry="9.93168" transform="rotate(-6.74925 42.6791 81.7065)" fill="#D2DDEC" />
    <ellipse cx="144.901" cy="65.7632" rx="10.2627" ry="9.93168" transform="rotate(-6.74925 144.901 65.7632)" fill="#D2DDEC" />
    <ellipse cx="233.993" cy="131.41" rx="10.2627" ry="9.93168" transform="rotate(-6.74925 233.993 131.41)" fill="#D2DDEC" />
    <ellipse cx="295.888" cy="211.124" rx="10.2627" ry="9.93168" transform="rotate(-6.74925 295.888 211.124)" fill="#D2DDEC" />
    <ellipse cx="394.358" cy="246.76" rx="10.2627" ry="9.93168" transform="rotate(-6.74925 394.358 246.76)" fill="#D2DDEC" />
    <ellipse cx="495.641" cy="213" rx="10.2627" ry="9.93168" transform="rotate(-6.74925 495.641 213)" fill="#D2DDEC" />
  </svg>
)
