/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FlowButton } from '../../components/flowComponents';
import { Reset } from '../../shared/components/icons';

export const Controls = ({ timerHasStarted, startTimer, reset }) => {
  return (
    <div css={css`
      display: flex;
      justify-content: center;
    `}>
      {!timerHasStarted ?
        <FlowButton onClick={startTimer}>Start</FlowButton> :
        <div css={css`cursor: pointer; width: fit-content; stroke: black;`} onClick={reset}>
          <Reset />
        </div>
      }
    </div>
  )
}