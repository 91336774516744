/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import { useRequestHostTBDSessionAndBook } from '../fetch/endpoints';
import { useBookSessionWrapper } from '../Sessions/modals/BookSessionModal';
import { FlowButton, Text, TEXT_STYLES } from '../components/flowComponents';
import { toastError } from '../components/utils/toaster';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { FC_GREEN, NEUTRAL_40, NEUTRAL_90 } from '../emotionVariables';
import { useContext, useState } from 'react';
import { formattedTimeFromMinutes } from './CalendarBooking';
import { LoginSignupModal } from '../Sessions/modals/LoginSignupModal';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { getUserIsUnauthed } from '../../functions/shared/helpers';
import { openBookingSessionModal } from '../bookingLogic/useBookSessionButtonHandler';
import { BookSessionModalContent } from '../Sessions/modals/BookSessionModalContent';

const openBookingSessionOrRequestingSessionModal = ({ type, user, event, setActiveModal, onSuccess }) => {
  if (type === 'session') {
    openBookingSessionModal(user, event, setActiveModal, onSuccess)
  } else {
    openRequestSessionModal({ user, event, setActiveModal, onSuccess })
  }
}

const openRequestSessionModal = ({ user, event, setActiveModal, onSuccess }) => {
  setActiveModal(RequestAndBookSessionModal, { user, event, onSuccess })
}

const RequestAndBookSessionModal = ({ event, onHide, onSuccess = () => {} }) => {

  const { performFetch: requestAndBookSession, fetching: requestSessionLoading } = useRequestHostTBDSessionAndBook()
  const [ updatedEvent, setUpdatedEvent ] = useState(event)
  useEffect(() => {
    const doRequestAndBookSession = async () => {
      const { result, success, error } = await requestAndBookSession({ startTimestamp: event.startTimestamp })
      if (success) {
        onSuccess()
        setUpdatedEvent({ ...event, id: result.eventId })
      } else {
        toastError({ message: error })
      }
    }
    doRequestAndBookSession({ startTimestamp: event.startTimestamp })
  }, [])

  return (
    <BookSessionModalContent event={updatedEvent} onHide={onHide} loading={requestSessionLoading} error={null} />
  );
}

export const SessionRecommendation = ({ recommendation, onBookingSuccess }) => {
  const { user } = useContext(UserContext)
  const [booked, setBooked] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()
  const { setActiveModal } = useModal()

  const { id, type, title, startMinutes, endMinutes, startTimestamp } = recommendation
  let { description } = recommendation
  const event = {
    ...recommendation,
    start: dayjs(startTimestamp).toDate(),
  }

  const { bookSession, bookingSession } = useBookSessionWrapper()
  const { performFetch: requestAndBookSession, fetching: requestSessionLoading } = useRequestHostTBDSessionAndBook()
  const loading = bookingSession || requestSessionLoading
  
  const bookOrRequestSession = async () => {
    const bookingMethod = type === 'session' ?
    () => bookSession({ id }) :
    () => requestAndBookSession({ startTimestamp })

    const { success, error } = await bookingMethod({ id })
    if (success) {
      setBooked(true)
      onBookingSuccess()
    } else {
      toastError({ message: error })
    }
  }

  const showLoginAndBookOrRequestSession = () => {
    setActiveModal(LoginSignupModal, {
      showLoginModeLink: true,
      onSuccess: async ({ user }) => { openBookingSessionOrRequestingSessionModal({ type, user, event, setActiveModal, onSuccess: () => {
        setBooked(true)
        onBookingSuccess()
      }}) }
    })
  }

  const bookButtonClicked = async () => {
    const nonUserOrUnauthedUser = user === null || getUserIsUnauthed(user)
    sendSegmentEvent("Recommendation Book Button Clicked", { recommendation, redirectedToSignup: nonUserOrUnauthedUser })

    if (nonUserOrUnauthedUser) {
      showLoginAndBookOrRequestSession()
    } else {
      await bookOrRequestSession()
    }
  }


  return (
    <div css={css`border: 1px solid ${NEUTRAL_40}; padding: 24px 16px; border-radius: 8px;`}>
      <div css={css`display: flex; flex-direction: column; gap: 8px;`}>
        {type === 'session' ?
        <Link to={`/session/${id}`}>
          <Text style={TEXT_STYLES.APP_H5} customCss={css``}>{title}</Text>
        </Link> : //requestSession
        title !== undefined ? <Text style={TEXT_STYLES.APP_H5} customCss={css``}>{title}</Text> : null
        }
        {description !== undefined && <Text style={TEXT_STYLES.BODY_1} customCss={css`color: ${NEUTRAL_90};`}>{description}</Text>}
      </div>
      <div css={css`display: flex; align-items: center; flex-direction: column;`}>
        <Text style={TEXT_STYLES.BODY_1} customCss={css`margin-bottom: 8px; color: ${NEUTRAL_90}; font-weight: bold;`}>{formattedTimeFromMinutes(startMinutes)} — {formattedTimeFromMinutes(endMinutes)}</Text>
        {!booked ?
          <FlowButton onClick={bookButtonClicked} loading={loading} customCss={css`width: 121px;`}>Book</FlowButton> :
          <Text style={TEXT_STYLES.APP_H5} customCss={css`color: ${FC_GREEN};`}>Booked!</Text>
        }
      </div>
    </div>
  )
}