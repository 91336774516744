/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { UpgradeUnauthedUser } from '../auth/UpgradeUnauthedUser';
import { Text, TEXT_STYLES } from '../components/flowComponents';
import { toastSuccess } from '../components/utils/toaster';
import { NEUTRAL_90 } from '../emotionVariables';

export const PostSessionSignupPrompt = ({ canCreateSessions, isFirstSession }) => {
  const showCanHostMessage = canCreateSessions && isFirstSession
  const message = showCanHostMessage ?
    'Host sessions for your team, and access 1,500+ weekly Flow Club sessions with Flow Club members.' :
    'Sign up for an account to record your tasks from this session and to access 1,500+ weekly Flow Club sessions.'

  const onSuccess = () => {
    toastSuccess({ message: `Signup successful! Welcome to Flow Club 😃` })
  }

  return (
    <div css={css`
      max-width: 554px;
      display: flex;
      flex-direction: column;
      align-items: center;
    `}>
      <Text customCss={css`margin-bottom: 12px;`} style={TEXT_STYLES.APP_H3}>Create an account</Text>
      <Text customCss={css`color: ${NEUTRAL_90};`}>{message}</Text>
      <div css={css`width: 100%; max-width: 450px;`}>
        <UpgradeUnauthedUser onSuccess={onSuccess} />
      </div>
    </div>
  )
}