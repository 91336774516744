/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef, useContext } from 'react'
import { Text, TEXT_STYLES } from '../components/flowComponents';
import { CalendarViewContext } from './CalendarView';

const getRelativeLocationInElement = (cursorX, dayWidth, cursorY, dayHeight) => ({ x: cursorX / dayWidth, y: cursorY / dayHeight })

export const DayColumn = ({ title = '', contents = [], interactionEvents = {}, customCss = css`background-color: white; border: 1px solid black;` }) => {
  const { rowHeight: height, mobileView } = useContext(CalendarViewContext)
  
  const {
    onTouchStart = () => {},
    onTouchMove = () => {},
    onTouchEnd = () => {},
    onTouchCancel = () => {},

    onMouseDown = () => {},
    onMouseUp = () => {},
    onMouseMove = () => {},
    onMouseLeave = () => {},

    onClick = () => {}
  } = interactionEvents

  const intermediaryOnMouseMove = event => {
    const { offsetX, offsetY } = event.nativeEvent
    const { clientWidth, clientHeight } = event.target

    onMouseMove(getRelativeLocationInElement(offsetX, clientWidth, offsetY, clientHeight))
  }

  const intermediaryOnTouchStart = event => {
    const { clientX, clientY } = event.touches[0]
    const { x, y, width: clientWidth, height: clientHeight } = event.touches[0].target.getBoundingClientRect()
    const offsetX = clientX - x
    const offsetY = clientY - y

    onTouchStart(getRelativeLocationInElement(offsetX, clientWidth, offsetY, clientHeight))
  }

  const intermediaryOnTouchMove = event => {
    const { clientX, clientY } = event.touches[0]
    const { x, y, width: clientWidth, height: clientHeight } = event.touches[0].target.getBoundingClientRect()
    const offsetX = clientX - x
    const offsetY = clientY - y

    onTouchMove(getRelativeLocationInElement(offsetX, clientWidth, offsetY, clientHeight))
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;

      touch-action: pan-x;
    `}>
      <Text style={TEXT_STYLES.CAPTION} customCss={css`margin-bottom: 12px; white-space: nowrap; font-size: 11px;`}>{title}</Text>
      <div
        onTouchStart={intermediaryOnTouchStart}
        onTouchMove={intermediaryOnTouchMove}
        onTouchEnd={onTouchEnd}
        onTouchCancel={onTouchCancel}

        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={intermediaryOnMouseMove}
        onMouseLeave={onMouseLeave}

        css={css`
          background-color: white;
          border-radius: 6px;
          width: ${mobileView ? '100%' : '100px'};
          height: ${height}px;

          position: relative;
          
          ${customCss}
        `}
      >
        {contents.map(({ startMinutes, endMinutes, customCss = null, children = null, hourBlockRef = null, key }) => (
          <HourBlock totalHeight={height} startMinutes={startMinutes} endMinutes={endMinutes} customCss={customCss} onMouseLeave={onMouseLeave} ref={hourBlockRef} key={key}>
            {children}
          </HourBlock>
        ))}
      </div>
    </div>
  )
}

const HourBlock = forwardRef(({ totalHeight, startMinutes, endMinutes, customCss, onMouseLeave, children }, ref) => {
  const startTimeRelativeY = (startMinutes) / (24 * 60)
  const eventDurationRelativeY = (endMinutes - startMinutes) / (24 * 60)

  const endOfDay = endMinutes === 0 || endMinutes === 24 * 60
  const startOfDay = startMinutes === 0 || startMinutes === 24 * 60

  const onInteraction = (event) => {
    event.stopPropagation();
    onMouseLeave(); 
  }

  return (
    <div ref={ref} onMouseMove={onInteraction} onTouchMove={onInteraction} onTouchStart={onInteraction} css={css`
      width: 100%;
      height: ${eventDurationRelativeY * totalHeight}px;
      background-color: transparent;

      position: absolute;
      left: 0px;
      top: ${startTimeRelativeY * totalHeight}px;

      pointer-events: none;
      user-select: none;

      display: flex;
      justify-content: center;
      align-items: center;
      
      ${startOfDay && css`
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      `}
      ${endOfDay && css`
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      `}

      ${customCss}
    `}>
      {children}
    </div>
  )
})