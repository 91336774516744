/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useCallback, useMemo } from "react";
import {
  useParticipantIds,
  useLocalParticipant,
  useDailyEvent,
  DailyAudio,
} from "@daily-co/daily-react";
import { ExclusiveMouseoverWrapper } from "../ExclusiveMouseoverWrapper";

import LoungeVideoTile from "./LoungeVideoTile";
import LoungeVideoError from "./LoungeVideoError";

export default function LoungeCall({ users }) {
  /* If a participant runs into a getUserMedia() error, we need to warn them. */
  const [getUserMediaError, setGetUserMediaError] = useState(false);

  /* We can use the useDailyEvent() hook to listen for daily-js events. Here's a full list of all events: https://docs.daily.co/reference/daily-js/events */
  useDailyEvent(
    "camera-error",
    useCallback(() => {
      setGetUserMediaError(true);
    }, [])
  );

 // Should be a SortParticipantsFunction
 // like here https://github.com/daily-co/daily-react/blob/3a504ea4ae5fbb658ee9baa27eb6ccb79f105aeb/src/hooks/useParticipantIds.ts#L110
  const loungeParticipantSortFunction = (a, b) => {
    if (a === undefined || b === undefined) {
      return 0;
    }
    if (a.permissions.canSend && !b.permissions.canSend) {
      return -1;
    }
    if (!a.permissions.canSend && b.permissions.canSend) {
      return 1;
    }
    const aTrackOn = a.tracks?.video?.state === 'playable'
    const bTrackOn = b.tracks?.video?.state === 'playable'
    if (aTrackOn && !bTrackOn) {
      return -1;
    }
    if(!aTrackOn && bTrackOn) {
      return 1;
    }
    const userA = users.find((user) => user.userId === a.user_id);
    const userB = users.find((user) => user.userId === b.user_id);
    if (userB === undefined) {
      return -1;
    }
    if (userA === undefined) {
      return 1;
    }
    if (userB.timedSession === undefined || userB.timedSession.start === undefined) {
      return -1;
    }
    if (userA.timedSession === undefined || userA.timedSession.start === undefined) {
      return 1;
    }
    if (userA.name !== undefined && userB.name !== undefined) {
      return userA.name > userB.name ? 1 : -1;
    }
    return 0;
  }

  const remoteParticipantIds = useParticipantIds({ filter: "remote", sort: loungeParticipantSortFunction });

  /* This is for displaying our self-view. */
  const localParticipant = useLocalParticipant();
  const isAlone = useMemo(
    () => remoteParticipantIds?.length < 1,
    [remoteParticipantIds]
  );

  const renderCallScreen = () => (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        width: 100%; 
        gap: 16px;
        padding: 16px;
      `}
    >
      <ExclusiveMouseoverWrapper exclusivityKey={0}>
        {/* self view */}
        {localParticipant && (
          <LoungeVideoTile
            id={localParticipant.session_id}
            isLocal
            isAlone={isAlone}
            users={users}
          />
        )}
        {/* Videos of remote participants */}
        {remoteParticipantIds?.length > 0 && (
          <>
            {remoteParticipantIds.map((id) => (
              <LoungeVideoTile key={id} id={id} users={users} />
            ))}
          </>
        )}
      </ExclusiveMouseoverWrapper>
    </div>
  );

  return getUserMediaError ? <LoungeVideoError /> : renderCallScreen();
}
