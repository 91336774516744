import {
  Container,
} from 'react-bootstrap';
import Login from './login'
import CalendarInvites from './calendar-invites'

function Account() {
  return (
    <Container>
      <h2>Account</h2>
      <br />
      <Login />
      <CalendarInvites />

    </Container>
  );
}

export default Account;