import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserProvider';
import dayjs from 'dayjs'
import { useUpdateUser } from '../fetch/endpoints';

export const useUpdateUserTimezone = () => {
  const { user } = useContext(UserContext)
  const { performFetch: updateUser } = useUpdateUser()
  const [timezoneUpdated, setTimezoneUpdated] = useState(false);

  const userTimezone = dayjs.tz.guess()
  const userTimezoneOffset = new Date().getTimezoneOffset()

  useEffect(() => {
    const hasFirebaseUser = user && user.created
    const noTimezoneOrShouldUpdateTimezone = user && (!user.timezone || user.timezone !== userTimezone || user.timezoneOffset !== userTimezoneOffset)
    if (userTimezone && hasFirebaseUser && noTimezoneOrShouldUpdateTimezone && !timezoneUpdated) {
      // this is async but doesn't really matter because nothing depends on this resolving
      updateUser({ updateObject: { timezone: userTimezone, timezoneOffset: userTimezoneOffset } })
      setTimezoneUpdated(true)
    }
  }, [user, userTimezone, userTimezoneOffset, timezoneUpdated])

}