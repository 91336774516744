/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { createContext, useContext, useState } from "react";
import { FC_DARK_BLUE, FC_LIGHTER_BLUE, TEXT_60_PERCENT } from '../../emotionVariables';
import { DarkText, DropdownToggleButton, LinkStyledText, LightGrayText, Text, TEXT_STYLES } from '../../components/flowComponents';
import Icon, { TYPE_CHECKMARK } from '../../daily/daily-components/Icon/Icon';
import { useClickOutside } from './useClickOutside';
import { useUrlParamThemeFilters } from './useThemeFilters';
import { SegmentProvider, useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const ThemeFiltersContext = createContext(null)

export const ThemeFilters = ({ themeFilterOptions, activeThemeFilters, setActiveThemeFilters }) =>
  <SegmentProvider eventLabel={'Theme Filters'}>
    <ThemeFiltersContent themeFilterOptions={themeFilterOptions} activeThemeFilters={activeThemeFilters} setActiveThemeFilters={setActiveThemeFilters} />
  </SegmentProvider>

const ThemeFiltersContent = ({ themeFilterOptions, activeThemeFilters, setActiveThemeFilters }) => {

  const activeFiltersList = Object.entries(themeFilterOptions).flatMap(([_header, values]) => values.filter(({ name }) => activeThemeFilters[name]))
  const activeFiltersCount = activeFiltersList.length
  const dropdownName = activeFiltersCount === 0 ?
    'Themes' :
    activeFiltersList.map(({ icon }) => icon).join(' ')

  const urlParamThemeFilter = useUrlParamThemeFilters()
  const urlParamThemeFilterActive = urlParamThemeFilter !== null

  const sendSegmentEvent = useSendSegmentEvent()
  const dropdownOpened = () => {
    sendSegmentEvent('Opened')
  }
  const dropdownClosed = () => {
    sendSegmentEvent('Closed')
  }

  return (
    <ThemeFiltersContext.Provider value={{ themeFilterOptions, activeThemeFilters, setActiveThemeFilters }}>
      <DropdownToggleButton
        label={dropdownName}
        onOpen={dropdownOpened}
        onClose={dropdownClosed}
        initiallyOpened={urlParamThemeFilterActive}
        customCss={activeFiltersCount > 0 && css`
          background-color: ${FC_LIGHTER_BLUE};
          border-color: ${FC_DARK_BLUE};
        `}
        width={350}
        height={400}
      >
        <ThemeFiltersDropdown />
      </DropdownToggleButton>
    </ThemeFiltersContext.Provider>
  )
}

const ThemeFiltersDropdown = ({ openButtonRef, closeDropdown }) => {
  const { themeFilterOptions, activeThemeFilters, setActiveThemeFilters } = useContext(ThemeFiltersContext)

  const anyFiltersActive = Object.values(activeThemeFilters).filter(filterActive => filterActive).length > 0

  const sendSegmentEvent = useSendSegmentEvent()
  const clickedOutside = (_, eventType) => {
    const dropdownWasClosed = closeDropdown()
    if (dropdownWasClosed) {
      if (eventType === 'click') {
        sendSegmentEvent('Dropdown Clicked Outside')
      } else {
        sendSegmentEvent('Escape Key Pressed')
      }
    }
  }

  const clearAllClicked = () => {
    setActiveThemeFilters({})
    sendSegmentEvent('Clear All Clicked')
  }

  const doneClicked = () => {
    closeDropdown()
    sendSegmentEvent('Done Clicked')
  }

  const clickOutsideRef = useClickOutside({ onTriggered: clickedOutside, additionalIgnoredRefs: [openButtonRef] })

  return (<div ref={clickOutsideRef} css={css`
    width: 100%;
    height: 100%;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    display: flex;
    flex-direction: column;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  `}>
    <div css={css`
      padding: 16px 16px 14px 16px;
      border-bottom: 1px solid #D9D9D9;
    `}>
      {Object.entries(themeFilterOptions).map(([header, values], index) =>
       <div key={index} css={index !== 0 && css`margin-top: 8px;`}>
       <Text style={TEXT_STYLES.SUBTITLE_2}>{header}</Text>
        {values.map(({ name, label, field, description }) =>
          <ThemeFilterOption
            name={name}
            label={label}
            field={field}
            description={description}
            selected={activeThemeFilters[name]}
            setSelected={(selected) => setActiveThemeFilters({...activeThemeFilters, [name]: selected })}
            />)}
        </div>
      )}
    </div>
    <div css={css`display: flex; justify-content: flex-end; flex-grow: 1; align-items: center;`}>
      <LinkStyledText onClick={clearAllClicked} customCss={css`
        margin-right: 16px;
        ${!anyFiltersActive && css`
          cursor: initial;
          color: ${TEXT_60_PERCENT};
          user-select: none;
        `}
      `}>Clear All</LinkStyledText>
      <LinkStyledText onClick={doneClicked} customCss={css`margin-right: 16px;`}>Done</LinkStyledText>
    </div>
  </div>)
}

const ThemeFilterOption = ({ name, label, field, description = '', selected = false, setSelected }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const filterToggled = () => {
    setSelected(!selected)
    sendSegmentEvent('Filter Option Toggled', { name, field, selected })
  }

  const selectedAnimation = keyframes({
    'from': css`transform: scale(1.1);`,
    '50%': css`transform: scale(1.25);`,
    'to': css`transform: scale(1.1);`
  })

  const [hovered, setHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={filterToggled}
      css={css`display: flex; align-items: center; cursor: pointer; margin-bottom: 8px;`}
    >
      <div css={css`
        width: 24px;
        height: 24px;
        border: 1px solid ${selected ? FC_DARK_BLUE : 'rgba(0, 0, 0, 0.38)'};
        border-radius: 4px;
        ${selected && css`background-color: ${FC_LIGHTER_BLUE};`}

        transition: transform 0.2s;
        ${hovered && css`
          border-color: ${FC_DARK_BLUE};
          transform: scale(1.1);
        `}

        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 12px;
        margin-bottom: 4px;

        ${selected && css`animation: ${selectedAnimation} 0.3s;`}
      `}>
        <Icon type={TYPE_CHECKMARK} css={css`fill: ${FC_DARK_BLUE}; width: 16px; height: 12px; opacity: ${selected ? '1' : '0'}; ${selected && css`transition: opacity 0.3s;`}`}/>
      </div>
      <div css={css`flex-grow: 1; display: flex; flex-direction: column`}>
      <DarkText>
        {label}
      </DarkText>
      {description && 
        <LightGrayText customCss={css`display: inline;`}>
          {description}
        </LightGrayText>
      }
      </div>
      
    </div>
  )
}