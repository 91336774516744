/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import EventContext from '../../EventContext';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import ShareMusic from './share-music.png'
import { useSetUserPreferences } from '../../fetch/endpoints';
import { usePreferences } from '../../UserProvider';
import { CheckboxButton } from '../../components/flowComponents';

export const ScreensSharedMusicHelp = ({ onHide, updateWrapperDimensions }) => {
  const { callObject, isSharingScreen } = useContext(EventContext)
  const [shareMusicError, setShareMusicError] = useState(null)
  const { performFetch: setUserPreferences } = useSetUserPreferences()
  const { openSpotifyInNewWindow: savedOpenSpotifyInNewWindow }= usePreferences()
  const [ openSpotifyInNewWindow, setOpenSpotifyInNewWindow ] = useState(savedOpenSpotifyInNewWindow ?? false)
  // 

  async function toggleSharingScreen() {
    if (isSharingScreen) {
      await callObject.stopScreenShare();
    } else {
      try {
        await callObject.startScreenShare({
          displayMediaOptions: {
            video: true,
            audio: {
              channels: 2,
              autoGainControl: false,
              echoCancellation: false,
              noiseSuppression: false
            },
            displaySurface: "browser",
            surfaceSwitching: "include",
            selfBrowserSurface: "exclude",
            systemAudio: "exclude",
          }
        })
        setShareMusicError(null)
        onHide()
      } catch (error) {
        setShareMusicError("Sorry, we weren't able to share audio from your selected tab. Make sure you toggle over to \"Chrome Tab\" at the top, check \"Share Audio\" in the bottom left corner, then hit \"Share\"!")
      }
    }
  }

  // 

  const sendSegmentEvent = useSendSegmentEvent()

  const openSpotifyToggled = (selected) => {
    sendSegmentEvent("Open Spotify Toggled", { toggledTo: selected})
    setUserPreferences({ newPreferenceData: { openSpotifyInNewWindow: selected } })
    setOpenSpotifyInNewWindow(selected)
  }

  const shareButtonPressed = async () => {
    sendSegmentEvent("Open Share Dialog Button Clicked", { openSpotifyChecked: openSpotifyInNewWindow })
    if (openSpotifyInNewWindow) {
      window.open('https://open.spotify.com/',
        'musicWindow',
        `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1000,height=800`
      )
    }
    await toggleSharingScreen()
  }

  const closeButtonPressed = () => {
    sendSegmentEvent("Share Music Help Popup Closed")
    onHide()
  }

  return (
    <div className="music-share-container">
      <div className="music-share">
        {shareMusicError !== null &&
          <Alert variant="danger" css={css` color: white;`}>
            {shareMusicError}
          </Alert>
        }
        <MusicDescriptionInfo />
        In the browser menu that appears:
        <ol className="pt-3 px-4">
          <li>Select “Chrome/Brave Tab”</li>
          <li>Select the appropriate tab with music</li>
          <li>Click the “Share Audio” checkbox at the bottom left</li>
          <li>Click the “Share” button</li>
        </ol>
        <img css={css`width: 450px; max-width: 100%; margin: 10px 0px;`} onLoad={updateWrapperDimensions} src={ShareMusic} alt="Make sure to check Share Audio" />
        <br />
        If you run into any issues, make sure that you have:
        <ul>
          <li>an up-to-date version of Google Chrome, Brave, or Microsoft Edge.</li>
          <li>enabled pop-ups/redirects for this domain.</li>
          <li>
            <a href="https://help.daily.co/en/articles/3400532-enable-screen-recording-permission-for-macos-catalina-10-15-and-later#:~:text=If%20you%20do%20not%20see,2.&text=You%20will%20not%20be%20able,quit%20and%20restart%20Google%20Chrome" target="_blank" rel="noreferrer">
              enabled screen recording for Chrome/Brave if you're on MacOS.
            </a>
          </li>
          <li>
            <a href="https://www.loom.com/share/5258007fe53441baaf1625f11fa22872" target="_blank" rel="noreferrer">
              watched this walkthrough of how to share music.
            </a>
          </li>
        </ul>
      </div>
      <div className="music-share-footer p-3">
        <Form>
          <Form.Row>
            <Form.Group as={Col} >
              <CheckboxButton
                selected={openSpotifyInNewWindow}
                setSelected={openSpotifyToggled}
              >Open Spotify in a new window</CheckboxButton>
            </Form.Group>
            <Form.Group as={Col} >
              <Button className="float-right" onClick={shareButtonPressed}>
                Open Share Dialog
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}

const MusicDescriptionInfo = () => {
  const { event } = useContext(EventContext)
  return event.musicDescription !== "" ? (
    <>
      <div css={css`
        margin: 4px 0px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.6);
      `}>
        Music to share
      </div>
      <div css={css`
        padding: 8px 12px;
        margin-bottom: 16px;
        background-color: #EAF3FF;
        border-radius: 4px;
        color: rgba(0, 0, 0, .87);
        line-height: 20px;
        letter-spacing: 0.25px;
        word-wrap: break-word;
      `}>
        🎵 {event.musicDescription}
      </div>
    </>
  ) : null
}