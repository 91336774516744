/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC_GREEN, FC_BLUE, FC_LIGHT_BLUE, FC_DARK_BLUE } from '../../emotionVariables'

const INCOMPLETE_COLOR = FC_GREEN
const COMPLETE_COLOR = FC_DARK_BLUE
const EMPTY_COLOR = FC_LIGHT_BLUE
const BREAK_COLOR = FC_BLUE

export const ProgressCircle = ({ portionFilled = 0, breakTime, children }) => {

  const diameter = 200;
  const ringWidth = 6;
  const circumference = Math.PI * diameter

  return (
    <div css={css`
      width: ${diameter + ringWidth}px;
      height: ${diameter + ringWidth}px;
      display: inline-block;
      align-items: center;
      justify-content: center;
    `}>
      <div css={css`
        width: ${diameter}px;
        height: ${diameter}px;
        position: relative;
      `}>
        <div css={css`
          width: 100%;
          height: 100%;
          
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
          <div css={css`
            font-size: 12px;
            letter-spacing: 0.4px;
            color: #404040;
          `}>
            {children}
          </div>
        </div>
        <svg css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
          overflow: visible;
        `}>
          <circle cx="50%" cy="50%" r="50%" css={css`
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: ${ringWidth};
            stroke-dasharray: ${circumference};
            stroke-dashoffset: ${circumference - (circumference * portionFilled)};
            stroke: ${breakTime ? BREAK_COLOR : portionFilled < 1 ? INCOMPLETE_COLOR : COMPLETE_COLOR};
            stroke-linecap: butt;
          `}/>
        </svg>
        <svg css={css`
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
          overflow: visible;
        `}>
          <circle cx="50%" cy="50%" r="50%" css={css`
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: ${ringWidth};
            stroke-dasharray: ${circumference} ${circumference * portionFilled};
            stroke-dashoffset: ${circumference};
            stroke: ${EMPTY_COLOR};
            stroke-linecap: butt;
          `}/>
        </svg>
      </div>
    </div>
  )
}