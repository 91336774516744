/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { FC_DARK_BLUE } from '../emotionVariables';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { WelcomeImage1 } from './welcome-images'
import { InterstitialContext } from './WelcomeInterstitial';

export const WelcomePane = () => {
  const { guestPass, inviteValidationInvitedBy: invitedBy } = useContext(InviteInfoContext);

  const { largeScreenView } = useContext(InterstitialContext)

  const inviteeName = guestPass ? guestPass.firstName : null
  const inviterName = guestPass ?
    guestPass.invitedByName :
    invitedBy && invitedBy.name ?
      invitedBy.name :
      null
  
  const welcomeText = `${inviteeName !== null ? `Hi ${inviteeName}! ` : ''}${inviterName !== null ? `${inviterName} invited you` : 'Welcome'} to Flow Club, an online co-working community.`

  return (
    <>
      <div css={css`
        font-family: 'Sato';
        font-weight: 500;
        font-size: ${largeScreenView ? '36' : '32'}px;
        line-height: 36px;
        text-align: center;
        color: ${FC_DARK_BLUE};

        margin-bottom: ${largeScreenView ? '54' : '40'}px;
      `}>
        Commit to doing deep work and finding flow
      </div>
      <WelcomeImage1 />
      <div css={css`
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.15px;
        color: ${FC_DARK_BLUE};

        margin-top: ${largeScreenView ? '62' : '24'}px;
      `}>
        {welcomeText}
      </div>
    </>
  )
}