/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { USER_SESSION_STATUS } from '../../functions/shared/constants';
import { CancelModal } from '../shared/components/cancel';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { RescheduleModal } from './modals/RescheduleModal';
import { EventsStateContext } from './Schedule/EventsList';

export const getSecondaryInteractionsAvailable = (userSessionStatus) => [
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION,
  USER_SESSION_STATUS.HOST_FUTURE_SESSION,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.NOT_JOINABLE_BAD_SUBSCRIPTION_STATE,
].includes(userSessionStatus)

export const SecondaryInteractionOptions = ({ event, onActionSuccess = () => {} }) => {
  const { setActiveModal } = useModal()
  const sendSegmentEvent = useSendSegmentEvent()
  const { eventsValidForRescheduling } = useContext(EventsStateContext) || {}

  const cancelClicked = () => {
    setActiveModal(CancelModal, { eventToCancel: event, onSuccess: onActionSuccess })
  }
  const rescheduleClicked = () => {
    setActiveModal(RescheduleModal, { eventToReschedule: event, events: eventsValidForRescheduling, onSuccess: onActionSuccess })
  }

  const CancelLinkContent = (
    <Button variant="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); cancelClicked(); }}>
      Cancel
    </Button>
  )

  const CancelOrRescheduleContent = (
    <div>
      {eventsValidForRescheduling !== undefined &&
        <>
          <Button variant="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); rescheduleClicked(); }}>
            Reschedule
          </Button>{" or "}
        </>
      }
      <Button variant="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); cancelClicked(); }}>
        Cancel
      </Button>
    </div>
  )

  const TrialEndingContent = (
    <div>Starts after your free trial</div>
  )

  switch (event.userSessionStatus) {
    case USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE:
    case USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION:
      return CancelOrRescheduleContent
    case USER_SESSION_STATUS.HOST_FUTURE_SESSION:
    case USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE:
      return CancelLinkContent
    case USER_SESSION_STATUS.NOT_JOINABLE_BAD_SUBSCRIPTION_STATE:
      return TrialEndingContent
    default:
      return null
  }
}