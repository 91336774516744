/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useModal } from '../../wrappers/MagnificentlyMunificentModalManager';
import { LoungeFullscreenModal } from '../LoungeFullscreenModal';
import { PomodoroExplanation } from './PomodoroExplanation';

export const PomodoroBreakIndicator = ({ breakActive, breakTimeRemaining, displayTime }) => {
  const { setActiveModal } = useModal()
  const openPomodoroExplanation = () => {
    setActiveModal(LoungeFullscreenModal, { Contents: PomodoroExplanation })
  }
  
  const textStyle = css`
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    color: black;
  `

  const inactiveStyle = css`
    ${textStyle}
    color: rgba(0, 0, 0, 0.4);
  `

  return (
    <div css={css`
      display: flex;
      justify-content: space-between;
    `}>
      <div css={css`flex: 1; display: flex; justify-content: flex-end;`}>
        <div css={css`${!breakActive ? textStyle : inactiveStyle}; cursor: help; transition: transform 0.1s; &:hover, &:active { transform: scale(1.1); }`} onClick={openPomodoroExplanation}>POMODORO</div>
      </div>
      <div css={css`width: 20px;`}/>
      <div css={css`${breakActive ? textStyle : inactiveStyle}; flex: 1; text-align: start;`}>BREAK{displayTime ? ` ${breakTimeRemaining}` : ''}</div>
    </div>
  )
}