import { useContext } from 'react';
import { SpaceEventTypes } from '../../../functions/shared/constants';
import { useInitiateHighFiveWithUser} from '../../fetch/endpoints';
import { UserContext } from '../../UserProvider';
import { useLoungeOptimisticFetchFunction } from './helpers';
import { v4 as uuidv4 } from 'uuid';

const createFakeHighFiveEvent = (spaceId, recipientId, spaceState, sendingUser) => {
  const recipientUserSpaceVisit = spaceState.currSpaceVisits.find(spaceVisit => spaceVisit.user.id === recipientId)
  const clientSpaceEventId = uuidv4()
  return {
    displayName: `You (${sendingUser.displayName})`,
    eventType: SpaceEventTypes.HIGH_FIVE,
    id: clientSpaceEventId,
    metadata: {
      canHighFive: false,
      otherUsers: [{
        displayName: recipientUserSpaceVisit.displayName,
        id: recipientId
      }],
      respondingHighFiveCompleted: false
    }
  }
}

export const useOptimisticInitiateHighFiveWithUser = () => {
  const { user } = useContext(UserContext)

  const argumentParser = (currentState, { spaceId, recipientId }) => {
    const fakeHighFiveEvent = createFakeHighFiveEvent(spaceId, recipientId, currentState, user)
    return {
      temporary: {
        ...currentState,
        space: {
          ...currentState.space,
          events: [
            fakeHighFiveEvent,
            ...currentState.space.events.slice(0, 3)
          ]
        }
      },
      authoritative: { spaceId, recipientId, clientSpaceEventId: fakeHighFiveEvent.id }
    }
  }
  
  return useLoungeOptimisticFetchFunction(useInitiateHighFiveWithUser, argumentParser)
}