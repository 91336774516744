/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { LoungeContext } from './Lounge';
import FlipMove from 'react-flip-move';
import { useOptimisticHighFiveFromEvent } from './loungeStateManagement/useOptimisticHighFiveFromEvent';
import { UserContext } from '../UserProvider';
import { HighFiveIcon } from './HighFiveIcon';
import { NEUTRAL_40 } from "../emotionVariables";


const HighFiveFromMessageLink = ({ children: linkText }) => {
  return (
    <> <span css={css`font-weight: 700; text-decoration: underline; margin-left: 4px;`}>
      {linkText}
    </span></>
  )
}

const HighFivePrompt = ({ children: linkText, spaceEvent, sendHighFive, canHighFive, highFiveSent }) => {
  const highFiveClicked = () => {
    if (!highFiveSent) {
      sendHighFive(spaceEvent.id)
    }
  }

  const [hovered, setHovered] = useState(false)

  if (!canHighFive && !highFiveSent) {
    return null
  }

  return (
    <span onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={highFiveClicked} css={css`
      ${canHighFive && css`cursor: pointer;`}
      display: inline-flex;
      align-items: center;
    `}>
      {canHighFive && <HighFiveFromMessageLink>{linkText}</HighFiveFromMessageLink>}
      <HighFiveIcon hovered={hovered} highFiveSent={highFiveSent} iconColor="black" customCss={css`margin-left: 8px;`} />
    </span>
  )
}

const EmojiContainer = ({ children }) => {
  return (
    <span css={css`margin-right: 6px`}>
      {children}
    </span>
  )
}

const parseDisplayContentFromSpaceEvent = (spaceEvent, sendHighFive, userExists) => {
  const canHighFive = spaceEvent.metadata.canHighFive && userExists
  const highFiveSent = !spaceEvent.metadata.canHighFive && userExists && spaceEvent.metadata.respondingHighFiveCompleted

  const MainUserName = () => <b>{spaceEvent.displayName}</b>
  const SecondaryUserName = () => <b>{spaceEvent.metadata.otherUsers[0].displayName}</b>

  switch (spaceEvent.eventType) {
    case 'JOINED':
      return <>
      <EmojiContainer>👋</EmojiContainer>
      <MainUserName/> joined the lounge.
      </>
    case 'STARTED_TIMER':
      return <>
      <EmojiContainer>⏰</EmojiContainer>
      <MainUserName/> started a pomodoro.
      </>
    case 'STARTED_BREAK':
      return <>
        <EmojiContainer>🧘</EmojiContainer>
        <MainUserName/> started a break.
      </>
    case 'COMPLETED_GOAL':
      return <>
        <EmojiContainer>✅</EmojiContainer>
        <MainUserName/> just finished a task.
        <HighFivePrompt spaceEvent={spaceEvent} sendHighFive={sendHighFive} canHighFive={canHighFive} highFiveSent={highFiveSent}>Give a high five!</HighFivePrompt>
      </>
    case 'HIGH_FIVE':
      const text = spaceEvent.metadata.respondingHighFiveCompleted ?
        <><MainUserName/> and <SecondaryUserName/> high fived each other!</> :
        <><MainUserName/> high fived <SecondaryUserName/>.</>
      
      return <>
        <EmojiContainer>🖐️</EmojiContainer>
        {text}
        <HighFivePrompt spaceEvent={spaceEvent} sendHighFive={sendHighFive} canHighFive={canHighFive} highFiveSent={highFiveSent}>High five back!</HighFivePrompt>
      </>
    default:
      return null
  }
}

const StatusUpdate = ({ spaceEvent, spaceIndex }) => {
  const { user } = useContext(UserContext)
  const { spaceId } = useContext(LoungeContext)
  const { performFetch: sendHighFive } = useOptimisticHighFiveFromEvent()
  const highFiveClicked = (spaceEventId) => {
    sendHighFive({ spaceId, spaceEventId })
  }

  return (
    <div css={css`
      padding-right: 16px;
      padding-left: 16px;
      opacity: ${spaceIndex === 0 ? 1 : 1-(spaceIndex/5)};
    `}>
      {parseDisplayContentFromSpaceEvent(spaceEvent, highFiveClicked, user !== null)}
    </div>
  )
}

export const StatusUpdates = ({ events = [] }) => {
  events = events.map(event => ({...event, effectiveId: event.clientId ?? event.id}))
  
  return (
    <div css={css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${NEUTRAL_40}
  `}>
      {events.length > 0 &&
        <FlipMove
          typeName={null}
          enterAnimation={{
            from: {
              transform: 'translateY(-100%)',
              opacity: 0,
            },
            to: {
              transform: '',
            },
          }}
          leaveAnimation={{
            from: {
              transform: '',
            },
            to: {
              transform: 'translateY(100%)',
              opacity: 0,
            },
          }}
        >
          {events.map((spaceEvent, index) => (
            <div key={spaceEvent.effectiveId}>
              <StatusUpdate spaceEvent={spaceEvent} spaceIndex={index} />
            </div>
          ))}
        </FlipMove>
      }
    </div>
  )
}