import dayjs from "dayjs"
import { useState } from "react"
import { ONE_MINUTE, TimeFilterOptions } from "../../../functions/shared/constants"
import { useSetUserPreferences } from "../../fetch/endpoints"
import { usePreferences } from "../../UserProvider"
import { useDebounced, useQuery } from "../../utils"

const timeFilterOptions = Object.values(TimeFilterOptions)

export const useUrlParamTimeFilters = () => {
  const query = useQuery()
  const queryTimeFilter = query.get('timeFilter')
  const queryTimeFilterActive = queryTimeFilter !== null && TimeFilterOptions[queryTimeFilter] !== undefined
  return queryTimeFilterActive ? queryTimeFilter : null
}

export const useTimeFilters = (events) => {
  const { sessionTimeFilters: savedFilters } = usePreferences()
  const urlParamTimeFilter = useUrlParamTimeFilters()
  const [activeTimeFilters, setActiveTimeFilters] = useState(
    urlParamTimeFilter !== null ?
      { [urlParamTimeFilter]: true }
      : savedFilters ?? {}
  )
  const { performFetch: setUserPreferences } = useSetUserPreferences()
  const activeTimePeriods = timeFilterOptions.filter(({ name }) => activeTimeFilters[name])
  const eventsWithinActiveTimeFilters = events.filter(event => {
    if (activeTimePeriods.length === 0) {
      return true
    }
    const eventStartMs = (dayjs(event.start).hour() * 60 + dayjs(event.start).minute()) * ONE_MINUTE
    return activeTimePeriods.some(({ start: filterStart, end: filterEnd, nextDayEnd: filterNextDayEnd }) => (eventStartMs >= filterStart && eventStartMs <= filterEnd) || (filterNextDayEnd  !== undefined && eventStartMs <= filterNextDayEnd))
  })

  const [saveSelectedFilters] = useDebounced((sessionTimeFilters) => {
    setUserPreferences({ newPreferenceData: { sessionTimeFilters } })
  }, 1000, true)

  const setNewFilters = newSelectedFilters => {
    setActiveTimeFilters(newSelectedFilters)
    saveSelectedFilters(newSelectedFilters)
  }

  return {eventsWithinActiveTimeFilters, timeFilterOptions, activeTimeFilters, setActiveTimeFilters: setNewFilters}
}