import './plans.scss'

import dayjs from 'dayjs'
import { getImageForHost } from '../../functions/shared/helpers'

export const PlansSessionCard = ({
  user,
  event,
  eventOverride
}) => {
  return (
    <div className="session-card">
        <div className="session-card-time">{ event ? dayjs(event.start).format("h:mma") : eventOverride }</div>
        <img src={getImageForHost(event.hostUser, 'image64')} className="small-club-leader rounded-circle" alt={event.host} />
        <div className="session-card-meta">
            <div className="session-card-title">{ event.title }</div>
            <div className="session-card-host">{ event.host }</div>
            {event.music ?
              <div className="session-card-music">
              {event.musicLink ?
                <a href={event.musicLink} target="_blank" rel="noreferrer">{event.music}</a>
                :
                event.music
              }
              </div> : ""
            } 
        </div>
    </div>
  )
}