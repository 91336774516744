/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useHistory } from "react-router";
import { LinkStyledText } from '../components/flowComponents';
import { auth } from "../firebase";
import { useEffect } from 'react';
import { EntryPage } from '../EntryPage/EntryPage';

export const useLogout = () => {
  const history = useHistory()
  
  return async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userDisplay');
    } catch {}

    history.push("/login")
  }
}

export const LogoutPage = () => {
  const logout = useLogout()
  useEffect(() => {
    logout()
  }, [])
  return (
    <EntryPage>
      <h1>Logging out...</h1>
      <br/>
      <LinkStyledText onClick={logout}>Click here if you are not redirected</LinkStyledText>
    </EntryPage>
  )
}

export const LogoutLink = () => {
  const logout = useLogout()
  return <LinkStyledText onClick={logout}>Logout</LinkStyledText>
}