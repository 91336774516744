/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '../components/flowComponents';

export const NowLine = () => {
  return (
    <div css={css`
      background-color: red;
      height: 1.5px;
      width: 100%;
      position: relative;
    `}>
      <div css={css`
        position: absolute;
        left: 0px;
        top: -20px;
        width: 100%;
        pointer-events: none;

        display: flex;
        justify-content: center;
      `}>
        <Text customCss={css`color: #f00f00a0; font-size: 10px;`}>Now</Text>
      </div>
    </div>
  )
}