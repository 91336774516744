/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BUTTON_STYLES, FlowButton, Text, TEXT_STYLES } from '../flowComponents';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { TooltipWrapper } from '../TooltipWrapper';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const disabledTooltipText = "There's already an open session at this time -- feel free to join it from the schedule page!"

export const QuickCreate = ({ onDemandCreationInfo, selectSessionTime }) => {
  const buttonEnabled = onDemandCreationInfo.createOnDemandSessionButtonEnabled

  const sendSegmentEvent = useSendSegmentEvent()
  const quickCreateClicked = () => {
    sendSegmentEvent("Quick Create Button Clicked")
    selectSessionTime()
  }

  return (
    <div css={css`margin-top: 24px;`}>
      <Text style={TEXT_STYLES.OVERLINE} customCss={css`margin-bottom: 8px;`}>QUICK CREATE</Text>

      <div css={css`width: fit-content;`}>
        <TooltipWrapper TooltipContents={disabledTooltipText} useFloatingArgs={{ placement: 'top', middleware: [shift({ padding: 32 }), offset(12), flip()] }} disabled={buttonEnabled}>
          <FlowButton disabled={!buttonEnabled} onClick={quickCreateClicked} buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>
            Start at {onDemandCreationInfo.creationAllowedAt.format("h:mm a")}
          </FlowButton>
        </TooltipWrapper>
      </div>
    </div>
  )
}