import {
  createContext,
  useState,
} from 'react';

export const LoungeContext = createContext({
  ctaModalOpen: false,
  toggleCtaModalOpen: (val) => {},
});

export const LoungeContextProvider = (props) => {
  
  const [ctaModalOpen, setCtaModalOpen] = useState(false)
  const toggleCtaModalOpen = (newValue = null) => {
    if (newValue !== null) {
      setCtaModalOpen(newValue)
    } else {
      setCtaModalOpen(!ctaModalOpen)
    }
  }

  return (
    <LoungeContext.Provider value={{ctaModalOpen, toggleCtaModalOpen}}>
      {props.children}
    </LoungeContext.Provider>
  )
}
