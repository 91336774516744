/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { DayColumn } from '../CalendarView/DayColumn';
import { useClickOutside } from '../Sessions/Schedule/useClickOutside';
import { Popover, usePopoverData } from '../components/Popover';
import { toastSuccess } from '../components/utils/toaster';
import { CalendarBookingContext } from './CalendarBooking';
import { RecommendationPopoverContent } from './RecommendationPopoverContent';
import { useRecommendations } from './useRecommendations';
import { calculateDayColumnContents } from './calculateDayColumnContents';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import dayjs from 'dayjs';
import { CalendarViewContext } from '../CalendarView/CalendarView';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { RecommendationModal } from './RecommendationModal';
import { DATE_FORMAT } from '../../functions/shared/constants';

const MINIMUM_SPAN_LENGTH = 60
const MINIMUM_SPAN_INCREMENT = 15

export const DayColumnWrapper = ({ date }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { calendarEvents, onRecommendationBooked } = useContext(CalendarBookingContext)
  const { mobileView } = useContext(CalendarViewContext) 

  const title = date.format('dddd MM/DD')


  const [highlightedSlot, setHighlightedSlot] = useState(null)
  const [selectedSpan, setSelectedSpan] = useState({ startMinutes: null, endMinutes: null })
  const spanSelectionFinished = selectedSpan.endMinutes !== null
  const visualHighlightedSlot = !spanSelectionFinished ? highlightedSlot : null
  const visualSelectedSpanEnd = selectedSpan.endMinutes ?? highlightedSlot ?? null

  const effectiveSelectedSpan = {
    startMinutes: Math.min(selectedSpan.startMinutes, visualSelectedSpanEnd),
    endMinutes: Math.max(selectedSpan.startMinutes, visualSelectedSpanEnd)
  }

  const {
    sessionRecommendations,
    gettingSessionRecommendations,
    clearSessionRecommendations
  } = useRecommendations(date, effectiveSelectedSpan, spanSelectionFinished)

  const recommendationsPresent = sessionRecommendations.length > 0

  const [bookedRecommendations, setBookedRecommendations] = useState(new Set())
  const floatingUIData = usePopoverData()
  const { reference: popoverTargetRef } = floatingUIData

  const onBookingSuccess = (bookedRecommendationIndex) => {
      setBookedRecommendations(bookedRecommendations => new Set([...bookedRecommendations, bookedRecommendationIndex]))
      onRecommendationBooked()
      toastSuccess({ message: 'We booked some time for you — check your calendar for an invite!' })
  }


  const eventsOnDay = (calendarEvents ?? [])
    .filter(({ start }) => start.format(DATE_FORMAT)=== date.format(DATE_FORMAT))
    .map(event => ({ ...event, startMinutes: event.start.hour() * 60 + event.start.minute(), endMinutes: event.end.hour() * 60 + event.end.minute() }))


  const onMouseMove = ({ x, y }) => {
    calculateHighlightedSlot(x, y)
  }

  const calculateHighlightedSlot = (x, y) => {
    let closestSlot = findClosestSlot({ y, snapToMinutes: MINIMUM_SPAN_INCREMENT, date })
    if (selectedSpan.startMinutes !== null) {
      closestSlot = Math.max(closestSlot, selectedSpan.startMinutes + MINIMUM_SPAN_LENGTH)
    }
    if (closestSlot !== highlightedSlot) {
      setHighlightedSlot(closestSlot)
    }
    return closestSlot
  }

  const clearHighlightedSlot = () => {
    setHighlightedSlot(null)
  }

  const clearSelectedSpan = () => {
    if (!gettingSessionRecommendations) {
      setSelectedSpan({ startMinutes: null, endMinutes: null })
      clearSessionRecommendations()

      setBookedRecommendations(new Set())

      clearHighlightedSlot()
    }
  }
  
  const popupClosed = () => {
    clearSelectedSpan()
  }

  const onMouseDown = () => {
    if (selectedSpan.startMinutes === null && highlightedSlot !== null) {
      setSelectedSpan(selectedSpan => ({ ...selectedSpan, startMinutes: highlightedSlot }))
      setHighlightedSlot(highlightedSlot + MINIMUM_SPAN_LENGTH)
      sendSegmentEvent("Span Start Point Selected", { startMinutes: highlightedSlot })
    }
  }

  const onMouseUp = () => {
    if (selectedSpan.startMinutes !== null && highlightedSlot !== null && highlightedSlot !== selectedSpan.startMinutes && !spanSelectionFinished) {
      setSelectedSpan(selectedSpan => ({ ...selectedSpan, endMinutes: highlightedSlot }))
      sendSegmentEvent("Span End Point Selected", { ...selectedSpan, endMinutes: highlightedSlot, duration: highlightedSlot - selectedSpan.startMinutes })
    }
  }

  const onClick = () => {
    if (selectedSpan.startMinutes !== null && highlightedSlot !== null && spanSelectionFinished) {
      clearSelectedSpan()
      sendSegmentEvent("Span Deselected", selectedSpan)
    }
  }

  const onTouchStart = ({ x, y }) => {
    const highlightedSlot = calculateHighlightedSlot(x, y)
    setSelectedSpan(selectedSpan => ({ ...selectedSpan, startMinutes: highlightedSlot }))
    sendSegmentEvent("Span Start Point Selected", { startMinutes: highlightedSlot, usingTouchInterface: true })
  }

  const onMouseLeave = () => clearHighlightedSlot()

  const clickOutsideRef = useClickOutside({ onTriggered: clearSelectedSpan })

  const contents = calculateDayColumnContents({
    eventsOnDay,
    selectedSpan,
    effectiveSelectedSpan,
    visualSelectedSpanEnd,
    spanSelectionFinished,
    visualHighlightedSlot,
    sessionRecommendations,
    gettingSessionRecommendations,
    popoverTargetRef,
    bookedRecommendations,
    date
  })

  const { setActiveModal } = useModal()
  useEffect(() => {
    if (mobileView && recommendationsPresent) {
      setActiveModal(RecommendationModal, {
        recommendations: sessionRecommendations,
        onBookingSuccess
      })
    }
  }, [mobileView, recommendationsPresent])
  
  return (
    <div ref={clickOutsideRef}>
      <DayColumn
        title={title}
        contents={contents}
        interactionEvents={{
          onMouseDown,
          onMouseUp,
          onMouseMove,
          onMouseLeave,
          onClick,

          onTouchStart: onTouchStart,
          onTouchEnd: onMouseUp,
          onTouchMove: onMouseMove,
          onTouchCancel: onMouseLeave,
        }}
        customCss={visualHighlightedSlot !== null && css`cursor: pointer;`}
      />
      {recommendationsPresent && !mobileView &&
        <Popover popupWindowCss={css`padding: 24px 56px;`} closePopup={popupClosed} floatingUIData={floatingUIData}>
          <RecommendationPopoverContent recommendations={sessionRecommendations} onBookingSuccess={onBookingSuccess} />
        </Popover>
      }
    </div>
  )
}

const findClosestSlot = ({ y, snapToMinutes, date }) => {
  const now = dayjs()
  if (date.isBefore(now.startOf('day'))) {
    return null
  }
  const nowMinutes = now.hour() * 60 + now.minute()

  const totalSlots = (24 * 60) / snapToMinutes
  const firstAvailableSlot = now.format(DATE_FORMAT) === date.format(DATE_FORMAT) ? Math.ceil(nowMinutes / snapToMinutes) : 0
  const slotInterval = 1 / (totalSlots)

  const closestSlot = Math.min(Math.round(y / slotInterval), totalSlots)
  const clampedClosestSlot = Math.max(firstAvailableSlot, closestSlot)
  const closestSlotMinutes = clampedClosestSlot * snapToMinutes
  return closestSlotMinutes
}