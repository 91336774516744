/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { UserContext } from '../../UserProvider';
import { Link } from "react-router-dom";
import { NEUTRAL_70 } from '../../emotionVariables';
import { useSetUserPreferences, useUpdateUser } from '../../fetch/endpoints';
import { HostIncentiveProgress } from '../../HostIncentiveProgress';
import { usePreferences } from '../../UserProvider';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { LoadingIndicator, Text, TEXT_STYLES, ToggleSwitchButton } from '../flowComponents';
import { toastError, toastSuccess } from '../utils/toaster';


export const HostingSettings = ({ progressData }) => {
  const { user } = useContext(UserContext)
  const { hideInPublicDirectory, useFullNameForHostProfile, displayName, fullName } = user
  const { performFetch: setUserPreferences, fetching: setUserPreferencesLoading } = useSetUserPreferences()
  const { performFetch: updateUser, fetching: updatingUser } = useUpdateUser()

  const { hideHostFollowButton } = usePreferences()
  const [hostFollowingEnabled, setHostFollowingEnabled] = useState(!hideHostFollowButton)
  const [hostInPublicDirectoryEnabled, setHostInPublicDirectoryEnabled] = useState(!hideInPublicDirectory)
  const [hostProfileUsesFullName, setHostProfileUsesFullName] = useState(useFullNameForHostProfile)

  const sendSegmentEvent = useSendSegmentEvent()

  const hostFollowingToggled = async (togglingToActive) => {
    sendSegmentEvent("Host Following Setting Toggled", { togglingToActive })
    setHostFollowingEnabled(togglingToActive)
    const { success, error } = await setUserPreferences({ newPreferenceData: { hideHostFollowButton: !togglingToActive } })
    if (success) {
      toastSuccess({ message: `Host following ${togglingToActive ? 'enabled' : 'disabled'}.` })
    } else {
      toastError({ message: error })
    }
  }

  const hostInPublicDirectoryToggled = async (togglingToActive) => {
    sendSegmentEvent("Host In Public Directory Setting Toggled", { togglingToActive })
    setHostInPublicDirectoryEnabled(togglingToActive)
    const { success, error } = await updateUser({ updateObject: { hideInPublicDirectory: !togglingToActive } })
    if (success) {
      toastSuccess({ message: togglingToActive ? 'Your host profile will now appear in the public directory.' : 'Your host profile will no longer appear in the public directory.' })
    } else {
      toastError({ message: error })
    }
  }

  const hostProfileUsesFullNameToggled = async (togglingToActive) => {
    sendSegmentEvent("Host Profile Uses Full Name Setting Toggled", { togglingToActive })
    setHostProfileUsesFullName(togglingToActive)
    const { success, error } = await updateUser({ updateObject: { useFullNameForHostProfile: togglingToActive } })
    if (success) {
      toastSuccess({ message: togglingToActive ? 'Your host profile will now display your full name.' : 'Your host profile will no longer display your full name.' })
    } else {
      toastError({ message: error })
    }
  }

  return (
    <div>
      <Text style={TEXT_STYLES.APP_H1} customCss={css`margin-bottom: 16px;`}>Hosting</Text>
      <Text style={TEXT_STYLES.BODY_1} customCss={css`margin-bottom: 48px;`}>View settings related to being a host on Flow Club below.</Text>
      {progressData.completedSessions === null ?
        <LoadingIndicator /> :
        <div css={css`margin-bottom: 24px;`}>
          <HostIncentiveProgress progressData={progressData} />
        </div>
      }
      <Text style={TEXT_STYLES.APP_H4} customCss={css`margin-bottom: 24px;`}>Host Profile</Text>
      <div css={css`margin: 12px 0px;`}>
        <Link to="/host/">See and edit your host profile</Link>
      </div>
      <div>
        <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
          <Text>Enable favorite button</Text>
          <ToggleSwitchButton selected={hostFollowingEnabled} setSelected={hostFollowingToggled} loading={setUserPreferencesLoading} />
        </div>
        <Text customCss={css`color: ${NEUTRAL_70};`}>If enabled, other users will be able to add you as a favorite host to more easily find and be notified of your sessions.</Text>
      </div>
      <div css={css`margin: 12px 0px;`}>
        <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
          <Text>Appear in public host directory</Text>
          <ToggleSwitchButton selected={hostInPublicDirectoryEnabled} setSelected={hostInPublicDirectoryToggled} loading={updatingUser} />
        </div>
        <Text customCss={css`color: ${NEUTRAL_70};`}>If enabled, your profile will appear in a list of all active Flow Club hosts in the <Link to="/hosts/">public host directory.</Link></Text>
      </div>
      <div css={css`margin: 12px 0px;`}>
        <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
          <Text>Use full name in host profile</Text>
          <ToggleSwitchButton selected={hostProfileUsesFullName} setSelected={hostProfileUsesFullNameToggled} loading={updatingUser} />
        </div>
        <Text customCss={css`color: ${NEUTRAL_70};`}>Enable this to show your full name ({fullName}) instead of your display name ({displayName}), on your host profile, making it easier for others to find you on the internet. You are credited for <Link to="/settings/invite">referrals</Link> for anyone who joins Flow Club through your host profile.</Text>
      </div>
    </div>
  )
}