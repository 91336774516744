import { useEffect, useRef, useState } from "react";

const alertParticipant = e => {
  e.preventDefault()
  // This gets ignored by most browsers in favor of the default message, 
  // but might as well customize it for the ones that'll show it!
  return e.returnValue = 'Are you sure to want to break the flow?';
}

const getCumulativeDurations = (stages) => {
  let totalDuration = 0
  return stages.map(stage => {
    totalDuration += stage.duration
    return totalDuration
  })
}

export const useSessionTimer = ({ startTime, stages, active }) => {

  const [timeElapsed, setTimeElapsed] = useState(Date.now() - startTime.getTime())
  const minutesElapsed = timeElapsed / (1000 * 60)
  const cumulativeStageDurations = getCumulativeDurations(stages)
  const totalDuration = cumulativeStageDurations[cumulativeStageDurations.length - 1]
  let currentStage = cumulativeStageDurations.findIndex(duration => duration > minutesElapsed)
  if (currentStage === -1) { currentStage = stages.length - 1 }

  const minutesElapsedThisStage = minutesElapsed - (currentStage === 0 ? 0 : cumulativeStageDurations[currentStage - 1])

  const sessionStarted = timeElapsed >= 0
  const sessionFinished = minutesElapsed >= totalDuration
  const activeWorkingTime = stages[currentStage].activeWorkingTime
  const nextStageIsActiveWorkingTime = stages[currentStage + 1] !== undefined && stages[currentStage + 1].activeWorkingTime
  const sessionWorkingTimeFinished = sessionFinished || stages.slice(currentStage).every(stage => !stage.activeWorkingTime)
  const sessionWorkingTimeStarted = currentStage >= stages.findIndex(stage => stage.activeWorkingTime)

  const timerInterval = useRef(null)
  const removeBeforeUnload = () => {
    window.removeEventListener('beforeunload', alertParticipant)
  }
  useEffect(() => {
    if (active && !sessionFinished) {
      timerInterval.current = setInterval(() => {
        setTimeElapsed(Date.now() - startTime.getTime())
      }, 1000)
      return () => clearInterval(timerInterval.current)
    } else {
      clearInterval(timerInterval.current)
    }
  }, [startTime, active, sessionFinished])

  useEffect(() => {
    window.addEventListener('beforeunload', alertParticipant)
    return removeBeforeUnload
  }, [])
  useEffect(() => {
    if (sessionWorkingTimeFinished) {
      removeBeforeUnload()
    }
  }, [sessionWorkingTimeFinished])

  return {
    minutesElapsedThisStage,
    activeWorkingTime,
    nextStageIsActiveWorkingTime,
    sessionWorkingTimeStarted,
    sessionStarted,
    sessionWorkingTimeFinished,
    sessionFinished,
    timeElapsed,
    cumulativeStageDurations,
    currentStage,
    removeBeforeUnload,
  }
}