/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { LoginSignup } from '../auth/LoginSignup';
import { EntryPage, LinkToLogin } from './EntryPage';

export const SignUp = () => {
  const bottomContent = (
    <div css={css`margin-top: 40px;`}>
      <LinkToLogin/>
    </div>
  )
  
  return (
    <EntryPage bottomContent={bottomContent}>
      <LoginSignup onSuccess={() => { }} showLoginModeLink={false} startInLoginMode={false} showBookingRelatedText={false} />
    </EntryPage>
  )
}