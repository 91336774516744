/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { baseURL } from '../../firebase';
import { FC_LIGHTER_BLUE } from '../../emotionVariables';
import { FlowClubLogo } from '../../components/FlowClubLogo';
import { LoadingIndicator, Text, TEXT_STYLES } from '../../components/flowComponents';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useUpdatePhoneFreeParticipant } from '../../fetch/endpoints';
import { PhoneFreeTimer } from './PhoneFreeTimer';
import { useSessionTimer } from '../useSessionTimer';
import EventContext from '../../EventContext';

import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

const SessionTimerWrapper = ({ event, participant, startTimer, endTimer, onHeartbeat }) => {
  const { start, agenda: stages, end } = event
  const { eventId } = participant
  const url = `${baseURL}/dnd/${eventId}`
  const { goals } = participant ?? { goals: [] }
  const currentGoal = goals.find(goal => goal.completedAt === null)
  const startTime = new Date(start)
  const sessionTimerData = useSessionTimer({
    startTime,
    stages,
    active: true,
  })

  return (
    <EventContext.Provider value={{
      event,
    }}>
      <PhoneFreeTimer onTimerStart={startTimer} onTimerEnd={endTimer} onHeartbeat={onHeartbeat} goal={currentGoal ? currentGoal.text : null} sessionTimerData={sessionTimerData} stages={stages} url={url} endTime={end} />
    </EventContext.Provider>
  )
};


export const PhoneFreeParticipantSession = () => {
  const { dndSessionId: participantId } = useParams();
  const [ participant, setParticipant ] = useState(null)
  const [ event, setEvent ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: updatePhoneFreeParticipant } = useUpdatePhoneFreeParticipant()

  useEffect(() => {
    const openPhoneFreeParticipant = async () => {
      setLoading(true)
      const { result, error } = await updatePhoneFreeParticipant({ participantId, updateObject: {phoneFreeOpened: true } })
      if (error) {
        setLoading(false)
        return
      }
      const { event, participant } = result
      setParticipant(participant)
      setEvent(event)
      const { eventId } = participant
      sendSegmentEvent("Phone Free Session Opened", { eventId })
      setLoading(false)
    }
    openPhoneFreeParticipant()
  }, [])

  const onHeartbeat = async () => {
    const { result } = await updatePhoneFreeParticipant({ participantId, updateObject: {phoneFreeLastActive: true } })
    const { participant } = result
    setParticipant(participant)
  }

  const startTimer = async () => {
    const { result } = await updatePhoneFreeParticipant({ participantId, updateObject: {phoneFreeStarted: true, phoneFreeLastActive: true } })
    const { event, participant } = result
    setParticipant(participant)
    setEvent(event)
    const { eventId } = participant
    sendSegmentEvent("Phone Free Session Started", { eventId })
  }

  const endTimer = async (finalElapsedTime) => {
    const { result } = await updatePhoneFreeParticipant({ participantId, updateObject: {phoneFreeEnded: true, phoneFreeElapsedTime: finalElapsedTime } })
    const { event, participant } = result
    setParticipant(participant)
    setEvent(event)
    const { eventId } = participant
    sendSegmentEvent("Phone Free Session Ended", { eventId, finalElapsedTime })
  }
  return (
    <div css={css`
      min-height: 100vh;
      width: 100vw;
      padding: 0px 10px;
      
      background: ${FC_LIGHTER_BLUE};
      font-size: 16px;
      line-height: 24px;
      font-family: "Red Hat Display", sans-serif;
      display: flex;
      text-align: center;
      flex-direction: column;
      overflow: hidden;
    `}>
      <FlowClubLogo customCss={css`height: 16px;`}/>
      <div css={css`
        margin-top: 5vh;
      `}>
        {loading ?
        <div>
          <Text style={TEXT_STYLES.APP_H2}>Loading...</Text><br/>
          <LoadingIndicator />
        </div>:
        <div>
        { participant && <span>👋 Welcome, { participant.name }</span>}
        { event && <Text style={TEXT_STYLES.APP_H4}>{ event.title } w/ {event.hostUser.displayName}</Text>}
        { event ?
          <SessionTimerWrapper event={event} participant={participant} startTimer={startTimer} endTimer={endTimer} onHeartbeat={onHeartbeat} />
          : 
          <div>
            <Text style={TEXT_STYLES.APP_H2}>Sorry</Text>
            <Text style={TEXT_STYLES.SUBTITLE_1}>That doesn't seem to be an in-progress phone-free session.</Text><br />
            Try scanning the QR code again, or<br/>
            <a href="https://www.phonefreehour.com?utm_source=flowclub&utm_medium=web&utm_campaign=phone-free-session">use the standalone www.phonefreehour.com</a>
          </div>
        }
        </div>}
      </div>
    </div>
  );
}

