/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_DARK_BLUE } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext, } from 'react';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import UpdateProfile from '../components/settings/update-profile/update-profile';

export const UpdateProfilePane = () => {
  const { largeScreenView, goToNextPane } = useContext(InterstitialContext)

  const sendSegmentEvent = useSendSegmentEvent()

  const onUpdateProfile = async () => {
    sendSegmentEvent("Updated Profile During Onboarding")
    goToNextPane()
  }
  return (
    <div css={css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    `}>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? '54' : '40'}px;
        text-align: center;
      `}>Update your profile</h2>
      <div css={css`letter-spacing: 0.15px; text-align: left; color: ${FC_DARK_BLUE}; margin-bottom: 40px;`}>
        We recommend at least setting your preferred display name and location. You can always update your profile under settings later.
      </div>
      <UpdateProfile onSuccess={onUpdateProfile} showHeader={false} />
    </div>
  )
}