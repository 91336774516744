/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef } from 'react';
import * as Sentry from "@sentry/react";
import { pick } from 'lodash';

export const FlowClubRadioPlayer = ({ hostedMusicPlaybackData, volume = 100 }) => {

  const playlistAudioFiles = hostedMusicPlaybackData.playlist.tracks.map(track => `${hostedMusicPlaybackData.playlist.name}/${track}`)

  const audioMetadata = useRef(playlistAudioFiles.map(() => ({ ref: null, duration: null, cumulativeDuration: null })))

  useEffect(() => {
    audioMetadata.current.forEach(({ ref }) => ref.volume = volume / 100)
  }, [audioMetadata, volume])

  const getHasAllSongMetadataLoaded = () => audioMetadata.current.every(({ ref, duration }) => duration !== null && ref !== null)

  const setPlayingAudioState = async () => {
    if (!getHasAllSongMetadataLoaded()) {
      return
    }

    const secondsSincePlaybackStarted = (Date.now() - hostedMusicPlaybackData.playbackStartedAt) / 1000
    const totalDurationOfPlaylist = audioMetadata.current[audioMetadata.current.length - 1].cumulativeDuration
    const playbackPointInPlaylist = ((hostedMusicPlaybackData.playbackPoint / 1000) + secondsSincePlaybackStarted) % totalDurationOfPlaylist

    let audioIndexToPlay = 0
    for (let i = 0; i < playlistAudioFiles.length; i++) {
      if (audioMetadata.current[i].cumulativeDuration < playbackPointInPlaylist) {
        audioIndexToPlay = i + 1
      }
    }
    if (audioIndexToPlay >= playlistAudioFiles.length) {
      console.error("idk, something broke in this case I think. can't play a song after the end of the playlist")
      Sentry.captureException(new Error(
        "Failed to set audio playing state in <FlowClubRadioPlayer>"),
        {
          extra: {
            audioIndexToPlay,
            audioMetadata: JSON.stringify(audioMetadata.map(metadata => pick(metadata, ['duration, cumulativeDuration']))),
            hostedMusicPlaybackData: JSON.stringify(hostedMusicPlaybackData)
          }
        }
      )
      return
    }

    const audioToPlay = audioMetadata.current[audioIndexToPlay]
    audioToPlay.ref.currentTime = playbackPointInPlaylist - (audioToPlay.cumulativeDuration - audioToPlay.duration)
    await audioToPlay.ref.play()
    // pause other audio tags
    await Promise.all(audioMetadata.current.map(async ({ ref }, index) => {
      if (index !== audioIndexToPlay) {
        await ref.pause()
      }
    }))
  }

  useEffect(() => setPlayingAudioState(), [hostedMusicPlaybackData.playbackStartedAt, hostedMusicPlaybackData.playbackPoint])

  if (hostedMusicPlaybackData === null || hostedMusicPlaybackData.playbackStartedAt === null) {
    return null
  }

  const calcCumulativePlaylistDurations = () => {
    const cumulativeDurationsAlreadyCalculated = audioMetadata.current.some(({ cumulativeDuration }) => cumulativeDuration !== null)
    if (!getHasAllSongMetadataLoaded() || cumulativeDurationsAlreadyCalculated) {
      return
    }

    let cumulativeDuration = 0
    audioMetadata.current = audioMetadata.current.map(metadata => {
      cumulativeDuration += metadata.duration
      return { ...metadata, cumulativeDuration }
    })

    setPlayingAudioState()
  }

  const songDurationFound = (index, duration) => {
    audioMetadata.current[index].duration = duration
    calcCumulativePlaylistDurations()
  }

  const audioMounted = (ref, index) => {
    audioMetadata.current[index].ref = ref
    calcCumulativePlaylistDurations()
  }

  return (
    <>
      {playlistAudioFiles.map((filename, index) =>
        <audio
          ref={ref => audioMounted(ref, index)}
          key={filename}
          onLoadedMetadata={event => songDurationFound(index, event.target.duration)}
          controls={false}
          autoPlay={false}
          onPlay={() => { }}
          onEnded={setPlayingAudioState}
          css={css`
            margin: 10px;
          `}
        >
          <source src={`https://storage.googleapis.com/flowclub-static/music/${filename}`} type={"audio/mpeg"} />
          Your browser does not support the audio element.
        </audio>

      )}
    </>
  )
}