/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import {
  Alert,
  Container,
  Nav,
} from 'react-bootstrap';
import {
  Link,
} from 'react-router-dom';
import { UserContext } from '../../UserProvider';
import { toTitleCase } from '../../../functions/shared/helpers';
import { DateShortcuts } from '../DateShortcuts';
import { TimeFilters } from './TimeFilters';
import { HostFilters } from './HostFilters';
import { ThemeFilters } from './ThemeFilters';
import { CheckboxButton, LinkStyledText, Text } from '../../components/flowComponents';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import Icon, { TYPE_FILTER } from '../../daily/daily-components/Icon/Icon';
import { FC_DARK_BLUE } from '../../emotionVariables';

export const DatesAndHosts = ({
  daysWithSessions,
  groupFilter,
  invitedGroupFilter,
  privateGroups,
  mainLink,
  selectedHost,
  hostsOfUpcomingSessions,
  sessionDayVisibilities,
  timeFilterOptions,
  activeTimeFilters,
  setActiveTimeFilters,
  themeFilterOptions,
  activeThemeFilters,
  setActiveThemeFilters,
  activeDurationFilters,
  setActiveDurationFilters,
  showHiddenSessions,
  setShowHiddenSessions,
  hiddenEventsCount = 0,
  loading = false,
  collapseFilters = false,
  setCollapseFilters = () => { },
}) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { user } = useContext(UserContext)
  const onClearAllClicked = () => {
    sendSegmentEvent('Clear all filters clicked')
    setActiveTimeFilters({})
    setActiveThemeFilters({})
    setActiveDurationFilters({})
  }

  const toggleFilters = () => {
    setCollapseFilters(!collapseFilters);
  };

  return (
    <Container>
      <div css={css`display: flex; flex-direction: row; justify-content: center; gap: 8px; align-items: center;`}>
        <div css={css`
          flex: 1;
          overflow: hidden;
          max-width: calc(100% - 48px);
        `}>
          <DateShortcuts days={daysWithSessions} sessionDayVisibilities={sessionDayVisibilities} loading={loading} />
        </div>
        <div css={css`cursor: pointer; padding: 8px; border: 1px solid ${FC_DARK_BLUE}; border-radius: 8px; background: ${collapseFilters ? 'white' : FC_DARK_BLUE}`} onClick={toggleFilters}>
          <Icon type={TYPE_FILTER} css={css`width: 20px; height: 20px;`} fill={collapseFilters ? FC_DARK_BLUE : 'white'} />
        </div>
      </div>

      {!collapseFilters && <hr />}
      <div className="d-flex justify-content-center light">
        <Nav className="schedule-nav" variant="pills">
          <div>
            {(groupFilter || invitedGroupFilter || privateGroups.length > 0) &&
              <GroupFilters mainLink={mainLink} groupFilter={groupFilter} invitedGroupFilter={invitedGroupFilter} privateGroups={privateGroups} />
            }
            <div css={css`display: flex; justify-content: center; gap: 12px; align-items: center;`}>
              {collapseFilters && hiddenEventsCount > 0 &&
                <Text>Not showing {hiddenEventsCount} {hiddenEventsCount === 1 ? "session" : "sessions"} because of filters
                <LinkStyledText
                  onClick={onClearAllClicked}
                  customCss={css`
                    margin-left: 8px;
                  `}
                >
                  Clear all
                </LinkStyledText></Text>}
              {!collapseFilters &&
              <>
              <TimeFilters
                timeFilterOptions={timeFilterOptions}
                activeTimeFilters={activeTimeFilters}
                setActiveTimeFilters={setActiveTimeFilters}
                activeDurationFilters={activeDurationFilters}
                setActiveDurationFilters={setActiveDurationFilters}
              />
              <ThemeFilters
                themeFilterOptions={themeFilterOptions}
                activeThemeFilters={activeThemeFilters}
                setActiveThemeFilters={setActiveThemeFilters}
              />
              <HostFilters
                filterOptions={hostsOfUpcomingSessions}
                activeFilters={{ [selectedHost]: true }}
              />
              </>
              }
            </div>
          </div>
        </Nav>

      </div>
      {!collapseFilters && (user !== null || hiddenEventsCount > 0) && <div css={css`display: flex; justify-content: center; margin-top: 8px;`}>
        <Alert variant="info">
        {hiddenEventsCount > 0 &&
        <p>
          Not showing {hiddenEventsCount} {hiddenEventsCount === 1 ? "session" : "sessions"} because of filters
          <LinkStyledText
            onClick={onClearAllClicked}
            customCss={css`
              margin-left: 8px;
            `}
          >
            Clear all
          </LinkStyledText>
          </p>}
          {user !== null && <CheckboxButton selected={showHiddenSessions} setSelected={setShowHiddenSessions}>Show In-progress & full sessions</CheckboxButton>}
        </Alert>
      </div>}
    </Container>
  )
}

const GroupFilters = ({ mainLink, groupFilter, invitedGroupFilter, privateGroups }) => {
  return (
    <div css={css`display: flex; justify-content: center; flex-wrap: wrap; gap: 8px; margin-bottom: 8px`}>
      {<Nav.Item as="li" key="nav-group-all" className="rounded">
        <Nav.Link as={Link} to={`${mainLink}?group=all`} className={`${groupFilter === null || groupFilter === 'all' ? "active" : ""} date-pills`}>
          All Sessions
        </Nav.Link>
      </Nav.Item>}
      {invitedGroupFilter ?
        <Nav.Item as="li" key={`nav-group-${invitedGroupFilter}`} className="rounded">
          <Nav.Link css={css`white-space: nowrap;`} as={Link} to={`${mainLink}?group=${invitedGroupFilter}`} className={`${groupFilter === invitedGroupFilter ? "active" : ""} date-pills`}>
            {toTitleCase(invitedGroupFilter)}-Only
          </Nav.Link>
        </Nav.Item>
        :
        privateGroups.map((group, index) => (
          <Nav.Item as="li" key={`nav-host-${group}`} className="rounded">
            <Nav.Link css={css`white-space: nowrap;`} as={Link} to={`${mainLink}?group=${group}`} className={`${groupFilter === group ? "active" : ""} date-pills`}>
              {toTitleCase(group)}-Only
            </Nav.Link>
          </Nav.Item>
        ))
      }
    </div>
  )
}