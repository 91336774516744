/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createContext, useContext, useEffect, useState } from "react"
import { useLocation } from 'react-router';

const ModalContext = createContext(null)
export const MagnificentlyMunificentModalManager = ({ children }) => {
  const defaultState = { ModalComponent: null, props: null, modalSetFrom: null }
  const [activeModal, setActiveModal] = useState({ ...defaultState })

  const { ModalComponent, props } = activeModal

  const clearModal = () => setActiveModal({ ...defaultState })

  const location = useLocation();
  useEffect(() => {
    const retainModals = location.state !== undefined && location.state.retainModals
    if (activeModal.ModalComponent !== null && activeModal.modalSetFrom !== location.key && !retainModals) {
      clearModal()
    }
  }, [location]);

  return (
    <ModalContext.Provider value={
      {
        setActiveModal: (ModalComponent, props = {}) =>
          setActiveModal({ ModalComponent, props, modalSetFrom: location.key }),
        clearModal
      }
    }>
      <div css={css`isolation: isolate;`}>
        {children}
      </div>
      {ModalComponent !== null &&
        <ModalComponent
          closeFn={clearModal}
          {...props}
          onHide={() => {
            if (props.onHide !== undefined) { props.onHide() }
            clearModal()
          }}
        />
      }
    </ModalContext.Provider>
  )
}
/**
 * @returns {{setActiveModal: function}}
 */
export const useModal = () => useContext(ModalContext)