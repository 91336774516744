/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { 
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap'

import './JoinScreen.scss'
import Tile from '../Tile/Tile'

import { UserContext, useGetDisplayName } from '../../../UserProvider'
import EventContext from '../../../EventContext'
import { EditIcon } from '../../../event-utils'
import { V2DeviceSelector } from '../device-selector/device-selector'
import { useBookSession, useJoinSession, useGetMostRecentParticipantGoalsForUser } from '../../../fetch/endpoints'
import { useAutomaticallyFetch } from '../../../fetch/helpers'
import { useModal } from '../../../wrappers/MagnificentlyMunificentModalManager'
import { DefaultBanner } from '../../../components/banners'
import { FC_DARK_BLUE, NEUTRAL_60 } from '../../../emotionVariables'
import { UpdateNameModal } from '../../../Sessions/modals/UpdateNameModal'
import { makeNewGoalObject, useGoals } from '../../../components/GoalList/useGoals'
import { SegmentProvider, useSendSegmentEvent } from '../../../wrappers/SegmentProvider'
import { useMediaQuery } from 'react-responsive'
import { Card, FlowButton, LoadingIndicator, Text, TEXT_STYLES } from '../../../components/flowComponents'
import Timer from '../../../ParticipantApp/Timer'
import { JoinScreenAgendaOverview } from '../../../ParticipantApp/JoinScreenAgendaOverview'
import { useParticipant, useParticipantIds } from '@daily-co/daily-react'
import { toastError } from '../../../components/utils/toaster'
import * as Sentry from "@sentry/react";
import { GoalListWithCopyPaste } from '../../../components/GoalList/GoalListWithCopyPaste'
import { InviteInfoContext } from '../../../InviteInfoWrapper'
import { DoNotDisturb } from '../../../ParticipantApp/DoNotDisturb/DoNotDisturb'

// just inspiringly elegant naming as always
const useJoinAndBookIfNeeded = () => {
  const { performFetch: joinSession, fetching: updatingGoal, error: joinSessionError } = useJoinSession()
  const { performFetch: bookSession, fetching: bookingSession, error: bookSessionError } = useBookSession()

  const joinAndBookIfNeeded = async ({ eventId, goals, displayName, participant }) => {
    if (participant === null) {
      const { success, error } = await bookSession({ eventId })
      if (!success) {
        toastError({ message: error })
        return
      }
    }

    return await joinSession({ eventId, goals, displayName })
  }

  return {
    performFetch: joinAndBookIfNeeded,
    fetching: updatingGoal || bookingSession,
    error: joinSessionError ?? bookSessionError
  }
}

const useJoinScreenFunctionality = () => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext);
  const userDisplayName = useGetDisplayName(user)
  const { event, startEnteringCall: enterInSessionView, dailyMeetingToken, callObject, roomUrl, activeUserParticipant: participant, hidingSelfView, mirrorSelfView } = useContext(EventContext);

  const [startCameraLoading, setStartCameraLoading] = useState(true);

  const { performFetch: joinSession, fetching: updatingGoal, error: joinSessionError } = useJoinAndBookIfNeeded()
  const existingGoals = participant !== null ?
    participant.goals ??  [makeNewGoalObject([])] :
    [makeNewGoalObject([])]
  const goalsState = useState(existingGoals)
  const [goals, setGoals] = goalsState
  const [waitingToJoinSession, setWaitingToJoinSession] = useState(false)

  const [dailyParticipantId] = useParticipantIds({ filter: ({ user_id }) => user_id === user.uid });
  const localDailyParticipant = useParticipant(dailyParticipantId)

  useEffect(() => {
    if (
      waitingToJoinSession &&
      goals.length === existingGoals.length &&
      goals.every(({ completedAt, text }, index) => existingGoals[index].completedAt === completedAt && existingGoals[index].text === text) &&
      participant !== null &&
      participant.joined !== undefined
    ) {
      enterInSessionView()
    }
  }, [waitingToJoinSession, goals, existingGoals, participant])

  const enterSessionButtonClicked = async () => {
    sendSegmentEvent("Enter Session Button Clicked", { hadParticipant: participant !== null })
    if (event) {
      const { success: successfullyJoined } = await joinSession({ eventId: event.id, goals, displayName: userDisplayName, participant })
      if (successfullyJoined) {
        setWaitingToJoinSession(true)
      }
    }
  };

  const startCamera = useCallback(async () => {
    if (!callObject || !dailyMeetingToken || !roomUrl) return;

    const { access } = callObject.accessState();
    if (access === 'unknown') {
      try {
        const preAuthResult = await callObject.preAuth({ url: roomUrl, token: dailyMeetingToken })
        const startCameraResult = await callObject.startCamera()
        setStartCameraLoading(false);
      } catch (error) {
        console.error(`Daily preAuth call failed. url: ${roomUrl}, token: ${dailyMeetingToken}`)
        console.error(error)
        Sentry.captureException(error, {
          extra: {
            roomUrl,
            dailyMeetingToken
          }
        });
      }
    }
  }, [callObject, roomUrl, dailyMeetingToken])

  useEffect(() => {
    startCamera();
  }, [startCamera])

  return {
    waitingToJoinSession,
    goalsState,
    updatingGoal,
    joinSessionError,
    startCameraLoading,
    localDailyParticipant,
    callObject,
    enterSessionButtonClicked,
    event,
    participant,
    hidingSelfView,
    mirrorSelfView,
  }
}

export default function JoinScreen({ sessionTimerData, timerText, timerTextColor, stages, sessionName }) {
  const { sessionStarted, sessionFinished, currentStage, nextStageIsActiveWorkingTime, sessionWorkingTimeStarted } = sessionTimerData
  const { user } = useContext(UserContext);
  const userDisplayName = useGetDisplayName(user)

  const {
    waitingToJoinSession,
    goalsState,
    updatingGoal,
    joinSessionError,
    startCameraLoading,
    localDailyParticipant,
    callObject,
    enterSessionButtonClicked,
    event,
    participant,
    hidingSelfView,
    mirrorSelfView,
  } = useJoinScreenFunctionality()

  const { setActiveModal } = useModal()
  const showUpdateNameModal = () => {
    setActiveModal(UpdateNameModal, { title: "Update your info" })
  }

  // not even going to pretend this is really meaningful, just hacking around bootstrap breakpoints
  const somewhatSquishedWidth = useMediaQuery({query: '(max-width: 1199px)'})

  const mobileView = useMediaQuery({query: '(max-width: 992px)'})


  const EarlyJoinMessage = () => (
    <span>
      Take a minute to check your mic/camera, fill out your profile, and your goal for the session!
      {mobileView ? ' Join from a computer for optimal experience.' : ''}
    </span>
  )
  const LateJoinMessage = () => (
    <span>
      Session is in-progress. Follow these tips when joining: 1) Say hi in the chat, 2) work until the end to get celebrated{mobileView ? ', 3) Join from a computer for optimal experience' : ''}!
    </span>
  )

  return (
    <div css={css`
      padding: 10px;
      width: 100%;
      min-height: 100vh;
      display: flex;
      font-size: calc(10px + 1.5vmin);
      color: white;
    `}>
      <Container>
        <div css={css`
          display: flex;
          width: 100%;
          color: initial;
          margin-bottom: 20px;
        `}>
          <Card customCss={css`
            flex: 1;
            ${!mobileView && css`margin-right: 20px;`}
          `}>
            <Timer
              sessionStarted={sessionStarted}
              sessionFinished={sessionFinished}
              nextStageIsActiveWorkingTime={nextStageIsActiveWorkingTime}
              text={timerText}
              textColor={timerTextColor}
            />
            {mobileView && <JoinScreenAgendaOverview stages={stages} currentStage={currentStage} sessionName={sessionName} />}
          </Card>
          {!mobileView &&
            <Card customCss={css`flex: 2; display: flex;`}>
              <JoinScreenAgendaOverview stages={stages} currentStage={currentStage} sessionName={sessionName} />
            </Card>
          }
        </div>
        {user.sessions === 0 &&
          <DefaultBanner>
            <div css={css`
              color: ${FC_DARK_BLUE};
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.5px;
            `}>
            {sessionWorkingTimeStarted ?
              <LateJoinMessage /> :
              <EarlyJoinMessage />
            }
            </div>
          </DefaultBanner>
        }
        <>
          {callObject && user && localDailyParticipant && localDailyParticipant.tracks &&
            <div css={css`padding-bottom: 100px; display: flex; flex-wrap: wrap; justify-content: ${somewhatSquishedWidth ? 'center' : 'space-between'}; width: 100%;`}>
              <div css={css`width: 530px; margin-bottom: 20px;`}>
                <div className="mic-check-background">
                  Looking good? Looking good!
                  <div css={mirrorSelfView && css`video { transform: scale(-1, 1); }`}>
                    <Tile
                      userId={localDailyParticipant.user_id}
                      videoTrackState={localDailyParticipant.tracks.video}
                      audioTrackState={localDailyParticipant.tracks.audio}
                      isHidingSelfView={hidingSelfView}
                      isLocalPerson={true}
                      isJoinScreen={true}
                      showOverlay={false}
                    />
                  </div>
                  <Row>
                    <Col className="join-screen-col join-screen-check mt-2">
                      <div className="join-screen-subtitle mb-2">
                        Profile
                      </div>
                      <div onClick={showUpdateNameModal}>
                        {userDisplayName}
                        {user.pronunciation && <><small className="small mx-2">({user.pronunciation})</small></>}
                        {user.pronouns && <><small className="small">{user.pronouns}</small></>}
                        <br />
                        <small className="small quiet">{user.location ? user.location : "(Add your location)"}</small>
                      </div>
                      <EditIcon className="join-screen-edit" onClick={showUpdateNameModal} />
                      { event.eventTypeId === 'Phone Free' && participant !== null &&
                        <DoNotDisturb deviceMenuStyle={true} participant={participant} />
                      }
                    </Col>
                    <Col className="join-screen-check mt-2 options-menu">
                      <V2DeviceSelector displayMicVolume />
                    </Col>
                  </Row>
                </div>
              </div>
              <div css={css`width: 530px;`}>
                <SegmentProvider eventLabel={'Join Screen'}>
                  <GoalsContainer goalsState={goalsState} disabled={updatingGoal || waitingToJoinSession} />
                </SegmentProvider>
              </div>
            </div>
          }
          {/* Device state: {deviceState} */}
          <JoiningCallStatus
            joinSessionError={joinSessionError}
            startCameraLoading={startCameraLoading}
            enterSessionButtonClicked={enterSessionButtonClicked}
            updatingGoal={updatingGoal}
            waitingToJoinSession={waitingToJoinSession}
          />
        </>
      </Container>
    </div>
  );
}

const GoalsContainer = ({ goalsState, disabled }) => {
  const { user } = useContext(UserContext)
  const { introContent: inviteIntroContent } = useContext(InviteInfoContext);
  const { result: lastSessionData } = useAutomaticallyFetch(useGetMostRecentParticipantGoalsForUser)

  const goalsData = useGoals(goalsState)

  useEffect(() => {
    if (goalsData.goals.length === 0 && inviteIntroContent === null) {
      goalsData.addGoal()
    }
  }, [goalsData.goals])

  useEffect(() => {
    if (goalsData.goals.length === 0 && inviteIntroContent !== null) {
      goalsData.pasteGoals(inviteIntroContent.prefilledGoals.map(goal => ({ text: goal, completed: false })), true)
    }
  }, [inviteIntroContent])

  return (
    <>
      {inviteIntroContent !== null && <DefaultBanner>{inviteIntroContent.prefilledGoalsTitle}</DefaultBanner>}
      <div className="mic-check-background">
        <div css={css`margin-bottom: 16px;`}>
          <div css={css`
          color: #333333;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
        `}>
            What are you working on today?
          </div>
        </div>
        <GoalListWithCopyPaste goalsData={goalsData} disabled={disabled} lastSessionData={lastSessionData} />
      </div>
    </>
  )
}

const JoiningCallStatus = ({
  joinSessionError,
  startCameraLoading,
  enterSessionButtonClicked,
  updatingGoal,
  waitingToJoinSession
}) => {
  const { callInitializationError } = useContext(EventContext);

  if (callInitializationError !== null) {
    return (
      <Alert variant="danger" css={css` color: white; font-size: 16px;`}>
        {callInitializationError}
      </Alert>
    )
  }

  if (startCameraLoading) {
    return (
      <div css={css`width: 100%; display: flex; justify-content: center; align-items: center; gap: 8px;`}>
        <LoadingIndicator /><Text style={TEXT_STYLES.APP_H3}>Finding the flow...</Text>
      </div>
    )
  }

  return (
    <div css={css`
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      background-color: white;
      box-shadow: 0px -1px 25px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    `}>
      <FlowButton onClick={enterSessionButtonClicked}>
        {updatingGoal || waitingToJoinSession ?
          <>
            <Spinner
              as="span"
              animation="grow"
              size="lg"
              role="status"
              aria-hidden="true"
            />
            Getting you in the flow...
          </>
          :
          <span>Enter Session</span>
        }
      </FlowButton>
    </div>
  )
}