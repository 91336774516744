/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { FlowButton, LoadingIndicator, Text, TEXT_STYLES } from '../components/flowComponents';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';
import { useAdminThemeClubsData } from '../fetch/endpoints';


export const ThemeClubsAdmin = () => {
  const now = dayjs()
  const [dateRange, setDateRange] = useState({ 
    start: now.startOf("week").format("YYYY/MM/DD"), 
    end: now.startOf("week").add(1, 'week').format("YYYY/MM/DD") 
  })

  const { result: clubs, performFetch, fetching, error } = useAutomaticallyFetch(useAdminThemeClubsData, {
    start: new Date(dateRange.start).valueOf(),
    end: new Date(dateRange.end).valueOf()
  }, { dependencies: [dateRange] })

  const loadingFinished = useFetchSucceeded(fetching, error)

  const scrollWeek = (direction) => {
    setDateRange(prevRange => ({
      start: dayjs(prevRange.start).add(direction, 'week').format("YYYY/MM/DD"),
      end: dayjs(prevRange.end).add(direction, 'week').format("YYYY/MM/DD")
    }))
  }

  return (
    <div css={css`min-height: 100vh; display: flex; justify-content: center; padding-top: 24px;`}>
      <div css={css`display: flex; flex-direction: column; align-items: center;`}>
        <Text customCss={css`margin-bottom: 12px;`} style={TEXT_STYLES.APP_H3}>Themes as Clubs Admin</Text>

        <div css={css`
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 12px;
          border-radius: 4px;
        `}>
          <FlowButton onClick={() => scrollWeek(-1)}>{'<-'}</FlowButton>
          <Text>Week: {dayjs(dateRange.start).format("MMMM D")} - {dayjs(dateRange.end).subtract(1, 'day').format("MMMM D, YYYY")}</Text>
          <FlowButton onClick={() => scrollWeek(1)}>{'->'}</FlowButton>
        </div>

        <div css={css`padding: 16px; display: flex; flex-direction: column; gap: 16px; width: 100%;`}>
          {fetching && (
            <div css={css`display: flex; justify-content: center;`}>
              <Text>Loading theme clubs...</Text>
              <LoadingIndicator />
            </div>
          )}
          {loadingFinished && <DataTable clubs={clubs} refetchClubData={performFetch} />}
        </div>
      </div>
    </div>
  )
}


const DataTable = ({ clubs, refetchClubData }) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId]
    }));
  };

  return (
    <>
    <Table striped bordered css={css`width: 100%`}>
      <thead>
        <tr>
          <th>Club</th>
          <th>Members</th>
          <th>Sessions</th>
          <th>Hosts</th>
          <th>Attendees</th>
        </tr>
      </thead>
      <tbody>
        {clubs.map(({ badge, numEvents, numUniqueHosts, numUniqueAttendees, mostActiveHosts, mostActiveAttendees }) => {
          const { name, clubPageSlug: slug, emoji, clubPage } = badge
          const isExpanded = expandedRows[slug];
          return (
          <tr key={slug}>
            <td>
              <Link to={`/club/${slug}`}>{emoji} {name}</Link>
            </td>
            <td>
              {clubPage.cachedNumMembers}
            </td>
            <td>
              {numEvents}
            </td>
            <td css={css`max-width: 800px; overflow-x: auto;`}>
              {numUniqueHosts}
              {mostActiveHosts.length > 0 && <FlowButton onClick={() => toggleRow(slug)}>
                {isExpanded ? 'Hide hosts' : 'Show hosts'}
              </FlowButton>}

              {mostActiveHosts.length > 0 && isExpanded &&
              <table striped bordered>
              <tr>
                <th>Host</th>
                <th>Email</th>
                <th>Number of sessions</th>
                <th>Subscription Status</th>
                <th>Goals</th>
                <th>Host Goal Descriptions</th>
                <th>Sessions</th>
              </tr>
              <tbody>
              {mostActiveHosts.map(({ name, email, subscriptionStatus, slug, count, sessions, goals, hostGoalDescriptions }) => (
                <tr key={slug}>
                  <td>
                    <Link to={`/host/${slug}`}>{name}</Link>
                  </td>
                  <td>
                    <a href={`mailto:${email}`}>{email}</a>
                  </td>
                  <td>
                    {count}
                  </td>
                  <td>
                    {subscriptionStatus}
                  </td>
                  <td>
                    <ul>
                    {goals.length > 0 ? goals.map(goal =>
                      <li>{goal.text}</li>
                    ) : <li>No goals</li>}
                    </ul>
                  </td>
                  <td>
                    {hostGoalDescriptions.length > 0 ? hostGoalDescriptions.map(goal =>
                      <li>{goal}</li>
                    ) : <li>No host goal descriptions</li>}
                  </td>
                  <td>
                    <ul>
                    {sessions.map(({ title, start, attendees, id }) => (
                      <li>
                      <a href={`/session/${id}`}target="_blank" rel="noreferrer" key={id}>
                        {title} - {dayjs(start).format('ddd, MMM D h:mm A')} - {attendees} participants
                      </a></li>
                    ))}
                    </ul>
                  </td>
                </tr>
              ))}
              </tbody>
              </table>}
            </td>
            <td>
              {numUniqueAttendees}
            </td>
          </tr>
        )})}
      </tbody>
    </Table>
    </>
  )
}
