/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect } from "react";
import { NEUTRAL_20, NEUTRAL_40 } from "../emotionVariables";
import {
  PomodoroTimer,
  usePomodoroTimerState,
} from "./PomodoroTimer/PomodoroTimer";
import { StatusUpdates } from "./StatusUpdates";
import { useModal } from "../wrappers/MagnificentlyMunificentModalManager";
import { LoungeFullscreenModal } from "./LoungeFullscreenModal";
import { LoungeVideoGrid } from "./LoungeVideoGrid/LoungeVideoGrid";
import { UserContext } from "../UserProvider";
import { WelcomeMessage } from "./WelcomeMessage";
import { GoalListWithCopyPaste } from "../components/GoalList/GoalListWithCopyPaste";
import LoungeCtaModal from "./LoungeCtaModal";
import { LoungeContextProvider } from "./LoungeContext";
import { SegmentContext } from "../wrappers/SegmentProvider";
import { LoungeRadioPlayer } from "./LoungeRadioPlayer";
import { useAutomaticallyFetch } from "../fetch/helpers";
import { useGetMostRecentParticipantGoalsForUser } from "../fetch/endpoints";
import { LoginSignupModal } from "../Sessions/modals/LoginSignupModal";

export const LoungeContent = ({
  spaceState,
  goalsData,
  existingTimedSessionRemainingTime,
}) => {
  const { user } = useContext(UserContext);

  const { visitId, space, currSpaceVisits, nextUpcomingParticipant } =
    spaceState || {};
  const { events: spaceEvents, hostedMusicPlaybackData } = space || {};
  const otherPeepsPresent = (currSpaceVisits || []).map((spaceVisit) => ({
    id: spaceVisit.id,
    name: spaceVisit.displayName,
    goals: spaceVisit.goals,
    timedSession: spaceVisit.currTimedSession,
    userId: spaceVisit.user.id,
    locationSubtitle: spaceVisit.user.locationSubtitle,
  }));
  const { result: lastSessionData } = useAutomaticallyFetch(useGetMostRecentParticipantGoalsForUser, {}, {condition: user !== null})

  const { setActiveModal } = useModal();
  useEffect(() => {
    if (user === null) {
      setActiveModal(LoungeFullscreenModal, {
        Contents: LoginSignupModal,
        onHide: () => {},
        contentProps: {
          showCloseButton: false,
          showLoginModeLink: true,
          onHide: () => {},
        },
        overlayColor: "rgba(0, 0, 0, .25)",
      });
    } else if (user !== null && !user.preferences.spaceWelcomeMessageSeen) {
      setActiveModal(LoungeFullscreenModal, { Contents: WelcomeMessage });
    }
  }, []);

  const pomodoroTimerState = usePomodoroTimerState({ visitId });
  const segmentContextValue = useContext(SegmentContext);
  return (
    <SegmentContext.Provider value={segmentContextValue}>
      <LoungeContextProvider>
        <div
          css={css`
            min-height: 100vh;
            background-color: ${NEUTRAL_20};
            display: flex;
            flex-direction: column;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              width: 100%;
              display: flex;
              @media (max-width: 640px) {
                width: 100%;
                max-width: 100%;
                height: auto;
                display: block;
              }
              justify-content: stretch;
              align-items: stretch;
              position: relative;
              z-index: 1;
              flex-grow: 1;
              overflow: hidden;
            `}
          >
            <LeftColumn key="LoungeLeftColumn">
              <PomodoroTimer
                visitId={visitId}
                existingTimedSessionRemainingTime={
                  existingTimedSessionRemainingTime
                }
                nextUpcomingParticipant={nextUpcomingParticipant}
                pomodoroTimerState={pomodoroTimerState}
              />
              <GoalListWithCopyPaste
                key="GoalsList"
                goalsData={goalsData}
                lastSessionData={lastSessionData}
                disabled={user === null}
              />
              {hostedMusicPlaybackData !== undefined && hostedMusicPlaybackData.playlist !== undefined &&
                <LoungeRadioPlayer hostedMusicPlaybackData={hostedMusicPlaybackData} />
              }
            </LeftColumn>

            <RightColumn key="LoungeRightColumn">
              <StatusUpdates events={spaceEvents} />
              <LoungeVideoGrid
                users={otherPeepsPresent}
                space={space}
                user={user}
              />
            </RightColumn>
          </div>
        </div>
        <LoungeCtaModal />
      </LoungeContextProvider>
    </SegmentContext.Provider>
  );
};

const LeftColumn = ({ children }) => {
  return (
    <div
      css={css`
        flex-shrink: 0;
        background: #ffffff;
        border-right: 1px solid ${NEUTRAL_40};
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px 8px;
        max-width: 50%;
        width: 400px;
        overflow: auto;
        @media (max-width: 640px) {
          width: 100%;
          max-width: 100%;
        }
      `}
    >
      {children}
    </div>
  );
};

const RightColumn = ({ children }) => {
  return (
    <div
      css={css`
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        @media (max-width: 640px) {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      `}
    >
      {children}
    </div>
  );
};
