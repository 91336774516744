/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createContext, useContext, useEffect } from 'react';
import { useGetUserGoogleCalendarEvents } from '../fetch/endpoints';
import { useAutomaticallyFetch } from '../fetch/helpers';
import dayjs from 'dayjs';
import { CalendarView } from '../CalendarView/CalendarView';
import { DayColumnWrapper } from './DayColumnWrapper';
import { toastError } from '../components/utils/toaster';
import { UserContext } from '../UserProvider';
import { LoadingIndicator } from '../components/flowComponents';

export const CalendarBookingContext = createContext(null)

export const CalendarBooking = ({ onRecommendationBooked = () => {} }) => {
  const { user } = useContext(UserContext)
  const userHasRefreshToken = user !== null && user.googleCalendarAuth !== undefined

  const { result: calendarEvents, fetching: calendarEventsLoading, error: calendarEventsError } = useAutomaticallyFetch(useGetUserGoogleCalendarEvents, {}, { transform: result =>
    result.googleCalendarData.items.map(event => {
      const { start, end, summary, id } = event
      return {
        // I have literally zero conception of why these events don't have to be processed with .tz() (and in fact become wrong if they are)
        start: dayjs(start.dateTime),
        end: dayjs(end.dateTime),
        title: summary,
        id
      }
    }),
    condition: userHasRefreshToken
  })
  useEffect(() => {
    if (calendarEventsError !== null) {
      toastError({ message: `Error loading calendar events: ${calendarEventsError} Please email help@flow.club for assistance.` })
    }
  }, [calendarEventsError])

  // try to read timezone on the fly here, since we don't have a user object to go off of in this context
  const userTimezone = dayjs.tz.guess()
  const today = dayjs().tz(userTimezone).startOf('day')
  const dates = []
  for (let i = 0; i < 7; i++) {
    const newDate = today.add(i, 'day')
    dates.push(newDate)
  }

  return (
    <CalendarBookingContext.Provider value={{ calendarEvents, onRecommendationBooked }}>
      <div css={css`position: relative; width: 100%;`}>
        <CalendarView dates={dates} DayColumnComponent={DayColumnWrapper} rowHeight={300} />
        {calendarEventsLoading && <div css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
          <LoadingIndicator />
        </div>}
      </div>
    </CalendarBookingContext.Provider>
  )
}

export const formattedTimeFromMinutes = (minutes) => dayjs().startOf('day').minute(minutes).format("h:mm a")