

export const HostAvatar = (props) => (
    <svg {...props} width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="63" cy="63" r="63" fill="url(#paint0_linear_3064:5873)"/>
    <mask id="mask0_3064:5873" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="126" height="126">
    <circle cx="63" cy="63" r="63" fill="black"/>
    </mask>
    <g mask="url(#mask0_3064:5873)">
    <path d="M63 84.6562C35.8172 84.6562 13.7812 103.166 13.7812 126H112.219C112.219 103.166 90.1828 84.6562 63 84.6562Z" fill="url(#paint1_linear_3064:5873)"/>
    <ellipse cx="62.0156" cy="53.1562" rx="34.4531" ry="35.4375" fill="#5B6DD4"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M71.5947 65.917C72.2794 65.3033 72.7799 64.4603 73.1806 63.5443C73.3934 63.0581 73.9107 62.861 74.3362 63.1041C74.7617 63.3473 74.9341 63.9385 74.7214 64.4248C74.2608 65.4775 73.6204 66.6032 72.6579 67.466C71.679 68.3435 70.4141 68.9062 68.7831 68.9062C67.2571 68.9062 65.8304 68.6464 64.5356 67.9516C63.2319 67.2521 62.1149 66.14 61.176 64.5306C60.9121 64.0782 61.0191 63.4671 61.4149 63.1655C61.8107 62.864 62.3454 62.9862 62.6093 63.4385C63.3931 64.7821 64.2782 65.6387 65.2677 66.1696C66.2659 66.7052 67.4232 66.9376 68.7831 66.9376C70.0382 66.9376 70.9266 66.516 71.5947 65.917Z" fill="white"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M64.4107 46.9458C63.9332 50.3645 60.9039 53 57.2381 53C53.2406 53 50 49.866 50 46C50 42.134 53.2406 39 57.2381 39C60.8248 39 63.8023 41.5231 64.3762 44.8341C65.7036 43.8225 67.2072 43.3021 69 43.3021C70.7928 43.3021 72.2964 43.8225 73.6238 44.8341C74.1977 41.5231 77.1752 39 80.7619 39C84.7594 39 88 42.134 88 46C88 49.866 84.7594 53 80.7619 53C77.0961 53 74.0668 50.3645 73.5893 46.9458C73.3284 46.9626 73.0613 46.8791 72.855 46.693C71.7346 45.6816 70.5321 45.1979 69 45.1979C67.4679 45.1979 66.2654 45.6816 65.145 46.693C64.9387 46.8791 64.6716 46.9626 64.4107 46.9458ZM57.2381 51.25C60.2362 51.25 62.6667 48.8995 62.6667 46C62.6667 43.1005 60.2362 40.75 57.2381 40.75C54.24 40.75 51.8095 43.1005 51.8095 46C51.8095 48.8995 54.24 51.25 57.2381 51.25ZM80.7619 51.25C83.76 51.25 86.1905 48.8995 86.1905 46C86.1905 43.1005 83.76 40.75 80.7619 40.75C77.7638 40.75 75.3333 43.1005 75.3333 46C75.3333 48.8995 77.7638 51.25 80.7619 51.25Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_3064:5873" x1="63" y1="0" x2="63" y2="129.5" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CAE7E0"/>
    <stop offset="1" stopColor="#88CFC0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_3064:5873" x1="63" y1="84.6562" x2="63" y2="126" gradientUnits="userSpaceOnUse">
    <stop stopColor="#33439F"/>
    <stop offset="1" stopColor="#727DB7"/>
    </linearGradient>
    </defs>
    </svg>
)

export const HostAvatarGroup = (props) => (
    <svg {...props} viewBox="0 0 210 151" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M104.942 64.1518C77.8514 71.5967 48.6954 71.1605 9.96206 65.092L10.0965 64.1975C48.777 70.2577 77.7924 70.6758 104.711 63.278C131.629 55.8803 156.496 40.6546 186.855 15.3433L187.416 16.0442C157.012 41.3925 132.032 56.7067 104.942 64.1518Z" fill="#D2DDEC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M109.111 79.8747C82.0204 87.3196 52.8645 86.8834 14.1311 80.8149L14.2656 79.9204C52.946 85.9806 81.9615 86.3987 108.88 79.0009C135.798 71.6032 160.665 56.3775 191.024 31.0662L191.585 31.7671C161.181 57.1154 136.201 72.4296 109.111 79.8747Z" fill="#D2DDEC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M117.45 111.32C90.3594 118.765 61.2034 118.329 22.47 112.26L22.6045 111.366C61.2849 117.426 90.3004 117.844 117.219 110.446C144.137 103.049 169.004 87.8228 199.363 62.5115L199.924 63.2124C169.52 88.5607 144.54 103.875 117.45 111.32Z" fill="#D2DDEC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M121.272 125.733C94.1816 133.178 65.0256 132.741 26.2923 126.673L26.4267 125.778C65.1071 131.839 94.1226 132.257 121.041 124.859C147.959 117.461 172.826 102.235 203.185 76.9241L203.746 77.625C173.342 102.973 148.362 118.287 121.272 125.733Z" fill="#D2DDEC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M113.048 94.7238C85.9582 102.169 56.8022 101.733 18.0689 95.664L18.2033 94.7695C56.8838 100.83 85.8993 101.248 112.818 93.85C139.736 86.4523 164.602 71.2267 194.962 45.9153L195.522 46.6162C165.119 71.9645 140.139 87.2787 113.048 94.7238Z" fill="#D2DDEC"/>
    <ellipse cx="27.9212" cy="28.5051" rx="27.9212" ry="28.5051" fill="url(#paint0_linear_349_226)"/>
    <mask id="mask0_349_226" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="58">
    <ellipse cx="27.9212" cy="28.5051" rx="27.9212" ry="28.5051" fill="url(#paint1_linear_349_226)"/>
    </mask>
    <g mask="url(#mask0_349_226)">
    <path d="M27.921 38.3037C15.8738 38.3037 6.1076 46.6789 6.1076 57.0102H49.7344C49.7344 46.6789 39.9682 38.3037 27.921 38.3037Z" fill="url(#paint2_linear_349_226)"/>
    <ellipse cx="27.485" cy="24.0514" rx="15.2694" ry="16.0341" fill="#5B6DD4"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.7303 29.8247C32.0337 29.547 32.2555 29.1656 32.4331 28.7512C32.5274 28.5312 32.7567 28.442 32.9453 28.552C33.1338 28.662 33.2103 28.9295 33.116 29.1495C32.9118 29.6259 32.628 30.1352 32.2015 30.5256C31.7676 30.9226 31.207 31.1772 30.4842 31.1772C29.8079 31.1772 29.1756 31.0597 28.6017 30.7453C28.0239 30.4288 27.5289 29.9256 27.1128 29.1974C26.9958 28.9928 27.0432 28.7162 27.2186 28.5798C27.394 28.4434 27.631 28.4987 27.748 28.7033C28.0953 29.3112 28.4876 29.6988 28.9262 29.939C29.3686 30.1814 29.8815 30.2865 30.4842 30.2865C31.0404 30.2865 31.4341 30.0957 31.7303 29.8247Z" fill="white"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.6601 21.6936C27.4485 23.2404 26.1059 24.4328 24.4812 24.4328C22.7096 24.4328 21.2734 23.0148 21.2734 21.2656C21.2734 19.5164 22.7096 18.0984 24.4812 18.0984C26.0709 18.0984 27.3904 19.24 27.6448 20.7381C28.2331 20.2804 28.8995 20.0449 29.694 20.0449C30.4886 20.0449 31.155 20.2804 31.7433 20.7381C31.9976 19.24 33.3172 18.0984 34.9068 18.0984C36.6785 18.0984 38.1147 19.5164 38.1147 21.2656C38.1147 23.0148 36.6785 24.4328 34.9068 24.4328C33.2822 24.4328 31.9396 23.2404 31.728 21.6936C31.6124 21.7012 31.494 21.6634 31.4026 21.5792C30.906 21.1216 30.373 20.9027 29.694 20.9027C29.015 20.9027 28.4821 21.1216 27.9855 21.5792C27.8941 21.6634 27.7757 21.7012 27.6601 21.6936ZM24.4812 23.641C25.81 23.641 26.8871 22.5775 26.8871 21.2656C26.8871 19.9537 25.81 18.8902 24.4812 18.8902C23.1525 18.8902 22.0753 19.9537 22.0753 21.2656C22.0753 22.5775 23.1525 23.641 24.4812 23.641ZM34.9068 23.641C36.2356 23.641 37.3127 22.5775 37.3127 21.2656C37.3127 19.9537 36.2356 18.8902 34.9068 18.8902C33.5781 18.8902 32.5009 19.9537 32.5009 21.2656C32.5009 22.5775 33.5781 23.641 34.9068 23.641Z" fill="white"/>
    <ellipse cx="98.8324" cy="101.804" rx="27.9212" ry="28.5051" fill="url(#paint3_linear_349_226)"/>
    <mask id="mask1_349_226" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="70" y="73" width="57" height="58">
    <ellipse cx="98.8324" cy="101.804" rx="27.9212" ry="28.5051" fill="url(#paint4_linear_349_226)"/>
    </mask>
    <g mask="url(#mask1_349_226)">
    <rect x="80.6608" y="106.329" width="37.6714" height="23.9804" rx="4" fill="url(#paint5_linear_349_226)"/>
    <rect x="85.0928" y="84.6106" width="27.478" height="26.2428" rx="4" fill="#5B6DD4"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M99.9681 104.934C100.272 104.656 100.493 104.275 100.671 103.86C100.765 103.64 100.995 103.551 101.183 103.661C101.372 103.771 101.448 104.039 101.354 104.259C101.15 104.735 100.866 105.244 100.439 105.635C100.005 106.032 99.4449 106.286 98.722 106.286C98.0457 106.286 97.4134 106.169 96.8395 105.854C96.2618 105.538 95.7667 105.035 95.3506 104.307C95.2337 104.102 95.2811 103.825 95.4565 103.689C95.6319 103.552 95.8689 103.608 95.9858 103.812C96.3332 104.42 96.7255 104.808 97.164 105.048C97.6064 105.291 98.1193 105.396 98.722 105.396C99.2782 105.396 99.672 105.205 99.9681 104.934Z" fill="white"/>
    <rect x="91.2976" y="94.1123" width="1.32958" height="3.61969" rx="0.664789" fill="white"/>
    <rect x="104.15" y="94.1123" width="1.32958" height="3.61969" rx="0.664789" fill="white"/>
    </g>
    <ellipse cx="170.629" cy="28.5051" rx="27.9212" ry="28.5051" fill="url(#paint6_linear_349_226)"/>
    <mask id="mask2_349_226" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="142" y="0" width="57" height="58">
    <ellipse cx="170.629" cy="28.5051" rx="27.9212" ry="28.5051" fill="url(#paint7_linear_349_226)"/>
    </mask>
    <g mask="url(#mask2_349_226)">
    <rect x="152.458" y="33.0298" width="37.6714" height="23.9804" rx="4" fill="url(#paint8_linear_349_226)"/>
    <rect x="156.891" y="11.3115" width="27.478" height="26.2428" rx="4" fill="#2FC6A5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M169.993 32.0874C170.296 31.8097 170.518 31.4283 170.696 31.0139C170.79 30.7939 171.019 30.7047 171.208 30.8147C171.396 30.9247 171.473 31.1922 171.379 31.4122C171.174 31.8886 170.891 32.3979 170.464 32.7883C170.03 33.1853 169.47 33.4399 168.747 33.4399C168.07 33.4399 167.438 33.3224 166.864 33.008C166.286 32.6915 165.791 32.1883 165.375 31.4601C165.258 31.2555 165.306 30.9789 165.481 30.8425C165.657 30.706 165.894 30.7614 166.011 30.966C166.358 31.5739 166.75 31.9615 167.189 32.2017C167.631 32.4441 168.144 32.5492 168.747 32.5492C169.303 32.5492 169.697 32.3584 169.993 32.0874Z" fill="#091454"/>
    <rect x="161.323" y="20.8132" width="1.32958" height="3.61969" rx="0.664789" fill="#091454"/>
    <rect x="174.175" y="20.8132" width="1.32958" height="3.61969" rx="0.664789" fill="#091454"/>
    </g>
    <ellipse cx="98.8324" cy="28.5051" rx="27.9212" ry="28.5051" fill="url(#paint9_linear_349_226)"/>
    <mask id="mask3_349_226" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="70" y="0" width="57" height="58">
    <ellipse cx="98.8324" cy="28.5051" rx="27.9212" ry="28.5051" fill="url(#paint10_linear_349_226)"/>
    </mask>
    <g mask="url(#mask3_349_226)">
    <path d="M81.0704 52.3312L97.989 61.1803C99.0976 61.7601 100.409 61.7735 101.528 61.2162L117.87 53.0834C120.801 51.6247 120.845 47.3766 117.944 45.856L101.067 37.0075C99.9587 36.4266 98.6476 36.412 97.5275 36.968L81.1444 45.1002C78.2088 46.5574 78.1652 50.8117 81.0704 52.3312Z" fill="url(#paint11_linear_349_226)"/>
    <path d="M81.0704 31.5175L97.989 40.3666C99.0976 40.9464 100.409 40.9597 101.528 40.4025L117.87 32.2696C120.801 30.811 120.845 26.5629 117.944 25.0422L101.067 16.1938C99.9587 15.6129 98.6476 15.5983 97.5275 16.1543L81.1444 24.2865C78.2088 25.7437 78.1652 29.998 81.0704 31.5175Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M96.2527 34.3496C95.7642 34.0719 95.4071 33.6905 95.1212 33.2761C94.9694 33.0561 94.6003 32.9669 94.2967 33.0769C93.9932 33.1869 93.8701 33.4544 94.0219 33.6745C94.3505 34.1508 94.8075 34.6601 95.4941 35.0505C96.1926 35.4475 97.095 35.7021 98.2587 35.7021C99.3475 35.7021 100.365 35.5846 101.289 35.2702C102.219 34.9537 103.016 34.4505 103.686 33.7223C103.874 33.5177 103.798 33.2411 103.516 33.1047C103.233 32.9683 102.852 33.0236 102.664 33.2282C102.104 33.8361 101.473 34.2237 100.767 34.4639C100.055 34.7063 99.229 34.8114 98.2587 34.8114C97.3633 34.8114 96.7294 34.6206 96.2527 34.3496Z" fill="#091454"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M90.951 27.1101C90.6476 26.8324 90.4258 26.451 90.2482 26.0366C90.1539 25.8166 89.9246 25.7274 89.736 25.8374C89.5475 25.9474 89.471 26.2149 89.5653 26.4349C89.7695 26.9113 90.0533 27.4206 90.4798 27.811C90.9137 28.208 91.4743 28.4626 92.1971 28.4626C92.8734 28.4626 93.5058 28.3451 94.0796 28.0307C94.6574 27.7142 95.1524 27.211 95.5685 26.4828C95.6855 26.2782 95.6381 26.0016 95.4627 25.8652C95.2873 25.7288 95.0503 25.7841 94.9333 25.9887C94.586 26.5966 94.1937 26.9842 93.7551 27.2244C93.3127 27.4668 92.7998 27.5719 92.1971 27.5719C91.6409 27.5719 91.2472 27.3811 90.951 27.1101Z" fill="#091454"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M103.804 27.1101C103.5 26.8324 103.278 26.451 103.101 26.0366C103.007 25.8166 102.777 25.7274 102.589 25.8374C102.4 25.9474 102.324 26.2149 102.418 26.4349C102.622 26.9113 102.906 27.4206 103.333 27.811C103.766 28.208 104.327 28.4626 105.05 28.4626C105.726 28.4626 106.358 28.3451 106.932 28.0307C107.51 27.7142 108.005 27.211 108.421 26.4828C108.538 26.2782 108.491 26.0016 108.315 25.8652C108.14 25.7288 107.903 25.7841 107.786 25.9887C107.439 26.5966 107.046 26.9842 106.608 27.2244C106.165 27.4668 105.652 27.5719 105.05 27.5719C104.494 27.5719 104.1 27.3811 103.804 27.1101Z" fill="#091454"/>
    </g>
    <ellipse cx="170.629" cy="101.804" rx="27.9212" ry="28.5051" fill="url(#paint12_linear_349_226)"/>
    <mask id="mask4_349_226" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="142" y="73" width="57" height="58">
    <ellipse cx="170.629" cy="101.804" rx="27.9212" ry="28.5051" fill="url(#paint13_linear_349_226)"/>
    </mask>
    <g mask="url(#mask4_349_226)">
    <path d="M152.868 125.63L169.787 134.479C170.895 135.059 172.206 135.073 173.326 134.515L189.668 126.382C192.599 124.924 192.642 120.676 189.742 119.155L172.864 110.307C171.756 109.726 170.445 109.711 169.325 110.267L152.942 118.399C150.006 119.856 149.963 124.111 152.868 125.63Z" fill="url(#paint14_linear_349_226)"/>
    <path d="M152.868 104.817L169.787 113.666C170.895 114.245 172.206 114.259 173.326 113.702L189.668 105.569C192.599 104.11 192.642 99.862 189.742 98.3413L172.864 89.4929C171.756 88.912 170.445 88.8974 169.325 89.4534L152.942 97.5856C150.006 99.0427 149.963 103.297 152.868 104.817Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M168.51 107.649C168.207 107.371 167.985 106.99 167.807 106.575C167.713 106.355 167.484 106.266 167.295 106.376C167.107 106.486 167.03 106.754 167.124 106.974C167.329 107.45 167.612 107.959 168.039 108.35C168.473 108.747 169.034 109.001 169.756 109.001C170.433 109.001 171.065 108.884 171.639 108.569C172.217 108.253 172.712 107.75 173.128 107.021C173.245 106.817 173.198 106.54 173.022 106.404C172.847 106.267 172.61 106.323 172.493 106.527C172.146 107.135 171.753 107.523 171.315 107.763C170.872 108.005 170.359 108.11 169.756 108.11C169.2 108.11 168.806 107.92 168.51 107.649Z" fill="#091454"/>
    </g>
    <rect x="162.652" y="98.1843" width="1.32958" height="3.61969" rx="0.664789" fill="#091454"/>
    <rect x="175.505" y="98.1843" width="1.32958" height="3.61969" rx="0.664789" fill="#091454"/>
    <ellipse cx="27.9212" cy="101.804" rx="27.9212" ry="28.5051" fill="url(#paint15_linear_349_226)"/>
    <mask id="mask5_349_226" style={{"maskType": "alpha"}} maskUnits="userSpaceOnUse" x="0" y="73" width="56" height="58">
    <ellipse cx="27.9212" cy="101.804" rx="27.9212" ry="28.5051" fill="url(#paint16_linear_349_226)"/>
    </mask>
    <g mask="url(#mask5_349_226)">
    <path d="M27.1071 112.93C27.9005 112.059 29.2715 112.059 30.0648 112.93L43.0027 127.14C44.1721 128.424 43.2609 130.486 41.5238 130.486H15.6481C13.9111 130.486 12.9999 128.424 14.1693 127.14L27.1071 112.93Z" fill="url(#paint17_linear_349_226)"/>
    <path d="M25.9587 88.5942C26.7245 87.2403 28.6748 87.2403 29.4405 88.5942L43.4755 113.412C44.2295 114.745 43.2663 116.396 41.7346 116.396H13.6647C12.133 116.396 11.1698 114.745 11.9238 113.412L25.9587 88.5942Z" fill="#2FC6A5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.9326 112.174C34.236 111.896 34.4579 111.514 34.6355 111.1C34.7297 110.88 34.959 110.791 35.1476 110.901C35.3362 111.011 35.4126 111.278 35.3183 111.498C35.1142 111.975 34.8303 112.484 34.4038 112.874C33.9699 113.272 33.4094 113.526 32.6865 113.526C32.0102 113.526 31.3779 113.409 30.804 113.094C30.2263 112.778 29.7312 112.275 29.3151 111.546C29.1981 111.342 29.2455 111.065 29.421 110.929C29.5964 110.792 29.8334 110.848 29.9503 111.052C30.2977 111.66 30.69 112.048 31.1285 112.288C31.5709 112.53 32.0838 112.635 32.6865 112.635C33.2427 112.635 33.6365 112.445 33.9326 112.174Z" fill="#091454"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.9898 102.232C28.7782 103.779 27.4356 104.971 25.811 104.971C24.0393 104.971 22.6031 103.553 22.6031 101.804C22.6031 100.055 24.0393 98.637 25.811 98.637C27.4006 98.637 28.7201 99.7785 28.9745 101.277C29.5628 100.819 30.2292 100.583 31.0237 100.583C31.8183 100.583 32.4847 100.819 33.073 101.277C33.3274 99.7785 34.6469 98.637 36.2365 98.637C38.0082 98.637 39.4444 100.055 39.4444 101.804C39.4444 103.553 38.0082 104.971 36.2365 104.971C34.6119 104.971 33.2693 103.779 33.0577 102.232C32.9421 102.24 32.8237 102.202 32.7323 102.118C32.2357 101.66 31.7028 101.441 31.0237 101.441C30.3447 101.441 29.8118 101.66 29.3152 102.118C29.2238 102.202 29.1054 102.24 28.9898 102.232ZM25.811 104.18C27.1397 104.18 28.2169 103.116 28.2169 101.804C28.2169 100.492 27.1397 99.4288 25.811 99.4288C24.4822 99.4288 23.4051 100.492 23.4051 101.804C23.4051 103.116 24.4822 104.18 25.811 104.18ZM36.2365 104.18C37.5653 104.18 38.6424 103.116 38.6424 101.804C38.6424 100.492 37.5653 99.4288 36.2365 99.4288C34.9078 99.4288 33.8306 100.492 33.8306 101.804C33.8306 103.116 34.9078 104.18 36.2365 104.18Z" fill="#091454"/>
    <defs>
    <linearGradient id="paint0_linear_349_226" x1="27.9212" y1="0" x2="27.9212" y2="57.0101" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D6E5E2"/>
    <stop offset="1" stopColor="#B7CEC9"/>
    </linearGradient>
    <linearGradient id="paint1_linear_349_226" x1="27.9212" y1="0" x2="27.9212" y2="57.0101" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C0D9D3"/>
    <stop offset="1" stopColor="#3F4544"/>
    </linearGradient>
    <linearGradient id="paint2_linear_349_226" x1="27.921" y1="38.3037" x2="27.921" y2="57.0102" gradientUnits="userSpaceOnUse">
    <stop stopColor="#33439F"/>
    <stop offset="1" stopColor="#727DB7"/>
    </linearGradient>
    <linearGradient id="paint3_linear_349_226" x1="98.8324" y1="73.2991" x2="98.8324" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D6E5E2"/>
    <stop offset="1" stopColor="#B7CEC9"/>
    </linearGradient>
    <linearGradient id="paint4_linear_349_226" x1="98.8324" y1="73.2991" x2="98.8324" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C0D9D3"/>
    <stop offset="1" stopColor="#3F4544"/>
    </linearGradient>
    <linearGradient id="paint5_linear_349_226" x1="99.4965" y1="106.329" x2="99.4965" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3646A0"/>
    <stop offset="1" stopColor="#6E79B6"/>
    </linearGradient>
    <linearGradient id="paint6_linear_349_226" x1="170.629" y1="0" x2="170.629" y2="57.0101" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D6E5E2"/>
    <stop offset="1" stopColor="#B7CEC9"/>
    </linearGradient>
    <linearGradient id="paint7_linear_349_226" x1="170.629" y1="0" x2="170.629" y2="57.0101" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C0D9D3"/>
    <stop offset="1" stopColor="#3F4544"/>
    </linearGradient>
    <linearGradient id="paint8_linear_349_226" x1="171.294" y1="33.0298" x2="171.294" y2="57.0102" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3BA991"/>
    <stop offset="1" stopColor="#5FD5BC"/>
    </linearGradient>
    <linearGradient id="paint9_linear_349_226" x1="98.8324" y1="0" x2="98.8324" y2="57.0101" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D6E5E2"/>
    <stop offset="1" stopColor="#B7CEC9"/>
    </linearGradient>
    <linearGradient id="paint10_linear_349_226" x1="98.8324" y1="0" x2="98.8324" y2="57.0101" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C0D9D3"/>
    <stop offset="1" stopColor="#3F4544"/>
    </linearGradient>
    <linearGradient id="paint11_linear_349_226" x1="99.7184" y1="37.1022" x2="99.7184" y2="62.2138" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D8D8D8"/>
    <stop offset="1" stopColor="#FCFCFC"/>
    </linearGradient>
    <linearGradient id="paint12_linear_349_226" x1="170.629" y1="73.2991" x2="170.629" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D6E5E2"/>
    <stop offset="1" stopColor="#B7CEC9"/>
    </linearGradient>
    <linearGradient id="paint13_linear_349_226" x1="170.629" y1="73.2991" x2="170.629" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C0D9D3"/>
    <stop offset="1" stopColor="#3F4544"/>
    </linearGradient>
    <linearGradient id="paint14_linear_349_226" x1="171.516" y1="110.401" x2="171.516" y2="135.513" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D8D8D8"/>
    <stop offset="1" stopColor="#FCFCFC"/>
    </linearGradient>
    <linearGradient id="paint15_linear_349_226" x1="27.9212" y1="73.2991" x2="27.9212" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D6E5E2"/>
    <stop offset="1" stopColor="#B7CEC9"/>
    </linearGradient>
    <linearGradient id="paint16_linear_349_226" x1="27.9212" y1="73.2991" x2="27.9212" y2="130.309" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C0D9D3"/>
    <stop offset="1" stopColor="#3F4544"/>
    </linearGradient>
    <linearGradient id="paint17_linear_349_226" x1="28.586" y1="111.306" x2="28.586" y2="136.88" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3AA78F"/>
    <stop offset="1" stopColor="#5DD3BA"/>
    </linearGradient>
    </defs>
    </svg>
)
