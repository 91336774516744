
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import dayjs from 'dayjs';

import { useContext, useEffect } from 'react';
import { UserContext } from '../../UserProvider';
import { useModal } from '../../wrappers/MagnificentlyMunificentModalManager';
import { useBookSession } from '../../fetch/endpoints';
import { BookSessionModalContent } from './BookSessionModalContent';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

// TODO: I suspect this layer around useBookSession, and basically all of the inviteUserToEvent arguments,
// don't really need to exist now that there's guaranteed to be a user at the point of booking; maybe get rid of them
export const useBookSessionWrapper = () => {
  const { user } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent()

  const bookSession = async (event) => {
    const result = await performFetch({
      eventId: event.id,
      displayName: user.displayName,
      tags: user.tags || []
    })
    const hoursUntilSession = dayjs(event.start).diff(dayjs(), 'hours')
    if (result.success) {
      // TODO: I think this is redundant with the InviteInfoWrapper logic, but not going to check right now
      localStorage.removeItem('inviteCode');
      sendSegmentEvent("Booked session", {
        location: window.location.pathname,
        host: event.host,
        hoursUntilSession,
      })
      if (user.sessions === 0) {
        sendSegmentEvent("Booked session with 0 sessions", {
          location: window.location.pathname,
          host: event.host,
          hoursUntilSession,
        })
      }
    }
    return result
  }

  const {
    performFetch,
    fetching,
    error
  } = useBookSession()

  return {
    bookSession,
    bookingSession: fetching,
    sessionModalError: error
  }
}

export function BookSessionModal({ event, onHide, onSuccess = () => {} }) {
  const { setActiveModal } = useModal()

  const { bookSession, bookingSession, sessionModalError } = useBookSessionWrapper()

  // Reasonably sure this `if (!bookingSession)` check is completely unnecessary, but leaving it in for now because it's kind of mimicking what was already present
  const startBookingSession = async () => {
    if (!bookingSession) {
      await bookSession(event)
      onSuccess()
    } else {
      setActiveModal(null)
    }
  }
  useEffect(() => {
    startBookingSession()
  }, [])

  return (
    <BookSessionModalContent event={event} onHide={onHide} loading={bookingSession} error={sessionModalError} />
  );
}