/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { TextInput } from '../flowComponents'
import { getSessionShareLink } from '../../Sessions/SessionUtils'
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider'
import { copyToClipboard } from '../../utils'
import { useMediaQuery } from 'react-responsive';

export const ShareSessionLinkWithImagePreview = ({
  eventId,
  inviteCode,
  ogImage,
}) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const sessionShareLink = getSessionShareLink(eventId, inviteCode)
  const copyToClipboardAndSendSegmentEvent = () => {
    sendSegmentEvent("Copied session share link", { eventId, inviteCode })
    copyToClipboard(sessionShareLink)
  }
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})

  return (
    <div css={css`
      padding: 24px 24px 48px 24px;
      background: #f6f6f6;
      border-radius: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      hyphens: auto;
      color: #000000;
      text-align: center;
      margin: 15px 0px;
    `}>
      <div css={css`
        text-align: center;
        font-size: 20px;
        font-family: "Sato";
      `}>
        Share your session
      </div>
      <div css={css`
        max-width: 460px;
        margin: 10px auto;
      `}>
          <TextInput
            readOnly
            value={sessionShareLink}
            onFocus={copyToClipboardAndSendSegmentEvent}
          />
          <br/>
          <img
            css={css`
              width: ${mobileView ? '100%' : '280px'};
            `}
            src={ogImage}
            alt="Flow Club session"
          />
      </div>
    </div>
  )
}