/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { zeroPad } from "react-countdown";
import dayjs from 'dayjs';
import { useContext } from 'react';
import { LinkStyledText, Text, TEXT_STYLES } from '../components/flowComponents';
import { FC_BLUE, NEUTRAL_100, FC_GREEN } from '../emotionVariables';
import EventContext from '../EventContext';
import { useToggleSessionAutomuting } from '../fetch/endpoints';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

export const getTimerText = (sessionStarted, timeElapsed, cumulativeStageDurations, currentStage, sessionFinished, activeWorkingTime) => {
  const timeToDisplay = !sessionStarted ? (timeElapsed * -1) : (cumulativeStageDurations[currentStage] * 60 * 1000) - timeElapsed
  const textColor = !sessionFinished ? (!sessionStarted ? FC_BLUE : activeWorkingTime ? NEUTRAL_100 : FC_GREEN) : '#1FAB8D';
  const text = !sessionFinished ? parseTimeForDisplay(timeToDisplay) : "Thanks for joining! 🎉"
  return { text, textColor }
}

const parseTimeForDisplay = time => {
  const duration = dayjs.duration(time)
  const days = Math.floor(duration.days())
  const hours = Math.floor(duration.hours())
  const minutes = Math.floor(duration.minutes())
  const seconds = Math.floor(duration.seconds())
  const text = `${days > 0 ? days + " day" + (days > 1 ? "s" : "") + ", " : ""}${hours > 0 || days > 0 ? zeroPad(hours) + ":" : ""}${zeroPad(minutes)}:${zeroPad(seconds)}`
  return text
}

const TimeDisplay = ({ sessionFinished, text, textColor }) => {
  return (
    <Text style={!sessionFinished ? TEXT_STYLES.MARKETING_H2 : TEXT_STYLES.SUBTITLE_1} customCss={css`color: ${textColor};`}>
      {text}
    </Text>
  )
}

const Timer = ({ sessionStarted, sessionFinished, sessionWorkingTimeStarted, nextStageIsActiveWorkingTime, text, textColor, userIsHost = false }) => {
  const { event, refetchEventData } = useContext(EventContext)
  const { performFetch: toggleAutomuting } = useToggleSessionAutomuting()
  const { automutingDisabled, id: eventId, chatOnly } = event

  const sendSegmentEvent = useSendSegmentEvent()
  const automutingToggled = async (togglingTo) => {
    sendSegmentEvent(togglingTo ? "Enable Automuting Clicked" : "Disable Automuting Clicked")
    await toggleAutomuting({ eventId, togglingTo })
    refetchEventData()
  }

  const countdownPrefaceText = !sessionStarted ?
    "Starting in..." :
    (nextStageIsActiveWorkingTime && !sessionWorkingTimeStarted ?
      (chatOnly ?
      <div>
        <Text>Say hi and share your goals in the chat! Work starting in...</Text>
      </div> :
      <div>
        {!automutingDisabled && <Text>Muting for work in…</Text>}
        {userIsHost &&
          <LinkStyledText onClick={() => automutingToggled(!automutingDisabled)}>
            <Text style={TEXT_STYLES.CAPTION}>
              {!automutingDisabled ? 'Disable automuting?' : 'Automuting disabled. Click to re-enable.'}
            </Text>
          </LinkStyledText>
        }
      </div>) :
      "")

  return (
    <>
      <Text>{countdownPrefaceText}</Text>
      <TimeDisplay sessionFinished={sessionFinished} text={text} textColor={textColor} />
    </>
  );
}

export default Timer;