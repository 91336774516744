import { useContext } from "react";
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { LoginSignupModal } from '../Sessions/modals/LoginSignupModal';
import { UserContext } from "../UserProvider";
import { UpdateNameModal } from "../Sessions/modals/UpdateNameModal";
import { BookSessionModal } from "../Sessions/modals/BookSessionModal";
import { ClaimHostModal } from "../Sessions/modals/ClaimHostModal";
import { getHostClaimability } from "../Sessions/SessionUtils";
import { getUserIsUnauthed } from "../../functions/shared/helpers";

export const openBookingSessionModal = (user, event, setActiveModal, onSuccess) => {
  const { userCanClaimHosting } = getHostClaimability({ event, user })

  if (user.displayName === null) {
    setActiveModal(UpdateNameModal, { title: "Before we book, what's your name?", successFn: () => setActiveModal(BookSessionModal, { event, onSuccess }) })
  } else if (userCanClaimHosting) {
    setActiveModal(ClaimHostModal, { event, alreadyBooked: false, onSuccess })
  } else {
    setActiveModal(BookSessionModal, { event, onSuccess })
  }
}

export const useBookSessionButtonHandler = (event, onSuccess, onLoggedIn = openBookingSessionModal) => {
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()

  const showLoginAndSaveEvent = () => {
    setActiveModal(LoginSignupModal, {
      showLoginModeLink: true,
      onSuccess: ({ user }) => onLoggedIn(user, event, setActiveModal, onSuccess)
    })
  };

  const bookSessionClicked = () => {
    if (user === null || getUserIsUnauthed(user)) {
      showLoginAndSaveEvent()
    } else {
      onLoggedIn(user, event, setActiveModal, onSuccess)
    }
  }

  return bookSessionClicked
}