/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FlowButton, LoadingIndicator, Text, TEXT_STYLES, UnstyledLink } from '../components/flowComponents';
import { UserContext } from '../UserProvider';
import { useGetReferralsLeaderboardData } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { InviteModal } from '../InviteUtils';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import dayjs from 'dayjs';
import { adminBackground } from '../emotionVariables';

export const ReferralsLeaderboard = () => {
  const { user, userHasAdminPermissions } = useContext(UserContext)
  const [monthOffset, setMonthOffset] = useState(0)
  const { result: users, fetching, error } = useAutomaticallyFetch(useGetReferralsLeaderboardData, { monthOffset }, { transform: result => result.usersWithReferrals, dependencies: [monthOffset] })

  const loadingFinished = useFetchSucceeded(fetching, error)

  const { setActiveModal } = useModal()
  const sendSegmentEvent = useSendSegmentEvent()
  const inviteButtonClicked = () => {
    sendSegmentEvent("Referrals Leaderboard Invite Button Clicked")
    setActiveModal(InviteModal)
  }

  const signupButtonClicked = () => {
    sendSegmentEvent("Referrals Leaderboard Signup Button Clicked")
  }

  return (
    <div css={css`display: flex; justify-content: center; padding-top: 24px;`}>
      <div css={css`max-width: 800px; display: flex; flex-direction: column; align-items: center;`}>
        {userHasAdminPermissions && <MonthSelector monthOffset={monthOffset} setMonthOffset={setMonthOffset} />}
        <Text customCss={css`margin-bottom: 12px;`} style={TEXT_STYLES.APP_H3}>Monthly Referrals Leaderboard</Text>
        {user === null ?
        <UnstyledLink to="/signup">
          <FlowButton onClick={signupButtonClicked}>Sign up for Flow Club to join the competition</FlowButton>
        </UnstyledLink>
        :
        <FlowButton onClick={inviteButtonClicked}>Invite your friends to flow</FlowButton>}
        <div css={css`padding: 16px; display: flex; flex-direction: column; gap: 16px; width: 100%;`}>
          {fetching && (
            <div css={css`display: flex; justify-content: center;`}>
              <Text>Loading referrals data...</Text>
              <LoadingIndicator />
            </div>
          )}
          <Text></Text>
          {loadingFinished && <DataTable users={users} userHasAdminPermissions={userHasAdminPermissions} />}
        </div>
      </div>
    </div>
  )
}

const DataTable = ({ users, userHasAdminPermissions }) => {
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>User</th>
          <th>Referrals</th>
          {userHasAdminPermissions && <>
            <th css={adminBackground}>Email</th>
            <th css={adminBackground}>User ID</th>
          </>}
        </tr>
      </thead>
      <tbody>
        {users.map(({ userId, displayName, invitesAccepted, email }, index) => {
          return (
            <tr css={index === 0 && css`font-weight: bold;`} key={userId}>
              <td>{displayName}</td>
              <td>{invitesAccepted}</td>
              {userHasAdminPermissions && <>
                <td css={adminBackground}>{email}</td>
                <td css={adminBackground}>{userId}</td>
              </>}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const MonthSelector = ({ monthOffset, setMonthOffset }) => {
  return (
    <div css={css`
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px;
      border-radius: 4px;

      ${adminBackground};
    `}>
      <FlowButton onClick={() => setMonthOffset(offset => offset - 1)}>{'<–'}</FlowButton>
      <Text>Current month: {dayjs().add(monthOffset, 'month').format("MMMM YYYY")}</Text>
      <FlowButton onClick={() => setMonthOffset(offset => offset + 1)}>{'–>'}</FlowButton>
    </div>
  )
}