/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { FC_DARK_BLUE, FC_GREEN } from '../emotionVariables';

export const STEP_STATUS = {
  PENDING: 'pending',
  CURRENT: 'current',
  COMPLETED: 'completed'
}

const STEP_DIAMETER = 24;

const STEP_STATUS_WIDTH = 230;

const LastStepIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M17.5875 4.36456C17.4618 4.30452 17.3221 4.27979 17.1833 4.293C17.0447 4.30621 16.9121 4.35687 16.8 4.43956C14.1469 6.42706 11.8969 5.47081 9.3 4.35519C6.70312 3.23956 3.64688 1.92706 0.3 4.43956C0.20685 4.50942 0.13125 4.60002 0.0791774 4.70415C0.0271049 4.8083 0 4.92313 0 5.03956V20.7896C0 20.9885 0.0790201 21.1792 0.219668 21.3199C0.360323 21.4605 0.551085 21.5396 0.75 21.5396C0.948915 21.5396 1.13968 21.4605 1.28033 21.3199C1.42098 21.1792 1.5 20.9885 1.5 20.7896V16.674C4.02187 14.9771 6.19688 15.9052 8.7 16.9833C10.2281 17.6302 11.8687 18.3333 13.6312 18.3333C14.925 18.3333 16.2844 17.9583 17.7 16.8896C17.7926 16.8192 17.8679 16.7285 17.9198 16.6245C17.9719 16.5204 17.9993 16.4058 18 16.2896V5.03956C17.9989 4.90023 17.9599 4.76382 17.8872 4.64493C17.8146 4.52604 17.711 4.42913 17.5875 4.36456ZM11.625 16.5052V11.4896C9.85312 10.9833 8.14688 9.99897 6.375 9.49272V14.5177C4.84688 14.124 3.225 14.0677 1.5 14.9396V9.9896C3.21562 8.99585 4.81875 9.0521 6.375 9.49272V4.82394C7.16603 5.0844 7.9422 5.38797 8.7 5.73331C9.6375 6.12706 10.6125 6.54894 11.625 6.81144V11.4896C13.1812 11.9302 14.7844 11.9865 16.5 10.9927V15.9052C14.7844 17.0583 13.2281 17.0021 11.625 16.5052Z" fill="#091454"/>
  </svg>
)

const CompletedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none" css={css`
    width: 8.601px;
    height: 6px;
    flex-shrink: 0;
  `}>
    <path d="M0.699707 4L3.70049 7L9.30048 1" stroke="white" strokeWidth="2"/>
  </svg>
)

const Step = ({ status, isLast }) => {
  const baseStyle = css`
    display: flex;
    box-sizing: border-box;
    width: ${STEP_DIAMETER}px;
    height: ${STEP_DIAMETER}px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 100px;
  `

  const pendingStyle = css`
    border: 1px solid rgba(9, 20, 84, 0.50);
    padding: 10px;
  `
  const currentStyle = css`
    border: 8px solid ${FC_DARK_BLUE};
  `

  const completedStyle = css`
    background: ${FC_GREEN};
    padding: 10px;
  `

  const stepStyle = css`
    ${baseStyle};
    ${status === STEP_STATUS.PENDING ?
      pendingStyle
      : status === STEP_STATUS.COMPLETED ?
      completedStyle :
      isLast ? '' : currentStyle};
  `
  return (
    <div css={stepStyle}>
      {status === STEP_STATUS.CURRENT && isLast ?
        <LastStepIcon /> :
        status === STEP_STATUS.COMPLETED ?
        <CompletedIcon /> :
        " "
      }
    </div>
  );
};

export const StepsStatus = ({ steps }) => {
  const numSteps = steps.length
  const lastIndex = numSteps - 1;
  const connectorWidth = (STEP_STATUS_WIDTH - numSteps * STEP_DIAMETER) / (numSteps - 1);
  return (
    <div css={css`
      display: flex;
      margin: 24px auto;
      flex-direction: row;
      width: ${STEP_STATUS_WIDTH}px;
      align-items: center;
      justify-content: space-between;
    `}>
      {steps.map(({status, name, MainContent, navType}, index) => {
        const isLast = index === lastIndex;
        return (
        <div key={`steps-container-${index}`} css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>
          <Step key={index} status={status} isLast={isLast} />
          {!isLast && <div css={css`
            height: 1px;
            background: rgba(9, 20, 84, 0.50);
            width: ${connectorWidth}px;
          `}></div>}
          </div>
        )
      })}
    </div>
  );
};