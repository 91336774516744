import React from 'react';
import './BrowserUnsupported.css';

export default function BrowserUnsupported() {
  return (
    <p className="browser-unsupported">
      Sorry, but it loooks like you need to upgrade your browser to join Flow Club sessions.
      <br />
      If you're using one of these browsers and seeing this message, please
      contact <a href="mailto:help@flow.club">help@flow.club</a>:
      <ul>
        <li>Chrome 74 and above</li>
        <li>Safari 12.1 and above</li>
        <li>Firefox 80 and above</li>
        <li>Microsoft Edge 74 and above</li>
      </ul>
    </p>
  );
}
