/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FC_DARK_BLUE, SMOKE } from "./emotionVariables";

import { UserContext } from './UserProvider';
import { SegmentProvider, useSendSegmentEvent } from './wrappers/SegmentProvider';
import { GoalListWithCopyPaste } from './components/GoalList/GoalListWithCopyPaste';
import { useGoals, makeNewGoalObject } from './components/GoalList/useGoals';

export const MyGoals = () => {
  const { user } = useContext(UserContext)
  const mobileView = useMediaQuery({ query: '(max-width: 768px)' })

  const goalsState = useState([makeNewGoalObject([])] )
  const goalsData = useGoals(goalsState)

  return (
    <SegmentProvider eventLabel={'My Flow Club'}>
       <div css={css`
          background-color: white;
        `}>
        <Helmet>
          <title>My Goals</title>
          <meta name='robots' content='noindex, follow' />
        </Helmet>
          <Container css={css`
            text-align: center;
            margin-top: 20px;
          `}>
            <h1>My Goals</h1>
            <GoalListWithCopyPaste goalsData={goalsData} />
          </Container>
      </div>
    </SegmentProvider>
  );
}