/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { cloneElement, useEffect, useRef, useState } from 'react';
import { animated, useTransition, useSpring } from '@react-spring/web'
import { usePreviousSticky } from '../utils';

// Similar but not identical to FlowCarousel, although they could potentially be unified at some point
export const AnimatedSlidingWrapper = ({ children: items, currentItemIndex, skipAnimatingHeight = false }) => {
  const prevItemIndex = usePreviousSticky(currentItemIndex)
  const transitions = useTransition(currentItemIndex, {
    keys: null,
    from: index => {
      const targetX = prevItemIndex === null ? '0' : (prevItemIndex > index ? '-100' : prevItemIndex < index ? '100' : '0')
      return { transform: `translate3d(${targetX}%,0,0)` }
    },
    enter: { transform: `translate3d(${0}%,0,0)` },
    leave: index => {
      const targetX = index > currentItemIndex ? '100' : index < currentItemIndex ? '-100' : '0'
      return { transform: `translate3d(${targetX}%,0,0)` }
    }
  })

  const [slidingContentHeight, setSlidingContentHeight] = useState(0)
  const containerRef = useRef(null)
  const updateDimensions = () => {
    const element = containerRef.current
    if (element !== null) {
      const { height } = element.getBoundingClientRect()
      setSlidingContentHeight(height)
    }
  }
  const ref = (element) => {
    containerRef.current = element
    updateDimensions()
  }

  useEffect(() => {
    const timer = setInterval(() => {
      updateDimensions()
    }, 1000 / 30)
    return () => clearInterval(timer)
  }, [])

  const containerStyle = useSpring({ height: slidingContentHeight, immediate: skipAnimatingHeight })

  return (
    <animated.div style={containerStyle} css={css`
      position: relative;
      width: 100%;
      ${!skipAnimatingHeight && css`overflow: hidden;`}
    `}>
      {transitions((style, index) => {
        const CarouselItem = items[index]
        return <div ref={ref} css={css`position: absolute; top: 0px; left: 0px; width: 100%;`}>
          <animated.div style={style}>
            <div css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}>
              {CarouselItem !== undefined ? cloneElement(CarouselItem, {
                updateWrapperDimensions: updateDimensions
              }) : undefined}
            </div>
          </animated.div>
        </div>
      })}
    </animated.div>
  )
}