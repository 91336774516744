/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useEffect, useMemo, useState } from 'react';
import Icon, {
  TYPE_CAMERA_OFF,
  TYPE_CAMERA_ON,
  TYPE_MIC_OFF,
  TYPE_MIC_ON,
  TYPE_MUSIC_SHARE_ON,
  TYPE_MUSIC_SHARE_OFF,
  TYPE_MUSIC_ON,
  TYPE_MUSIC_OFF,
  TYPE_LEAVE,
  TYPE_CHAT,
  TYPE_CHAT_NOTIFICATION,
  TYPE_MORE,
} from '../Icon/Icon';
import { CaretIcon, CaretUpIcon } from '../Chat/ChatIcons';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { TooltipWrapper } from '../../../components/TooltipWrapper';
import { FC_DARK_BLUE, FC_LIGHT_BLUE, FC_LIGHTER_BLUE, FC_RED, NEUTRAL_90 } from '../../../emotionVariables';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { MicVolumeIndicator } from '../../../ParticipantApp/MicVolumeIndicator';

const tooltipFloatingUIArgs = { placement: 'top', middleware: [shift({ padding: 32 }), offset(12), flip()] }

const trayButtonCss = ({ isLeaveButton, active, disabled, customButtonCss }) => css`
  border: none;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 15px;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background: linear-gradient(0deg, rgba(210, 221, 236, 0.64) 0%, rgba(210, 221, 236, 0.64) 100%), #FFF;
  }
  ${active && css`
    background-color: ${FC_DARK_BLUE};
  `
  }

  ${isLeaveButton && css`
    background-color: ${FC_RED};
    &:hover {
      background-color: #A65140;
    }
  `}
  ${disabled && css`opacity: 66%;`}
  ${customButtonCss}
`

const sideButtonCss = ({ active }) => css`
  width: 18px;
  height: 40px;
  background-color: #fff;
  position: absolute;
  border-radius: 6px;
  left: 40px;
  padding: 0px 5px;
  color: #000;
  border: none;
  font-size: 20px;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${FC_LIGHTER_BLUE};
  }
  ${active && css`
    background-color: ${FC_DARK_BLUE};
    &:hover {
      background-color: ${FC_DARK_BLUE};
    }
  `}
  `;
const mainButtonCss = ({ active }) => css`
  border: none;
  border-radius: 6px;
  height: 40px;
  padding: 0px 5px;
  background: none;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${FC_LIGHTER_BLUE};
  }
  ${active && css`
    background-color: ${FC_DARK_BLUE};
  `}
  clear: none;
`


/**
 * Props:
 * - type: string
 * - disabled: boolean
 * - onClick: () => ()
 */
export default function TrayButton({ disabled, onClick, type, active, highlighted, className, tooltip: tooltipText, clickEvent, sharedMouseoverState, customButtonCss = null, forceRerenderOnContentsChange = true, showTooltipOnMobile = false, showSideButton = false, onSideButtonClicked = () => {}, fill = null }) {
  const TooltipContents = useMemo(() => <span css={css`color: ${NEUTRAL_90};`}>{tooltipText}</span>, [tooltipText])

  const dualButtonCss = css`
    ${customButtonCss}
    position: relative;
    height: 40px;
    width: 58px;
    display: inline-flex;
    align-items: center;
  `
  const sendSegmentEvent = useSendSegmentEvent()
  const buttonClicked = () => {
    sendSegmentEvent("Tray Button Clicked", { buttonClicked: clickEvent.name, togglingTo: clickEvent.togglingTo })
    onClick()
  }

  // some hacky nonsense to make sure the tooltip resizes if the text length changes
  const [updateTooltipAt, setUpdateTooltipAt] = useState(null)
  useEffect(() => {
    if (forceRerenderOnContentsChange) {
      setUpdateTooltipAt(Date.now())
    }
  }, [tooltipText])

  return (
    showSideButton ?
      <div css={trayButtonCss({ isLeaveButton: type === TYPE_LEAVE, active, disabled, customButtonCss: dualButtonCss})}>
         <TooltipWrapper showOnMobile={showTooltipOnMobile} key={updateTooltipAt} TooltipContents={TooltipContents} useFloatingArgs={tooltipFloatingUIArgs} sharedMouseoverState={sharedMouseoverState}>
          <button
            disabled={disabled}
            onClick={buttonClicked}
            css={mainButtonCss({ active })}
            >
              {type === TYPE_MIC_ON ?
                <MicVolumeIndicator
                  icon={TYPE_MIC_ON}
                  backgroundColor={active ? FC_DARK_BLUE : '#ffffff'}
                  fill={fill}
                  size={40}
                /> :
                <Icon type={type} className={className} css={css`width: 40px; height: 40px;`} fill={fill} />
              }
            </button>
          </TooltipWrapper>
          <button css={sideButtonCss({ active })} onClick={onSideButtonClicked}>
            {active ? <CaretUpIcon customCss={css`width: 12px; height: 6px; fill: #ffffff;`}/> : <CaretIcon customCss={css`width: 12px; height: 6px; fill: #0202020;`}/>}
          </button>
      </div>
      :
      <TooltipWrapper showOnMobile={showTooltipOnMobile} key={updateTooltipAt} TooltipContents={TooltipContents} useFloatingArgs={tooltipFloatingUIArgs} sharedMouseoverState={sharedMouseoverState}>
        <button
          disabled={disabled}
          onClick={buttonClicked}
          css={trayButtonCss({ isLeaveButton: type === TYPE_LEAVE, active, disabled, customButtonCss })}
        >
        <Icon type={type} className={className} fill={fill} css={css`width: 40px; height: 40px;`}/>
      </button>
      </TooltipWrapper>
  );
}

export { TYPE_CAMERA_OFF, TYPE_CAMERA_ON, TYPE_MIC_OFF, TYPE_MIC_ON, TYPE_MUSIC_SHARE_ON, TYPE_MUSIC_SHARE_OFF, TYPE_MUSIC_ON, TYPE_MUSIC_OFF, TYPE_LEAVE, TYPE_CHAT, TYPE_CHAT_NOTIFICATION, TYPE_MORE };