/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { HOST_INCENTIVE_SESSIONS_GOAL } from '../functions/shared/constants';
import { Text, TEXT_STYLES } from './components/flowComponents';
import { FC_GREEN, FC_LIGHT_GREEN, NEUTRAL_20, NEUTRAL_40, NEUTRAL_70, NEUTRAL_80, NEUTRAL_90 } from './emotionVariables';
import { useGetHostIncentiveInfo } from './fetch/endpoints';
import { useAutomaticallyFetch } from './fetch/helpers';

export const useHostIncentiveProgress = () => {
  const { result: sessionsHosted } = useAutomaticallyFetch(useGetHostIncentiveInfo)
  // I should really just add a default value option to the fetch hooks one of these days
  return sessionsHosted ?? { completedSessions: null, pendingSessions: null, billingDate: null, displayProgress: false }
}

const getExplanatoryText = ({ billingDate, goalMet, displayProgress }) => {
  if (!displayProgress) {
    return "⚠️ Note that the host incentive discount can only be applied if you have a currently active, un-discounted Flow Club subscription."
  }

  if (billingDate === null) {
    return "Your Flow Club subscription is currently set to cancel so we won't be able to apply a discount. Continue your subscription and get $20 off!"
  }

  // Discounts are only applied at the end of the month, so if the user's next billing date is within the current month, it won't apply then
  // It'll apply to the next month instead
  let billingDateDayjs = dayjs(billingDate)
  const billingDateMonth = billingDateDayjs.month()
  const currentMonthNumber = dayjs().month()
  if (currentMonthNumber === billingDateMonth) {
    billingDateDayjs = billingDateDayjs.add(1, 'month')
  }

  const billingDateText = billingDateDayjs.format("MMMM Do")

  if (!goalMet) {
    const currentMonthText = dayjs().format("MMMM")
    const hoursUntilEndOfMonth = dayjs().endOf('month').diff(dayjs(), 'hours')
    const days = Math.floor(hoursUntilEndOfMonth / 24);
    const hours = hoursUntilEndOfMonth % 24;
    const hostingWindowText = `${days > 0 ? `${days} day${days === 1 ? '' : 's'}, ` : ''}${hours} hour${hours === 1 ? '' : 's'}`
    return <div>
        Host 10 sessions in {currentMonthText} (within the next <b>{hostingWindowText}</b>) to receive a $20 discount on your next month of Flow Club.
        <Text>The discount will be applied to your {billingDateText} invoice.</Text>
      </div>
  }

  return `Your $20 discount will be applied to your ${billingDateText} invoice.`
}

export const HostIncentiveProgress = ({ displayExplanatoryText = true, progressData }) => {
  let { completedSessions, pendingSessions, billingDate, displayProgress } = progressData

  const goalPortionMet = Math.min(completedSessions / HOST_INCENTIVE_SESSIONS_GOAL, 1)
  const goalPortionMetPending = Math.min((completedSessions + pendingSessions) / HOST_INCENTIVE_SESSIONS_GOAL, 1)
  const goalMet = completedSessions >= HOST_INCENTIVE_SESSIONS_GOAL

  const currentMonthText = dayjs().format("MMMM")

  const explanatoryText = getExplanatoryText({ billingDate, goalMet, displayProgress })

  return (
    <div css={css`width: 400px; max-width: 100%;`}>
      {displayExplanatoryText && <Text customCss={css`margin-bottom: 12px;`} style={TEXT_STYLES.APP_H4}>Host Incentive Progress</Text>}
      <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
        <Text customCss={css`color: ${!goalMet ? NEUTRAL_80 : FC_GREEN}; font-weight: bold;`}>
          {!displayExplanatoryText ? `${currentMonthText} Host Incentive` : (
            !goalMet ?
              `${HOST_INCENTIVE_SESSIONS_GOAL - completedSessions} more to go!` :
              '50% off achieved!'
          )}
        </Text>
        <div css={css`background-color: ${NEUTRAL_20}; border-radius: 4px; padding: 4px 8px; text-align: end;`}>
          <Text customCss={css`color: ${NEUTRAL_90};`}>{completedSessions}/{HOST_INCENTIVE_SESSIONS_GOAL} sessions</Text>
          {!goalMet && pendingSessions > 0 && <Text style={TEXT_STYLES.CAPTION}>
            ({pendingSessions} upcoming)
          </Text>}
        </div>
      </div>
      <ProgressBar goalPortionMet={goalPortionMet} goalPortionMetPending={goalPortionMetPending} showDisabled={!displayProgress} />
      {displayExplanatoryText &&
        <Text customCss={css`color: ${NEUTRAL_70};`}>{explanatoryText}</Text>
      }
    </div>
  )
}

const ProgressBar = ({ goalPortionMet, goalPortionMetPending, showDisabled = false }) => {
  const progressBarPortionStyle = css`
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: inherit;
    height: 100%;
  `

  return (
    <div css={css`
      width: 100%;
      height: 12px;
      background-color: ${NEUTRAL_40};
      border-radius: 8px;

      position: relative;

      margin-bottom: 24px;

      ${showDisabled && css`filter: saturate(0);`}
    `}>
      <div css={css`
        ${progressBarPortionStyle}
        background-color: ${FC_LIGHT_GREEN};
        width: ${goalPortionMetPending * 100}%;
      `} />
      <div css={css`
        ${progressBarPortionStyle}
        background-color: ${FC_GREEN};
        width: ${goalPortionMet * 100}%;
      `} />
    </div>
  )
}