/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect } from 'react'
import Icon, { TYPE_CHECKMARK } from '../../../daily/daily-components/Icon/Icon'
import { FC_BLUE, FC_DARK_BLUE } from '../../../emotionVariables'
import { WeeklySessionGoalOptions } from '../../../../functions/shared/constants'
import { SegmentProvider, useSendSegmentEvent } from '../../../wrappers/SegmentProvider'
import { Text } from '../../flowComponents'

export const WeeklyGoal = ({ activeOption, setActiveOption }) => 
  <SegmentProvider baseData={{ goalOptions: Object.keys(WeeklySessionGoalOptions) }} eventLabel='Weekly Goal'>
    <WeeklyGoalContent activeOption={activeOption} setActiveOption={setActiveOption} />
  </SegmentProvider>

const WeeklyGoalContent = ({ activeOption, setActiveOption }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  useEffect(() => {
    sendSegmentEvent("Page Viewed")
  }, [])

  const goalOptionSelected = (optionKey) => {
    setActiveOption(optionKey)
    sendSegmentEvent("Setting Selected", { optionKey })
  }

  return (
    <div>
      <div css={css`
        font-family: 'Sato';
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        color: #202020;

        margin-bottom: 20px;
      `}>
        Weekly Goal Settings
      </div>
      <Text customCss={css`margin-bottom: 32px;`}>Setting a weekly goal helps you stay committed. We'll send you reminders to join Flow Clubs. You can change your weekly goal any time.</Text>
      <div>
        {Object.entries(WeeklySessionGoalOptions).map(([optionKey, optionValues]) =>
          <GoalOption option={optionValues} selected={activeOption === optionKey} select={() => goalOptionSelected(optionKey)} />
        )}
      </div>
    </div>
  )
}

const GoalOption = ({ option, selected, select }) => {
  const { name, hours } = option
  return (
    <div onClick={select} css={css`
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
      padding: 8px 12px;
      border: 1px solid transparent;

      ${selected && css`
        border-color: ${FC_DARK_BLUE};
      `}
    `}>
      <div css={css`
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 1px solid #C4C4C4;
        ${selected && css`
          background-color: ${FC_BLUE};
        `}

        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-right: 22px;
      `}>
        {selected && <Icon css={css`fill: white; width: 8px; height: 8px;`} type={TYPE_CHECKMARK}/>}
      </div>
      <div css={css`
        color: #404040; 
        letter-spacing: 0.5px;
      `}>
        <div css={css`font-weight: 700;`}>{name}</div>
        <div>{`${hours} hour${hours === 1 ? '' : 's'} a week`}</div>
      </div>
    </div>
  )
}