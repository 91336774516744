/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useContext, useState } from 'react'
import dayjs from 'dayjs'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from'react-responsive';

import { UserContext } from '../../UserProvider'
import { useGetCreatePage } from '../../fetch/endpoints'
import { FancySessionTimeSelector } from './FancySessionTimeSelector';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { AdminCreationOptions } from './AdminCreationOptions';
import { Text, TEXT_STYLES } from '../flowComponents';
import { SegmentProvider } from '../../wrappers/SegmentProvider';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { getUserHasAdminPermissions } from '../../../functions/shared/helpers';
import Icon, { TYPE_CLOSE } from '../../daily/daily-components/Icon/Icon';
import { ONE_MINUTE } from '../../../functions/shared/constants';

const MobileViewMediaQuery = {query: '(max-width: 767px)'}

export const CreateSession = () => {
  return (
    <SegmentProvider eventLabel={'Session Creation'}>
      <CreateSessionContent />
    </SegmentProvider>
  )
}

const CreateSessionContent = () => {
  const { user } = useContext(UserContext)
  const history = useHistory()
  const mobileView = useMediaQuery(MobileViewMediaQuery)

  // @ts-ignore
  const [adminModeEnabled, setAdminModeEnabled] = useState(false)

  // @ts-ignore
  const [selectedHostId, setSelectedHostId] = useState(user.uid)

  // @ts-ignore
  const creatingForOtherHost = adminModeEnabled && selectedHostId !== user.uid

  const { result: createPageData } = useAutomaticallyFetch(useGetCreatePage, {}, { refetchEvery: ONE_MINUTE })
  const { hosts, eventTypes, canCreateSessions, claimableSessions, creatableSessionVisibilities } = createPageData ?? { hosts: [], eventTypes: [], onDemandCreationInfo: null, claimableSessions: [], canCreateSessions: false, creatableSessionVisibilities: [] }

  const selectedHost = !creatingForOtherHost ?
    // @ts-ignore
    { timezone: user.timezone, id: user.uid, displayName: user.displayName, tags: user.tags }
    : hosts.find(host => host.id === selectedHostId)

  const onDemandCreationInfo = createPageData === null ? null : ({
    ...createPageData.onDemandCreationInfo,
    creationAllowedAt: createPageData.onDemandCreationInfo.creationAllowedAt !== null ? dayjs(createPageData.onDemandCreationInfo.creationAllowedAt).tz(selectedHost.timezone) : null
  })

  const selectedHostRelativeTimezoneOffset =
  // @ts-ignore
    selectedHost.timezoneOffset === undefined || user.timezoneOffset === selectedHost.timezoneOffset ?
      null :
      // @ts-ignore
      `${(user.timezoneOffset - selectedHost.timezoneOffset) / 60} hrs (e.g. ${dayjs().tz(user.timezone).startOf('hour').format('h:mm a')} locally -> ${dayjs().tz(selectedHost.timezone).startOf('hour').format('h:mm a')})`

  if (createPageData !== null && !canCreateSessions) {
    return <Redirect to={"/"} />
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: ${mobileView ? "0 16px" : "0 24px"};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
          `}
        >
          <h2
            css={css`
              margin: 16px 0px;
              flex-grow: 1;
              text-align: center;
            `}
          >
            Create a Session
          </h2>
        <Link
          to="/upcoming/"
          onClick={(e) => {
            // History will always have new tab and /upcoming/create. If we came from somewhere besides new tab, go back to that.
            if (history.length > 2) {
              e.preventDefault();
              history.goBack();
            }
          }}
        >
          <div css={css`
            margin: -16px;
            padding: 16px;
            cursor: pointer;
          `}>
            {/* @ts-ignore */}
            <Icon type={TYPE_CLOSE} />
          </div>
        </Link>
        </div>
      </div>
      <Container>
          {/* special case: calling getUserHasAdminPermissions directly to make this display even if admin view is disabled */}
          {getUserHasAdminPermissions(user) && (
            <AdminCreationOptions
              selectedHostId={selectedHostId}
              setSelectedHostId={setSelectedHostId}
              allHosts={hosts}
              adminModeEnabled={adminModeEnabled}
              setAdminModeEnabled={setAdminModeEnabled}
              creatingForOtherHost={creatingForOtherHost}
            />
          )}
        {creatingForOtherHost && (
          <div css={css``}>
            <div
              css={css`
                display: flex;
                height: 24px;
              `}
            >
              <div
                css={css`
                  height: 50%;
                  border-bottom: 1px solid orange;
                  flex-grow: 1;
                  margin-top: 2px;
                `}
              />
              <Text
                style={TEXT_STYLES.APP_H5}
                customCss={css`
                  color: orange;
                  padding: 0px 12px;
                `}
              >
                Creating session for: {selectedHost.displayName} (
                {selectedHost.email})
              </Text>
              <div
                css={css`
                  height: 50%;
                  border-bottom: 1px solid orange;
                  flex-grow: 1;
                  margin-top: 2px;
                `}
              />
            </div>
            {selectedHostRelativeTimezoneOffset !== null && (
              <div>
                <Text
                  style={TEXT_STYLES.BODY_2}
                  customCss={css`
                    color: orange;
                  `}
                >
                  Timezone offset from local time:{" "}
                  {selectedHostRelativeTimezoneOffset}
                </Text>
              </div>
            )}
          </div>
        )}
      </Container>
      <FancySessionTimeSelector
        eventTypes={eventTypes}
        selectedHost={selectedHost}
        adminModeEnabled={adminModeEnabled}
        onDemandCreationInfo={onDemandCreationInfo}
        claimableSessions={claimableSessions}
        creatableSessionVisibilities={creatableSessionVisibilities}
      />
    </div>
  );
}