/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import "../settings.scss"
import React, {
    useContext,
    useState,
} from "react";

import { useForm } from 'react-hook-form';
import {
  Link,
} from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';

import {toastError, toastSuccess } from '../../utils/toaster';
import { 
  Check,
} from '../../../shared/components/icons'
import { HELP_EMAIL } from '../../../../functions/shared/constants';
import { AuthenticationError, translateAuthErrorToToast } from "../../utils/auth";
import { UnstyledLink } from "../../flowComponents";

import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { UserContext } from '../../../UserProvider';
import { functionsHost } from "../../../firebase"
import { FinishSignupBanner } from "../../../shared/components/FinishSignupBanner";

const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider()
GOOGLE_PROVIDER.setCustomParameters({
  prompt: 'select_account',
})
const GOOGLE_PROVIDER_ID = GOOGLE_PROVIDER.providerId
const EMAIL_PASSWORD_PROVIDER = new firebase.auth.EmailAuthProvider()
const EMAIL_PASSWORD_PROVIDER_ID = EMAIL_PASSWORD_PROVIDER.providerId

// Login change options
enum UpdateLoginOption {
  UPDATE_LOGIN_FROM_GOOGLE = 1,
  UPDATE_LOGIN_FROM_EMAIL
}

const PLACEHOLDER_EMAIL = "email@gmail.com";

function Login () {
  const { authUser, emailVerified} = useContext(UserContext);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [intendedAuthMethodId, setIntendedAuthMethodId] = useState<null | string>("");
  // only used for re-auth from Google
  const [currUserGoogleCredential, setCurrUserGoogleCredential] = useState<null | firebase.auth.UserCredential>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { register, handleSubmit } = useForm();

  const sendEmailVerification = async () => {
    try {
      //@ts-ignore
      await authUser.sendEmailVerification();
      toastSuccess({ message: 'Verification email sent.' });
      setEmailVerificationSent(true);
    } catch (error) {
      console.error('Error sending verification email:', error);
      toastError({ message: 'Error sending verification email. Please try again or contact help@flow.club.' });
    }
  }

  // currently only authenticating with one mechanism, so take index 0
  // @ts-ignore
  const currAuthMethodId = authUser.providerData.length > 0 ? authUser.providerData[0].providerId : null
  
  /**
   * Called when the user selects which auth method they prefer.
   * @param intendedAuthMethodId ID of the new auth method
   */
  async function newLoginIntent(intendedAuthMethodId) {
    setIntendedAuthMethodId(intendedAuthMethodId);
  }

  /**
   * Clears all state.
   */
  function cancel() {
    setIntendedAuthMethodId(null);
    setCurrUserGoogleCredential(null);
    setIsUpdating(false);
  }

  /**
   * Reauthentication function. Reauthentication is required before an auth change.
   * @param args arguments for the currAuthMethodId credential method 
   * @returns error or null
   */
  async function reauthenticate({ args }) {
    let reauthenticationCredential;
    let error;
    // choose appropriate authentication method
    if (currAuthMethodId === GOOGLE_PROVIDER_ID) {
      // @ts-ignore
      reauthenticationCredential = await authUser.reauthenticateWithPopup(GOOGLE_PROVIDER).catch((e) => {
        error = e;
      });
      setCurrUserGoogleCredential(reauthenticationCredential);
    } else if (currAuthMethodId === EMAIL_PASSWORD_PROVIDER_ID) {
      // @ts-ignore
      reauthenticationCredential = firebase.auth.EmailAuthProvider.credential(authUser.email, args.password || '');
      // @ts-ignore
      await authUser.reauthenticateWithCredential(reauthenticationCredential).catch((e) => {
        error = e;
      });
    } else {
      error = { code: AuthenticationError.ERR_CODE_UNSUPPORTED_METHOD }
      return;
    }
    
    if (error) {
      setCurrUserGoogleCredential(null);
      translateErrorToToast({ errorCode: error.code, });
      return error;
    }
    return reauthenticationCredential;
  }

  function translateErrorToToast({ errorCode }) {
    translateAuthErrorToToast({ errorCode })
    setIsUpdating(false);
  }

  /**
   * Called when the user selects a new mechanism for authentication.
   * @param credential The UserCredential, if applicable, to be linked to the account.
   * If none are supplied, defaults to Google login.
   * @returns null
   */
  async function newAuthMethodSelected({ credential, currUserEmailCredential }) {
    setIsUpdating(true);
    let currUserCredential = currUserEmailCredential || (currUserGoogleCredential && currUserGoogleCredential.credential);
    // ensure proper assumptions are met
    if (
      // must have an updated credential
      !currUserCredential || 
      // if google auth method, should not have credential
      (intendedAuthMethodId === GOOGLE_PROVIDER_ID && credential)
    ) {
      return { errorCode: AuthenticationError.ERR_CODE_BAD_STATE };
    }

    // unlink the old auth methods
    // @ts-ignore
    let providerData = authUser.providerData;
    for (let i = 0; i < providerData.length; i++) {
      // @ts-ignore
      await authUser.unlink(providerData[i].providerId);
    }

    // link the credential to the account. if the credential already exists, then add it
    // otherwise, pop up for google account linking.
    let error;
    if (credential) {
      // @ts-ignore
      await authUser.linkWithCredential(credential).catch((e) => {
        error = e;
      });
    } else if (intendedAuthMethodId === GOOGLE_PROVIDER_ID) {
      // @ts-ignore
      await authUser.linkWithPopup(GOOGLE_PROVIDER).catch((e) => {
        error = e;
      });
    }

    //@ts-ignore
    error = error || await updateEmailData({ newEmail: authUser.providerData[0].email });

    if (error) {
      // regardless of error, link back to the old account so the user still has access.
      // @ts-ignore
      await authUser.linkWithCredential(currUserCredential);

      // process the error into a readable state for the user
      translateErrorToToast({ errorCode: error.code, });
      return;
    }
  }

  /**
   * Update data stores to reflect a new email.
   * @param newEmail the new email to update the database and authUser with.
   */
  async function updateEmailData({ newEmail }) {
    let error;
    // @ts-ignore
    await authUser.updateEmail(newEmail).catch((e) => {
      error = e;
    });
    if (error) {
      return error;
    }

    // @ts-ignore
    await authUser.updateProfile({ email: newEmail })

    // @ts-ignore
    await authUser.sendEmailVerification()

    // update email in the db
    // @ts-ignore
    const token = await authUser.getIdToken();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        updateObject: { email: newEmail },
      })
    };
    await fetch(`${functionsHost}updateUser/`, requestOptions)
    .catch((e) => {
      toastError({
        message: e.message,
      });
    });

    // notify user of success
    toastSuccess({
      message: "Email updated. Check your email to verify your new email address.",
    })
    // clear all state by "cancel"-ing
    cancel()
  }

  const isGoogleAuth = currAuthMethodId === GOOGLE_PROVIDER_ID;

  // currentStep relies on 2 things:
  const currentStep = (
    !!intendedAuthMethodId &&  // 1. whether or not a new method is chosen
    ( isGoogleAuth ?  // 2. whether or not the current auth method is Google or Email
      UpdateLoginOption.UPDATE_LOGIN_FROM_GOOGLE :
      UpdateLoginOption.UPDATE_LOGIN_FROM_EMAIL
    )
  );

  // Unauthed user case
  if (currAuthMethodId === null) {
    return (
      // this is comically ugly but it also doesn't crash. and that's pretty cool
      <FinishSignupBanner />
    )
  }

  return(
    <div>
      <h4>Login</h4>
      <div className="row">
        <div className="col-sm">
          <div>Signed in with {isGoogleAuth ? 'Google' : 'email' }</div>
          {/* @ts-ignore */}
          <div className="login-email">{authUser.email}</div>{emailVerified === false && !emailVerificationSent && <UnstyledLink css={css`cursor: pointer;color: white; text-decoration: underline;`} onClick={sendEmailVerification}><Alert variant="danger" css={css` color: white;`}>Verify your email address</Alert></UnstyledLink>}
        </div>
        <div className="col-sm">
          { intendedAuthMethodId ? 
            <CancelNewAuth cancel={cancel} /> : 
            <NewAuthOptions
              isGoogleAuth={isGoogleAuth}
              newEmailLogin={() => newLoginIntent(EMAIL_PASSWORD_PROVIDER_ID)}
              newGoogleLogin={() => newLoginIntent(GOOGLE_PROVIDER_ID)}
            />
          }
        </div>
      </div>
      { currentStep === UpdateLoginOption.UPDATE_LOGIN_FROM_GOOGLE ? 
        <UpdateLoginFromGoogle
          // @ts-ignore
          successfullyReauthed={!!currUserGoogleCredential}
          intendedAuthMethodId={intendedAuthMethodId}
          newAuthMethodSelected={newAuthMethodSelected}
          clickedAuthenticate={reauthenticate}
          register={register}
          handleSubmit={handleSubmit}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
        /> :
        null
      }
      {currentStep === UpdateLoginOption.UPDATE_LOGIN_FROM_EMAIL ?
        <UpdateLoginFromEmail
          // @ts-ignore
          intendedAuthMethodId={intendedAuthMethodId}
          newAuthMethodSelected={newAuthMethodSelected}
          reauthenticateEmail={reauthenticate}
          register={register}
          handleSubmit={handleSubmit}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
        /> : 
        null
      }
    </div>
  );
}

function CancelNewAuth(props) {
  return (
    <div><Link to="#" onClick={props.cancel}>Cancel</Link></div>
  )
}

function NewAuthOptions(props) {
  return (
    <div>
      <p><Link to="#" onClick={props.newEmailLogin}>
        {props.isGoogleAuth ? 'Switch to Email Login' : 'Update'}
      </Link></p>
      <p><Link to="#" onClick={props.newGoogleLogin}>
        {props.isGoogleAuth ? 'Switch Google Accounts' : 'Switch to Google login'}
      </Link></p>
    </div>
  );
}

function UpdateLoginFromGoogle(props) {
  return(
    <div>
      <div className="mt-3 font-weight-bold">
        {
          props.intendedAuthMethodId === GOOGLE_PROVIDER_ID ?
            'Switch Google Accounts' :
            'Switch to email login'
        }
      </div>
      <ol type="1" className="pl-20 mt-3">
        <li className="font-weight-bold pl-20">
          <UpdateLoginFromGoogleStep1
            successfullyReauthed={props.successfullyReauthed}
            clickedAuthenticate={props.clickedAuthenticate}
          />
        </li>
        <li className="font-weight-bold pl-20">
          <UpdateLoginFromGoogleStep2
            successfullyReauthed={props.successfullyReauthed}
            intendedAuthMethodId={props.intendedAuthMethodId}
            newAuthMethodSelected={props.newAuthMethodSelected}
            saveNewEmailClicked={props.saveNewEmailClicked}
            handleSubmit={props.handleSubmit}
            register={props.register}
            isUpdating={props.isUpdating}
            setIsUpdating={props.setIsUpdating}
          />
        </li>
      </ol>
      
    </div>
  );
}

function UpdateLoginFromGoogleStep1(props) {
  if (props.successfullyReauthed) {
    return (
      <div className="d-flex">
        <Check />
        <div className="font-weight-normal">
            &nbsp;Authenticated with current account
        </div>
      </div>
    )
  }
  return (
    <Button
      variant="outline-primary"
      style={{ margin: "12px 0px"}}
      onClick={props.clickedAuthenticate}
    >
      Authenticate Current Account
    </Button>
  )
}

function UpdateLoginFromGoogleStep2(props) {
  const onSubmit = (data) => {
    // update state
    props.setIsUpdating(true);

    // check passwords
    if (data.password !== data.confirmPassword) {
      toastError({
        message: "Oops! The passwords do not match."
      });
      props.setIsUpdating(false);
      return;
    }

    // update auth method
    let credential = firebase.auth.EmailAuthProvider.credential(data.email, data.password);
    return props.newAuthMethodSelected({ credential: credential, })
  }
  if (props.intendedAuthMethodId === GOOGLE_PROVIDER_ID) {
    return (
      <Button
        variant="outline-primary"
        style={{ margin: "12px 0px"}}
        onClick={props.newAuthMethodSelected}
        disabled={!(props.successfullyReauthed && !props.isUpdating)}
      >
        { props.isUpdating ?
              <span>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                Authenticating...
              </span> :
              <span>Authenticate New Account</span>
            }
      </Button>
    )
  } else if (props.intendedAuthMethodId === EMAIL_PASSWORD_PROVIDER_ID) {
    return (
      <div className="font-weight-normal">
        <Form onSubmit={props.handleSubmit(onSubmit)} className="UpdateInfoForm">
        <fieldset disabled={!props.successfullyReauthed}>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>New Email</Form.Label>
            <InputGroup className="mb-2">
                <Form.Control
                    type="text"
                    name="email"
                    ref={props.register}
                    placeholder={PLACEHOLDER_EMAIL}
                />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>New Password</Form.Label>
            <InputGroup className="mb-2">
                <Form.Control
                    type="password"
                    name="password"
                    ref={props.register}
                    placeholder="Set New Password"
                />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Confirm New Password</Form.Label>
            <InputGroup className="mb-2">
                <Form.Control
                    type="password"
                    name="confirmPassword"
                    ref={props.register}
                    placeholder="Confirm New Password"
                />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Button
          variant="primary"
          size="lg"
          type="submit"
          block
          className="my-3"
          disabled={props.isUpdating}>
            { props.isUpdating ?
              <span>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                Saving...
              </span> :
              <span>Save</span>
            }
        </Button>
        </fieldset>
        </Form>
      </div>
    )
  }
  return (
    <div>Oops! This login method is unsupported. Please reach out to {HELP_EMAIL}</div>
  )
}

function UpdateLoginFromEmail(props) {
  async function onSubmit(data) {
    // update state
    props.setIsUpdating(true);
    // check password for current account
    let res;
    res = await props.reauthenticateEmail({ args: { password: data.password }});
    if ('code' in res) { // returned an error
      return;
    }

    let credential;
    // update auth method if email
    if (props.intendedAuthMethodId === EMAIL_PASSWORD_PROVIDER_ID) {
      credential = firebase.auth.EmailAuthProvider.credential(data.newEmail, data.password);
    } 
    return props.newAuthMethodSelected({ credential: credential, currUserEmailCredential: res,})
  }

  return(
    <div>
      <div className="mt-3 font-weight-bold">
        {
          props.intendedAuthMethodId === GOOGLE_PROVIDER_ID ?
            'Switch to Google Login' :
            'Update email'
        }
      </div>
      
      <Form onSubmit={props.handleSubmit(onSubmit)} className="UpdateInfoForm">
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Current Password</Form.Label>
            <InputGroup className="mb-2">
                <Form.Control
                    type="password"
                    name="password"
                    ref={props.register}
                    placeholder="******"
                />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        {
          props.intendedAuthMethodId === EMAIL_PASSWORD_PROVIDER_ID ?
            <div>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>New Email</Form.Label>
                  <InputGroup className="mb-2">
                      <Form.Control
                          type="text"
                          name="newEmail"
                          ref={props.register}
                          placeholder={PLACEHOLDER_EMAIL}
                      />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Button
                variant="primary"
                type="submit"
                block
                className="my-3"
                disabled={props.isUpdating}>
                  { props.isUpdating ?
                    <span>
                      <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                      Saving...
                    </span> :
                    <span>Save</span>
                  }
              </Button>
            </div> : 
            <Button
              variant="outline-primary"
              type="submit"
              block
              className="my-3"
              disabled={props.isUpdating}
            >
            { props.isUpdating ?
              <span>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                Logging In...
              </span> :
              <span>Log In With Google</span>
            }
            </Button>
        }
        </Form>
    </div>
  );
}
export default Login;