/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'
import { FlowButton } from '../components/flowComponents'
import { FC_BLUE, FC_LIGHTER_BLUE } from '../emotionVariables'

export const FlowScoreButtons = ({ onSubmitFlowScore, presetFlowScore = null }) => {
  const [highlightedFlowScore, setHighlightedFlowScore] = useState(null)
  const displayedFlowScore = presetFlowScore ?? highlightedFlowScore

  const disabled = presetFlowScore !== null
  
  return (
    <div aria-label="Flow score" css={css`
      display: flex;
      width: 100%;
      height: 32px;
    `}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((flowScore, index) => {
        const borderColor = `#060E3B${index !== 9 && (displayedFlowScore === null || flowScore >= displayedFlowScore) ? '18' : '00'}`
        const isHighlighted = flowScore === displayedFlowScore
        const backgroundColor = displayedFlowScore !== null && flowScore <= displayedFlowScore ? FC_BLUE : FC_LIGHTER_BLUE
        return (
          <FlowButton
            fillAvailableWidth
            key={flowScore}
            customCss={css`
              border-radius: ${index === 0 ? '8' : '0'}px ${index === 9 ? '8px 8px' : '0px 0px'} ${index === 0 ? '8' : '0'}px;
              height: 100%;
              background-color: ${backgroundColor};
              &:hover {
                background-color: ${!disabled ? FC_BLUE : backgroundColor};
              }

              color: ${displayedFlowScore !== null && flowScore <= displayedFlowScore ? (isHighlighted ? 'white' :'rgba(255, 255, 255, 0.4)') : FC_BLUE};

              padding: 0px;
              border: none;
              border-image: ${`linear-gradient(to bottom, #0000 20%, ${borderColor} 21%, ${borderColor} 80%, #0000 81%) 1`};
              border-right: 1px solid;
              transition: background-color 0.1s;
            `}
            onClick={() => onSubmitFlowScore({ flowScore })}
            onMouseEnter={() => setHighlightedFlowScore(flowScore)}
            onMouseLeave={() => setHighlightedFlowScore(highlightedFlowScore => highlightedFlowScore === flowScore ? null : highlightedFlowScore)}
            disabled={disabled}
          >
            {flowScore}
          </FlowButton>
        )
      })}
    </div>
  )
}