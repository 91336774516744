/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {
  useAudioLevel,
  useAudioTrack,
  useLocalSessionId,
} from '@daily-co/daily-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FC_GREEN, FC_DARK_BLUE, FC_LIGHTER_BLUE, NEUTRAL_20 } from '../emotionVariables';
import { throttle } from 'lodash';
import Icon from '../daily/daily-components/Icon/Icon';
import EventContext from '../EventContext';

export const MicVolumeIndicator = ({ icon, backgroundColor = NEUTRAL_20, fill = FC_DARK_BLUE, hoverColor = FC_LIGHTER_BLUE, size = 40, forceMicOn = true }) => {
  const localSessionId = useLocalSessionId();
  const audioTrack = useAudioTrack(localSessionId);
  const [audioLevel, setAudioLevel] = useState(0)

  const setVolume = useCallback(throttle(setAudioLevel, 1000 / 30), [setAudioLevel])
  useAudioLevel(audioTrack?.persistentTrack, setVolume)

  const { toggleMic, isMicMuted } = useContext(EventContext);
  // Force mic status to unmuted while component is mounted
  useEffect(() => {
    if (isMicMuted && forceMicOn) { toggleMic() }
  }, [isMicMuted])

  return (
    <div css={css`display: flex; align-items: center; gap: 8px; height: {size}px;`}>
      <div css={css`
        background-color: ${FC_GREEN};
        background-image: linear-gradient(${backgroundColor}, ${backgroundColor});
        &:hover {
          background-image: linear-gradient(${hoverColor}, ${hoverColor});
        }
        background-size: 100% ${100 - (audioLevel * 100)}%;
        background-repeat: no-repeat;
        height: ${size}px;
      `}>
        <Icon type={icon} css={css`width: ${size}px; height: ${size} px;`} fill={fill} width={size} height={size} />
      </div>
    </div>
  )
}