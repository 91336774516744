/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NEUTRAL_90, FC_GREEN, FC_RED, FC_LIGHTER_BLUE, FC_DARK_BLUE, FC_LIGHTER_IRIS_BLUE } from '../../emotionVariables';
import { shouldShowClickAllow } from './Call/callState';
import { FlowButton, BUTTON_STYLES, SIZE_PRESETS } from '../../components/flowComponents';
import Icon, { TYPE_CLOSE_UNSET_FILL } from './Icon/Icon';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import EventContext from '../../EventContext';
import { useBackgroundBlurOrImage } from '../../ParticipantApp/useBackgroundBlurOrImage';

export enum SessionToastType {
  ERROR,
  INFO,
  SUCCESS,
}

const fadeIn = keyframes({
  'from': css`opacity: 0;`,
  'to': css`opacity: 1;`,
})

const getBackgroundColorForType = (type: SessionToastType) => {
  switch (type) {
    case SessionToastType.ERROR:
      return FC_RED;
    case SessionToastType.INFO:
      return NEUTRAL_90;
    case SessionToastType.SUCCESS:
      return FC_GREEN;
  }
}

const commonToastStyles = css`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #ffffff;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const mobileToastStyles = css`
  padding: 8px 16px 16px 16px;
  width: calc(100% - 30px);
  flex-direction: column;
  align-items: flex-start;
`;

const desktopToastStyles = css`
  padding: 12px 16px 12px 18px;
  width: calc(100% - 50px);
  flex-direction: row;
  align-items: start;
`;

const buttonContainerStyles = (mobileView) => css`
  margin-top: 4px;
  display: flex;
  flex-direction: ${mobileView ? 'column' : 'row'};
  gap: ${mobileView ? 4 : 8}px;
`;

const buttonStyles = (mobileView) => css`
  margin-top: ${mobileView ? '0' : '8px'};
  width: ${mobileView ? '100%' : 'auto'};
  color: #ffffff;
  border: 1px solid #ffffff;
  &:hover, &:focus {
    color: ${FC_DARK_BLUE};
  }
  ${SIZE_PRESETS.CHUNKY}
`;

const linkStyle = css`color: #fff; text-decoration: underline; &:hover { color: ${FC_LIGHTER_IRIS_BLUE} }`;

export const SessionToast = ({ style: sessionToastType, children, duration = Infinity }) => {
  const mobileView = useMediaQuery({query: '(max-width: 845px)'});
  const [show, setShow] = useState(true);

  useEffect(() => {
    let hideToast;
    if (duration !== Infinity) {
      hideToast = setTimeout(() => {
        setShow(false);
      }, duration);
    }
    return () => {
      if (hideToast) clearTimeout(hideToast);
    }
  }, [duration])

  if (!show) return null;

  return (
    <div css={css`
      ${commonToastStyles}
      position: ${mobileView ? 'fixed' : 'absolute'};
      bottom: ${mobileView ? 75 : 20}px;
      left: 25px;
      right: 25px;
      background-color: ${getBackgroundColorForType(sessionToastType)};
      display: flex;
      ${mobileView ? mobileToastStyles : desktopToastStyles}
    `}>
      <div css={css`flex-grow: 1;`}>
        {children}
      </div>
      <div onClick={() => setShow(false)} css={css`
        cursor: pointer;
        ${mobileView ? css`margin-right: -8px; margin-bottom: -8px; margin-left: auto;` : css`margin-right: -10px; margin-top: -10px;`}
      `}>
        <Icon
          // @ts-ignore
          type={TYPE_CLOSE_UNSET_FILL}
          css={css`
            fill: white;
            &:hover { fill: ${FC_LIGHTER_BLUE} }
            &:active { fill: ${FC_LIGHTER_BLUE} }
          `}
        /> 
      </div>
    </div>
  )
}

const TIME_BEFORE_CAMERA_REMINDER_MINUTES = 5
const TIME_BEFORE_CAMERA_REMINDER = TIME_BEFORE_CAMERA_REMINDER_MINUTES * 60 * 1000

export const SessionToastForCallState = ({ callState }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  // @ts-ignore
  const { callObject, isCameraMuted, toggleCamera } = useContext(EventContext)
  const [cameraOffTimestamp, setCameraOffTimestamp] = useState(null);
  const [dismissedForSession, setDismissedForSession] = useState(false);
  const { backgroundBlurOn, backgroundImageOn } = useBackgroundBlurOrImage(callObject)

  const callStateErrorType = callState.fatalError ? "fatal" : callState.camOrMicError ? "cam/mic" : "click allow"
  
  const reloadPage = () => {
    sendSegmentEvent(`Reloaded page due to ${callState.fatalError ? "fatal error" : "cam/mic error"}`, { callStateErrorType })
    window.location.reload()
  }
  const visitHelp = () => {
    sendSegmentEvent("Visited help for mic/cam error", { callStateErrorType })
    window.open("https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer")
  }

  const snooze = () => {
    //@ts-ignore
    setCameraOffTimestamp(Date.now());
    sendSegmentEvent("Snoozed camera off reminder");
  };

  const dismissPermanently = () => {
    setDismissedForSession(true);
    sendSegmentEvent("Dismissed camera off reminder for session");
  };

  const turnCameraOn = () => {
    toggleCamera(true);
    sendSegmentEvent("Turned camera on from reminder");
  };

  useEffect(() => {
    if (isCameraMuted) {
      //@ts-ignore
      setCameraOffTimestamp(Date.now());
    } else {
      setCameraOffTimestamp(null);
    }
  }, [isCameraMuted]);

  const isCameraOffPastThreshold = () => {
    if (!cameraOffTimestamp) return false;
    //@ts-ignore
    return (Date.now() - cameraOffTimestamp) >= TIME_BEFORE_CAMERA_REMINDER;
  };

  useEffect(() => {
    if (callState.fatalError) {
      sendSegmentEvent("Saw fatal call error toast", { callStateErrorType })
    } else if (callState.camOrMicError) {
      sendSegmentEvent("Saw cam/mic error toast", { callStateErrorType })
    } else if (shouldShowClickAllow(callState)) {
      sendSegmentEvent("Saw click allow camera/mic toast", { callStateErrorType })
    }
  }, [callState])

  const mobileView = useMediaQuery({query: '(max-width: 674px)'});

  if (callState.fatalError) {
    return (
      <SessionToast style={SessionToastType.ERROR}>
        Unfortunately, we ran into an error while trying to connect to the session. Please reload the page and try again or <a css={linkStyle} href="mailto:help@flow.club">contact support</a>.
        <FlowButton customCss={buttonStyles(mobileView)} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={reloadPage}>Reload</FlowButton>
      </SessionToast>
    )
  } else if (callState.camOrMicError) {
    return (
      <SessionToast style={SessionToastType.ERROR}>
        We weren't able to access your camera or mic. It's important to turn these on to have a good session. <a css={linkStyle} href="https://help.flow.club/en/articles/782337" target="_blank" rel="noopener noreferrer" onClick={() => sendSegmentEvent("Clicked on why", {toast: "Camera Error"})}>Why is this important?</a> Please give permission and refresh the page.
        <div css={buttonContainerStyles(mobileView)}>
          <FlowButton customCss={buttonStyles(mobileView)} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={reloadPage}>Reload</FlowButton>
          <FlowButton customCss={buttonStyles(mobileView)} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={visitHelp}>Help</FlowButton>
        </div>
      </SessionToast>
    )
  } else if (shouldShowClickAllow(callState)) {
    return (
      <SessionToast style={SessionToastType.INFO}>
        Please click "Allow" to give us permission to use your camera and microphone.
      </SessionToast>
    )
  } else if (callState.videoProcessorError) {
    return (
      <SessionToast style={SessionToastType.ERROR}>
        We had to turn your camera off due to high CPU usage.
        <br/>
        Close any unused browser tabs or applications.
        {(backgroundImageOn || backgroundBlurOn) &&
        <>
        <br/>
        {backgroundImageOn ? ' Consider turning off the background image and turn your camera on when ready.' : backgroundBlurOn ? ' Consider turning off background blur and turn your camera on when ready.' : 'Turn your camera on when ready.'}
        </>}
      </SessionToast>
    )
  } else if (isCameraMuted && isCameraOffPastThreshold() && !dismissedForSession) {
    return (
      <SessionToast style={SessionToastType.INFO}>
        Your camera has been off for {TIME_BEFORE_CAMERA_REMINDER_MINUTES} minutes. If you're ready, turning it back on can help with focus and supporting others in the session. It's fine to keep it off if that works best for you. <a css={linkStyle} href="https://help.flow.club/en/articles/782337" target="_blank" rel="noopener noreferrer" onClick={() => sendSegmentEvent("Clicked on why", {toast: "Turn Camera on Reminder"})}>Why?</a>
        <div css={buttonContainerStyles(mobileView)}>
          <FlowButton customCss={buttonStyles(mobileView)} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={snooze}>Remind me in {TIME_BEFORE_CAMERA_REMINDER_MINUTES} min</FlowButton>
          <FlowButton customCss={buttonStyles(mobileView)} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={turnCameraOn}>Turn Camera On</FlowButton>
          <FlowButton customCss={buttonStyles(mobileView)} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} onClick={dismissPermanently}>Stay camera off for this session</FlowButton>
        </div>
      </SessionToast>
    )
  } else {
    return null;
  }

}