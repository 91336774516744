import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Container,
  Spinner,
} from 'react-bootstrap'
import { UserContext } from '../UserProvider';
import { loadStripe } from '@stripe/stripe-js';
import { useCreateCheckoutSession } from '../fetch/endpoints';

const isProd = process.env.REACT_APP_PROJECT_ID === 'flowclub-21'
const stripePublishableKey =  isProd ? 'pk_live_51J2J1oE0xMKXSRCd2PAwCHavKCBAtOhPpBhRhI70Tck0rmlKbmcADOjJW1yzOdp2K7BAURyrMBtkuhvU17dFp2SP0042556Df5' : 'pk_test_51J2J1oE0xMKXSRCdEE7zpmfTffa3nS9oYljkFEnZJG6Yy0tXnJUtjTSC7OYuongmtr1a9fafV0eIsiFkZIXMLBYJ00YMHfZXiQ'
const stripePromise = loadStripe(stripePublishableKey)

const Membership = () => {
  const { user } = useContext(UserContext)
  const { period } = useParams()
  const subscriptionPeriod = period ? period : "monthly"
  const [loading, setLoading] = useState(false)
  const { performFetch: getStripeCheckoutSessionId, fetching } = useCreateCheckoutSession()
  useEffect(() => {
    const createSession = async () => {
      if (!loading & !fetching && user) {
        setLoading(true)
        const stripe = await stripePromise;
        const { result: sessionId } = await getStripeCheckoutSessionId({ period: subscriptionPeriod })
        stripe.redirectToCheckout({ sessionId })
      }
    }
    createSession()
  }, [user, subscriptionPeriod, loading])
  return (
    <Container fluid className="text-center FlowClub-Login">
      <h1 className="FlowClub-Title pt-5">Flow Club</h1>
      <Spinner animation="grow" variant="primary" className="mt-5" />
      <h2 className="mt-5">
        Setting up secure Stripe checkout...
      </h2>
    </Container>
  );
}

export default Membership