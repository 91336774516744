
import { useContext, useState } from 'react';
import { useChimeOnWorkingTimeEnd } from './useChimeOnWorkingTimeEnd';
import { useGongOnBreakTimeEnd } from './useGongOnBreakTimeEnd';
import { useSessionTimer } from './useSessionTimer';
import EventContext from '../EventContext';
import { UserContext } from '../UserProvider';
import { JoinedSessionView } from './JoinedSessionView';
import JoinScreen from '../daily/daily-components/JoinScreen/JoinScreen';
import { useDailyCall } from './useDailyCall';
import { useHistory } from 'react-router';
import { useMuteOnWorkingTimeStart } from './useMuteOnWorkingTimeStart';
import { getTimerText } from './Timer';

export const ActiveSession = ({ event, participants, badgeMembershipsByUser, demoSession = false, chatHistory, chatMessageAttachments, refetchEventData }) => {
  const { user } = useContext(UserContext)
  const [hidingSelfView, setHidingSelfView] = useState(localStorage.getItem('hidingSelfView') === 'true')
  const [mirrorSelfView, setMirrorSelfView] = useState(!(localStorage.getItem('turnOffMirrorSelfView') === 'true'))
  const [ hiddenUserVideos, setHiddenUserVideos ] = useState([])

  const { start: startTime, sessionName, agenda: stages, automutingDisabled } = event

  const history = useHistory()
  const leaveSession = (error = null) => {
    if (!demoSession) {
      history.push(`/s/${event.id}/post-session/${error !== null ? `?error=${error}` : ''}`)
    } else {
      history.push("/")
    }
  }

  const sessionTimerData = useSessionTimer({
    startTime: startTime,
    stages: stages,
    active: true
  })
  const {
    activeWorkingTime,
    sessionWorkingTimeStarted,
    sessionStarted,
    sessionWorkingTimeFinished,
    sessionFinished,
    timeElapsed,
    cumulativeStageDurations,
    currentStage,
  } = sessionTimerData

  const activeUserParticipant = (user !== null && participants[user.uid] !== undefined) ? participants[user.uid] : null
  const { goals: existingGoals } = activeUserParticipant ?? { goals: [] }

  const { text: timerText, textColor: timerTextColor } = getTimerText(sessionStarted, timeElapsed, cumulativeStageDurations, currentStage, sessionFinished, activeWorkingTime)

  const userIsHost = user.uid === event.hostId

  const [newChatMessages, setNewChatMessages] = useState(false)

  const {
    callObject,
    showCall: enteredCall,
    callInitializationError,
    enableCallButtons,
    startLeavingCall,
    startEnteringCall,
    dailyMeetingToken,
    roomUrl,

    toggleMusic,
    toggleCamera,
    toggleMic,
    muteMic,
    isCameraMuted,
    isMicMuted,
    isMusicMuted,
    isSharingScreen,

    backgroundBlurOn,
    onToggleBackgroundBlur,
    backgroundImageOn,
    onToggleBackgroundImage,

    videosAreaRef
  } = useDailyCall({ event, sessionWorkingTimeFinished, leaveSession, demoSession })

  useMuteOnWorkingTimeStart(activeWorkingTime, muteMic, automutingDisabled)
  useChimeOnWorkingTimeEnd(activeWorkingTime)
  useGongOnBreakTimeEnd(activeWorkingTime, sessionWorkingTimeStarted)
  
  return (
    <EventContext.Provider value={{
      demoSession,

      event,
      participants,
      chatHistory,
      chatMessageAttachments,
      activeUserParticipant,
      refetchEventData,
      
      activeWorkingTime,
      sessionWorkingTimeStarted,
      sessionWorkingTimeFinished,
      newChatMessages,
      setNewChatMessages,
      badgeMembershipsByUser,

      callObject,
      callInitializationError,
      enableCallButtons,
      startLeavingCall,
      startEnteringCall,
      dailyMeetingToken,
      roomUrl,

      toggleMusic,
      toggleCamera,
      toggleMic,
      isCameraMuted,
      isMicMuted,
      isMusicMuted,
      isSharingScreen,

      backgroundBlurOn,
      onToggleBackgroundBlur,
      backgroundImageOn,
      onToggleBackgroundImage,
      hidingSelfView,
      setHidingSelfView,
      mirrorSelfView,
      setMirrorSelfView,
      hiddenUserVideos,
      setHiddenUserVideos,

      videosAreaRef
    }}>
      {enteredCall ?
        <JoinedSessionView
          sessionTimerData={sessionTimerData}
          timerText={timerText}
          timerTextColor={timerTextColor}
          existingGoals={existingGoals}
          stages={stages}
          sessionName={sessionName}
          userIsHost={userIsHost}
        /> :
        <JoinScreen
          sessionTimerData={sessionTimerData}
          timerText={timerText}
          timerTextColor={timerTextColor}
          stages={stages}
          sessionName={sessionName}
        />}
    </EventContext.Provider>
  )
}

