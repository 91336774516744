
import { useContext } from 'react'
import { Button } from 'react-bootstrap'

import { UserContext } from '../../UserProvider'
import { HostStatus } from "../../../functions/shared/constants"
import { Banner } from '../../shared/components/banner'
import { HostAvatar } from "./avatar"
import './host-banner.scss'
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider'
import { Link } from 'react-router-dom'
import { LinkStyledText } from '../flowComponents'
import { useUpdateUser } from '../../fetch/endpoints'

export const HostBanner = () => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: updateUser } = useUpdateUser()

  const { user } = useContext(UserContext)
  // @ts-ignore:next-line
  const show = user && user.hostStatus === HostStatus.QUALIFIED
  const hideBanner = async () => {
    if (user) {
      await updateUser({ updateObject: { hostStatus: HostStatus.NOT_NOW } })
    }
  }
  if (!show) return "";
  return (
    <Banner onClose={() => hideBanner()}>
      <div className="flex-banner host-banner justify-content-md-center">
        <h5 className="small-screen-title">
          You've unlocked hosting!
        </h5>
        <div className="banner-left">
          <HostAvatar className="avatar" />
        </div>
        <div className="banner-right">
          <h5 className="large-screen-title">
            You've unlocked hosting!
          </h5>
          <div className="my-2">
            Hosting is now possible for dedicated members like you.
          </div>
          <div className="host-actions">
            <Link to={"/host-onboarding/"}>
              <Button className="mr-3" variant="outline-primary" onClick={() => sendSegmentEvent("Host Onboarding Link Clicked (Hosting Unlocked Banner)")}>
                Become a Host
              </Button>
            </Link>
            <LinkStyledText onClick={hideBanner}>
              Stop seeing this
            </LinkStyledText>
          </div>
        </div>
      </div>
    </Banner>
  )
}