/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC_LIGHTER_IRIS_BLUE } from "../emotionVariables";
import { FullScreenSidebar, SIDEBAR_COLORS } from "./FullScreenSidebar";


export const QUOTE_TYPES = {
  MEDIA: "media",
  USER: "user",
}

const mediaQuotes = [
  {
    text: "The latest work-from-home craze.",
    source: "Wall Street Journal",
  },
  {
    text: "Combining the freedom of remote work with the extra focus that comes with someone else working nearby",
    source: "New York Times",
  },
  {
    text: "It's like I'm an electric car and when I join these sessions, I plug into the recharge thing and I get stuff done.",
    source: "Wall Street Journal",
  },
]

const userQuotes = [
  {
    text: "Sessions help me stay focused and productive by providing accountability, shared energy, and support.",
    source: "Erin R., Freelance Writer",
  },
  {
    text: "Just having someone \"next\" to me doing their own thing is enough for me to focus. I've achieved more in the few months I've been with Flow Club than I have in years.",
    source: "Ximena V., Graduate student"
  }
]

const frameCss = css`
  align-items: center;
  align-self: stretch;
  background-color: #ffffff1a;
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
`
const textWrapperCss = css`
  align-self: stretch;
  color: #ffffff;
  font-family: "Red Hat Display-Italic", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
`

const sourceCss = css`
  align-self: stretch;
  color: #ffffff;
  font-family: "Red Hat Display-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
`

const sectionCss = css`
  width: 100%;
  background: ${FC_LIGHTER_IRIS_BLUE};
  justify-content: center;
`

const contentCss = css`
  max-width: 768px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 32px;
  justify-content: center;
`

export const QuotesSidebar = ({ quoteType = QUOTE_TYPES.MEDIA }) => {
  const quotes = quoteType === QUOTE_TYPES.MEDIA ? mediaQuotes : userQuotes;
  return (
    <FullScreenSidebar color={SIDEBAR_COLORS.BLUE} soloContent={false}>
      {quotes.map(({ text, source }, index) => (
        <div css={frameCss} key={index}>
          <div css={textWrapperCss}>{text}</div>
          <div css={sourceCss}>- {source}</div>
        </div>
      ))}
    </FullScreenSidebar>
  );
};

export const QuotesSection = ({ quoteType = QUOTE_TYPES.MEDIA }) => {
  const quotes = quoteType === QUOTE_TYPES.MEDIA ? mediaQuotes : userQuotes;
  return (
    <div css={sectionCss}>
        <div css={contentCss} >
      {quotes.map(({ text, source }, index) => (
        <div css={frameCss} key={index}>
          <div css={textWrapperCss}>{text}</div>
          <div css={sourceCss}>- {source}</div>
        </div>
      ))}
      </div>
    </div>
  );
};