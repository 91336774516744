/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Modal } from "react-bootstrap"
import { LoginSignup } from '../../auth/LoginSignup'

export const LoginSignupModal = ({ onSuccess, onHide, showLoginModeLink = false, startInLoginMode = false, showBookingRelatedText = true, showCloseButton = true }) => {
  onSuccess = onSuccess ?? onHide

  return (
    <Modal
      onHide={onHide}
      show
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={showCloseButton} />
      <Modal.Body>
        <LoginSignup onSuccess={onSuccess} showLoginModeLink={showLoginModeLink} startInLoginMode={startInLoginMode} showBookingRelatedText={showBookingRelatedText} />
      </Modal.Body>
    </Modal>
  )
}