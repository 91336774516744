/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createContext, useContext, useState } from 'react'
import dayjs from 'dayjs'
import { UserContext } from '../../UserProvider'
import { CircledCaretButton, LoadingIndicator } from '../flowComponents';
import { groupObjectsByProperty } from '../../../functions/shared/helpers';
import { SessionDetailsPopup, SessionDetailsPopupFullScreen, usePopupData } from './SessionDetailsPopup';
import { useMediaQuery } from 'react-responsive';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { CalendarView } from '../../CalendarView/CalendarView';
import { DayColumnWrapper } from './DayColumnWrapper';
import { QuickCreate } from './QuickCreate';
import { Col, Container, Row } from 'react-bootstrap';
import { HostIncentiveProgress, useHostIncentiveProgress } from '../../HostIncentiveProgress';
import { AnimatedSlidingWrapper } from '../AnimatedSlidingWrapper';
import { useAutomaticallyFetch } from '../../fetch/helpers';
import { useGetEventsForUser } from '../../fetch/endpoints';
import { USER_SESSION_LIST_MODES, DATE_FORMAT } from '../../../functions/shared/constants';
import { ExclusiveMouseoverWrapperGroup } from '../../Lounge/ExclusiveMouseoverWrapper';

export const FancySessionTimeSelectorContext = createContext(null)

export const FancySessionTimeSelector = ({ eventTypes, selectedHost, adminModeEnabled, onDemandCreationInfo, claimableSessions, creatableSessionVisibilities }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { user } = useContext(UserContext)
  const { result: alreadyBookedSessions, fetching: alreadyBookedSessionsLoading, performFetch: fetchUpcomingSessions } = useAutomaticallyFetch(useGetEventsForUser, { listMode: USER_SESSION_LIST_MODES.UPCOMING, userId: selectedHost.id, pageSize: 100 }, { transform: result => result.events, dependencies: [selectedHost.id] })
  const refetchUpcomingSessions = () => fetchUpcomingSessions({ pageNum: 0, listMode: USER_SESSION_LIST_MODES.UPCOMING, hostId: selectedHost.id })
  const alreadyBookedSessionsByDay = groupObjectsByProperty(alreadyBookedSessions ?? [], session => dayjs(session.start).tz(selectedHost.timezone).format(DATE_FORMAT))
  const claimableSessionsByDay = groupObjectsByProperty(claimableSessions ?? [], session => dayjs(session.start).tz(selectedHost.timezone).format(DATE_FORMAT))


  const hostProgressData = useHostIncentiveProgress()

  const mobileView = useMediaQuery({query: '(max-width: 999px)'})

  const today = dayjs().tz(selectedHost.timezone).startOf('day')
  const [weeksOffset, setWeeksOffset] = useState(0)
  const dates = []
  const weeks = []
  for (let week = 0; week < 4; week++) {
    for (let i = 0; i < 7; i++) {
      const newDate = today.add(i + week * 7, 'day').startOf('day')
      dates.push(newDate)
    }
    weeks.push(dates.slice(-7))
  }

  const [selectedSessionTime, setSelectedSessionTime] = useState(null)
  const selectSessionTime = (date) => {
    setSelectedSessionTime(date)
    sendSegmentEvent("Session Time Selected", { date })
  }
  const popupClosed = () => {
    setSelectedSessionTime(null)
    sendSegmentEvent("Session Details Popup Closed")
  }
  const floatingUIData = usePopupData()
  const { reference: selectedHourBlockRef } = floatingUIData
  
  const visibleEventTypesForCreatingHost = eventTypes.filter(eventType => eventType.active)

  const confirmDetailData = {
    sessionStart: selectedSessionTime,
    eventTypes: visibleEventTypesForCreatingHost,
    user,
    adminModeEnabled,
    selectedHost,
    onDemandCreationInfo
  }

  return (
    <div css={css`position: relative;`}>
      <FancySessionTimeSelectorContext.Provider value={{
        alreadyBookedSessionsByDay,
        claimableSessionsByDay,
        selectedSessionTime,
        selectSessionTime,
        selectedHourBlockRef,
        selectedHost,
        onDemandCreationInfo,
        refetchUpcomingSessions,
        creatableSessionVisibilities
      }}>
        <Container>
          <div css={css`
            display: flex;
            justify-content: space-between;
            flex-direction: ${mobileView ? 'column' : 'row'};
          `}>
            <div>
              <div>
                <small className="muted">All times in {dayjs().tz(selectedHost.timezone).format("z (zzz)")}</small>
              </div>
              {(mobileView && hostProgressData.displayProgress) &&
                <div css={css`margin-top: 16px;`}>
                  <HostIncentiveProgress progressData={hostProgressData} displayExplanatoryText={false} />
                </div>
              }
              {onDemandCreationInfo !== null && onDemandCreationInfo.creationAllowedAt !== null &&
                <QuickCreate
                  selectSessionTime={() => selectSessionTime(onDemandCreationInfo.creationAllowedAt)}
                  onDemandCreationInfo={onDemandCreationInfo}
                />
              }
            </div>
            {(!mobileView && hostProgressData.displayProgress) &&
              <HostIncentiveProgress progressData={hostProgressData} displayExplanatoryText={false} />
            }
          </div>
        </Container>
        <ExclusiveMouseoverWrapperGroup>
          {!mobileView ?
            <div css={css`position: relative;`}>
              <AnimatedSlidingWrapper currentItemIndex={weeksOffset}>
                {weeks.map(datesInWeek =>
                  <CalendarView dates={datesInWeek} DayColumnComponent={DayColumnWrapper} rowHeight={800} />
                )}
              </AnimatedSlidingWrapper>
              <WeekSelectionArrows weeksOffset={weeksOffset} setWeeksOffset={setWeeksOffset} />
            </div>
            :
            <CalendarView dates={dates} DayColumnComponent={DayColumnWrapper} rowHeight={800} />
          }
        </ExclusiveMouseoverWrapperGroup>
        {(alreadyBookedSessionsLoading || eventTypes.length === 0 || onDemandCreationInfo === null) && <LoadingOverlay />}
        {!mobileView && <ManualSessionCreationInfo />}
        {selectedSessionTime !== null &&
          (!mobileView ?
            <SessionDetailsPopup closePopup={popupClosed} floatingUIData={floatingUIData} confirmDetailData={confirmDetailData} /> :
            <SessionDetailsPopupFullScreen confirmDetailData={confirmDetailData} closePopup={popupClosed} />)
        }
      </FancySessionTimeSelectorContext.Provider>
    </div>
  )
}

const ManualSessionCreationInfo = () => (
  <Container>
    <Row>
      <Col>
        <p>
          Want to schedule sessions in bulk, further in the future, or at times not listed here? <a href="mailto:hosts@flow.club">
            Just email us at hosts@flow.club and we'll take care of it!
          </a>
        </p>
      </Col>
    </Row>
  </Container>
)

const LoadingOverlay = () => (
  <div css={css`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100%;
    background-color: #ffffff80;
    display: flex;
    justify-content: center;
    align-items: center;
  `}>
    <LoadingIndicator />
  </div>
)

const WeekSelectionArrows = ({ weeksOffset, setWeeksOffset }) => {
  const leftArrowEnabled = weeksOffset > 0
  const rightArrowEnabled = weeksOffset < 3
  
  const sendSegmentEvent = useSendSegmentEvent()
  const leftArrowPressed = () => {
    if (leftArrowEnabled) {
      sendSegmentEvent("Week Decremented", { movingToWeek: weeksOffset - 1 })
      setWeeksOffset(weeks => weeks - 1)
    }
  }

  const rightArrowPressed = () => {
    if (rightArrowEnabled) {
      sendSegmentEvent("Week Incremented", { movingToWeek: weeksOffset + 1 })
      setWeeksOffset(weeks => weeks + 1)
    }
  }

  return (
    <div css={css`
      position: absolute;
      top: 6px;
      left: 0px;
      width: 100%;

      display: flex;
      justify-content: center;
      pointer-events: none;
    `}>
      <div css={css`
        width: 880px;
        display: flex;
        justify-content: space-between;
      `}>
        <div css={css`display: flex; align-items: center; pointer-events: auto;`}>
          <CircledCaretButton enabled={leftArrowEnabled} onClick={leftArrowPressed} leftFacing/>
        </div>
        <div css={css`display: flex; align-items: center; pointer-events: auto;`}>
          <CircledCaretButton enabled={rightArrowEnabled} onClick={rightArrowPressed}/>
        </div>
      </div>
    </div>
  )
}