/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useMediaQuery } from 'react-responsive';

import ComputerWorld from '../images/computerworld.png'
import FastCompany from '../images/fastcompany.png'
import NYTimes from '../images/nytimes.png'
import Protocol from '../images/protocol.png'
import Slate from '../images/slate.png'
import Techcrunch from '../images/techcrunch.png'

export const Logos = () => {
  const mobileView = useMediaQuery({ query: '(max-width: 896px)' })

  const logoRowStyle = css`
    display: flex;
    flex-direction: ${mobileView ? 'column' : 'row'};
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: ${mobileView ? '32px' : '64px'};
  `
  const logoStyle = css`
    height: 42px;
  `
  return (
    <div css={css`
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 32px;
    `}>
      <div>
        Featured in these publications
      </div>
      <div css={logoRowStyle}>
        <img css={logoStyle} src={Techcrunch} alt="Computer World" />
        <img css={logoStyle} src={Slate} alt="Slate" />
        <img css={logoStyle} src={Protocol} alt="Protocol" />
        <img css={logoStyle} src={ComputerWorld} alt="Computer World" />
      </div>
      <div css={logoRowStyle}>
        <img css={logoStyle} src={NYTimes} alt="New York Times" />
        <img css={logoStyle} src={FastCompany} alt="Fast Company" />
      </div>
    </div>
  )
}



