/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Modal } from '../../components/flowComponents';
import { FlowScoreInput } from '../../PostSession/PostSessionFeedbackScreen';
import { EventCard } from '../../Sessions/EventCard';

export const FlowScoreModal = ({ session, onHide = () => {}, onSuccess = (flowScore) => {}}) => {
  const [ localFlowScore, setLocalFlowScore ] = useState(null)

  const onSuccessWrapper = (flowScore) => {
    setLocalFlowScore(flowScore)
    onSuccess(flowScore)
  }
  return (
    <Modal onClose={onHide} dismissOnClickOutside={localFlowScore !== null}>
      <div css={css`

        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
      `}>
        <div>
          <FlowScoreInput eventId={session.id} updateUserFlowScore={true} flowScore={localFlowScore} callback={onSuccessWrapper} />
        </div>
        <div>
          <EventCard event={session} isFirst={true} sessionIsPast={true} />
        </div>
      </div>
    </Modal>
  )
}
