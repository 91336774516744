/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { FlowClubRadioPlayer } from "../ParticipantApp/MusicSharing/FlowClubRadioPlayer";
import { SharedMusicPlaylistInfo } from '../ParticipantApp/MusicSharing/MusicSharing';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { Card } from '../components/flowComponents';

import Icon, { TYPE_MUSIC_SHARE_ON_UNSET_FILL } from '../daily/daily-components/Icon/Icon';
import { FC_DARK_BLUE,  FC_LIGHTER_BLUE, FC_LIGHT_BLUE } from '../emotionVariables';
import { TooltipWrapper } from '../components/TooltipWrapper';

const PlayPauseMusicButton = ({ isMusicActive, musicButtonClicked }) => {

  const Wrapper = useMemo(() => ({ children }) => <TooltipWrapper TooltipContents={isMusicActive ? 'Stop music' : 'Start music'}>{children}</TooltipWrapper>
  , [isMusicActive])

  return (
    <Wrapper>
      <div onClick={musicButtonClicked} css={css`
        width: 44px;
        height: 44px;

        background-color: ${FC_LIGHTER_BLUE};
        border-radius: 100%;
        cursor: pointer;
        &:hover, &:active { background-color: ${FC_LIGHT_BLUE}; }

        ${isMusicActive &&
          css`animation: ${keyframes({
            'from': css`transform: rotate(0deg);`,
            'to': css`transform: rotate(360deg);`
          })} 5s linear infinite;
        `}
      `}>
        <Icon type={TYPE_MUSIC_SHARE_ON_UNSET_FILL} css={css`
          fill: ${FC_DARK_BLUE};
        `} />
      </div>
    </Wrapper>
  )
}

export const LoungeRadioPlayer = ({ hostedMusicPlaybackData }) => {
  const [isMusicMuted, setIsMusicMuted] = useState(true)
  const sendSegmentEvent = useSendSegmentEvent()

  const playlistDisplayInfo = hostedMusicPlaybackData === undefined ? {} : {
    text: (hostedMusicPlaybackData.playlist !== undefined && hostedMusicPlaybackData.playlist.name !== undefined) ?
      `Flow Club Radio: ${hostedMusicPlaybackData.playlist.name}` :
      "Flow Club Radio",
    link: null
  }
  const toggleMusic = () => {
    sendSegmentEvent("Toggled radio in lounge", { togglingTo: !isMusicMuted})
    setIsMusicMuted(!isMusicMuted)
  }

  return (
    <Card customCss={css`
      display: flex;
      background-color: white;
      align-items: center;
      justify-content: center;
      gap: 0px 10px;

      padding: 12px;
    `}>
      <PlayPauseMusicButton isMusicActive={!isMusicMuted} musicButtonClicked={toggleMusic} />
      <SharedMusicPlaylistInfo playlistDisplayInfo={playlistDisplayInfo} />
      {!isMusicMuted && <FlowClubRadioPlayer hostedMusicPlaybackData={hostedMusicPlaybackData} />}
    </Card>
  )
}