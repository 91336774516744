/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSpring, animated, config } from '@react-spring/web'
import { createContext, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const randomInRange = ({ min = 0, max, roundToInt = false }) => {
  const randomValue = min + Math.random() * (max - min)
  return roundToInt ? Math.round(randomValue) : randomValue
}

const RadialParticle = ({ length, rotation, width, radius, rootColor, onAnimationFinished, animationConfig }) => {
  const style = useSpring({
    from: { opacity: 1, transform: `rotate(${rotation}deg) translateX(0px)` },
    to: { opacity: 0, transform: `rotate(${rotation}deg) translateX(${radius}px)` },
    config: animationConfig,
    onRest: onAnimationFinished
  })

  return (
    <div css={css`
      position: absolute;
      left: 0px;
      top: 0px;
    `}>
      <animated.div style={style}>
        <div css={css`
          width: ${length}px;
          height: ${width}px;
          border-radius: 100%;
          background-color: ${rootColor};
        `} />
      </animated.div>
    </div>
  )
}

const CrossParticle = ({ length, rotation, width, rootColor, position, emissionDelay, onAnimationFinished }) => {
  const { x, y } = position;

  const style = useSpring({
    from: { opacity: 0 },
    to: [
      { opacity: 1, },
      { opacity: 0, },
    ],
    config: config.default,
    onRest: onAnimationFinished,
    delay: emissionDelay
  })

  const rotationWrapperStyle = useSpring({
    from: { transform: `rotate(0deg)` },
    to: 
      { transform: `rotate(${90 + rotation}deg)` }
    ,
    config: { duration: 1500 },
    delay: emissionDelay
  })

  return (
    <div css={css`
      position: absolute;
      left: ${x}px;
      top: ${y}px;
    `}>
      <animated.div style={rotationWrapperStyle}>
        <div>
          <animated.div style={style}>
            <div css={css`
              width: ${length}px;
              height: ${width}px;
              border-radius: 100%;
              background-color: ${rootColor};
              transform: translateX(-${0}px) translateY(${width}px);
            `} />
          </animated.div>
          <animated.div style={style}>
            <div css={css`
              width: ${length}px;
              height: ${width}px;
              border-radius: 100%;
              background-color: ${rootColor};
              transform: rotate(${0 + 90}deg);
            `} />
          </animated.div>
        </div>
      </animated.div>
    </div>
  )
}

const defaultAreaParticleData = {
  area: { minX: 0, maxX: 0, minY: 0, maxY: 0 },
  particleCountRange: { min: 6, max: 15 },
  particleLengthRange: { min: 10, max: 30 },
  width: 5,
  rootColor: 'white',
  emissionDuration: 0,
  emissionTimeVarianceFactor: 2
}
const buildNewAreaParticleSet = (effectData) => {
  effectData = { ...defaultAreaParticleData, ...effectData }
  const { area, particleCountRange, particleLengthRange, width, rootColor, emissionDuration, emissionTimeVarianceFactor } = effectData
  const particleCount = Math.floor(randomInRange({ ...particleCountRange, roundToInt: true }))

  const particleSet = []
  for (let i = 0; i < particleCount; i++) {
    const rotation = randomInRange({ max: 360 })
    const particleLength = randomInRange(particleLengthRange)
    const position = {
      x: randomInRange({ min: area.minX, max: area.maxX }),
      y: randomInRange({ min: area.minY, max: area.maxY })
    }
    const delayVariance = randomInRange({ min: (emissionDuration / particleCount) * emissionTimeVarianceFactor * -1, max: (emissionDuration / particleCount) * emissionTimeVarianceFactor })
    particleSet.push({ length: particleLength, rotation, width, rootColor, position, emissionDelay: (emissionDuration / particleCount) * i + delayVariance })
  }
  return particleSet
}

const defaultRadialParticleData = {
  position: { x: 0, y: 0 },
  particleCountRange: { min: 6, max: 15 },
  particleLengthRange: { min: 10, max: 30 },
  angleVarianceFactor: 1,
  width: 5,
  radius: 150,
  rootColor: 'white',
  animationConfig: config.default
}
const buildNewRadialParticleSet = (effectData) => {
  effectData = { ...defaultRadialParticleData, ...effectData }
  const { particleCountRange, particleLengthRange, angleVarianceFactor, width, radius, rootColor, animationConfig } = effectData
  const particleCount = Math.floor(randomInRange({ ...particleCountRange, roundToInt: true }))

  const particleSet = []
  const baseAngle = randomInRange({ max: 360 })
  for (let i = 0; i < particleCount; i++) {
    const particleLength = randomInRange(particleLengthRange)
    const angleVariance = randomInRange({ min: (360 / particleCount) * angleVarianceFactor * -1, max: (360 / particleCount) * angleVarianceFactor })
    particleSet.push({ length: particleLength, rotation: baseAngle + angleVariance + (360 / particleCount * i), width, radius, rootColor, animationConfig })
  }
  return particleSet
}

const buildNewParticleSet = (effectData, mode) => {
  if (mode === "radial") {
    const adjustedPosition = adjustPosition(effectData)
    return { mode, position: adjustedPosition, particles: buildNewRadialParticleSet(effectData) }
  } else if (mode === "withinArea") {
    return { mode, particles: buildNewAreaParticleSet(effectData) }
  }
}

const adjustPosition = (effectData = {}) => {
  effectData = { ...defaultRadialParticleData, ...effectData }
  const { x, y } = effectData.position
  const averageWidth = (effectData.particleLengthRange.min + effectData.particleLengthRange.max) / 2
  return { x: x - averageWidth / 2, y: y - effectData.width }
}

const ParticlesContext = createContext(null)

export const ParticlesManager = ({ children }) => {
  const [activeParticles, setActiveParticles] = useState([])
  const emitParticleSet = (effectData = {}, options = {}) => {
    const { mode = "radial" } = options
    const elementKey = uuidv4()
    const particleSetData = buildNewParticleSet(effectData, mode)
    const removeParticleSet = () => setActiveParticles(activeParticles => activeParticles.filter(particleSet => particleSet.key !== elementKey))
    const ParticleSetComponent = mode === "radial" ? RadialParticleSet : AreaParticleSet
    const newParticleSet = <ParticleSetComponent key={elementKey} particleSetData={particleSetData} onAnimationFinished={removeParticleSet} />

    setActiveParticles(activeParticles => [
      ...activeParticles,
      newParticleSet
    ])
  }

  return (
    <>
      <div css={css`
        position: fixed;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        z-index: 2;
      `}>
        {activeParticles}
      </div>
      <div css={css`isolation: isolate;`}>
        <ParticlesContext.Provider value={{ emitParticleSet }}>
          {children}
        </ParticlesContext.Provider>
      </div>
    </>
  );
}

const RadialParticleSet = ({ particleSetData, onAnimationFinished }) => {
  const { position, particles } = particleSetData
  let { x, y } = position;

  const particlesFinished = useRef(0)
  const particleAnimationFinished = () => {
    particlesFinished.current++
    if (particlesFinished.current >= particles.length) {
      onAnimationFinished()
    }
  }

  return (
    <div css={css`
      position: absolute;
      left: ${x}px;
      top: ${y}px;
    `}>
      {particles.map(({ length, rotation, width, radius, rootColor, animationConfig }, index) =>
        <RadialParticle key={index} length={length} rotation={rotation} width={width} radius={radius} rootColor={rootColor} onAnimationFinished={particleAnimationFinished} animationConfig={animationConfig} />
      )}
    </div>
  )
}

const AreaParticleSet = ({ particleSetData, onAnimationFinished }) => {
  const { particles } = particleSetData

  const particlesFinished = useRef(0)
  const particleAnimationFinished = () => {
    particlesFinished.current++
    if (particlesFinished.current >= particles.length) {
      onAnimationFinished()
    }
  }
  
  return (
    <div css={css`
      position: absolute;
      left: 0px;
      top: 0px;
    `}>
      {particles.map(({ length, rotation, width, rootColor, position, emissionDelay }, index) => {
        const adjustedPosition = { x: position.x - length / 2, y: position.y - width }
        return (
          <CrossParticle key={index} length={length} rotation={rotation} width={width} rootColor={rootColor} position={adjustedPosition} emissionDelay={emissionDelay} onAnimationFinished={particleAnimationFinished} />
        )
      })}
    </div>
  )
}

export const useParticles = () => useContext(ParticlesContext)