/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext } from 'react'
import { UserContext } from '../UserProvider'
import { getOrdinalNumber } from '../../functions/shared/helpers'
import { ShareMilestoneLinkWithImagePreview } from '../components/ShareMilestoneLinkWithImagePreview'

export const MilestoneDisplay = ({ postFlowMilestoneInfo }) => {
  const { user } = useContext(UserContext)
  const { displayName } = user
  const { isFirstHostedSession, sessions, inviteLink, imageUrl } = postFlowMilestoneInfo

  return (
    <div css={css`
      background-color: white;
      display: flex;
      justify-content: center;
      padding-top: 60px;
      text-align: center;
      font-family: "Sato";
    `}>
      <div css={css`
        width: 420px;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding-bottom: 120px;
      `}>
        <span css={css`
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 38px;
        `}>
          Way to go, {displayName}!
        </span>
        <span css={css`
          margin: 10px 0;
        `}>
          {isFirstHostedSession ?
            `Congrats on hosting your first session! Spread the word that you're a new host on Flow Club.` :
            `You just completed your ${getOrdinalNumber(sessions)}! Share your milestone.`}
        </span>
        <ShareMilestoneLinkWithImagePreview inviteLink={inviteLink} milestone={sessions} isFirstHostedSession={isFirstHostedSession} ogImage={imageUrl} />
      </div>
    </div>
  )
}