/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ClubsMenu } from './ClubsMenu';
import { NEUTRAL_20 } from '../emotionVariables';
import { NAV_SIDEBAR_WIDTH } from '../../functions/shared/constants';

export const SideBar = ({ sidebarOpen = false, toggleSidebar = () => {}}) => {

  const sidebarStyle = css`
    height: 100vh;
    width: ${NAV_SIDEBAR_WIDTH}px;
    position: fixed;
    top: 64px;
    left: -${sidebarOpen ? '0' : NAV_SIDEBAR_WIDTH}px;
    transition: 0.3s;
    background-color: #ffffff;
    z-index: 999;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  `;

  const navLinkStyle = css`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
      background-color: ${NEUTRAL_20};
    }

  `;


  return (
    <div css={sidebarStyle}>
      <Nav css={css`
          display: flex;
          flex-direction: column;
        `}>
        <Link to="/" onClick={toggleSidebar} css={navLinkStyle}>🏠 Home</Link>
        <Nav.Item css={css`
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        `}>
          <ClubsMenu sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        </Nav.Item>
        <Link to ="/lounge/" onClick={toggleSidebar} css={navLinkStyle}>🪑 Lounge</Link>
      </Nav>
    </div>
  );
};
