/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { HostStatus } from '../../../functions/shared/constants';
import { useUpdateUser } from '../../fetch/endpoints';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

import { HostAvatar } from "./avatar"


export const HostInterestSession = () => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: updateUser } = useUpdateUser()

  const stopSeeingThisClicked = async () => {
    sendSegmentEvent("Stop Seeing This Clicked (Schedule inline button)")
    await updateUser({ updateObject: { hostStatus: HostStatus.NOT_NOW } })
  }

  return (
    <div css={css`
      background-color: white;
      border: 2px solid #E4E4E4;
      border-radius: 0px 0px 12px 12px;
    `}>
      <Row className="schedule-event align-items-center event-row" onClick={(e) => {
        e.preventDefault();
        return null;
      }}>
        <Col xs="12" md="1" lg="2">
        </Col>
        <Col xs="4" sm="2" md="2" lg="1">
          <HostAvatar className="small-club-leader" />
        </Col>
        <Col xs="8" sm="6" md="6" lg="6">
          <span className="event-title">
            Would you like to host a session?
          </span>
        </Col>
        <Col xs="12" sm="4" md="3" lg="3">
          <div className="float-right mt-2">
            <Link to="/host-onboarding/">
              <Button variant="success" onClick={() => sendSegmentEvent("Host Onboarding Link Clicked (Schedule inline button)")}>
                Learn More
              </Button>
            </Link>
            <br />
            <Button variant="link" className="mt-2 float-right" onClick={stopSeeingThisClicked}>
              Stop seeing this
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}