/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { LoungeContext } from "../LoungeContext";
import { SubscriptionStatus } from "../../../functions/shared/constants";
import { Popover, usePopoverData } from "../../components/Popover";
import { useDevices } from "@daily-co/daily-react";
import { useSendSegmentEvent } from "../../wrappers/SegmentProvider";

import Icon, {
  TYPE_CAMERA_ON,
  TYPE_CAMERA_OFF,
  TYPE_MORE,
} from "../../daily/daily-components/Icon/Icon";

import { useState } from "react";
import { FC_DARK_BLUE, NEUTRAL_40 } from "../../emotionVariables";

export default function LoungeTray({ user, loungeCallData }) {
  const [showDeviceChooser, setShowDeviceChooser] = useState(false);
  const { toggleCtaModalOpen } = useContext(LoungeContext);
  const { subscriptionStatus } = user ?? { subscriptionStatus: null };
  const cantShowVideo =
    subscriptionStatus === null ||
    subscriptionStatus === SubscriptionStatus.EXTREMELY_UNAUTHED_USER;

  const {
    toggleCamera,
    isCameraMuted,
  } = loungeCallData;

  const deviceChooserPopoverData = usePopoverData();

  return (
    <div
      css={css`
        width: 100%;
        border-top: 1px solid ${NEUTRAL_40};
        padding: 8px;
        background: white;
        display: flex;
        justify-content: center;
        box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.16);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
      `}
    >
      <div
        onClick={cantShowVideo ? ()=> toggleCtaModalOpen(true) : toggleCamera}
        css={css`
          cursor: pointer;
        `}
      >
        <Icon
          type={isCameraMuted ? TYPE_CAMERA_OFF : TYPE_CAMERA_ON}
        />
      </div>
      {!cantShowVideo && (
        <div
          onClick={() => setShowDeviceChooser(!showDeviceChooser)}
          css={css`
            cursor: pointer;
          `}
        >
          <Icon type={TYPE_MORE} fill={FC_DARK_BLUE} />
        </div>
      )}
      {showDeviceChooser && (
        <Popover
          floatingUIData={deviceChooserPopoverData}
          animate={false}
          popupWindowCss={css`
            position: absolute;
            bottom: 0px;
            padding: 18px;
          `}
          closePopup={() => setShowDeviceChooser(false)}
        >
          <DeviceChooser />
        </Popover>
      )}
    </div>
  );
}

const DeviceChooser = () => {
  const { cameras, setCamera } = useDevices();
  return (
    <div>
      <label
        css={css`
          font-weight: bold;
          margin-bottom: 4px;
        `}
      >
        Set Camera
      </label>
      <select
        onChange={(event) => setCamera(event.target.value)}
        css={css`
          padding: 4px;
        `}
      >
        {cameras &&
          cameras.map((camera) => {
            return (
              <option
                key={camera.device.deviceId}
                value={camera.device.deviceId}
              >
                {camera.device.label}
              </option>
            );
          })}
      </select>
    </div>
  );
};
