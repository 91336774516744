/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LoadingIndicator, Text, TEXT_STYLES } from '../components/flowComponents';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useGetBadgesForUsers } from '../fetch/endpoints';
import { NEUTRAL_20 } from '../emotionVariables';

export const ClubsMenu = ({ mobile = false, sidebarOpen = false, toggleSidebar = () => {} }) => {
  const [ clubsDataForUser, setClubsDataForUser ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const { user } = useContext(UserContext)

  const { performFetch: fetchBadgeClubs } = useGetBadgesForUsers()

  useEffect(() => {
    const cachedClubsDataForUser = localStorage.getItem('clubsDataForUser')
    if (cachedClubsDataForUser) {
      setClubsDataForUser(JSON.parse(cachedClubsDataForUser))
      setLoading(false)
    }
    const fetchClubData = async () => {
      setLoading(true)
      const { result: badgeData } = await fetchBadgeClubs({ userIds: [] })
      const badges = badgeData !== null ? badgeData.badges : []
      const badgeIdsForUser = badgeData !== null && user !== null && badgeData.badgeMembershipsByUser[user.uid] !== undefined ? new Set(badgeData.badgeMembershipsByUser[user.uid].map(membership => membership.badgeId)) : new Set([])
      const badgesForUser = badgeData !== null ? badges.filter(badge => badgeIdsForUser.has(badge.id)) : badges
      setClubsDataForUser(badgesForUser)
      localStorage.setItem('clubsDataForUser', JSON.stringify(badgesForUser))
      setLoading(false)
    }

    fetchClubData()
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <ClubsMenuContent clubs={clubsDataForUser} mobile={mobile} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
  )
}

export const ClubsMenuContent = ({ clubs, sidebarOpen = false, toggleSidebar = () => {} }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const location = useLocation()
  const clubPageLinkClicked = ({ slug, type }) => {
    sendSegmentEvent("Navbar Club Page Link Clicked", { slug, type })
    if (sidebarOpen) {
      toggleSidebar()
    }
  }
  return (
    <div css={css`
      width: fit-content;

      display: flex;
      flex-direction: column;
      align-items: left;
      background: #FFFFFF;
      user-select: none;
      gap: 4px;
    `}>
      <Link to="/clubs/community/" css={css`padding: 8px;`} onClick={toggleSidebar}>
        <Text style={TEXT_STYLES.APP_H6} customCss={css`text-transform: uppercase;`}>
          Community Clubs
        </Text>
      </Link>
      {clubs.filter(club => club.clubPage !== undefined).map(({ clubPageSlug, name, emoji }) => {
        const currentlyViewing = location.pathname.indexOf(`/club/${clubPageSlug}/`) === 0
        return (
          <Link
            key={clubPageSlug}
            css={css`
              text-decoration: none;
              font-size: 16px;
              padding: 4px 15px;
              transition: 0.3s;
              &:hover {
                background-color: ${NEUTRAL_20};
              }
              ${currentlyViewing && css`
                font-weight: bold;
              `}
            `}
            onClick={() => clubPageLinkClicked({ slug: clubPageSlug, type: "community" })} 
            to={`/club/${clubPageSlug}/`}>
            {emoji} {name}
          </Link>
        )
      })}
    </div>
  )
}