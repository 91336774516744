/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Col,
  Form,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/app';

import { useUpgradeUnauthedUser } from '../fetch/endpoints';
import { translateAuthErrorToToast } from '../components/utils/auth';
import { UserContext } from '../UserProvider';
import { toastError } from '../components/utils/toaster';
import { EmailLoginButton, GoogleLoginButton } from '../auth/loginButtons';
import { BUTTON_STYLES, FlowButton, SIZE_PRESETS } from '../components/flowComponents';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider()
GOOGLE_PROVIDER.setCustomParameters({
  prompt: 'select_account',
})

export function UpgradeUnauthedUser({ onSuccess = () => { }, onError = () => { } }) {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user, authUser } = useContext(UserContext);
  const [showSignupWithEmail, setShowSignupWithEmail] = useState(false);
  const { register, handleSubmit } = useForm();
  const [isUpdating, setIsUpdating] = useState(false);

  const { performFetch: upgradeUnauthedUser } = useUpgradeUnauthedUser()

  const upgradeUser = async (userId, email) => {
    const { success, error, result } = await upgradeUnauthedUser({ userId, email })
    if (success) {
      onSuccess({ user: result.upgradedUser, authType: 'upgradeUnauthed' });
    } else {
      toastError(error)
      onError(error)
    }
  }

  const googleLogin = () => {
    authUser.linkWithPopup(GOOGLE_PROVIDER).then((e) => {
      console.log(e)
      upgradeUser(user.uid, authUser.email)
    }).catch((e) => {
      if (e.code === "auth/popup-closed-by-user") {
        // no-op, write this way for clarity. don't close the modal if the user
        // runs into this error.
      } else if (e.code) {
        translateAuthErrorToToast({ errorCode: e.code })
      } else {
        onError(e)
      }
    });
  }

  const onSubmit = (data) => {
    // update state
    setIsUpdating(true);

    // update auth method
    let credential = firebase.auth.EmailAuthProvider.credential(data.email, data.password);
    authUser.linkWithCredential(credential)
      .then((usercred) => {
        var user = usercred.user;
        console.log("Anonymous account successfully upgraded", user);
        upgradeUser(user.uid, data.email)
      }).catch((error) => {
        translateAuthErrorToToast({ errorCode: error.code })
        console.log("Error upgrading anonymous account via email login", error);
        onError(error);
      })
  }

  const cancelButtonClicked = () => {
    sendSegmentEvent("[Unauthed User Upgrade] Cancel Button Clicked")
    setShowSignupWithEmail(false)
  }

  const firstStep = (
    <>
      <GoogleLoginButton customCss={css`margin-bottom: 12px; margin-top: 40px;`} onClick={googleLogin} loading={isUpdating}>Sign Up With Google</GoogleLoginButton>
      <EmailLoginButton customCss={css`margin-bottom: 12px; margin-top: 40px;`} onClick={() => setShowSignupWithEmail(true)} loading={isUpdating}>Sign Up With Email</EmailLoginButton>
    </>
  )

  const emailSignup = (
    <>
      <Modal.Header>
        <Modal.Title as="h2">
          Sign up with email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={handleSubmit(onSubmit)} className="finishSignUpModal">
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Email</Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  name="email"
                  ref={register}
                  placeholder={"email@email.com"}
                  defaultValue={user?.email}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>Password</Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  type="password"
                  name="password"
                  ref={register}
                  placeholder="******"
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <div css={css`
            display: flex;
            margin-top: 16px;
          `}>
            <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} buttonStyle={BUTTON_STYLES.NO_BACKGROUND} customCss={css`flex: 1; margin-right: 4px;`} onClick={cancelButtonClicked}>Cancel</FlowButton>
            <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} loading={isUpdating} customCss={css`flex: 1;`} type='submit'>Sign Up</FlowButton>
          </div>
        </Form>
      </Modal.Body>
    </>
  )

  return (showSignupWithEmail ?
    emailSignup :
    firstStep
  );
}