/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CalendarView } from '../../CalendarView/CalendarView';
import { CalendarViewScheduleDayColumnWrapper } from './CalendarViewScheduleDayColumnWrapper';
import dayjs from 'dayjs'
import { createContext } from 'react';
import { groupObjectsByProperty } from '../../../functions/shared/helpers';
import { DATE_FORMAT } from '../../../functions/shared/constants';

export const CalendarViewScheduleContext = createContext(null)

export const CalendarViewSchedule = ({ events }) => {
  const today = dayjs().startOf('day')
  const dates = []

  for (let i = 0; i < 7; i++) {
    const newDate = today.add(i, 'day').startOf('day')
    dates.push(newDate)
  }

  const sessionsByDay = groupObjectsByProperty(events, event => dayjs(event.start).format(DATE_FORMAT))

  return (
    <CalendarViewScheduleContext.Provider value={{ sessionsByDay }}>
      <CalendarView dates={dates} DayColumnComponent={CalendarViewScheduleDayColumnWrapper} rowHeight={800} />
    </CalendarViewScheduleContext.Provider>
  )
}