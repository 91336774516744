/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { BUTTON_STYLES, FlowButton } from '../../components/flowComponents';
import { useModal } from '../../wrappers/MagnificentlyMunificentModalManager';
import chimeSound from '../../sounds/bell.wav'
import gongSound from '../../sounds/gong.wav'
import useSound from 'use-sound';
import { Helmet } from 'react-helmet';
import { LoungeFullscreenModal } from '../LoungeFullscreenModal';
import { JoinSessionTimer } from '../JoinSessionTimer';
import { useTimer } from '../useTimer';
import { TimeDisplay } from '../TimeDisplay';
import { useStartSpaceTimer, useStopSpaceTimer } from '../../fetch/endpoints';
import { UserContext } from '../../UserProvider';
import { ResetConfirmPopup } from './ResetConfirmPopup';
import { PomodoroBreakIndicator } from './PomodoroBreakIndicator';
import { FlowScoreInput } from './FlowScoreInput';
import { Controls } from './Controls';
import { ViewSchedulePrompt } from './ViewSchedulePrompt';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

export const INITIAL_TIMER_DURATION = 25
const INITIAL_TIMER_DURATION_MS = INITIAL_TIMER_DURATION * 60 * 1000
const INITIAL_BREAK_TIMER_DURATION = 5


const getUpcomingSessionIsStartingSoon = (participant = null) =>
  participant !== null &&
  dayjs.unix(participant.start._seconds).isBefore(dayjs().add('25', 'minute')) &&
  dayjs.unix(participant.start._seconds).isAfter(dayjs().subtract('5', 'minute'))

export const usePomodoroTimerState = ({ visitId }) => {
  const { performFetch: stopTimerServerSync } = useStopSpaceTimer()
  const [playChime] = useSound(chimeSound)
  const [playGong] = useSound(gongSound)
  const sendSegmentEvent = useSendSegmentEvent()
  
  const flowScoreState = useState(false)
  const viewSchedulePromptDismissedState = useState(false)

  const onWorkTimerFinished = () => {
    playChime()
    startBreakTimer()
    stopTimerServerSync({ visitId, shouldStartBreak: true })
  }
  const onBreakTimerFinished = () => {
    playGong()
    resetTimers()
    sendSegmentEvent("Break Naturally Elapsed")
  }

  const workTimerState = useTimer(INITIAL_TIMER_DURATION, 1000, onWorkTimerFinished)
  const breakTimerState = useTimer(INITIAL_BREAK_TIMER_DURATION, 1000, onBreakTimerFinished)

  const resetTimers = () => {
    resetWorkTimer()
    resetBreakTimer()
  }

  const { reset: resetWorkTimer } = workTimerState
  const { start: startBreakTimer, reset: resetBreakTimer } = breakTimerState

  return { flowScoreState, viewSchedulePromptDismissedState, workTimerState, breakTimerState, resetTimers }
}

export const PomodoroTimer = ({ visitId, existingTimedSessionRemainingTime = null, nextUpcomingParticipant = null,  pomodoroTimerState }) => {
  const { flowScoreState, viewSchedulePromptDismissedState, workTimerState, breakTimerState, resetTimers } = pomodoroTimerState
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()
  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: startTimerServerSync } = useStartSpaceTimer()
  const { performFetch: stopTimerServerSync } = useStopSpaceTimer()

  const [flowScoreSubmitted, setFlowScoreSubmitted] = flowScoreState
  const [viewSchedulePromptDismissed, setViewSchedulePromptDismissed] = viewSchedulePromptDismissedState

  const { timeRemaining: workTimeRemaining, active: workTimeActive, start: startWorkTimer, reset: resetWorkTimer } = workTimerState
  const { timeRemaining: breakTimeRemaining, active: breakActive, start: startBreakTimer, reset: resetBreakTimer } = breakTimerState

  useEffect(() => {
    if (existingTimedSessionRemainingTime !== null && !workTimeActive) {
      startWorkTimer(existingTimedSessionRemainingTime)
    }
  }, [existingTimedSessionRemainingTime])

  const manualResetConfirmed = () => {
    resetTimers()
    if (workTimeActive) {
      stopTimerServerSync({ visitId, shouldStartBreak: false })
    }
    // a bit ridiculous to have both, but currentlyInPomodoroClientSide is slightly more accurate/up to date than the generic currentlyInPomodoro in the SegmentProvider's baseData
    // it can capture things like the client resetting immediately after starting a timer, that the serverstate-based check can't currently
    sendSegmentEvent("Timer Reset", { currentlyInPomodoroClientSide: workTimeActive, flowScoreSubmitted })
  }
  const resetPressed = () => {
    setActiveModal(LoungeFullscreenModal, { Contents: ResetConfirmPopup, onConfirm: manualResetConfirmed })
  }

  const startPressed = () => {
    startWorkTimer()
    setFlowScoreSubmitted(false)
    setViewSchedulePromptDismissed(false)
    startTimerServerSync({ visitId, timerLengthMs: INITIAL_TIMER_DURATION_MS })
    sendSegmentEvent("Timer Started")
  }

  const timeToDisplay = (breakActive ? breakTimeRemaining : workTimeRemaining).format('m:ss')
  const timerActive = workTimeActive || breakActive

  const title = (
    timerActive ?
      `${workTimeActive ? "Work" : "Break"}: ${timeToDisplay} - Flow Club` :
      "Flow Club Lounge"
  )

  const displayFlowScoreInput = breakActive && !flowScoreSubmitted
  const displayViewSchedulePrompt = breakActive && flowScoreSubmitted && user.sessions === 0 && !viewSchedulePromptDismissed
  
  const sessionUpcomingSoon = getUpcomingSessionIsStartingSoon(nextUpcomingParticipant)
  // Don't display upcoming session info if currently in a pomodoro or waiting for flow score input -- prioritize the active pomodoro timer until it's over
  const displayUpcomingSessionCountdown = sessionUpcomingSoon && !workTimeActive && !displayFlowScoreInput && !displayViewSchedulePrompt

  return (
    <>
      <Helmet defer={false}>
        <title>{title}</title>
      </Helmet>
      {
        displayUpcomingSessionCountdown ?
          <JoinSessionTimer nextUpcomingParticipant={nextUpcomingParticipant} /> :
          <div css={css`
            display: flex;
            flex-flow: column;
            align-items: center;
          `}>
            <div css={css`${user === null ? css`opacity: 0.65;` : ''}`}>
              <PomodoroBreakIndicator breakActive={breakActive} breakTimeRemaining={timeToDisplay} displayTime={!flowScoreSubmitted && breakActive} />
            </div>
            <SubPomodoroBreakContent
              displayFlowScoreInput={displayFlowScoreInput}
              displayViewSchedulePrompt={displayViewSchedulePrompt}
              setFlowScoreSubmitted={setFlowScoreSubmitted}
              visitId={visitId}
              timerActive={timerActive}
              startPressed={startPressed}
              resetPressed={resetPressed}
              timeToDisplay={timeToDisplay}
              setViewSchedulePromptDismissed={setViewSchedulePromptDismissed}
              user={user}
            />
          </div>
      }
    </>
  )
}

const SubPomodoroBreakContent = ({ displayFlowScoreInput, displayViewSchedulePrompt, setFlowScoreSubmitted, visitId, timerActive, startPressed, resetPressed, timeToDisplay, setViewSchedulePromptDismissed, user }) =>{
  if (displayFlowScoreInput) {
    return (
      <>
        <div css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 24px;
          max-width: 100%;
        `}>
          <FlowScoreInput finishFlowScoreSubmittal={() => setFlowScoreSubmitted(true)} visitId={visitId} />
        </div>
        <Controls timerHasStarted={timerActive} startTimer={startPressed} reset={resetPressed} />
      </>
    )
  }
  if (displayViewSchedulePrompt) {
    return (
      <ViewSchedulePrompt dismissPrompt={() => setViewSchedulePromptDismissed(true)} />
    )
  }
  return (
    <>
      <div css={css`

        ${user === null ? css`opacity: 0.65;` : ''}
      `}>
        <TimeDisplay time={timeToDisplay} />
      </div>
      <Controls timerHasStarted={timerActive} startTimer={startPressed} reset={resetPressed}/>
    </>
  )
}