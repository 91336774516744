/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { BUTTON_STYLES, FlowButton, Text, TEXT_STYLES } from '../components/flowComponents';
import { toastError, toastSuccess } from '../components/utils/toaster';
import Icon, { TYPE_CLOSE } from '../daily/daily-components/Icon/Icon';
import EventContext from '../EventContext';
import { useClaimHost } from '../fetch/endpoints';
import { getHostClaimability } from '../Sessions/SessionUtils';
import { UserContext } from '../UserProvider';
import { usePreviousSticky } from '../utils';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';

export const HostClaimNotification = ({ removeBeforeUnload }) => {
  return (
    <SegmentProvider eventLabel={'Host Claim Notification'}>
      <HostClaimNotificationContent removeBeforeUnload={removeBeforeUnload}/>
    </SegmentProvider>
  )
}

const HostClaimNotificationContent = ({ removeBeforeUnload }) => {
  const { event, participants } = useContext(EventContext)
  const { user } = useContext(UserContext)
  const hostParticipant = participants[event.hostId]
  const sendSegmentEvent = useSendSegmentEvent()
  const { isEmptyHost, noShowedHostClaimingPeriodOpen, userCanClaimHosting } = getHostClaimability({ event, user, claimingFromNoShowingHost: true })
  const wasEmptyHost = usePreviousSticky(isEmptyHost)

  const [dismissed, setDismissed] = useState(false)
  const [ successfullyClaimed, setSuccessfullyClaimed ] = useState(false)

  const hostIsOrWasHostTBD = isEmptyHost || wasEmptyHost
  const hostHasntJoined = !hostIsOrWasHostTBD && (hostParticipant !== undefined && hostParticipant.joined === undefined)
  const claimableFromNoShowingHost = userCanClaimHosting && hostHasntJoined && noShowedHostClaimingPeriodOpen
  const showNotification = !dismissed && (hostIsOrWasHostTBD || claimableFromNoShowingHost)

  const { performFetch: claimHost, fetching: claimHostLoading } = useClaimHost()
  const claimHosting = async () => {
    const { success, error } = await claimHost({ eventId: event.id, claimingFromNoShowingHost: claimableFromNoShowingHost })
    if (success) {
      toastSuccess({ message: "Thanks for taking over as host! Rejoining with you as the host..."})
      setSuccessfullyClaimed(true)
      // Unset the beforeunload so user doesn't have to confirm reload
      removeBeforeUnload()
      window.location.reload()
    } else {
      toastError({ message: error })
    }
  }

  useEffect(() => {
    if (showNotification) {
      sendSegmentEvent("Notification Shown", { userCanClaimHosting, claimableFromNoShowingHost })
    }
  }, [showNotification, claimableFromNoShowingHost])
  
  const hostSessionClicked = () => {
    sendSegmentEvent("Become Host Clicked", { claimableFromNoShowingHost })
    claimHosting()
  }

  const dismissClicked = () => {
    sendSegmentEvent("Dismiss Clicked", { hostWasClaimed: !isEmptyHost, claimableFromNoShowingHost })
    setDismissed(true)
  }

  if (!showNotification) {
    return null
  }

  const content = !isEmptyHost ?
    (claimableFromNoShowingHost ?
      {
        text: `The host of this session hasn't joined. Would you like to host instead?`,
        buttonText: 'Become Host',
        buttonAction: hostSessionClicked
      } : {
        text: successfullyClaimed ? `🎉 Thanks for stepping up to host, ${hostParticipant.name}! Rejoining the session as a host` : `🎉 ${hostParticipant.name} is now the host. Thanks ${hostParticipant.name}!`,
        buttonText: successfullyClaimed ? "Rejoin as host" : 'Dismiss',
        buttonAction: successfullyClaimed ? () => window.location.reload() : dismissClicked
      }
    ) : (userCanClaimHosting ? {
      text: claimHostLoading || successfullyClaimed ? 'Thanks for stepping up to host! Give us a minute to update everything...' : 'This session is looking for a host. Would you like to host?',
      buttonText: 'Become Host',
      buttonAction: hostSessionClicked
    } : {
      text: 'Looking for a host.',
      buttonText: 'Dismiss',
      buttonAction: dismissClicked
    })

  const { text, buttonText, buttonAction } = content

  return (
    <div css={css`
      position: fixed;
      bottom: 80px;
      width: 100%;

      display: flex;
      justify-content: center;
      padding: 0px 20px;
    `}>
        <div css={css`
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          width: 550px;

          background: white;
          padding: 10px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
        `}>
          <Text style={TEXT_STYLES.BODY_2}>{text}</Text>
          <FlowButton loading={claimHostLoading} buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={buttonAction}>{buttonText}</FlowButton>
          <div onClick={dismissClicked} css={css`transform: translate(12px, -12px); cursor: pointer;`}>
            <Icon type={TYPE_CLOSE} />
          </div>
        </div>
    </div>
  )
}