/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Text, TEXT_STYLES } from '../components/flowComponents';
import CelebrationImage from '../Onboarding/images/celebration.jpg';
import { FullScreenSidebar } from '../Onboarding/FullScreenSidebar';


export const CelebrationScreen = ({ mobileView = false}) => {

  const celebrationCss = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
  `

  const imageCss = css`
    width: ${mobileView ? 198 : 286}px;
    height: ${mobileView ? 187 : 270}px;
    margin-bottom: 40px;
  `

  return (
    <FullScreenSidebar>
    <div css={celebrationCss}>
      <div>
        <img src={CelebrationImage} alt="Celebration" css={imageCss}/>
      </div>
      <Text style={TEXT_STYLES.APP_H2}>Well Done!</Text>
      <Text style={TEXT_STYLES.BODY_SUBTITLE}>You've identified your goal and made an external commitment to doing it. That's the hard part.</Text>
    </div>
    </FullScreenSidebar>
  )
}