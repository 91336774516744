/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserProvider';
import { Container } from 'react-bootstrap';
import { FC_BACKGROUND_GRAY, FC_ALT_GREEN, FC_IRIS_BLUE } from '../emotionVariables';
import { useGetNextJoinableSessionForNewUser } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { SessionPane } from '../Onboarding/SessionPane';
import { SelectTimePane } from '../Onboarding/SelectTimePane';
import { WelcomeInterstitial } from '../Onboarding/WelcomeInterstitial';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { useMediaQuery } from 'react-responsive';
import { Text, TEXT_STYLES } from '../components/flowComponents';
import NowImg from './now.png'
import LaterImg from './later.png'
import { UpcomingSessions } from '../HomeOnboarding/HomeHome';
import { useUpcomingEvents } from '../Sessions/SessionUtils';

const TimeOnboardingWrapper = () =>
  <SegmentProvider eventLabel={'Time Onboarding'}>
    <TimeOnboardingContent />
  </SegmentProvider>

const CARD_STYLES = {
  NOW: { backgroundColor: FC_ALT_GREEN, image: NowImg, text: 'Now', subtitle: 'Get focused immediately.'},
  LATER: { backgroundColor: FC_IRIS_BLUE, image: LaterImg, text: 'Later', subtitle: 'Schedule your focus time.'},
}

export const TimeOnboardingCard = ({ style = CARD_STYLES.NOW, onClick = () => {} }) => {
  const mobileView = useMediaQuery({query: '(max-width: 575px)'})
  const imageSize = 64;
  return (
    <div css={css`
      border-radius: 16px;
      height: 112px;
      width: ${mobileView ? '100%' : '470px'};
      justify-content: center;
      background-color: ${style.backgroundColor};
      color: #FFFFFF;
      text-align: center;
      display: flex;
      padding: 24px;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      cursor: pointer;
      margin: 0 auto;
    `} onClick={onClick}>
      <img src={style.image} css={css`
        width: ${imageSize}px;
        height: ${imageSize}px;
      `} alt={style} />
      <div css={css`
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;
        gap: 4px;
      `}>
        <Text style={TEXT_STYLES.APP_H3}>{style.text} </Text>
        <Text style={TEXT_STYLES.APP_BODY}>{style.subtitle} </Text>
      </div>
    </div>
  )
}

const TimeOnboardingContent = () => {
  const { user } = useContext(UserContext)
  const userId = user !== null ? user.uid : null
  const [ activeTaskIndex, setActiveTaskIndex ] = useState(null)
  const sendSegmentEvent = useSendSegmentEvent()
  const [ upcomingEvents ] = useUpcomingEvents(userId)
  const { result: nextJoinableSessionResult, fetching: nextJoinableSessionLoading, error: nextJoinableSessionError } = useAutomaticallyFetch(useGetNextJoinableSessionForNewUser, {})
  const { nextSession: nextJoinableSession, isNow } = nextJoinableSessionResult || {}
  const nextJoinableSessionLoaded = useFetchSucceeded(nextJoinableSessionLoading, nextJoinableSessionError)

  const mobileView = useMediaQuery({query: '(max-width: 575px)'})

  const paneSets = [
    {
      name: 'timeOnboarding-nowSession',
      panes: [
        { name: "SessionPane", MainContent: <SessionPane defaultSession={nextJoinableSession} 
        isNow={isNow}
        recommendedSessionLoading={!nextJoinableSessionLoaded} key='session' />, navType: 'none' },
      ]
    },
    {
      name: 'timeOnboarding-chooseTime',
      panes: [
        { name: "ChooseTimePane", MainContent: <SelectTimePane key='choose-time' />, navType: 'none' },
      ]
    }
  ]

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [activeTaskIndex])

  const onNowClicked = () => {
    sendSegmentEvent('Clicked Now')
    setActiveTaskIndex(0)
  }

  const onLaterClicked = () => {
    sendSegmentEvent('Clicked Later')
    setActiveTaskIndex(1)
  }

  return (
    <div css={css`
      background-color: ${FC_BACKGROUND_GRAY};
      ${(!mobileView || activeTaskIndex === null) && css`padding: 16px 0px;`}
      flex-grow: 1;
    `}>
      <Container css={css`text-align: center;`}>
        {activeTaskIndex === null ?
        <>
          <div css={css`
            text-align: center;
            margin-bottom: 32px;
            justify-content: center;
          `}>
          {upcomingEvents.length > 0 &&
            <UpcomingSessions upcomingEvents={upcomingEvents} />
          }
          </div>
          <Text style={TEXT_STYLES.APP_H2} css={css`text-align: center;`}>{upcomingEvents.length > 0 ? "Book another session" : "When would you like to focus?"}</Text>
          <div css={css`
            margin-top: ${mobileView ? 24 : 32}px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 8px;
            align-self: stretch;
          `}>
            <TimeOnboardingCard style={CARD_STYLES.NOW} onClick={onNowClicked}/>
            <TimeOnboardingCard style={CARD_STYLES.LATER} onClick={onLaterClicked}/>

          </div>
        </>
        :
          <WelcomeInterstitial paneSetOverride={paneSets[activeTaskIndex]} onExit={() => setActiveTaskIndex(null)}/>
        }
      </Container>
    </div>
  )
}

export const TimeOnboarding = TimeOnboardingWrapper