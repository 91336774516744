/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { BUTTON_STYLES, FlowButton, Modal, Text, TextInput, TEXT_STYLES } from '../../components/flowComponents';
import { CalendarGoalEntry } from '../../components/CalendarGoalEntry';
import { toastError } from '../../components/utils/toaster';
import { useBookSession, useClaimHost } from '../../fetch/endpoints';
import { UserContext, usePreferences } from '../../UserProvider';
import { SegmentProvider, useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { useMediaQuery } from 'react-responsive';

export const ClaimHostModal = ({ onHide, event, alreadyBooked, confirmImmediately = false, onSuccess = () => {} }) => {
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})

  const [claimed, setClaimed] = useState(false)
  const [booked, setBooked] = useState(false)

  const onHostingClaimed = () => {
    setClaimed(true)
    onSuccess()
  }

  return (
    <Modal onClose={onHide}>
      <div css={css`
        ${!mobileView && css`width: 500px;`}

        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
      `}>
        <SegmentProvider eventLabel={'Claim Host Modal'} baseData={{ alreadyBooked, confirmImmediately }}>
          {claimed || booked ?
            <ConfirmationContent event={event} onHide={onHide} alreadyBooked={alreadyBooked} hostingClaimed={claimed} /> :
            <ClaimingContent event={event} onHostingClaimed={onHostingClaimed} onSessionBooked={() => setBooked(true)} alreadyBooked={alreadyBooked} confirmImmediately={confirmImmediately} />
          }
        </SegmentProvider>
      </div>
    </Modal>
  )
}

const ClaimingContent = ({ event, onHostingClaimed, onSessionBooked, alreadyBooked, confirmImmediately }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext);

  const { start: startTime, title, eventTypeDescription } = event
  const startTimeText = dayjs(startTime.getTime()).format("ddd, MMM D @ h:mmA z")

  const [musicDescription, setMusicDescription] = useState('')

  const { performFetch: claimHost, fetching: claimHostLoading } = useClaimHost()
  const claimHosting = async () => {
    const { success, error } = await claimHost({ eventId: event.id, musicDescription })
    if (success) {
      onHostingClaimed()
    } else {
      toastError({ message: error })
    }
  }

  useEffect(() => {
    sendSegmentEvent("Modal Shown")
  }, [])
  
  const hostSessionClicked = () => {
    sendSegmentEvent("Book as Host Clicked")
    claimHosting()
  }

  const { performFetch: bookWithoutHosting, fetching: bookWithoutHostingLoading } = useBookSession()

  const bookWithoutHostingClicked = async () => {
    sendSegmentEvent("Book as Non-Host Clicked")
    const { success, error } = await bookWithoutHosting({
      eventId: event.id,
      displayName: user.displayName,
      tags: user.tags || []
    })
    if (success) {
      onSessionBooked()
    } else {
      toastError({ message: error })
    }
  }

  useEffect(() => { if (confirmImmediately) { claimHosting() } }, [confirmImmediately])

  return (
    <>
      <Text style={TEXT_STYLES.MARKETING_H4}>Would you like to host?</Text>
      <Text>This session is looking for a host. Please consider hosting it!</Text>
      <div>
        <strong>
          <Text>{startTimeText}</Text>
          <Text>{title}</Text>
        </strong>
        <Text>{eventTypeDescription}</Text>
      </div>
      <TextInput label={'Optional Music Info'} placeholder={"Describe the music you'll play"} value={musicDescription} onChange={(event) => setMusicDescription(event.target.value)} />
      <div css={css`display: flex; justify-content: flex-end; gap: 10px;`}>
        <div css={css`flex: 1;`}>
          {!alreadyBooked &&
            <FlowButton fillAvailableWidth loading={bookWithoutHostingLoading} customCss={css`flex: 1; padding-right: 0px; padding-left: 0px;`} onClick={bookWithoutHostingClicked} buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>Book as Non-Host</FlowButton>
          }
        </div>
        <div css={css`flex: 1;`}>
          <FlowButton fillAvailableWidth loading={claimHostLoading} customCss={css`flex: 1;`} onClick={hostSessionClicked}>Book as Host</FlowButton>
        </div>
      </div>
    </>
  )
}

const ConfirmationContent = ({ event, onHide, alreadyBooked, hostingClaimed }) => {
  const { disableCalendarInvites } = usePreferences()
  const startTimeText = dayjs(event.start.getTime()).format("ddd, MMM D @ h:mmA z")

  const saveGoalsRef = useRef(null)

  const onHideWrapper = () => {
    if (saveGoalsRef && saveGoalsRef.current) {
      saveGoalsRef.current()
    }
    onHide()
  }

  return (
    <>
      <Text style={TEXT_STYLES.MARKETING_H4}>You're confirmed!</Text>
      <div>
        {hostingClaimed && <Text>Thanks for hosting!</Text>}
        {disableCalendarInvites ?
          <>
            <Text>Calendar invites turned off in <Link to="/settings/account">settings</Link>.</Text>
            <Text>Access this session from the top of the schedule or from <Link to="/profile/sessions">My Sessions</Link>.</Text>
          </> :
          <>
            <Text>We'll send you a calendar invite on</Text>
            <Text>{startTimeText}.</Text>
          </>
        }
      </div>
      {!alreadyBooked && <CalendarGoalEntry eventId={event.id} saveGoalsRef={saveGoalsRef} />}
      <div css={css`display: flex; justify-content: flex-end;`}>
        <FlowButton onClick={onHideWrapper}>Save and Close</FlowButton>
      </div>
    </>
  )
}