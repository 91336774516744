/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { AgendaDescription } from '../components/AgendaDescription';
import { Text, TEXT_STYLES } from '../components/flowComponents';

import EventContext from '../EventContext';

export const JoinScreenAgendaOverview = ({ stages, currentStage, sessionName }) => {
  const { event } = useContext(EventContext)
  const { chatOnly } = event
  return (
    <div>
      <Text style={TEXT_STYLES.SUBTITLE_2}>
        Get ready to enter
      </Text>
      <Text
        style={TEXT_STYLES.APP_H3}
        customCss={css`
          margin: 8px 0px;
        `}
      >
          {sessionName}
        </Text>
      <AgendaDescription agenda={stages} currentStage={currentStage} />
      {chatOnly && <Badge css={css`background-color: #D5E3E0; margin-top: 8px; font-size: 12px; font-weight: normal;`}>⌨️ Chat-only (No verbal sharing)</Badge>}
    </div>
  )
}