/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { capitalize } from 'lodash';
import { createContext, ReactElement, useContext } from "react"
import toast from "react-hot-toast"
import { isFunction } from "../../functions/shared/helpers"
import { FC_LIGHTER_BLUE, NEUTRAL_80 } from "../emotionVariables"
import { IS_PROD } from '../utils';

const ToastComponent = ({ type, eventName }) => (
  <a href={'https://app.segment.com/flow-club/sources/flow_club_dev_dashboard/debugger'} target="_blank" rel="noreferrer">
    <div>
      <span css={css`color: black; font-weight: bold;`}>{capitalize(type)}</span> event sent to segment!
    </div>
    <div css={css`font-style: oblique;`}>{eventName}</div>
  </a>
)

const showToast = (type, eventName) => {
  toast(() => <ToastComponent type={type} eventName={eventName} />,
    {
      position: "bottom-right",
      style: {
        borderRadius: '15px',
        background: FC_LIGHTER_BLUE,
        color: NEUTRAL_80,
      },
    }
  )
}

const sendTrackEvent = (eventName, eventData) => {
  window.analytics.track(eventName, eventData)
  if (!IS_PROD) { showToast('track', eventName) }
}

const sendPageEvent = ({ eventData }) => {
  window.analytics.page({ eventData })
  if (!IS_PROD) { showToast('page', null) }
}

export const SegmentContext = createContext({
  sendSegmentEvent: (eventName, eventData = {}) => sendTrackEvent(eventName, eventData),
  sendSegmentPageLoad: (eventData = {}) => { sendPageEvent({ eventData }) }
})

export const SegmentProvider: (args: { children: ReactElement, baseData?: object | Function, eventLabel?: string }) => ReactElement = ({ children, baseData = {}, eventLabel = null }) => {
  const parentSegmentContextValue = useContext(SegmentContext)
  const { sendSegmentEvent: parentSendSegmentEvent, sendSegmentPageLoad: parentSendSegmentPageLoad } = parentSegmentContextValue

  const sendSegmentEvent = (eventName, eventData = {}) => {
    const childBaseData = isFunction(baseData) ? (baseData as Function)() : baseData
    parentSendSegmentEvent(
      `${eventLabel !== null ? `[${eventLabel}] ` : ''}${eventName}`,
      { ...childBaseData, ...eventData }
    )
  }
  
  const sendSegmentPageLoad = (eventData = {}) => {
    const childBaseData = isFunction(baseData) ? (baseData as Function)() : baseData
    parentSendSegmentPageLoad({ ...childBaseData, ...eventData })
  }

  return <SegmentContext.Provider value={{ sendSegmentEvent, sendSegmentPageLoad }}>
    {children}
  </SegmentContext.Provider>
}
export const useSendSegmentEvent = () => useContext(SegmentContext).sendSegmentEvent
export const useSendSegmentPageLoad = () => useContext(SegmentContext).sendSegmentPageLoad