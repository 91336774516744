/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';


import dayjs from 'dayjs'
import { UserContext } from '../../UserProvider';
import { BUTTON_STYLES, FlowButton } from '../../components/flowComponents';
import { useCancelHostSession, useRemoveUserFromEvent } from '../../fetch/endpoints';
import { useFetchSucceeded } from '../../fetch/helpers';
import { toastError } from '../../components/utils/toaster';

const reasonsToCancel = [
  "Joining another session",
  "Booked by accident",
  "Conflicting meeting",
  "Have an emergency",
  "Something important came up",
  "Other..."
];

export const CancelModal = ({ eventToCancel, closeFn, onHide, onSuccess }) => {
  const { user } = useContext(UserContext)
  const { hostId, guests, id: eventId, hostUser, start } = eventToCancel
  const { displayName: hostDisplayName } = hostUser
  
  const isHost = user && eventToCancel && user.uid === hostId
  const emptyHostSession = isHost && eventToCancel && guests && guests.length <= 1
  const cancelationHook = emptyHostSession ? useCancelHostSession : useRemoveUserFromEvent
  const { performFetch: cancelSession, fetching: canceling, error: cancelError } = cancelationHook()
  const canceled = useFetchSucceeded(canceling, cancelError)
  const { register, handleSubmit, watch } = useForm()
  const watchReason = watch('reason', '')

  const cancelBooking = async (data) => {
    const { success, error } = await cancelSession({
      eventId,
      reason: data.reason,
      otherReason: data.otherReason || '',
    })

    if (success) {
      onSuccess()
    } else {
      toastError({ message: error })
    }
  }
  
  return (
    <Modal
      show
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {canceling ?
            <span>
              <Spinner animation="grow" variant="primary" className="mr-2" />
              Working on your cancellation...
            </span> :
            canceled ? (emptyHostSession ? "Cancellation is complete" : "Confirmed") : (emptyHostSession ? "Cancel Hosting" : "Cancel")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(canceling || canceled) ?
          <div>
            {isHost ?
              emptyHostSession ?
                <div>
                  We've removed the session from the schedule.
                  Thanks for letting us know.
                </div> :
                <div>
                  You are no longer hosting this session. See you next time!
                </div>
              :
              <div>
                We'll update your host, {hostDisplayName}.<br />
                Thanks for letting us know—
                doing so opens up a seat for someone else.
              </div>
            }
            {canceled &&
              <div className="float-right">
                <Button variant="primary" type="button" className="my-3 mr-3" onClick={closeFn}>
                  Close
                </Button>
              </div>
            }
          </div>
          :
          isHost && !emptyHostSession ?
            <div>
              <div>Things happen. Since this session has attendees, we'll keep the session, remove you from hosting, and look for a substitute.</div>
              <div css={css`display: flex; justify-content: flex-end; gap: 8px; margin-top: 24px;`}>
                <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={closeFn}>
                  Never Mind
                </FlowButton>
                <FlowButton type="submit" onClick={cancelBooking}>
                  Confirm Cancel
                </FlowButton>
              </div>
            </div>
            :
            <div>
              Cancel your session at {dayjs(start).format('h:mma z on ddd M/D')}?
              <Form onSubmit={handleSubmit(cancelBooking)}>
                <Form.Group controlId="cancel" className="mt-3">
                  <Form.Label>Why are you canceling?</Form.Label>
                  <Form.Control as="select" name="reason" defaultValue="" ref={register} required>
                    <option hidden disabled value=""> -- Select a reason -- </option>
                    {reasonsToCancel.map((reason, index) => (
                      <option value={reason} key={index}>{reason}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {watchReason === 'Other...' ?
                  <Form.Group>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="text"
                        name="otherReason"
                        ref={register}
                        placeholder="My reason for canceling"
                      />
                    </InputGroup>
                  </Form.Group>
                  : ""}
                <div className="float-right">
                  <Button variant="link" type="button" className="my-3 mr-3" onClick={closeFn}>
                    Never Mind
                  </Button>
                  <Button variant="primary" type="submit" className="my-3">
                    Confirm
                  </Button>
                </div>
              </Form>
            </div>
        }
      </Modal.Body>
    </Modal>
  );
}