
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createContext, useContext, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { AnimatedSlidingWrapper } from '../components/AnimatedSlidingWrapper';
import { Text, TEXT_STYLES } from '../components/flowComponents';
import Icon, { TYPE_RIGHT_CARET_UNSET_FILL } from '../daily/daily-components/Icon/Icon';
import { FC_DARK_BLUE, NEUTRAL_50, NEUTRAL_60 } from '../emotionVariables';
import { DayColumn } from './DayColumn';

export const CalendarViewContext = createContext(null)

export const CalendarView = ({ dates, DayColumnComponent = DayColumn, rowHeight = 400 }) => {

  const mobileView = useMediaQuery({ query: '(max-width: 999px)' })

  const Content = !mobileView ? DesktopContent : MobileContent

  return (
    <div css={css`position: relative;`}>
      <CalendarViewContext.Provider value={{ DayColumnComponent, rowHeight, mobileView }} >
        <Content dates={dates} />
      </CalendarViewContext.Provider>
    </div>
  )
}

const MobileContent = ({ dates }) => {
  return (
    <DaySelector dates={dates} />
  )
}

const DesktopContent = ({ dates }) => {
  return (
    <>
      <RowOfDays dates={dates.slice(0, 8)} />
      {dates.length > 8 &&
        <RowOfDays dates={dates.slice(8)} />
      }
    </>
  )
}

const RowOfDays = ({ dates }) => {
  const { DayColumnComponent } = useContext(CalendarViewContext)

  return (
    <div css={css`
      margin: 20px;
      display: flex;
      justify-content: center;
    `}>
      <div css={css`
        display: flex;
        
        width: fit-content;
        position: relative;
      `}>
        {dates.map((date, index) =>
          <div key={date} css={css`
            ${index !== dates.length - 1 && css`margin-right: 12px;`}
          `}>
            <DayColumnComponent date={date} />
          </div>
        )}
        <NoonLabel/>
      </div>
    </div>
  )
}

const DaySelector = ({ dates }) => {
  const { DayColumnComponent } = useContext(CalendarViewContext)

  const [selectedDay, setSelectedDay] = useState(0)

  return (
    <div>
      <AnimatedSlidingWrapper currentItemIndex={selectedDay}>
        {dates.map(date =>
          <div css={css`
            width: 100%;
            padding: 16px 48px;
          `}>
            <div css={css`position: relative;`}>
              <DayColumnComponent date={date} />
              <NoonLabel />
            </div>
          </div>
        )}
      </AnimatedSlidingWrapper>
      <DaySelectionArrows selectedDay={selectedDay} setSelectedDay={setSelectedDay} totalDays={dates.length} />
    </div>
  )
}

const DaySelectionArrows = ({ selectedDay, setSelectedDay, totalDays }) => {
  const leftArrowEnabled = selectedDay > 0
  const rightArrowEnabled = selectedDay < totalDays - 1
  
  const leftArrowPressed = () => {
    if (leftArrowEnabled) {
      setSelectedDay(day => day - 1)
    }
  }

  const rightArrowPressed = () => {
    if (rightArrowEnabled) {
      setSelectedDay(day => day + 1)
    }
  }

  return (
    <div css={css`
      position: absolute;
      top: 16px;
      left: 0px;
      width: 100%;
      padding: 0px 16px;
    `}>
      <div css={css`
        width: 100%;
        display: flex;
        justify-content: space-between;
      `}>
        <div onClick={leftArrowPressed} css={css`display: flex; align-items: center;`}>
          <Icon type={TYPE_RIGHT_CARET_UNSET_FILL} css={css`stroke: ${leftArrowEnabled ? FC_DARK_BLUE : NEUTRAL_50}; transform: rotate(180deg);`} />
        </div>
        <div onClick={rightArrowPressed} css={css`display: flex; align-items: center;`}>
          <Icon type={TYPE_RIGHT_CARET_UNSET_FILL} css={css`stroke: ${rightArrowEnabled ? FC_DARK_BLUE : NEUTRAL_50};`} />
        </div>
      </div>
    </div>
  )
}

const NoonLabel = () => {
  const { rowHeight: height } = useContext(CalendarViewContext)

  return (
    <div css={css`
      position: absolute;
      top: ${height / 2 + 28}px;
      left: 0%;
      height: 1px;
      width: 100%;
      background-color: ${NEUTRAL_50};
      pointer-events: none;
    `}>
      <Text style={TEXT_STYLES.CAPTION} customCss={css`position: absolute; right: -40px; top: -8px; color: ${NEUTRAL_60};`}>
        Noon
      </Text>
    </div>
  )
}