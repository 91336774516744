/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSetUserPreferences } from '../fetch/endpoints';
import { FlowButton } from '../components/flowComponents';
import { useMediaQuery } from 'react-responsive';

export const WelcomeMessage = ({ onHide }) => {
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})

  const { performFetch: setUserPreference } = useSetUserPreferences()
  const setMessageSeen = () => {
    setUserPreference({ newPreferenceData: { spaceWelcomeMessageSeen: true } })
  }

  const onClick = () => {
    setMessageSeen()
    onHide()
  }

  return (
    <div css={css`
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      width: ${mobileView ? '360px' : '500px'};
      margin: 0 20px;
    `}>
      <div css={css`
        font-family: 'Sato';
        font-size: 32px;
        line-height: 32px;
        color: #FFFFFF;
        margin-bottom: 24px;
      `}>
        Welcome to the Flow Club Lounge
      </div>
      <div css={css`
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        margin-bottom: 32px;
      `}>
        We launched this as an experiment to see if we can bring some
        of the magic of a Flow Club session to a different setting.<br/><br/>
        Write down your goals and start the timer.
        You'll be able to see fellow Flow Club members and their goals as you work.<br/><br/>
        We'd love to hear any feedback at members@flow.club!
      </div>
      <FlowButton onClick={onClick}>Enter Lounge</FlowButton>
    </div>
  )
}