import { 
    Redirect,
    useRouteMatch,
    useLocation
} from 'react-router-dom';

function Invite() {
    const match = useRouteMatch()
    const inviteCode = match.params.code
    const queryParams = useLocation().search.slice(1)
    return <Redirect to={`/upcoming?inviteCode=${inviteCode}${queryParams.length > 0 ? `&${queryParams}` : ""}`}/>
}

export default Invite;
