import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import emojiRegex from 'emoji-regex';

import { usePreSignupUserDataProperty, UserContext } from './UserProvider';
import { toastError, toastSuccess } from './components/utils/toaster';
import { useUpdateUser } from './fetch/endpoints';
import { MAX_EMOJIS_IN_DISPLAY_NAME } from '../functions/shared/constants';

export const IS_PROD = process.env.REACT_APP_ENVIRONMENT === "production"

export const rsplit = (source, sep, maxsplit) => {
    const split = source.split(sep);
    return maxsplit
    ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
    : split;
};

// TODO (David, 2021-10-11): Replace with lodash's version since we have that as a dependency now
export const groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

// Moves an item in an array to a new index, shifting all intervening items to accomodate
export const reorder = (array, sourceIndex, destinationIndex) => {
  const reorderedItems = Array.from(array);
  const [removed] = reorderedItems.splice(sourceIndex, 1);
  reorderedItems.splice(destinationIndex, 0, removed);

  return reorderedItems;
}

// a generous definition of 'valid' 🙃
export const emailIsValid = email => email.match(/.+@.+\..+/) !== null

// not super robust and a little duplicated with elsewhere, but taking the time to do this well doesn't seem amazingly appealing right now
export const copyToClipboard = async (text, { onSuccessMessage } = { onSuccessMessage: 'Copied!' }) => {
  const copyErrorText = "Sorry, your browser doesn't support this. Please select the link and copy it manually!";
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text)
    toastSuccess({ message: onSuccessMessage })
  } else {
    toastError({ message: copyErrorText })
  }
}

/*
 * Get the number of days between two javascript Date objects,
 * rounded to the nearest integer number of days, always positive.
 */
export const getDaysBetween = (startDate, endDate) => {
  const diff = Math.abs(endDate.getTime() - startDate.getTime())
  return Math.round(diff / (1000 * 60 * 60 * 24))
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export const usePreviousSticky = (value) => {
  const lastDifferentValue = useRef(null);
  const lastValue = useRef(value);

  if (value !== lastValue.current) {
    lastDifferentValue.current = lastValue.current
    lastValue.current = value
  }

  return lastDifferentValue.current;
}

export const useDelay = (delay) => {
  const [delayElapsed, setDelayElapsed] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayElapsed(true)
    }, delay)
    
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return delayElapsed
}

export const useDebounced = (callback, delay, finishLastCallIfCanceled = false) => {
  const timer = useRef(null)
  const lastArgs = useRef(null)

  const debouncedFunction = (...args) => {
    return new Promise(resolve => {
      lastArgs.current = args
      if (timer.current !== null) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        resolve(callback(...args))
        timer.current = null
      }, delay)
    })
  }

  const cancel = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }

  useEffect(() => {
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current)
        if (finishLastCallIfCanceled) {
          callback(...lastArgs.current)
        }
      }
    }
  }, [])

  return [debouncedFunction, cancel]
}

export const useAuthToken = () => {
  const { authUser } = useContext(UserContext)

  return authUser !== null ? authUser.getIdToken.bind(authUser) : () => null
}

export const useUserOrPreSignupUserData = (property) => {
  const { user, preSignupUserData } = useContext(UserContext)
  return (user ?? preSignupUserData)[property]
}

export const useABTestVariation = (abTestUserProperty, options) => {
  const { setPreSignupUserData, user } = useContext(UserContext)
  const { performFetch: updateUser } = useUpdateUser()

  const variation = usePreSignupUserDataProperty(abTestUserProperty)

  useEffect(() => {
    if (variation === undefined) {
      const newVariation = options[Math.floor(Math.random() * options.length)]
      const updateObject = { [abTestUserProperty]: newVariation }
      if (user === null) {
        setPreSignupUserData(updateObject)
      } else {
        updateUser({ updateObject })
      }
    }
  }, [variation])

  return variation
}

/**
 * - Returns a component along with a function that can be used to temporarily render it for a given duration.
 * - The component isn't rendered except while triggered.
 * - Intended for use with toast-esque things.
*/
export const useTriggerable = (Component) => {
  const [active, setActive] = useState(false)
  const interval = useRef(null)
  const triggerComponent = (duration) => {
    if (interval.current === null) {
      setActive(true)
      interval.current = setTimeout(() => {
        setActive(false)
        interval.current = null
      }, duration)
    }
  }

  useEffect(() => {
    return () => clearTimeout(interval.current)
  }, [])

  return [active ? Component : null, triggerComponent]
}

/**
 * - Takes a component and returns a ref-tracked version of it,
 * - calling the callback with updates on whether the component is within the browser viewport or not
 * - The provided component must expose a ref in order to function
*/
export const useWithVisibility = (Component, callback, options = {}) => {
  const componentRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        callback(entry.isIntersecting);
      }, { threshold: 0, rootMargin: '-160px 0px 0px 0px', ...options }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [componentRef.current]);

  const TrackedComponent = <Component ref={componentRef} />

  return TrackedComponent
};

/**
 * a somewhat hacky solution to force components to automatically re-render periodically, to access updated state that doesn't reside in the react state hierarchy
 * (localstorage, current date/time, data we want to poll for, etc)
 * @param {number | null} period how often to re-render the component, in ms
 */
export const useRefresh = (period = null) => {
  const [refreshedAt, setRefreshedAt] = useState(null)
  const activeInterval = useRef(null)
  useEffect(() => {
    if (activeInterval.current !== null) {
      clearInterval(activeInterval.current)
      activeInterval.current = null
    }

    if (period !== null) {
      activeInterval.current = setInterval(() => {
        setRefreshedAt(Date.now())
      }, period)
    }

    return () => {
      clearInterval(activeInterval.current)
      activeInterval.current = null
    }
  }, [period])

  return refreshedAt
}

export const useRerenderOnScreenResize = () => {
  const [refreshedAt, setRefreshedAt] = useState(null)
  const onResize = useCallback(() => {
    setRefreshedAt(Date.now())
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize)
  }, [])
  
  return refreshedAt
}

export const removeEmojis = (str) => str.replaceAll(emojiRegex(), '')

export const getOnlyEmojis = (str) => Array.from(str.matchAll(emojiRegex())).map(match => match[0]).join('')

export const containsEmojis = (str) => emojiRegex().test(str)

export const getVisibleLength = (str) => [...new Intl.Segmenter().segment(str)].length

export const sliceEmojiString = (str, maxLength) => [...new Intl.Segmenter().segment(str)].slice(0, maxLength).map(segment => segment.segment).join('')


export const checkForOnlyEmojisAndMaxLength = (e) => {
  const inputElement = e.target
  const cleanedValue = getOnlyEmojis(inputElement.value)
  inputElement.value = cleanedValue
  try {
    const visibleLength = getVisibleLength(cleanedValue)
    if (visibleLength > MAX_EMOJIS_IN_DISPLAY_NAME) {
      const cleanedAndSlicedValue = sliceEmojiString(cleanedValue, MAX_EMOJIS_IN_DISPLAY_NAME)
      inputElement.value = cleanedAndSlicedValue
      toastError({ message: `You can only have up to ${MAX_EMOJIS_IN_DISPLAY_NAME} emojis in your display name.`})
      return cleanedAndSlicedValue
    }
  } catch(e) {
    console.log(e)
  }

  return cleanedValue
}

export const openInSessionPage = (sessionLink) => {
  window.open(
    sessionLink,
    'sessionWindow',
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1200,height=800`
  )
}

export const isInAppBrowser = () => {
  const ua = navigator.userAgent
  const isAndroid = /Android/.test(ua)
  const isIOS = /iPad|iPhone|iPod/.test(ua)
  const isInApp = (/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(ua) ||
  /FBAN/.test(ua) ||
  /FBAV/.test(ua) ||
  /Instagram/.test(ua) ||
  /LinkedInApp/.test(ua) ||
  /Twitter/.test(ua) ||
  // Android in-app browsers
  /FB_IAB/.test(ua) ||
  /Android.*wv/.test(ua))

  return {
    isAndroid,
    isIOS,
    isInApp,
  }
}
