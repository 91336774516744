/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useState } from 'react'
import { BUTTON_STYLES, FlowButton, LightGrayText, Text, TextArea, TEXT_STYLES } from '../components/flowComponents'
import { toastError } from '../components/utils/toaster'
import { useSubmitPostFlowData, useSubmitSharedWin } from '../fetch/endpoints'
import { Checkmark, SparklyCurve } from '../shared/components/icons'
import { UserContext } from '../UserProvider'
import { Link } from 'react-router-dom'
import { getImageForHost, getOrdinalNumber, getUserAverageFlowScore } from '../../functions/shared/helpers'
import { FlowScoreButtons } from '../components/FlowScoreButtons'
import dayjs from 'dayjs'
import { HostIncentiveProgress, useHostIncentiveProgress } from '../HostIncentiveProgress'
import { useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { TEXT_60_PERCENT, FC_LIGHTER_BLUE, FC_BACKGROUND_GRAY, FC_BLUE } from '../emotionVariables'
import { Alert, Form } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import { FlowDefinitionTooltipWrapper, ExperienceFlowTooltipWrapper } from '../components/FlowTooltips'
import { FollowButton, HostProfileInfoContext } from '../HostProfile'
import { toastSuccess } from '../components/utils/toaster'


const getUpcomingSessionIsWithin15Min = nextUpcomingSessionTime => nextUpcomingSessionTime.isBefore(dayjs().add('15', 'minute'))
const getDayjsObjectForParticipant = participant => participant !== null ? dayjs.unix(participant.start._seconds) : null

export const PostSessionFeedbackScreen = ({ eventId, postFlowInfo, participant, error }) => {
  const mobileView = useMediaQuery({query: '(max-width: 420px)'})
  const { user } = useContext(UserContext)
  const { hostData, nextUpcomingParticipant, totalSessions, hostedSessions, eventAttendanceRun, eventData, badgesUserCanSubmitSharedWinsFor } = postFlowInfo
  const { flowScore, hostFeedback, siteFeedback } = participant

  const userIsHost = user.uid === eventData.hostId

  const totalSessionsAfter = totalSessions + (eventAttendanceRun ? 0 : 1)
  const hostedSessionsAfter = hostedSessions + (eventAttendanceRun ? 0 : 1)
  const nextUpcomingSessionTime = getDayjsObjectForParticipant(nextUpcomingParticipant)

  return (
    <div css={css`
      width: ${mobileView ? '100%' : '420px'};
      display: flex;
      flex-flow: column;
      align-items: center;
      padding-bottom: 120px;
      ${mobileView && css` padding-left: 10px; padding-right: 10px;`}
    `}>
      {error !== null && <Alert variant="danger" css={css`font-size: 16px; color: white;`}>{error}</Alert>}
      <Congratulations totalSessions={totalSessionsAfter} hostedSessions={hostedSessionsAfter} userIsHost={userIsHost} nextUpcomingSessionTime={nextUpcomingSessionTime} />
      <FlowScoreInput eventId={eventId} flowScore={flowScore} userIsHost={userIsHost}/>
      {!userIsHost && <HostFeedback eventId={eventId} hostData={hostData} hostFeedback={hostFeedback} />}
      {badgesUserCanSubmitSharedWinsFor.length > 0 && <ShareWinToClubPrompt badges={badgesUserCanSubmitSharedWinsFor} eventId={eventId} userIsHost={userIsHost} />}
      <SiteFeedback eventId={eventId} userIsHost={userIsHost} hostName={hostData.displayName} siteFeedback={siteFeedback} />
      <HostIncentiveProgressSection eventAttendanceRun={eventAttendanceRun} userIsHost={userIsHost} />
    </div>
  )
}

const Congratulations = ({ totalSessions, hostedSessions, userIsHost, nextUpcomingSessionTime }) => {
  const { user } = useContext(UserContext)
  // ugly janky-data edge case handling
  const userDisplayName = user.displayName || user.email

  const title = userIsHost ? `Thanks for hosting your ${getOrdinalNumber(hostedSessions)} Flow Club (${getOrdinalNumber(totalSessions)} completed overall)!` : 'You finished a Flow Club!'
  const subtitle = userIsHost ? `Thanks for holding space for others to get great work done, ${userDisplayName}! 🙏` : `Congratulations on ${totalSessions > 1 ? `${totalSessions} sessions` : 'your first session'}! You are awesome.`
  return (
    <div css={css`border-bottom: 1px solid #DEDEDE; padding-bottom: 32px;`}>
      <SparklyCurve />
      <h4 css={css`margin-top: 36px; margin-bottom: 16px;`}>{title}</h4>
      <LightGrayText>{subtitle}</LightGrayText>
      {nextUpcomingSessionTime !== null && <UpcomingSessionMessage nextUpcomingSessionTime={nextUpcomingSessionTime} userTimezone={user.userTimezone} />}
    </div>
  )
}

const getMessageForSessionTime = (nextUpcomingSessionTime, userTimezone) => {
  const upcomingSessionDate = nextUpcomingSessionTime.tz(userTimezone).format('YYYY/MM/DD')
  const currentDate = dayjs().tz(userTimezone).format('YYYY/MM/DD')
  const upcomingSessionIsWithin15Min = getUpcomingSessionIsWithin15Min(nextUpcomingSessionTime)
  const upcomingSessionIsDifferentDay = upcomingSessionDate !== currentDate

  if (upcomingSessionIsWithin15Min) {
    return 'in a few minutes'
  } else if (!upcomingSessionIsDifferentDay) {
    return `at ${nextUpcomingSessionTime.format('h:mma')}`
  } else {
    return `at ${nextUpcomingSessionTime.format('h:mma on ddd MMM D')}`
  }
}
const UpcomingSessionMessage = ({ nextUpcomingSessionTime, userTimezone }) => {
  const nextUpcomingSessionMessage = getMessageForSessionTime(nextUpcomingSessionTime, userTimezone)

  return <LightGrayText>See you at your next Flow Club {nextUpcomingSessionMessage}!</LightGrayText>
}

export const FlowScoreInput = ({ eventId, flowScore: existingFlowScore = null, updateUserFlowScore = false, callback = (flowScore) => {}, userIsHost = false }) => {

  const { user } = useContext(UserContext)
  const mobileView = useMediaQuery({query: '(max-width: 420px)'})
  const [flowScoreSubmitted, setFlowScoreSubmitted] = useState(existingFlowScore !== null)
  const [localFlowScore, setLocalFlowScore] = useState(existingFlowScore)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: submitPostFlowData } = useSubmitPostFlowData()
  const averageFlowScore = getUserAverageFlowScore(user)

  const onSubmitFlowScore = async ({ flowScore }) => {
    setFlowScoreSubmitted(true)
    setLocalFlowScore(flowScore)
    const { success, error } = await submitPostFlowData({ eventId, property: 'flowScore', value: flowScore, updateUserFlowScore })
    sendSegmentEvent("Flow Score Submitted", { flowScore, eventId })
    if (!success) {
      setFlowScoreSubmitted(false)
      console.error(error)
      toastError({ message: "There was an error submitting your flow score. Please try again or contact members@flow.club and we'll get this resolved." })
    } else {
      callback(flowScore)
    }
  };

  return (
    <div>
      <h4 css={css`margin-top: 64px; margin-bottom: 12px;`}>How close to flow were you?</h4>
      <LightGrayText customCss={css`margin-bottom: 24px;`}>Rating: 1 = Completely distracted, 
      <FlowDefinitionTooltipWrapper mobileView={mobileView} desktopWidth={420}>
        <span css={css`cursor: pointer; margin-left: 8px; text-decoration: underline; color: ${FC_BLUE}; font-weight: bold;`} onMouseEnter={() => sendSegmentEvent("Moused over flow definition")}>10 = Immersed in flow</span>
      </FlowDefinitionTooltipWrapper>
      </LightGrayText>
      {flowScoreSubmitted ?
        <div css={css`
          margin-top: 8px;
        `}>
          <div>
            <LightGrayText>This session</LightGrayText>
            <Text style={TEXT_STYLES.APP_H2} customCss={css`margin-bottom: 8px; color: ${FC_BLUE}`}>{localFlowScore}</Text>
          </div>
          <div>
            {user.scoredSessions <= 3 ?
              <LightGrayText>Rate a few more sessions to see insights about achieving flow state.</LightGrayText>
              :
              <LightGrayText>This is {averageFlowScore < localFlowScore ? 'higher than': averageFlowScore == localFlowScore ? 'about the same as' : 'lower than'} your average of {averageFlowScore}.</LightGrayText>
            }
            {localFlowScore < 8 ?
              <LightGrayText customCss={css`margin-top: 8px;`}>Great job pausing to reflect!
              <ExperienceFlowTooltipWrapper mobileView={mobileView} desktopWidth={420}>
                <span css={css`cursor: pointer; margin-left: 8px; text-decoration: underline; color: ${FC_BLUE}; font-weight: bold;`} onMouseEnter={() => sendSegmentEvent("Moused over flow tips")}>
                  Tips to experience more flow.
                </span>
              </ExperienceFlowTooltipWrapper>
              </LightGrayText> :
              <LightGrayText customCss={css`margin-top: 8px;`}>🥳 Congrats on a great session!{updateUserFlowScore || userIsHost ? '': ' Be sure to thank your host.'}</LightGrayText>
            }
          </div>
        </div>
      :
        <div css={css`opacity: ${flowScoreSubmitted ? '0.5' : '1'};`}>
          <FlowScoreButtons onSubmitFlowScore={onSubmitFlowScore} presetFlowScore={existingFlowScore} />
        </div>
      }
    </div>
  )
}

export const ShareWinToClubPrompt = ({ badges, eventId, userIsHost = false, onSuccess = () => {}}) => {
  const [text, setText] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: submitSharedWin } = useSubmitSharedWin()
  const [selectedBadgeIndex, setSelectedBadgeIndex] = useState(0)
  const { id: badgeId, clubPageSlug, clubTitle, winsPlaceholders } = badges[selectedBadgeIndex]

  if (submitted) {
    return <div css={css`margin-top: 32px; border-top: 1px solid #DEDEDE; padding 32px 0px; width: 100%;`}>
      <LightGrayText customCss={css`margin-top: 24px;`}>Win submitted! 
        {eventId === undefined ? <></> :
        <>It'll now be visible on the <Link target="_blank" rel="noreferrer noopener" to={`/club/${clubPageSlug}/`}>club page</Link>!</>}
        </LightGrayText>
      <Checkmark width={18} height={13} /><br/>
      My small win:<br/>
      <Text customCss={css`margin-top: 12px; padding: 8px; border: 1px solid ${FC_LIGHTER_BLUE}; background: ${FC_BACKGROUND_GRAY};`}>{text}</Text>
    </div>

  }

  const onSubmit = async (event) => {
    event.preventDefault()

    setSubmitted(true)
    const { success, error } = await submitSharedWin({ badgeId, text, eventId })
    sendSegmentEvent("Submitted Shared Win", { from: "postSession", isHost: userIsHost, badgeId, text, eventId })
    if (!success) {
      setSubmitted(false)
      console.error(error)
      toastError({ message: "There was an error submitting your win. Please try again or contact members@flow.club and we'll get this resolved." })
    } else {
      onSuccess()
    }
  }

  const disabled = text.length <= 0
  const defaultPlaceholder = "Remembered to drink water\nSent an email I've been dreading\nOvercame a distraction and got back on track"
  const placeholder = winsPlaceholders !== undefined && winsPlaceholders.length > 0 ? winsPlaceholders.join('\n') : defaultPlaceholder



  return (
    <div css={css`margin-top: 32px; border-top: 1px solid #DEDEDE; padding: 32px 0px;`}>
      <Text style={TEXT_STYLES.APP_H5} customCss={css`margin-bottom: 8px;`}>Share a small win {eventId !== undefined ? `from the session with the ${clubTitle} ` : ''}🎉</Text>
      {eventId !== undefined &&<Text customCss={css`color: ${TEXT_60_PERCENT}; margin-bottom: 8px;`}>You can see small wins from others on the <Link target="_blank" rel="noreferrer noopener" to={`/club/${clubPageSlug}/`}>club page</Link></Text>}
      <form onSubmit={onSubmit}>
        {badges.length > 1 &&
          <div css={css`display: flex; align-items: center; gap: 8px; margin-bottom: 8px;`}>
            <Text customCss={css`flex-shrink: 0;`}>Share to:</Text>
            <Form.Control
              as="select"
              value={selectedBadgeIndex}
              onChange={event => setSelectedBadgeIndex(event.target.value)}
            >
              {badges.map((badge, index) =>
                <option
                  key={badge.id}
                  value={index}
                >
                  {badge.clubTitle}
                </option>
              )}
            </Form.Control>
          </div>
        }
        <TextArea customCss={css`margin-bottom: 12px;`} placeholder={placeholder} value={text} onChange={event => setText(event.target.value)} label={'Post on club page'}></TextArea>
        <FlowButton fillAvailableWidth type={'submit'} buttonStyle={BUTTON_STYLES.SECONDARY} disabled={disabled}>Post</FlowButton>
      </form>
    </div>
  )
}

const HostFeedback = ({ eventId, hostData, hostFeedback: existingHostFeedback = null }) => {
  const [ localFavorited, setLocalFavorited ] = useState(hostData.isFollowed)
  const hostDataForFollowButton = {
    ...hostData,
    isFollowed: localFavorited,
    refetchHostProfileData: () => {
      setLocalFavorited(!localFavorited)
      toastSuccess({ message: `You ${localFavorited ? 'unfavorited' : 'favorited'} ${hostData.displayName}!` })
    }
  }
  return (
    <div>
      <h4 css={css`margin-top: 32px; padding-top: 32px; border-top: 1px solid #DEDEDE; margin-bottom: 8px;`}>Thank your host</h4>
      <LightGrayText customCss={css`margin-bottom: 32px;`}>Hosts are volunteers who contribute to the Flow Club community. Showing your support means a lot!</LightGrayText>
      <div css={css`display:flex; flex-direction: row; justify-content: center; align-items: top; gap: 16px;`}>
        <div css={css`display: flex; flex-direction: column; align-items: center;`}>
          <img
            css={css`
              width: ${64}px;
              height: ${64}px;
              border-radius: 50%;
              margin-bottom: 8px;

            `}
            src={getImageForHost(hostData, 'image64')}
            alt={hostData.displayName}
          />
          <a href={`/host/${hostData.slug}`} target="_blank" rel="noreferrer" css={css`
            font-family: 'Sato';
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 8px;
            display: block;
          `}>{hostData.displayName}</a>
          <LightGrayText customCss={css`
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            margin-bottom: 36px;
          `}>Your Flow Club Host</LightGrayText>
        </div>
        <div css={css`width: 180px; display: flex; flex-direction: column; align-items: center; gap: 16px; vertical-align: text-top;`}>
          <HostProfileInfoContext.Provider value={hostDataForFollowButton}>
            <FollowButton />
          </HostProfileInfoContext.Provider>
          {!hostData.hideHostFollowButton && <Link to={`/settings/clubs-and-hosts/`} target="_blank" rel="noreferrer noopener">
            Manage Favorites
          </Link>}
          <Link to={`/host/${hostData.slug}`} target="_blank" rel="noreferrer noopener">
            <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} css={css`margin-top: 8px;`}>
              View Profile
            </FlowButton>
          </Link>
        </div>
      </div>

      <HostFeedbackForm eventId={eventId} hostName={hostData.displayName} existingHostFeedback={existingHostFeedback} />
    </div>
  )
}

const HostFeedbackForm = ({ eventId, hostName, existingHostFeedback = null }) => {
  const [hostFeedback, setHostFeedback] = useState(existingHostFeedback ?? '')
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: submitPostFlowData } = useSubmitPostFlowData()

  if (feedbackSubmitted) {
    return <div css={css`margin-top: 64px;`}>
      <Checkmark width={18} height={13} />
      <LightGrayText customCss={css`margin-top: 24px;`}>We’ll send your message to {hostName}</LightGrayText>
    </div>
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    setFeedbackSubmitted(true)
    const { success, error } = await submitPostFlowData({ eventId, property: 'hostFeedback', value: hostFeedback })
    sendSegmentEvent("Submitted Feedback", { feedback: hostFeedback, isHost: false, hostName, eventId, feedbackType: 'host' })
    if (!success) {
      setFeedbackSubmitted(false)
      console.error(error)
      toastError({ message: "There was an error submitting your feedback. Please try again or contact members@flow.club and we'll get this resolved." })
    }
  }

  const disabled = hostFeedback.length <= 0

  return (
    <form onSubmit={onSubmit}>
      <TextArea customCss={css`margin-bottom: 12px;`} placeholder='Thanks for a great session' value={hostFeedback} onChange={event => setHostFeedback(event.target.value)} label={'Write a message'}></TextArea>
      <FlowButton fillAvailableWidth type={'submit'} buttonStyle={BUTTON_STYLES.SECONDARY} disabled={disabled}>{existingHostFeedback !== null ? 'Re-' : ''}Submit</FlowButton>
    </form>
  )
}

const SiteFeedback = ({ eventId, userIsHost, hostName, siteFeedback: existingSiteFeedback = null }) => {
  const title = 'Share Feedback with Flow Club Team'
  return (
    <div css={css`border-top: 1px solid #DEDEDE; margin-top: 32px;`}>
      <h4 css={css`margin-top: 32px; margin-bottom: 8px;`}>{title}</h4>
      <LightGrayText customCss={css`margin-bottom: 36px;`}>Share any issues or feedback for the Flow Club team about the session.</LightGrayText>
      <SiteFeedbackForm eventId={eventId} userIsHost={userIsHost} hostName={hostName} existingSiteFeedback={existingSiteFeedback} />
    </div>
  )
}

const SiteFeedbackForm = ({ eventId, userIsHost, hostName, existingSiteFeedback = null }) => {
  const [siteFeedback, setSiteFeedback] = useState(existingSiteFeedback ?? '')
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: submitPostFlowData } = useSubmitPostFlowData()

  if (feedbackSubmitted) {
    return <div css={css`margin-top: 64px;`}>
      <Checkmark width={18} height={13} />
      <LightGrayText customCss={css`margin-top: 24px;`}>Thanks for your feedback</LightGrayText>
    </div>
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    setFeedbackSubmitted(true)
    const { success, error } = await submitPostFlowData({ eventId, property: 'siteFeedback', value: siteFeedback })
    sendSegmentEvent("Submitted Feedback", { feedback: siteFeedback, isHost: userIsHost, hostName, eventId, feedbackType: 'site' })
    if (!success) {
      setFeedbackSubmitted(false)
      console.error(error)
      toastError({ message: "There was an error submitting your feedback. Please try again or contact members@flow.club and we'll get this resolved." })
    }
  }

  const disabled = siteFeedback.length <= 0

  const placeholderText = userIsHost ? 'How did the session go? Any wins to celebrate?' : 'I wish...'

  return (
    <form css={css`width: 100%;`} onSubmit={onSubmit}>
      <TextArea customCss={css`margin-bottom: 12px;`} placeholder={placeholderText} value={siteFeedback} onChange={event => setSiteFeedback(event.target.value)} label={'Write a message'}></TextArea>
      <FlowButton fillAvailableWidth type={'submit'} disabled={disabled} buttonStyle={BUTTON_STYLES.SECONDARY}>{existingSiteFeedback !== null ? 'Re-' : ''}Submit</FlowButton>
    </form>
  )
}

const HostIncentiveProgressSection = ({ eventAttendanceRun, userIsHost }) => {
  let progressData = useHostIncentiveProgress()
  const sendSegmentEvent = useSendSegmentEvent()

  if (!progressData.displayProgress) {
    return null
  }

  if (!eventAttendanceRun && userIsHost ) {
    progressData = {
      ...progressData,
      completedSessions: progressData.completedSessions + 1,
      pendingSessions: progressData.pendingSessions - 1
    }
  }

  return (
    <Link to="/settings/hosting" css={css`border-top: 1px solid #DEDEDE; margin-top: 64px; padding-top: 64px;`} onClick={() => sendSegmentEvent("Clicked on Host Incentive Progress")}>
      <HostIncentiveProgress progressData={progressData} />
    </Link>
  )
}