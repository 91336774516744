/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { Text } from '../components/flowComponents';
import { TEXT_60_PERCENT } from '../emotionVariables';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

export const CreateSessionPrompt = () => {
  const sendSegmentEvent = useSendSegmentEvent()
  const imageClicked = () => {
    sendSegmentEvent("Create Session Image Clicked")
  }
  
  return (
    <div css={css`display: flex; flex-flow: column; justify-content: center; align-items: center;`}>
      <h4 css={css`margin-top: 36px; margin-bottom: 16px;`}>Host the next Flow Club</h4>
      <Text customCss={css`margin-bottom: 24px; color: ${TEXT_60_PERCENT};`}>
        Take initiative and host the next session.<br />Bookmark the next page to create more sessions for your team.
      </Text>
      <Link to={"/upcoming/create/"} onClick={imageClicked} css={css`
        transition: transform .5s cubic-bezier(.39,1.99,.69,1.01);
        &:hover, &:active {
          transform: scale(1.05);
        }
      `}>
        <img width={440} src={'https://storage.googleapis.com/flowclub-static/images/create-session-preview.png'} alt="Create Session Preview" />
      </Link>
    </div>
  )
}