/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { useFloating } from '@floating-ui/react-dom-interactions'
import { cloneElement, forwardRef, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import Icon, { TYPE_CHECKMARK, TYPE_CLOSE, TYPE_RIGHT_CARET_UNSET_FILL, TYPE_HELP} from '../daily/daily-components/Icon/Icon'
import { FC_BLUE, FC_DARK_BLUE, FC_IRIS_BLUE, FC_LIGHT_BLUE, FC_LIGHTER_BLUE, TEXT_60_PERCENT, TEXT_87_PERCENT, NEUTRAL_50, LINK_MAIN, LINK_ACTIVE, NEUTRAL_40, NEUTRAL_20, NEUTRAL_60, NEUTRAL_30, FC_GREEN, FC_LIGHT_GREEN } from '../emotionVariables'
import { usePreviousSticky } from '../utils'
import { shift, offset } from '@floating-ui/react-dom';
import { Link } from 'react-router-dom'
import { animated, useSpring } from '@react-spring/web'
import { AnimatedSlidingWrapper } from './AnimatedSlidingWrapper'
import { useClickOutside } from '../Sessions/Schedule/useClickOutside'
import data from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'
import { useMediaQuery } from 'react-responsive'

export const LightGrayText = ({ children, customCss }) =>
  <div css={css`color: ${TEXT_60_PERCENT}; ${customCss}`}>{children}</div>

export const DarkText = ({ children, customCss }) => 
  <div css={css`color: ${TEXT_87_PERCENT}; ${customCss}`}>{children}</div>


export enum BUTTON_STYLES {
  PRIMARY,
  SECONDARY,
  NO_BACKGROUND,
  OUTLINE_DARK,
  OUTLINE_LIGHT, // Only for use against dark backgrounds. White text with a transparent background.
  OUTLINE_SECONDARY,
  GREEN,
  GREEN_OUTLINE,
}

export const SIZE_PRESETS = {
  STANDARD: css`height: 38px;`,
  CHUNKY: css`height: 48px;`,
}

const getButtonStyleValues = (disabled, buttonStyle) => {
  if (buttonStyle === BUTTON_STYLES.PRIMARY) {
    const enabledStyles = css`
      background-color: ${FC_DARK_BLUE};
      &:hover { background-color: ${FC_BLUE}; }
      &:active { background-color: ${FC_BLUE}; }
      color: #FFFFFF;
    `
    const disabledStyles = css`
      background-color: #D1D1D1;
      color: #767676;
    `

    return css`
      ${disabled ? disabledStyles : enabledStyles}
      border: none;
    `
  } else if (buttonStyle === BUTTON_STYLES.NO_BACKGROUND) {
    const enabledStyles = css`
      &:hover { background-color: #EBEDF0; }
      &:active { background-color: #EBEDF0; }
      &:focus { outline: none; background-color: #EBEDF0; }
      color: ${FC_DARK_BLUE}; 
    `
    const disabledStyles = css`
      color: #767676;
    `

    return css`
      ${disabled ? disabledStyles : enabledStyles}
      background-color: transparent;
      border: none;
    `
  } else if (buttonStyle === BUTTON_STYLES.SECONDARY) {
    const enabledStyles = css`
      background-color: ${FC_BLUE};
      &:hover { background-color: ${FC_IRIS_BLUE}; }
      &:active { background-color: ${FC_IRIS_BLUE}; }
      color: #FFFFFF;
    `
    const disabledStyles = css`
      background-color: #D1D1D1;
      color: #767676;
    `

    return css`
      ${disabled ? disabledStyles : enabledStyles}
      border: none;
    `
  } else if (buttonStyle === BUTTON_STYLES.OUTLINE_DARK) {
    const enabledStyles = css`
      color: ${FC_DARK_BLUE};
      border-color: ${FC_DARK_BLUE};
      background-color: transparent;
      &:hover, &:active, &:focus { background-color: ${FC_LIGHT_BLUE}; }
    `
    const disabledStyles = css`
      background-color: #D1D1D1;
      border-color: #D1D1D1;
      color: #767676;
    `

    return css`
      border: 1px solid;
      ${disabled ? disabledStyles : enabledStyles}
    `
  } else if (buttonStyle === BUTTON_STYLES.OUTLINE_LIGHT) {
    const enabledStyles = css`
      border-color: rgba(255, 255, 255, 0.85);
      color: black;
      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
        border-color: white;
      }
    `
    const disabledStyles = css`
      color: rgba(255, 255, 255, 0.65);
      border-color: rgba(255, 255, 255, 0.65);
    `

    return css`
      border: 1px solid;
      ${disabled ? disabledStyles : enabledStyles}
      background-color: transparent;
    `
  } else if (buttonStyle === BUTTON_STYLES.OUTLINE_SECONDARY) {
  const enabledStyles = css`
    color: ${FC_BLUE};
    border-color: ${FC_BLUE};
    background-color: transparent;
    &:hover, &:active, &:focus { background-color: ${FC_LIGHT_BLUE}; }
  `
  const disabledStyles = css`
    background-color: #D1D1D1;
    border-color: #D1D1D1;
    color: #767676;
  `

  return css`
    border: 1px solid;
    ${disabled ? disabledStyles : enabledStyles}
  `
  } else if (buttonStyle === BUTTON_STYLES.GREEN) {
    const enabledStyles = css`
      background-color: ${FC_GREEN};
      color: #FFFFFF;
      &:hover { background-color: ${FC_LIGHT_GREEN}; }
      &:active { background-color: ${FC_LIGHT_GREEN}; }
    `

    const disabledStyles = css`
      background-color: #D1D1D1;
      color: #767676;
    `

    return css`
      ${disabled ? disabledStyles : enabledStyles}
      border: none;
    `
  } else if (buttonStyle === BUTTON_STYLES.GREEN_OUTLINE) {
    const enabledStyles = css`
      color: ${FC_GREEN};
      border-color: ${FC_GREEN};
      background-color: transparent;
      &:hover, &:active, &:focus { background-color: ${FC_GREEN}; color: #FFFFFF; }
    `
    const disabledStyles = css`
      background-color: #D1D1D1;
      border-color: #D1D1D1;
      color: #767676;
    `

    return css`
      border: 1px solid;
      ${disabled ? disabledStyles : enabledStyles}
    `
  }
}

const buttonbaseStyle = css`
  padding: 11px 16px;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: 'Sato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const FlowButton = forwardRef<HTMLButtonElement, {
  children?,
  customCss?,
  onClick?,
  disabled?: boolean,
  loading?: boolean,
  type?,
  fillAvailableWidth?: boolean,
  buttonStyle?: BUTTON_STYLES,
  onMouseEnter?: (event) => void,
  onMouseLeave?: (event) => void,
  sizePreset?: typeof SIZE_PRESETS
}>(({
  children,
  customCss,
  onClick,
  disabled = false,
  loading = false,
  type = 'button',
  fillAvailableWidth = false,
  buttonStyle = BUTTON_STYLES.PRIMARY,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  sizePreset = SIZE_PRESETS.STANDARD
}, ref) => {
  return (
    <button
      ref={ref}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
      type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={css`
        ${sizePreset}
        ${fillAvailableWidth && css`width: 100%;`}
        ${buttonbaseStyle}
        cursor: ${disabled ? 'inherit' : 'pointer'};

        ${getButtonStyleValues(disabled, buttonStyle)}
        ${customCss}
      `}
    >
      {loading ? <LoadingIndicator/> : children}
    </button>
  )
})

export const FloatingBottomButton = forwardRef<HTMLButtonElement, {
  children?,
  customCss?,
  onClick?,
  disabled?: boolean,
  loading?: boolean,
  type?,
  buttonStyle?: BUTTON_STYLES,
  onMouseEnter?: (event) => void,
  onMouseLeave?: (event) => void,
}>(({
  children,
  customCss,
  onClick,
  disabled = false,
  loading = false,
  type = 'button',
  buttonStyle = BUTTON_STYLES.PRIMARY,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}, ref) => {
  return (
    <button
      ref={ref}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
      type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={css`
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 64px;
        height: 64px;
        line-height: 64px;
        padding: 0px;

        box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
        border-radius: 100%;
        font-size: 36px;
        z-index: 1000;
        cursor: ${disabled ? 'inherit' : 'pointer'};

        ${getButtonStyleValues(disabled, buttonStyle)}
        ${customCss}
      `}
    >
      {loading ? <LoadingIndicator/> : children}
    </button>
  )
})

export enum TEXT_INPUT_STYLES {
  PRIMARY,
  OUTLINE_LIGHT,
}

const getTextInputStyleValues = (inputStyle) => {
  if (inputStyle === TEXT_INPUT_STYLES.PRIMARY) {
    return css`
      background-color: #FFFFFF;
      border: 1px solid #C4C4C4;
      &:focus, &:focus-visible {
        outline-style: solid;
        outline-color: ${FC_BLUE};
      }
    `
  } else if (inputStyle === TEXT_INPUT_STYLES.OUTLINE_LIGHT) {
    return css`
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.25);
      color: rgba(255, 255, 255, 0.87);
      letter-spacing: 0.5px;
      &:hover {
        border-color: rgba(255, 255, 255, 0.87);
      }
      &:focus, &:focus-visible {
        outline-color: ${FC_IRIS_BLUE};
      }
    `
  }
}

export enum TEXT_INPUT_TYPES {
  TEXT = 'text',
  PASSWORD = 'password',
  PHONE_NUMBER = 'tel',
  EMAIL = 'email'
}

export const TextInput = forwardRef<HTMLInputElement, {
  customCss?,
  wrapperCustomCss?,
  value?: string,
  onChange?: (event) => void,
  onFocus?: (event) => void,
  onBlur?: (event) => void,
  disabled?: boolean,
  placeholder?: string,
  label?: string,
  type?: TEXT_INPUT_TYPES
  autofocus?: boolean,
  tabIndex?: number,
  readOnly?: boolean,
  sizePreset?: typeof SIZE_PRESETS,
  inputStyle?: TEXT_INPUT_STYLES
}>(({
  customCss,
  wrapperCustomCss,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  placeholder,
  label,
  type = TEXT_INPUT_TYPES.TEXT,
  autofocus = false,
  tabIndex = 0,
  readOnly = false,
  sizePreset = SIZE_PRESETS.STANDARD,
  inputStyle = TEXT_INPUT_STYLES.PRIMARY
}, ref) => {
  return (
    <div css={css`
      ${wrapperCustomCss}
    `}>
      {label && <LightGrayText customCss={css`
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 4px;
      `}>{label}</LightGrayText>}
      <input
        ref={ref}
        css={css`
          border: 1px solid;
          border-radius: 6px;
          width: 100%;
          ${sizePreset}
          padding: 12px;
          ${getTextInputStyleValues(inputStyle)}
          ${customCss}
        `}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        autoFocus={autofocus}
        tabIndex={tabIndex}
        readOnly={readOnly}
        disabled={disabled}
      />
    </div>
  )
})

export const TextArea = ({ customCss, wrapperCustomCss, value, onChange, placeholder, label, maxLength }) => {
  return (
    <div css={css`
      text-align: start;
      display: grid;
      ${wrapperCustomCss}
    `}>
      {label && <LightGrayText customCss={css`
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 6px;
      `}>{label}</LightGrayText>}
      <textarea css={css`
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        border-radius: 6px;
        width: 100%;
        height: 76px;
        padding: 6px 12px;
        resize: none;
        ${customCss}
        &:focus {
          outline-color: ${FC_BLUE};
        }
        &:placeholder-shown {
          letter-spacing: 0.5px;
        }
      `} placeholder={placeholder} value={value} onChange={onChange} maxLength={maxLength} />
    </div>
  )
}

export const LinkStyledText = ({ children, onClick, customCss = null }) => {
  return (
    <span css={css`
      color: ${LINK_MAIN};
      cursor: pointer;
      &:hover {
        color: ${FC_IRIS_BLUE};
      }
      &:active {
        color: ${LINK_ACTIVE};
      }
      ${customCss}
    `} onClick={onClick}>
      {children}
    </span>
  )
}

// happily accepting suggestions for a better name than this
export const DropdownToggleButton = ({ children: DropdownContent, label, customCss, onOpen = () => {}, onClose = () => {}, initiallyOpened = false, width = 264, height = 167, buttonStyle = null }) => {
  const [opened, setOpened] = useState(initiallyOpened)

  const ref = useRef(null)

  const closeDropdown = () => {
    setOpened(false)
    return opened !== false
  }

  const previousOpened = usePreviousSticky(opened)
  useEffect(() => {
    if (opened && previousOpened === false) {
      onOpen()
    } else if (!opened && previousOpened) {
      onClose()
    }
  }, [opened])

  const interactingStyle = css`
    background-color: ${FC_LIGHTER_BLUE};
    border-color: ${FC_DARK_BLUE};
    transform: scale(1.05);
  `

  const floatingUIData =  useFloating({
    open: opened,
    placement: 'bottom-start',
    middleware: [shift({ padding: 32 }), offset(4)],
  });
  const { x, y, reference, floating, strategy } = floatingUIData

  const onContainerDivMount = useCallback(element => {
    ref.current = element
    reference(element)
  }, [reference])

  const buttonCss = buttonStyle !== null && css`
    ${buttonbaseStyle}
    ${SIZE_PRESETS.STANDARD}
    ${getButtonStyleValues(false, buttonStyle)}
  `

  return (
    <>
      <div
        onClick={() => setOpened(!opened)}
        ref={onContainerDivMount}
        css={css`
          min-width: 114px;
          height: 34px;
          /* this is a bafflingly, comically absurd value, but it's what was in figma and it makes me laugh so it gets to stay */
          /* for context, in this case I believe anything over 17px has no effect */
          border-radius: 1216px;

          border: 1px solid ${NEUTRAL_50};
          &:hover, &:active {
            ${interactingStyle}
          }
          ${opened && interactingStyle};
          transition: border-color 0.2s, transform 0.2s;

          cursor: pointer;

          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          padding: 0px 16px;
          ${customCss}
          ${buttonCss}
        `}
      >
        <div css={css`
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.25px;
          ${!buttonStyle && css`color: ${FC_DARK_BLUE};`}
          flex-grow: 1;
          text-align: center
        `}>
          {label}
        </div>
        <div css={css`
          border: 4px solid; 
          border-color: transparent transparent ${FC_DARK_BLUE} transparent;
          transform: rotateZ(${opened ? '0' : '180'}deg) translateY(-2px);
          transition: transform 0.2s;
        `} />
      </div>
      {opened &&
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
          css={css`
            z-index: 2;
            width: ${width}px;
            height: ${height}px;
          `}
        >
          {cloneElement(DropdownContent, { openButtonRef: ref, closeDropdown })}
        </div>
      }
    </>
  )
}

export const TEXT_STYLES = {
  SUBTITLE_1: ['Sato', 'medium', 16, 24, .15],
  SUBTITLE_2: ['Sato', 'medium', 14, 24, .1],
  SUBTITLE_3: ['Sato', 'medium', 12, 18, .1],
  BODY_BOLD_SUBTITLE: ['Red Hat Display', 700, 18, 27, 0],
  BODY_SUBTITLE: ['Red Hat Display', 'regular', 18, 27, 0],
  BODY_1: ['Red Hat Display', 'regular', 16, 24, .5],
  BODY_1_BOLD: ['Red Hat Display', 700, 16, 24, .5],
  BODY_2: ['Red Hat Display', 'regular', 14, 20, .25],
  BUTTON: ['Sato', 'medium', 14, 16, 1.25],
  BUTTON_2: ['Sato', 'medium', 14, 16, .25],
  CAPTION: ['Red Hat Display', 'regular', 12, 16, .4],
  OVERLINE: ['Red Hat Display', 'medium', 10, 16, 1.5],
  MARKETING_H1: ['Sato', 'medium', 96, 112, -1.5],
  MARKETING_H2: ['Sato', 'medium', 60, 72, -.5],
  MARKETING_H3: ['Sato', 'medium', 48, 56, 0],
  MARKETING_H4: ['Sato', 'medium', 34, 36, 0],
  MARKETING_H5: ['Sato', 'medium', 24, 28, .18],
  MARKETING_H6: ['Sato', 'medium', 20, 24, .15],
  APP_H1: ['Sato', 'medium', 36, 38, 0],
  APP_H2: ['Sato', 'medium', 32, 34, 0],
  APP_H3: ['Sato', 'medium', 28, 32, 0],
  APP_H4: ['Sato', 'medium', 24, 28, 0],
  APP_H5: ['Sato', 'medium', 20, 24, 0],
  APP_H6: ['Sato', 'medium', 16, 24, .15],
}

export const Text: (props: { children?: any, customCss?: any, onClick?: Function, style?: any }) => ReactElement = ({ children, customCss, onClick, style = TEXT_STYLES.BODY_1 }) => {
  // @ts-ignore
  const [fontFamily, fontWeight, fontSize, lineHeight, letterSpacing] = style
  
  return (
    // @ts-ignore
    <div onClick={onClick} css={css`
      font-family: ${fontFamily};
      font-weight: ${fontWeight};
      font-size: ${fontSize}px;
      line-height: ${lineHeight}px;
      letter-spacing: ${letterSpacing}px;

      ${customCss}
    `}>
      {children}
    </div>
  )
}

export const Card = ({ customCss, children }) => {
  return (
    <div css={css`
      border-radius: 8px;
      padding: 32px;
      background-color: #F5F5F5;
      ${customCss}
    `}>
      {children}
    </div>
  )
}

export const CheckboxButton = ({ children, selected = false, setSelected, loading = false, disabled = false }) => {
  const interactionEnabled = !loading && !disabled

  const checkboxToggled = () => {
    if (interactionEnabled) {
      setSelected(!selected)
    }
  }

  const selectedAnimation = keyframes({
    'from': css`transform: scale(1.1);`,
    '50%': css`transform: scale(1.25);`,
    'to': css`transform: scale(1.1);`
  })

  const [hovered, setHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={checkboxToggled}
      css={css`display: flex; align-items: center; user-select: none; ${interactionEnabled && css`cursor: pointer;`}`}
    >
      <div css={css`
        width: 24px;
        height: 24px;
        border: 1px solid ${selected ? FC_DARK_BLUE : 'rgba(0, 0, 0, 0.38)'};
        border-radius: 4px;
        ${selected && css`background-color: ${FC_LIGHTER_BLUE};`}

        transition: transform 0.2s;
        ${hovered && interactionEnabled && css`
          border-color: ${FC_DARK_BLUE};
          transform: scale(1.1);
        `}

        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 12px;

        ${selected && css`animation: ${selectedAnimation} 0.3s;`}

        flex-shrink: 0;
      `}>
        {/* @ts-ignore */}
        {!loading ? <Icon type={TYPE_CHECKMARK} css={css`fill: ${FC_DARK_BLUE}; width: 16px; height: 12px; opacity: ${selected ? '1' : '0'}; ${selected && css`transition: opacity 0.3s;`}`} /> :
          <LoadingIndicator small />
        }
      </div>
      {children}
    </div>
  )
}

export const RadioButton = ({ children, selected = false, setSelected, loading = false, disabled = false }) => {
  const interactionEnabled = !loading && !disabled

  const checkboxToggled = () => {
    if (interactionEnabled) {
      setSelected(!selected)
    }
  }

  const selectedAnimation = keyframes({
    'from': css`transform: scale(1.1);`,
    '50%': css`transform: scale(1.25);`,
    'to': css`transform: scale(1.1);`
  })

  const [hovered, setHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={checkboxToggled}
      css={css`display: flex; align-items: center; user-select: none; ${interactionEnabled && css`cursor: pointer;`}`}
    >
      <div css={css`
        width: 24px;
        height: 24px;
        border: 1px solid ${selected ? FC_DARK_BLUE : 'rgba(0, 0, 0, 0.38)'};
        border-radius: 100%;
        ${selected && css`background-color: ${FC_LIGHTER_BLUE};`}

        transition: transform 0.2s;
        ${hovered && interactionEnabled && css`
          border-color: ${FC_DARK_BLUE};
          transform: scale(1.1);
        `}

        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 4px;

        ${selected && css`animation: ${selectedAnimation} 0.3s;`}

        flex-shrink: 0;
      `}>
        {/* @ts-ignore */}
        {!loading ? <div css={css`background-color: ${FC_DARK_BLUE}; width: 14px; height: 14px; border-radius: 100%; opacity: ${selected ? '1' : '0'}; ${selected && css`transition: opacity 0.3s;`}`} /> :
          <LoadingIndicator small />
        }
      </div>
      {children}
    </div>
  )
}

export const EmojiButton = ({ onEmojiSelect, selectedEmoji }) => {
  const selectedAnimation = keyframes({
    'from': css`transform: scale(1.1);`,
    '50%': css`transform: scale(1.25);`,
    'to': css`transform: scale(1.1);`
  })

  const [hovered, setHovered] = useState(false)

  const buttonWrapperStyles = css`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  `;
  const buttonStyles = css`
    width: 32px;
    height: 32px;
    font-size: 16px;
    border: 1px solid ${selectedEmoji ? FC_DARK_BLUE : 'rgba(0, 0, 0, 0.38)'};
    border-radius: 100%;
    background-color: ${FC_LIGHTER_BLUE};

    transition: transform 0.2s;
    ${hovered && css`
      border-color: ${FC_DARK_BLUE};
      transform: scale(1.1);
    `}

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 4px;

    ${selectedEmoji && css`animation: ${selectedAnimation} 0.3s;`}

    flex-shrink: 0;
  `;

  const popoverStyles = css`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  `;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiSelect = (emoji) => {
    const { native } = emoji;
    onEmojiSelect(native);
    setShowEmojiPicker(false); // Close the EmojiPicker popover after selecting an emoji
  };

  return (
    <div
      css={buttonWrapperStyles}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <button
        css={buttonStyles}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
      >
        {selectedEmoji}
      </button>
      {showEmojiPicker && (
        <div css={popoverStyles}>
          <EmojiPicker
            // Just putting activity and objects first because they're the most likely to be used
            categories={['frequent', 'objects', 'activity', 'people', 'nature', 'foods', 'places', 'symbols', 'flags']}
            perLine={7}
            data={data}
            onEmojiSelect={handleEmojiSelect}
          />
        </div>
      )}
    </div>
  );
};

export const ToggleSwitchButton = ({ selected = false, setSelected, loading = false, disabled = false }) => {
  const interactionEnabled = !loading && !disabled

  const checkboxToggled = () => {
    if (interactionEnabled) {
      setSelected(!selected)
    }
  }

  const [hovered, setHovered] = useState(false)

  const animationConfig = { tension: 250 }

  const knobStyle = useSpring({
    left: `${selected ? 18 : 2}px`,
    config: animationConfig
  })

  const { backgroundColor: backgroundColorSpring } = useSpring({
    from: { backgroundColor: 0 },
    backgroundColor: selected ? (hovered ? .7 : 1) : (hovered ? .3 : 0),
    config: animationConfig
  })

  const backgroundStyle = {
    backgroundColor: backgroundColorSpring.to({ range: [0, 1], output: [NEUTRAL_50, FC_BLUE] }),
    config: animationConfig
  }

  return (
    <animated.div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={checkboxToggled}
      style={backgroundStyle}
      css={css`
        position: relative;
        width: 34px;
        min-width: 34px;
        height: 18px;
        min-height: 18px;
        background-color: ${FC_BLUE};
        border-radius: 34px;
        ${interactionEnabled && css`cursor: pointer;`}
      `}
    >
      <animated.div style={knobStyle} css={css`
        background-color: white;
        border-radius: 100%;
        width: 14px;
        height: 14px;

        position: absolute;
        top: 2px;
        right: 2px;
      `} />
    </animated.div>
  )
}

export const Modal = ({ onClose, children, width = null, dismissable = true, dismissOnClickOutside = true }) => {
  const clickOutsideRef = useClickOutside({ onTriggered: () => { if (dismissOnClickOutside && dismissable) { onClose() } } })

  return (
    <div css={css`
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;

      padding: 16px;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    `}>
      {!dismissable && <div css={css`
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
      `} />}
      <div ref={clickOutsideRef} css={css`
        ${width !== null ? css`width: min(100%, ${width}px);` : css`max-width: 100%;`}
        max-height: 100%;
        border-radius: 8px;
        overflow: auto;

        background: #FFFFFF;
        box-shadow: 0px 32px 32px -16px rgba(0, 0, 0, 0.1);

        position: relative;
      `}>
        {dismissable &&
          <div onClick={onClose} css={css`
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
          `}>
            {/* @ts-ignore */}
            <Icon type={TYPE_CLOSE} />
          </div>
        }
        {children}
      </div>
    </div>
  )
}

export const LoadingIndicator = ({ small = false }) => <Spinner animation="grow" size={small ? "sm" : undefined} />

export const UnstyledLink = (props) => <Link css={css`color: unset; &:hover { text-decoration: unset; color: unset; }`} {...props} />

export const SegmentedButton = ({ options, onOptionToggled, customCss = null, customItemCss = null }) => {
  return (
    <div css={css`
      display: flex;
      user-select: none;

      ${customCss};
    `}>
      {options.map(({ value, name, active }, index) => {
        const interactable = !active
        return (
          <div tabIndex={0} onClick={() => { if (interactable) { onOptionToggled(value) } }} css={css`
            height: 36px;
            border: 1px solid ${NEUTRAL_50};
            ${index !== 0 && css`border-left-width: 0px;`};
            border-radius: ${index === 0 ? '100' : '0'}px ${index === options.length - 1 ? '100' : '0'}px ${index === options.length - 1 ? '100' : '0'}px ${index === 0 ? '100' : '0'}px; 

            background-color: ${NEUTRAL_20};
            ${interactable && css`
              &:hover, :active, :focus {
                background-color: ${NEUTRAL_40};
              }
            `}
            
            ${active && css`
              font-weight: bold;
              background-color: ${FC_LIGHTER_BLUE};

              ${interactable && css`
                &:hover, :active, :focus {
                  background-color: ${FC_LIGHT_BLUE};
                }
              `}
            `}
            padding: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            ${interactable && css`
              cursor: pointer;
            `}

            width: 180px;

            ${customItemCss};
          `}>
            <div css={css`display: flex; align-items: center; gap: 10px;`}>
              {/* @ts-ignore */}
              {active && <Icon type={TYPE_CHECKMARK} css={css`fill: ${FC_DARK_BLUE}; height: 10px;`} />}
              <Text customCss={css`color: ${FC_DARK_BLUE};`}>
                {name}
              </Text>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const TabbedInterface = ({ tabs, activeTabIndex, setActiveTabIndex, customTabCss = null, customContentCss = null, customHeaderCss = null, showDescriptions = false }) => {
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })

  const [mobileShowDescription, setMobileShowDescriptions] = useState(false)

  return (
    <div>
      <Container css={css`border: none;`}>
      <div css={css`
        display: flex;
        padding: ${mobileView ? 8 : 16}px;
        flex-direction: row;
        align-items: center;
        gap: ${mobileView ? 8 : 16}px;
        ${customHeaderCss}
      `}>
        {tabs.map(({ title }, index) => <TabTitle index={index} key={title} activeTabIndex={activeTabIndex} onClick={() => setActiveTabIndex(index)} customCss={customTabCss}>{title}</TabTitle>)}
        {mobileView && showDescriptions &&
          <div css={css`margin: 0px`}>
          <UnstyledLink onClick={() => setMobileShowDescriptions(show => !show)}>
            {/* @ts-ignore */}
            <Icon type={TYPE_HELP} css={css`width: 24px; height: 24px; margin: 0px;`} />
          </UnstyledLink>
          </div>
        }
      </div>
      {showDescriptions &&
        <>
        {mobileView ?
          <>
            {mobileShowDescription &&
              <div css={css`padding: 8px; text-align: left;`}>
                <Text style={TEXT_STYLES.CAPTION}>{tabs[activeTabIndex].description}</Text>
              </div>
            }
          </> :
          <div
            css={css`
              margin: ${mobileView ? 8 : 16}px;
              display: flex;
              flex-direction: row;
              gap: 8px;
            `}
          >
            <div>
            {/* @ts-ignore */}
            <Icon type={TYPE_HELP} css={css`width: 24px; height: 24px;`} />
            </div>
            <div css={css`text-align: left;`}>
            <Text>{tabs[activeTabIndex].description}</Text>
            </div>
          </div>
          }
        </>
      }
      </Container>
      <div css={css`padding-top: 24px; ${customContentCss}`}>
        <AnimatedSlidingWrapper currentItemIndex={activeTabIndex}>
          {tabs.map(tab => tab.Content)}
        </AnimatedSlidingWrapper>
      </div>
    </div>
  )
}

const TabTitle = ({ children: title, onClick, index, activeTabIndex, customCss }) => {
  const highlighted = index === activeTabIndex
  const mobileView = useMediaQuery({ query: '(max-width: 767px)' })

  const selectedTabStyle = css`
    border: 2px solid #091454;
    background: ${FC_LIGHTER_BLUE};
  `

  return (
    <div onClick={onClick} css={css`
      padding: ${mobileView ? 8: 16}px;
      display: flex;
      opacity: 0.8;
      align-items: center;
      cursor: pointer;
      border-radius: 16px;
      border: 1px solid ${NEUTRAL_50};

      position: relative;

      ${highlighted && selectedTabStyle}

      ${customCss}
    `}>
      <div css={css`
        position: absolute;
        left: 0px;
        top: 0px;
      `}/>
      <div css={css`
        font-weight: 500;
        font-size: ${mobileView ? 14 : 18}px;
        letter-spacing: 0.5px;
        color: ${FC_DARK_BLUE};
      `}>
        {title}
      </div>
    </div>
  )
}

export const CircledCaretButton = ({ enabled = true, onClick = () => { }, leftFacing = false }) => {
  return (
    <div onClick={onClick} css={css`
      width: 44px;
      height: 44px;
      border-radius: 100%;
      background-color: ${NEUTRAL_30};
      &:hover {
        ${enabled && css`
          background-color: ${FC_IRIS_BLUE};
          stroke: white;
        `}
      }
      stroke: ${!enabled ? NEUTRAL_60 : FC_DARK_BLUE};
      ${enabled && css`cursor: pointer;`}

      ${leftFacing && css`transform: rotate(180deg);`}

      display: flex;
      align-items: center;
      justify-content: center;
    `}>
      {/* @ts-ignore */}
      <Icon type={TYPE_RIGHT_CARET_UNSET_FILL} />
    </div>
  )
}

// Not actually used but keeping around in case it's handy in the future
// Based on the badge-selection pills from profile settings
export const SelectablePill = ({ selected, onClick, children }) => {
  const selectedStyle = css`
    background-color: ${FC_LIGHTER_BLUE};
    border-color: ${FC_BLUE};
    color: ${FC_BLUE};
    font-weight: bold;
  `

  return (
    <div onClick={onClick} css={css`
      border: 1px solid ${NEUTRAL_50};
      border-radius: 32px;
      width: fit-content;

      background-color: white;
      color: black;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s;

      cursor: pointer;
      &:hover {
        background-color: ${FC_LIGHTER_BLUE};
        transition: color 0.2s, border-color 0.2s;
      }

      padding: 8px 16px 8px 12px;
      display: flex;
      gap: 8px;

      ${selected && selectedStyle}
    `}>
      {children}
    </div>
  )
}