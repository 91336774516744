/** @jsxImportSource @emotion/react */
import DailyIframe from '@daily-co/daily-js';
import { useDaily, useParticipantIds } from '@daily-co/daily-react';
import { css, keyframes } from '@emotion/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LinkStyledText, LoadingIndicator, Text, TEXT_STYLES } from '../../components/flowComponents';
import { toastError } from '../../components/utils/toaster';
import Icon, { TYPE_MUSIC_SHARE_ON_UNSET_FILL, TYPE_SOUND_OFF, TYPE_SOUND_ON } from '../../daily/daily-components/Icon/Icon';
import { FC_DARK_BLUE, FC_IRIS_BLUE, FC_LIGHTER_BLUE, FC_LIGHT_BLUE, LINK_ACTIVE, LINK_MAIN, NEUTRAL_60, NEUTRAL_70 } from '../../emotionVariables';
import EventContext from '../../EventContext';
import { useToggleSessionHostedMusicPlayback, useUpdateMusic } from '../../fetch/endpoints';
import { UserContext } from '../../UserProvider';
import './MusicShare.scss'
import { FlowClubRadioPlayer } from './FlowClubRadioPlayer';
import { ScreensharedMusicLinkSharingForm } from './ScreensharedMusicLinkSharingForm';
import { MusicSharingModal } from './MusicSharingModal';
import { useModal } from '../../wrappers/MagnificentlyMunificentModalManager';
import { SegmentContext, useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { FetchOverrideContext } from '../../fetch/helpers';
import { TooltipWrapper } from '../../components/TooltipWrapper';
import * as Sentry from "@sentry/react";
import { useDebounced } from '../../utils';

const getPlaylistDisplayInfo = (event, musicIsBeingScreenshared, flowClubRadioActive, userIsHost) => {
  const { music: sharedMusicPlaylistName, musicLink: sharedMusicLink, hostedMusicPlaybackData } = event

  if (flowClubRadioActive) {
    return {
      text: (hostedMusicPlaybackData.playlist !== undefined && hostedMusicPlaybackData.playlist.name !== undefined) ?
        `Flow Club Radio: ${hostedMusicPlaybackData.playlist.name}` :
        "Flow Club Radio",
      link: null
    }
  }

  if (musicIsBeingScreenshared) {
    return {
      text: sharedMusicPlaylistName ?? (userIsHost ? '' : "Currently Playing"),
      link: sharedMusicLink ?? null
    }
  }

  return {
    text: userIsHost ? '' : 'The host is not currently sharing music.',
    textProps: { style: TEXT_STYLES.BODY_2, customCss: css`color: ${NEUTRAL_70};` },
    link: null
  }
}

export const MusicSharing = () => {
  const { performFetch: toggleHostedMusicPlayback } = useToggleSessionHostedMusicPlayback()
  const callObject = useDaily()
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()
  const eventContextValue = useContext(EventContext)
  const segmentContextValue = useContext(SegmentContext)
  const fetchOverrideContextValue = useContext(FetchOverrideContext)
  const { event, isSharingScreen, toggleMusic, isMusicMuted, refetchEventData } = eventContextValue
  const { music: sharedMusicPlaylistName, hostedMusicPlaybackData } = event
  const sendSegmentEvent = useSendSegmentEvent()

  const canShareMusic = DailyIframe.supportedBrowser().supportsScreenShare

  const flowClubRadioActive = hostedMusicPlaybackData !== null && hostedMusicPlaybackData.playbackStartedAt !== null
  const flowClubRadioActiveLocally = !isMusicMuted && flowClubRadioActive
  const musicIsBeingScreenshared = (useParticipantIds({filter: 'screen'})).length > 0
  const anyMusicActive = musicIsBeingScreenshared || flowClubRadioActive

  const { performFetch: updateMusicLink } = useUpdateMusic()
  const resetMusicLink = async () => {
    if (event) {
      const { success, error } = await updateMusicLink({ eventId: event.id, musicLink: null })
      if (success) {
        refetchEventData()
      } else {
        Sentry.captureException(error)
      }
    }
  }
  useEffect(() => {
    if (userIsHost && musicIsBeingScreenshared && sharedMusicPlaylistName !== null && sharedMusicPlaylistName !== undefined) {
      resetMusicLink()
    }
  }, [musicIsBeingScreenshared])

  const musicButtonClicked = () => {
    if (userIsHost) {
      musicButtonClickedAsHost()
    } else {
      sendSegmentEvent("Music Button Clicked (Attendee)", { sharedMusicLink: event.musicLink })
    }
  }

  const [turningOffFlowClubRadio, setTurningOffFlowClubRadio] = useState(false)
  const stopAllMusic = async () => {
    if (flowClubRadioActive) {
      setTurningOffFlowClubRadio(true)
    }
    callObject.stopScreenShare();
    const { success, error } = await toggleHostedMusicPlayback({ eventId: event.id, togglingTo: false })
    if (!success) { toastError({ message: error }) }
  }
  useEffect(() => {
    if (!flowClubRadioActive) {
      setTurningOffFlowClubRadio(false)
    }
  }, [flowClubRadioActive])
  
  const musicButtonClickedAsHost = async () => {
    sendSegmentEvent("Music Button Clicked (Host)", { canShareMusic, flowClubRadioActive, isSharingScreen })

    if (canShareMusic) {
      if (!anyMusicActive) {
        // remind me some day to make context providers work in a less dumb way with modals
        setActiveModal(({ onHide }) => (
          <SegmentContext.Provider value={segmentContextValue}>
            <EventContext.Provider value={eventContextValue}>
              <FetchOverrideContext.Provider value={fetchOverrideContextValue}>
                <MusicSharingModal onHide={onHide} />
              </FetchOverrideContext.Provider>
            </EventContext.Provider>
          </SegmentContext.Provider>
        ))
      }
      else {
        stopAllMusic()
        // Pretty sure this was unnecessary since stopAllMusic already calls this
        // console.log("toggling playback")
        // alert('toggle')
        // const { success, error } = await togglePlayback({ eventId: event.id, togglingTo: !flowClubRadioActive })
        // if (success) {
        //   refetchEventData()
        // } else {
        //   toastError({ message: error })
        // }
      }
    }
  }

  const userIsHost = event && user && event.hostId === user.uid;

  const muteMusicClicked = () => {
    toggleMusic()
    sendSegmentEvent("Mute Music Toggled", { togglingTo: !isMusicMuted })
  }

  const playlistDisplayInfo = getPlaylistDisplayInfo(event, musicIsBeingScreenshared, flowClubRadioActive, userIsHost)

  const [volume, setVolume] = useState(100)
  const [sendMusicVolumeChangedSegmentEvent] = useDebounced(volume => sendSegmentEvent("Music Volume Changed", { volume }), 1000)
  const volumeChanged = (event) => {
    setVolume(event.target.value)
    sendMusicVolumeChangedSegmentEvent(event.target.value)
  }

  return (
    <div css={css`padding: 0px 18px;`}>
      <div css={css`display: flex; justify-content: space-between; align-items: center;`}>
        <div css={css`display: flex; align-items: center; gap: 0px 10px;`}>
          <ShareMusicButton userIsHost={userIsHost} anyMusicActive={anyMusicActive} musicButtonClicked={musicButtonClicked} playlistLink={playlistDisplayInfo.link} turningOffFlowClubRadio={turningOffFlowClubRadio} />
          <div>
            {userIsHost && (
              !anyMusicActive ?
                <LinkStyledText>
                  <Text style={TEXT_STYLES.SUBTITLE_1} onClick={musicButtonClickedAsHost}>Share music</Text>
                </LinkStyledText> :
                <Text style={TEXT_STYLES.SUBTITLE_1} customCss={css`min-height: 25px;`}>{turningOffFlowClubRadio ? 'Stopping music...' : 'Currently sharing'}{isMusicMuted ? <span css={css`color: ${NEUTRAL_60}; font-size: 14px;`}> (muted for self)</span> : null}</Text>
            )}
            <SharedMusicPlaylistInfo playlistDisplayInfo={playlistDisplayInfo} />
          </div>
        </div>
        <div>
        {(flowClubRadioActive || !userIsHost) && <MuteMusicButton userIsHost={userIsHost} isMusicMuted={isMusicMuted} muteMusicClicked={muteMusicClicked} />}
        {flowClubRadioActive && <input value={volume} onChange={volumeChanged} css={css`width: 80px;`} type="range" min="1" max="100"/>}
        </div>
      </div>
      {canShareMusic && isSharingScreen && !sharedMusicPlaylistName && <ScreensharedMusicLinkSharingForm />}
      {flowClubRadioActiveLocally && <FlowClubRadioPlayer hostedMusicPlaybackData={hostedMusicPlaybackData} volume={volume} />}
    </div>
  )
}

export const SharedMusicPlaylistInfo = ({ playlistDisplayInfo }) => {
  const { text, link, textProps = {} } = playlistDisplayInfo

  if (link !== null) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <Text {...textProps}>{text}</Text>
      </a>
    )
  } else {
    return <Text {...textProps}>{text}</Text>
  }
}

// Name is a bit of a lie – for attendees, it's either an uninteractive icon or a link to a playlist
const ShareMusicButton = ({ userIsHost, anyMusicActive, musicButtonClicked, playlistLink, turningOffFlowClubRadio }) => {
  const isButton = userIsHost
  const isLink = (!isButton && playlistLink !== null)

  const Wrapper = useMemo(() => ({ children }) => userIsHost ?
    <TooltipWrapper TooltipContents={anyMusicActive ? 'Stop sharing music' : 'Share music'}>{children}</TooltipWrapper> :
    isLink ?
      <a href={playlistLink} target="_blank" rel="noreferrer">{children}</a> :
      children
  , [userIsHost, anyMusicActive, isLink, playlistLink])

  const buttonClicked = () => {
    if (!turningOffFlowClubRadio) {
      musicButtonClicked()
    }
  }

  return (
    <Wrapper>
      <div onClick={buttonClicked} css={css`
        width: 44px;
        height: 44px;

        ${isButton && css`
          background-color: ${FC_LIGHTER_BLUE};
          border-radius: 100%;
          ${!turningOffFlowClubRadio && css`
            cursor: pointer;
            &:hover, &:active { background-color: ${FC_LIGHT_BLUE}; }
          `}
        `}

        ${(anyMusicActive && !turningOffFlowClubRadio) &&
          css`animation: ${keyframes({
            'from': css`transform: rotate(0deg);`,
            'to': css`transform: rotate(360deg);`
          })} 5s linear infinite;
        `}
      `}>
        {turningOffFlowClubRadio ?
          <div css={css`width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;`}>
            <LoadingIndicator />
          </div> :
          <Icon type={TYPE_MUSIC_SHARE_ON_UNSET_FILL} css={css`
            fill: ${FC_DARK_BLUE};
            
            ${!isButton && css`
              ${!anyMusicActive && css`fill: ${NEUTRAL_70};`}
              ${playlistLink !== null && css`
                fill: ${LINK_MAIN};
                &:hover { fill: ${FC_IRIS_BLUE}; }
                &:active { fill: ${LINK_ACTIVE} }
              `};
            `}
          `} />
        }
      </div>
    </Wrapper>
  )
}


export const MuteMusicButton = ({ userIsHost, isMusicMuted, muteMusicClicked }) => {
  return (
    <TooltipWrapper retainMousedOverInsideTooltip={false} TooltipContents={`${isMusicMuted ? 'Unmute' : 'Mute'}${userIsHost ? ' for self' : ' music'}`}>
      <div onClick={muteMusicClicked} css={css`
        width: 36px;
        height: 36px;

        background-color: ${FC_LIGHTER_BLUE};
        border-radius: 100%;
        cursor: pointer;
        &:hover, &:active { background-color: ${FC_LIGHT_BLUE}; }
      `}>
        <Icon type={isMusicMuted ? TYPE_SOUND_OFF : TYPE_SOUND_ON} css={css`fill: ${LINK_MAIN};`} />
      </div>
    </TooltipWrapper>
  )
}