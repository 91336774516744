import {
  Badge,
} from 'react-bootstrap'
import { HostStatus } from "../../../functions/shared/constants"

import './host-badge.scss'
import { UnstyledLink } from '../flowComponents'

export const HostBadgePill = (props) => {
  return (
    <Badge className="host-badge-pill">
      <HostBadgeImage fill="#ffffff" {...props} /> Host
    </Badge>
  )
}

export const ClubHostBadgePill = ({ badgeImageProps, clubSlug }) => {
  return (
    <UnstyledLink to={`/guided-club/${clubSlug}`}>
      <Badge className="host-badge-pill">
        <HostBadgeImage fill="#ffffff" {...badgeImageProps } /> Club Host
      </Badge>
    </UnstyledLink>
  )
}

export const HostBadge = ({ user, ...props }) => {
  return (user && user.hostStatus && user.hostStatus === HostStatus.ACTIVE ?
    <HostBadgeImage {...props} /> : ""
  )
}

export const HostBadgeImage = ({ fill = "#5D5FEF", ...props }) => {
  return (
    <svg {...props} className="host-badge-image" width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4Z" fill={fill} />
      <path d="M11.89 6.11C11.5 5.72 10.83 5 9.53 5C9.32 5 8.11 5 6.99 5C4.24 4.99 2 2.75 2 0H0C0 3.16 2.11 5.84 5 6.71L6.5 10.59L5 20H7L8 14H9.53L9 20H11L11.89 10.59L11 8.05L14.95 12L16.36 10.59L11.89 6.11Z" fill={fill} />
    </svg>
  )
}