/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { FC_DARK_BLUE } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext, useEffect, useState } from 'react';
import { FlowButton, TextInput, BUTTON_STYLES, SIZE_PRESETS } from '../components/flowComponents';
import { useUpdateUser } from '../fetch/endpoints';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { UserContext } from '../UserProvider';
import { MAKING_TIME_FOR_GOALS, goalToMakeTimeFor } from '../../functions/shared/constants';
import { usePreSignupUserDataProperty } from '../UserProvider';

const placeholderGoals = {
  [MAKING_TIME_FOR_GOALS.WORK]: 'Email outreach, Project planning',
  [MAKING_TIME_FOR_GOALS.SCHOOL]: 'Final assignment, Study for exam',
  [MAKING_TIME_FOR_GOALS.MYSELF]: 'Declutter my room, Meditate',
  [MAKING_TIME_FOR_GOALS.RELATIONSHIPS]: 'Family budget, Respond to messages',
  [MAKING_TIME_FOR_GOALS.OTHER]: 'Volunteer training, Hobby project',
}

const BackButton = ({ onClick }) => {
return (
    <FlowButton
      buttonStyle={BUTTON_STYLES.OUTLINE_SECONDARY}
      customCss={css`
        width: 48px;
        height: 48px;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
      `}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
        <path d="M8.5 1L1.5 8L8.5 15" stroke="#737AC9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </FlowButton>
  );
};


export const SpecificGoalPane = () => {
  const { largeScreenView, goToNextPane, goToPrevPane } = useContext(InterstitialContext)
  const makingTimeFor = usePreSignupUserDataProperty('makingTimeFor') || 'work'
  const savedSpecificGoal = usePreSignupUserDataProperty('specificGoal')
  const makingTimeForText = goalToMakeTimeFor[makingTimeFor]
  const sendSegmentEvent = useSendSegmentEvent()

  const [userEnteredGoal, setUserEnteredGoal] = useState(savedSpecificGoal || '')

  const { performFetch: updateUser } = useUpdateUser()
  const { user, setPreSignupUserData } = useContext(UserContext)
  const saveGoal = async (goal) => {
    if (user !== null) {
      await updateUser({ updateObject: { specificGoal: goal } })
    } else {
      setPreSignupUserData({ specificGoal: goal })
    }
  }

  const submitUserEnteredGoal = async (event) => {
    event.preventDefault()
    sendSegmentEvent("[Goal] Specific goal submitted", { specificGoal: userEnteredGoal })
    await saveGoal(userEnteredGoal)
    goToNextPane()
  }

  return (
    <>
      <h2
        css={css`
          color: ${FC_DARK_BLUE};
          margin-bottom: 16px;
          text-align: center;
        `}
      >
        Let's make it more specific
      </h2>
      <div
        css={css`
          letter-spacing: 0.15px;
          text-align: center;
          color: ${FC_DARK_BLUE};
          margin-bottom: ${largeScreenView ? 32 : 24}px;
        `}
      >
        Making more time for {makingTimeForText} is an ambitious goal. Having a specific task in mind increases clarity and ease of compliance.
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
        `}
      >
        <form onSubmit={submitUserEnteredGoal}>
          <TextInput
            wrapperCustomCss={css`
              width: 100%;
              margin: 24px 0px;
            `}
            customCss={css`
              ::placeholder {
                color: rgba(0, 0, 0, 0.38);
              }
            `}
            value={userEnteredGoal}
            onChange={(event) => setUserEnteredGoal(event.target.value)}
            placeholder={placeholderGoals[makingTimeFor]}
          />
          <div css={css`
            display: flex;
            flex-direction: row;
            gap: 8px;
          `}>
            <BackButton onClick={() => goToPrevPane()} />
            <FlowButton
              sizePreset={SIZE_PRESETS.CHUNKY}
              type={'submit'}
              disabled={!userEnteredGoal}
              fillAvailableWidth
            >
              Continue
            </FlowButton>
          </div>
        </form>
        <Button
          onClick={() => goToNextPane()}
          disabled={userEnteredGoal !== ''}
          variant="link"
        >
          Do this later
        </Button>
      </div>
    </>
  );
}