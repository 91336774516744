/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const LoungeFullscreenModal = ({ Contents, onConfirm, onHide, overlayColor = 'rgba(0, 0, 0, 0.85)', contentProps = {} }) => {
  return (
    <div css={css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: ${overlayColor};
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
    `}>
      <Contents onHide={onHide} onConfirm={onConfirm} {...contentProps} />
    </div>
  )
}