/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FC_BACKGROUND_GRAY } from '../emotionVariables';
import { useGetNextJoinableSessionForNewUser } from '../fetch/endpoints';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { ConnectCalendarPane } from '../Onboarding/ConnectCalendarPane';
import { GoalPane } from '../Onboarding/GoalPane';
import { SelectCalendarSpanPane } from '../Onboarding/SelectCalendarSpanPane';
import { SessionPane } from '../Onboarding/SessionPane';
import { SignupPane } from '../Onboarding/SignupPane';
import { WelcomeInterstitial } from '../Onboarding/WelcomeInterstitial';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { HomeHome } from './HomeHome';
import { UpdateProfilePane } from '../Onboarding/UpdateProfilePane';
import { useMediaQuery } from 'react-responsive';

const HomeOnboardingWrapper = () =>
  <SegmentProvider eventLabel={'Home Onboarding'}>
    <HomeOnboardingContent />
  </SegmentProvider>

const HomeOnboardingContent = () => {
  const [activeTaskIndex, setActiveTaskIndex] = useState(null)

  const { result: nextJoinableSessionResult, fetching: nextJoinableSessionLoading, error: nextJoinableSessionError } = useAutomaticallyFetch(useGetNextJoinableSessionForNewUser, {})
  const { nextSession: nextJoinableSession, isNow } = nextJoinableSessionResult || {}
  const nextJoinableSessionLoaded = useFetchSucceeded(nextJoinableSessionLoading, nextJoinableSessionError)

  // Note that we rely on the ordering here in HomeHome.js's checklistTasks
  // Previously the indexes matched up, but now they're hardcoded in
  const paneSets = [
    {
      name: 'homeOnboarding-goal',
      panes: [
        { name: "GoalPane", MainContent: <GoalPane key='goal' />, navType: 'none' },
      ]
    },
    {
      name: 'homeOnboarding-connectCalendar',
      panes: [
        { name: "SignupPane", MainContent: <SignupPane key='signup' />, navType: 'none' },
        { name: "ConnectCalendarPane", MainContent: <ConnectCalendarPane key='connect-calendar' />, navType: 'skip' },
        { name: "SelectCalendarSpanPane", MainContent: <SelectCalendarSpanPane key='select-calendar-span' />, navType: 'none' }
      ]
    },
    {
      name: 'homeOnboarding-suggestSession',
      panes: [
        { name: "SessionPane", MainContent: <SessionPane defaultSession={nextJoinableSession}
        isNow={isNow}
        recommendedSessionLoading={!nextJoinableSessionLoaded} key='session' />, navType: 'none' },
      ]
    },
    {
      name: 'homeOnboarding-updateProfile',
      panes: [
        { name: "SignupPane", MainContent: <SignupPane key='signup' />, navType: 'none' },
        { name: "UpdateProfilePane", MainContent: <UpdateProfilePane key='profile' />, navType: 'none' },
      ]
    },
  ]

  const mobileView = useMediaQuery({query: '(max-width: 575px)'})

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [activeTaskIndex]);

  return (
    <div css={css`
      background-color: ${FC_BACKGROUND_GRAY};
      ${(!mobileView || activeTaskIndex === null) && css`padding: 32px 0px;`}
      flex-grow: 1;
    `}>
      <Container>
        {activeTaskIndex === null ?
          <HomeHome setActiveTaskIndex={setActiveTaskIndex}/> :
          <WelcomeInterstitial paneSetOverride={paneSets[activeTaskIndex]} onExit={() => setActiveTaskIndex(null)}/>}
      </Container>
    </div>
  )
}

export const HomeOnboarding = HomeOnboardingWrapper