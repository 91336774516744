/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserProvider';
import { Link } from "react-router-dom";
import { NEUTRAL_70 } from '../../emotionVariables';
import { useGetBadgesForUsers, useSetJoinedBadges, useGetFavoriteHosts, useUnfollowHost, useToggleHostFollowCalendarInvites, useSetUserPreferences } from "../../fetch/endpoints";
import { useAutomaticallyFetch } from '../../fetch/helpers';
import Icon, { TYPE_CLOSE } from '../../daily/daily-components/Icon/Icon';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { FlowButton, LoadingIndicator, Text, TEXT_STYLES, ToggleSwitchButton, BUTTON_STYLES } from '../flowComponents';
import { BadgesSelector } from '../BadgesSelector';

import { toastError, toastSuccess } from '../utils/toaster';


export const ClubsAndHostsSettings = ({ }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const [selectedBadgeIds, setSelectedBadgeIds] = useState(new Set())
  const [optIntoHostFavoriteEmails, setOptIntoHostFavoriteEmails] = useState(user.preferences?.optIntoHostFavoriteEmails ?? false)

  const { performFetch: setJoinedBadges, fetching: settingJoinedBadges } = useSetJoinedBadges()
  const { result: favoriteHosts, fetching: fetchingFavoriteHosts, performFetch: fetchFavoriteHosts } = useAutomaticallyFetch(useGetFavoriteHosts)
  const { performFetch: unfollowHost } = useUnfollowHost()
  const { performFetch: toggleHostFollowCalendarInvites } = useToggleHostFollowCalendarInvites()
  const { performFetch: setUserPreferences } = useSetUserPreferences()
  const updateOptInPreference = async (newValue) => {
    const { success, error } = await setUserPreferences({ newPreferenceData: { optIntoHostFavoriteEmails: newValue } })
    if (success) {
      sendSegmentEvent("Changed Host Favorite Invite Preference", { newValue })
      setOptIntoHostFavoriteEmails(newValue)
      toastSuccess({ message: 'Successfully updated your favorite hosts invite preferences' })
    } else {
      toastError({ message: error })
    }
  }

  const unfavoriteHost = async (hostId) => {
    sendSegmentEvent("Unfollow Button Clicked", { hostId })
    const { success, error } = await unfollowHost({ hostId })
    if (success) {
      toastSuccess({ message: 'Successfully removed host from your favorites' })
      fetchFavoriteHosts()
    } else {
      toastError({ message: error })
    }
  }

  const toggleCalendarInvites = async (hostId, togglingTo) => {
    sendSegmentEvent("Calendar Invites Toggle Clicked", { hostId, togglingTo })
    const { success, error } = await toggleHostFollowCalendarInvites({ hostId, receiveCalendarInvites: togglingTo })
    if (success) {
      toastSuccess({ message: togglingTo ? 'Successfully enabled calendar invites' : 'Successfully disabled calendar invites' })
      fetchFavoriteHosts()
    } else {
      toastError({ message: error })
    }
  }

  const { result: badgeData } = useAutomaticallyFetch(useGetBadgesForUsers, { userIds: [] }, {})
  useEffect(() => {
    if (badgeData !== null && badgeData.badgeMembershipsByUser[user.uid] !== undefined) {
      setSelectedBadgeIds(new Set(badgeData.badgeMembershipsByUser[user.uid].map(membership => membership.badgeId)))
    }
  }, [badgeData])

  const updateBadges = async () => {
    sendSegmentEvent("Badges and Hosts Settings Updated", { numClubs: selectedBadgeIds.size })
    const { success } = await setJoinedBadges({ badgeIds: Array.from(selectedBadgeIds) })
    if (success) {
      toastSuccess({ message: 'Successfully saved your changes!' })
    } else {
      toastError({ message: "There was an error saving your settings. Please try again" })
    }
  }

  return (
    <div>
      <Text
        style={TEXT_STYLES.APP_H1}
        customCss={css`
          margin-bottom: 16px;
        `}
      >
        Clubs and Hosts
      </Text>
      <Text
        style={TEXT_STYLES.BODY_1}
        customCss={css`
          margin-bottom: 48px;
        `}
      >
        Select your preferred hosts and clubs to receive notifications of upcoming sessions.
      </Text>
      <Text
        customCss={css`
          margin-bottom: 12px;
        `}
        style={TEXT_STYLES.APP_H4}
      >
        Favorite Hosts
      </Text>
      <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
          <Text>Calendar invites for favorite host sessions</Text>
          <ToggleSwitchButton selected={optIntoHostFavoriteEmails} setSelected={updateOptInPreference} />
      </div>
      {optIntoHostFavoriteEmails ?
      <Text customCss={css`color: ${NEUTRAL_70};`}>We will send you calendar invites whenever one of your favorite hosts creates a session. You can toggle these on/off for each host. <strong>You'll still need to book the session.</strong></Text> :
      <Text customCss={css`color: ${NEUTRAL_70};`}>We will <strong>NOT</strong> send you calendar invites when your favorite hosts create sessions. You can still filter for these hosts on the schedule to easily find their sessions.</Text>
      }
      <div css={css`margin-top: 12px;`}>
      {fetchingFavoriteHosts ? (
        <LoadingIndicator />
      ) : favoriteHosts !== null ? (
        favoriteHosts.map((host) => (
          <div
            key={host.slug}
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              margin-bottom: 16px;
            `}
          >
            <img
              src={host.image64}
              css={css`
                width: 40px;
                height: 40px;
                border-radius: 50%;
              `}
            />
            <Link
              to={`/host/${host.slug}`}
              css={css`
                flex: 3;
                margin-right: 16px;
              `}
            >
              <Text style={TEXT_STYLES.BODY_1}>{host.displayName}</Text>
            </Link>
            {optIntoHostFavoriteEmails &&
            <div css={css`
              display: flex;
              gap: 8px;
              align-items: center;
            `}>
            <ToggleSwitchButton
              selected={host.receiveCalendarInvites}
              setSelected={(togglingTo) =>
                toggleCalendarInvites(host.id, togglingTo)
              }
              disabled={!optIntoHostFavoriteEmails}
            />
            <Text
              css={css`
                flex: 2;
              `}
            >
              Calendar invites
            </Text>
            </div>}
            <FlowButton
              buttonStyle={BUTTON_STYLES.OUTLINE_DARK}
              onClick={() => unfavoriteHost(host.id)}
              customCss={css`
                margin-left: auto;
                flex: 1;
              `}
            >
              <Icon type={TYPE_CLOSE} />
            </FlowButton>
          </div>
        ))
      ) : (
        <Text
          style={TEXT_STYLES.BODY_1}
          customCss={css`
            color: ${NEUTRAL_70};
            margin-bottom: 48px;
          `}
        >
          You don't have any favorite hosts yet.
        </Text>
      )}
      </div>
      <hr />
      {badgeData !== null ? (
        <BadgesSelector
          badges={badgeData.badges}
          selectedBadgeIds={selectedBadgeIds}
          setSelectedBadgeIds={setSelectedBadgeIds}
        />
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <LoadingIndicator />
        </div>
      )}
      <FlowButton
        onClick={updateBadges}
        loading={settingJoinedBadges}
        fillAvailableWidth
      >
        Save Changes
      </FlowButton>
    </div>
  );

}