
import '../settings.scss';
import {
    useContext,
    useEffect,
    useState,
} from 'react'
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap'
import MediaQuery from 'react-responsive'
import { InviteFriendIcon } from "../../../shared/components/icons"
import { UserContext } from '../../../UserProvider'
import { db } from '../../../firebase';
import { InviteModal } from '../../../InviteUtils'
import { useModal } from '../../../wrappers/MagnificentlyMunificentModalManager';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';

async function getInvitedCodeUserIds(userId) {
    // Gets all user ids from inviteCodes where user == userId.  This
    // assumes that EVERY successfully invited user is associated with an
    // inviteCode, and handles the edge case where one user has multiple inviteCodes
    const userRef = db.collection('users').doc(userId);
    const userInvites = await db.collection('invites').where('user', '==', userRef).get();
    const inviteCodeUsers = userInvites.docs.map(doc => {
        return doc.data().users
    });
    return inviteCodeUsers.flat()
}

function formatSubscriptionStatus(status, invited, trialEnd) {
    if (status.includes('active')) return 'Subscribed';
    if (status.includes('pending')) {
        return `Invited on ${invited.toDate().toLocaleDateString()}`;
    }
    if (status.includes('trial')) {
        if (typeof trialEnd !== "undefined" && new Date() > trialEnd.toDate()) {
            return `Trial Expired on ${trialEnd.toDate().toLocaleDateString()}`;
        } else {
            return `Trialing until ${trialEnd.toDate().toLocaleDateString()}`;
        }
    }
    return '';
}

function extractInviteDataFromUser(user) {
    try {
        const res = (({ firstName, lastName, email, trialEnd, created, invited,
                        subscriptionStatus='pending' }) => (
            {
                email,
                created,
                status: formatSubscriptionStatus(subscriptionStatus, invited, trialEnd),
                name: `${firstName || ''} ${lastName || ''}`,
                trialEnd,
                invited
            }))(user);
        return res;
    } catch (e) {
        return {}
    }
}

async function getPendingInvitedUsers(userId) {
    // Assumes all pending invited users exist under the invitedUsers collection
    // and have redeemed == false
    const userRef = db.collection('users').doc(userId);
    const userInvitedUsersSnapshot = await db.collection('invitedUsers')
        .where('invitedByUser', '==', userRef)
        .where('redeemed', '==', false).get();
    const pendingInvitedUsers = userInvitedUsersSnapshot.docs.map(doc => {
        return extractInviteDataFromUser(doc.data());
    }).filter(doc => Object.keys(doc).length > 0);
    return sortByInvitedTime(pendingInvitedUsers)
}

async function getInvitedUserData(invitedUsers) {
    return await Promise.all(invitedUsers.map(async (userRef) => 
        { return (await userRef.get()).data() }));
}

function sortByCreatedTime(userData) {
    return userData.sort((a, b) => (a.created > b.created))
}

function sortByInvitedTime(userData) {
    return userData.sort((a, b) => (a.invited > b.invited))
}

function filterUserData(userData, statusString) {
    return userData.filter(user => user.status.includes(statusString))
}

function organizeInvitedUserData(invitedUserData) {
    const subscribedUsers = sortByCreatedTime(filterUserData(invitedUserData, "Subscribed"));
    const trialingUsers = sortByCreatedTime(filterUserData(invitedUserData, "In Trial"));
    const expiredUsers = sortByCreatedTime(filterUserData(invitedUserData, "Trial Expired"));
    return { subscribedUsers, trialingUsers, expiredUsers }
}

async function getInvitedUsers(user) {
    const userId = user.uid;
    const invitedPendingUsers = await getPendingInvitedUsers(userId);
    const invitedCodeUserIds = await getInvitedCodeUserIds(userId);
    const invitedCodeUserRefs = invitedCodeUserIds.map(id => {
        return db.collection('users').doc(id);
    });
    const invitedUsers = await getInvitedUserData(invitedCodeUserRefs)
    const invitedUsersData = invitedUsers.map(extractInviteDataFromUser).filter(doc => Object.keys(doc).length > 0)
    let { subscribedUsers, trialingUsers, expiredUsers } = organizeInvitedUserData(invitedUsersData);
    return { invitedPendingUsers, subscribedUsers, trialingUsers, expiredUsers }
}

type InvitedUser = {
    name: string,
    email: string,
    status: string,
}

const InviteFriend = () => {
    const { user } = useContext(UserContext)
    const sendSegmentEvent = useSendSegmentEvent()
    //@ts-ignore
    const hasUnlockedInvites = user.inviteCode !== undefined
    //@ts-ignore
    const [ pendingInvites, setPendingInvites ] = useState<InvitedUser[]>([])
    const [ usersInvited, setUsersInvited ] = useState<InvitedUser[]>([])
    const [ loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchUserInvites = async () => {
            setLoading(true)
            let { invitedPendingUsers, subscribedUsers, trialingUsers, expiredUsers } = await getInvitedUsers(user);
            const invitedUsersData = invitedPendingUsers.concat(trialingUsers, expiredUsers)
            // Will display pending users first
            //@ts-ignore
            setPendingInvites(invitedPendingUsers);
            setUsersInvited(subscribedUsers);
            setLoading(false)
            return invitedUsersData
        }
        if (user) {
            fetchUserInvites()
        }
    }, [user])


    const { setActiveModal } = useModal()
    const openInviteModal = () => setActiveModal(InviteModal)

    return (
        <Container>
            <h2 className="header-settings">Invite Friends</h2>
            <Row className="invite-a-friend border py-5">
                <Col xs={12} md={3} className="flex justify-center">
                    <InviteFriendIcon />
                </Col>
                {hasUnlockedInvites ?
                <Col xs={12} md={8} className="mt-3"> 
                    <h5>
                        Get a free month of Flow Club<br/>
                        for each friend who subscribes to Flow Club membership (after they pay for their first month)
                    </h5>
                    <Button className="mt-3" variant="primary" onClick={() => {
                        openInviteModal()
                        sendSegmentEvent('Opened Invite Modal', {
                            location: "Settings - Invite Friends",
                        })
                    }} >
                        Invite a friend
                    </Button>
                </Col>
                :
                <Col xs={12} md={8} className="mt-3">
                    <h5>Attend your first session to unlock your invite code!</h5>
                </Col>
                }
            </Row>
            <Row className="mt-32 mb-24">
                <Col xs={12}>
                    <h5>Pending Invites</h5>
                    {loading ?
                    <Spinner animation="grow" variant="primary" />
                    :
                    pendingInvites.length > 0 ?
                    <span className="settings-success">
                        You've invited {pendingInvites.length} friend{pendingInvites.length > 1? "s" : ""}
                    </span>
                    : <span className="settings-default">
                        No pending invites
                    </span>}

                </Col>
            </Row>
            {pendingInvites.length > 0 ?
            <>
            <MediaQuery minWidth={768}>
                <Table className="settings-invite-table">
                    <thead>
                        <tr>
                            <th>Guests Invited</th>
                            <th>Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingInvites.map((userInvited, index) => (
                            <tr key={index}>
                                <td>{userInvited.name}</td>
                                <td>{userInvited.email}</td>
                                <td>{userInvited.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </MediaQuery>

            <MediaQuery maxWidth={768}>
            {pendingInvites.map((userInvited, index) => (
                <Card key={index} className="my-3 p-4">
                    <b>{userInvited.name}</b>
                    {userInvited.email}
                    <br />
                    { userInvited.status}
                </Card>
            ))}
            </MediaQuery>
            </>
            : ""}
            <Row className="mt-32 mb-24">
                <Col xs={12}>
                    <h5>Successful Invites</h5>
                    {loading ?
                    <Spinner animation="grow" variant="primary" />
                    :
                    usersInvited.length > 0 ?
                    <span className="settings-success">
                        {usersInvited.length} of your friends have joined Flow Club!
                    </span>
                    : <span className="settings-default">
                        None of your friends have joined Flow Club yet
                    </span>}

                </Col>
            </Row>
            {usersInvited.length > 0 ?
            <>
            <MediaQuery minWidth={768}>
                <Table className="settings-invite-table">
                    <thead>
                        <tr>
                            <th>Guests Invited</th>
                            <th>Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersInvited.map((userInvited, index) => (
                            <tr key={index}>
                                <td>{userInvited.name}</td>
                                <td>{userInvited.email}</td>
                                <td>{userInvited.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </MediaQuery>

            <MediaQuery maxWidth={768}>
            {usersInvited.map((userInvited, index) => (
                <Card key={index} className="my-3 p-4">
                    <b>{userInvited.name}</b>
                    {userInvited.email}
                    <br />
                    { userInvited.status}
                </Card>
            ))}
            </MediaQuery>
            </>
            : ""}
        </Container>
        
    )
}


export default InviteFriend