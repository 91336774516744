/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TooltipWrapper, Tooltip } from '../components/TooltipWrapper'

export const FlowDefinitionTooltipWrapper = ({ children, mobileView, desktopWidth }) => {
  const FlowTooltip = () => (
    <Tooltip customCss={css`width: ${mobileView ? '100%' : `${desktopWidth}px`}; padding: 24px;`}>
      <div css={css` text-align: left;`}>
        Being in flow feels like <i>effortless effort</i>.
        <br/>
        It tends to correlate with:
        <ul>
          <li><b>Challenge-Skill Balance:</b> My skills allowed me to meet the challenge</li>
          <li><b>Clear Goals:</b> I knew what I wanted to do</li>
          <li><b>Unambiguous Feedback:</b> I knew how well I was doing</li>
          <li><b>Autotelic Experience:</b> I loved the feeling of what I was doing, and want to feel it again</li>
          <li><b>Time Transformation:</b> Time appeared to speed up or slow down</li>
          <li><b>Total Concentration:</b> I had no difficulty focusing</li>
          <li><b>Sense of Control:</b> I felt in control</li>
          <li><b>Loss of Self-Consciousness:</b> I was not worried about others or critical of myself</li>
          <li><b>Action-awareness merging:</b> I moved seamlessly from thinking to acting</li>
        </ul>
      </div>
    </Tooltip>
  )
  return (
    <TooltipWrapper retainMousedOverInsideTooltip={true} useFloatingArgs={{ placement: 'bottom' }} TooltipComponent={FlowTooltip}>
      {children}
    </TooltipWrapper>
  )
}

export const ExperienceFlowTooltipWrapper = ({ children, mobileView, desktopWidth }) => {
  const FlowTooltip = () => (
    <Tooltip customCss={css`width: ${mobileView ? '100%' : `${desktopWidth}px`}; padding: 24px;`}>
      <div css={css` text-align: left;`}>
      <ul>
        <li>Manage distractions before you begin</li>
        <li>When difficult, break it down</li>
        <li>When vague, make specific</li>
        <li>When all else fails: Use time-based goals (eg. clean for 25 minutes)</li>
        <li>Stack your flow triggers (eg. coffee, music, Flow Club)</li>
        <li>Bring intention: Use the sharing time to raise your intention</li>
        <li>Plan according to your energy level</li>
        <li>Identify your peak performance windows</li>
        <li>Pause, reflect, and be curious</li>
        <li>Practice getting into flow regularly</li>
      </ul>
      </div>
    </Tooltip>
  )
  return (
    <TooltipWrapper retainMousedOverInsideTooltip={true} useFloatingArgs={{ placement: 'bottom' }} TooltipComponent={FlowTooltip}>
      {children}
    </TooltipWrapper>
  )
}