import { useCallback, useContext, useState } from 'react';
import { baseURL } from '../firebase'
import { USER_SESSION_STATUS, ONE_HOUR, NO_SHOWED_HOST_CLAIMING_GRACE_PERIOD } from '../../functions/shared/constants'
import dayjs from 'dayjs';
import { arrObjectsToObject, getEmptyHostId, getUserIsHost } from '../../functions/shared/helpers';
import { eventWithParsedDates, useEventsWithParticipantData } from '../event-utils';
import { useCompoundFirestoreSubscribe, useFirestoreSubscribe } from '../firebase/helpers';
import { UserContext } from '../UserProvider';

export function getSessionLink(eventId) {
  return `${baseURL}/s/${eventId}`
}

export function getSessionShareLink(eventId, inviteCode) {
  // t for Twitter? Just needed another letter since s, l, h, are reserved
  return `${baseURL}/t/${eventId}${inviteCode !== undefined ? `?inviteCode=${inviteCode}` : ""}`
}

// Tracks which of a set of page elements (days of sessions on the schedule) are onscreen
// Not 100% sure how necessary the level of complexity in this is, but since it
// causes (potentially cascading) re-renders on scroll probably good to be somewhat careful
export const useSessionDayVisibilities = () => {
  const [sessionDayVisibilities, setSessionDayVisibilities] = useState({})
  const setDayVisibility = useCallback((index, visible) => {
    setSessionDayVisibilities(sessionDayVisibilities => {
      if (sessionDayVisibilities[index] !== visible) {
        return { ...sessionDayVisibilities, [index]: visible }
      } else {
        return sessionDayVisibilities
      }
    })
  }, [])

  return [sessionDayVisibilities, setDayVisibility]
}

const userHasNewUserPrivileges = (user) => user === null || user.sessions < 5

const reservedSlotConditions = [
  // reserved 10th slot, for all sessions but only for first timers
  (_, user) => {
    const userIsFirstTimer = userHasNewUserPrivileges(user)
    return userIsFirstTimer
  }
]
export const getEffectiveMaxAttendees = (event, user) => {
  if (!event.maxAttendees) { return Infinity }

  return reservedSlotConditions.reduce(
    (maxAttendees, slotIsAvailable) => slotIsAvailable(event, user) ? maxAttendees : maxAttendees - 1,
    event.maxAttendees + 1
  )
}

export const getSessionIsInProgress = (event) => {
  // EW
  // trying to handle both older (JS date object) and newer (unix timestamp) event.start and .end values
  // but also EW
  const startTime = event.start.getTime !== undefined ? event.start.getTime() : event.start
  const endTime = event.end.getTime !== undefined ? event.end.getTime() : event.end
  const now = Date.now()
  return startTime < now && endTime > now
}

const timeIsOnHour = time => time.get('minutes') === 0 && time.get('seconds') === 0 && time.get('milliseconds') === 0
export const getSessionDisplayTimeAndDate = (session, user = null) => {
  const userTimezone = user !== null && user.timezone !== null ? user.timezone : dayjs.tz.guess()
  const [tzAdjustedStartTime, tzAdjustedEndTime] = [session.start, session.end].map(time => dayjs(time).tz(userTimezone))
  const endTimeCrossesAmPmBoundary = tzAdjustedStartTime.format('A') !== tzAdjustedEndTime.format('A')
  const timeToDisplay = `${tzAdjustedStartTime.format(`h${timeIsOnHour(tzAdjustedStartTime) ? '' : ':mm'}${endTimeCrossesAmPmBoundary ? ' A' : ''}`)}-${tzAdjustedEndTime.format(`h${timeIsOnHour(tzAdjustedEndTime) ? '' : ':mm'} A`)}`
  const dateToDisplay = tzAdjustedStartTime.startOf('day').isAfter(dayjs().tz(userTimezone).startOf('day')) || tzAdjustedStartTime.startOf('day').isBefore(dayjs().tz(userTimezone).startOf('day')) ? `${tzAdjustedStartTime.format('ddd, MMM DD')}` : 'Today'

  return { timeToDisplay, dateToDisplay }
}

// may or may not be correct logic
export const sessionIsInteractable = (userSessionStatus) => [
  USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE,

  USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION,
  USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUEST,
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW,
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_NON_USER,
  USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_LOADING,
  USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUIRES_CONFIRMATION,

  USER_SESSION_STATUS.HOST_FUTURE_SESSION,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE,
].includes(userSessionStatus)

export const sessionAlreadyBooked = (userSessionStatus) => [
  USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW,
  USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.PARTICIPANT_PAST_SESSION,

  USER_SESSION_STATUS.HOST_FUTURE_SESSION,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW,
  USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE,
  USER_SESSION_STATUS.HOST_PAST_SESSION
].includes(userSessionStatus)

export const EMPTY_HOST_ID = getEmptyHostId(process.env.REACT_APP_ENVIRONMENT === "production" ? "production" : "dev")

export const getHostClaimability = ({ event, user, claimingFromNoShowingHost = false }) => {
  const { userSessionStatus } = event

  const isEmptyHost = event.hostId === EMPTY_HOST_ID
  const userIsHost = getUserIsHost(user)
  const timeToSessionStart = event.start.getTime() - Date.now()
  const noShowedHostClaimingPeriodOpen = timeToSessionStart <= NO_SHOWED_HOST_CLAIMING_GRACE_PERIOD * -1
  const userCanClaimHosting = userIsHost && sessionIsInteractable(userSessionStatus) && (isEmptyHost || claimingFromNoShowingHost && noShowedHostClaimingPeriodOpen)

  return { isEmptyHost, noShowedHostClaimingPeriodOpen, userCanClaimHosting, alreadyBooked: sessionAlreadyBooked(userSessionStatus) }
}

export const useUpcomingEvents = (userId, count = 20) => {
  // TODO 2023-12-20: Can't return early conditionally here because of hooks
  // if (userId === null) {
  //   return [[], false]
  // }
  const startingCutoff = dayjs(new Date(Date.now() - ONE_HOUR * 2)).startOf("minute").toDate();
  let [events, eventsLoading] = useEventsWithParticipantData({
    functionalQuery: db =>
      db.collection('participants')
        .where('userId', '==', userId)
        .where('status', '==', 'upcoming')
        .where("start", ">=", startingCutoff)
        .orderBy("start", "asc")
        .limit(count),
    fields: ["eventId"]
  },
    ["hostId", "start", "end", "id", "host", "title", "guests"]
  )

  const hostUserIds = events.map(event => event.hostId)
  const [hostUsers, hostUsersLoading] = useCompoundFirestoreSubscribe('users', hostUserIds, ["id", "displayName", "bio", "image64", "image100", "webflowPage", "musicTitles", "musicImages"])
  const hostUsersById = arrObjectsToObject(hostUsers)

  if (events !== null) {
    events = events
      .map(eventWithParsedDates)
      .map(event => ({ ...event, hostUser: hostUsersById[event.hostId] }))
      .filter(event => event.hostUser !== undefined)
  }
  return [events, eventsLoading || hostUsersLoading]
}

export const useNumUpcomingEvents = () => {
  const { user } = useContext(UserContext)
  const startingCutoffBooked = dayjs(new Date(new Date().getTime() - 1000 * 60 * 5)).startOf("minute").toDate()
  const userId = user ? user.uid : null
  const [numUpcomingBookedEvents] = useFirestoreSubscribe({
    functionalQuery: db =>
      db.collection('participants')
        .where('userId', '==', userId)
        .where('status', '==', 'upcoming')
        .where("start", ">=", startingCutoffBooked)
        .orderBy("start", "desc"),
    fields: [],
    transform: bookedEvents => bookedEvents.length
  })

  return numUpcomingBookedEvents
}