/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import dayjs from 'dayjs';

import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { PurpleBanner } from '../../components/banners';

import { UserContext } from '../../UserProvider';
import { SubscriptionStatus } from '../../../functions/shared/constants';

export const TrialBanner = () => {
  const { user } = useContext(UserContext);
  const now = new Date()
  const location = useLocation()
  const hiddenPaths = ['plans', 'home-onboarding']
  if (hiddenPaths.some(path => location.pathname.includes(path))) {
    return null
  }

  // @ts-ignore:next-line
  const isOnTrial = user !== null && user.subscriptionStatus === SubscriptionStatus.TRIALING_NO_CARD
  if (!isOnTrial) {
    return null
  }
  // @ts-ignore:next-line
  const trialEnd = user.trialEnd ? user.trialEnd.toDate() : now

  return (
    <PurpleBanner>
      {trialEnd > now ? `${dayjs(trialEnd).fromNow(true)} left on your free trial.` :
        "You free trial has ended."}
      &nbsp;<Link to="/plans/">See plans</Link>
    </PurpleBanner>
  )
}