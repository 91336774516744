/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import '../Schedule.scss';
import { Modal } from "react-bootstrap";
import UpdateProfile from "../../components/settings/update-profile/update-profile";

export function UpdateNameModal(props) {
  const { title, closeFn, successFn = () => { }, ...modalProps } = props;
  return (
    <Modal
      {...modalProps}
      show
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UpdateProfile onSuccess={() => { closeFn(); successFn(); }} showHeader={false} />
      </Modal.Body>
    </Modal>
  );
}