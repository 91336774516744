/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_BLUE, FC_DARK_BLUE, FC_GREEN, FC_LIGHT_BLUE, NEUTRAL_50, NEUTRAL_60, NEUTRAL_80, NEUTRAL_90 } from '../emotionVariables';
import { Text } from './flowComponents';

export const AgendaDescription = ({ agenda, currentStage = null }) => {
  const previewDisplay = currentStage === null
  return <div>
    {agenda.map(({ duration, name, activeWorkingTime }, index) => {
      const height = duration < 5 ? 12 : duration * 1.2
      return (
        <div css={css`display: flex; gap: 4px; user-select: none;`}>
          <div css={css`
            background-color: ${activeWorkingTime ? FC_BLUE : FC_LIGHT_BLUE};
            width: 4px;
            height: ${height}px;
            border-radius: ${index === 0 ? '8px 8px' : '0px 0px'} ${index === agenda.length - 1 ? '8px 8px' : '0px 0px'};
          `} />
          <Text customCss={css`font-size: 12px; line-height: ${height}px; color: rgba(0, 0, 0, .6); flex-basis: 40px;`}>{duration}m</Text>
          <Text customCss={css`
            font-size: 12px;
            line-height: ${height}px;
            ${previewDisplay ? css`
              color: ${activeWorkingTime ? FC_DARK_BLUE : NEUTRAL_90};
              ${activeWorkingTime && css`font-weight: bold;`}
            ` : css`
              color: ${currentStage === index ? FC_GREEN : (currentStage > index ? NEUTRAL_60 : NEUTRAL_90 )};
              ${currentStage === index && css`font-weight: bold;`}
            `}
          `}>{name}</Text>
        </div>
      )
    })}
  </div>
}