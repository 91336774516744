/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RecommendationPopoverContent } from './RecommendationPopoverContent';
import { Modal } from '../components/flowComponents';

export const RecommendationModal = ({ onHide, onBookingSuccess, recommendations }) => {
  return (
    <Modal onClose={onHide}>
      <div css={css`
        padding: 56px 8px 32px;
      `}>
        <RecommendationPopoverContent
          recommendations={recommendations}
          onBookingSuccess={onBookingSuccess}
        />
      </div>
    </Modal>
  )
}