import { useState } from "react"
import { ThemeFilterOptions } from "../../../functions/shared/constants"
import { useSetUserPreferences } from "../../fetch/endpoints"
import { usePreferences } from "../../UserProvider"
import { useDebounced, useQuery } from "../../utils"

const themeFilterOptions = ThemeFilterOptions

export const useUrlParamThemeFilters = () => {
  const query = useQuery()
  const queryThemeFilter = query.get('themeFilter')
  const queryThemeFilterActive = queryThemeFilter !== null && Object.values(ThemeFilterOptions).flat().find(({name}) => name === queryThemeFilter)
  return queryThemeFilterActive ? queryThemeFilter : null
}

export const getOnlyShowChatOnly = (activeThemeFilters) => {
  return activeThemeFilters.showChatOnly && !activeThemeFilters.showNonChatOnly
}

export const getOnlyShowPomodoro = (activeThemeFilters) => {
  return activeThemeFilters.showPomodoro && !activeThemeFilters.showNonPomodoro
}

export const useThemeFilters = (events) => {
  const { sessionThemeFilters: savedFilters } = usePreferences()
  
  const urlParamThemeFilter = useUrlParamThemeFilters()
  const [activeThemeFilters, setActiveThemeFilters] = useState(
    urlParamThemeFilter !== null ?
      { [urlParamThemeFilter]: true }
      : savedFilters ?? {}
  )

  const { performFetch: setUserPreferences } = useSetUserPreferences()
  const themeFilterValues = Object.values(themeFilterOptions).flat()
  const activeThemesFull = themeFilterValues.filter(({name}) => activeThemeFilters[name])
  const activeThemes = activeThemesFull.reduce((themeFilterValues, {field, value}) => ({
    ...themeFilterValues,
    [field]: themeFilterValues[field]
      ? [...themeFilterValues[field], value]
      : [value]
  }), {});
  const activeThemesToFilter = Object.entries(activeThemes).filter(
    ([field, values]) => values.length === 1).map(([field, values]) => [field, values[0]])
  
  // 2023-06-27: At least for this initial version with just chat-only/non-chat-only and pomodoro/non-pomodoro,
  // We only have to account for the case where one of them is set to true and the other is set to false or not set.
  // 2024-05-17: This remains true for check-ins during breaks/no check-ins during breaks.
  const eventsForActiveThemeFilters = events.filter(event => activeThemesToFilter.every(([field, value]) => event[field] === value))

  const [saveSelectedFilters] = useDebounced((sessionThemeFilters) => {
    setUserPreferences({ newPreferenceData: { sessionThemeFilters } })
  }, 1000, true)

  const setNewFilters = newSelectedFilters => {
    setActiveThemeFilters(newSelectedFilters)
    saveSelectedFilters(newSelectedFilters)
  }

  return {eventsForActiveThemeFilters, themeFilterOptions, activeThemeFilters, setActiveThemeFilters: setNewFilters}
}