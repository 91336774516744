const querystring = require('querystring');
const dayjs = require('dayjs');
const {
  GENERAL_DESCRIPTION,
  DESCRIPTION_FEEDBACK_FOOTER,
  CALENDAR_ORGANIZER_EMAIL,
  CALENDAR_ORGANIZER_NAME,
} = require('./constants');
const { createEvent } = require('ics');


const googleCalendarAddToCalendarBaseUrl = 'https://calendar.google.com/calendar/render'
const outlookCalendarAddToCalendarBaseUrl = 'https://outlook.live.com/calendar/0/deeplink/compose'

// TODO (2023-03-09: David): In order to share this between frontend and backend, we just need to hardcode baseURL here
// since the backend uses functions config and the frontend uses process.env variables to determine `baseURL` and `DOMAIN`

const baseURL = 'https://in.flow.club'

const getSessionLink = ({ id, urlParams }) => {
  return `${baseURL}/s/${id}${urlParams !== undefined ? `?${querystring.stringify(urlParams)}` : ''}`
}

const getSessionDetailsLink = ({ id, urlParams }) => {
  return `${baseURL}/session/${id}${urlParams !== undefined ? `?${querystring.stringify(urlParams)}` : ''}`
}

const GCAL_DATE_FORMAT = 'YYYYMMDDTHHmmssZ'

const getTitleDescriptionLinkForCalendar = (event, calendarType, source='session-details', sessionJoinLink = false) => {
  const id = event.id
  const description = event.description
  const title = event.title
  const hostUser = event.hostUser
  const urlParams = {
    'utm_source': calendarType,
    'utm_medium': 'calendar',
    'utm_campaign': source,
  }
  const sessionLink = sessionJoinLink ? getSessionLink({ id, urlParams }) : getSessionDetailsLink({ id, urlParams })

  // Both Google Calendar and ICS for Apple calendar use `\n` whereas outlook uses `<br/>`
  const lineBreak = calendarType === 'outlook-calendar' ? '<br/>' : '\n'
  const manualAddDescription = source == 'session-details' ? `${hostUser.displayName} is hosting a Flow Club session to get more done together. You previously added this event because you thought it looked interesting!${lineBreak}${lineBreak}` : ''
  const calendarDescription = `${manualAddDescription}${description}.${lineBreak}${lineBreak}Join here:${lineBreak}${sessionLink}${lineBreak}${lineBreak}Here's what to expect in a Flow Club co-working session:${lineBreak}${GENERAL_DESCRIPTION}${lineBreak}${lineBreak}Connect your Google Calendar to plan your tasks ahead of the session and automatically remove events from your calendar when you cancel: https://in.flow.club/settings/account/${lineBreak}${lineBreak}${DESCRIPTION_FEEDBACK_FOOTER}`;
  return {
    title: `${title} w/ ${hostUser.displayName}`,
    description: calendarDescription,
    link: sessionLink,
  }
}
exports.getTitleDescriptionLinkForCalendar = getTitleDescriptionLinkForCalendar

const getGoogleAddToCalendarUrl = (event) => {
  const start = event.start
  const end = event.end

  const titleDescriptionLink = getTitleDescriptionLinkForCalendar(event, 'google-calendar')
  const title = titleDescriptionLink.title
  const description = titleDescriptionLink.description
  const link = titleDescriptionLink.link
  // See this for a list of params: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
  const eventParams = {
    action: 'TEMPLATE',
    text: title,
    details: description,
    location: link,
    dates: `${dayjs(start).format(GCAL_DATE_FORMAT)}/${dayjs(end).format(GCAL_DATE_FORMAT)}`,
  }
  return `${googleCalendarAddToCalendarBaseUrl}?${querystring.stringify(eventParams)}`
}
exports.getGoogleAddToCalendarUrl = getGoogleAddToCalendarUrl

const getOutlookAddToCalendarUrl = (event) => {
  const start = event.start
  const end = event.end

  const titleDescriptionLink = getTitleDescriptionLinkForCalendar(event, 'outlook-calendar')
  const title = titleDescriptionLink.title
  const description = titleDescriptionLink.description
  const link = titleDescriptionLink.link

  const eventParams = {
    path: 'calendar/action/compose',
    rru: 'flowclub',
    subject: title,
    body: description,
    location: link,
    startdt: start.toISOString(),
    enddt: end.toISOString(),
  }
  return `${outlookCalendarAddToCalendarBaseUrl}?${querystring.stringify(eventParams)}`
}
exports.getOutlookAddToCalendarUrl = getOutlookAddToCalendarUrl


const ICSDateFormat = 'YYYY-M-D-H-m'


const getICSDateFormat = (dateAndTime) => dayjs(dateAndTime).format(ICSDateFormat).split('-').map(dateNumber => parseInt(dateNumber, 10))


const createICSEvent = ({event, user = null, calendarType, source, sessionJoinLink = false, eventProperties = {}, attendeeProperties = {}}) => {
  const start = event.start
  const end = event.end

  // eventProperties may overwrite some of these
  const eventAttributes = {
    start: getICSDateFormat(start),
    end: getICSDateFormat(end),
    status: 'CONFIRMED',
    method: 'REQUEST',
    productId: 'Flow Club',
    organizer: {name: CALENDAR_ORGANIZER_NAME, email: CALENDAR_ORGANIZER_EMAIL},
  }
  if (!eventProperties.title || !eventProperties.description || !eventProperties.location || !eventProperties.url) {
    var defaultValues = getTitleDescriptionLinkForCalendar(event, calendarType, source, sessionJoinLink);
    Object.assign(eventAttributes, {
      title: defaultValues.title,
      description: defaultValues.description,
      location: defaultValues.link,
      url: defaultValues.link
    });
  }
  
  Object.assign(eventAttributes, eventProperties);

  if (user !== null) {
    const emails = Object.keys(user.additionalCalendarEmails || {});
    emails.push(user.email)
    // attendeeProperties may overwrite some of these
    eventAttributes.attendees = emails.map(function(email) {
      var attendee = {
        email: email,
        name: user.displayName,
        rsvp: true,
        partstat: 'ACCEPTED',
        role: 'REQ-PARTICIPANT'
      };
      return Object.assign(attendee,
      attendeeProperties);
    });
  }
  return createEvent(eventAttributes)
}

exports.createICSEvent = createICSEvent