/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { toastError } from '../components/utils/toaster';
import { useParticipantIds, useVideoTrack } from '@daily-co/daily-react';
import { FC_DARK_BLUE } from '../emotionVariables';

export const Pip = ({ onCanvasMount, dailyVideoElement }) => {
  return (
    <div>
      <canvas css={css`display: none;`} ref={onCanvasMount} width="384" height="216"></canvas>
      <div css={css`visibility: hidden; width: 0px; height: 0px; position: fixed; top: 50vh; left: 50vw;`}>
        <video crossOrigin={'anonymous'} ref={dailyVideoElement} id="video" width="0" height="0" controls autoPlay loop muted />
      </div>
    </div>
  )
}



function drawImageCenteredAndScaled(img, ctx) {
  var currCanvas = ctx.canvas;
  const { videoWidth, videoHeight } = img
  var hRatio = currCanvas.width / videoWidth;
  var vRatio = currCanvas.height / videoHeight;
  var ratio = Math.max(hRatio, vRatio);
  var centerShift_x = 0 // (currCanvas.width - videoWidth * ratio) / 2;
  var centerShift_y = 0 // (currCanvas.height - videoHeight * ratio) / 2;
  ctx.clearRect(0, 0, currCanvas.width, currCanvas.height);
  ctx.drawImage(
    img,
    0,
    0,
    videoWidth,
    videoHeight,
    centerShift_x,
    centerShift_y,
    videoWidth * ratio,
    videoHeight * ratio
  );
}

export const useCreatePipStream = ({ sessionFinished, goalText, timerText, active, userIsHost, onDeactivate }) => {
  const hostParticipantId = useParticipantIds({
    filter: ({ owner, tracks, local }) => owner && tracks.video.state === 'playable' && !local 
  })[0];


  const nonHostParticipantIds = useParticipantIds({
    sort: 'joined_at',
    filter: ({ owner, tracks, local }) => !owner && tracks.video.state === 'playable' && !local
  });

  const currentTimerText = useRef(timerText)
  currentTimerText.current = timerText

  const currentGoalText = useRef(goalText)
  currentGoalText.current = goalText

  const currentSessionFinished = useRef(sessionFinished)
  currentSessionFinished.current = sessionFinished

  const dailyVideoElementRef = useRef(null)
  const [dailyVideoElementMounted, setDailyVideoElementMounted] = useState(false)
  const dailyVideoElement = (element) => {
    dailyVideoElementRef.current = element
    setDailyVideoElementMounted(true)
  }
  const [dailyParticipantId, setDailyParticipantId] = useState((userIsHost ? nonHostParticipantIds[0] : hostParticipantId) ?? null)
  const videoTrackFromHook = useVideoTrack(dailyParticipantId)
  const videoTrack = dailyParticipantId !== null ? videoTrackFromHook : { state: "Waiting for video connection" }
  const latestVideoTrackState = useRef(null)
  latestVideoTrackState.current = videoTrack.state

  useEffect(() => {
    const selectedParticipantId = userIsHost
      ? nonHostParticipantIds[0]
      : (hostParticipantId || nonHostParticipantIds[0]);

    setDailyParticipantId(selectedParticipantId);

    if (videoTrack.state === "playable" && dailyVideoElementMounted) {
      const constraints = videoTrack.track.getConstraints()
      const width = constraints.width !== undefined ? constraints.width.ideal : 0
      const height = constraints.height !== undefined ? constraints.height.ideal : 0
      dailyVideoElementRef.current.width = width
      dailyVideoElementRef.current.height = height
      dailyVideoElementRef.current.srcObject = new MediaStream([videoTrack.track]);
    } else if (dailyVideoElementRef.current) {
      dailyVideoElementRef.current.srcObject = null;
    }
  }, [userIsHost, hostParticipantId, nonHostParticipantIds, videoTrack.state, dailyVideoElementMounted]);


  const latestPipActive = useRef(active)
  latestPipActive.current = active

  const canvasRef = useRef(null)

  async function createVideoForStream() {
    const video = document.createElement('video');
    video.setAttribute("id", "timerVideo");
    video.muted = true;
    video.srcObject = canvasRef.current.captureStream();
    video.play();
    // video.addEventListener('enterpictureinpicture', () => { });
    video.addEventListener('leavepictureinpicture', () => onDeactivate());
  
    return await new Promise(resolve => {
      video.onloadedmetadata = function () {
        resolve(video)
      };
    })
  }

  const pipGoalLength = 40

  const drawToCanvas = () => {
    const canvasElement = canvasRef.current
    const canvasCtx = canvasElement !== null ? canvasElement.getContext('2d') : null
    if (canvasCtx !== null && dailyVideoElementRef.current !== null) {
      // trying to do this seems to just make the drawing break entirely. why? it's a mystery
      // canvasElement.width = dailyVideoElementRef.current.width
      // canvasElement.height = dailyVideoElementRef.current.height

      if (latestVideoTrackState.current === "playable") {
        drawImageCenteredAndScaled(dailyVideoElementRef.current, canvasCtx)
      } else {
        canvasCtx.fillStyle = FC_DARK_BLUE;
        canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
      }

      const fontSize = !currentSessionFinished.current ? 52 : 30
      canvasCtx.font = `${fontSize}px sato`
      canvasCtx.textAlign = 'center';
      canvasCtx.textBaseline = "middle"
      canvasCtx.fillStyle = '#fff';
      canvasCtx.shadowColor = 'rgba(0, 0, 0, .8)';
      canvasCtx.shadowBlur = 8;

      if (currentGoalText.current !== undefined) {
        canvasCtx.fillText(currentTimerText.current, canvasElement.width / 2, canvasElement.height / 3);
        canvasCtx.font = `20px sato`
        const text = currentGoalText.current.length > pipGoalLength ? currentGoalText.current.substring(0, pipGoalLength - 3) + "..." : currentGoalText.current
        canvasCtx.fillText(text, canvasElement.width / 2, canvasElement.height /3 + fontSize + 10);
      } else {
        canvasCtx.fillText(currentTimerText.current, canvasElement.width / 2, canvasElement.height / 2);
      }
    }

    if (latestPipActive.current) {
      requestAnimationFrame(drawToCanvas)
    }
  }

  const startStream = async () => {
    try {
      drawToCanvas()
      const videoElement = await createVideoForStream();
      videoElement.requestPictureInPicture();
    } catch(err) {
      toastError({ message: err.message })
      console.error(err)
    }
  }

  const canvasDrawnTo = useRef(false)
  const onCanvasMount = (canvasElement) => {
    if (!canvasDrawnTo.current) {
      canvasRef.current = canvasElement
      canvasDrawnTo.current = true
    }
  }

  return { onCanvasMount, dailyVideoElement, startStream }
}