/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_IRIS_BLUE, FC_BLUE, FC_GREEN, FC_LIGHT_BLUE, NEUTRAL_40 } from '../emotionVariables';
import { formattedTimeFromMinutes } from "./CalendarBooking"
import { LoadingIndicator, Text, TEXT_STYLES } from '../components/flowComponents';
import dayjs from 'dayjs';
import { NowLine } from '../CalendarView/NowLine';
import { DATE_FORMAT } from '../../functions/shared/constants';

export const calculateDayColumnContents = ({
  eventsOnDay,
  selectedSpan,
  effectiveSelectedSpan,
  visualSelectedSpanEnd,
  spanSelectionFinished,
  visualHighlightedSlot,
  sessionRecommendations,
  gettingSessionRecommendations,
  popoverTargetRef,
  bookedRecommendations,
  date
}) => {
  const contents = [
    {
      startMinutes: 0,
      endMinutes: 60 * 24,
      customCss: css`
        background-color: white;
        border: 1px solid ${FC_IRIS_BLUE};
      `,
      key: `day-background`
    },
    ...(eventsOnDay.map(({ startMinutes, endMinutes, id }) => ({
      startMinutes,
      endMinutes,
      customCss: css`
        background-color: ${NEUTRAL_40};
        border-right: 1px solid ${FC_IRIS_BLUE};
        border-left: 1px solid ${FC_IRIS_BLUE};
      `,
      key: `occupied-${id}`
    }))),
  ]

  if (selectedSpan.startMinutes !== null && visualSelectedSpanEnd !== null) {
    contents.push({
      startMinutes: effectiveSelectedSpan.startMinutes,
      endMinutes: effectiveSelectedSpan.endMinutes,
      customCss: css`
        background-color: #5D5FEF40;
        box-shadow: inset 0px 0px 8px 1px white;
        border: 2px solid ${FC_IRIS_BLUE};
          border-radius: 4px;
        ${spanSelectionFinished && css`
          /* pointer-events: initial; */
        `}
      `,
      key: `selectedSpan`,
      children: <SelectedSpan
        startMinutes={effectiveSelectedSpan.startMinutes}
        endMinutes={effectiveSelectedSpan.endMinutes}
        loading={gettingSessionRecommendations}
        finishedSelecting={spanSelectionFinished}
        popoverTargetRef={popoverTargetRef}
      />
    })
    if (!spanSelectionFinished && effectiveSelectedSpan.startMinutes !== effectiveSelectedSpan.endMinutes) {
      contents.push({
        startMinutes: effectiveSelectedSpan.startMinutes,
        endMinutes: effectiveSelectedSpan.startMinutes,
        key: `selectedSpanStartAnchor`,
        children: <SelectionFrameDot dotLocation='left' />
      }, {
        startMinutes: effectiveSelectedSpan.endMinutes,
        endMinutes: effectiveSelectedSpan.endMinutes,
        key: `selectedSpanEndAnchor`,
        children: <SelectionFrameDot dotLocation='right' />
      })
    }
  }
  if (visualHighlightedSlot !== null && selectedSpan.startMinutes === null) {
    contents.push({
      startMinutes: visualHighlightedSlot,
      endMinutes: visualHighlightedSlot,
      key: `highlightedSlot`,
      children: <HighlightedSlot label={selectedSpan.startMinutes == null ? visualHighlightedSlot : null} />
    })
  }
  contents.push(
    ...(sessionRecommendations.map(({ type, startMinutes, endMinutes }, index) => ({
      startMinutes,
      endMinutes,
      key: `recommendation${startMinutes}-${endMinutes}-${type}`,
      children: <Recommendation
        type={type}
        // onClick={() => sessionClicked(index)}
        booked={bookedRecommendations.has(index)}
      />
    })))
  )
  if (dayjs().format(DATE_FORMAT) === date.format(DATE_FORMAT)) {
    const now = dayjs()
    const currentMinutes = now.hour() * 60 + now.minute()
    contents.push({
      startMinutes: currentMinutes,
      endMinutes: currentMinutes,
      key: `current-time`,
      children: <NowLine/>
    })
  }

  return contents
}

export const HighlightedSlot = ({ label = null }) => (
  <div css={css`
    background-color: ${FC_IRIS_BLUE};
    height: 2px;
    width: 100%;
    position: relative;
  `}>
    {label !== null && <div css={css`
      position: absolute;
      left: 0px;
      top: -30px;
      width: 100%;
      pointer-events: none;

      display: flex;
      justify-content: center;
    `}>
      <Text style={TEXT_STYLES.CAPTION}>{formattedTimeFromMinutes(label)}</Text>
    </div>}
  </div>
)

// implemented in a weird, vestigial, unnecessarily complicated way. such is life in the dot zone
export const SelectionFrameDot = ({ dotLocation = 'none' }) => (
  <div css={css`
    height: 2px;
    width: 100%;
    position: relative;
    ${dotLocation === 'left' && css`&::after {
      content: ""; width: 6px; height: 6px; background-color: ${FC_IRIS_BLUE}; position: absolute; top: -1px; left: -2px; border-radius: 100%;
    }`}
    ${dotLocation === 'right' && css`&::after {
      content: ""; width: 6px; height: 6px; background-color: ${FC_IRIS_BLUE}; position: absolute; top: -3px; right: -2px; border-radius: 100%;
    }`}
  `} />
)

export const SelectedSpan = ({ startMinutes, endMinutes, loading, finishedSelecting, popoverTargetRef }) => {
  return (
    <div ref={popoverTargetRef} css={css`
      width: 100%;
      height: 100%;
      cursor: pointer;

      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
    `}>
      {loading && <LoadingIndicator/>}
      <div css={css`
        position: absolute;
        left: 0px;
        top: -30px;
        width: 100%;
        pointer-events: none;

        display: flex;
        justify-content: center;
      `}>
        <Text style={TEXT_STYLES.CAPTION}>{formattedTimeFromMinutes(startMinutes)}</Text>
      </div>
      {startMinutes !== endMinutes &&
        <div css={css`
          position: absolute;
          left: 0px;
          bottom: -30px;
          width: 100%;
          pointer-events: none;

          display: flex;
          justify-content: center;
        `}>
          <Text style={TEXT_STYLES.CAPTION}>{formattedTimeFromMinutes(endMinutes)}</Text>
        </div>
      }
    </div>
  )
}

export const Recommendation = ({ type, onClick, booked }) => {
  const recommendationClicked = (event) => {
    event.stopPropagation()
    onClick()
  }

  return (
    <div css={css`
      padding: 0px 2px;
      width: 100%;
      height: 100%;
    `}>
      <div onClick={recommendationClicked} css={css`
        border-top: 1px solid ${FC_LIGHT_BLUE};
        border-bottom: 1px solid ${FC_LIGHT_BLUE};
        background-color: ${booked ? FC_GREEN : FC_BLUE};
        color: white;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        /* pointer-events: initial; */
        /* cursor: pointer; */
      `}>
      </div>
    </div>
  )
}