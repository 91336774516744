import { 
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { MainLoading } from './commonLayout/MainLoading';
import { useFirestoreQuery } from './firebase/helpers';

const GuestPass = () => {
  const match = useRouteMatch()
  const guestPassId = match.params.guestPassId

  const [ guestPass, loading ] = useFirestoreQuery({
    collection: 'invitedUsers',
    id: guestPassId,
    fields: ["id", "firstName", "lastName", "email", "invitedByName", "inviteUsed", "invitedBy", "invitedByUser", "redeemed"],
    transform: (guestPass) => ({...guestPass, inviteCode: guestPass && guestPass.inviteUsed ? guestPass.inviteUsed.id : null})
  })
  const error = (guestPass && (guestPass.redeemed || !guestPass.inviteCode)) ? "Sorry, that guest pass is invalid or has already been redeemed." : null

  if (guestPass !== null && error === null) {
    const { firstName, lastName, email, inviteCode } = guestPass
    return <Redirect to={`/upcoming?inviteCode=${encodeURIComponent(inviteCode)}${firstName ? `&firstName=${encodeURIComponent(firstName)}` : ''}${lastName ? `&lastName=${encodeURIComponent(lastName)}` : ''}${email ? `&email=${encodeURIComponent(email)}` : ''}`}/>
  } else if (!loading && error !== null) {
    return <Redirect to={`/signup?error=${error}`}/>
  } else {
    return <MainLoading/>
  }
}

export default GuestPass