/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NEUTRAL_50 } from '../../../emotionVariables';

import { WeeklyGoal } from './WeeklyGoal';
import { useState } from 'react'
import { usePreferences } from '../../../UserProvider'
import { useSetUserPreferences } from '../../../fetch/endpoints'
import { WeeklySessionGoalOptions } from '../../../../functions/shared/constants'
import { toastError, toastSuccess } from '../../utils/toaster'
import { SegmentProvider, useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { FlowButton } from '../../flowComponents';

const useGoalSettings = () => {
  const { weeklyHoursGoal: savedGoal } = usePreferences()
  const [weeklyActiveOption, setWeeklyActiveOption] = useState(savedGoal ?
    Object.keys(WeeklySessionGoalOptions)[Object.values(WeeklySessionGoalOptions).findIndex(({ hours }) => hours === savedGoal)] :
    Object.keys(WeeklySessionGoalOptions)[0])
  const { performFetch: setUserPreferences, fetching: loading } = useSetUserPreferences()

  const saveGoals = async () => {
    const { success } = await setUserPreferences({ newPreferenceData: { weeklyHoursGoal: WeeklySessionGoalOptions[weeklyActiveOption].hours } })
    if (success) {
      toastSuccess({ message: 'Successfully saved your changes!' })
    } else {
      toastError({ message: "There was an error saving your settings. Please try again or contact members@flow.club and we'll get this resolved." })
    }
  }

  const weeklyActiveOptionDirty = weeklyActiveOption !== Object.keys(WeeklySessionGoalOptions)[savedGoal]

  return { weeklyActiveOption, setWeeklyActiveOption, weeklyActiveOptionDirty, saveGoals, loading }
}


export const GoalSettings = ({ }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const { weeklyActiveOption, setWeeklyActiveOption, weeklyActiveOptionDirty, saveGoals, loading } = useGoalSettings()

  const saveButtonClicked = () => {
    saveGoals()
    // inconsistent names because "dirty" is probably confusing in a segment/amplitude context but "changed" feels a bit confusing in this code context where it might imply an event listener
    // is this dumb? probably 🤷‍♂️
    sendSegmentEvent("Weekly/Monthly Goal Settings Saved", { weeklyActiveOption, weeklyActiveOptionChanged: weeklyActiveOptionDirty, intentionChanged: false })
  }

  return (
    <div>
      <div>
        <WeeklyGoal activeOption={weeklyActiveOption} setActiveOption={setWeeklyActiveOption} />
        <FlowButton onClick={saveButtonClicked} loading={loading} fillAvailableWidth>Save Changes</FlowButton>
      </div>
    </div>
  )
}