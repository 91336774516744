
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import './host-info-tooltip.scss'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isArray } from 'lodash';
import { getImageForHost } from '../../functions/shared/helpers';

export const HostInfoTooltip = (hostInfo) => {
  if (!hostInfo) return ''
  const musicTitles = hostInfo.musicTitles ? isArray(hostInfo.musicTitles) ? hostInfo.musicTitles.join(', ') : hostInfo.musicTitles : ''
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <img src={getImageForHost(hostInfo, 'image100')} className="rounded-circle" alt={hostInfo.displayName} width={100} />
        </Col>
        <Col xs={9}>
          <div className="host-name mb-3"><strong>{hostInfo?.displayName}</strong></div>
          {hostInfo?.bio ? <p>{hostInfo?.bio}<Link to={`/host/${hostInfo.slug}`} css={css`margin-left: 8px;`}>See More</Link></p>
            : ""}
        </Col>
      </Row>
      {(hostInfo.musicImages && hostInfo.musicImages.length) ?
        <Row>
          <Col xs={{ offset: 3, span: 9 }}>
            <div className="pr-3 py-2"><strong>On {hostInfo?.displayName}'s Playlist</strong></div>
            <div className="host-music-images">
              {hostInfo.musicImages.map((musicImage) => (
                <img key={musicImage} className="host-music-image" src={musicImage} alt={`${hostInfo?.displayName}'s music`} />
              ))}
            </div>
          </Col>
        </Row> : ""}
      {musicTitles ?
        <Row className="mt-2">
          <Col xs={{ offset: 3, span: 9 }}>
            🎵 {musicTitles}
          </Col>
        </Row> : ""}
    </Container>
  )
}