/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { DarkText, LightGrayText, LoadingIndicator } from '../../components/flowComponents'
import { FC_DARK_BLUE } from '../../emotionVariables'

export const HostingFeedback = ({ feedbackData }) => {
  if (feedbackData === null) {
    return <LoadingIndicator/>
  }

  return (
    <div css={css`
      width: calc(100vw - 64px);
      max-width: 704px;
    `}>
      {feedbackData.eventsWithFeedback.map(({ eventData, feedback }) => <Event key={eventData.id} eventData={eventData} feedback={feedback} />)}
    </div>
  )
}

const Event = ({ eventData, feedback }) => {
  const { title, start, musicDescription } = eventData
  const displayDate = dayjs(start).format('h:mm A, ddd, MMM DD')

  return (
    <div css={css`
      letter-spacing: 0.5px;

      margin-bottom: 32px;
    `}>
      <div css={css`
        font-weight: 700;
        color: black;
      `}>{title}</div>
      <LightGrayText>{displayDate}</LightGrayText>
      {musicDescription !== '' &&
        <div css={css`
          color: ${FC_DARK_BLUE};

          margin-top: 8px;
        `}>
          🎶 {musicDescription}
        </div>
      }
      <div css={css`
        margin-top: 16px;
      `}>
        {feedback.map((feedbackItem, index) => <div key={index} css={index !== 0 && css`margin-top: 8px;`}><FeedbackItem feedbackItem={feedbackItem}/></div>)}
      </div>
    </div>
  )
}

const FeedbackItem = ({ feedbackItem }) => {
  const { name, hostFeedback: feedback } = feedbackItem

  return (
    <div css={css`
      padding: 16px 24px;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      line-height: 24px;
    `}>
      <DarkText>
        "{feedback}"
      </DarkText>
      <LightGrayText>
        — {name}
      </LightGrayText>
    </div>
  )
}