import { useEffect } from "react"
import useSound from "use-sound"
import { usePrevious } from "../utils"
import gongSound from '../sounds/gong.wav'

export const useGongOnBreakTimeEnd = (activeWorkingTime, sessionWorkingTimeStarted) => {
  const sessionStartTimeJustStarted = usePrevious(!sessionWorkingTimeStarted) && sessionWorkingTimeStarted
  const workingTimeJustStarted = usePrevious(!activeWorkingTime) && activeWorkingTime
  const breakTimeJustEnded = workingTimeJustStarted && !sessionStartTimeJustStarted
  const [playGong] = useSound(gongSound)
  useEffect(() => {
    if (breakTimeJustEnded) {
      playGong()
    }
  }, [breakTimeJustEnded])
}