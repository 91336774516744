/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import EventContext from '../../../EventContext';
import './more-menu.scss'
import Icon, {
  TYPE_CAMERA_OFF,
  TYPE_CAMERA_ON,
  TYPE_BLUR_ON,
  TYPE_BLUR_OFF,
  TYPE_BACKGROUND_ON,
  TYPE_BACKGROUND_OFF,
  TYPE_MIRROR_OFF,
  TYPE_MIRROR,
} from '../Icon/Icon';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { usePreferences } from '../../../UserProvider';
import { useSetUserPreferences } from '../../../fetch/endpoints';
import { CheckboxButton } from '../../../components/flowComponents';
import { TooltipWrapper } from '../../../components/TooltipWrapper';
import { toastSuccess } from '../../../components/utils/toaster';


export const MoreMenu = ({ closeFn }) => {
  const { hidingSelfView, setHidingSelfView, mirrorSelfView, setMirrorSelfView } = useContext(EventContext);
  return (
    <div css={css`width: 300px;`}>
      <div className="more-menu options-menu">
        <span className="menu-subtitle">
          Video
        </span>
        <BackgroundImageToggle />
        <BackgroundBlurToggle />
        <HideSelfViewToggle hidingSelfView={hidingSelfView} setHidingSelfView={setHidingSelfView}/>
        <MirrorSelfViewToggle hidingSelfView={hidingSelfView} mirrorSelfView={mirrorSelfView} setMirrorSelfView={setMirrorSelfView} />
        <span className="menu-subtitle">
          Other
        </span>
        <ConfettiToggle/>
      </div>
    </div>
  );
}

export const BackgroundBlurToggle = () => {
  const { backgroundBlurOn, onToggleBackgroundBlur, backgroundImageOn } = useContext(EventContext)

  return (
    <div className="host-controls-section">
      <TooltipWrapper TooltipContents={backgroundImageOn ? `Disable background image to ${backgroundBlurOn ? "disable" : "enable"} blur` : backgroundBlurOn ? "Disable background blur" : "Enable background blur—not recommended on older machines"}>
        <Button
          variant="menu"
          disabled={backgroundImageOn}
          onClick={onToggleBackgroundBlur}
        >
          <div className="more-menu-device-icon"><Icon type={backgroundBlurOn ? TYPE_BLUR_ON : TYPE_BLUR_OFF} /></div>
          <div className="more-menu-button-text">Turn {backgroundBlurOn ? 'Off' : 'On'} Background Blur</div>
        </Button>
      </TooltipWrapper>
    </div>
  )
}

export const BackgroundImageToggle = () => {
  const { backgroundImageOn, onToggleBackgroundImage, mirrorSelfView } = useContext(EventContext)

  const toggleBackgroundImageAndWarnAboutMirrorIfNecessary = () => {
    if (!backgroundImageOn && mirrorSelfView) {
      toastSuccess({ message: "Your background image looks good! The text appears reversed to you due to mirroring, but it's correct for others. Disable mirroring to see their view.", duration: 8000 })
    }
    onToggleBackgroundImage()
  }

  return (
    <div className="host-controls-section">
      <Button
        variant="menu"
        onClick={toggleBackgroundImageAndWarnAboutMirrorIfNecessary}
      >
        <div className="more-menu-device-icon"><Icon type={backgroundImageOn ? TYPE_BACKGROUND_ON : TYPE_BACKGROUND_OFF } css={css`width: 44px; height: 44px;`}/></div>
        <div className="more-menu-button-text">Turn {backgroundImageOn ? 'Off' : 'On'} Background Image</div>
      </Button>
    </div>
  )
}

export const MirrorSelfViewToggle = ({ mirrorSelfView, setMirrorSelfView, hidingSelfView }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const onClick = () => {
    sendSegmentEvent("Mirror Self View Toggled", { togglingTo: !mirrorSelfView })
    if (mirrorSelfView) {
      localStorage.setItem('turnOffMirrorSelfView', true)
    } else {
      localStorage.removeItem('turnOffMirrorSelfView')
    }
    setMirrorSelfView(mirrorSelfView => !mirrorSelfView)
  }

  return (
    <div className="host-controls-section">
      <TooltipWrapper TooltipContents={hidingSelfView ? "Enable self-view to adjust mirror settings" : mirrorSelfView ? "Disabling this shows you as others see you" : "Mirror your view to see yourself as in a mirror. Others see you normally"}>
        <Button
          variant="menu"
          disabled={hidingSelfView}
          onClick={onClick}
        >
          <div className="more-menu-device-icon"><Icon type={mirrorSelfView ? TYPE_MIRROR : TYPE_MIRROR_OFF } /></div>
          <div className="more-menu-button-text">Turn {mirrorSelfView ? 'Off' : 'On'} Mirror My View</div>
        </Button>
      </TooltipWrapper>
    </div>
  )
}


export const HideSelfViewToggle = ({ hidingSelfView, setHidingSelfView }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const onClick = () => {
    // toggling to: true here means hiding turned on, aka self view turned off
    sendSegmentEvent("Hide Self View Toggled", { togglingTo: !hidingSelfView })
    if (!hidingSelfView) {
      localStorage.setItem('hidingSelfView', true)
    } else {
      localStorage.removeItem('hidingSelfView')
    }
    setHidingSelfView(hidingSelfView => !hidingSelfView)
  }

  return (
    <div className="host-controls-section">
      <TooltipWrapper TooltipContents={"Disabling this hides your video from yourself"}>
        <Button
          variant="menu"
          onClick={onClick}
        >
          <div className="more-menu-device-icon"><Icon type={hidingSelfView ? TYPE_CAMERA_OFF : TYPE_CAMERA_ON} /></div>
          <div className="more-menu-button-text">Turn {hidingSelfView ? 'On' : 'Off'} Self View</div>
        </Button>
      </TooltipWrapper>
    </div>
  )
}

const ConfettiToggle = () => {
  const { disablePopConfetti } = usePreferences()
  const [confettiDisabled, setConfettiDisabled] = useState(disablePopConfetti)
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: setUserPreferences } = useSetUserPreferences()

  const confettiToggled = async (disabling) => {
    sendSegmentEvent("Disable Pop Confetti Toggled", { togglingTo: disabling })
    setConfettiDisabled(disabling)
    await setUserPreferences({ newPreferenceData: { disablePopConfetti: disabling } })
  }

  return (
    <div className="host-controls-section">
      <div css={css`height: 46px; display: flex; align-items: center;`}>
        <CheckboxButton selected={confettiDisabled} setSelected={confettiToggled}><span className='btn btn-menu'>Disable Confetti</span></CheckboxButton>
      </div>
    </div>
  )
}
