/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { BUTTON_STYLES, FlowButton } from '../../components/flowComponents';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';

export const ViewSchedulePrompt = ({ dismissPrompt }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const nahClicked = () => {
    sendSegmentEvent("[View Schedule Prompt] Dismissed By User")
    dismissPrompt()
  }

  const seeSessionsLinkClicked = () => {
    sendSegmentEvent("[View Schedule Prompt] Link Clicked")
  }

  useEffect(() => {
    sendSegmentEvent("[View Schedule Prompt] Shown")
    return () => sendSegmentEvent("[View Schedule Prompt] Hidden")
  }, [])

  return (
    <div css={css`
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    `}>
      <div css={css`
        font-family: 'Sato';
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        /* text-align: center; */
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

        margin-bottom: 12px;
      `}>
        Want more accountability?
      </div>
      <div css={css`
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

        margin-bottom: 40px;
      `}>
        Try a video co-working session
      </div>
      <div css={css`
        display: flex;
        width: 100%;
        justify-content: center;
      `}>
        <FlowButton customCss={css`margin-right: 12px;`} buttonStyle={BUTTON_STYLES.OUTLINE_LIGHT} onClick={nahClicked}>Nah</FlowButton>
        <a href={'/upcoming'} target="_blank" rel="noreferrer" onClick={seeSessionsLinkClicked}>
          <FlowButton>See Sessions</FlowButton>
        </a>
      </div>
    </div>
  )
}