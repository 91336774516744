/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const LoungeTooltip = ({ children, arrowX, arrowRef, placement, refs }) => {
  const isTop = placement === 'top';
  const wrapperHeight = getWrapperHeight(refs);
  return (
    <>
      <div css={css`
        padding: 24px 32px;
        background-color: black;
        border-radius: 4px;
        color: white;
      `}>
        {children}
      </div>
      <div css={css`
        /* A slightly hacky extra element to add the little wedge at the top */
        left: ${arrowX !== null ? `${arrowX}px` : ''};
        position: absolute;
        border: 8px solid; 
        border-color: transparent transparent black transparent;
        
        /* Rotate and re-position the arrow if tooltip auto-placement puts us at the 'top' position */
        top: ${isTop ? wrapperHeight : -16}px;
        transform: ${isTop ? 'rotate(180deg)' : 'none'};
      `} ref={arrowRef} />
    </>
  )
}

function getWrapperHeight(refs) {
  if (!refs?.floating?.current) return 0;
  return refs.floating.current.getBoundingClientRect().height;
}