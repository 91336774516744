import { TooltipWrapper } from '../components/TooltipWrapper';
import { sessionLimitTooltip } from './sessionLimitTooltip';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { EMPTY_HOST_ID } from './SessionUtils';

export const getLimitedSeatsText = ({ guests, maxAttendees, canceledSeatOpen }) => {
  if (!guests) { return null }
  const actualGuests = guests.filter(id => id !== EMPTY_HOST_ID)
  const limitedSeats = Boolean(actualGuests && maxAttendees && (actualGuests.length >= maxAttendees - 2))
  if (!limitedSeats) { return null }

  const text = actualGuests.length < maxAttendees ?
  canceledSeatOpen ?
    `⚡️ ${maxAttendees - actualGuests.length} seat${maxAttendees - actualGuests.length === 1 ? "" : "s"} just opened up!` :
    `🔥 ${maxAttendees - actualGuests.length} seat${maxAttendees - actualGuests.length === 1 ? "" : "s"} left!`
  : "🏠 Full house!"

  return text
}

export const LimitedSeats = ({ guests, maxAttendees, canceledSeatOpen }) => {
  const text = getLimitedSeatsText({ guests, maxAttendees, canceledSeatOpen })
  if (text === null) {
    return null
  }

  return (
    <TooltipWrapper TooltipContents={sessionLimitTooltip} useFloatingArgs={{ placement: 'top', middleware: [shift({ padding: 32 }), offset(12), flip()] }}>
      {text}
    </TooltipWrapper>
  )
}