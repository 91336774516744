import { reorder, useDebounced, usePrevious } from '../../utils';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { v4 as uuidv4 } from 'uuid';

const GOAL_SUGGESTIONS = [
  "Warmup for work",
  "Take 3 deep breaths",
  "Relax the shoulders",
  "Clear your workspace",
  "Take a comfortable seat, or stance",
  "Do the easy thing first",
  "Review today's priorities",
  "Book a Flow Club session"
]

export const makeNewGoalObject = (existingGoals, newGoalData = null) => {
  // arbitrary id, just used for react keying -- not stored serverside
  const id = uuidv4()
  
  if (newGoalData !== null) {
    return {
      text: newGoalData.text,
      placeholder: null,
      completedAt: newGoalData.completed ? Math.floor(Date.now() / 1000) : null,
      id
    }
  }

  const unusedGoals = GOAL_SUGGESTIONS.filter(goal => !existingGoals.some(({ text, placeholder }) => text === goal || placeholder === goal))
  return {
    text: existingGoals.length === 0 ?
      unusedGoals[Math.floor(Math.random() * unusedGoals.length)] :
      '',
    placeholder: existingGoals.length > 0 ?
      unusedGoals[Math.floor(Math.random() * unusedGoals.length)] :
      null,
    completedAt: null,
    id
  }
} 

export const useGoals = (goalsState, onGoalCompleted = () => { }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const [goals, setGoals] = goalsState
  const currentGoal = goals.find(goal => !goal.completedAt)

  const addGoal = () => {
    setGoals(goals => [...goals, makeNewGoalObject(goals)])
    sendSegmentEvent("Goal Added")
  }

  const pasteGoals = (newGoals, prefilled = false) => {
    setGoals(goals => [...goals, ...newGoals.map(goal => makeNewGoalObject(goals, goal))])
    sendSegmentEvent("Goals Bulk Pasted", { prefilled })
  }

  const replaceGoals = (newGoals) => {
    setGoals(newGoals.map(goal => ({ ...goal, completed: goal.completedAt !== null })))
    sendSegmentEvent("Goals Replaced")
  }

  const [sendGoalEditedSegmentEvent] = useDebounced((oldText, newText) => sendSegmentEvent("Goal Edited", { oldText, newText }), 2000)

  const goalEdited = (text, editedIndex) => {
    sendGoalEditedSegmentEvent(goals[editedIndex].text, text)
    setGoals(goals => goals.map((goal, index) => editedIndex === index ? { ...goal, text } : goal))
  }

  const toggleCompleted = (editedIndex) => {
    let reorderCompletedGoal = false
    if (goals[editedIndex].completedAt === null) {
      reorderCompletedGoal = true
      onGoalCompleted()
      sendSegmentEvent("Goal Marked Completed", { text: goals[editedIndex].text })
    } else {
      sendSegmentEvent("Goal Marked Uncompleted", { text: goals[editedIndex].text })
    }

    setGoals(goals => {
      let newGoals = goals.map((goal, index) => editedIndex === index ? { ...goal, completedAt: goal.completedAt === null ? Math.floor(Date.now() / 1000) : null } : goal)
      if (reorderCompletedGoal) {
        const firstUncompletedGoalIndex = goals.findIndex(goal => goal.completedAt === null)
        newGoals = reorder(newGoals, editedIndex, firstUncompletedGoalIndex)
      }
      return newGoals
    })
  }

  const removeGoal = (editedIndex) => {
    sendSegmentEvent("Goal Removed", { text: goals[editedIndex].text })
    setGoals(goals => goals.filter((goal, index) => index !== editedIndex))
  }

  const reorderGoals = ({ originalIndex, destinationIndex }) => {
    setGoals(goals => reorder(goals, originalIndex, destinationIndex))
  }

  const previousGoals = usePrevious(goals) ?? []
  const justCompletedIndex = goals.findIndex(goal => {
    const previousGoal = previousGoals.find(({ id }) => goal.id === id)
    return goal.completedAt !== null && previousGoal !== undefined && previousGoal.completedAt === null
  })

  return { goals, addGoal, pasteGoals, replaceGoals, goalEdited, toggleCompleted, removeGoal, reorderGoals, justCompletedIndex, currentGoal }
}