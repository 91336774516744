/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createContext, useContext, useState } from 'react';
import Confetti from 'react-confetti'
import { v4 as uuidv4 } from 'uuid';
import { usePreferences } from '../UserProvider';

const ConfettiContext = createContext({
  emitConfetti: () => {},
  emitPopConfetti: () => {},
})

const generateColorVariations = baseColor => {
  const colorVariations = []
  const [hue, saturation, baseLightness] = baseColor
  const VARIATIONS = 8
  for (let i = 0; i < VARIATIONS / 2; i++) {
    const darkenedLightness = baseLightness * i / (VARIATIONS / 2)
    const brightenedLightness = baseLightness + ((100 - baseLightness) * i / (VARIATIONS / 2))
    colorVariations.push(
      `hsl(${hue}, ${saturation}%, ${darkenedLightness}%)`,
      `hsl(${hue}, ${saturation}%, ${brightenedLightness}%)`
    )
  }
  colorVariations.push(`hsl(${hue}, ${saturation}%, ${baseLightness}%)`)
  return colorVariations
}

export const ConfettiProvider = ({ children }) => {
  const { disablePopConfetti } = usePreferences()

  const [renderConfetti, setRenderConfetti] = useState(false)
  const emitConfetti = () => {
    if (disablePopConfetti) { return }
    setRenderConfetti(true)
  }

  const [popConfetti, setPopConfetti] = useState([])
  const emitPopConfetti = ({ x, y, color }) => {
    if (disablePopConfetti) { return }
    setPopConfetti(popConfetti => [...popConfetti, { id: uuidv4(), x, y, color }])
  }

  return (
    <>
      {renderConfetti && <Confetti
        run={renderConfetti}
        recycle={false}
        numberOfPieces={200}
        colors={['#091454', '#515EA8', '#D2DDEC', '#EAF3FF', '#D5E3E0', '#A5F3E3', '#EBB4A9', '#F9F9F9', '#333232']}
        onConfettiComplete={confetti => {
          setRenderConfetti(false)
          confetti.reset()
        }}
        width={window.innerWidth}
        height={window.innerHeight}
      />}

      {!disablePopConfetti && popConfetti.map(({ id, x, y, color }) => {
        const colorVariations = generateColorVariations(color)
        return (
          <Confetti
            key={id}
            run={true}
            recycle={false}
            numberOfPieces={200}
            colors={colorVariations}
            onConfettiComplete={confetti => {
              confetti.reset()
              setPopConfetti(popConfetti => popConfetti.filter(confettiData => confettiData.id !== id))
            }}
            width={window.innerWidth}
            height={window.innerHeight}
            confettiSource={{ x, y, w: 0, h: 0 }}
            tweenDuration={1}
            initialVelocityX={4}
            friction={0.99}
            gravity={0.1}
            initialVelocityY={{ min: -10, max: 0 }}
          />
        )
      })}

      <ConfettiContext.Provider value={{ emitConfetti, emitPopConfetti }}>
        {children}
      </ConfettiContext.Provider>
    </>
  )
}

export const useConfetti = () => useContext(ConfettiContext).emitConfetti
export const usePopConfetti = () => useContext(ConfettiContext).emitPopConfetti