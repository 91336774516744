/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_DARK_BLUE } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';
import { useContext, useEffect } from 'react';
import { UserContext } from '../UserProvider';
import { ConnectCalendarButton } from '../CalendarBooking/ConnectCalendarButton';
import { useUserMakingTimeFor } from './useUserMakingTimeFor';

export const ConnectCalendarPane = () => {
  const { largeScreenView, goToNextPane } = useContext(InterstitialContext)

  const { user } = useContext(UserContext)
  const makingTimeFor = useUserMakingTimeFor()
  // imagine a world in which security.
  // it's not this world.
  const userHasRefreshToken = user !== null && user.googleCalendarAuth !== undefined
  useEffect(() => {
    if (userHasRefreshToken) {
      goToNextPane()
    }
  }, [userHasRefreshToken])

  return (
    <>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? '54' : '40'}px;
        text-align: center;
      `}>Let's block out time to commit to {makingTimeFor}!</h2>
      <div css={css`letter-spacing: 0.15px; text-align: center; color: ${FC_DARK_BLUE}; margin-bottom: 20px; width: 300px;`}>Connect your Google calendar and we'll find time.</div>
      <ConnectCalendarButton onSuccess={goToNextPane}/>
    </>
  )
}