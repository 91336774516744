/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import { LoadingIndicator, Text } from './components/flowComponents';
import { toastError } from './components/utils/toaster';
import { useUnfollowHostByFollowId } from './fetch/endpoints';
import { useAutomaticallyFetch } from './fetch/helpers';

export const HostFollowUnsubscribe = ({ }) => {
  const { hostFollowId } = useParams()

  const { result: unsubscribed, error } = useAutomaticallyFetch(useUnfollowHostByFollowId, { hostFollowId }, {transform: result => result.unsubscribed})
  useEffect(() => {
    if (error !== null) {
      toastError({ message: error })
    }
  }, [error])
  if (unsubscribed === false) {
    return <Redirect to="/" />
  }

  return (
    <div css={css`
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    `}>
      {!unsubscribed ?
        <>
          <Text>Unsubscribing you from host follow notifications...</Text>
          <LoadingIndicator />
        </> :
        <Text>Successfully unsubscribed!</Text>
      }
    </div>
  )
}