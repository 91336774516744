/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FlowButton } from '../components/flowComponents';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { TimeDisplay } from './TimeDisplay';
import { useTimer } from './useTimer';

export const JoinSessionTimer = ({ nextUpcomingParticipant }) => {
  const timeToUpcomingSession = nextUpcomingParticipant.start._seconds - dayjs().unix()

  const minutesUntilSessionLinkExpires = 5 + timeToUpcomingSession / 60
  const onTimerFinished = () => {
    startSessionAlreadyStartedTimer(dayjs.duration(5, 'minutes'))
  }
  const { timeRemaining: timeUntilSession, start: startTimeUntilSessionTimer, active: timeUntilSessionTimerActive } = useTimer(timeToUpcomingSession / 60, 1000, onTimerFinished)
  const { timeRemaining: timeUntilSessionLinkExpires, start: startSessionAlreadyStartedTimer } = useTimer(minutesUntilSessionLinkExpires, 1000)
  useEffect(() => {
    if (timeToUpcomingSession > 0) {
      startTimeUntilSessionTimer()
    } else {
      startSessionAlreadyStartedTimer(dayjs.duration(minutesUntilSessionLinkExpires, 'minutes'))
    }
  }, [])


  const sendSegmentEvent = useSendSegmentEvent()
  useEffect(() => {
    sendSegmentEvent("Upcoming Session Timer Displayed", { secondsToUpcomingSession: timeToUpcomingSession })
  }, [])
  const joinSessionLinkClicked = () => {
    sendSegmentEvent("Upcoming Session Join Button Clicked", { secondsToUpcomingSession: timeToUpcomingSession })
  }

  const timeToDisplay = timeUntilSessionTimerActive ?
    timeUntilSession.format('m:ss') :
    dayjs.duration(5, 'minutes').subtract(timeUntilSessionLinkExpires).format('m:ss')

  return (
    <div css={css`
      display: flex;
      flex-flow: column;
      align-items: center;
    `}>
      <div css={css`
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #FFFFFF;
      `}>
        {timeUntilSessionTimerActive ? 'Your session starts in...' : 'Your session has started'}
      </div>
      <TimeDisplay time={timeToDisplay}/>
      <Link onClick={joinSessionLinkClicked} to={`/s/${nextUpcomingParticipant.eventId}`}>
        <FlowButton customCss={css`margin-top: 24px;`}>Join Session</FlowButton>
      </Link>
    </div>
  )
}