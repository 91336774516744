/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FlowButton, Modal, Text, TEXT_STYLES } from '../../components/flowComponents';
import { useMediaQuery } from 'react-responsive';


export const HostingAlreadyClaimedModal = ({ onHide }) => {
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})

  return (
    <Modal onClose={onHide}>
      <div css={css`
        ${!mobileView && css`width: 500px;`}

        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
      `}>
        <Text style={TEXT_STYLES.MARKETING_H4}>Another host has already stepped in</Text>
        <div>
          <Text>Thanks so much for offering to host!</Text>
          <Text> It looks like another host has already stepped in to run the session.</Text>
        </div>
        <FlowButton onClick={onHide}>Close</FlowButton>
      </div>
    </Modal>
  )
}