/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DailyVideo } from "@daily-co/daily-react";
import { useParticipant } from "@daily-co/daily-react";
import { LoungeTooltip } from "../LoungeTooltip";
import {
  UserIconTooltip,
  parseInitialsFromDisplayName,
} from "../OtherPeepsList";
import { TooltipWrapper } from "../../components/TooltipWrapper";
import { NEUTRAL_80, FC_GREEN, FC_LIGHT_GREEN } from "../../emotionVariables";

export default function LoungeVideoTile({
  id,
  isScreenShare,
  isLocal,
  isAlone,
  users,
  sharedMouseoverState,
}) {
  const participant = useParticipant(id);
  if (!participant) return <></>;
  const user = users.find((user) => user.userId === participant.user_id);
  const canShowVideo = participant.permissions.canSend;
  if (!user) return <></>;
  return (
    <TooltipWrapper
      TooltipComponent={LoungeTooltip}
      TooltipContents={<UserIconTooltip user={user} />}
      sharedMouseoverState={sharedMouseoverState}
      retainMousedOverInsideTooltip
      shouldAutoAlign
    >
      <div
        css={css`
          position: relative;
          z-index: 0;
          background: black;
          color: white;
          border-radius: 13px;
          height: 0;
          background: ${isLocal ? `${FC_GREEN}d4` : NEUTRAL_80};
          padding-bottom: calc(100% / 1.7777); /* 16:9 */
          overflow: hidden;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        `}
      >
        {isLocal && <YouBadge />}
        <InitialsCircle canShowVideo={canShowVideo} name={user.name} />

        <div
          css={css`
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          `}
        >
          <DailyVideo
            automirror
            sessionId={id}
            type={isScreenShare ? "screenVideo" : "video"}
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        </div>

        <PomodoroMeter user={user} />
      </div>
    </TooltipWrapper>
  );
}

const PomodoroMeter = ({ user }) => {
  const { name, timedSession } = user;
  const currentlyInTimedSession = timedSession.start !== undefined;
  const timerLengthSeconds = Math.floor(timedSession.timerLengthMs / 1000);
  const completionRate =
    (Date.now() - timedSession.start) / 1000 / timerLengthSeconds;

  const renderMeter = () => {
    return (
      <div css={css`
        padding: 6px 12px;
      `}>
        <div
          css={css`
            font-size: 12px;
            line-height: 14px;
            position: relative;
            z-index: 2;
          `}
        >
          Pomodoro
        </div>
        <div
          css={css`
            height: 5px;
            border-radius: 5px;
            background: ${FC_GREEN};
            border: 1px solid ${FC_GREEN};
            overflow: hidden;
            position: relative;
            z-index: 2;
          `}
        >
          <div
            css={css`
              width: ${completionRate * 100}%;
              height: 100%;
              background: ${FC_LIGHT_GREEN};
              border-radius: 6px;
            `}
          ></div>
        </div>
        <div css={css`
          position: absolute;
          z-index: 1;
          bottom: 0;
          right: 0;
          left: 0;
          height: 45px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        `}></div>
      </div>
    );
  };

  return (
    <div
      css={css`
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      {currentlyInTimedSession && renderMeter()}
    </div>
  );
};

const InitialsCircle = ({ canShowVideo, name }) => {
  const initials = parseInitialsFromDisplayName(name);

  const position = canShowVideo
    ? `top: 6px; left: 6px;`
    : `top: 50%; left: 50%;`;
  const transform = canShowVideo
    ? `transform: translate(0, 0);`
    : `transform: translate(-50%, -50%);`;
  const dimensions = canShowVideo
    ? `height: 24px; width: 24px; line-height: 24px;`
    : `height: 40px; width: 40px; line-height: 40px;`;
  const font = canShowVideo ? `font-size: 12px;` : `font-size: 20px;`;
  return (
    <div
      css={css`
        z-index: 2;
        position: absolute;
        ${position}
        ${transform}
        ${dimensions}
        ${font}
        background: rgba(0,0,0,0.2);
        border-radius: 100%;
        text-align: center;
      `}
    >
      {initials}
    </div>
  );
};

const YouBadge = () => (
  <div
    css={css`
      z-index: 2;
      position: absolute;
      top: 8px;
      right: 8px;
      background: ${FC_GREEN};
      color: ${FC_LIGHT_GREEN};
      padding: 1px 6px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.5px;
      border-radius: 4px;
    `}
  >
    You
  </div>
);
