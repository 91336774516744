/** @jsxImportSource @emotion/react */
import { keyframes, css } from '@emotion/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BUTTON_STYLES, FlowButton, TextInput, TEXT_INPUT_STYLES } from '../flowComponents';
import Icon, { TYPE_CLOSE_UNSET_FILL, TYPE_DRAG_HANDLE } from '../../daily/daily-components/Icon/Icon';
import { NEUTRAL_90, FC_GREEN, FC_IRIS_BLUE, NEUTRAL_60, NEUTRAL_70 } from '../../emotionVariables';
import { GoalListCheckMark } from '../../shared/components/icons';
import { DraggableList } from '../DraggableList';
import { useConfetti } from '../../wrappers/ConfettiProvider';

export const GoalList = ({ goalsData, onGoalItemFocus = null, interactionEnabled = true, onPaste = () => {} }) => {
  const { goals, addGoal, goalEdited, toggleCompleted, removeGoal, reorderGoals, justCompletedIndex } = goalsData

  const goalInputRefs = useRef({})
  const setGoalInputRef = (element, index) => goalInputRefs.current[index] = element

  // we have to do some roundabout state tracking and useEffect-ing rather than imperatively focusing inside onSubmit()
  // because it's challenging to autofocus a goal before it's been rendered
  const [autofocusPending, setAutofocusPending] = useState(false)
  const onSubmit = (event) => {
    event.preventDefault();
    const currentlyFocusedGoalIndex = parseInt(Object.keys(goalInputRefs.current).find(index => document.activeElement === goalInputRefs.current[index]), 10) ?? -1
    if (currentlyFocusedGoalIndex === goals.length - 1) {
      addGoal();
    }
    setAutofocusPending(true)
  }
  // When enter is pressed, focus will move down to the subsequent goal in the list (very similar, albeit not identical to hitting tab)
  // If there is no subsequent goal, because the currently focused goal is the last one, then a new one will get created first.
  // Clicking the "Add Goal" button will always create a new goal, but won't focus anything.
  useEffect(() => {
    const currentlyFocusedGoalIndex = parseInt(Object.keys(goalInputRefs.current).find(index => document.activeElement === goalInputRefs.current[index]), 10) ?? -1
    const goalIndexToAutofocus = currentlyFocusedGoalIndex + 1
    if (autofocusPending && goalInputRefs.current[goalIndexToAutofocus] !== undefined) {
      goalInputRefs.current[goalIndexToAutofocus].focus()
      setAutofocusPending(false)
    }
  }, [autofocusPending])

  const GoalsComponents = useMemo(() => goals.map((goal, index) =>
    <Goal
      key={goal.id}
      goal={goal}
      goalEdited={(text) => goalEdited(text, index)}
      toggleCompleted={() => toggleCompleted(index)}
      removeGoal={() => removeGoal(index)}
      removable={goals.length > 1}
      onFocus={onGoalItemFocus}
      isLast={index === goals.length - 1}
      setGoalInputRef={(element) => setGoalInputRef(element, index)}
      justCompleted={justCompletedIndex === index}
      onPaste={onPaste}
    />
  ), [goals])

  return (
    <div css={css`width: 100%;`}>
      <form onSubmit={onSubmit} css={css`justify-content: stretch; display: flex; flex-direction: column;`}>
        <DraggableList reorder={reorderGoals} options={{ verticalSpacing: 42 }} key="draggableList">
          {GoalsComponents}
        </DraggableList>
        {interactionEnabled &&
          <div css={css`margin: 12px 38px 0px 66px;`}>
            <FlowButton fillAvailableWidth onClick={addGoal} buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>Add Goal</FlowButton>
            <button type='submit' css={css`display: none;`}/>
          </div>
        }
      </form>
    </div>
  )
}

const Goal = ({ goal, goalEdited, toggleCompleted, removeGoal, removable = true, onFocus, isLast, setGoalInputRef, justCompleted, reorderIsPending = false, dragBindings, onPaste = () => { } }) => {  const { text, completedAt, placeholder } = goal

  const completed = completedAt !== null
  const [completedAnimationType, setCompletedAnimationType] = useState(null)
  useEffect(() => {
    if (justCompleted) { setCompletedAnimationType(Math.floor(Math.random() * 2)) }
  }, [justCompleted])
  useEffect(() => {
    if (!completed) { setCompletedAnimationType(null) }
  }, [completed])

  const emitConfetti = useConfetti()
  useEffect(() => {
    if (justCompleted) { emitConfetti() }
  }, [justCompleted])

  const strikethroughAnimation = keyframes({
    'from': css`
      clip-path: inset(0 100% 0 0);
    `,
    'to': css`
      clip-path: inset(0 0% 0 0);
    `
  })

  const borderFlashAnimation = keyframes({
    'from': css`
      border-width: 1px;
      border-color: transparent;
      clip-path: inset(0 100% 0 0);
    `,
    '25%': css`
      border-color: ${FC_GREEN};
    `,
    '50%': css`
      clip-path: inset(0 0% 0 0);
      border-width: 1px;
    `,
    '51%': css`
      border-width: 3px;
      border-color: ${FC_GREEN};
      clip-path: inset(0 0% 0 0);
    `,
    'to': css`
      clip-path: inset(0 0% 0 0);
    `
  })

  const flipForFun = keyframes({
    'from': css`
      transform: translateZ(0);
      animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);

    `,
    '50%': css`
      transform: translateZ(150px);
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);

    `,
    'to': css`
      transform: translateZ(0);
    `
  })

  const flipForFun2 = keyframes({
    'from': css`
      transform: rotateX(0turn);
      animation-timing-function: linear;
    `,
    'to': css`
      transform: rotateX(-2turn);
    `
  })

  const glow = keyframes({
    'from': css`
      box-shadow: 0 0 0 0 ${FC_GREEN};
    `,
    'to': css`
      box-shadow: 0 0 20px 10px hsla(113, 0%, 100%, 0);
    `
  })

  const flippyStyle = css`
    animation: ${flipForFun} .75s both ease-out, ${glow} .4s ease-out forwards .75s;
  `

  const flippyStyle2 = css`
    animation: ${flipForFun2} .75s both ease-out;
  `

  const fakeOverlaidInputStyle = css`
    top: 0;
    left: -1px;
    border-width: 2px;
    border-color: transparent;
    background-color: transparent;
    color: transparent;
    position: absolute;
    pointer-events: none;
  `

  const [hovered, setHovered] = useState(false)

  const ifInteractionEnabled = callback => (...args) => { if (!reorderIsPending) { callback(...args) } }

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} css={css`
      display: flex;
      align-items: center;
      width: 100%;
    `}>
      <div {...dragBindings} css={css`
        touch-action: none;
        ${!hovered && css`visibility: hidden;`}
      `}>
        <Icon
          css={css`
            fill: ${NEUTRAL_60};
            transition: fill 0.2s;
            cursor: grab;
            &:active { fill: ${NEUTRAL_70}; cursor: grabbing; }
            &:hover { fill: ${NEUTRAL_70}; transition: none; }
          `}
          type={TYPE_DRAG_HANDLE}
        />
      </div>
      <div tabIndex={text !== '' ? 0 : undefined} onClick={ifInteractionEnabled(toggleCompleted)} css={css`
        width: 38px;
        height: 38px;
        border-radius: 8px;
        border: 1px solid rgba(0,0,0, 0.25);
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
        margin-right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
        ${text !== '' && css`
          opacity: 1;
          cursor: pointer;
          pointer-events: unset;
        `}

        &:hover, &:active { border-color: rgba(0,0,0, 0.87); }
        &:focus, &:focus-visible {
          outline-style: solid;
          outline-color: ${FC_IRIS_BLUE};
        }

      `}>
        {completed && <GoalListCheckMark css={css`fill: ${NEUTRAL_90}};`}/>}
      </div>
      <div css={css`
        ${completedAnimationType === 0 && justCompleted && flippyStyle2};
        perspective: 500px;
        flex-grow: 1;
      `}>
        <div onPaste={onPaste} css={css`
          position: relative;
            ${completedAnimationType === 0 && justCompleted && flippyStyle};
            border-radius: 6px;
        `}>
          <TextInput
            inputStyle={TEXT_INPUT_STYLES.PRIMARY}
            customCss={css`
              
              ${completed && css`color: rgba(0, 0, 0, 0.6);`}
              
            `}
            value={text}
            onFocus={onFocus}
            onChange={ifInteractionEnabled(event => goalEdited(event.target.value))}
            placeholder={placeholder ?? 'Enter a goal'}
            ref={setGoalInputRef}
          >
          </TextInput>
          {completed && <TextInput customCss={css`
            ${fakeOverlaidInputStyle}

            text-decoration: line-through;
            text-decoration-color: rgba(0, 0, 0, 0.87);

            animation: ${strikethroughAnimation} ${justCompleted ? '.6s' : '0s'} both ease-in-out ${completedAnimationType === 0 && justCompleted ? '.75s' : ''};
          `} inputStyle={TEXT_INPUT_STYLES.PRIMARY} tabIndex={-1} value={text} readOnly />}
          {(completedAnimationType === 1 && justCompleted) && <TextInput customCss={css`
            ${fakeOverlaidInputStyle}
            animation: ${borderFlashAnimation} 1.2s both ease-out 0.15s;
          `} inputStyle={TEXT_INPUT_STYLES.PRIMARY} tabIndex={-1} value={''} readOnly />}
        </div>
      </div>
      <div onClick={ifInteractionEnabled(removeGoal)} css={css`visibility: ${removable ? 'visible' : 'hidden'};`}>
        <Icon type={TYPE_CLOSE_UNSET_FILL} css={css`
          cursor: pointer;
          width: 38px;
          height: 38px;
          fill: rgba(0, 0, 0, 0.6);
          &:hover { fill: black; }
          &:active { fill: black; }
        `} />
      </div>
    </div>
  )
}