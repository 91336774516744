import React, { useEffect, useRef } from "react"
import toast from "react-hot-toast";
import { useCheckVersion } from '../fetch/endpoints';
import "./UpdateNotification.scss"

/** @jsxImportSource @emotion/react */
import { keyframes, css } from '@emotion/react'
import { LinkStyledText } from "./flowComponents";

const UpdateFlowClubNotification = () => {
  return (
    <div>
      <div style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "10px", letterSpacing: "1.5px" }}>
        UPDATE FLOW CLUB
      </div>
      <div style={{ color: "rgba(0, 0, 0, 0.87)", letterSpacing: "0.5px", marginTop: "8px" }}>
        A new version of Flow Club is available&nbsp;— <LinkStyledText onClick={() => window.location.reload()}>
          Reload
        </LinkStyledText> or refresh to update!
      </div>
    </div>
  )
}

const StaticNotification = ({children}) => {
  const wiggle = keyframes({
    '0%, 100%': {
      transform: "rotate(0deg)",
      transformOrigin: "50% 100%"
    },
    "20%": {
      transform: "rotate(1.5deg)"
    },
    "40%": {
      transform: "rotate(-3deg)"
    },
    "60%": {
      transform: "rotate(3deg)"
    },
    "80%": {
      transform: "rotate(-1.5deg)"
    }
  })

  return (
    <div css={css`
      display: flex;
      width: 368px;
      padding: 24px 32px 24px 36px;
      background: linear-gradient(90deg, #5D5FEF 12px, #fff 0%);
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      animation: ${wiggle} 1s ease-in-out both;
      animation-delay: 0.75s;
    `}>
      {children}
    </div>
  )
}

// entirely arbitrarily -- check every 10 minutes
const versionCheckPeriod = 1000 * 60 * 10
export const useUpdateNotification = () => {
  const { performFetch: checkVersion, result } = useCheckVersion()

  useEffect(() => {
    checkVersion({version: process.env.REACT_APP_DEPLOY_VERSION})
    const interval = setInterval(() => {
      checkVersion({version: process.env.REACT_APP_DEPLOY_VERSION})
    }, versionCheckPeriod)
    return () => clearInterval(interval)
  }, [])

  const activeToastId = useRef(null)
  const updateNeeded = result !== null && result.updateNeeded
  
  useEffect(() => {
    if (updateNeeded) {
      activeToastId.current = toast(<StaticNotification><UpdateFlowClubNotification/></StaticNotification>,
        {
          duration: Infinity,
          position: "bottom-center",
          style: {
            padding: "0px", margin: "0px", boxShadow: "none", backgroundColor: "#0000"
          },
          className: "toast-default-override"
        }
      )
    }
    return () => {
      toast.dismiss(activeToastId.current)
      activeToastId.current = null
    }
  }, [updateNeeded])
}