/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FlowButton, TextInput, BUTTON_STYLES } from './flowComponents'
import { useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { NEUTRAL_50, NEUTRAL_100 } from '../emotionVariables'
import { copyToClipboard } from '../utils'

export const ShareMilestoneLinkWithImagePreview = ({
  milestone,
  isFirstHostedSession,
  inviteLink,
  ogImage,
}) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const copyToClipboardAndSendSegmentEvent = async () => {
    sendSegmentEvent("Copied milestone share link", { milestone, isFirstHostedSession, inviteLink })
    await copyToClipboard(inviteLink)
  }

  return (
    <div css={css`
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      hyphens: auto;
      color: ${NEUTRAL_100};
      margin: 15px 0px;
    `}>
      <img
        css={css`
          width: 364px;
          margin: 32px 0 42px 0;
        `}
        src={ogImage}
        alt={isFirstHostedSession ? `I'm hosting Flow Club sessions to help you get work done`:`Celebrating a Flow Club milestone`}
      />
      <div css={css`
        width: 100%;
        text-align: left;
        justify-content: left;
      `}>
        <span css={css`
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 24px;
        `}>
          {isFirstHostedSession ? `Share the news` : `Share your achievement`}
        </span>
        <TextInput
          readOnly
          value={inviteLink}
          onFocus={copyToClipboardAndSendSegmentEvent}
          customCss={css`
            color: ${NEUTRAL_50};
            margin: 24px 0 16px 0;
          `}
        />
      <FlowButton
        fillAvailableWidth
        buttonStyle={BUTTON_STYLES.SECONDARY}
        onClick={copyToClipboardAndSendSegmentEvent}
      >
        Copy share link
      </FlowButton>
    </div>
  </div>
  )
}