import './InviteUtils.scss'
import { useContext, useState } from 'react';

import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { functionsHost, baseURL } from './firebase';
import { copyToClipboard, useQuery } from './utils'
import { Checkmark } from './shared/components/icons';
import { UserContext } from './UserProvider';
import { useSendSegmentEvent } from './wrappers/SegmentProvider';

export const InviteLink = () => {
  return (
    <a href="mailto:founders@flow.club?subject=I%20want%20to%20bring%20a%20friend%20to%20Flow%20Club&body=Their%20name%20is%20_____%20and%20their%20email%20is%20__________">Invite a friend</a>
  );
}

const CommunityTooltip = (props) => (
  <Tooltip {...props}>
    This badge is only visible to other members of this community.
  </Tooltip>
);

export const CommunityOverlay = (props) => (
  <OverlayTrigger
    placement={props.placement || 'top'}
    delay={{ show: 250, hide: 400 }}
    overlay={CommunityTooltip}
  >
    {props.children}
  </OverlayTrigger>
);

export const getShareLink = (inviteCode) => `${baseURL}?inviteCode=${inviteCode}`

export const InviteModal = ({ ...modalProps }) => {
  const { user } = useContext(UserContext)
  const query = useQuery();
  const inviteFirstName = query.get('inviteFirstName', '')
  const inviteLastName = query.get('inviteLastName', '')
  const inviteEmail = query.get('inviteEmail', '')
  return (
    <Modal
      {...modalProps}
      show
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="invite-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Invite a Friend
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InviteModalBody
          user={user}
          shareLink={getShareLink(user.inviteCode)}
          showInviteActionDefault={true}
          showCopy={true}
          inviteFirstName={inviteFirstName}
          inviteLastName={inviteLastName}
          inviteEmail={inviteEmail}
        />
      </Modal.Body>
    </Modal>
  );
}

export const InviteModalBody = ({
  user,
  shareLink,
  shareButtonText,
  showInviteActionDefault = false,
  showCopy = true,
  inviteFirstName = '',
  inviteLastName = '',
  inviteEmail = '',
}) => {
  const [showInviteAction, setShowInviteAction] = useState(showInviteActionDefault)
  const [shareStatus, setShareStatus] = useState("invite")
  const [invitedUser, setInvitedUser] = useState(null)
  const [invitingUser, setInvitingUser] = useState(false)
  const { register, handleSubmit } = useForm();
  const sendSegmentEvent = useSendSegmentEvent()

  const onSubmit = async (data) => {
    setInvitedUser(data.email)
    setInvitingUser(true)
    const body = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email.trim(),
      invitedByName: user.firstName,
      invitedByEmail: user.email,
      inviteCodeUsed: user.inviteCode,
      inviteMessage: data.message.trim(),
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };
    const resp = await fetch(functionsHost + 'createInvitedUser', requestOptions)
    const result = await resp.json()
    setInvitingUser(false)
    if (result.error === null) {
      setShareStatus("success")
      sendSegmentEvent("Sent guest pass", {
        ...body, location: window.location.pathname
      })
    } else {
      setShareStatus("failed")
      sendSegmentEvent("Tried to send guest pass to existing member", {
        ...body, location: window.location.pathname
      })
    }

  }
  const copyToClipboardAndSendSegmentEvent = () => {
    sendSegmentEvent("Copied invite link", {
      location: window.location.pathname
    })
    copyToClipboard(shareLink)
  }
  if (!showInviteAction) {
    return (
      <Button className="float-right" variant="outline-secondary" onClick={() => setShowInviteAction(true)}>Invite</Button>
    );
  }
  return (
    <>
      {invitingUser ?
        <div className="text-center">
          <Spinner
            as="span"
            animation="grow"
            size="lg"
            role="status"
            aria-hidden="true"
          />
          Bringing your friend into the Flow...
        </div>
        :
        shareStatus === 'success' ?
          <div className="text-center">
            <Checkmark />
            <p className="my-5">Sent your invite to <b>{invitedUser}</b></p>
            <p className="my-5">We'll email you when they sign up for their first session.</p>
            <Button variant="primary" onClick={() => setShareStatus('invite')}>
              Invite Another
            </Button>
          </div>
          :
          shareStatus === 'failed' ?
            <div className="text-center">
              <Checkmark />
              <p className="my-5"><b>{invitedUser} is already a member!</b></p>
              <p className="my-5">We appreciate you spreading the word though.<br />
                <a href={`mailto:${invitedUser}`}>Email them to coordinate a time to attend a session together!</a>
              </p>
              <Button variant="primary" onClick={() => setShareStatus('invite')}>
                Invite Another
              </Button>
            </div>
            :
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <p className="mb-4">
                  They get a guest pass to try Flow Club free for two weeks (instead of just 7 days).<br />
                  If your friend subscribes to a Flow Club membership, you will receive a free month after they pay for their first month!
                </p>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="12" className="required">
                  <Form.Label>First Name</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      name="firstName"
                      ref={register}
                      placeholder="Mihaly"
                      defaultValue={inviteFirstName}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="12" className="required">
                  <Form.Label>Last Name</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      name="lastName"
                      ref={register}
                      placeholder="Csikszentmihalyi"
                      defaultValue={inviteLastName}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="12" className="required">
                  <Form.Label>Email</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      name="email"
                      ref={register}
                      placeholder="mihaly@example.com"
                      defaultValue={inviteEmail}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="12">
                  <Form.Label>Message (optional)</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="invite-textarea"
                      as="textarea"
                      rows={2}
                      name="message"
                      ref={register}
                      placeholder="I've been using Flow Club to..."
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              {showCopy &&
                <Form.Row className="justify-content-md-center my-3">
                  <a href={shareLink}
                    onClick={(e) => { e.preventDefault(); copyToClipboardAndSendSegmentEvent(); }}>
                    Or, copy the link</a>
                </Form.Row>
              }
              <Form.Row className="justify-content-md-center">
                <Button variant="primary" type="submit">
                  {shareButtonText || "Send invite"}
                </Button>
              </Form.Row>
            </Form>
      }
    </>
  );
}