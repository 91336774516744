/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FlowButton } from '../components/flowComponents'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { FC_DARK_BLUE, FC_LIGHTER_BLUE } from '../emotionVariables'
import { useSendSegmentEvent } from '../wrappers/SegmentProvider'

const getUpcomingSessionIsWithin15Min = nextUpcomingSessionTime => nextUpcomingSessionTime.isBefore(dayjs().add('15', 'minute'))
const getDayjsObjectForParticipant = participant => participant !== null ? dayjs.unix(participant.start._seconds) : null

const UPCOMING_SESSION_STATES = {
  'NONE': 'NONE',
  'UPCOMING_SOON': 'UPCOMING_SOON',
  'UPCOMING': 'UPCOMING',
}

const getUpcomingSessionState = (nextUpcomingParticipant) => {
  if (nextUpcomingParticipant === null) {
    return UPCOMING_SESSION_STATES.NONE
  } else if (getUpcomingSessionIsWithin15Min(getDayjsObjectForParticipant(nextUpcomingParticipant))) {
    return UPCOMING_SESSION_STATES.UPCOMING_SOON
  } else {
    return UPCOMING_SESSION_STATES.UPCOMING
  }
}
const continueButtonTextMapping = {
  [UPCOMING_SESSION_STATES.NONE]: 'View the Schedule',
  [UPCOMING_SESSION_STATES.UPCOMING_SOON]: 'Join Next Session',
  [UPCOMING_SESSION_STATES.UPCOMING]: 'View your Next Session'
}
export const ContinueButtons = ({ nextUpcomingParticipant, space }) => {
  const upcomingSessionState = getUpcomingSessionState(nextUpcomingParticipant)
  const sendSegmentEvent = useSendSegmentEvent()
  const buttonText = continueButtonTextMapping[upcomingSessionState]
  const buttonLinkDestination = upcomingSessionState === UPCOMING_SESSION_STATES.NONE ?
    '/upcoming' :
    `/${upcomingSessionState === UPCOMING_SESSION_STATES.UPCOMING_SOON ? 's' : 'session'}/${nextUpcomingParticipant.eventId}`
  const destinationDescription = upcomingSessionState === UPCOMING_SESSION_STATES.NONE ?
    'Schedule' :
    upcomingSessionState === UPCOMING_SESSION_STATES.UPCOMING_SOON ? 'Session' : 'Session detail';

  const showLoungeLink = upcomingSessionState !== UPCOMING_SESSION_STATES.UPCOMING_SOON && space !== null

  return (
    <div css={css`
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      background-color: white;
      box-shadow: 0px -1px 25px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    `}>
      <div css={css`
        display: flex;
        width: 420px;
      `}>
        {showLoungeLink &&
          <Link to={`/lounge/`} css={css`flex: 1; margin-right: 10px; &:hover { text-decoration: none; }`}
            onClick={() => sendSegmentEvent("Clicked post-session lounge")}>
            <FlowButton fillAvailableWidth customCss={css`
              background-color: #FFFFFF;
              border: 1px solid ${FC_DARK_BLUE};
              color: ${FC_DARK_BLUE};
              &:hover { background-color: #EBEDF0; }
              &:active { background-color: ${FC_LIGHTER_BLUE} }
            `}>{'Join the Lounge'}</FlowButton>
          </Link>
        }
        <Link to={buttonLinkDestination} css={css`flex: 1;`}
          onClick={() => sendSegmentEvent("Clicked post-session button", { destination: destinationDescription })}>
          <FlowButton fillAvailableWidth>{buttonText}</FlowButton>
        </Link>
      </div>
    </div>
  )
}

export const SingleContinueButton = ({ onClick = () => {}, text = "Continue" }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const buttonClicked = () => {
    sendSegmentEvent("Single Continue Button Clicked", { text })
    onClick()
  }

  return (
    <div css={css`
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      background-color: white;
      box-shadow: 0px -1px 25px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    `}>
      <div css={css`
        display: flex;
        width: 420px;
      `}>
        <FlowButton fillAvailableWidth onClick={buttonClicked}>{text}</FlowButton>
      </div>
    </div>
  )
  }