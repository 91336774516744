/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SadOctopus } from "../components/sad-octopus"

export const SadOctoPage = ({ title = "Sorry, couldn't find that page", children = (
  <div>
    It may have been canceled or the link may be wrong.
    <br />
    Visit the schedule to find your next session.<br />
  </div>
)}) => {
  return (
    <div css={css`text-align: center; margin: 50px;`}>
      <SadOctopus />
      <h2 css={css`margin-top: 48px;`}>{title}</h2>
      {children}
    </div>
  )
}