/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { Text, TEXT_STYLES, LinkStyledText } from '../components/flowComponents';
import { FC_DARK_BLUE, NEUTRAL_50, FC_PINK, NEUTRAL_60, NEUTRAL_90, NEUTRAL_20, NEUTRAL_30, NEUTRAL_70, FC_IRIS_BLUE, NEUTRAL_80, FC_LIGHTER_BLUE } from '../emotionVariables';
import { usePreSignupUserDataProperty, UserContext } from '../UserProvider';
import { getSessionDisplayTimeAndDate, useUpcomingEvents } from '../Sessions/SessionUtils';
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import Icon, { 
  TYPE_CALENDAR,
  TYPE_CLOCK,
  TYPE_MAKING_TIME_FOR,
  TYPE_PLAY,
  TYPE_RIGHT_CARET_UNSET_FILL,
  TYPE_SETTINGS_PROFILE,
  TYPE_ATTENDEES,
  TYPE_STARS,
} from '../daily/daily-components/Icon/Icon';
import { Checkmark } from '../shared/components/icons';
import { useSpring, animated, config } from '@react-spring/web';
import { SquareIshSessionCard } from '../components/SquareIshSessionCard';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IntroVideoModal } from './IntroVideoModal';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { useGetEventsForUser } from '../fetch/endpoints';
import { USER_SESSION_LIST_MODES } from '../../functions/shared/constants';
import { FlowScoreModal } from '../Sessions/modals/FlowScoreModal';
import { ProgressCircle } from '../components/WeeklyGoalProgressIndicator';

export const useHomeScreenVisibility = () => {
  const { user, preSignupUserData } = useContext(UserContext)
  const { welcomeType } = user ?? preSignupUserData

  const isUserLoggedIn = user !== null;
  const hasLessThanThreeScoredSessions = user && user.scoredSessions < 3;
  const hasLessThanFiveSessions = user && user.sessions < 5;
  if (!isUserLoggedIn) {
    return true
  }
  switch (welcomeType) {
    case 'home':
      return hasLessThanFiveSessions
    case 'score3Sessions':
      return hasLessThanThreeScoredSessions || hasLessThanFiveSessions
    case 'timeOnboarding':
      return !isUserLoggedIn // TODO (2023-12-28, David): We're only showing this to non-logged in users for now because of a bug with `useUpcomingEvents` where `upcomingEventsLoading` will be false even though we haven't loaded the upcoming events yet. Ideally this would be !hasUpcomingEventsOrSessions, but we'll always return true temporarily while loading.
    case 'intentionOnboarding':
      return !isUserLoggedIn
    default:
      return hasLessThanFiveSessions
  }
}

// i'm sorry, the name was just too silly, i couldn't help myself
export const HomeHome = ({ setActiveTaskIndex }) => {
  const { user, preSignupUserData } = useContext(UserContext)
  const { inviteValidationInvitedBy: invitedBy } = useContext(InviteInfoContext)
  const { welcomeType } = user ?? preSignupUserData
  const wasInvited = invitedBy !== null
  const invitedByName = invitedBy?.name ?? null
  const greetingMessage = `${wasInvited && invitedByName ? `${invitedByName} invited you to jump` : "Jump"} into productive flow. Get started in 2 minutes with these steps.`
  const userId = user !== null ? user.uid : null
  // 2023-12-20: Ugh, we've been calling this hook for logged out users, which calls
  // Firestore subscribe even when the userId is null, but we have to call all the hooks
  // going all the way down so this would requite a major refactor
  const [upcomingEvents, upcomingEventsLoading] = useUpcomingEvents(userId)
  const [ mostRecentlyCompletedSessions, setMostRecentlyCompletedSessions ] = useState(null)
  const { performFetch: getMostRecentlyCompletedSessionsForUser } = useGetEventsForUser()
  const { setActiveModal } = useModal()

  const showIntroVideoModal = () => setActiveModal(IntroVideoModal)
  useEffect(() => {
    const fetchMostRecentlyCompletedSessions = async () => {
      if (user !== null && user.sessions >= 1 && welcomeType === 'score3Sessions') {
        const { events } = (await getMostRecentlyCompletedSessionsForUser({ pageSize: 3, listMode: USER_SESSION_LIST_MODES.PAST  })).result ?? { events: null}
        setMostRecentlyCompletedSessions(events)
      }
    }
    fetchMostRecentlyCompletedSessions()
  }, [user])

  return (
    <div css={css`width: 100%;`}>
      <div css={css`display: flex; justify-content: space-between; align-items: center;`}>
        <Text style={TEXT_STYLES.APP_H3} customCss={css`color: ${NEUTRAL_90};`}>{user === null ? "Welcome!" : `Welcome back${user && user.displayName ? `, ${user.displayName}!` : '!'}`}</Text>
        {upcomingEvents.length === 0 && <SkipToScheduleButton />}
      </div>
      {user === null &&
        <Text style={TEXT_STYLES.SUBTITLE_1} customCss={css`margin: 32px 0;`}>
          {greetingMessage}
        </Text>
      }
      {upcomingEvents.length > 0 && <UpcomingSessions upcomingEvents={upcomingEvents} />}
      <Checklist upcomingEvents={upcomingEvents} upcomingEventsLoading={upcomingEventsLoading} setActiveTaskIndex={setActiveTaskIndex} showIntroVideoModal={showIntroVideoModal} />
      {mostRecentlyCompletedSessions !== null && welcomeType === 'score3Sessions' && <RecentlyCompletedSessions recentlyCompletedSessions={mostRecentlyCompletedSessions} />}
    </div>
  )
}

const Checklist = ({ upcomingEvents, upcomingEventsLoading, setActiveTaskIndex, showIntroVideoModal }) => {
  const { user, preSignupUserData } = useContext(UserContext)
  const { welcomeType } = user ?? preSignupUserData
  const [ showCompletedTasks, setShowCompletedTasks ] = useState(false)
  const bookedSessionTimeAndDate = upcomingEvents.length > 0 ? getSessionDisplayTimeAndDate(upcomingEvents[0]) : null

  const checklistTasks = [
    { text: 'Watch 50s intro', icon: TYPE_PLAY, condition: usePreSignupUserDataProperty('watchedIntroVideo') !== undefined, taskClicked: showIntroVideoModal },
    { text: 'Fill out a goal', icon: TYPE_MAKING_TIME_FOR, condition: usePreSignupUserDataProperty('makingTimeFor') !== undefined, completedContent: usePreSignupUserDataProperty('makingTimeFor'), taskClicked: (() => setActiveTaskIndex(0)) },
    { text: 'Block out time', icon: TYPE_CALENDAR, condition: user !== null && user.googleCalendarAuth !== undefined, taskClicked: (() => setActiveTaskIndex(1)) },
    { text: 'Book a session', icon: TYPE_CLOCK, condition: (user !== null && user.sessions > 0) || (!upcomingEventsLoading && upcomingEvents.length > 0), completedContent: bookedSessionTimeAndDate !== null ? `${bookedSessionTimeAndDate.timeToDisplay} ${bookedSessionTimeAndDate.dateToDisplay}` : undefined, taskClicked: (() => setActiveTaskIndex(2)) },
    { text: 'Complete profile', icon: TYPE_SETTINGS_PROFILE, condition: user !== null && user.location !== undefined, taskClicked: (() => setActiveTaskIndex(3)) }
  ]
  
  const SESSION_COMPLETED_GOAL = 5
  const SCORED_SESSIONS_GOAL = 3

  const getSessionContent = () => {
    const userSessions = user === null ? 0 : user.sessions
    const sessionsCompletedPercentage = Math.min(1, userSessions / SESSION_COMPLETED_GOAL)
    return <ProgressCircle portionFilled={sessionsCompletedPercentage}>{userSessions}</ProgressCircle>
  }
  const getFlowScoreContent = () => {
    const scoredSessions = user === null ? 0 : user.scoredSessions
    const scoredSessionsPercentage = Math.min(1, scoredSessions / SCORED_SESSIONS_GOAL)
    return <ProgressCircle portionFilled={scoredSessionsPercentage}>{scoredSessions}</ProgressCircle>
  }

  const unlockedTasks = [
    { text: `Complete ${SESSION_COMPLETED_GOAL} sessions`, icon: TYPE_ATTENDEES, condition: user !== null && user.sessions >= SESSION_COMPLETED_GOAL, taskClicked: (() => setActiveTaskIndex(2)), completedContent: getSessionContent() },
    { text: `Give yourself a Flow Score in ${SCORED_SESSIONS_GOAL} sessions`, icon: TYPE_STARS, condition: user !== null && user.scoredSessions >= SCORED_SESSIONS_GOAL, taskClicked: (() => setActiveTaskIndex(2)), completedContent: getFlowScoreContent() },
  ]
  
  const tasksCompleted = checklistTasks.filter(task => task.condition)
  const tasksCompletedText = tasksCompleted.map(task => task.text)
  const tasksCompletedCount = tasksCompletedText.length
  const remainingTasks = checklistTasks.filter(task => !task.condition)

  return (
    <SegmentProvider baseData={{ numTasksCompleted: tasksCompletedText.length, tasksCompleted: tasksCompletedText, upcomingEvents: upcomingEvents.length }}>
      <div>
        <div css={css`display: flex; flex-direction: column; gap: 8px;`}>
          {remainingTasks.map(({ text, condition, icon, completedContent, taskClicked }, index) => (
            <ChecklistTask text={text} key={index} condition={condition} icon={icon} completedContent={completedContent} taskClicked={taskClicked} />
          ))}
        </div>
        {user && user.sessions >= 1 && welcomeType === 'score3Sessions' &&
        <div css={css`display: flex; flex-direction: column; gap: 8px;`}>
          {unlockedTasks.map(({ text, condition, icon, completedContent, taskClicked }, index) => (
            <ChecklistTask text={text} key={index} condition={condition} icon={icon} completedContent={completedContent} taskClicked={taskClicked} />
          ))}
        </div>
        }
        <div css={css`margin-top: 16px; display: flex; flex-direction: column; gap: 8px;`}>
        {tasksCompletedCount > 0 &&
          <div css={css`text-align: right; margin-bottom: 8px;`}>
          <LinkStyledText onClick={() => setShowCompletedTasks(!showCompletedTasks)}>
            <Text style={TEXT_STYLES.SUBTITLE_1}>
              {showCompletedTasks ? 'Hide' : 'Show'} {tasksCompletedCount} Completed Task{tasksCompletedCount === 1 ? '' : 's'}</Text>
          </LinkStyledText>
          </div>
        }
        {showCompletedTasks &&
          tasksCompleted.map(({ text, condition, icon, completedContent, taskClicked }, index) => (
            <ChecklistTask text={text} key={index} condition={condition} icon={icon} completedContent={completedContent} taskClicked={taskClicked} />
          ))
        }
        </div>
      </div>
    </SegmentProvider>
  )
}

const ChecklistTask = ({ text, condition, icon, completedContent, taskClicked }) => {
  const mobileView = useMediaQuery({query: '(max-width: 575px)'})

  const [hovered, setHovered] = useState(false)
  const animatedStyle = useSpring({
    transform: `translateX(${hovered ? 30 : 0}px)`,
    config: {...config.default, friction: 12}
  })

  const sendSegmentEvent = useSendSegmentEvent()
  const onClick = () => {
    sendSegmentEvent("Checklist Task Clicked", { taskClicked: text })
    taskClicked()
  }

  return (
    <animated.div
      style={animatedStyle}
      onMouseEnter={() => { if (!condition) { setHovered(true) } }}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      css={css`
        background-color: ${condition ? NEUTRAL_20 : 'white'};
        border-radius: 12px;
        height: 96px;

        user-select: none;
        padding: 20px;
        ${!condition && css`cursor: pointer;`}

        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <div css={css`display: flex; align-items: center;`}>
        <div css={css`
          width: 38px;
          height: 38px;
          border: 1px solid ${condition ? NEUTRAL_30 : NEUTRAL_50};
          border-radius: 8px;
          margin-right: 48px;
          flex-shrink: 0;

          display: flex;
          justify-content: center;
          align-items: center;
        `}>
          {condition && <Checkmark width={22} height={16} />}
        </div>
        {!mobileView && <Icon css={css`
          width: 50px;
          height: 50px;
          fill: ${condition ? NEUTRAL_60 : FC_PINK};
          margin-right: 28px;
        `} type={icon} />}
        <Text style={TEXT_STYLES.APP_H5} customCss={css`
          color: ${condition ? NEUTRAL_70 : (hovered ? FC_IRIS_BLUE : FC_DARK_BLUE)};
          ${condition && css`text-decoration: line-through;`}
        `}>
          {text}
        </Text>
      </div>

      <div css={css`display: flex; align-items: center;`}>
        <Text customCss={css`font-style: oblique; color: ${NEUTRAL_80}; margin-right: 24px;`}>{completedContent}</Text>
        <div css={css`
          width: 44px;
          height: 44px;
          border-radius: 100%;
          background-color: ${hovered ? FC_IRIS_BLUE : NEUTRAL_30};

          display: flex;
          align-items: center;
          justify-content: center;
        `}>
          <Icon type={TYPE_RIGHT_CARET_UNSET_FILL} css={css`stroke: ${condition ? NEUTRAL_60 : (hovered ? 'white' : FC_DARK_BLUE)};`} />
        </div>

      </div>
    </animated.div>
  )
}

export const UpcomingSessions = ({ upcomingEvents }) => {
  const mobileView = useMediaQuery({query: '(max-width: 575px)'})
  return (
    <div css={css`margin: 16px 12px;`}>
      <div css={css`display: flex; justify-content: space-between; align-items: center;`}>
        <Link to="/profile/sessions"><Text style={TEXT_STYLES.APP_H4} customCss={css`color: ${NEUTRAL_90};`}>Upcoming Sessions</Text></Link>
        <SkipToScheduleButton text="See schedule" />
      </div>
      <div
        css={css`
          display: flex;
          padding: 12px 0px;
          overflow-x: auto; // Add horizontal scrolling
          -webkit-overflow-scrolling: touch; // Improve scrolling experience on mobile
          scroll-snap-type: x mandatory; // Enable snap scrolling
          scroll-padding: 16px; // Add some padding for better visibility
          gap: 16px;

          ${mobileView
            ? css`
                width: calc(100vw - 32px); // Make the container full-width on mobile
                margin-left: -16px; // Adjust for padding
                padding-left: 16px; // Add padding for better visibility
                padding-right: 16px;
              `
            : null}
        `}
      >
        {upcomingEvents.map((event) => (
          <div css={css`scroll-snap-align: start;`}>
            <SquareIshSessionCard session={event} key={event.id} />
          </div>
          ))}
      </div>
    </div>
  )
}

const RecentlyCompletedSessions = ({ recentlyCompletedSessions }) => {
  const { setActiveModal } = useModal()
  return (
    <div css={css`margin-top: 16px;`}>
      <Text style={TEXT_STYLES.APP_H4} customCss={css`color: ${NEUTRAL_90};`}>Recently Completed</Text>
      <div css={css`display: flex; padding: 12px 8px; max-width: 100%; overflow: auto; gap: 16px;`}>
        {recentlyCompletedSessions.map(event =>
          <SquareIshSessionCard
            session={event} key={event.id} completed={true} participant={event.participant}
            flowScoreFn={() => setActiveModal(FlowScoreModal, { session: event })}
          />)}
      </div>
    </div>
  )
}


export const SkipToScheduleButton = ({ text = "Skip to schedule"}) => {
  const sendSegmentEvent = useSendSegmentEvent()

  return (
    <Link to="/upcoming" onClick={() => sendSegmentEvent("Home Onboarding Skip Link Clicked")} css={css`&:hover { text-decoration: none; }`}>
      <div css={css`
        user-select: none;
        border-radius: 22px;
        background-color: white;
        color: ${FC_DARK_BLUE};
        border: 1px solid transparent;

        display: flex;
        align-items: center;
        gap: 8px;
        padding: 11px 16px;

        transition: background-color 0.2s, border-color 0.2s;
        &:hover {
          border-color: ${FC_DARK_BLUE};
          background-color: ${FC_LIGHTER_BLUE};
          transition: none;
        }
      `}>
        <Text style={TEXT_STYLES.BODY_2} customCss={css`margin-right: 36xpx;`}>{text}</Text>
        <Icon type={TYPE_RIGHT_CARET_UNSET_FILL} css={css`stroke: ${FC_DARK_BLUE};`} />
      </div>
    </Link>
  )
}