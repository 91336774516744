/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef } from 'react';
import Icon, { TYPE_HIGH_FIVE_HAND } from '../daily/daily-components/Icon/Icon';
import { useParticles } from '../wrappers/ParticlesManager';
import { usePreviousSticky } from '../utils';

export const HighFiveIcon = ({ hovered = null, highFiveSent, iconColor = "white", clickParticleColor = "white", customCss = null }) => {
  const handIconRef = useRef(null)
  const { emitParticleSet } = useParticles()
  const justHighFived = usePreviousSticky(highFiveSent) === false && highFiveSent
  useEffect(() => {
    if (justHighFived && handIconRef.current !== null) {
      const { x, y, width, height } = handIconRef.current.getBoundingClientRect()
      emitParticleSet({ position: { x: x + width / 2, y: y + height / 2 }, radius: 30, particleLengthRange: { min: 5, max: 15 }, width: 3, particleCountRange: { min: 8, max: 12 }, angleVarianceFactor: 0.5, rootColor: clickParticleColor })
    }
  }, [justHighFived])

  return (
    <Icon ref={handIconRef} type={TYPE_HIGH_FIVE_HAND} css={css`
      width: 16px;
      height: 16px;
      stroke: ${iconColor};
      transform: rotate(${hovered && !highFiveSent ? '-20' : '0'}deg);
      ${hovered === null && !highFiveSent && css`
        &:hover {
          transform: rotate(-20deg);
        }
      `}

      ${!highFiveSent && css`transition: transform 0.2s;`}
      ${highFiveSent && css`
        fill: ${iconColor};
        stroke: transparent;
      `}

      ${customCss};
    `} />
  )
}