import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePreviousSticky } from '../utils';

export const useResetScroll = () => {
  const smoothResetPaths = ['/profile'] // a bit unnecessary, but makes switching between tabs on my flow club look slightly less weird
  const { pathname } = useLocation()
  const previousPathname = usePreviousSticky(pathname)
  useEffect(() => {
    const smoothReset = smoothResetPaths.some(excludedPath =>
      previousPathname !== null && previousPathname.includes(excludedPath) && pathname.includes(excludedPath)
    )
    window.scrollTo({ top: 0, left: 0, behavior: !smoothReset ? 'auto' : 'smooth' });
  }, [pathname]);
}