// const newRoomEndpoint =
//   `${window.location.origin}/api/rooms`;

/**
 * Create a short-lived room for demo purposes.
 *
 * It uses the redirect proxy as specified in netlify.toml`
 * This will work locally if you following the Netlify specific instructions
 * in README.md
 *
 * See https://docs.daily.co/reference#create-room for more information on how
 * to use the Daily REST API to create rooms and what options are available. 
 */
import {dailyHost} from '../firebase'

const getRoomName = (event) => {
  return event.dailyRoomName ?? event.hostUser.displayName.toLowerCase().replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').trim()
}

export const getRoomUrl = (event) => {
  const hostNameSlug = getRoomName(event);
  return `${dailyHost}${hostNameSlug || 'flowclub'}`;
}
