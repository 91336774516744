
import { ONE_HOUR, ONE_MINUTE, SESSION_CREATION_MINIMUM_MINUTES_BEFORE_SESSION_START, USER_SESSION_STATUS } from "../../../functions/shared/constants"
import { getSessionIsJoinable, getUserHasBookedSession, groupObjectsByProperty } from "../../../functions/shared/helpers"
import { EMPTY_HOST_ID } from "../SessionUtils"

const getFakeHostTBDSessionData = start => ({
  "id": "fakeHostTBDSession",
  "description": "Classic 60-minute session. Great for first-timers to see what Flow Club is all about.",
  "hostId": EMPTY_HOST_ID,
  "hostName": "Host TBD",
  "start": start,
  "end": start + ONE_HOUR,
  "subtitle": "5m share goals / 50m muted deep work / 5m celebrate",
  "title": "60 min Flow Club",
  "userTags": [],
  "agenda": [
      {
          "duration": 5,
          "name": "share goals",
          "activeWorkingTime": false
      },
      {
          "duration": 50,
          "name": "muted deep work",
          "activeWorkingTime": true
      },
      {
          "duration": 5,
          "name": "celebrate",
          "activeWorkingTime": false
      }
  ],
  "duration": 60,
  "guests": [EMPTY_HOST_ID],
  "musicDescription": "",
  "hostedMusicPlaybackData": null,
  "tags": [],
  "maxAttendees": 9,
  "chatOnly": false,
  "automutingDisabled": false,
  "visibility": "public",
  "showCompany": false,
  "canceledSeatOpen": false,
  "userSessionStatus": USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUEST,
  "scheduleSection": "full",
  "hostUser": {
    "id": EMPTY_HOST_ID,
    "joined": "2022-09-12T20:45:52.467Z",
    "firstName": "Anita",
    "lastName": "Host",
    "displayName": "Host TBD",
    "fullName": "",
    "hostStatus": "active",
    "pronouns": "",
    "location": "",
    "company": "",
    "pronunciation": "",
    "tags": [],
    "flowActivities": "",
    "timezone": "America/Los_Angeles",
    "totalFlowScore": 0,
    "scoredSessions": 0,
    "sessions": 1000,
    "inviteCode": "",
    "invitesLeft": 0,
    "numCoworkedWith": 0,
    "slug": "host-tbd",
    "hostedSessions": 5,
    "hostedMinutes": 0,
    "linkedin": null,
    "twitter": null,
    "preferences": {},
    "bio": "This session is looking for a host. Would you be willing to adopt it?",
    "ogImageUrl": "https://storage.googleapis.com/flowclub-static/images/hosts/host-tbd--9E0n40ccoLMgjxqpTw9Wk5hJZix1_og-image.png",
    "musicTitles": [
      ""
    ],
    "musicImages": null,
    "image": "https://storage.googleapis.com/flowclub-static/images/hosts/host-tbd.jpg",
    "image64": "https://storage.googleapis.com/flowclub-static/images/hosts/thumbnails/host-tbd_64x64.jpeg",
    "image100": "https://storage.googleapis.com/flowclub-static/images/hosts/thumbnails/host-tbd_100x100.jpeg",
    "hostOnboardedTime": {
      "_seconds": 1665440417,
      "_nanoseconds": 129000000
    },
    "startedHostOnboardingTime": null
  },
  "isFakeHostTBDSession": true,

  "dailyRoomName": null,
})

export const fillScheduleWithHostTBDCreatableSessions = sessions => {
  const sessionsWithHostTBDCreationOptions = [...sessions]
  const sessionsByTimeSlot = groupObjectsByProperty(sessions, 'start')

  Object.keys(sessionsByTimeSlot).forEach(timeSlot => {
    const timeUntilTimeSlot = Date.now() - timeSlot
    if (timeUntilTimeSlot * -1 < SESSION_CREATION_MINIMUM_MINUTES_BEFORE_SESSION_START * ONE_MINUTE) {
      return
    }

    const sessionsAtTime = sessionsByTimeSlot[timeSlot]
    if (sessionsAtTime.every(session =>
      !getSessionIsJoinable(session.userSessionStatus) &&
      !getUserHasBookedSession(session.userSessionStatus) &&
      session.userSessionStatus !== USER_SESSION_STATUS.NOT_JOINABLE_BAD_SUBSCRIPTION_STATE
    )) {
      sessionsWithHostTBDCreationOptions.push(getFakeHostTBDSessionData(parseInt(timeSlot)))
    }
  })

  return sessionsWithHostTBDCreationOptions
}