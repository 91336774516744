/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { DarkTooltip } from '../DarkTooltip';
import { Text, TEXT_STYLES } from '../flowComponents';
import { GoalList } from './GoalList';
import { TooltipWrapper } from '../TooltipWrapper';
import Icon, { TYPE_CLIPBOARD, TYPE_CLOCK, TYPE_PASTE } from '../../daily/daily-components/Icon/Icon';
import { FC_IRIS_BLUE, FC_LIGHTER_BLUE } from '../../emotionVariables';
import { copyToClipboard } from '../../utils';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { toastError, toastSuccess } from '../utils/toaster';

const parsePastedMarkdown = input => (input.match(/((?:- )?\[ ?[x]? ?\]|-) (.*)/gim) ?? []).slice(0, 20) // arbitrarily limit to 20 matched goals so things don't actually break on some insane input
const goalIsCompleted = input => input.match(/\[ ?[x] ?\]/gi) !== null
const strippedGoal = input => input.replace((input.match(/((?:- )?\[ ?[x]? ?\]|-) /gi) ?? [""])[0], "")
const textContainsMarkdownCheckbox = input => input.match(/((?:- )?\[ ?[x]? ?\]|-) /gi) !== null
export const parseGoalsToMarkdown = goals => goals.reduce(
  (goalsString, { completedAt, text }) =>
    `${goalsString ? `${goalsString}\n` : ''}- [${completedAt !== null ? 'X' : ' '}] ${text}`,
  '')

export const GoalListWithCopyPaste = ({ goalsData, disabled = false, lastSessionData = null }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const copyToClipboardClicked = async () => {
    copyToClipboard(parseGoalsToMarkdown(goalsData.goals), { onSuccessMessage: "Successfully copied goals to clipboard!" })
    sendSegmentEvent("Copy Goals To Clipboard Clicked")
  }

  const onPaste = (event) => {
    const text = event.clipboardData.getData("text")
    // if clipboard contains something that's not text, this will be an empty string
    // allow normal pasting if this doesn't look like a fancy formatted paste
    if (!textContainsMarkdownCheckbox(text)) {
      return
    }
    processPastedGoals(text)
    event.preventDefault()
    sendSegmentEvent("Pasted Goals via keyboard or menu")
  }

  const pasteFromClick = async () => {
    const text = await navigator.clipboard.readText();
    if (!textContainsMarkdownCheckbox(text)) {
      toastError({ message: "Sorry, we only know how to process markdown-formatted goals." })
      return
    }
    processPastedGoals(text)
    sendSegmentEvent("Pasted goals via click")
  }

  const processPastedGoals = (text) => {
    const pastedGoals = parsePastedMarkdown(text).map(text => ({ originalText: text, text: strippedGoal(text), completed: goalIsCompleted(text) }))
    goalsData.pasteGoals(pastedGoals)
    toastSuccess({ message: "Pasted goals from your clipboard!" })
  }

  const copyPreviousGoalsClicked = () => {
    if (lastSessionData.goals !== null) {
      goalsData.pasteGoals(lastSessionData.goals)
      sendSegmentEvent("Copy Previous Goals Clicked")
      toastSuccess({ message: "Copied over your goals" })
    }
  }

  return (
    <div>
      <div css={css`display: flex; justify-content: flex-end; gap: 24px;`}>
        {lastSessionData !== null && lastSessionData.goals.length > 0 &&
          <div onClick={copyPreviousGoalsClicked} css={css`
          cursor: pointer;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
          <ChecklistIconTooltip TooltipContents={
            <Text style={TEXT_STYLES.CAPTION}>
              {`Copy ${lastSessionData.goals.length} goal${lastSessionData.goals.length > 1 ? 's' : ''} from previous session on ${dayjs(lastSessionData.start).format("MM/DD/YY, hh:mm a")}`}
            </Text>
          }>
            <div>
              <Icon type={TYPE_CLOCK} css={css`fill: ${FC_IRIS_BLUE}; width: 24px; height: 24px;`} />
            </div>
          </ChecklistIconTooltip>
          </div>
        }
        <div onClick={copyToClipboardClicked} css={css`
          cursor: pointer;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
        <ChecklistIconTooltip TooltipContents={<Text style={TEXT_STYLES.CAPTION}>{'Copy list'}</Text>}>
          <div>
            <Icon type={TYPE_CLIPBOARD} css={css`fill: ${FC_IRIS_BLUE}; width: 24px; height: 24px;`} />
          </div>
        </ChecklistIconTooltip>
        </div>
        <div onClick={pasteFromClick} css={css`
          cursor: pointer;
          &:hover {
            background: ${FC_LIGHTER_BLUE};
          }
        `}>
        <ChecklistIconTooltip TooltipContents={
          <Text style={TEXT_STYLES.CAPTION}>
            <div>{'Your Markdown-formatted todos can be pasted directly— just paste into any of the input boxes, e.g.'}</div>
            <div>[ ] uncompleted task</div>
            <div>[ X ] completed task!</div>
          </Text>
        }>
          <Icon type={TYPE_PASTE} css={css`width: 24px; height: 24px;`} />
        </ChecklistIconTooltip>
        </div>
      </div>
      <div css={css`
        font-size: initial;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0px;
      `}>
        <GoalList onPaste={onPaste} goalsData={goalsData} interactionEnabled={!disabled} />
      </div>
    </div>
  )
}

export const ChecklistIconTooltip = ({ children, TooltipContents }) =>
  <TooltipWrapper TooltipComponent={DarkTooltip} useFloatingArgs={{ placement: 'top' }} TooltipContents={TooltipContents} retainMousedOverInsideTooltip={true}>
    {children}
  </TooltipWrapper>
