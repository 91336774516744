/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { isValidElement, useContext, useState } from 'react';
import { TooltipWrapper } from '../components/TooltipWrapper';

const ExclusiveMouseoverContext = React.createContext(null);

/**
 * Provides a sharedMouseoverState property to wrapped children, which if used in a {@link TooltipWrapper} ensures that more than one element's tooltip won't appear at once
 */
export const ExclusiveMouseoverWrapper = ({ children, exclusivityKey = null }) => {
  const parseIndexWithKey = index => exclusivityKey !== null ? `${exclusivityKey}-${index}` : index

  const { sharedMouseoverState: contextualSharedMouseoverState } = useContext(ExclusiveMouseoverContext) ?? {}
  const localSharedMouseoverState = useState(null)

  const [mousedOverIndex, setMousedOverIndex] = contextualSharedMouseoverState ?? localSharedMouseoverState

  const setItemMousedOverState = (index, mousedOver) => {
    const parsedIndex = parseIndexWithKey(index)
    if (mousedOver) {
      setMousedOverIndex(parsedIndex)
    } else {
      // set moused over item to null if the item that was moused out was the item currently being displayed
      setMousedOverIndex(mousedOverIndex => mousedOverIndex === parsedIndex ? null : mousedOverIndex)
    }
  }

  return React.Children.map(children, (child, index) =>
    child ?
      React.cloneElement(child, {
        sharedMouseoverState: [
          mousedOverIndex === parseIndexWithKey(index),
          (mousedOver) => setItemMousedOverState(index, mousedOver)
        ]
      }) :
      child
  )
}

/**
 * Creates a group within which context any {@link ExclusiveMouseoverWrapper}s will share exclusivity
 * (no more than one item from among all grouped wrappers will create a tooltip)
 */
export const ExclusiveMouseoverWrapperGroup = ({ children }) => {
  const sharedMouseoverState = useState(null)
  
  return (
    <ExclusiveMouseoverContext.Provider value={{ sharedMouseoverState }}>
      {children}
    </ExclusiveMouseoverContext.Provider>
  )
}